import { useEffect, useState } from "react"
import utilsService from "../../services/utils.service"
export default (props) => {
    const [selectedDate, setselectedDate] = useState(null)
    const [minDate, setMinDate] = useState(utilsService.getDateFormat(new Date(), 'YMD'))
    useEffect(() => {
        if(props.value != selectedDate) {
            setselectedDate(props.value)
        }
    }, [props.value])
    useEffect(() => {
        if(minDate != props.minDate) {
            setMinDate(props.minDate)
        }
    }, [props.minDate])
    const handleSelectedDate = (e) => {
        let dateValue = e.target.value;
        props.onChange && props.onChange(dateValue);
    }
    return (
        <input value={selectedDate} onChange={handleSelectedDate} type="date" min={minDate}/>
    )
}