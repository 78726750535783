import { useEffect, useState } from "react";
import utilsService from "../services/utils.service";
import apiService from "../services/api.service.js";
import ConfirmModal from "../comps/molecules/ConfirmModal.jsx";

export default () => {
  const [selectedQuestion, setSelectedQuestion] = useState(
    "Sight Sense Comparison Prompt - OpenAI"
  );
  const [correspondingInfo, setCorrespondingInfo] = useState("");
  const [promptData, setPromptData] = useState();
  const [confirmModal, showConfirmModal] = useState(false);
  const [showSaveMessage, setShowSaveMessage] = useState(false);

  let dropdownList = [
    {
      name: "Sight Sense Comparison Prompt - OpenAI",
      id: "ComparisonPromptOpenAI",
      key: "SightSensePrompts",
    },
    {
      name: "Sight Sense Comparison Prompt - Anthropic",
      id: "ComparisonPromptAnthropic",
      key: "SightSensePrompts",
    },
    {
      name: "Sight Sense Best Practices Prompt",
      id: "BestPracticesPrompt1",
      key: "SightSensePrompts",
    },
    {
      name: "Sight Sense Best Practices Format Prompt",
      id: "BestPracticesPrompt2",
      key: "SightSensePrompts",
    },
    {
      name: "Page Blazer - System Prompt",
      id: "SystemPrompt",
      key: "PageBlazer",
    },
    {
      name: "Page Blazer - Question Prompt",
      id: "UserPrompt",
      key: "PageBlazer",
    },
  ];

  const handleSelect = (target) => {
    setSelectedQuestion(target);
  };

  const getSiteData = async () => {
    const resp = await apiService.get({
      url: utilsService.END_POINTS.getgptprompts,
    });
    parsePromptData(resp);
  };

  const parseCorresponsingInfo = (updatedDropdownList) => {
    const selectedQuestionChange =
      updatedDropdownList &&
      updatedDropdownList.filter((prompt) => prompt.name === selectedQuestion);
    selectedQuestionChange &&
      setCorrespondingInfo(selectedQuestionChange[0].value);
  };

  const parsePromptData = (promptData) => {
    const { data } = promptData;
    if (data) {
      const updatedDropdownList = dropdownList.map((eachList) => {
        return {
          ...eachList,
          value: data[eachList.key][eachList.id],
        };
      });
      setPromptData(updatedDropdownList);
      parseCorresponsingInfo(updatedDropdownList);
    }
  };

  useEffect(() => {
    getSiteData();
  }, []);

  const filterPrompt = () => {
    return (
      promptData &&
      promptData.filter((prompt) => prompt.name === selectedQuestion)
    );
  };

  useEffect(() => {
    const selectedQuestionChange = filterPrompt();
    selectedQuestionChange &&
      setCorrespondingInfo(selectedQuestionChange[0].value);
  }, [selectedQuestion]);

  const updateData = async (correspondingInfo, updatedPrompt) => {
    const resp = await apiService.post({
      url: utilsService.END_POINTS.updateprompt,
      data: {
        correspondingInfo,
        updatedPrompt,
      },
    });
    if (resp) {
      await getSiteData();
      showConfirmModal(false);
      setShowSaveMessage(true);
      setTimeout(() => {
        setShowSaveMessage(false);
      }, 3000)
    }
  };

  const handleSave = () => {
    const updatedPrompt = filterPrompt();
    updateData(correspondingInfo, updatedPrompt[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    showConfirmModal(true);
  };

  const handleInfoChange = (e) => {
    setCorrespondingInfo(e.target.value);
  };

  return (
    <div>
      <div className="main-header-nav fluid-container py-4">
        <h2>Edit Prompt questions</h2>
      </div>
      <div className="mx-auto fluid-container">
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="form-field-select">
            <label className="label mb-1">Question</label>
            <div className="form-field">
              {" "}
              <select
                value={selectedQuestion}
                onChange={(e) => handleSelect(e.target.value)}
              >
                {dropdownList.map((x) => {
                  return (
                    <option key={"dropdown-item" + x._id} value={x.name}>
                      {x.name}
                    </option>
                  );
                })}
              </select>
              <svg
                className="post-svg-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="11"
                viewBox="0 0 18 11"
                fill="none"
              >
                <path
                  d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z"
                  fill="#1B72E6"
                />
              </svg>
            </div>
          </div>
          <div className="py-4 form-field">
            <label className="label mb-1">Corresponding information</label>
            <textarea
              rows="8"
              value={correspondingInfo}
              className="w-full text-area"
              onChange={(e) => handleInfoChange(e)}
            ></textarea>
          </div>
          <p className={`pb-2 toast ${showSaveMessage ? 'show' : ''}`}>
            <span>{selectedQuestion}</span> is updated successfully.
          </p>
          <button type="submit" class="btn-medium-2">
            Save
          </button>
        </form> 
        {confirmModal && (
          <ConfirmModal
            confirmationTitle="Do you want to save the change?"
            confirmButtonLabel="Save"
            onOk={handleSave}
            onCancel={() => showConfirmModal(false)}
          />
        )}
      </div>
    </div>
  );
};
