import { useState, useEffect } from 'react'
import Select from 'react-select';
import utilsService from "../../services/utils.service";
import scheduleService from '../../services/schedule.service';
import storeService from '../../services/store.service';
import TimePicker from '../atoms/TimePicker';
import DatePicker from '../atoms/DatePicker';
import DateTimePicker from '../atoms/DateTimePicker';
import SvgCode from '../atoms/SvgCode';
import { Tooltip } from 'react-tooltip';
import ConfirmModal from '../molecules/ConfirmModal.jsx';

const scheduleTypeList = [
    { label: 'Run daily', value: 'DAILY' },
    { label: 'Run one time', value: 'ONE_TIME' },
    { label: 'Run weekly', value: 'WEEKLY' },
]
const weekNames = { 'MON': 'Mon', 'TUE': 'Tue', 'WED': 'Wed', 'THU': 'Thu', 'FRI': 'Fri', 'SAT': 'Sat', 'SUN': 'Sun' }
const getTimeFormat = (dateObj) => {
    var dt1 = new Date();
    let hours = dateObj.getHours();
    let minutes = dateObj.getMinutes();
    const modifier = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const formattedHours = hours.toString().padStart(2, '0');
    let formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes} ${modifier}`;
}
const getTimezoneName = () => {
    try {
        const date = new Date();
        let timezoneName = '';
        const localStringPart = date.toLocaleString('en-US', { timeZoneName: 'short' }).match(/\b[A-Z]+\b$/);
        if (localStringPart && localStringPart.length > 0) {
            timezoneName = localStringPart[0];
        } else {
            const parts = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).formatToParts(date);
            const timezoneNamePart = parts.find(part => part.type === 'timeZoneName');
            timezoneName = timezoneNamePart ? timezoneNamePart.value : '';
        }
        return timezoneName;
    } catch (ex) {
        return '';
    }
}
const getScheduleText = (scheduleItem, doCheckExpired = true) => {
    let prefix = '';
    let sTime = '';
    let tillDateStr = '';
    let currentTime = new Date();
    if (scheduleItem.tillDate && doCheckExpired && scheduleItem.type != 'ONE_TIME') {
        let tillDate = new Date(scheduleItem.tillDate);
        if (currentTime.getTime() > tillDate.getTime()) {
            return null;
        }
    }
    if (scheduleItem.daily) {
        let daily = new Date(scheduleItem.daily);
        sTime = getTimeFormat(daily)
    }
    if (scheduleItem.type == 'ONE_TIME') {
        let onetimedate = new Date(scheduleItem.date);
        prefix = 'One time ' + utilsService.getDisplayDateTime(onetimedate, true, false);
        if (scheduleItem.date && doCheckExpired) {
            if (currentTime.getTime() > onetimedate.getTime()) {
                return null;
            }
        }
    } else if (scheduleItem.type == 'DAILY') {
        prefix = 'Every day'
        if (scheduleItem.tillDate) {
            tillDateStr = utilsService.getDisplayDateTime(scheduleItem.tillDate, true, false);
        }
    } else if (scheduleItem.type == 'WEEKLY') {
        prefix = 'Weekly';
        if (scheduleItem.weekdays) {
            prefix = 'Every ' + scheduleItem.weekdays.map(x => weekNames[x]).join(',')
        }
        if (scheduleItem.tillDate) {
            tillDateStr = utilsService.getDisplayDateTime(scheduleItem.tillDate, true, false);
        }
    }
    return `${prefix} @${sTime} ${tillDateStr.length > 0 ? 'till ' + tillDateStr : ''}`;
}
export default (props) => {
    const [content, setConent] = useState('')
    const [showIcon, setShowIcon] = useState(false)
    const [editScheduleText, seteditScheduleText] = useState('')


    const scheduleInterval = storeService.store.GLOBAL.schedularInterval;
    const { page, device, workflow } = props;
    const [inProgress, setInProgress] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [btnDisabled, setbtnDisabled] = useState(false)
    const [scheduleType, setScheduleType] = useState({ label: 'Run daily', value: 'DAILY' })
    const [loadingData, setLoadingData] = useState(false)
    const [scheduleSaved, setscheduleSaved] = useState(false)
    const [schedularText, setSchedularText] = useState('')
    const [savedItem, setSavedItem] = useState(null)

    const [selectedTime, setSelectedTime] = useState(null)
    const [runTillDate, setRunTillDate] = useState(null)
    const [tillDateMinDate, settillDateMinDate] = useState(utilsService.getDateFormat(new Date(), 'YMD'))

    const [onetimeDateTime, setonetimeDateTime] = useState(null)
    const [onetimeDateTimeError, setonetimeDateTimeError] = useState(false)
    const [onetimeTillDateTime, setonetimeTillDateTime] = useState(null)
    const [tillDateError, settillDateError] = useState(false)
    const [weekdays, setweekdays] = useState([]);
    const [weekdaysError, seteweekdaysError] = useState(false)

    const validateForm = () => {
        seteweekdaysError(false)
        const [hours, minutes] = selectedTime.split(':').map(Number);
        let inValidCount = 0;
        var daily = new Date();
        daily.setHours(hours, minutes, 0, 0);
        // 2024-09-10T00:20:00.000Z
        // let [datePart, hourPart] = daily.toISOString().split('T');
        // let [hpart, mpart, ...rpart] = hourPart.split('')
        // let isoHour = hours;
        // let isoMinutes = minutes;
        let tillDateValue = null;
        if(runTillDate) {
            let dt1 = new Date();
            const [yyyy, mm, dd] = runTillDate.split('-')
            dt1.setFullYear(yyyy - 0)
            dt1.setMonth(mm - 1)
            dt1.setDate(dd - 0)
            tillDateValue = dt1;
        }
        let fieldstoUpdate = {
            module: props.module,
            type: scheduleType.value,
            hour: hours,
            minutes: minutes,
            daily: daily,
            date: null,
            weekdays: null,
            tillDate: tillDateValue,
            active: true,
        };
        const formData = {
            ...fieldstoUpdate,
            selectedTenantEnv: storeService.store.ENVIRONMENTS.selectedTenantEnv
        };
        let tillError = false;
        if (runTillDate == null) {
            tillError = true;
        }
        if (scheduleType.value == 'ONE_TIME') {
            tillError = false;
            fieldstoUpdate.tillDate = null;
            if (onetimeDateTime == null) {
                inValidCount += 1;
                setonetimeDateTimeError(true)
            } else {
                let selOneTime = new Date(onetimeDateTime);
                if (selOneTime > new Date()) {
                    formData.date = selOneTime;
                    formData.daily = selOneTime;
                    fieldstoUpdate = {
                        ...fieldstoUpdate,
                        date: selOneTime,
                        daily: selOneTime
                    };
                } else {
                    inValidCount += 1;
                    setonetimeDateTimeError(true)
                }
            }
        }
        if (scheduleType.value == 'WEEKLY') {
            if (weekdays.length == 0) {
                seteweekdaysError(true)
                inValidCount += 1;
            } else {
                formData.weekdays = weekdays;
                fieldstoUpdate = {
                    ...fieldstoUpdate,
                    weekdays: weekdays,
                }
            }
        }
        settillDateError(tillError)
        if (tillError) {
            inValidCount += 1;
        }
        return {
            inValidCount,
            formData,
            fieldstoUpdate
        }
    }
    const handleformSubmit = (e) => {
        e.preventDefault();
        setbtnDisabled(true);
        const { inValidCount, formData, fieldstoUpdate } = validateForm();
        if (inValidCount > 0) {
            setbtnDisabled(false);
            setInProgress(false);
            return;
        }

        let scheduleToAdd = formData;
        // return;
        if (props.mode == 'DEVICE_DETAIL') {
            if (props.module == 'SIGHT_SENSE') {
                scheduleToAdd['page'] = utilsService.filterPageObject(page || props.page);
                scheduleToAdd['pageId'] = page._id;
            }
            if (props.module == 'PAGE_BLAZER') {
                scheduleToAdd['workflow'] = { ...(workflow || props.workflow) }
                scheduleToAdd['workflow'].steps = null;
                scheduleToAdd['workflowId'] = (workflow || props.workflow)?._id;
            }
            if (props.module == "PAGE_BLAZER" || props.module == "SIGHT_SENSE") {
                scheduleToAdd['device'] = utilsService.filterDeviceObject(device || props.device);
                scheduleToAdd['deviceId'] = device._id;
            }
            if (props.module == "API_GLIDE") {
                scheduleToAdd['collectionId'] = props.collectionId;
                scheduleToAdd['apiType'] = props.apiType;
            }
            if (savedItem) {
                scheduleService.updateschedulejob({
                    id: savedItem._id,
                    fieldsToUpdate: fieldstoUpdate
                }).then(addResp => {
                    setInProgress(false);
                    setbtnDisabled(false);
                    setscheduleSaved(true);
                    loadScheduleJobs();
                    setEditModal(false);
                })
            } else {
                scheduleService.addscheduleservice(scheduleToAdd).then(addResp => {
                    setInProgress(false);
                    setbtnDisabled(false);
                    setscheduleSaved(true);
                    loadScheduleJobs();
                    setEditModal(false);
                })
            }
        }
    }
    const loadScheduleJobs = () => {
        setLoadingData(true);
        let loadPayload = {}
        if (props.module == 'SIGHT_SENSE' || props.module == 'PAGE_BLAZER') {
            loadPayload.deviceId = device._id
        }
        if (props.module == 'SIGHT_SENSE') {
            loadPayload.pageId = page._id
        }
        if (props.module == 'PAGE_BLAZER') {
            loadPayload.workflowId = workflow._id
        }
        if (props.module == 'API_GLIDE') {
            loadPayload.collectionId = props.collectionId;
        }
        scheduleService.loadschedulejobs(loadPayload).then(items => {
            if (items.length > 0) {
                let firstItem = items[0];
                setSavedItem(firstItem);
            } else {
                setSavedItem(null);
            }
            setLoadingData(false);
        })
    }
    const onConfirmation = async (e) => {
        setShowDeleteModal(false);
        scheduleService.deleteschedulejob({ id: savedItem._id }).then(dbResp => {
            setSavedItem(null)
            setbtnDisabled(false);
            setEditModal(false);
            setInProgress(false)
            setRunTillDate(null)
        })
    }
    const handleDeleteItems = (e) => {
        e.preventDefault();
        setShowDeleteModal(true);
        setbtnDisabled(true);
    }
    const setTooltipContent = (scheduleItem) => {
        let toolTipContent = getScheduleText(scheduleItem);
        if (toolTipContent) {
            setConent(toolTipContent)
            setShowIcon(true)
        }
    }
    useEffect(() => {
        let selectedTenantEnv = storeService.store.ENVIRONMENTS.selectedTenantEnv;
        if (props.display == 'ICON') {
            if (props.mode == 'WORK_FLOW_LIST') {
                let byWorkflowIdHash = storeService.store.SCHEDULED_JOB_LIST.byWorkflowIdHash;
                if (byWorkflowIdHash.has(props.workflowId)) {
                    let schedules = (byWorkflowIdHash.get(props.workflowId) || []).filter(x => x.selectedTenantEnv == selectedTenantEnv);
                    if (schedules.length > 0) {
                        let scheduledContentList = [];
                        schedules.forEach(sItem => {
                            scheduledContentList.push(getScheduleText(sItem));
                        })
                        scheduledContentList = scheduledContentList.filter(x => !!x);
                        if (scheduledContentList.length > 0) {
                            setConent(scheduledContentList[0])
                            setShowIcon(true)
                        }
                    }
                }
            }
            if (props.mode == 'WORKFLOW_PAGE_LIST_DEVICE') {
                let byWorkflowIdHash = storeService.store.SCHEDULED_JOB_LIST.byWorkflowIdHash;
                if (byWorkflowIdHash.has(props.workflowId)) {
                    let schedules = (byWorkflowIdHash.get(props.workflowId) || []).filter(x => x.selectedTenantEnv == selectedTenantEnv);
                    if (schedules.length > 0) {
                        let scheduleItem = schedules.find(x => x.device._id == props.deviceId);
                        if (scheduleItem) {
                            setTooltipContent(scheduleItem)
                        }
                    }
                }
            }
            if (props.mode == 'PAGE_LIST' && props.pageId) {
                let byPageIdHash = storeService.store.SCHEDULED_JOB_LIST.byPageIdHash;
                if (byPageIdHash.has(props.pageId)) {
                    let schedules = (byPageIdHash.get(props.pageId) || []).filter(x => x.selectedTenantEnv == selectedTenantEnv);
                    if (schedules.length > 0) {
                        let scheduledContentList = [];
                        schedules.forEach(sItem => {
                            scheduledContentList.push(getScheduleText(sItem));
                        })
                        scheduledContentList = scheduledContentList.filter(x => !!x);
                        if (scheduledContentList.length > 0) {
                            setConent(scheduledContentList[0])
                            setShowIcon(true)
                        }
                    }
                }
            }
            if (props.mode == 'PAGE_LIST_DEVICE' && props.pageId) {
                let byPageIdHash = storeService.store.SCHEDULED_JOB_LIST.byPageIdHash;
                if (byPageIdHash.has(props.pageId)) {
                    let schedules = (byPageIdHash.get(props.pageId) || []).filter(x => x.selectedTenantEnv == selectedTenantEnv);
                    if (schedules.length > 0) {
                        let scheduleItem = schedules.find(x => x.device._id == props.deviceId);
                        if (scheduleItem) {
                            setTooltipContent(scheduleItem)
                        }
                    }
                }
            }
            if (props.mode == 'GLIDE_LIST' && props.collectionId) {
                let byCollectionHash = storeService.store.SCHEDULED_JOB_LIST.byCollectionIdHash;
                if (byCollectionHash.has(props.collectionId)) {
                    let schedules = (byCollectionHash.get(props.collectionId) || []).filter(x => x.selectedTenantEnv == selectedTenantEnv);
                    if (schedules.length > 0) {
                        let scheduledContentList = [];
                        schedules.forEach(sItem => {
                            scheduledContentList.push(getScheduleText(sItem));
                        })
                        scheduledContentList = scheduledContentList.filter(x => !!x);
                        if (scheduledContentList.length > 0) {
                            setConent(scheduledContentList[0])
                            setShowIcon(true)
                        }
                    }
                }
            }
        }
    }, [props.mode, props.workflowId, props.deviceId, props.pageId, props.collectionId, props.randomId])
    useEffect(() => {
        if (savedItem) {
            let dateToSet = new Date(savedItem.daily);
            let hours = dateToSet.getHours();
            let minutes = dateToSet.getMinutes();
            setSelectedTime(`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`)

            let time1 = new Date();
            if (savedItem.date) {
                time1 = new Date(savedItem.date);
            }
            if (savedItem.tillDate) {
                setRunTillDate(utilsService.getYMDFormat(savedItem.tillDate, true))
            }
            time1.setSeconds(0, 0);
            setOneTimeDateFrom(time1);
            setScheduleType(scheduleTypeList.find(x => x.value == savedItem.type));
            setweekdays(savedItem.weekdays || [])
            seteditScheduleText(getScheduleText(savedItem, false))

        }
    }, [savedItem])
    const setOneTimeDateFrom = (time1) => {
        // time1.setMinutes(time1.getMinutes() + 15);
        let minutes = time1.getMinutes();
        if (minutes % scheduleInterval !== 0) {
            const closest = Math.round(minutes / scheduleInterval) * scheduleInterval;
            minutes = closest === 60 ? 0 : closest;
        }
        time1.setMinutes(minutes, 0, 0)
        setonetimeTillDateTime(utilsService.getDateFormat(time1, 'YMDHS'))
        setonetimeDateTime(utilsService.getDateFormat(time1, 'YMDHS'))
    }
    const handleWeekDayChange = (e) => {
        if (e.target.checked) {
            setweekdays([...weekdays, e.target.value])
            seteweekdaysError(false)
        } else {
            setweekdays([...weekdays.filter(x => x != e.target.value)])
        }
    }
    useEffect(() => {
        if (props.module == 'SIGHT_SENSE') {
            if (props.device && props.page) {
                loadScheduleJobs();
            }
        }
        if (props.module == 'PAGE_BLAZER') {
            if (props.device && props.workflow) {
                loadScheduleJobs();
            }
        }
        if (props.module == 'API_GLIDE') {
            if (props.collectionId) {
                loadScheduleJobs();
            }
        }
    }, [props.device, props.page, props.workflow, props.collectionId])
    useEffect(() => {
        let time1 = new Date();
        time1.setMinutes(time1.getMinutes());
        setOneTimeDateFrom(time1);
        let dateToSet = new Date();
        // dateToSet.setHours(dateToSet.getHours() + 1);
        let hours = dateToSet.getHours();
        let minutes = dateToSet.getMinutes();
        if (minutes % scheduleInterval !== 0) {
            const closest = Math.round(minutes / scheduleInterval) * scheduleInterval;
            minutes = closest === 60 ? 0 : closest;
        }
        setSelectedTime(`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`)
        // loadScheduleJobs();

    }, [])

    if (props.display == 'ICON') {
        if (!showIcon) {
            return null;
        }
        let randomId = utilsService.guid(4);
        return (
            <>
                <span id={'id' + randomId} className='caption-1 flex align-center'>
                    <SvgCode fill="#5e6e82" name="schedule"></SvgCode>
                </span>
                <Tooltip
                    anchorSelect={'#id' + randomId}
                    content={content}
                    place="top"
                    style={{
                        backgroundColor: "#000",
                        color: "#ff",
                        borderRadius: "5px",
                        zIndex: 22,
                        fontWeight: "bold",
                    }}
                />
            </>
        )
    }

    return (
        <>
            <a
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    setInProgress(true)
                    setonetimeDateTimeError(false)
                    setEditModal(true)
                }}
                className={
                    (inProgress ? 'disabled' : '') +
                    "py-2 px-4 flex align-center no-underline edit-link-option"
                }
            >
                <SvgCode fill="#185cb9" name="schedule"></SvgCode>
                {
                    savedItem ? (
                        <span>{editScheduleText || 'Edit schedule'}</span>
                    ) : (
                        <span>Schedule</span>
                    )
                }
            </a>
            {
                editModal && (
                    <div className="app-modal">
                        <div className="app-modal__content">
                            <div className="add-page-comp">
                                <div className="add-page-comp__head justify-between align-center modal-title">
                                    <div className='flex align-center'>
                                        <div className="align-center">
                                            <h2 className="h2-heading">
                                                {savedItem ? 'Edit ' : 'New '}
                                                Schedule configuration
                                            </h2>
                                        </div>
                                    </div>
                                    <a onClick={() => {
                                        setEditModal(false)
                                        setInProgress(false)
                                        setbtnDisabled(false)
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="close-svg-icon" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                            <path d="M21.5365 19.3139C21.8316 19.609 21.9974 20.0092 21.9974 20.4265C21.9974 20.8438 21.8316 21.2441 21.5365 21.5391C21.2414 21.8342 20.8411 22 20.4238 22C20.0065 22 19.6062 21.8342 19.3111 21.5391L11 13.2259L2.68626 21.5365C2.39116 21.8316 1.99092 21.9974 1.57358 21.9974C1.15624 21.9974 0.755993 21.8316 0.46089 21.5365C0.165787 21.2414 6.21883e-09 20.8412 0 20.4239C-6.21883e-09 20.0066 0.165787 19.6064 0.46089 19.3113L8.77463 11.0007L0.463508 2.68741C0.168405 2.39233 0.00261814 1.99211 0.00261815 1.57479C0.00261815 1.15748 0.168405 0.757257 0.463508 0.462172C0.758611 0.167086 1.15886 0.00130875 1.5762 0.00130875C1.99353 0.00130874 2.39378 0.167086 2.68888 0.462172L11 8.77541L19.3137 0.460862C19.6088 0.165777 20.0091 -6.95245e-09 20.4264 0C20.8438 6.95245e-09 21.244 0.165777 21.5391 0.460862C21.8342 0.755948 22 1.15617 22 1.57348C22 1.9908 21.8342 2.39102 21.5391 2.6861L13.2254 11.0007L21.5365 19.3139Z" fill="#1B72E6" />
                                        </svg>
                                    </a>
                                </div>
                                <div style={{ 'filter': loadingData ? 'blur(2px)' : '' }}>
                                    <form className="app-form">
                                        <div className="app-form__fields modal-form-content">
                                            <div className="form-field mb-8">
                                                <label className="white-space-no-wrap mr-2 bold label mb-2">
                                                    Schedule Type
                                                </label>
                                                <Select
                                                    name="deviceName"
                                                    value={scheduleType}
                                                    options={scheduleTypeList}
                                                    onChange={eventType => setScheduleType(eventType)}
                                                    className="basic-multi-select mt-1"
                                                    classNamePrefix="select"
                                                />
                                            </div>
                                            {
                                                scheduleType.value == 'DAILY' && (
                                                    <div className="form-field mb-8">
                                                        <label className="white-space-no-wrap mr-2 bold label mb-2">
                                                            At what time on every day you want to run? (10 min intervals)
                                                        </label>
                                                        <div className='mt-2'>
                                                            <TimePicker onChange={(val) => {
                                                                setSelectedTime(val)
                                                            }} value={selectedTime}></TimePicker>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                            {
                                                scheduleType.value == 'ONE_TIME' && (
                                                    <div className={`form-field mb-8 ${onetimeDateTimeError ? 'error-field' : ''}`}>
                                                        <label className="white-space-no-wrap mr-2 bold label mb-2">
                                                            At what time you want to run one time?
                                                        </label>
                                                        <div className='mt-2'>
                                                            <DateTimePicker onChange={(val) => {
                                                                setonetimeDateTimeError(false)
                                                                setonetimeDateTime(val)
                                                            }} minDate={onetimeTillDateTime} value={onetimeDateTime}></DateTimePicker>
                                                        </div>
                                                        {
                                                            onetimeDateTimeError && (
                                                                <div className='error-message mt-1'>This date time must be required and future date time.</div>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }

                                            {
                                                scheduleType.value == 'WEEKLY' && (
                                                    <>
                                                        <div className={`form-field mb-8  ${weekdaysError ? 'error-field' : ''}`}>
                                                            <label className="white-space-no-wrap mr-2 bold label mb-2">
                                                                On which day you want to run weekly?
                                                            </label>
                                                            <div className='mt-2 flex'>
                                                                <label className='flex align-center mr-4' for="mon">
                                                                    <input onChange={handleWeekDayChange} checked={weekdays.indexOf('MON') >= 0} id="mon" type="checkbox" value="MON" />
                                                                    <span className='ml-1'>Mon</span>
                                                                </label>
                                                                <label className='flex align-center mr-4' for="tue">
                                                                    <input onChange={handleWeekDayChange} checked={weekdays.indexOf('TUE') >= 0} id="tue" type="checkbox" value="TUE" />
                                                                    <span className='ml-1'>Tue</span>
                                                                </label>
                                                                <label className='flex align-center mr-4' for="wed">
                                                                    <input onChange={handleWeekDayChange} checked={weekdays.indexOf('WED') >= 0} id="wed" type="checkbox" value="WED" />
                                                                    <span className='ml-1'>Wed</span>
                                                                </label>
                                                                <label className='flex align-center mr-4' for="thu">
                                                                    <input onChange={handleWeekDayChange} checked={weekdays.indexOf('THU') >= 0} id="thu" type="checkbox" value="THU" />
                                                                    <span className='ml-1'>Thur</span>
                                                                </label>
                                                                <label className='flex align-center mr-4' for="fri">
                                                                    <input onChange={handleWeekDayChange} checked={weekdays.indexOf('FRI') >= 0} id="fri" type="checkbox" value="FRI" />
                                                                    <span className='ml-1'>Fri</span>
                                                                </label>
                                                                <label className='flex align-center mr-4' for="sat">
                                                                    <input onChange={handleWeekDayChange} checked={weekdays.indexOf('SAT') >= 0} id="sat" type="checkbox" value="SAT" />
                                                                    <span className='ml-1'>Sat</span>
                                                                </label>
                                                                <label className='flex align-center' for="sun">
                                                                    <input onChange={handleWeekDayChange} checked={weekdays.indexOf('SUN') >= 0} id="sun" type="checkbox" value="SUN" />
                                                                    <span className='ml-1'>Sun</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="form-field mb-8">
                                                            <label className="white-space-no-wrap mr-2 bold label mb-2">
                                                                At what time on every day you want to run?
                                                            </label>
                                                            <div className='mt-2'>
                                                                <TimePicker onChange={(val) => {
                                                                    setSelectedTime(val)
                                                                }} value={selectedTime}></TimePicker>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                            {
                                                scheduleType.value != 'ONE_TIME' && (
                                                    <>
                                                        <div className={`form-field ${tillDateError ? 'error-field' : ''}`}>
                                                            <label className="white-space-no-wrap mr-2 bold label mb-2">
                                                                When you want to stop this schedule?
                                                            </label>
                                                            <div className='mt-2'>
                                                                <DatePicker onChange={(val) => {
                                                                    setRunTillDate(val)
                                                                    settillDateError(false)
                                                                }} minDate={tillDateMinDate} value={runTillDate}></DatePicker>
                                                            </div>
                                                        </div>
                                                        {
                                                            tillDateError && (
                                                                <div className='error-message mt-1'>You must select stop date.</div>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                            <div className='mb-8'>
                                                {/* {schedularText} */}
                                            </div>
                                        </div>
                                        <div className="app-form__controls align-center">
                                            <button className="btn-medium-2 btn-medium-width mr-3" type='button' onClick={handleformSubmit} disabled={btnDisabled}>Save Changes</button>
                                            {
                                                savedItem && (
                                                    <button className="btn-medium-2 btn-medium-width btn-danger" type='button' onClick={e => handleDeleteItems(e)} disabled={btnDisabled}>Delete</button>
                                                )
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                )
            }

            {
                showDeleteModal && (
                    <ConfirmModal
                        confirmationTitle={'Are you sure you want to delete schedule?'}
                        confirmButtonLabel={'Delete'}
                        onOk={() => onConfirmation()}
                        onCancel={e => setShowDeleteModal(false)}
                    />
                )
            }
        </>
    )
}