import { useState, useEffect, Fragment } from "react";
import CreatableSelect from 'react-select/creatable';
// import AutoSuggest from "../AutoSuggest/AutoSuggest";
import storeService from "../../../services/store.service";
import SvgIcon from "../SvgIcon";
import { Tooltip } from "react-tooltip";

const createOption = (label) => ({
  label,
  value: label,
});

const Collections = ({
  collectionSaved,
  data,
  regressionToAdd,
  documentId,
  records,
  // selectedRegressions,
  selectedSteps,
  pagePerformanceData,
  setUserFlowRegressionTags,
  setSelectedSteps,
  runNow,
  updateRunNow
}) => {
  const [expandMoreConfig, setexpandMoreConfig] = useState(false)
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState([]);
  const [selectedTags, setSelectedTags] = useState([])
  const [stepTimeout, setstepTimeout] = useState(null)
  const [enforceStepTimeout, setenforceStepTimeout] = useState(false)
  const [allTags, setAllTags] = useState(storeService.store.WORKFLOWS_WITH_DETAILS.allowedTags.map(item => {
    return {
      label: item,
      value: item
    }
  }) || [])
  return (
    <div className={`collections ${collectionSaved ? 'collections-empty' : ''}`}>
      {!collectionSaved ? (
        <Fragment>
          <div className="collections-list w-full pt-5">
            <div className="align-center justify-between">
              <h4 className="collections-list-title">Regression to be added</h4>
            </div>
            <ul className="collections-list-items no-disc mt-2">
              {selectedSteps.map((step, i) => {
                return <li className="mb-3" key={i}>
                  <input value={step.regressionName} type="text" className="input-txt" onChange={e => setSelectedSteps && setSelectedSteps([
                    ...selectedSteps.slice(0, i),
                    { ...selectedSteps[i], regressionName: e.target.value },
                    ...selectedSteps.slice(i + 1)
                  ])} />
                </li>
              })}
            </ul>
            <div className="form-field-select mb-2">
              <label className="label mb-2">Create or Select a Tag</label>
              <div className="align-center mb-2">
                <div className="form-field">
                  <CreatableSelect
                    inputValue={inputValue}
                    isClearable
                    isMulti
                    onChange={(tagValues) => {
                      let uList = tagValues.map((item) => item.value);
                      setSelectedTags(uList)
                      setValue(tagValues);
                      setUserFlowRegressionTags(uList)
                    }}
                    onBlur={(e) => {
                      const currentTargetVal = e.currentTarget.value;
                      if (currentTargetVal) {
                        let tagText = currentTargetVal;
                        tagText = tagText.replace(/[^a-zA-Z0-9 ]/g, '')
                        setValue((prev) => [...prev, createOption(tagText)]);
                        setInputValue('');
                        let uList = [...selectedTags, tagText];
                        setSelectedTags(uList)
                        setUserFlowRegressionTags(uList)
                      }
                    }}
                    onInputChange={(newValue) => {
                      setInputValue(newValue.replace(/[^a-zA-Z0-9 ]/g, ''))
                    }}
                    onKeyDown={event => {
                      if (!inputValue) return;
                      switch (event.key) {
                        case 'Enter':
                        case 'Tab':
                          let tagText = inputValue;
                          tagText = tagText.replace(/[^a-zA-Z0-9 ]/g, '')
                          setValue((prev) => [...prev, createOption(tagText)]);
                          setInputValue('');
                          let uList = [...selectedTags, tagText];
                          setSelectedTags(uList)
                          setUserFlowRegressionTags(uList)
                          event.preventDefault();
                      }
                    }}
                    placeholder="Create or Select a Tag and press enter..."
                    value={value}
                    defaultInputValue={[]}
                    options={allTags}
                  />
                </div>
              </div>
            </div>

            <div className="p-2 flex">
              <label
                htmlFor="runTestsNow"
                className="form-field-checkbox"
              >
                <input
                  id="runTestsNow"
                  onChange={(e) => {
                    updateRunNow(e.target.checked)
                  }}
                  checked={runNow}
                  type="checkbox"
                />
                <span className="checkmark"></span>
              </label>
              <label htmlFor="runTestsNow" className="ml-2">
                Run tests now
              </label>
            </div>
          </div>
          <div className="more-details">
            <div className="flex w-100">
              <div className='pointer'>
                <a onClick={e => {
                  e.preventDefault();
                  setexpandMoreConfig(!expandMoreConfig)
                }} className='flex mt-3 no-underline edit-link-option align-center'>
                  <span className="mr-1"> Advanced config (for all steps)</span>
                  {expandMoreConfig ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" className="svg-icon"
                      viewBox="0 0 18 12" fill="none">
                      <path
                        d="M0.34627 9.08042L8.16974 1.25695C8.27876 1.14755 8.40831 1.06074 8.55095 1.00151C8.69359 0.942283 8.84653 0.911793 9.00098 0.911793C9.15543 0.911793 9.30836 0.942283 9.45101 1.00151C9.59365 1.06074 9.7232 1.14755 9.83222 1.25695L17.6557 9.08042C17.8761 9.30088 18 9.59989 18 9.91166C18 10.2234 17.8761 10.5224 17.6557 10.7429C17.4352 10.9634 17.1362 11.0872 16.8244 11.0872C16.5127 11.0872 16.2137 10.9634 15.9932 10.7429L9 3.7497L2.0068 10.7439C1.78634 10.9643 1.48734 11.0882 1.17556 11.0882C0.863781 11.0882 0.564774 10.9643 0.344315 10.7439C0.123856 10.5234 7.5514e-08 10.2244 1.0277e-07 9.91264C1.30027e-07 9.60086 0.123856 9.30185 0.344315 9.08139L0.34627 9.08042Z"
                        fill="#1B72E6" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9"
                      viewBox="0 0 18 12" fill="none" className="svg-icon">
                      <path
                        d="M17.6537 2.91958L9.83027 10.743C9.72124 10.8524 9.59169 10.9393 9.44905 10.9985C9.30641 11.0577 9.15347 11.0882 8.99902 11.0882C8.84457 11.0882 8.69164 11.0577 8.54899 10.9985C8.40635 10.9393 8.2768 10.8524 8.16778 10.743L0.344312 2.91958C0.123853 2.69912 -3.2851e-09 2.40011 0 2.08834C3.2851e-09 1.77656 0.123853 1.47755 0.344312 1.25709C0.564772 1.03663 0.863779 0.912782 1.17556 0.912782C1.48733 0.912782 1.78634 1.03663 2.0068 1.25709L9 8.25029L15.9932 1.25612C16.2137 1.03566 16.5127 0.911804 16.8244 0.911804C17.1362 0.911804 17.4352 1.03566 17.6557 1.25612C17.8761 1.47658 18 1.77558 18 2.08736C18 2.39914 17.8761 2.69814 17.6557 2.9186L17.6537 2.91958Z"
                        fill="#1B72E6" />
                    </svg>
                  )}
                </a>
              </div>
            </div>
            {
              expandMoreConfig && (
                <>
                  <div className="py-2 flex mt-2">
                    <label htmlFor={"enforceStepTimeout"} className="form-field-checkbox">
                      <input id={"enforceStepTimeout"} type="checkbox" checked={enforceStepTimeout} onChange={e => {
                        setenforceStepTimeout(e.target.checked)
                        setSelectedSteps([...selectedSteps.map(flow => {
                          if (flow.steps) {
                            flow.steps.forEach(step => {
                              step['enforceStepTimeout'] = e.target.checked;
                            })
                          }
                          return flow;
                        })])
                      }} />
                      <span className="checkmark"></span>
                    </label>
                    <label htmlFor={"enforceStepTimeout"} className="ml-2">
                      Enforce step timeout (helpful in case of partial load in page)
                    </label>
                  </div>
                  <div className="form-field mt-2">
                    <div className="flex">
                      <label className="white-space-no-wrap mr-2 bold mb-2 label flex align-center" id="timed-catpture-id">
                        <span className="mr-1">Timed Screenshot Capture (in sec)</span>
                        <SvgIcon path="/icons/info-svg.svg"></SvgIcon>
                        <Tooltip
                          anchorSelect={'#timed-catpture-id'}
                          content={'Delays the screenshot by the configured timeout duration.'}
                          place="left-start"
                          style={{
                            backgroundColor: "#000",
                            color: "#fff",
                            borderRadius: "5px",
                            zIndex: 22,
                            fontWeight: "bold",
                          }}
                        />
                      </label>
                    </div>
                    <input
                      type="text"
                      value={stepTimeout}
                      className="input-field w-full p-2 input-txt"
                      onChange={(event) => {
                        let val = (event.target.value || '').replace(new RegExp('[^0-9]', 'g'), '')
                        setstepTimeout(val)
                        setSelectedSteps([...selectedSteps.map(flow => {
                          if (flow.steps) {
                            flow.steps.forEach(step => {
                              step['stepTimeout'] = val;
                            })
                          }
                          return flow;
                        })])

                      }}
                    />
                  </div>
                </>
              )
            }
          </div>
        </Fragment>
      ) : (
        <p className="text-center h3-heading">Collection saved successfully</p>
      )}
    </div>
  );
};

export default Collections;
