import React, { useEffect, useState } from "react";
import storeService from "../../../services/store.service";
import SectionLoader from "../SectionLoader";
import dataService from "../../../services/data.service";
import workflowsService from "../../../services/workflows.service";
import { Tooltip } from 'react-tooltip';
import { ChevronIcon } from "../ChevronIcon/ChevronIcon";
import SvgIcon from '../../atoms/SvgIcon.jsx';

const titleDescription = {
  'Top Actions': 'Actions users performed most frequently on top pages.',
  'Top Pages': ' Pages with the highest number of visits.',
  'Top Page Journeys': 'Visualize user paths through pages in a tree structure.',
  'User Flow Tree': 'Visualize user paths through pages in a tree structure.',
  'Top Journey': 'Most common sequences of page visits (2-10 steps).',
  'Top Entry Pages': 'Pages where users begin their journeys.',
  'Top Exit Pages': 'Pages where most users end their journeys.',
  'Bottom Pages': 'Pages with the fewest visits.',
  'Slowest Pages': 'Pages with the longest load times.',
  'Shifted Pages': 'Pages with 20% change in activity compared to the previous period.',
  'Top Actioned Journey': 'Common page journeys, including actions taken along the way.'
}

const titleMapper = {
  'Top Page Journeys': 'User Flow Tree'
}
const Recommendations = ({ onCategorySelect, data: usageDataItem, selectedCategory, onDataLoaded }) => {

  const recommendations = ['top_actions', 'top_pages', 'top_journey', 'top_entry_pages', 'top_exit_pages', 'devices', 'bottom_pages', 'slowest_pages', 'shifted_pages'];
  if (storeService.store.FEATURE_FLAGS.itemMap.has('ACTIONED_CARD')) {
    if (storeService.store.FEATURE_FLAGS.itemMap.get('ACTIONED_CARD').value) {
      recommendations.push('top_actioned_journey')
    }
  }

  const [cardLoading, setcardLoading] = useState(true)
  const [cardTreeData, setcardTreeData] = useState(null)

  const [isLoading, setIsLoading] = useState(false)
  const [loadingText, setloadingText] = useState('Cards are being generated, might take few more seconds.')
  const [data, setData] = useState(usageDataItem)

  const handleGenerateTreeData = () => {
    if (usageDataItem) {
      setcardLoading(true)
      let payload = {
        startDate: usageDataItem.start_date,
        endDate: usageDataItem.end_date,
        label: usageDataItem.label,
        siteId: usageDataItem.siteId,
        tenantId: usageDataItem.tenantId,
        recommendationId: usageDataItem._id
      }
      workflowsService.generaterecommendation(payload).then(firstThreePaths => {
        if (!firstThreePaths) {
          setcardLoading(false)
          setcardTreeData(null)
        } else {
          setcardTreeData(firstThreePaths)
        }
      })
    }
  }
  useEffect(() => {
    let dataMap = storeService.store.USAGE_DATA_MAP.dataMap;
    if (usageDataItem.dataVersion) {
      if (!dataMap[data._id]) {
        setIsLoading(true)
        workflowsService.getrecommendationcardsummary({
          recommendationId: data._id
        }).then(apiResp => {
          if (apiResp?.data?.length > 0) {
            let recommendationSummary = apiResp.data[0];
            if (recommendationSummary && recommendations.some(x => recommendationSummary[x])) {
              storeService.store.USAGE_DATA_MAP.dataMap[data._id] = recommendationSummary;
              setData(dataMap[data._id]);
              onDataLoaded(dataMap[data._id]);
              setloadingText(null)
            }
          }
        }).finally(() => {
          setIsLoading(false)
        })
      } else {
        setData(dataMap[data._id]);
        onDataLoaded(dataMap[data._id]);
        setloadingText(null)
        setIsLoading(false)
      }
    } else {
      if (!dataMap[data._id]) {
        setIsLoading(true)
        storeService.subscribe('USAGE_DATA_MAP', ({ detail }) => {
          if (detail.dataMap && detail.dataMap[data._id]) {
            setData(detail.dataMap[data._id]);
            onDataLoaded(detail.dataMap[data._id]);
            setIsLoading(false);
          }
        });
        dataService.getusagedatabyid({ _id: data._id });
      } else {
        setData(dataMap[data._id]);
        onDataLoaded(dataMap[data._id]);
      }
    }
    workflowsService.loadrecommendation({ _id: data._id }).then((firstThreePaths) => {
      if (!firstThreePaths) {
        setcardLoading(false)
        setcardTreeData(null)
      } else {
        setcardTreeData(firstThreePaths)
      }
    })
  }, [])

  return (
    <div className={`recommendations-tiles p-6 pos-rel ` + ((isLoading || usageDataItem.status == 'FAILED') ? '' : ' grid grid-cols-3 gap-6 ')}>
      {
        usageDataItem.dataVersion && loadingText && (
          <div>{loadingText}</div>
        )
      }
      {
        usageDataItem.status == 'FAILED' && (
          <>
            <div className="font-danger">Failed to Generate cards.</div>
            {
              usageDataItem.failedReason && (
                <div className="font-danger mt-2">{usageDataItem.failedReason}</div>
              )
            }
          </>
        )
      }

      {
        isLoading && (
          <SectionLoader></SectionLoader>
        )
      }
      {usageDataItem.status != 'FAILED' && recommendations.map((type, index) => {
        const recommendation = data[type];
        if (!recommendation) return;
        if (type == 'top_actioned_journey') {
          recommendation.category = 'actioned';
        }
        const title = recommendation.title;
        return (
          <div key={`${title}_${index}`} className="recommendations-tiles-desc relative">
            <div className="pointer" id={type}>
              <h4 className="sub-heading-1 recommendations-tiles-title">
                <span className="mr-2">{titleMapper[title] || title}</span>
                <SvgIcon path="/icons/info-svg.svg"></SvgIcon>
              </h4>
              <Tooltip
                anchorSelect={'#' + type}
                content={titleDescription[title]}
                place="top-start"
                style={{
                  backgroundColor: "#000",
                  color: "#fff",
                  borderRadius: "5px",
                  zIndex: 22,
                  fontWeight: "bold",
                }}
              />
            </div>
            <div className="recommendations-tiles-desc-items">
              {recommendation.data?.filter((x, i) => i <= 2).map((data, i) => {
                const name = data.pageName || data?.page_name || data?.name || data?.journey_flow;
                let popupName = (data?.journey_flow_white || name);
                if (type != 'top_journey' && type != 'top_actioned_journey') {
                  popupName += ((data.actionCount || data.no_of_users) ? ` (${data.actionCount || data.no_of_users})` : '');
                }
                return (
                  <>
                    <div id={type + '-' + i} className="body-3 recommendation-flex" key={`${name}-${i}`}>
                      <span className="recommendation-name">{name}</span>
                      <span>{(data.actionCount || data.no_of_users) ? `(${data.actionCount || data.no_of_users})` : null}</span>
                    </div>
                    <Tooltip
                      anchorSelect={'#' + type + '-' + i}
                      content={popupName}
                      place="top"
                      style={{
                        backgroundColor: "#000",
                        color: "#ff",
                        borderRadius: "5px",
                        zIndex: 22,
                        fontWeight: "bold",
                      }}
                    />
                  </>
                )
              })}
            </div>
            <div className="recommendations-tiles-desc-select">
              <div className="absolute">
                <label htmlFor={`${recommendation.category}_${index}`} className="form-field-radio button-small d-flex align-center">
                  <span class="mr-2">Select</span>
                  <input
                    type="radio"
                    id={`${recommendation.category}_${index}`}
                    name='category'
                    value={`${recommendation.category}_${index}`}
                    onChange={() => onCategorySelect({
                      type: type,
                      value: `${recommendation.category}_${index}`
                    }, title)}
                    checked={selectedCategory === `${recommendation.category}_${index}`}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        );
      })}

      <div key={`top-page-tree_0`} className="recommendations-tiles-desc relative">
        <h4 className="sub-heading-1 recommendations-tiles-title">Top Page Journeys</h4>
        <div className="recommendations-tiles-desc-items">
          {
            cardTreeData ? (
              <>
                {
                  cardTreeData.filter((x, i) => i <= 2).map((pathArr, i) => {
                    let pathLen = pathArr.length;
                    let name = [], tooltipContent = [];
                    pathArr.forEach((node, ni) => {
                      name.push(
                        <React.Fragment key={'re-' + i}>
                          {node.pagename} {node.count != null ? `(${node.count})` : ''}
                          {
                            ni < pathLen - 1 ? (
                              <span className="mx-2">
                                <ChevronIcon type="right" variant="small" />
                              </span>
                            ) : null
                          }
                        </React.Fragment>
                      );
                      tooltipContent.push(
                        <React.Fragment key={'re-' + i}>
                          {node.pagename} {node.count != null ? `(${node.count})` : ''}
                          {
                            ni < pathLen - 1 ? (
                              <span className="mx-2">
                                <ChevronIcon type="right-white" variant="small" />
                              </span>
                            ) : null
                          }
                        </React.Fragment>
                      );

                    });
                    return (
                      <>
                        <div id={'card-item-' + i} className="body-3 recommendation-flex" key={`card-item-${i}`}>
                          <span className="recommendation-name">{name}</span>
                        </div>
                        <Tooltip
                          anchorSelect={'#card-item-' + i}
                          content={tooltipContent}
                          place="top"
                          style={{
                            backgroundColor: "#000",
                            color: "#ff",
                            borderRadius: "5px",
                            zIndex: 22,
                            fontWeight: "bold",
                            width: '100%',
                            'text-overflow': 'ellipsis'
                          }}
                        />
                      </>
                    )
                  })
                }
              </>
            ) : (
              <div className="flex align-center justify-center mt-4">
                <button onClick={handleGenerateTreeData} disabled={cardLoading} type="button" className="btn-medium-2 btn-medium-width">
                  Generate tree data
                </button>
              </div>
            )
          }
        </div>
        {
          cardTreeData && (
            <div className="recommendations-tiles-desc-select">
              <div className="absolute">
                <label htmlFor={`treeview_0`} className="form-field-radio button-small d-flex align-center">
                  <span class="mr-2">Select</span>
                  <input
                    type="radio"
                    id={`treeview_0`}
                    name='category'
                    value={`treeview_0`}
                    onChange={() => onCategorySelect({
                      type: 'top_page_tree',
                      value: `treeview_0`
                    }, 'Top Page Journeys')}
                    checked={selectedCategory === `treeview_0`}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default Recommendations;
