import apiService from './api.service.js'
import utils from './utils.service.js'
import storeService from './store.service.js';
export default {
    async gettenants(opts = {}) {
        let find = opts.find || {};
        let options = {
            "sort": {
                "createdAt": -1
            }
        };
        if (opts.limit) {
            options['limit'] = opts.limit;
        }
        if (opts.skip) {
            options['skip'] = opts.skip;
        }
        return await apiService.post({
            url: utils.END_POINTS.gettenants,
            data: {
                "find": find,
                "options": options
            }
        });
    },
    async loadTenants(opts = {}) {
        return this.gettenants(opts).then(apiResp => {
            if (apiResp && apiResp.data) {
                storeService.saveStore({
                    items: (apiResp.data?.items || []).map(x => {
                        x['loadedGuid'] = utils.guid(8);
                        return x;
                    }),
                    totalCount: apiResp.data?.totalCount || 0,
                    lastLoaded: new Date(),
                    loaded: true,
                    isLoading: false,
                }, 'TENANTS');
            }
            return apiResp;
        })
    },
    async addtenants(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.addtenants,
            data: payload
        });
        return apiResp;
    },
    async updatetenant(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.updatetenant,
            data: payload
        });
        if (apiResp && apiResp.data && apiResp.data.length > 0) {
            let updatedTenant = apiResp.data[0];
            let items = storeService.store.TENANTS.items;
            items.forEach(item => {
                if (item._id == updatedTenant._id) {
                    item.loadedGuid = utils.guid(8);
                    item.environments = updatedTenant.environments;
                }
            })
            storeService.saveStore({
                items: [...items],
                totalCount: apiResp.data?.totalCount || 0,
                lastLoaded: new Date(),
                loaded: true,
                isLoading: false,
            }, 'TENANTS');
        }
        return apiResp;
    },
    async deletetenant(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.deletetenant,
            data: payload
        });
        if (apiResp && apiResp.data && apiResp.data.length > 0) {
            let items = storeService.store.TENANTS.items;
            items.forEach(item => {
                item.loadedGuid = utils.guid(8);
            })
            storeService.saveStore({
                items: [...items],
                totalCount: apiResp.data?.totalCount || 0,
                lastLoaded: new Date(),
                loaded: true,
                isLoading: false,
            }, 'TENANTS');
        }
        return apiResp;
    }
}