import apiService from './api.service.js'
import storeService from './store.service.js';
import utils from './utils.service.js'
export default {
    async addscheduleservice(payload) {
        return await apiService.post({
            url: utils.END_POINTS.addschedulejob,
            data: payload
        });
    },
    async loadschedulejobs(payload) {
        payload['selectedEnvId'] = storeService.store.ENVIRONMENTS.selectedEnvId;
        let getResp = await apiService.post({
            url: utils.END_POINTS.getschedulejobs,
            data: payload
        });
        const { data = [] } = getResp;
        return data;
    },
    async getschedulejobsbypage(payload) {
        payload['selectedEnvId'] = storeService.store.ENVIRONMENTS.selectedEnvId;
        let getResp = await apiService.post({
            url: utils.END_POINTS.getschedulejobsbypage,
            data: payload
        });
        const { data = [] } = getResp;
        return data;
    },
    async updateschedulejob(payload) {
        return await apiService.post({
            url: utils.END_POINTS.updateschedulejob,
            data: payload
        });
    },
    async deleteschedulejob(payload) {
        return await apiService.post({
            url: utils.END_POINTS.deleteschedulejobs,
            data: payload
        });
    },

    async getnotificationschedules(payload) {
        return await apiService.post({
            url: utils.END_POINTS.getnotificationschedules,
            data: payload
        });
    },
    async addnotificationschedule(payload) {
        return await apiService.post({
            url: utils.END_POINTS.addnotificationschedule,
            data: payload
        });
    },
    async updatesemailschedule(payload) {
        return await apiService.post({
            url: utils.END_POINTS.updatesemailschedule,
            data: payload
        });
    },
    async deleteemailschedule(payload) {
        return await apiService.post({
            url: utils.END_POINTS.deleteemailschedule,
            data: payload
        });
    }
}