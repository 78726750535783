import { useState } from 'react'
import AddTenant from "./AddTenant.jsx";
import TenantList from "./TenantList.jsx";
import AddEditEnvironment from './AddEditEnvironment.jsx';
import ConfirmModal from '../../comps/atoms/ConfirmModal.jsx';
import storeService from '../../services/store.service.js';
import tenantsService from '../../services/tenants.service.js';
import utilsService from '../../services/utils.service.js';
export default () => {
    const [showEnvironmentModal, setshowEnvironmentModal] = useState(false)
    const [selectedTenant, setSelectedTenant] = useState(null)
    const [selectedEnv, setselectedEnv] = useState(null)
    const [showConfirm, setShowConfirm] = useState(false);
    const openAddEditEnvironment = (tenant, selectedEnv) => {
        setSelectedTenant(tenant)
        setshowEnvironmentModal(true)
        setselectedEnv(selectedEnv)
    }
    const openDeleteEnvironment = (tenant, selectedEnv) => {
        setSelectedTenant(tenant)
        setselectedEnv(selectedEnv)
        setShowConfirm(true)
    }
    const handleDelete = async () => {
        if(selectedTenant && selectedEnv) {
            if (selectedTenant.environments) {
                let environmentsToUpdate = [];
                let items = storeService.store.TENANTS.items;
                items.forEach(item => {
                    if(item._id == selectedTenant._id) {
                        let updatedList = [];
                        item.environments.forEach(env => {
                            if(env._id != selectedEnv._id) {
                                updatedList.push(env)
                            }
                        });
                        item.environments = updatedList;
                        environmentsToUpdate = updatedList;
                    }
                })
                await tenantsService.updatetenant({
                    _id: selectedTenant._id,
                    fieldsToUpdate: {
                        environments: environmentsToUpdate
                    }
                });
                storeService.saveStore({
                    items: [...items]
                }, 'TENANTS');
            }
        }
        setShowConfirm(false);
    }
    const handleEditTenant = (item) => {
        storeService.pub(utilsService.CONSTANTS.EVENT_NAME.EDIT_TENANT, item);
    }
    return (
        <>
            <h2 className="mb-4">Tenants</h2>
            <AddTenant></AddTenant>
            <div className="mt-4">
                <TenantList openDeleteEnvironment={openDeleteEnvironment}
                    handleEditTenant={handleEditTenant}
                    openAddEditEnvironment={openAddEditEnvironment}></TenantList>
            </div>
            {
                showEnvironmentModal && (
                    <AddEditEnvironment 
                        selectedEnv={selectedEnv}
                        tenant={selectedTenant}
                        closeModal={e => {
                        setshowEnvironmentModal(false)
                    }}></AddEditEnvironment>
                )
            }
            {
                showConfirm && (
                    <ConfirmModal 
                        confirmText={'Do you want to delete the item?'}
                        onDelete={handleDelete} 
                        onClose={() => setShowConfirm(false)}></ConfirmModal>
                )
            }
        </>
    )
}