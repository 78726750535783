import { useState } from "react"
export default ({ showCancel = true, onCancel, onOk, confirmationTitle, confirmationMessage, confirmButtonLabel, confirmBtnClass }) => {
    const [disabled, setDisabled] = useState(false)
    return (
        <>
            <div className="app-modal">
                <div className="app-modal__content auto-width">
                    <div className="p-10">
                        <div className="mb-5">
                            <h4 className="h3-heading mb-4">{confirmationTitle}</h4>
                            {confirmationMessage && <p className="sub-heading-2">{confirmationMessage}</p>}
                        </div>
                        <div className="flex justify-end">
                            {
                                showCancel && (
                                    <button disabled={disabled} onClick={e => {
                                        setDisabled(true)
                                        onCancel()
                                    }} className="btn-medium-2 mr-4 btn-secondary">Cancel</button>
                                ) 
                            }
                           
                            <button disabled={disabled} onClick={e => {
                                setDisabled(true)
                                onOk(e)
                            }} className={`${confirmBtnClass ? confirmBtnClass : 'btn-medium-2'}`}>
                               {confirmButtonLabel}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}