import utilsService from "./utils.service";
import featureflags from "../helpers/featureflags";
const store = {
    'GLOBAL': {
        showAppLoader: false,
        appLoaderMsg: 'Processing...',
        schedularInterval: 10
    },
    'CURRENT_PAGE': {
        name: ''
    },
    'DEVICES': {
        items: [],
        lastLoaded: null,
        isLoading: false,
        loaded: false
    },
    'PAGES_WITH_DETAILS': {
        items: [],
        totalCount: 0,
        lastLoaded: null,
        isLoading: false,
        loaded: false,
        filterData: {
            searchText: '',
            selectedTags: [],
            selectedDevices: [],
            byScheduled: null,
            jobStatus: [],
            selectedDate: null
        }
    },
    'PAGE_JOBS': {
        items: [],
        lastLoaded: null,
        isLoading: false,
        loaded: false
    },
    'PAGE_COMPARE_JOBS': {
        items: [],
        lastLoaded: null,
        isLoading: false,
        loaded: false
    },
    'COLLECTIONS_WITH_DETAILS': {
        items: [],
        lastLoaded: null,
        isLoading: false,
        totalCount: 0,
        loaded: false,
        filterData: {
            searchText: '',
            selectedTags: [],
            selectedType: null,
            byScheduled: null,
            jobStatus: [],
            selectedDate: null
        }
    },
    'API_TEST_RESULTS': {
        items: [],
        lastLoaded: null,
        isLoading: false,
        loaded: false
    },
    'API_TEST_DATA': {
        items: [],
        lastLoaded: null,
        isLoading: false,
        loaded: false
    },
    'WORKFLOWS_WITH_DETAILS': {
        items: [],
        lastLoaded: null,
        isLoading: false,
        loaded: false,
        allowedTags: [],
        filterData: {
            searchText: '',
            selectedTags: [],
            selectedDevices: [],
            byScheduled: null,
            jobStatus: [],
            selectedDate: null
        },
    },
    'WORKFLOW_FAILED_JOBS': {
        itemsMap: new Map()
    },
    'WORKFLOWS_JOBS': {
        items: [],
        lastLoaded: null,
        isLoading: false,
        loaded: false
    },
    'USAGE_DATA_MAP': {
        dataMap: {},
        lastLoaded: null,
        isLoading: false,
        loaded: false,
        topPageJourneyDataMap: {},
    },
    "PAGE_HASH": {
        itemsMap: {}
    },
    "PAGE_JOBS_HASH": {
        itemsMap: {}
    },
    "PAGE_COMPARE_JOBS_HASH": {
        itemsMap: {}
    },
    "COLLECTIONS_HASH": {
        itemsMap: {}
    },
    "WORKFLOWS_JOBS_HASH": {
        itemsMap: {}
    },
    "TENANTS": {
        items: [],
        lastLoaded: null,
        isLoading: false,
        loaded: false
    },
    "ENVIRONMENTS": {
        items: [],
        selectedTenantEnv: "default||development",
        selectedEnvID: null,
        lastLoaded: null,
        isLoading: false,
        loaded: false
    },
    "WORKFLOW_DEVICES": {
        items: [
            {
                "_id": "2b3a694fdb0b5fc7dbfc95a318d4cac0169",
                "name": "Large Desktop (V)",
                "dimensions": {
                    "width": 1440,
                    "height": 1080
                },
                "deviceMode": "VIRTUAL",
                "deviceGlass": "DESKTOP"
            },
            {
                "_id": "35f84a7cedb7814c92d0a9ba18d4cac0169",
                "name": "Desktop (V)",
                "dimensions": {
                    "width": 1280,
                    "height": 1080
                },
                "deviceMode": "VIRTUAL",
                "deviceGlass": "DESKTOP"
            },
            {
                "_id": "55b28bfb2de923196bda74fa18d4cac0169",
                "name": "Tablet (V)",
                "dimensions": {
                    "width": 1024,
                    "height": 768
                },
                "deviceMode": "VIRTUAL",
                "deviceGlass": "MOBILE"
            },
            {
                "_id": "6e6983145bd358daffc53b6e18d4cac0169",
                "name": "Mobile (V)",
                "dimensions": {
                    "width": 360,
                    "height": 640
                },
                "deviceMode": "VIRTUAL",
                "deviceGlass": "MOBILE"
            }
        ]
    },
    'SCHEDULED_JOB_LIST': {
        byPageIdHash: new Map(),
        byWorkflowIdHash: new Map(),
        byCollectionIdHash: new Map()
    },
    'FEATURE_FLAGS': {
        itemMap: new Map(featureflags.featuresList()),
        sections: new Set(featureflags.sections),
        flagsBySection: new Map(),
        lastLoaded: null,
        isLoading: false,
        loaded: false
    },
    PAGES_METADATA: {
        tags: [],
        tagsLoadeAt: null
    },
    EMAILNOTIFICATIONS: {
        items: [],
        itemIds: new Set()
    }
};
window.store = store;
let storeElem = document.createElement('div');
const storeService = {
    saveStore(dataToSave, moduleName = 'GLOBAL', dispatchEvent = true) {
        store[moduleName] = Object.assign({}, store[moduleName], dataToSave);
        if (dispatchEvent) {
            storeElem.dispatchEvent(new CustomEvent(moduleName, { detail: store[moduleName] }));
        }
        if (moduleName == "PAGE_JOBS" && dataToSave.items) {
            dataToSave.items.forEach(item => {
                store['PAGE_JOBS_HASH'].itemsMap['ID_' + item._id] = item;
            });
        }
        if (moduleName == "PAGE_COMPARE_JOBS" && dataToSave.items) {
            dataToSave.items.forEach(item => {
                store['PAGE_COMPARE_JOBS_HASH'].itemsMap['ID_' + item._id] = item;
            });
        }
        if (moduleName == "COLLECTIONS_WITH_DETAILS" && dataToSave.items) {
            dataToSave.items.forEach(item => {
                store['COLLECTIONS_HASH'].itemsMap['ID_' + item._id] = item;
            });
        }
        if (moduleName == "WORKFLOWS_JOBS" && dataToSave.items) {
            dataToSave.items.forEach(item => {
                store['WORKFLOWS_JOBS_HASH'].itemsMap['ID_' + item._id] = item;
            });
        }
        // console.group('====== STORE UPDATE EVENT ======')
        // utilsService.log({}, {}, store)
        // console.groupEnd('====== STORE UPDATE EVENT ======')
    },
    subscribe(moduleName = 'GLOBAL', eventFn) {
        storeElem.addEventListener(moduleName, eventFn);
        return store[moduleName];
    },
    getStore(moduleName = 'GLOBAL') {
        return store[moduleName];
    },
    sub(eventName, eventFn) {
        storeElem.addEventListener(eventName, eventFn);
    },
    pub(eventName, data) {
        storeElem.dispatchEvent(new CustomEvent(eventName, { detail: data }));
    },
    store
}
export default storeService;