import { useState, useEffect } from "react";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import storeService from "../../services/store.service";
import dataService from "../../services/data.service";
import collectionsService from "../../services/collections.service";
const createOption = (label) => ({
    label,
    value: label,
});
export default () => {
    const navigate = useNavigate();
    const [testdataList, setTestdataList] = useState([])
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [formData, setFormData] = useState({
        name: {
            showError: false,
            value: ''
        },
        functionalRequirements: {
            showError: false,
            value: ''
        },
        apiDefinition: {
            showError: false,
            inValidFormat: false,
            value: ''
        },
        testDataId: {
            showError: false,
            value: ''
        },
        selectedTags: {
            showError: false,
            value: ''
        },
        isFormError: false,
        apiDataType: {
            label: 'JSON',
            value: 'JSON',
            showError: false
        }
    })
    const [isDataOnlyChecked, setIsdataOnlyChecked] = useState(false)
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedTestData, setSelectedTestData] = useState(null)
    const [allTags, setAllTags] = useState([])
    const handleInputChange = (value, key) => {
        setFormData({
            ...formData,
            [key]: {
                ...formData[key],
                value,
                ...(key === 'apiDefinition' ? {
                    inValidFormat: value ? !isValidJSON(value) : false,
                    showError: value ? !isValidJSON(value) : false
                } : {})
            }
        })
    }
    const handleformSubmit = async (e) => {
        e.preventDefault();
    }
    const isValidJSON = str => {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    };
    const validateForm = () => {
        let isInValid = 0,
            updatedFormData = formData;
        if (formData.name.value === '') {
            updatedFormData = {
                ...updatedFormData,
                name: {
                    ...updatedFormData.name,
                    showError: true
                },
                isFormError: true
            }
            isInValid++;
        }
        if (formData.functionalRequirements.value === '') {
            updatedFormData = {
                ...updatedFormData,
                functionalRequirements: {
                    ...updatedFormData.functionalRequirements,
                    showError: true
                },
                isFormError: true
            }
            isInValid++;
        }
        if (formData.apiDefinition.value === '' || formData.apiDefinition.inValidFormat) {
            updatedFormData = {
                ...updatedFormData,
                apiDefinition: {
                    ...updatedFormData.apiDefinition,
                    showError: true,
                    inValidFormat: formData.apiDefinition.inValidFormat
                },
                isFormError: true
            }
            isInValid++;
        }
        if (formData.testDataId.value === '') {
            updatedFormData = {
                ...updatedFormData,
                testDataId: {
                    ...updatedFormData.testDataId,
                    showError: true
                },
                isFormError: true
            }
            isInValid++;
        }
        if (isDataOnlyChecked && !selectedTestData) {
            isInValid++;
        }
        setFormData(updatedFormData)
        return isInValid == 0;
    }
    const handleFormClick = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            let collectionToAdd = {
                name: formData.name.value,
                functionalRequirements: formData.functionalRequirements.value,
                apiDefinition: formData.apiDefinition.value,
                testDataId: (selectedTestData && selectedTestData.value) || '',
                tags: selectedTags.map(x => x.value).join(',') || '',
                createtestdatacasesonly: isDataOnlyChecked ? 1 : 0,
                apiDataType: formData.apiDataType.value
            }
            await dataService.addCollections({
                "items": [
                    collectionToAdd
                ]
            });
            await dataService.getCollectionsWithDetails({ invokedBy: 'create collection line 146' })
            navigate('/api-regression')
            setLoading(false);
        }
    }
    const addTags = (e) => {
        e.preventDefault()
    }
    useEffect(() => {
        let pageStore = storeService.getStore('API_TEST_DATA');
        if (!pageStore.loaded) {
            storeService.subscribe('API_TEST_DATA', ({ detail }) => {
                setTestdataList(detail.items);
            });
            dataService.getApiTestData();
        } else {
            setTestdataList(pageStore.items);
        }
        collectionsService.loadmetaData().then(apiResp => {
            const { data = {} } = apiResp;
            if (data) {
                const { tags = [] } = data;
                setAllTags(tags.map(item => {
                    return {
                        label: item,
                        value: item
                    }
                }))
            }
        })
        storeService.saveStore({
            name: 'Create Collection',
            apiType: null
        }, 'CURRENT_PAGE');
    }, [])
    const handleCancel = (e) => {
        e.preventDefault();
        navigate('/api-regression')
    }
    const handleCreateTag = (tagName) => {
        setSelectedTags([...selectedTags, { value: tagName, label: tagName }])
    }
    return (
        <>
            <div className="section-box p-6 rounded-l">
                <div className="w-2/3 mx-auto">
                    <form className="app-form" onSubmit={handleformSubmit}>
                        <div className="app-form__fields px-4">
                            <div className="form-field">
                                <label className="label mb-1">Collection Name</label>
                                <input value={formData.name.value} onChange={e => handleInputChange(e.target.value, 'name')} className={`input-txt ${formData.name.showError && !formData.name.value ? 'invalid' : ''}`} type="text" />
                                {
                                    formData.name.showError && !formData.name.value && (
                                        <p className="error-message mt-1">This field is required.</p>
                                    )
                                }
                            </div>
                            <div className="form-field mt-3">
                                <label className="label mb-1">Functional Requirement</label>
                                <textarea rows="8" value={formData.functionalRequirements.value} onChange={e => handleInputChange(e.target.value, 'functionalRequirements')} className={`input-textarea ${formData.functionalRequirements.showError && !formData.functionalRequirements.value ? 'invalid' : ''}`}></textarea>
                                {
                                    formData.functionalRequirements.showError && !formData.functionalRequirements.value && (
                                        <p className="error-message">This field is required.</p>
                                    )
                                }
                            </div>
                            <div className="form-field mt-3">
                                <label className="label mb-1">API Definition</label>
                                <textarea rows="8" value={formData.apiDefinition.value} onChange={e => handleInputChange(e.target.value, 'apiDefinition')} className={`input-textarea ${(formData.apiDefinition.showError || formData.apiDefinition.inValidFormat) ? 'invalid' : ''}`}></textarea>
                                {
                                    formData.apiDefinition.showError && (
                                        <p className="error-message">{formData.apiDefinition.inValidFormat ? 'API Definition Format Error - Invalid JSON format' : 'This field is required.'}</p>
                                    )
                                }
                            </div>
                            <div className="form-field-select mt-3">
                                <label className="label mb-1">Pick Test Data </label>
                                <div className="align-center">
                                    <div className="form-field">
                                        <Select
                                            value={selectedTestData}
                                            onChange={(dataObj) => {
                                                setSelectedTestData(dataObj);
                                                setFormData({
                                                    ...formData,
                                                    testDataId: {
                                                        ...formData.testDataId,
                                                        value: dataObj.value,
                                                        showError: false
                                                    }
                                                })
                                            }}
                                            name="testDataId"
                                            options={testdataList.map(x => ({ label: x.name, value: x._id }))}
                                            className={`basic-select ${(isDataOnlyChecked && !selectedTestData) ? 'invalid' : ''}`}
                                            classNamePrefix="select"
                                        />
                                    </div>
                                </div>
                                {
                                    formData.testDataId.showError && !selectedTestData && (
                                        <p className="error-message">This field is required.</p>
                                    )
                                }
                            </div>

                            <div className="form-field-select mt-3">
                                <label className="label mb-1">Create or Select a Tag</label>
                                <div className="align-center">
                                    <div className="form-field">
                                        <CreatableSelect
                                            value={selectedTags}
                                            inputValue={inputValue}
                                            isMulti
                                            onChange={(tagValues) => {
                                                setSelectedTags(tagValues)
                                            }}
                                            onBlur={(e) => {
                                                const currentTargetVal = e.currentTarget.value;
                                                if (currentTargetVal) {
                                                    let tagText = currentTargetVal;
                                                    tagText = tagText.replace(/[^a-zA-Z0-9 ]/g, '')
                                                    setInputValue('');
                                                    let uList = [...selectedTags, createOption(tagText)];
                                                    setSelectedTags(uList)
                                                }
                                            }}
                                            onInputChange={(newValue) => {
                                                setInputValue(newValue.replace(/[^a-zA-Z0-9 ]/g, ''))
                                            }}
                                            onKeyDown={event => {
                                                if (!inputValue) return;
                                                switch (event.key) {
                                                    case 'Enter':
                                                    case 'Tab':
                                                        let tagText = inputValue;
                                                        tagText = tagText.replace(/[^a-zA-Z0-9 ]/g, '')
                                                        setInputValue('');
                                                        let uList = [...selectedTags, createOption(tagText)];
                                                        setSelectedTags(uList)
                                                        event.preventDefault();
                                                }
                                            }}
                                            name="colors"
                                            options={allTags}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-field-select mt-3">
                                <label className="label mb-1">API data type</label>
                                <div className="align-center">
                                    <div className="form-field">
                                        <Select
                                            value={formData.apiDataType}
                                            onChange={(dataObj) => {
                                                setFormData({
                                                    ...formData,
                                                    apiDataType: {
                                                        showError: false,
                                                        ...dataObj,
                                                    }
                                                })
                                            }}
                                            name="apidatatype"
                                            options={['JSON', 'XML'].map(x => ({ label: x, value: x }))}
                                            className={`basic-select`}
                                            classNamePrefix="select"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="mt-4 flex">
                                <label htmlFor="withUploaddataOnly" className="form-field-checkbox">
                                    <input id="withUploaddataOnly" onChange={e => {
                                        setIsdataOnlyChecked(e.target.checked)
                                    }} checked={isDataOnlyChecked} type="checkbox" />
                                    <span className="checkmark"></span>
                                </label>
                                <label htmlFor="withUploaddataOnly" className="ml-2">
                                    Create test cases using uploaded data only
                                </label>
                            </div>
                        </div>
                        {/* <div className=" mt-4 px-6 py-4 align-center">
                            { 
                                formData.isFormError && (<div className="error-message">Please fix above errors</div>)
                            }
                        </div> */}
                        <div className="app-form__controls mt-4 px-6 py-4 align-center">

                            <a onClick={handleCancel} className="btn-link-medium mr-8">Cancel</a>
                            <button className="btn-medium-2" type='button' onClick={handleFormClick} disabled={loading}>Create Collection</button>
                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}