import { useEffect, useState } from 'react';
import Select from 'react-select'
import storeService from '../../../services/store.service.js';
import apiService from '../../../services/api.service.js';
import utilsService from '../../../services/utils.service.js';
import featureFlagService from '../../../services/featureflags.service.js'
import tenantsService from '../../../services/tenants.service.js'
const EnvironmentSelector = () => {
  const [selectedTenantEnv, setSelectedTenant] = useState(storeService.store.ENVIRONMENTS.selectedTenantEnv);
  const [environments, setEnvironemts] = useState([{ name: 'Development' }])
  const [tenants, setTenants] = useState([])
  const [options, setOptions] = useState([])
  const constructOptions = (tenants) => {
    let items = [], availableTenentEnvs = [];
    tenants.forEach(tenant => {
      if (tenant.environments && tenant.environments.length > 0) {
        tenant.environments.forEach(env => {
          let val = `${tenant.name}||${env.name}`;
          items.push({
            value: val,
            label: `${tenant.name}-${env.name}`
          })
          availableTenentEnvs.push(val)
        })
      }
    })
    if (!availableTenentEnvs.find(x => x == selectedTenantEnv)) {
      storeService.saveStore({ selectedTenantEnv: availableTenentEnvs[0] }, 'ENVIRONMENTS')
      setSelectedTenant(availableTenentEnvs[0]);
      localStorage.setItem('selectedTenantEnv', availableTenentEnvs[0]);
    }
    setOptions([...items])
  }
  const loadTenants = async () => {
    storeService.subscribe('TENANTS', ({ detail }) => {
      setTenants(detail.items);
      constructOptions(detail.items);
    })
    tenantsService.loadTenants({})
  }
  useEffect(() => {
    storeService.subscribe('ENVIRONMENTS', ({ detail }) => {
      setEnvironemts(detail.items);
      setSelectedTenant(detail.selectedTenantEnv)
    })
    featureFlagService.getAllFeatures()
    loadTenants();
  }, [])


  const handleTenantChange = (option) => {
    storeService.saveStore({ selectedTenantEnv: option.value }, 'ENVIRONMENTS')
    setSelectedTenant(option.value);
    localStorage.setItem('selectedTenantEnv', option.value);
  }
  return (
    <div style={{ minWidth: '300px' }}>
      <Select onChange={handleTenantChange} value={options.find(x => x.value === selectedTenantEnv)} options={options} />
    </div>
  );
};

export default EnvironmentSelector;
