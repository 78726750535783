import React, { useEffect, useState } from "react";
import DefaultView from "./DefaultView";
import TreeView from './TreeView';

const checkAndReturn = (valToCheck, prefix) => {
  if (valToCheck) {
    return prefix + valToCheck;
  }
  return "";
};
const getStepsPath = (steps) => {
  let completePath = "";
  let totalNodeLen = steps.length;
  for (let i = 0; i < totalNodeLen; i++) {
    let stepData = steps[i];
    completePath +=
      getStepName(stepData) +
      ` (${stepData.actionCount})` +
      (i < totalNodeLen - 1 ? " -> " : "");
  }
  return completePath;
};
const getStepName = (step) => {
  if (step.actionType === "OPEN_URL") {
    return (
      "Open " +
      (step.pageName || step.pageUrl.substr(step.pageUrl.lastIndexOf("/") + 1))
    );
  }
  if (step.actionType === "INPUT") {
    return (
      "Input " +
      step.elementValue +
      checkAndReturn(
        step.elementText || step.elementId || step.elementName,
        " in "
      )
    );
  }
  if (
    ["CHECKBOX", "RADIO", "SELECT", "MULTI_SELECT"].indexOf(step.actionType) >=
    0
  ) {
    return "Select " + step.elementValue + " as " + step.elementName;
  }
  if (["ANCHOR_CLICK", "BUTTON_CLICK"].indexOf(step.actionType) >= 0) {
    return (
      "Click " +
      (step.elementLinkText ||
        step.elementText ||
        step.elementId ||
        step.elementName ||
        step.actionType)
    );
  }
  return step.pageName || step.actionType;
};
const getAllSteps = (data) => {
  let stepPaths = [];

  const printCompletePath = (steps) => {
    let completePath = "";
    let totalNodeLen = steps.length;
    for (let i = 0; i < totalNodeLen; i++) {
      let stepData = steps[i];
      completePath +=
        getStepName(stepData) + (i < totalNodeLen - 1 ? " -> " : "");
    }
    stepPaths.push(steps);
  };

  const checkNextNodes = (node, prevNodeSteps) => {
    let currentNode = { ...node };
    delete currentNode.nextActions;
    currentNode.stepName = getStepName(currentNode);
    let pathSoFar = [...prevNodeSteps, currentNode];
    if (node.nextActions) {
      if (node.nextActions instanceof Array) {
        if (node.nextActions.length > 0) {
          node.nextActions.forEach((action) => {
            checkNextNodes(action, pathSoFar);
          });
        } else {
          printCompletePath(pathSoFar);
        }
      } else if (typeof node.nextActions === "object") {
        [node.nextActions].forEach((action) => {
          checkNextNodes(action, pathSoFar);
        });
      } else {
        printCompletePath(pathSoFar);
      }
    } else {
      printCompletePath(pathSoFar);
    }
  };
  let rootNodeLen = data.length;
  for (let i = 0; i < rootNodeLen; i++) {
    checkNextNodes(data[i], []);
  }
  return stepPaths;
};
const PageJourney = ({ onRegressionSelect, actionData, selectedSteps, treeViewSelected }) => {
  // onRegressionSelect;
  const [stepPaths, setStepPaths] = useState([]);
  const [filteredStepPaths, setfilteredStepPaths] = useState([]);
  const getStepMessage = (step) => {
    if (step.actionType === "OPEN_URL") {
      return "Url: " + step.pageUrl;
    }
    if (step.actionType === "INPUT") {
      return "Input value: " + step.elementValue;
    }
    if (
      ["CHECKBOX", "RADIO", "SELECT", "MULTI_SELECT"].indexOf(
        step.actionType
      ) >= 0
    ) {
      return "Selected " + step.elementValue;
    }
    if (["ANCHOR_CLICK", "BUTTON_CLICK"].indexOf(step.actionType) >= 0) {
      //  + (step.elementLink && step.elementLink != '' ? ` (${step.elementLink})`: ``)
      return (
        <>
          <p>
            {
              "Click " +
              (step.elementLinkText ||
                step.elementText ||
                step.elementId ||
                step.elementName ||
                step.actionType)
            }
          </p>
          {
            step.elementLink && step.elementLink != '' && (
              <p>
                {
                  `Url: ` + (step.elementLink && step.elementLink != '' ? ` ${step.elementLink}` : ``)
                }
              </p>
            )
          }
          <p>

          </p>
        </>
      );
    }
    return "";
  };
  const data = actionData?.data;
  const dataV2 = actionData?.data;
  const [selectedPage, setSelectedPage] = useState(-1);
  const [selectedActionLevel, setselectedActionLevel] = useState(2)
  const [activeAction, setActiveAction] = useState(`0-0`);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [selectAllCheck, setSelectAllCheck] = useState(false);
  const [selectedStepList, setSelectedStepList] = useState([]);
  const [selectedPath, setSelectedPath] = useState(null);
  const [selectedPathIndex, setSelectedPathIndex] = useState(0);
  const [entryPages, setEntryPages] = useState([]);

  useEffect(() => {
    if (
      selectedSteps &&
      JSON.stringify(selectedSteps) !== JSON.stringify(selectedStepList)
    ) {
      setSelectedStepList(selectedSteps);
    }
  }, [selectedSteps]);

  const pageChange = (event) => {
    const { value, selectedIndex } = event.target;
    setSelectedPage(value);
    let pageLevelFilterList = [];
    if (selectedActionLevel == 'ALL') {
      pageLevelFilterList = [...stepPaths];
    } else {
      pageLevelFilterList = stepPaths.filter(x => x.length == selectedActionLevel);
    }
    if (value - 0 >= 0) {
      const selectedFirstPage = entryPages[value - 0];
      pageLevelFilterList = [
        ...pageLevelFilterList.filter((x) => {
          let firstStep = { ...x[0] };
          return (
            firstStep.pageName == selectedFirstPage.pageName &&
            firstStep.pageUrl == selectedFirstPage.pageUrl
          );
        }),
      ];
    }
    if (pageLevelFilterList.length > 0) {
      setfilteredStepPaths(pageLevelFilterList);
      setActiveAction(0);
      setSelectedPath(pageLevelFilterList[0]);
      let mappedSteps = constructSteps(pageLevelFilterList[0]);
      onRegressionSelect([
        {
          regressionName: getStepsPath(pageLevelFilterList[0]),
          steps: mappedSteps,
        },
      ]);
    } else {
      setfilteredStepPaths([])
      setSelectedPath(null)
      onRegressionSelect(null);
    }
  };
  const handleActionLevel = (event) => {
    let selVal = event.target.value;
    setselectedActionLevel(selVal)
    let pageLevelFilterList = [];
    if (selVal == 'ALL') {
      pageLevelFilterList = [...stepPaths];
    } else {
      pageLevelFilterList = stepPaths.filter(x => x.length == selVal);
    }
    if (selectedPage - 0 >= 0) {
      const selectedFirstPage = entryPages[selectedPage - 0];
      pageLevelFilterList = [
        ...pageLevelFilterList.filter((x) => {
          let firstStep = { ...x[0] };
          return (
            firstStep.pageName == selectedFirstPage.pageName &&
            firstStep.pageUrl == selectedFirstPage.pageUrl
          );
        }),
      ];
    }
    let firstPages = [];
    if (pageLevelFilterList.length > 0) {
      pageLevelFilterList.forEach((steps) => {
        let firstStep = { ...steps[0] };
        delete firstStep.nextActions;
        if (
          !firstPages.find(
            (x) =>
              x.pageName == firstStep.pageName &&
              x.pageUrl == firstStep.pageUrl
          )
        ) {
          firstPages.push(firstStep);
        }
      });
      firstPages.sort((a, b) => b.actionCount - a.actionCount);
      setfilteredStepPaths(pageLevelFilterList);
      setActiveAction(0);
      setSelectedPath(pageLevelFilterList[0]);
      let mappedSteps = constructSteps(pageLevelFilterList[0]);
      onRegressionSelect([
        {
          regressionName: getStepsPath(pageLevelFilterList[0]),
          steps: mappedSteps,
        },
      ]);
    } else {
      setfilteredStepPaths([])
      setSelectedPath(null)
      onRegressionSelect([]);
    }
    setEntryPages(firstPages);
  }
  const constructSteps = (steps) => {
    return steps.map((x) => {
      return {
        action: x.actionType,
        url: x.pageUrl,
        page_name: x.pageName,
        stepName: x.stepName,

        element: x.elementName,
        elementId: x.elementId,
        xPath: x.elementXPath,

        value: x.elementValue,
      };
    });
  };
  const handleSelectedPathChange = (steps, index) => {
    let mappedSteps = constructSteps(steps);
    onRegressionSelect([
      {
        regressionName: getStepsPath(steps),
        steps: mappedSteps,
      },
    ]);
  };

  useEffect(() => {
    if (dataV2) {
      let allSteps = getAllSteps(dataV2);
      setStepPaths(allSteps);
      let pageLevelFilterList = allSteps.filter(x => x.length == selectedActionLevel);
      setfilteredStepPaths(pageLevelFilterList);
      let firstPages = [];
      if (pageLevelFilterList.length > 0) {
        pageLevelFilterList.forEach((steps) => {
          let firstStep = { ...steps[0] };
          delete firstStep.nextActions;
          if (
            !firstPages.find(
              (x) =>
                x.pageName == firstStep.pageName &&
                x.pageUrl == firstStep.pageUrl
            )
          ) {
            firstPages.push(firstStep);
          }
        });
        firstPages.sort((a, b) => b.actionCount - a.actionCount);
        setActiveAction(0);
        setSelectedPath(pageLevelFilterList[0]);
        let mappedSteps = constructSteps(pageLevelFilterList[0]);
        onRegressionSelect([
          {
            regressionName: getStepsPath(pageLevelFilterList[0]),
            steps: mappedSteps,
          },
        ]);
      }
      setEntryPages(firstPages);
    }
  }, []);


  return (

    <div
      className={`page-actions flex ${treeViewSelected ? "page-action-tree-view" : ""
        }`}

    >
      {!treeViewSelected ? (
        <>
          <DefaultView
            pageChange={pageChange}
            entryPages={entryPages}
            filteredStepPaths={filteredStepPaths}
            setSelectAllCheck={setSelectAllCheck}
            setSelectedPath={setSelectedPath}
            setSelectedPathIndex={setSelectedPathIndex}
            handleSelectedPathChange={handleSelectedPathChange}
            selectedPathIndex={selectedPathIndex}
            getStepsPath={getStepsPath}
            getStepName={getStepName}
            getStepMessage={getStepMessage}
            selectedPage={selectedPage}
            selectedPath={selectedPath}
            handleActionLevel={handleActionLevel}
            selectedActionLevel={selectedActionLevel}
          />
        </>
      ) : (
        <TreeView dataV2={dataV2} onRegressionSelect={onRegressionSelect} filteredStepPaths={filteredStepPaths} getStepsPath={getStepsPath} selectedPath={selectedPath} />
      )}
    </div>
  );
};

export default PageJourney;
