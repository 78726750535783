import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import utilsService from "../../services/utils.service.js";
import dataService from "../../services/data.service.js";
import apiService from "../../services/api.service.js";
import LeftNav from "../../comps/atoms/LeftNav/LeftNav.jsx";
import storeService from "../../services/store.service.js";
import SvgCode from '../../comps/atoms/SvgCode';
import Logout from "../../comps/atoms/Logout.jsx";
import EditPageActionDetail from "./EditPageActionDetail.jsx";
import EnvironmentSelector from "../../comps/atoms/EnvironmentSelect/EnvironementSelect.jsx";
import { Tooltip } from 'react-tooltip';
import SvgIcon from '../../comps/atoms/SvgIcon';
import ScheduleButton from '../../comps/molecules/ScheduleButton.jsx'
import AppIcon from "../../comps/atoms/AppIcon/AppIcon.jsx";
import workflowsService from "../../services/workflows.service.js";
import ConfirmSaveModal from "../../comps/molecules/ConfirmSaveModal.jsx";
import XpathRecommendation from "../../comps/molecules/XpathRecommendation.jsx";
import PriorityGroupDisplay from "../../comps/molecules/PriorityGroupDisplay.jsx";
const JOB_STATUS = {
  THRESHOLD_VALUE: 3000,
  SUCCESS: "Passed",
  FAILURE: "Failed"
}
// Function to update the job status
const checkPerformance = (metrics = []) => {
  try {
    const propertiesToCheck = ['fcp', 'ttfb', 'ntt', 'lcp', 'ncp', 'pageLoadTime'];
    for (const prop of propertiesToCheck) {
      const value = metrics[prop] ? parseInt(metrics[prop]) : 0;
      if (!isNaN(value) && value > JOB_STATUS.THRESHOLD_VALUE) {
        return JOB_STATUS.FAILURE;
      }
    }
    return JOB_STATUS.SUCCESS;
  } catch (e) {
    console.error('Catch error in checkPerformance::', e);
  }
};
const PageActionDetails = () => {
  const { pageId, deviceId } = useParams();
  const navigate = useNavigate();
  const [pagePerformanceData, setPagePerformanceData] = useState([]);
  const [accordionOpen, setAccordionOpen] = useState(true);
  const [devices, setDevices] = useState([]);
  const [jobIndex, setJobIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState(storeService.store.WORKFLOWS_WITH_DETAILS.filterData.selectedDate || null)
  const [testImageIndex, setTestImageIndex] = useState(0);
  const [selectedFlow, setSelectedFlow] = useState({});
  const [selectedworkflow, setSelectedWorkflow] = useState(null)
  const [selectedJob, setSelectedJob] = useState({});
  const [testRunInProgress, setTestRunInProgress] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [history, setHistory] = useState({
    total: 0,
    failure: 0
  });
  const [selectedTenantEnv, setselectedTenantEnv] = useState(storeService.store.ENVIRONMENTS.selectedTenantEnv);

  const [selectedDevice, setSelectedDevice] = useState(storeService.store.WORKFLOW_DEVICES.items?.find((device) => device._id == deviceId) || {})

  const getJobFlowData = async () => {
    const resp = await apiService.post({
      url: utilsService.END_POINTS.getuserflowjobbyid,
      data: {
        userflowId: pageId,
        deviceId: deviceId,
        selectedTenantEnv: storeService.store.ENVIRONMENTS.selectedTenantEnv
      }
    });
    const { data = {} } = resp;
    setSelectedFlow(data);
    setSelectedDevice(storeService.store.WORKFLOW_DEVICES.items?.find((device) => device._id == deviceId))
    if (data?.jobs?.length) {
      let selectedIndex = 0;
      data.jobs.forEach((item, idx) => {
        storeService.store['WORKFLOWS_JOBS_HASH'].itemsMap['ID_' + item._id] = item;
        if (utilsService.getYMDFormat(item.jobStartedAt) == selectedDate && selectedIndex == 0) {
          selectedIndex = idx
        }
      });
      setJobIndex(selectedIndex)
      setSelectedJob(data?.jobs?.[selectedIndex]);
      const noOfFailure = data?.jobs?.filter(val => {
        return val.finalStatus === 'Failed';
      })?.length;
      setHistory({
        failure: noOfFailure,
        total: data?.jobs?.length
      })
    }
  }

  const removeDuplicates = devices => {
    const unique = {};
    return devices.filter(device => {
      return unique.hasOwnProperty(device._id) ? false : (unique[device._id] = true);
    });
  };


  const getDevices = async () => {
    const resp = await apiService.post({
      url: utilsService.END_POINTS.getuserflowjobdevices,
      data: {
        userflowId: pageId
      }
    });
    const uniqueDevices = removeDuplicates(resp?.data?.devices);
    setDevices(storeService.store.WORKFLOW_DEVICES.items);
    setSelectedDevice(resp?.data?.devices?.find((device) => device._id == deviceId))
  }

  useEffect(() => {
    document.addEventListener("scroll", () => {
      let headerElem = document.querySelector(".main-header-nav");
      if (headerElem) {
        headerElem.classList.remove("sticky");
        if (window.scrollY > 5) {
          headerElem.classList.add("sticky");
        }
      }
    });
    storeService.sub(utilsService.CONSTANTS.EVENT_NAME.ANALYSIS_JOB_DONE, () => {
      getJobFlowData();
    })
    storeService.sub(utilsService.CONSTANTS.EVENT_NAME.USERFLOW_JOB_DONE, () => {
      getJobFlowData();
    })
    storeService.sub(utilsService.CONSTANTS.EVENT_NAME.USERFLOW_JOB_STARTED, () => {
      getJobFlowData();
    })
    storeService.sub(utilsService.CONSTANTS.EVENT_NAME.OPEN_ADD_PAGE_MODAL, ({ detail }) => {
      setShowEditModal(detail);
      getJobFlowData();
    });
    getDevices();
    storeService.subscribe('ENVIRONMENTS', ({ detail }) => {
      setselectedTenantEnv(detail.selectedTenantEnv)
      setSelectedJob(null)
      getJobFlowData();
    })

    workflowsService.getworkflows().then(workflowResp => {
      if (workflowResp && workflowResp.data) {
        let uniquTags = new Set();
        workflowResp.data.forEach(x => {
          if (x.tags) {
            let xList = typeof x.tags == 'string' ? x.tags.split(',') : x.tags;
            xList.forEach(tagName => {
              uniquTags.add(tagName.trim())
            })
          }
        });
        storeService.store.WORKFLOWS_WITH_DETAILS.allowedTags = [...uniquTags];
      }
    });
  }, []);

  const loadOptions = (results, steps) => {
    return steps.map((step, stepIndex) => {
      return <option key={`step_${stepIndex}`} value={stepIndex}>Step {stepIndex + 1}: {step?.stepName}</option>
    });
  };

  const runTest = async (e) => {
    e.preventDefault();
    setTestRunInProgress(true);
    await dataService.runpageactionsondevices({
      items: [
        {
          _id: selectedFlow._id,
          device: selectedFlow.jobs.length > 0 ? selectedFlow.jobs[0].device : selectedDevice,
          deviceId: selectedFlow.jobs.length > 0 ? selectedFlow.jobs[0].deviceId : selectedDevice._id,
          refId: selectedFlow.refId,
          steps: selectedFlow.steps,
          configs: selectedFlow.configs
        }
      ]
    })
    await getJobFlowData();
    setTestRunInProgress(false);
  }

  const deviceChangeHandler = (event) => {
    const deviceSelected = devices?.find((device) => device.name == event.target.value);
    navigate(`/page-action/page/${pageId}/device/${deviceSelected._id}`);
  };

  useEffect(() => {
    async function fetchData() {
      await getJobFlowData();
    }
    fetchData();
  }, [pageId, deviceId, selectedTenantEnv])

  const setActiveTestIndex = (event, index) => {
    event.preventDefault();
    setAccordionOpen(!(index === jobIndex && accordionOpen));
    setJobIndex(index);
    setTestImageIndex(0);
    setSelectedJob(selectedFlow?.jobs?.[index]);
  };

  const handleTestImageChange = (event) => {
    setTestImageIndex(event.target.value);
  };

  const renderAnalysis = (analysisObj = {}, arr) => {
    let _arr = arr || [];
    {
      Object.keys(analysisObj).map((key) => {
        if (typeof analysisObj[key] === 'string') {
          if ((key || '').toLowerCase() != 'status') {
            _arr.push((<li className="mb-2 p-4" key={`${key}`}>
              <h4 className="sub-heading-2">{key}</h4>
              <p className="mt-2 body-3">
                {analysisObj?.[key]}
              </p>
            </li>))
          }
        } else if (typeof analysisObj[key] === 'object' && Object.keys(analysisObj[key])?.length) {
          _arr.push(<li className="pb-3 pt-4 bg-none border-none" key={`${key}`}> <h3 className="">{key}</h3></li>)
          renderAnalysis(analysisObj[key], _arr);
        }
        return null;
      })
    }
    return _arr;
  }

  const getJobStatus = (job, stepIndex) => {
    let status = '-'
    if (!job) return status;
    if (job.status == 'FAILED' || (job.analysisStatus || '').toUpperCase() == 'FAILED') {
      if (stepIndex != null) {
        if (job.analysis && job.analysis.length > 0 && job.analysis[stepIndex] && job.analysis[stepIndex].status) {
          return (job.analysis[stepIndex].status || '').toUpperCase() == 'FAILED' ? 'Failed' : 'Passed';
        }
        return job.results[stepIndex]?.screenshot ? 'Passed' : 'Failed';
      }
      return 'Failed';
    }
    if (job.status == 'IN_PROGRESS') {
      return 'Running';
    }
    if (job.analysisStatus == 'IN_PROGRESS') {
      return 'Analyzing';
    }
    if (job.status == 'TODO' || job.analysisStatus == 'TODO') {
      return 'Waiting';
    }
    if (job.status == 'DONE' && job.analysisStatus == 'DONE') {
      if (job.results && job.results.length > 0) {
        if (stepIndex != null) {
          return checkPerformance(job.results[stepIndex].metrics);
        }
        return checkPerformance(job.results[0].metrics);
      }
      return 'Failed';
    }
    return status;
  }
  const getPriorityStatus = (job, stepIndex) => {
    if (job.priority && job.priority.length > 0) {
      return job.priority[stepIndex] != null && job.priority[stepIndex] != '' ? '- ' + job.priority[stepIndex] : '';
    }
    if (job.finalPriority) {
      return '- ' + job.finalPriority;
    }
    return;
  }

  return (
    <div className="flex main-layout">
      <div className="main-layout__left-ribbon-nav">
        <LeftNav />
      </div>
      <div className="main-layout__content">
        <div className="main-header-nav fluid-container py-4">
          <div className="flex align-center">
            <ul className="flex no-disc">
              <li className="ml-2">
                <Link to={`/page-action`}>
                  <div className="align-center">
                    <svg
                      className="svg-icon-m page-action-detail-back"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="24"
                      viewBox="0 0 14 24"
                      fill="#1b72e6"
                    >
                      <path d="M11.1073 23.5383L0.675978 13.107C0.530108 12.9617 0.414368 12.7889 0.335395 12.5987C0.256421 12.4085 0.215767 12.2046 0.215767 11.9987C0.215767 11.7928 0.256421 11.5888 0.335395 11.3987C0.414368 11.2085 0.530108 11.0357 0.675978 10.8904L11.1073 0.459083C11.4012 0.165137 11.7999 1.92838e-08 12.2156 1.87067e-08C12.6313 1.81296e-08 13.03 0.165137 13.3239 0.459083C13.6179 0.753029 13.783 1.15171 13.783 1.56741C13.783 1.98311 13.6179 2.38179 13.3239 2.67573L3.99965 12L13.3252 21.3243C13.6192 21.6182 13.7843 22.0169 13.7843 22.4326C13.7843 22.8483 13.6192 23.247 13.3252 23.5409C13.0313 23.8349 12.6326 24 12.2169 24C11.8012 24 11.4025 23.8349 11.1086 23.5409L11.1073 23.5383Z"></path>
                    </svg>
                    <h1 className="h1-heading ml-2 pageblazer-table-text">{selectedFlow.name}</h1>
                    <Tooltip
                      anchorSelect={'.pageblazer-table-text'}
                      content={selectedFlow.name}
                      place="top"
                      style={{
                        maxWidth: "500px"
                      }}
                    />
                  </div>
                </Link>
              </li>
            </ul>
          </div>
          <div className="main-header-nav__links">
            <ul>
              <li>
                <div>
                  <EnvironmentSelector />
                </div>
              </li>
              <li>
                <a href="#">
                  <svg
                    xmlns="http:www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M27.8159 24.6347C26.0385 21.5152 23.2632 19.0846 19.9366 17.7342C21.5909 16.4935 22.8129 14.7637 23.4294 12.7899C24.046 10.8162 24.0259 8.69845 23.372 6.73673C22.7181 4.77502 21.4635 3.06878 19.786 1.8597C18.1085 0.65062 16.0931 0 14.0253 0C11.9574 0 9.94203 0.65062 8.26452 1.8597C6.58701 3.06878 5.33245 4.77502 4.67854 6.73673C4.02464 8.69845 4.00454 10.8162 4.6211 12.7899C5.23766 14.7637 6.45962 16.4935 8.11388 17.7342C4.78733 19.0846 2.01199 21.5152 0.234627 24.6347C0.124495 24.8117 0.0510929 25.0092 0.0187838 25.2152C-0.0135253 25.4212 -0.00408184 25.6316 0.0465531 25.8339C0.097188 26.0362 0.187981 26.2263 0.313533 26.3928C0.439086 26.5593 0.596835 26.6989 0.777404 26.8032C0.957972 26.9075 1.15767 26.9745 1.36463 27.0001C1.5716 27.0257 1.78159 27.0094 1.98214 26.9522C2.18269 26.895 2.36969 26.7981 2.53204 26.6672C2.69438 26.5363 2.82875 26.3741 2.92716 26.1903C5.27616 22.1301 9.4245 19.7085 14.0253 19.7085C18.626 19.7085 22.7744 22.1314 25.1234 26.1903C25.3366 26.5332 25.6749 26.7798 26.0667 26.8778C26.4584 26.9759 26.8729 26.9177 27.2226 26.7157C27.5723 26.5137 27.8297 26.1836 27.9404 25.7953C28.0511 25.4069 28.0065 24.9907 27.8159 24.6347ZM7.28421 9.8562C7.28421 8.52294 7.67957 7.21963 8.42028 6.11107C9.161 5.00251 10.2138 4.13849 11.4456 3.62828C12.6773 3.11806 14.0327 2.98457 15.3404 3.24467C16.648 3.50478 17.8492 4.1468 18.7919 5.08955C19.7347 6.03231 20.3767 7.23345 20.6368 8.54108C20.8969 9.84872 20.7634 11.2041 20.2532 12.4359C19.743 13.6677 18.879 14.7205 17.7704 15.4612C16.6618 16.2019 15.3585 16.5972 14.0253 16.5972C12.2381 16.5952 10.5246 15.8843 9.26089 14.6206C7.99715 13.3568 7.28627 11.6434 7.28421 9.8562Z"
                      fill="#1B72E6"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <Logout iconColor="#1B72E6" />
              </li>
            </ul>
          </div>
        </div>

        <div className="fluid-container py-4">
          <div className="section-box p-6 rounded-l">
            <div className="flex justify-between align-center">
              <div className="flex align-center pointer" id="pbdetail-tooltip">
                <h2 className="mr-3">Overview</h2>
                <img src="/icons/info-svg.svg" className="svg-icon-l" />
                <Tooltip
                  anchorSelect="#pbdetail-tooltip"
                  content="Each test simulates a user journey across pages. View detailed stats for the latest and past runs. Filter results by environment and date."
                  place="right"
                  style={{
                    backgroundColor: "#000",
                    color: "#ffffff",
                    borderRadius: "5px",
                    zIndex: 22,
                    fontWeight: "bold",
                    width: "50%",
                  }}
                />
              </div>
              {/* Filters */}
              <div className="flex align-center"></div>
              <div className="align-center justify-between">
                <div className="form-field form-field--select align-center mr-8 pageblazer-device">
                  <div className="form-field__label align-center">
                    Device:
                  </div>
                  <select
                    value={selectedDevice?.name}
                    onChange={(e) => deviceChangeHandler(e)}
                  >
                    {devices?.map((device, index) => (
                      <option key={`${index}__${device.name}`} value={device.name} data-id={device._id}>
                        {device.name}
                      </option>
                    ))}
                  </select>
                  <svg
                    className="post-svg-icon"
                    xmlns="http:www.w3.org/2000/svg"
                    width="18"
                    height="11"
                    viewBox="0 0 18 11"
                    fill="none"
                  >
                    <path
                      d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z"
                      fill="#1B72E6"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div className="flex box-tiles mt-4">
              {/* Results section here */}
              <div className="box-tile w-4/6 pr-2">
                {jobIndex !== null ?
                  <div className={`box-tile__item box-tile__left p-6 ${getJobStatus(selectedJob).toLowerCase() === 'failed' ? 'error' : ''}`}>
                    <h4 className="h4-heading subheading-color">Result</h4>
                    <div className="flex card-section mt-4">
                      <div className="card-item">
                        <div className="card-item__no align-center">
                          <span className="mr-3">
                            {
                              selectedJob && selectedJob.createdAt ?
                                utilsService.getDisplayDateTime(selectedJob?.createdAt) :
                                "-"
                            }
                          </span>
                        </div>
                        <div className="card-item__text align-center">
                          <span className="mr-1 mt-2">Test Date</span>
                        </div>
                      </div>
                      <div className="card-item">
                        <div className="card-item__no align-center">
                          <span className="mr-3">
                            {getJobStatus(selectedJob)} {getJobStatus(selectedJob).toLowerCase() == 'failed' && selectedJob?.finalPriority ? `- ${selectedJob.finalPriority}` : ''}
                          </span>
                        </div>
                        <div className="card-item__text align-center">
                          <span className="mr-1 mt-2">Status</span>
                        </div>
                      </div>
                      <div className="card-item">
                        <div className="card-item__no align-center">
                          <span className="mr-3">
                            {selectedJob?.results?.[0]?.metrics?.fcp || '-'}
                          </span>
                        </div>
                        <div className="card-item__text align-center">
                          <span className="mr-1 mt-2">First Contentful Paint</span>
                        </div>
                      </div>
                      <div className="card-item">
                        <div className="card-item__no align-center">
                          <span className="mr-3">
                            {selectedJob?.results?.[0]?.metrics?.pageSize || '-'}
                          </span>
                        </div>
                        <div className="card-item__text mt-2">
                          Size
                        </div>
                      </div>
                    </div>
                  </div> : null}
              </div>
              {/* History section here */}
              <div className="box-tile w-2/6 pl-2">
                <div className="box-tile__item box-tile__right p-6">
                  <h4 className="h4-heading subheading-color">History</h4>
                  <div className="flex card-section mt-4">
                    <div className="card-item">
                      <div className="card-item__no">
                        <a href="#" className="align-center">
                          <span className="mr-3">{history.total}</span>
                          <img
                            src="/icons/arrow-right.svg"
                            className="svg-icon "
                          />
                        </a>
                      </div>
                      <div className="card-item__text mt-2">Runs</div>
                    </div>
                    <div className="card-item">
                      <div className="card-item__no">
                        <a href="#" className="align-center">
                          <span className="mr-3">{history?.failure || '0'}</span>
                          <img
                            src="/icons/arrow-right.svg"
                            className="svg-icon "
                          />
                        </a>
                      </div>
                      <div className="card-item__text mt-2">Failures</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="table-list-section p-6 rounded-l mt-6">
            {/* Search  */}
            <div className="flex justify-between">
              <div className="flex align-center">
                <div className="flex align-center pointer" id="testlist-tooltip">
                  <h2 className="mr-1">Test List</h2>
                  <img src="/icons/info-svg.svg" className="svg-icon-l" />
                  <Tooltip
                    anchorSelect="#testlist-tooltip"
                    content="Each test simulates a user journey across pages. View past run details, search, and run, edit, or schedule future runs. Filter results by environment, device, and date."
                    place="top-start"
                    style={{
                      backgroundColor: "#000",
                      color: "#ffffff",
                      borderRadius: "5px",
                      zIndex: 22,
                      fontWeight: "bold",
                      width: "50%",
                    }}
                  />
                </div>
                {/* <div className="flex align-center ml-4">
                  <a href="/filter-modal.html" className="link-icon">
                    <img
                      src="/icons/filter-icon.svg"
                      className="svg-icon svg-size-l"
                    />
                  </a>
                </div> */}
              </div>
              <div className="align-center">
                {
                  selectedFlow && selectedDevice && (
                    <ScheduleButton module="PAGE_BLAZER" mode="DEVICE_DETAIL" workflow={{
                      workflowId: selectedFlow._id,
                      _id: selectedFlow._id,
                      steps: selectedFlow.steps,
                      refId: selectedFlow.refId,
                      configs: selectedFlow.configs
                    }} device={selectedDevice} />
                  )
                }
                <a href="#" onClick={(e) => { e.preventDefault(); setShowEditModal(true) }} className={'py-2 px-4 flex align-center no-underline edit-link-option'}>
                  <SvgIcon extraclass='svg-icon-l edit-icon' path="/icons/edit-icon.svg"></SvgIcon>
                  <span>Edit</span>
                </a>
                <a href="#" onClick={e => runTest(e)} className={'py-2 px-4 flex align-center no-underline edit-link-option ' + ((testRunInProgress || getJobStatus(selectedFlow?.jobs?.[0]) == 'In Progress') ? 'disabled' : '')}>
                  <SvgCode name="play"></SvgCode>
                  <span>Run</span>
                </a>
              </div>
            </div>
            {/* Table here */}

            <table className="app-table mt-4">
              <tbody>
                <tr className="table-row-title">
                  <td colSpan={1} className="nested-table-row">
                    <div className="align-center">
                      <span className="mr-2 table-heading">No</span>
                    </div>
                  </td>
                  <td colSpan={2} className="nested-table-row">
                    <div className="align-center">
                      <span className="mr-2 table-heading">Test Date</span>
                    </div>
                  </td>
                  <td className="nested-table-row">
                    <div className="align-center">
                      <span className="mr-2 table-heading">
                        First contentful Paint (FCP) (sec)
                      </span>
                    </div>
                  </td>
                  <td className="nested-table-row">
                    <div className="align-center">
                      <span className="mr-2 table-heading">Time to Interactive (TTI)  (sec)</span>
                    </div>
                  </td>
                  <td className="nested-table-row">
                    <div className="align-center">
                      <span className="mr-2 table-heading">
                        Size of Data Loaded in the Page (kb)
                      </span>
                    </div>
                  </td>
                  <td className="nested-table-row">
                    <span className="mr-2 table-heading">
                      Status
                    </span>
                  </td>
                  {/* <td className="nested-table-row">
                      <label className="form-field-checkbox">
                        <input type="checkbox" />
                        <span className="checkmark"></span>
                      </label>
                    </td> */}
                </tr>

                {selectedFlow?.jobs?.map((job, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr className={'table-content ' + (jobIndex == index ? 'active' : '')}>
                        <td>
                          <a href="#">
                            <div className="justify-between">
                              <div className="align-center">
                                <span className="table-text">{index + 1}</span>
                              </div>
                            </div>
                          </a>
                        </td>
                        <td colSpan={2}>
                          <div className="justify-between align-center">
                            <span className="table-text"> {utilsService.getDisplayDateTime(job?.createdAt, true)}</span>
                          </div>
                        </td>
                        <td
                          onClick={(e) => setActiveTestIndex(e, index)}
                        >
                          <div className="align-center justify-between">
                            <p className="table-text">{
                              job?.results?.length > 0 ? job?.results?.[0]?.metrics?.fcp : ('-')
                            }</p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="12"
                              viewBox="0 0 18 12"
                              fill="#185cb9"
                            >
                              {jobIndex === index && accordionOpen ? (
                                <path
                                  d="M0.34627 9.08042L8.16974 1.25695C8.27876 1.14755 8.40831 1.06074 8.55095 1.00151C8.69359 0.942283 8.84653 0.911793 9.00098 0.911793C9.15543 0.911793 9.30836 0.942283 9.45101 1.00151C9.59365 1.06074 9.7232 1.14755 9.83222 1.25695L17.6557 9.08042C17.8761 9.30088 18 9.59989 18 9.91166C18 10.2234 17.8761 10.5224 17.6557 10.7429C17.4352 10.9634 17.1362 11.0872 16.8244 11.0872C16.5127 11.0872 16.2137 10.9634 15.9932 10.7429L9 3.7497L2.0068 10.7439C1.78634 10.9643 1.48734 11.0882 1.17556 11.0882C0.863781 11.0882 0.564774 10.9643 0.344315 10.7439C0.123856 10.5234 7.5514e-08 10.2244 1.0277e-07 9.91264C1.30027e-07 9.60086 0.123856 9.30185 0.344315 9.08139L0.34627 9.08042Z"
                                  fill="#185cb9"
                                ></path>
                              ) : (
                                <path
                                  d="M17.6537 2.91958L9.83027 10.743C9.72124 10.8524 9.59169 10.9393 9.44905 10.9985C9.30641 11.0577 9.15347 11.0882 8.99902 11.0882C8.84457 11.0882 8.69164 11.0577 8.54899 10.9985C8.40635 10.9393 8.2768 10.8524 8.16778 10.743L0.344312 2.91958C0.123853 2.69912 -3.2851e-09 2.40011 0 2.08834C3.2851e-09 1.77656 0.123853 1.47755 0.344312 1.25709C0.564772 1.03663 0.863779 0.912782 1.17556 0.912782C1.48733 0.912782 1.78634 1.03663 2.0068 1.25709L9 8.25029L15.9932 1.25612C16.2137 1.03566 16.5127 0.911804 16.8244 0.911804C17.1362 0.911804 17.4352 1.03566 17.6557 1.25612C17.8761 1.47658 18 1.77558 18 2.08736C18 2.39914 17.8761 2.69814 17.6557 2.9186L17.6537 2.91958Z"
                                  fill="#185cb9"
                                ></path>
                              )}
                            </svg>
                          </div>
                        </td>
                        <td className="table-text">{
                          job?.results?.length > 0 ? job?.results?.[0]?.metrics?.tti : ('-')
                        }</td>
                        <td className="table-text">{
                          job?.results?.length > 0 ? job?.results?.[0]?.metrics?.pageSize : '-'
                        }</td>

                        <td>
                          <span className={`font-xs ${getJobStatus(job) == "Failed" ? "font-danger" : ""}`}>
                            {getJobStatus(job)} {getJobStatus(job).toLowerCase() == 'failed' && job?.finalPriority ? `- ${job?.finalPriority}` : ''}
                          </span>
                        </td>

                        {/* <td>
                              <label className="form-field-checkbox">
                                <input type="checkbox" />
                                <span className="checkmark"></span>
                              </label>
                            </td> */}
                      </tr>
                      {jobIndex === index && accordionOpen ? (
                        <tr>
                          <td colSpan={8} className="p-0">
                            <div className="page-performance-info w-full">
                              <div className="page-performance-info-steps p-4">
                                <div className="align-center page-performance-info-steps-content">
                                  <h3 className="font-urbanist fw-700 line-height-140">Steps in the Journey</h3>
                                  <div className="ml-5">
                                    <div className="form-field form-field--select align-center">
                                      <select className="aq-select-field" onChange={handleTestImageChange}>
                                        {loadOptions(job?.results, job?.steps)}
                                      </select>
                                      <svg
                                        className="post-svg-icon"
                                        xmlns="http:www.w3.org/2000/svg"
                                        width="18"
                                        height="11"
                                        viewBox="0 0 18 11"
                                        fill="none"
                                      >
                                        <path
                                          d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z"
                                          fill="#1B72E6"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                  {
                                    getJobStatus(job) == "Failed" && (
                                      <>
                                        <div className="flex align-center ml-4 font-danger">
                                          <div>
                                            <div>
                                              <span>Failed Steps: </span>
                                              {
                                                job?.priority?.length == 0 && job?.finalPriority == 'P0' && (`All steps failed with ${job.finalPriority} priority.`)
                                              }
                                              {
                                                job?.priority?.length > 0 && (
                                                  <PriorityGroupDisplay job={job} />
                                                )
                                              }
                                            </div>
                                            {/* {
                                              (job?.jobFailedReason || job?.failedReason?.message) && (
                                                <div>Failed Reason : {job?.jobFailedReason || job?.failedReason?.message}</div>
                                              )
                                            } */}
                                          </div>
                                        </div>
                                        {/* <XpathRecommendation workflow={selectedFlow} job={job} /> */}
                                      </>
                                    )
                                  }
                                </div>
                              </div>
                              <div className="page-performance-info_details flex">
                                <div className="page-performance-info_details_stats w-6/8">
                                  <div className="page-performance-info_details_stats_metrics align-center justify-around py-2 px-4">
                                    <div className="text-center">
                                      <p className="caption-1 line-height-140">Test Date</p>
                                      {/* TODO: we do not have the run time in the results */}
                                      <p className="sub-heading-1 metric line-height-140">{utilsService.getDisplayDateTime(job.createdAt)}</p>
                                    </div>
                                    {
                                      testImageIndex == 0 ? (
                                        <>
                                          <div className="text-center">
                                            <p className="caption-1 line-height-140">FCP</p>
                                            <p className="sub-heading-1 metric line-height-140">{
                                              job?.results?.[testImageIndex]?.metrics?.fcp || '-'
                                            }</p>
                                          </div>
                                          <div className="text-center">
                                            <p className="caption-1 line-height-140">TTI</p>
                                            <p className="sub-heading-1 metric line-height-140">
                                              {job?.results?.[testImageIndex]?.metrics?.tti || '-'}
                                            </p>
                                          </div>
                                          <div className="text-center">
                                            <p className="caption-1 line-height-140">Size of Data</p>
                                            <p className="sub-heading-1 metric line-height-140">{job?.results?.[testImageIndex]?.metrics?.pageSize || '-'}</p>
                                          </div>
                                        </>) : (
                                        <>
                                          <div className="text-center">
                                            <p className="caption-1 line-height-140">Next Paint</p>
                                            <p className="sub-heading-1 metric line-height-140">
                                              {
                                                job?.results?.[testImageIndex]?.metrics?.nextPaint < 0 ?
                                                  job?.results?.[testImageIndex]?.metrics?.fcp :
                                                  (job?.results?.[testImageIndex]?.metrics?.nextPaint || '-')
                                              }
                                            </p>
                                          </div>
                                          <div className="text-center">
                                            <p className="caption-1 line-height-140">Next FCP</p>
                                            <p className="sub-heading-1 metric line-height-140">{
                                              job?.results?.[testImageIndex]?.metrics?.nextPaintTimestamp < 0 ?
                                                job?.results?.[testImageIndex]?.metrics?.tti :
                                                job?.results?.[testImageIndex]?.metrics?.nextPaintTimestamp || '-'
                                            }</p>
                                          </div>
                                          {
                                            job?.results?.[testImageIndex]?.metrics?.nextPaint < 0 &&
                                            (
                                              <div className="text-center">
                                                <p className="caption-1 line-height-140">Size of Data</p>
                                                <p className="sub-heading-1 metric line-height-140">{job?.results?.[testImageIndex]?.metrics?.pageSize || '-'}</p>
                                              </div>
                                            )
                                          }
                                        </>
                                      )
                                    }
                                    <div className="text-center">
                                      <p className="caption-1 line-height-140">Status</p>
                                      <p className={`sub-heading-1 metric line-height-140 ${getJobStatus(job, testImageIndex) === 'Failed' ? 'font-error' : ''}`}>
                                        {getJobStatus(job, testImageIndex)} {getJobStatus(job, testImageIndex) === 'Failed' ? getPriorityStatus(job, testImageIndex) : ''}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="text-center my-8">
                                    {
                                      job?.results?.[testImageIndex]?.screenshot ?
                                        <img className="image-wrapper__image-base captured-image" src={`/?action=screenshots&method=download&name=${job?.results?.[testImageIndex]?.screenshot}`} />
                                        : (
                                          getJobStatus(selectedJob, testImageIndex) == 'Failed' ? (
                                            <>
                                              <p className="mb-4 error-message">{
                                                job?.jobFailedReason || job?.failedReason?.message
                                              }</p>
                                              {
                                                job.stepLogs && job.stepLogs.length > 0 && job.stepLogs.filter(x => x.stepStatus == 'STEP_FAILED').map((x, xdx) => {
                                                  return (
                                                    <p key={`step-error-${xdx}`} className="mb-4 error-message">{x.stepStatusMessage}</p>
                                                  )
                                                })
                                              }
                                            </>
                                          ) : (
                                            <p className="mb-8">
                                              Tests are not yet Run
                                            </p>
                                          )
                                        )
                                    }
                                  </div>
                                </div>
                                <div className="page-performance-info_suggestions p-4 w-1/4">
                                  {job?.analysis?.[testImageIndex] ? (
                                    <ul className="no-disc">
                                      {renderAnalysis(job?.analysis?.[testImageIndex])}
                                    </ul>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
            {showEditModal && <EditPageActionDetail editablePageData={selectedFlow} job={selectedFlow?.jobs?.[0]} />}
          </div>
        </div>
      </div>

    </div>
  );
};

export default PageActionDetails;