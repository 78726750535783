import apiService from './api.service.js'
import storeService from './store.service.js';
import utils from './utils.service.js'
import pagesService from './pages.service.js';
import collectionsService from './collections.service.js';
import workflowsService from './workflows.service.js';
import longPollingService from './long-polling.service.js';
import scheduleService from './schedule.service.js'
import utilsService from './utils.service.js';
export default {
    async testwebauthentication(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.testwebauthentication,
            data: payload
        });
        return apiResp;
    },
    async deleteuserflowdevices(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.deleteworkflows,
            data: payload
        });
        return apiResp;
    },
    async runpageactionsondevices(payload) {
        payload['selectedEnvId'] = storeService.store.ENVIRONMENTS.selectedEnvId;
        // payload['selectedEnvId'] = storeService.store.ENVIRONMENTS.selectedEnvId;
        const apiResp = await apiService.post({
            url: utils.END_POINTS.runpageactionsondevices,
            data: payload
        });
        return apiResp;
    },

    async deleteTestCase(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.deletetestcase,
            data: payload
        });
        return apiResp;
    },
    async updatetestcase(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.updatetestcase,
            data: payload
        });
        return apiResp;
    },
    async deletePages(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.deletePages,
            data: payload
        });
        return apiResp;
    },
    async addApiData(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.addApiData,
            data: payload
        });
        return apiResp;
    },
    async deleteApiData(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.deleteApiData,
            data: payload
        });
        return apiResp;
    },
    async deleteCollections(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.deleteCollections,
            data: payload
        });
        return apiResp;
    },
    async addEnvironmentList(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.addEnvironmentList,
            data: payload
        });
        return apiResp;
    },
    async updateEnvironmentItem(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.updateEnvironmentItem,
            data: payload
        });
        return apiResp;
    },
    async addEnvironmentsConfig(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.addEnvironmentsConfig,
            data: payload
        });
        return apiResp;
    },
    async updateEnvironmentsConfig(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.updateEnvironmentsConfig,
            data: payload
        });
        return apiResp;
    },
    async deleteEnvironmentConfig(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.deleteEnvironmentConfig,
            data: payload
        });
        return apiResp;
    },
    async updateEnvironmentCredentials(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.updateCredentials,
            data: payload
        });
        return apiResp;
    },
    async deleteEnvironmentCredentials(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.deleteCredentials,
            data: payload
        });
        return apiResp;
    },
    async deleteEnvironmentItem(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.deleteEnvironmentItem,
            data: payload
        });
        return apiResp;
    },
    async addDevicesList(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.addDevicesList,
            data: payload
        });
        return apiResp;
    },
    async addNewDevice(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.addNewDevice,
            data: payload
        });
        return apiResp;
    },
    async updateDevice(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.updateDevice,
            data: payload
        });
        return apiResp;
    },
    async deleteDevice(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.deleteDevice,
            data: payload
        });
        return apiResp;
    },
    async addPages(payload) {
        payload['selectedEnvId'] = storeService.store.ENVIRONMENTS.selectedEnvId;
        const apiResp = await apiService.post({
            url: utils.END_POINTS.addPages,
            data: payload
        });
        return apiResp;
    },
    async updatePages(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.updatePages,
            data: payload
        });
        return apiResp;
    },
    async addJobs(payload) {
        payload['selectedEnvId'] = storeService.store.ENVIRONMENTS.selectedEnvId;
        const apiResp = await apiService.post({
            url: utils.END_POINTS.addjobs,
            data: payload
        });
        return apiResp;
    },
    async addDevices(payload) {
        payload['selectedEnvId'] = storeService.store.ENVIRONMENTS.selectedEnvId;
        const apiResp = await apiService.post({
            url: utils.END_POINTS.adddevices,
            data: payload
        });
        return apiResp;
    },
    async addCollections(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.addcollections,
            data: payload
        });
        return apiResp;
    },
    async runApiTests(payload) {
        payload['selectedEnvId'] = storeService.store.ENVIRONMENTS.selectedEnvId;
        const apiResp = await apiService.post({
            url: utils.END_POINTS.runApiTests,
            data: payload
        });
        return apiResp;
    },
    async generateTestcases(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.generateTestcases,
            data: payload
        });
        return apiResp;
    },
    async getCollectionsWithDetails({
        skip = 0,
        limit = 5,
        searchText,
        id,
        selectedDate,
        selectedTags,
        byScheduled,
        selectedType,
        invokedBy
    } = {}) {
        storeService.store.COLLECTIONS_WITH_DETAILS.isLoading = true
        // console.log('invokedBy', invokedBy)
        collectionsService.getcollectionsv2({
            skip,
            limit,
            searchText,
            id,
            selectedDate,
            selectedTags,
            byScheduled,
            selectedType
        }).then(collectionResp => {
            const { data = {} } = collectionResp;
            const { items = [], totalCount = 0 } = data;
            let collectionsToLoad = []
            storeService.saveStore({
                items: items.map(x => {
                    storeService.store.COLLECTIONS_HASH.itemsMap[x._id] = x;
                    collectionsToLoad.push(x._id)
                    x['randomGuid'] = utils.guid(8);
                    return x;
                }),
                lastLoaded: new Date(),
                totalCount: totalCount,
                loaded: false,
                isLoading: false,
            }, 'COLLECTIONS_WITH_DETAILS');
            scheduleService.getschedulejobsbypage({
                selectedEnvId: storeService.store.ENVIRONMENTS.selectedEnvId,
                collectionIds: collectionsToLoad
            }).then(scheduledjobs => {
                if (scheduledjobs && scheduledjobs.length > 0) {
                    let byCollectionIdHash = storeService.store.SCHEDULED_JOB_LIST.byCollectionIdHash;
                    scheduledjobs.forEach(job => {
                        let existList = [];
                        if (byCollectionIdHash.has(job.collectionId)) {
                            existList = byCollectionIdHash.get(job.collectionId)
                        }
                        existList.push(job);
                        byCollectionIdHash.set(job.collectionId, existList)
                    })
                    collectionsService.mergeTestResults();
                }
            })
            collectionsService.gettestresultsv2({
                selectedEnvId: storeService.store.ENVIRONMENTS.selectedEnvId,
                collectionIds: collectionsToLoad,
                selectedDate: selectedDate
            }).then(testResp => {
                const { data = [] } = testResp;
                storeService.saveStore({
                    items: data,
                    lastLoaded: new Date(),
                    loaded: false,
                    isLoading: false,
                }, 'API_TEST_RESULTS');
                collectionsService.mergeTestResults();
            })
        })
        return;
    },
    async getWorkflowsWithDetails() {
        let workflowStore = storeService.getStore('WORKFLOWS_WITH_DETAILS');
        if (workflowStore.isLoading) {
            return
        }
        storeService.saveStore({
            isLoading: true,
        }, 'WORKFLOWS_WITH_DETAILS');
        const mergeWorkflowWithJobs = () => {
            let workflowJobStore = storeService.store.WORKFLOWS_JOBS;
            let workflowStore = storeService.store.WORKFLOWS_WITH_DETAILS;
            if (workflowStore.loaded && workflowJobStore.loaded) {
                let workflows = workflowStore.items
                workflows.forEach(workflow => {
                    let workflowJobs = workflowJobStore.items.filter(x => x.userflowId?.toString() === workflow._id?.toString());
                    let devices = storeService.store.WORKFLOW_DEVICES.items.map(x => {
                        let newItem = { ...x };
                        newItem['jobs'] = [];
                        return newItem;
                    });
                    workflowJobs.forEach(job => {
                        let deviceItem = devices.find(x => {
                            return x._id === job.deviceId
                        });
                        if (!deviceItem) {
                            devices.push({
                                ...job.device,
                                _id: job.deviceId,
                                jobs: [job]
                            });
                        } else {
                            deviceItem.jobs.push(job);
                        }
                    });
                    workflow.randomId = Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                    workflow.devices = devices;
                });
                storeService.saveStore({
                    items: workflows
                }, 'WORKFLOWS_WITH_DETAILS');
            }
        }
        workflowsService.getworkflows().then(workflowResp => {
            if (workflowResp && workflowResp.data) {
                let workflowIds = [];
                let uniquTags = new Set();
                let updatedItems = workflowResp.data.map(x => {
                    let devices = storeService.store.WORKFLOW_DEVICES.items.map(x => {
                        let newItem = { ...x };
                        newItem['jobs'] = [];
                        return newItem;
                    });
                    x.randomId = Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                    x.devices = devices;
                    if (x.tags) {
                        let xList = typeof x.tags == 'string' ? x.tags.split(',') : x.tags;
                        xList.forEach(tagName => {
                            uniquTags.add(tagName.trim())
                        })
                    }
                    x.jobsLoading = true;
                    workflowIds.push(x._id);
                    return x;
                });
                storeService.saveStore({
                    items: updatedItems,
                    allowedTags: [...uniquTags],
                    lastLoaded: new Date(),
                    loaded: true,
                    isLoading: false
                }, 'WORKFLOWS_WITH_DETAILS');
                mergeWorkflowWithJobs()
                scheduleService.getschedulejobsbypage({
                    workflowIds: workflowIds,
                    selectedEnvId: storeService.store.ENVIRONMENTS.selectedEnvId
                }).then(scheduledjobs => {
                    if (scheduledjobs && scheduledjobs.length > 0) {
                        let byWorkflowIdHash = storeService.store.SCHEDULED_JOB_LIST.byWorkflowIdHash;
                        scheduledjobs.forEach(job => {
                            let existList = [];
                            if (byWorkflowIdHash.has(job.workflowId)) {
                                existList = byWorkflowIdHash.get(job.workflowId)
                            }
                            existList.push(job);
                            byWorkflowIdHash.set(job.workflowId, existList)
                        })
                        let items = storeService.store.WORKFLOWS_WITH_DETAILS.items;
                        storeService.saveStore({
                            items: items.map(x => {
                                x.isScheduled = false;
                                x.randomId = Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                                let byWorkflowIdHash = storeService.store.SCHEDULED_JOB_LIST.byWorkflowIdHash;
                                if (byWorkflowIdHash.has(x._id)) {
                                    x.isScheduled = byWorkflowIdHash.get(x._id).find(j => j.selectedEnvId == storeService.store.ENVIRONMENTS.selectedEnvId) != null;
                                }
                                return x;
                            }),
                            lastLoaded: new Date(),
                            loaded: true,
                            isLoading: false
                        }, 'WORKFLOWS_WITH_DETAILS');
                    }
                })
            }
        });

        // workflowsService.getworkflowjobs().then(jobResp => {
        //     if (jobResp && jobResp.data) {
        //         storeService.saveStore({
        //             items: jobResp.data,
        //             lastLoaded: new Date(),
        //             loaded: true,
        //             isLoading: false
        //         }, 'WORKFLOWS_JOBS');
        //         mergeWorkflowWithJobs()
        //     }
        // });

        return;
    },
    async getPageWithDetails({ jobStatus, invokedBy, isAddNewPage, skip = 0, limit = 5, searchText, id, selectedDate, selectedTags, byScheduled, selectedDevices = [] } = {}) {
        // console.log('invoked by', invokedBy, searchText)
        storeService.store.PAGES_WITH_DETAILS.isLoading = true;
        pagesService.getpagesv2({
            skip,
            limit,
            searchText,
            id,
            selectedTags,
            byScheduled,
            selectedDevices: selectedDevices.map(x => x.value),
            jobStatus,
            selectedEnvId: storeService.store.ENVIRONMENTS.selectedEnvId
        }).then(apiResp => {
            const { data = {} } = apiResp;
            const { items = [], totalCount = 0 } = data;
            let pagesToLoadIds = [];
            storeService.saveStore({
                items: items.map(x => {
                    storeService.store.PAGE_HASH.itemsMap[x._id] = x;
                    pagesToLoadIds.push(x._id)
                    x['loadedGuid'] = utils.guid(8);
                    x['randomGuid'] = utils.guid(8);
                    x.isScheduled = false;
                    return x;
                }),
                lastLoaded: new Date(),
                totalCount: totalCount,
                loaded: true,
                isLoading: false,
            }, 'PAGES_WITH_DETAILS', false);
            pagesService.mergePagesJobsCompareJobs()
            storeService.store.PAGES_WITH_DETAILS.isLoading = false;
            scheduleService.getschedulejobsbypage({
                pageIds: pagesToLoadIds,
                selectedEnvId: storeService.store.ENVIRONMENTS.selectedEnvId
            }).then(scheduledjobs => {
                if (scheduledjobs && scheduledjobs.length > 0) {
                    let byPageIdHash = storeService.store.SCHEDULED_JOB_LIST.byPageIdHash;
                    scheduledjobs.forEach(job => {
                        let existList = [];
                        if (byPageIdHash.has(job.pageId)) {
                            existList = byPageIdHash.get(job.pageId)
                        }
                        existList.push(job);
                        byPageIdHash.set(job.pageId, existList)
                    })
                    pagesService.mergePagesJobsCompareJobs()
                }
            })
            pagesService.getpagejobsv2({
                "selectedEnvId": storeService.store.ENVIRONMENTS.selectedEnvId,
                pageIds: pagesToLoadIds,
                selectedDate: utilsService.getUtcYMD(selectedDate)
            }).then(jobResp => {
                const { data = [] } = jobResp;
                let jobIds = [];
                storeService.saveStore({
                    items: data.map(x => {
                        jobIds.push(x._id)
                        return x;
                    }),
                    lastLoaded: new Date(),
                    loaded: true,
                    isLoading: false,
                }, 'PAGE_JOBS');
                pagesService.mergePagesJobsCompareJobs()
                pagesService.getpagecomparejobsv2({ jobIds }).then(compareJobrep => {
                    const { data = [] } = compareJobrep;
                    storeService.saveStore({
                        items: data,
                        lastLoaded: new Date(),
                        loaded: true,
                        isLoading: false,
                    }, 'PAGE_COMPARE_JOBS');
                    pagesService.mergePagesJobsCompareJobs()
                })
            })
        })
        // pagesService.getpagejobs().then(jobResp => {
        //     if (jobResp && jobResp.data) {
        //         storeService.saveStore({
        //             items: jobResp.data,
        //             lastLoaded: new Date(),
        //             loaded: true,
        //             isLoading: false,
        //         }, 'PAGE_JOBS');
        //         pagesService.mergePagesJobsCompareJobs()
        //     }
        // })

        // pagesService.getpagecomparejobs().then(compareJobrep => {
        //     if (compareJobrep && compareJobrep.data) {
        //         storeService.saveStore({
        //             items: compareJobrep.data,
        //             lastLoaded: new Date(),
        //             loaded: true,
        //             isLoading: false,
        //         }, 'PAGE_COMPARE_JOBS');
        //         pagesService.mergePagesJobsCompareJobs()
        //     }
        // })
        return;
    },
    async getusagedatabyid(findData) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.getusagedatabyid,
            data: {
                "find": {
                    '_id': findData._id
                },
                "options": {
                    "sort": {
                        "createdAt": -1
                    }
                }
            }
        });
        if (apiResp && apiResp.data) {
            let dataMap = storeService.store.USAGE_DATA_MAP.dataMap;
            dataMap[findData._id] = apiResp.data[0];
            storeService.saveStore({
                dataMap
            }, 'USAGE_DATA_MAP');
        }
    },
    async getApiTestData() {
        storeService.saveStore({
            isLoading: true,
        }, 'API_TEST_DATA');
        const apiResp = await apiService.post({
            url: utils.END_POINTS.getTestData,
            data: {
                "find": {},
                "options": {
                    "sort": {
                        "createdAt": -1
                    }
                }
            }
        });
        if (apiResp && apiResp.data) {
            storeService.saveStore({
                items: apiResp.data,
                lastLoaded: new Date(),
                loaded: true,
                isLoading: false,
            }, 'API_TEST_DATA');
        }
        return apiResp;
    },
    async addUsageRegressionPage(payload) {
        const apiResp = await apiService.post({
            url: `${config.AI_HOST}${utils.END_POINTS.addRegressionPage}&skipAuth=true`,
            data: payload
        });
        return apiResp;
    },
    async addNewPageAction(payload) {
        const apiResp = await apiService.post({
            url: `${config.AI_HOST}${utils.END_POINTS.addNewPageAction}&skipAuth=true`,
            data: payload
        });
        return apiResp;
    },
    async executeUserflowRegression(payload) {
        payload['selectedEnvId'] = storeService.store.ENVIRONMENTS.selectedEnvId;
        const apiResp = await apiService.post({
            url: utils.END_POINTS.executeUserflowRegression,
            data: payload
        });
        return apiResp;
    },
    async saveUsageTestCase(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.saveUsageTestCase,
            data: payload
        });
        return apiResp;
    },
    async deleteTestCases(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.deleteTestCases,
            data: payload
        });
        return apiResp;
    },
    async getUsagePulseOverview(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.getUsagePulseOverview,
            data: payload
        });
        return apiResp;
    },
    async deleteUsageData(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.deleteUsageData,
            data: payload
        });
        return apiResp;
    },
    async updatebaselinejobs(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.updateBaseLineJobs,
            data: payload
        });
        return apiResp;
    },
    async reloadhomestats(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.reloadhomestats,
            data: payload
        });
        return apiResp;
    }
}