import { useState } from "react"
import { Tooltip } from 'react-tooltip';
import utilsService from "../../services/utils.service"
export default ({ children, toolTipContent }) => {
    const [tipId, setTipId] = useState('tooltip' + utilsService.guid(4))
    return (
        <div id={tipId}>
            {children}
            <Tooltip
                anchorSelect={'#' + tipId}
                content={toolTipContent || 'test'}
                place="top"
                style={{
                    backgroundColor: "#000",
                    color: "#fff",
                    borderRadius: "5px",
                    zIndex: 22,
                    fontWeight: "bold",
                }}
            />
        </div>
    )
}