import React, { useEffect, useState } from "react";
import AppIcon from "../atoms/AppIcon/AppIcon.jsx";
import dataService from "../../services/data.service.js";
import AppModal from "../organisms/AppModal.jsx";
import ExcelDownload from "./ExcelDownload.jsx";
import ConfirmModal from "./ConfirmModal.jsx";
import AddNewEnvironment from "./AddNewEnvironment.jsx";
import * as XLSX from 'xlsx';

export default ({
  environments,
  fetchEnvironmentsUpdate,
  environmentsConfig,
  sampleEnvironmentData
}) => {
  const [environmentDetails, setEnvironmentDetails] = useState({});
  const [showConfirmCredModal, setShowConfirmCredModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [webAuthFields, setWebAuthFields] = useState(null)
  const [testInProgress, settestInProgress] = useState(false)
  const [showAuthError, setShowAuthError] = useState(false)
  const [showAuthHint, setShowAuthHint] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showSuccessMsg, setshowSuccessMsg] = useState(false)
  const [addEnvironment, setAddEnvironment] = useState(false)
  const [authenticationErrMessage, setauthenticationErrMessage] = useState(null)

  useEffect(() => {
    if (environmentsConfig) {
      let authFields = environmentsConfig.webAuthenticationFields;
      setWebAuthFields(authFields);
      let authHint = true;
      if (authFields) {
        if (authFields.usernameXPath && authFields.usernameXPath.length > 0 &&
          authFields.passwordXPath && authFields.passwordXPath.length > 0 &&
          authFields.submitBtnXPath && authFields.submitBtnXPath.length > 0 &&
          authFields.successCheckXPath && authFields.successCheckXPath.length > 0
        ) {
          authHint = false;
        }
      }
      setShowAuthHint(authHint);
    }
  }, [environmentsConfig]);
  const handleTestAuthentication = async (e) => {
    e.preventDefault();
    setShowAuthError(false)
    setshowSuccessMsg(false)
    settestInProgress(true)
    setauthenticationErrMessage(null)
    let testResp = await dataService.testwebauthentication({
      credentials:environmentDetails.credentials,
      webAuthenticationURL: environmentDetails.webAuthenticationURL,
      webAuthFields
    });
    if(testResp) {
      if(testResp.data.success) {
        setshowSuccessMsg(true)
      } else {
        setShowAuthError(true);
        setauthenticationErrMessage(testResp.data.authenticationStatus)
      }
      settestInProgress(false)
    }
  }
  const uploadCredentials = async (e, environmentID) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      let creds = [];
      workbook.SheetNames.forEach((sheetName) => {
        creds = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
      });
      await dataService.updateEnvironmentCredentials({
        credentials: creds,
        id: environmentID
      });
      fetchEnvironmentsUpdate && fetchEnvironmentsUpdate();
    };
    reader.readAsArrayBuffer(file);
  };

  const deleteCreds = async (event, environmentDetails) => {
      setEnvironmentDetails(environmentDetails);
      setShowConfirmCredModal(true);
  };

  const deleteCredsConfirm = async () => {
    await dataService.deleteEnvironmentCredentials({
      id: environmentDetails._id
    });
    fetchEnvironmentsUpdate && fetchEnvironmentsUpdate();
    setShowConfirmCredModal(false);
  };

  const confirmDelete = env => {
    setEnvironmentDetails(env);
    setShowConfirmModal(true);
  }

  const skipKeys = ['Claude_ModelName', 'Claude_APIKey', 'TenantKey'];
  const hasEmptyFields = (obj) => {
    return Object.entries(obj).some(([key, value]) => {
      // Skip keys that are in the skipKeys array
      if (skipKeys.includes(key)) {
          return false;
      }
      // Check if the value is empty, null, or undefined
      return value === '' || value === null || value === undefined;
    });
  };
  
  const closeModal = () => {
    setOpenEditModal(false);
  };

  const editEnvironment = environment => {
    setEnvironmentDetails(environment);
    setOpenEditModal(true);
  };

  const deleteEnvironment = async (id) => {
    await dataService.deleteEnvironmentItem({
      id: environmentDetails._id
    });
    setOpenEditModal(false);
    setShowConfirmModal(false);
    fetchEnvironmentsUpdate && fetchEnvironmentsUpdate();
  };

  const updateEnvironment = async () => {
    await dataService.updateEnvironmentItem({
      environment: environmentDetails,
    });
    setOpenEditModal(false);
    fetchEnvironmentsUpdate && fetchEnvironmentsUpdate();
  };

  const openAddEnvironment = () => {
     setAddEnvironment(true);
  };

  const maskInput = (input) => {
    if (input) {
      return input.substring(0, 10) + '…';
    }
  }

  return (
    <div className="table-list-section p-5 rounded-l mt-6">
      <div className="flex align-center form-field justify-between mb-6">
        <h2 className="mr-1">Environments</h2>
        <div>
          <button type="button" onClick={openAddEnvironment} className="btn-medium-2 btn-medium-width mr-3">
            Add Environment
          </button>
          <ExcelDownload data={[sampleEnvironmentData]} title="Download sample data" />
        </div>
      </div>
      <div className="environment_lists justify-between flex-wrap">
        {environments.map((env, index) => (
          <div
            className="environment_lists_info radius-8 p-4 w-2/6 my-2 word-break-all"
            key={env._id}
          >
            <div className="justify-between align-center">
              <h3 className="word-break mr-2">{env?.name?.toUpperCase()}</h3>
              <div className="justify-between align-center">
                <button
                  type="button"
                  className="mr-4 bg-none no-border"
                  onClick={() => editEnvironment(env)}
                >
                  <AppIcon iconName="editIcon" iconColor="#1B72E6" />
                </button>
                <ExcelDownload data={[env]} />
                <button
                  type="button"
                  className="p-2 bg-none no-border"
                  onClick={() => confirmDelete(env)}
                >
                  <AppIcon iconName="delete" iconColor="#1B72E6" />
                </button>
              </div>
            </div>
            <div className="environment_lists_info_details mt-2 word-break-all">
              <p className="line-height-140 mb-1">Web Host URL</p>
              <span className="line-height-140">{env.host}</span>
            </div>
            <div className="environment_lists_info_details mt-6 word-break-all">
              <p className="line-height-140 mb-1">API Host URL</p>
              <span className="line-height-140">{env.apiHost}</span>
            </div>
            <div className="environment_lists_info_details mt-6 word-break-all">
              <p className="line-height-140 mb-1">API Authentication URL</p>
              <span className="line-height-140">
                {env.authenticationURL}
              </span>
            </div>
            <div className="environment_lists_info_details mt-2 word-break-all">
              <p className="line-height-140 mb-1">Web Authentication URL</p>
              <span className="line-height-140">
                {env.webAuthenticationURL}
              </span>
            </div>
            <div className="environment_lists_info_details mt-2 word-break-all">
              <p className="line-height-140 mb-1">OpenAI APIKey</p>
              <span className="line-height-140">{maskInput(env.apiKey)}</span>
            </div>
            <div className="environment_lists_info_details mt-2 word-break-all">
              <p className="line-height-140 mb-1">OpenAI Model Name 3</p>
              <span className="line-height-140">{env.model3}</span>
            </div>
            <div className="environment_lists_info_details mt-2 word-break-all">
              <p className="line-height-140 mb-1">OpenAI Model Name 4</p>
              <span className="line-height-140">{env.model4}</span>
            </div>
            <div className="environment_lists_info_details mt-2 word-break-all">
              <p className="line-height-140 mb-1">Tenant Key</p>
              <span className="line-height-140">{env.TenantKey}</span>
            </div>
            <div className="environment_lists_info_details mt-2 word-break-all">
              <p className="line-height-140 mb-1">Claude Model Name</p>
              <span className="line-height-140">{env.Claude_ModelName}</span>
            </div>
            <div className="environment_lists_info_details mt-2 word-break-all">
              <p className="line-height-140 mb-1">Claude API Key</p>
              <span className="line-height-140">{maskInput(env.Claude_APIKey)}</span>
            </div>
            <div className="environment_lists_info_details mt-2 word-break-all">
              <p className="line-height-140 mb-1">Credentials</p>
              {env.credentials === undefined || env?.credentials.length === 0 ? (
                <div className="text-center">
                  <label
                    htmlFor={`file-upload2_${env._id}`}
                    className={`custom-file-upload p-2 no-border w-full upload-creds`}
                  >
                    <AppIcon
                      iconColor="#1B72E6"
                      iconName="uploadIcon"
                    />
                    <span className="ml-2">Upload credentials</span>
                  </label>
                  <input
                    id={`file-upload2_${env._id}`}
                    className="file-upload"
                    type="file"
                    data-record={env._id}
                    onChange={(event) => uploadCredentials(event, env._id)}
                  />
                </div>
               ) : (
                <div className="align-center justify-between btn-medium-2 download-creds flex">
                  <p className="text-center">Download {env.name} Creds</p>
                  <div className="align-center">
                    <ExcelDownload data={env.credentials} />
                    <button
                      type="button"
                      onClick={event => deleteCreds(event, env)}
                      className="bg-none no-border ml-2"
                    >
                      <AppIcon iconName="delete" iconColor="#1B72E6" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      {openEditModal && (
        <AppModal>
          <div>
            <div className="justify-between align-center modal-title word-break-all">
              <div className="align-center">
                <h2 className="h2-heading">
                  Edit {environmentDetails?.name?.toUpperCase()}
                </h2>
              </div>
              <button
                type="button"
                onClick={closeModal}
                className="bg-none no-border"
              >
                <AppIcon iconName="closeIcon" iconColor="#1B72E6" />
              </button>
            </div>
            <div className="env-config-modal-content modal-form-content word-break-all">
              <div className="mb-8 form-field">
                <label className="white-space-no-wrap mr-2 bold w-full d-block label mb-2">
                Web Host URL
                </label>
                <input
                  type="text"
                  value={environmentDetails.host}
                  className="input-field w-full input-txt"
                  onChange={(event) =>
                    setEnvironmentDetails({
                      ...environmentDetails,
                      host: event.target.value,
                    })
                  }
                />
                {environmentDetails.host.length === 0 ? <p className="font-danger bold">This field is required</p> : null }
              </div>
              <div className="mb-8 form-field">
                <label className="white-space-no-wrap mr-2 bold w-full d-block label mb-2">
                  API Host URL
                </label>
                <input
                  type="text"
                  value={environmentDetails.apiHost}
                  className="input-field w-full input-txt"
                  onChange={(event) =>
                    setEnvironmentDetails({
                      ...environmentDetails,
                      apiHost: event.target.value,
                    })
                  }
                />
                {environmentDetails.apiHost && environmentDetails.apiHost.length === 0 ? <p className="font-danger bold">This field is required</p> : null }
              </div>
              <div className="mb-8 form-field">
                <label className="white-space-no-wrap mr-2 bold w-full d-block label mb-2">
                API Authentication URL
                </label>
                <input
                  type="text"
                  value={environmentDetails.authenticationURL}
                  className="input-field w-full input-txt word-break-all"
                  onChange={(event) =>
                    setEnvironmentDetails({
                      ...environmentDetails,
                      authenticationURL: event.target.value,
                    })
                  }
                />
                {environmentDetails.authenticationURL.length === 0 ? <p className="font-danger bold">This field is required</p> : null }
              </div>
              <div className="mb-8 form-field">
                <label className="white-space-no-wrap mr-2 bold w-full d-block label mb-2">
                  Web Authentication URL
                </label>
                <div className="flex align-center">
                  <input
                    type="text"
                    value={environmentDetails.webAuthenticationURL}
                    className="input-field flex-1 input-txt word-break-all"
                    onChange={(event) =>
                      setEnvironmentDetails({
                        ...environmentDetails,
                        webAuthenticationURL: event.target.value,
                      })
                    }
                  />
                  <div className="pl-3">
                    <button className="btn-medium-3" onClick={handleTestAuthentication} disabled={showAuthHint || testInProgress}>Test Authentication</button>
                  </div>
                </div>
                {environmentDetails.webAuthenticationURL.length === 0 ? <p className="font-danger bold">This field is required</p> : null }
                {
                  showAuthHint && (
                    <p className="sub-heading-3">Please fill authentication related information in Global settings.</p>
                  )
                }
                {
                  showAuthError && (
                    <p className="sub-heading-3 font-danger">
                      { authenticationErrMessage || 'Web authentication failed, please check all authentication related information.'}
                    </p>
                  )
                }
                {
                  showSuccessMsg && (
                    <p className="sub-heading-3 font-success">Web authentication success.</p>
                  )
                }
              </div>
              <div className="mb-8 form-field">
                <label className="white-space-no-wrap mr-2 bold w-full d-block label mb-2">
                  Tenant Key
                </label>
                <input
                  type="text"
                  value={environmentDetails.TenantKey}
                  className="input-field w-full input-txt"
                  onChange={(event) =>
                    setEnvironmentDetails({
                      ...environmentDetails,
                      TenantKey: event.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="flex justify-end px-6 py-4">
              <button
                onClick={updateEnvironment}
                type="button"
                className={`btn-medium-2 btn-medium-width px-4 ${hasEmptyFields(environmentDetails) ? 'disabled': ''}`}
              >
                Save
              </button>
            </div>
          </div>
        </AppModal>
      )}
      {addEnvironment && <AddNewEnvironment onClose={() => setAddEnvironment(false)}/>}
      
      {showConfirmModal && (<ConfirmModal 
      confirmationTitle={'Are you sure you want to delete the environment details?'}
      onDelete={deleteEnvironment} onClose={() => setShowConfirmModal(false)}/>)}
      {showConfirmCredModal && (<ConfirmModal 
      confirmationTitle={'Are you sure you want to delete the credentials?'}
      onDelete={deleteCredsConfirm} onClose={() => setShowConfirmCredModal(false)}/>)}
    </div>
  );
};