import { useState, useEffect } from 'react'
import workflowsService from '../../services/workflows.service'
import AppIcon from '../atoms/AppIcon/AppIcon'
import ConfirmSaveModal from './ConfirmSaveModal'
import AppModal from '../organisms/AppModal'
import { Tooltip } from 'react-tooltip';
export default ({ workflow, job, stepNo, handleSelectSuggestionStep }) => {
    const [showRecommendationsZero, setshowRecommendationsZero] = useState(false)
    const [showRecommendations, setshowRecommendations] = useState(false)
    const [recommendationsList, setrecommendationsList] = useState([])
    const [suggestingStep, setsuggestingStep] = useState(0)
    const [selectedSuggestionIndex, setselectedSuggestionIndex] = useState(-1)
    const handleSelectXPath = () => {
        handleSelectSuggestionStep && handleSelectSuggestionStep(recommendationsList[selectedSuggestionIndex])
        setshowRecommendations(false)
    }
    const handleXpathRecommendations = async (e) => {
        e.preventDefault();
        if (stepNo) {
            // let failedMessage = (recentJob.failedReason?.message || recentJob.failedReason || '').toLowerCase();
            // let isXpathError = failedMessage.indexOf('find element') >= 0 || failedMessage.indexOf('xpath') >= 0;
            // let firstFailedStep = recentJob.priority?.length > 0 ? recentJob.priority.findIndex(x => x == 'P0') : 0;
            // let stepNo = firstFailedStep + 1; 
        }
        const apiResp = await workflowsService.getxpathrecommendations({
            steps: workflow.steps,
            stepNo: stepNo,
            jobExectedTimestamp: job?.jobCompletedAt || job?.jobStartedAt,
            recommendationId: workflow.recommendationId
        })
        setsuggestingStep(stepNo);
        setselectedSuggestionIndex(-1)
        const { data = {} } = apiResp || {};
        const { xpath_recommendations = [] } = data;
        if (xpath_recommendations.length > 0) {
            setrecommendationsList(xpath_recommendations)
            setshowRecommendations(true);
        } else {
            setshowRecommendationsZero(true)
        }
    }
    if (!(workflow && workflow.recommendationId)) {
        return;
    }
    return (
        <>
            <div className="ml-2 flex wizard-details pointer" id="wizardstep">
                <label className="mr-1 py-2 bold">Fix Step</label>
                <img src="/icons/info-svg.svg" className="svg-icon mr-2 mt-2" />
                <Tooltip
                anchorSelect="#wizardstep"
                content="Automatically fix the xPath or URL issues in the step."
                place="top-start"
                style={{
                    backgroundColor: "#000",
                    color: "#ffffff",
                    borderRadius: "5px",
                    zIndex: 22,
                    fontWeight: "bold",
                    width: "50%",
                }}
                />
                <button className="wizard-icon" type='button' onClick={handleXpathRecommendations}>
                    <AppIcon iconName="wizard" />
                </button>
            </div>
            {showRecommendationsZero && (
                <ConfirmSaveModal
                    confirmationTitle={'No recommendations were found.'}
                    confirmButtonLabel={'Close'}
                    showCancel={false}
                    onOk={() => {
                        setshowRecommendationsZero(false)
                    }}
                />
            )}

            {
                showRecommendations && (
                    <AppModal>
                        <div className="justify-between align-center modal-title">
                            <div className="align-center">
                                <h2 className="h2-heading">
                                    XPath Recommendations
                                </h2>
                            </div>
                            <button
                                type="button"
                                onClick={e => {
                                    setshowRecommendations(false)
                                }}
                                className="bg-none no-border"
                            >
                                <AppIcon iconName="closeIcon" iconColor="#1B72E6" />
                            </button>
                        </div>

                        <div className='env-config-modal-content modal-form-content'>
                            {
                                suggestingStep > 0 && (
                                    <div>
                                        <label className='label'>Step to Replace:</label>
                                        <div className='recommendation-item mt-2'>
                                            <div className='flex justify-between'>
                                                <div>Action: {workflow.steps[suggestingStep - 1].action}</div>
                                                <div className="form-field-group-checkbox__item width-auto">
                                                </div>
                                            </div>
                                            <div className='mt-1'>
                                                StepName: {workflow.steps[suggestingStep - 1].stepName}
                                            </div>
                                            <div className='flex justify-between mt-1'>
                                                {
                                                    workflow.steps[suggestingStep - 1].element && (
                                                        <div>Element Name: {workflow.steps[suggestingStep - 1].element}</div>
                                                    )
                                                }
                                            </div>
                                            <div className='flex justify-between mt-1'>
                                                {
                                                    workflow.steps[suggestingStep - 1].elementId && (
                                                        <div>Element Id: {workflow.steps[suggestingStep - 1].elementId}</div>
                                                    )
                                                }
                                            </div>
                                            <div className='mt-1'>
                                                <div><span>xPath:</span> {workflow.steps[suggestingStep - 1].xPath}</div>
                                            </div>
                                            <div className='flex justify-between mt-1'>
                                                <div>Value: {workflow.steps[suggestingStep - 1].value}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            <label className='label my-2'>
                                Select suitable suggestion from one of the below.
                            </label>
                            {
                                <div className="radio-group">
                                    {
                                        recommendationsList && recommendationsList.map((item, idx) => {
                                            return (
                                                <div className='recommendation-item mb-2'>
                                                    <label className='recommendation-item__label' htmlFor={`radio-check-${idx}`}>
                                                        <div className='flex justify-between'>
                                                            <div>Action: {item.actionType} (No of Users: {item.no_of_users})</div>
                                                            <div className="form-field-group-checkbox__item width-auto">
                                                                <label htmlFor={`radio-check-${idx}`} className="form-field-checkbox mr-2">
                                                                    <input type="radio" name={`radio-check-${idx}`} id={`radio-check-${idx}`} onChange={e => {
                                                                        setselectedSuggestionIndex(idx)
                                                                    }} {...(selectedSuggestionIndex == idx ? { checked: true } : {})} />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className='flex justify-between mt-1'>
                                                            {
                                                                item.elementName && (
                                                                    <div>Element Name: {item.elementName}</div>
                                                                )
                                                            }
                                                        </div>
                                                        <div className='flex justify-between mt-1'>
                                                            {
                                                                item.elementId && (
                                                                    <div>Element Id: {item.elementId}</div>
                                                                )
                                                            }
                                                        </div>
                                                        <div className='flex justify-between mt-1'>
                                                            {
                                                                item.page_title && (
                                                                    <div>Page Name: {item.page_title}</div>
                                                                )
                                                            }
                                                        </div>
                                                        <div className='flex justify-between mt-1'>
                                                            {
                                                                item.pageURL && (
                                                                    <div>Page Url: {item.pageURL}</div>
                                                                )
                                                            }
                                                        </div>
                                                        <div className='flex justify-between mt-1'>
                                                            {
                                                                item.elementLink && (
                                                                    <div>Element Link: {item.elementLink}</div>
                                                                )
                                                            }
                                                        </div>

                                                        <div className='mt-1'>
                                                            <div><span>xPath:</span> {item.elementXPath}</div>
                                                            {/* <div className='old-xpath'><span className='visibility-hidden'>xPath:</span> {workflow.steps[suggestingStep - 1].xPath}</div> */}
                                                        </div>
                                                        <div className='flex justify-between mt-1'>
                                                            <div>Value: {item.elementValue}</div>
                                                        </div>
                                                    </label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }

                        </div>

                        <div className="flex justify-between px-6 py-4">
                            <div></div>
                            <button
                                onClick={handleSelectXPath}
                                type="button"
                                className={`white-space-no-wrap btn-medium-2 btn-medium-width px-4 ${selectedSuggestionIndex >= 0 ? '' : 'disabled'}`}
                            >
                                Update Step
                            </button>
                        </div>
                    </AppModal>
                )
            }
        </>
    )
}