export const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    let month = now.getMonth() + 1; // Month starts from 0
    let day = now.getDate();
  
    // Pad month and day with leading zeros if necessary
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;
  
    return `${year}-${month}-${day}`;
  };

export const generateRandomId = () => {
  // Generate a random number between 10000 and 99999 (inclusive)
  const randomNumber = Math.floor(Math.random() * 90000) + 10000;
  return randomNumber.toString(); // Convert the number to a string
};

export const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const month = date.getMonth() + 1; // Add 1 because getMonth() returns zero-based index
  const day = date.getDate();
  const year = date.getFullYear();

  // Format the date as MM/DD/YYYY
  return `${month}/${day}/${year}`;
}