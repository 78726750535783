export default () => {
    return (
        <>
            <div className="form-field-group-checkbox mt-4">
                <div className="form-field-group-checkbox__label mb-4 field-label-heading">
                    Status
                </div>
                <div className="form-field-group-checkbox__items radio-group">
                    <div className="form-field-group-checkbox__item">
                        <label className="form-field-checkbox mr-2">
                            <input type="radio" name="status"/>
                            <span className="checkmark"></span>
                        </label>
                        <label>All</label>
                    </div>
                    <div className="form-field-group-checkbox__item radio-group">
                        <label className="form-field-checkbox mr-2">
                            <input type="radio" name="status"/>
                            <span className="checkmark"></span>
                        </label>
                        <label>Passed</label>
                    </div>
                    <div className="form-field-group-checkbox__item radio-group">
                        <label className="form-field-checkbox mr-2">
                            <input type="radio" name="status"/>
                            <span className="checkmark"></span>
                        </label>
                        <label>Failed</label>
                    </div>
                </div>
            </div>
        </>
    )
}