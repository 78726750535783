import { useState, useEffect } from "react"
import licenseService from "../../../services/license.service"
import storeService from "../../../services/store.service"
import utilsService from "../../../services/utils.service"
import SectionLoader from "../../atoms/SectionLoader"
import TrailVersionDisplay from "./TrailVersionDisplay"
import LicenseDetails from "./LicenseDetails"

export default ({ onSuccessLicense, mode }) => {
    const [loading, setLoading] = useState(null)
    const [items, setItems] = useState([])
    const [licenseKey, setLicenseKey] = useState('')
    const [licenseKeyError, setlicenseKeyError] = useState(false)
    const [activatedLicense, setactivatedLicense] = useState(null)
    const [invalidKeyError, setinvalidKeyError] = useState(false)
    const loadLiceses = () => {
        
        licenseService.loadlicense({}).then(apiResp => {
            setLoading(false)
            let list = storeService.store.LICENSE_LIST.items;
            setItems([...list])
            if (list.length > 0) {
                setactivatedLicense(list[0])
            }
        })
    }
    const handleActivateKey = () => {
        if (!(licenseKey && licenseKey.length > 0)) {
            setlicenseKeyError(true)
            return
        }
        setLoading(true)
        licenseService.activatelicense({
            licenseKey
        }).then(apiResp => {
            const { data = {} } = apiResp || {};
            const { customerId, expiryDate } = data;
            if (customerId) {
                setLoading(true)
                onSuccessLicense && onSuccessLicense();
                loadLiceses()
            } else {
                setinvalidKeyError(true)
            }
        }).finally(() => {
            setLoading(false)
        })
    }
    useEffect(() => {
        loadLiceses()
    }, [])

    return (
        <div className="pos-rel">
            <div className="modal-form-content">
                <h2 className="h2-heading mb-8">License Details</h2>
                {
                    loading != null && items.length == 0 && (
                        <>
                            <TrailVersionDisplay />
                            <div className="mt-3 license-form">
                                <div className={`mb-4 ` + (licenseKeyError ? 'error ' : '')}>
                                    <label className="white-space-no-wrap mr-2 bold w-full d-block mb-2 label">
                                        License Key
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="xxxx-xxxx-xxxx-xxxx"
                                        value={licenseKey}
                                        onChange={e => {
                                            setlicenseKeyError(false)
                                            setLicenseKey(e.target.value)
                                        }}
                                        className={`input-field w-full p-2 input-txt ` + ((licenseKeyError || invalidKeyError) ? ' invalid' : '')}
                                    />
                                    {
                                        (licenseKeyError || invalidKeyError) && (
                                            <>
                                                {
                                                    invalidKeyError ? (
                                                        <p className="error-message">Invalid license key, please double check it.</p>
                                                    ) : (
                                                        <p className="error-message">This is required field</p>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                </div>
                                <div className="flex justify-between align-center">
                                    {
                                        mode == 'PAGE' && (
                                            <a className="btn-link" href="/">Back to Dashboard</a>
                                        )
                                    }
                                    <button
                                        disabled={loading}
                                        onClick={handleActivateKey}
                                        type="button"
                                        className="btn-medium-2 btn-medium-width">
                                        Activate now
                                    </button>
                                </div>
                            </div>
                        </>
                    )
                }
                {
                    activatedLicense && (
                        <LicenseDetails activatedLicense={activatedLicense} onSuccessLicense={onSuccessLicense} />
                    )
                }
            </div>
            {
                loading && (
                    <SectionLoader></SectionLoader>
                )
            }
        </div>
    )
}