import { useState, useEffect, Fragment } from "react";
import CreatableSelect from 'react-select/creatable';
// import AutoSuggest from "../AutoSuggest/AutoSuggest";

const createOption = (label) => ({
  label,
  value: label,
});

const Collections = ({
  collectionSaved,
  data,
  regressionToAdd,
  documentId,
  records,
  // selectedRegressions,
  selectedSteps,
  pagePerformanceData,
  setUserFlowRegressionTags,
  setSelectedSteps,
  runNow,
  updateRunNow
}) => {
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState([]);
  const [selectedTags, setSelectedTags] = useState([])

  return (
    <div className={`collections ${collectionSaved ? 'collections-empty' : ''}`}>
      {!collectionSaved ? (
        <Fragment>
          <div className="collections-list w-full pt-5">
            <div className="align-center justify-between">
              <h4 className="collections-list-title">Regression to be added</h4>
            </div>
            <ul className="collections-list-items no-disc mt-2">
              {selectedSteps.map((step, i) => {
                return <li className="mb-3" key={i}>
                  <input value={step.regressionName} type="text" className="input-txt" onChange={e => setSelectedSteps && setSelectedSteps([
                    ...selectedSteps.slice(0, i),
                    { ...selectedSteps[i], regressionName: e.target.value },
                    ...selectedSteps.slice(i + 1)
                  ])} />
                </li>
              })}
            </ul>
            <div className="form-field-select mb-2">
              <label className="label mb-2">Create or Select a Tag</label>
              <div className="align-center mb-2">
                <div className="form-field">
                  <CreatableSelect
                    inputValue={inputValue}
                    isClearable
                    isMulti
                    onChange={(tagValues) => {
                      let uList = tagValues.map((item) => item.value);
                      setSelectedTags(uList)
                      setValue(tagValues);
                      setUserFlowRegressionTags(uList)
                    }}
                    onBlur={(e) => {
                      const currentTargetVal = e.currentTarget.value;
                      if (currentTargetVal) {
                        let tagText = currentTargetVal;
                        tagText = tagText.replace(/[^a-zA-Z0-9 ]/g, '')
                        setValue((prev) => [...prev, createOption(tagText)]);
                        setInputValue('');
                        let uList = [...selectedTags, tagText];
                        setSelectedTags(uList)
                        setUserFlowRegressionTags(uList)
                      }
                    }}
                    onInputChange={(newValue) => {
                      setInputValue(newValue.replace(/[^a-zA-Z0-9 ]/g, ''))
                    }}
                    onKeyDown={event => {
                      if (!inputValue) return;
                      switch (event.key) {
                        case 'Enter':
                        case 'Tab':
                          let tagText = inputValue;
                          tagText = tagText.replace(/[^a-zA-Z0-9 ]/g, '')
                          setValue((prev) => [...prev, createOption(tagText)]);
                          setInputValue('');
                          let uList = [...selectedTags, tagText];
                          setSelectedTags(uList)
                          setUserFlowRegressionTags(uList)
                          event.preventDefault();
                      }
                    }}
                    placeholder="Create or Select a Tag and press enter..."
                    value={value}
                    defaultInputValue={[]}
                    options={[]}
                  />
                </div>
              </div>
            </div>

            <div className="p-2 flex">
              <label
                htmlFor="runTestsNow"
                className="form-field-checkbox"
              >
                <input
                  id="runTestsNow"
                  onChange={(e) => {
                    updateRunNow(e.target.checked)
                  }}
                  checked={runNow}
                  type="checkbox"
                />
                <span className="checkmark"></span>
              </label>
              <label htmlFor="runTestsNow" className="ml-2">
                Run tests now
              </label>
            </div>
          </div>
        </Fragment>
      ) : (
        <p className="text-center h3-heading">Collection saved successfully</p>
      )}
    </div>
  );
};

export default Collections;
