import { useState, useEffect } from "react"
import AppIcon from "../../comps/atoms/AppIcon/AppIcon"
import AppModal from "../../comps/organisms/AppModal"
import tenantsService from "../../services/tenants.service";
import utilsService from "../../services/utils.service";
import Select from 'react-select';
import storeService from "../../services/store.service";
import apiService from "../../services/api.service";

export default (props) => {
    const [saving, setSaving] = useState(false)
    const [tenant, setTenant] = useState(props.tenant);
    const [selectedEnv, setselectedEnv] = useState(props.selectedEnv)
    const [name, setName] = useState({
        value: '',
        isError: false
    })
    const [copyFromOptions, setCopyFromOptions] = useState([
        {
            value: '',
            label: 'Select'
        }
    ])
    const [selectedCopyOption, setSelectedCopyOption] = useState('')
    const [legacyEnvOptions, setlegacyEnvOptions] = useState([])
    const [selectedLegacyEnv, setselectedLegacyEnv] = useState('')
    const [environmentSections, setEnvironmentSections] = useState([
        {
            name: 'Web details'
        },
        {
            name: 'API details'
        },
        {
            name: 'Open AI details'
        },
        {
            name: 'Claude AI details'
        }
    ])
    const [environmentVariables, setEnvironmentVariables] = useState([
        {
            name: 'host',
            label: 'Web Host URL',
            type: 'text'
        },
        {
            name: 'webAuthenticationURL',
            label: 'Web Authentication URL',
            type: 'text'
        },
        {
            name: 'apiHost',
            label: 'API Host URL',
            type: 'text'
        },
        {
            name: 'authenticationURL',
            label: 'API Authentication URL',
            type: 'text'
        },
        {
            name: 'TenantKey',
            label: 'Tenant Key',
            type: 'text'
        },
        {
            name: 'userID',
            label: 'Username',
            type: 'text'
        },
        {
            name: 'password',
            label: 'Password',
            type: 'text'
        }
    ])
    const [environmentValues, setEnvironmentValues] = useState({
        host: {
            value: ''
        }
    })
    const saveEnvironment = async () => {
        setSaving(true)
        let inValidCount = 0;
        if (!(name.value && name.value.length > 1)) {
            inValidCount += 1;
            setName({
                ...name,
                isError: true
            })
        }

        if (inValidCount === 0) {
            let environments = [];
            if (tenant.environments) {
                environments = tenant.environments;
            }
            if (selectedEnv) {
                let environmentToUpdate = environments.find(x => x._id == selectedEnv._id);
                if (environmentToUpdate) {
                    environmentVariables.forEach(field => {
                        environmentToUpdate[field.name] = environmentValues[field.name]?.value || '';
                    })
                }
            } else {
                let values = {};
                environmentVariables.forEach(field => {
                    values[field.name] = environmentValues[field.name]?.value || '';
                })
                environments.push({
                    _id: utilsService.guid(8),
                    name: name.value,
                    ...values
                })
            }
            await tenantsService.updatetenant({
                _id: tenant._id,
                fieldsToUpdate: {
                    environments
                }
            });
            props.closeModal()
            tenantsService.loadTenants()
        }
        setSaving(false)
    }
    const constructOptions = (tenants) => {
        let items = [];
        tenants.forEach(tenant => {
            if (tenant.environments && tenant.environments.length > 1) {
                tenant.environments.forEach(env => {
                    items.push({
                        value: `${tenant.name}||${env.name}`,
                        label: `${tenant.name}-${env.name}`
                    })
                })
            }
        })
        setCopyFromOptions([{ value: '', label: 'Select' }, ...items])
    }
    const constructLegacyEnvOptions = (envList) => {
        let items = [];
        envList.forEach(env => {
            items.push({
                value: `${env.name}`,
                label: `${env.name}`
            })
        })
        setlegacyEnvOptions([{ value: '', label: 'Select' }, ...items])
    }
    const handleImportClick = () => {
        let [tenantName, envName] = selectedCopyOption.split('||');
        let selectedTenant = storeService.store.TENANTS.items.find(x => x.name == tenantName);
        if (selectedTenant) {
            let selectedEnv = selectedTenant.environments.find(x => x.name == envName);
            if (selectedEnv) {
                let envValues = {};
                environmentVariables.forEach(field => {
                    if (selectedEnv[field.name]) {
                        if (!envValues[field.name]) {
                            envValues[field.name] = {
                                value: ''
                            }
                        }
                        envValues[field.name].value = selectedEnv[field.name];
                    }
                })
                setEnvironmentValues({
                    ...environmentValues,
                    ...envValues
                })
            }
        }
        setSelectedCopyOption('');
    }
    const handleLegacyImportClick = () => {
        let selectedLegacyEnvironment = storeService.store.ENVIRONMENTS.items.find(x => x.name == selectedLegacyEnv);
        if (selectedLegacyEnvironment) {
            let envValues = {};
            environmentVariables.forEach(field => {
                if (selectedLegacyEnvironment[field.name]) {
                    if (!envValues[field.name]) {
                        envValues[field.name] = {
                            value: ''
                        }
                    }
                    envValues[field.name].value = selectedLegacyEnvironment[field.name];
                }
            })
            if (selectedLegacyEnvironment.credentials && selectedLegacyEnvironment.credentials.length > 0) {
                let firstCreds = selectedLegacyEnvironment.credentials[0];
                envValues['userID'] = {
                    value: firstCreds.userID
                }
                envValues['password'] = {
                    value: firstCreds.password
                }
            }
            setEnvironmentValues({
                ...environmentValues,
                ...envValues
            })
        }
        setselectedLegacyEnv('')
    }
    const getEnvironments = async () => {
        const environmentsResp = await apiService.get({
            url: utilsService.END_POINTS.getEnvironments,
        });
        const { data = [] } = environmentsResp;
        constructLegacyEnvOptions(data)
    };
    useEffect(() => {
        if (selectedEnv) {
            setName({
                value: selectedEnv.name
            });
            // environmentValues
            environmentVariables.forEach(field => {
                if (selectedEnv[field.name]) {
                    if (!environmentValues[field.name]) {
                        environmentValues[field.name] = {
                            value: ''
                        }
                    }
                    environmentValues[field.name].value = selectedEnv[field.name];
                }
            })
            setEnvironmentValues({
                ...environmentValues
            })
        }
        getEnvironments()
        constructOptions(storeService.store.TENANTS.items);
    }, [])
    return (
        <AppModal>
            <div className="justify-between align-center modal-title">
                <div className="align-center">
                    <h2 className="h2-heading">
                        {
                            selectedEnv ? (
                                `Edit environment ${selectedEnv.name} in ${tenant.name}`
                            ) : (
                                `Add environment in ${tenant.name}`
                            )
                        }

                    </h2>
                </div>
                <button
                    type="button"
                    onClick={e => {
                        props.closeModal()
                    }}
                    className="bg-none no-border"
                >
                    <AppIcon iconName="closeIcon" iconColor="#1B72E6" />
                </button>
            </div>
            <div className="p-4 overflow-y-scroll add-edit-environment-modal-content modal-form-content">
                <div className="form-fields">
                    <div className="form-fields-section">
                        <div className={` mb-4 ${name.isError ? 'error' : ''}`}>
                            <label className="white-space-no-wrap mr-2 bold w-full d-block mb-2 label">
                                Environment name
                            </label>
                            <input
                                type="text"
                                disabled={selectedEnv}
                                value={name.value}
                                onChange={e => {
                                    let nameVal = e.target.value;
                                    setName({
                                        value: nameVal,
                                        isError: !(nameVal && nameVal.length > 0)
                                    })
                                }}
                                className="input-field w-full p-2 input-txt"
                            />
                            {
                                name.isError && (
                                    <p className="sub-heading-3 font-danger">
                                        {'Name of the environment is required.'}
                                    </p>
                                )
                            }
                        </div>
                        <div className="mb-8 flex align-center justify-between">
                            <div className="flex align-center">
                                <div style={{ minWidth: '300px', maxWidth: '500px' }}>
                                    <Select onChange={val => {
                                        setSelectedCopyOption(val.value);
                                    }} options={copyFromOptions} value={copyFromOptions.find(x => x.value == selectedCopyOption)} />
                                </div>
                                <div className="ml-3">
                                    <button
                                        disabled={!selectedCopyOption}
                                        onClick={handleImportClick}
                                        type="button"
                                        className="btn-medium-2 px-4"
                                    >
                                        Import
                                    </button>
                                </div>
                            </div>
                            <div className="flex align-center">
                                <div style={{ minWidth: '300px', maxWidth: '500px' }}>
                                    <Select onChange={val => {
                                        setselectedLegacyEnv(val.value);
                                    }} options={legacyEnvOptions}
                                        value={legacyEnvOptions.find(x => x.value == selectedLegacyEnv)} />
                                </div>
                                <div className="ml-3">
                                    <button
                                        disabled={!selectedLegacyEnv}
                                        onClick={handleLegacyImportClick}
                                        type="button"
                                        className="btn-medium-2 px-4"
                                    >
                                        Import
                                    </button>
                                </div>
                            </div>
                        </div>
                        {
                            environmentVariables.map(variable => {
                                return (
                                    <div className="mb-8">
                                        <label className="white-space-no-wrap mr-2 bold w-full d-block mb-2 label">
                                            {variable.label}
                                        </label>
                                        <input
                                            type="text"
                                            value={environmentValues[variable.name]?.value}
                                            onChange={e => {
                                                setEnvironmentValues({
                                                    ...environmentValues,
                                                    [variable.name]: {
                                                        value: e.target.value
                                                    }
                                                })
                                            }}
                                            className="input-field w-full p-2 input-txt"
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="flex justify-end px-6 py-4 modal-footer">
                <button
                    disabled={saving}
                    onClick={() => saveEnvironment()}
                    type="button"
                    className="btn-medium-2 btn-medium-width px-4"
                >
                    Save
                </button>
            </div>
        </AppModal>
    )
}