import React from 'react';
import PageActionContent from '../../comps/organisms/PageActionContent/PageActionContent.jsx';
const UsageBasedRegression = () => {
    return (
        <div className="">
          <PageActionContent></PageActionContent>
        </div>
    );
};

export default UsageBasedRegression;
