import { useEffect, useState } from 'react'
import tenantsService from "../../services/tenants.service";
import storeService from "../../services/store.service";
import TablePagination from '../../comps/molecules/TablePagination.jsx';
import ConfirmModal from "../../comps/molecules/ConfirmModal.jsx";
import utilsService from '../../services/utils.service.js';

import TenantListItem from './TenantListItem.jsx';
const dummyList = new Array(5).fill({
    name: 'name',
    siteId: 'Site Id',
    tenantId: 'tenant Id'
})
export default (props) => {
    const [loading, setLoading] = useState(true)
    const [showConfirmModal, setshowConfirmModal] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [paginationData, setPagination] = useState({
        items: dummyList,
        pageNo: 1,
        pageSize: 5,
        totalCount: 5
    })
    useEffect(() => {
        storeService.subscribe('TENANTS', ({ detail }) => {
            paginationData.items = detail.items;
            paginationData.totalCount = detail.totalCount;
            setPagination({
                ...paginationData
            })
            setLoading(false)
        });
        storeService.sub(utilsService.CONSTANTS.EVENT_NAME.ADDED_TENANT, () => {
            setLoading(true);
            tenantsService.loadTenants();
        })
        tenantsService.loadTenants();
    }, [])
    const handleDeleteTenant = (item) => {
        setSelectedItem(item)
        setshowConfirmModal(true)
    }
    const confirmDeleteTenant = () => {
        setLoading(true)
        tenantsService.deletetenant({
            items: [{ _id: selectedItem._id }]
        }).finally(() => {
            setSelectedItem(null)
            setshowConfirmModal(false)
            tenantsService.loadTenants()
        })
    }
    return (
        <>
            <div className='relative'>
                <div className={`${loading ? 'blur' : ''}`}>
                    <table className={`app-table my-4`}>
                        <tbody>
                            {
                                paginationData && (
                                    paginationData.items.length > 0 ? paginationData.items.map((item, itemIndex) => {
                                        return (
                                            <TenantListItem
                                                id={item._id}
                                                loadedGuid={item.loadedGuid}
                                                handleDeleteTenant={handleDeleteTenant}
                                                handleEditTenant={props.handleEditTenant}
                                                openDeleteEnvironment={props.openDeleteEnvironment}
                                                openAddEditEnvironment={props.openAddEditEnvironment}
                                                index={itemIndex}></TenantListItem>
                                        )
                                    }) :
                                        (
                                            <tr>
                                                <td colSpan={3}>
                                                    <div className='flex justify-center'>
                                                        No Tenants configured
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                )
                            }
                        </tbody>
                    </table>
                    {
                        false && paginationData.totalCount > 0 && (
                            <TablePagination onChange={handlePaginationChange} pageNo={paginationData.pageNo} pageSize={paginationData.pageSize} totalSize={paginationData.totalCount}></TablePagination>
                        )
                    }
                </div>
                {
                    loading && (
                        <>
                            <div className="section-loader2">
                                <div class="wave">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </>

                    )
                }
            </div>
            {showConfirmModal &&
                <ConfirmModal
                    confirmationTitle="Delete Tenant"
                    confirmationMessage="Are you sure you want to the tenant?"
                    confirmButtonLabel="Delete"
                    confirmBtnClass="button-warning"
                    onOk={() => {
                        confirmDeleteTenant()
                    }}
                    onCancel={() => {
                        setshowConfirmModal(false)
                    }}
                />}
        </>
    )
}