import { useEffect, useState } from "react"
import utilsService from "../../services/utils.service"
import storeService from "../../services/store.service"
export default (props) => {
    const scheduleInterval = storeService.store.GLOBAL.schedularInterval;
    const [selectedDateTime, setselectedDateTime] = useState(null)
    const [minDate, setMinDate] = useState(utilsService.getDateFormat(new Date(), 'YMD'))
    useEffect(() => {
        if (props.value != selectedDateTime) {
            setselectedDateTime(props.value)
        }
    }, [props.value])
    useEffect(() => {
        if (minDate != props.minDate) {
            setMinDate(props.minDate)
        }
    }, [props.minDate])
    const handleSelectedDate = (e) => {
        let dateValue = e.target.value;
        if(dateValue) {
            let [dateStr, timeStr] = dateValue.split('T');
            let [hours, minutes] = timeStr.split(':').map(Number);
    
            if (minutes % scheduleInterval !== 0) {
                const closest = Math.round(minutes / scheduleInterval) * scheduleInterval;
                minutes = closest === 60 ? 0 : closest;
            }
            let formatedValue = `${dateStr}T${utilsService.padStart(hours)}:${utilsService.padStart(minutes)}`;
            setselectedDateTime(formatedValue);
            props.onChange && props.onChange(formatedValue);
        } else {
            setselectedDateTime(null);
            props.onChange && props.onChange(null);
        }
    }
    return (
        <input value={selectedDateTime} onChange={handleSelectedDate} type="datetime-local" min={minDate} />
    )
}