export default () => {
    return (
        <div className="section-loader">
            {/* <div className="section-loader__loader"></div> */}
            <div className="section-loader2">
                <div class="wave">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    )
}