import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SvgIcon from '../atoms/SvgIcon.jsx';
import SvgCode from '../atoms/SvgCode.jsx';
import dataService from '../../services/data.service.js';
import storeService from '../../services/store.service.js';
import utilsService from '../../services/utils.service.js';
import ApiCollectionFilter from '../../comps/organisms/ApiCollectionFilter';
import AppModal from '../../comps/organisms/AppModal';
import TablePagination from '../molecules/TablePagination.jsx';
import ConfirmModal from '../molecules/ConfirmModal.jsx';
import ScheduleButton from '../molecules/ScheduleButton.jsx'
import SearchAndFilter from '../molecules/SearchAndFilter.jsx';
import { Tooltip } from 'react-tooltip';
export default ({ selectedEnvId, date }) => {
    const navigate = useNavigate();
    const [collections, setCollections] = useState([])
    const [selectedDate, setSelectedDate] = useState(null)
    const [totalCount, settotalCount] = useState(0)
    const [filterData, setFilterData] = useState(storeService.store.COLLECTIONS_WITH_DETAILS.filterData || {
        searchText: '',
        selectedTags: [],
        selectedDevices: [],
        byScheduled: null
    })
    const [selectedItems, setSelectedItems] = useState({ ids: [], isCheckedAll: false })
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedCollectionArr, setselectedCollectionArr] = useState({});
    const [paginationData, setPaginationData] = useState({
        pageNo: 1,
        pageSize: 5
    })
    const [filteredList, setFilteredList] = useState([])
    const [paginationList, setPaginationList] = useState([])
    const handlePaginationChange = (data) => {
        if (data.perPage) {
            setPaginationData({
                ...paginationData,
                pageSize: data.perPage,
                pageNo: 1
            })
        }
        if (data.pageNo) {
            setPaginationData({
                ...paginationData,
                pageNo: data.pageNo
            })
        }
    }

    useEffect(() => {
        let pageStore = storeService.getStore('COLLECTIONS_WITH_DETAILS');
        storeService.subscribe('COLLECTIONS_WITH_DETAILS', ({ detail }) => {
            parseListData(detail.items);
        });
        if (!pageStore.loaded) {
            let start = (((paginationData.pageNo - 1) * paginationData.pageSize) + (1))
            dataService.getCollectionsWithDetails({
                skip: start - 1,
                limit: paginationData.pageSize,
                searchText: filterData.searchText,
                selectedDate: date,
                selectedTags: filterData.selectedTags.map(x => x.value),
                byScheduled: filterData.byScheduled,
                selectedType: filterData.selectedType?.value,
                invokedBy: 'line 65'
            });
        } else {
            parseListData(pageStore.items);
        }
        storeService.sub(utilsService.CONSTANTS.EVENT_NAME.SERVER_LOG, ({ detail }) => {
            if (detail?.data?.code == 'TESTCASE_GENERATION_JOB_DONE') {
                let start = (((paginationData.pageNo - 1) * paginationData.pageSize) + (1))
                dataService.getCollectionsWithDetails({
                    skip: start - 1,
                    limit: paginationData.pageSize,
                    searchText: filterData.searchText,
                    selectedDate: date,
                    selectedTags: filterData.selectedTags.map(x => x.value),
                    byScheduled: filterData.byScheduled,
                    selectedType: filterData.selectedType?.value,
                    invokedBy: 'line 81'
                });
            }
        });
    }, [])

    useEffect(() => {
        let start = (((paginationData.pageNo - 1) * paginationData.pageSize) + (1))
        dataService.getCollectionsWithDetails({
            skip: start - 1,
            limit: paginationData.pageSize,
            searchText: filterData.searchText,
            selectedDate: date,
            selectedTags: filterData.selectedTags.map(x => x.value),
            byScheduled: filterData.byScheduled,
            selectedType: filterData.selectedType?.value,
            invokedBy: 'line 97'
        });
    }, [selectedEnvId])

    const parseListData = (items) => {
        items.forEach(item => {
            item.testcasesCount = (item.testcases || []).length;
            item.lastRunDate = 'Not Run Yet';
            item.lastRunStatus = '-'
            if (item.testresults && item.testresults.length > 0) {
                let recentTestRun = item.testresults[0];
                item.lastRunDate = utilsService.getDisplayDateTime(recentTestRun.createdAt, true);
                item.lastRunStatus = (recentTestRun?.results == 0 || recentTestRun?.results?.filter(x => !x.result || x.result == 'Failed')?.length > 0) ? 'Failed' : 'Passed';
            }
        })
        setCollections(items);
        setFilteredList(items);
        settotalCount(storeService.store.COLLECTIONS_WITH_DETAILS.totalCount)
        setPaginationList([...items])
    }

    const navigateToDetailPage = (e, collection) => {
        e.preventDefault();
        navigate(`/api-regression/collection/${collection._id}`);
    }
    const runApiTests = async (e, collection) => {
        e.preventDefault();
        await dataService.runApiTests({
            "_id": collection._id,
            "apiType": collection.apiType,
            "selectedEnvId": selectedEnvId
        });
        let start = (((paginationData.pageNo - 1) * paginationData.pageSize) + (1))
        dataService.getCollectionsWithDetails({
            skip: start - 1,
            limit: paginationData.pageSize,
            searchText: filterData.searchText,
            selectedDate: date,
            selectedTags: filterData.selectedTags.map(x => x.value),
            byScheduled: filterData.byScheduled,
            selectedType: filterData.selectedType?.value,
            invokedBy: 'line 138'
        });
    }

    useEffect(() => {
        let selectedCollectionArr = [];
        selectedItems.ids.forEach((eachId) => {
            let selectedCollection = collections.find(collection => collection._id === eachId);
            if (selectedCollection) {
                selectedCollectionArr.push(selectedCollection);
            }
            setselectedCollectionArr(selectedCollectionArr)
        });
    }, [selectedItems]);
    const handleToggleChecked = (e, collectionId) => {
        const selectedCollections = e.target.checked ? [...selectedItems.ids, collectionId] : selectedItems.ids?.filter(it => it !== collectionId);
        setSelectedItems({
            ids: selectedCollections,
            isCheckedAll: false
        });
    }
    const handleAllChecked = (e) => {
        setSelectedItems({
            ids: e.target.checked ? (collections?.map(it => !(it.disable || (it.status == 'IN_PROGRESS')) && it._id)?.filter(item => !!item) || []) : [],
            isCheckedAll: e.target.checked
        });
    }
    const handleDeleteItems = (e) => {
        e.preventDefault();
        setShowDeleteModal(true);
    }

    const runSelectedTest = (e) => {
        selectedCollectionArr.forEach(async (eachCollection) => {
            e.preventDefault();
            await dataService.runApiTests({
                "_id": eachCollection._id,
                "apiType": eachCollection.apiType,
                "selectedEnvId": selectedEnvId
            });
            let start = (((paginationData.pageNo - 1) * paginationData.pageSize) + (1))
            dataService.getCollectionsWithDetails({
                skip: start - 1,
                limit: paginationData.pageSize,
                searchText: filterData.searchText,
                selectedDate: date,
                selectedTags: filterData.selectedTags.map(x => x.value),
                byScheduled: filterData.byScheduled,
                selectedType: filterData.selectedType?.value,
                invokedBy: 'line 187'
            });
            setSelectedItems({ ids: [], isCheckedAll: false });
            setselectedCollectionArr({})
        })
    }

    const onConfirmation = async (e) => {
        if (selectedItems?.ids?.length) {
            await dataService.deleteCollections({
                items: selectedItems.ids.map(id => ({ _id: id }))
            });
            let start = (((paginationData.pageNo - 1) * paginationData.pageSize) + (1))
            dataService.getCollectionsWithDetails({
                skip: start - 1,
                limit: paginationData.pageSize,
                searchText: filterData.searchText,
                selectedDate: date,
                selectedTags: filterData.selectedTags.map(x => x.value),
                byScheduled: filterData.byScheduled,
                selectedType: filterData.selectedType?.value,
                invokedBy: 'line 208'
            });
            setShowDeleteModal(false);
        }
    }
    useEffect(() => {
        storeService.store.COLLECTIONS_WITH_DETAILS.filterData = { ...filterData };
        let start = (((paginationData.pageNo - 1) * paginationData.pageSize) + (1))
        dataService.getCollectionsWithDetails({
            skip: start - 1,
            limit: paginationData.pageSize,
            searchText: filterData.searchText,
            selectedDate: date,
            selectedTags: filterData.selectedTags.map(x => x.value),
            byScheduled: filterData.byScheduled,
            selectedType: filterData.selectedType?.value,
            invokedBy: 'line 225'
        });
    }, [filterData])
    useEffect(() => {
        if (selectedDate != date) {
            setSelectedDate(date)
            let start = (((paginationData.pageNo - 1) * paginationData.pageSize) + (1))
            dataService.getCollectionsWithDetails({
                skip: start - 1,
                limit: paginationData.pageSize,
                searchText: filterData.searchText,
                selectedDate: date,
                selectedTags: filterData.selectedTags.map(x => x.value),
                byScheduled: filterData.byScheduled,
                selectedType: filterData.selectedType?.value,
                invokedBy: 'line 240'
            });
        }
    }, [date])
    useEffect(() => {
        let start = (((paginationData.pageNo - 1) * paginationData.pageSize) + (1))
        dataService.getCollectionsWithDetails({
            skip: start - 1,
            limit: paginationData.pageSize,
            selectedDate: date,
            searchText: filterData.searchText,
            selectedTags: filterData.selectedTags.map(x => x.value),
            byScheduled: filterData.byScheduled,
            selectedType: filterData.selectedType?.value,
            invokedBy: 'line 254'
        });
    }, [paginationData])
    const onFilterUpdate = (updatedFilterData) => {
        storeService.store.COLLECTIONS_WITH_DETAILS.filterData = { ...updatedFilterData };
        setFilterData({
            searchText: updatedFilterData.searchText,
            selectedTags: updatedFilterData.selectedTags,
            selectedType: updatedFilterData.selectedType,
            byScheduled: updatedFilterData.byScheduled
        })
    }
    return (
        <>
            <div className="flex justify-between">
                <div className="flex align-center">
                    <div className="flex align-center pointer" id="apiglide-table">
                        <h2 className='mr-2'>Test Collection</h2>
                        <SvgIcon path="/icons/info-svg.svg"></SvgIcon>
                        <Tooltip
                            anchorSelect="#apiglide-table"
                            content="View a list of tests created and executed. Check results, trigger new runs, and filter by environment or date. Use the Advanced Filter for device, priority, tags, or schedule-based filtering."
                            place="top-start"
                            style={{
                                backgroundColor: "#000",
                                color: "#ffffff",
                                borderRadius: "5px",
                                zIndex: 22,
                                fontWeight: "bold",
                                width: "50%",
                            }}
                        />
                    </div>
                    <div className="flex align-center ml-4">
                        <SearchAndFilter selectedFilterData={filterData} onFilterUpdate={onFilterUpdate} mode="COLLECTION_LIST" />
                    </div>
                </div>
                <div className="flex align-center justify-end">
                    <a href="#" className={'py-2 px-4 flex align-center no-underline edit-link-option ' + (selectedItems.ids?.length == 0 ? 'disabled' : '')}
                        onClick={e => runSelectedTest(e)}>
                        <SvgCode name="play"></SvgCode>
                        <span>Run</span>
                    </a>
                    <a href="#" onClick={e => handleDeleteItems(e)} className={'py-2 px-4 flex align-center no-underline edit-link-option ' + (selectedItems.ids?.length == 0 ? 'disabled' : '')}>
                        <SvgCode name="delete"></SvgCode>
                        <span>Delete</span>
                    </a>
                </div>
            </div>

            <table className="app-table mt-4 mb-6">
                <tbody>
                    <tr className="table-row-title">
                        <td>
                            <div className="align-center">
                                <span className="mr-1 table-subheading">Collection Name</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                    fill="none">
                                    <path
                                        d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389957 7.20038 -0.17433 9.00998 0.172936 10.7558C0.520203 12.5016 1.37737 14.1053 2.63604 15.364C3.89471 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9974 6.61386 17.0483 4.32622 15.361 2.63896C13.6738 0.951708 11.3861 0.00264685 9 0ZM9 16C7.61553 16 6.26216 15.5895 5.11101 14.8203C3.95987 14.0511 3.06266 12.9579 2.53285 11.6788C2.00303 10.3997 1.86441 8.99223 2.13451 7.63437C2.4046 6.2765 3.07129 5.02922 4.05026 4.05025C5.02922 3.07128 6.2765 2.4046 7.63437 2.1345C8.99224 1.86441 10.3997 2.00303 11.6788 2.53284C12.9579 3.06266 14.0511 3.95986 14.8203 5.11101C15.5895 6.26215 16 7.61553 16 9C15.998 10.8559 15.2599 12.6352 13.9476 13.9476C12.6352 15.2599 10.8559 15.998 9 16ZM13.0408 6.95917C13.1341 7.05207 13.208 7.16246 13.2585 7.28401C13.309 7.40556 13.335 7.53588 13.335 7.6675C13.335 7.79911 13.309 7.92943 13.2585 8.05098C13.208 8.17253 13.1341 8.28293 13.0408 8.37583L9.7075 11.7092C9.6146 11.8024 9.50421 11.8764 9.38265 11.9268C9.2611 11.9773 9.13078 12.0033 8.99917 12.0033C8.86755 12.0033 8.73724 11.9773 8.61568 11.9268C8.49413 11.8764 8.38374 11.8024 8.29084 11.7092L4.9575 8.37583C4.76964 8.18797 4.6641 7.93317 4.6641 7.6675C4.6641 7.40182 4.76964 7.14703 4.9575 6.95917C5.14536 6.7713 5.40016 6.66576 5.66584 6.66576C5.93151 6.66576 6.18631 6.7713 6.37417 6.95917L9 9.58333L11.6258 6.95667C11.7189 6.86385 11.8293 6.79029 11.9508 6.74018C12.0723 6.69007 12.2024 6.6644 12.3339 6.66463C12.4653 6.66486 12.5953 6.69099 12.7167 6.74153C12.838 6.79207 12.9481 6.86602 13.0408 6.95917Z"
                                        fill="#889AB1" />
                                </svg>
                            </div>
                        </td>
                        <td>
                            <div className="align-center">
                                <span className="mr-1 table-subheading">Cases</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                    fill="none">
                                    <path
                                        d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389957 7.20038 -0.17433 9.00998 0.172936 10.7558C0.520203 12.5016 1.37737 14.1053 2.63604 15.364C3.89471 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9974 6.61386 17.0483 4.32622 15.361 2.63896C13.6738 0.951708 11.3861 0.00264685 9 0ZM9 16C7.61553 16 6.26216 15.5895 5.11101 14.8203C3.95987 14.0511 3.06266 12.9579 2.53285 11.6788C2.00303 10.3997 1.86441 8.99223 2.13451 7.63437C2.4046 6.2765 3.07129 5.02922 4.05026 4.05025C5.02922 3.07128 6.2765 2.4046 7.63437 2.1345C8.99224 1.86441 10.3997 2.00303 11.6788 2.53284C12.9579 3.06266 14.0511 3.95986 14.8203 5.11101C15.5895 6.26215 16 7.61553 16 9C15.998 10.8559 15.2599 12.6352 13.9476 13.9476C12.6352 15.2599 10.8559 15.998 9 16ZM13.0408 6.95917C13.1341 7.05207 13.208 7.16246 13.2585 7.28401C13.309 7.40556 13.335 7.53588 13.335 7.6675C13.335 7.79911 13.309 7.92943 13.2585 8.05098C13.208 8.17253 13.1341 8.28293 13.0408 8.37583L9.7075 11.7092C9.6146 11.8024 9.50421 11.8764 9.38265 11.9268C9.2611 11.9773 9.13078 12.0033 8.99917 12.0033C8.86755 12.0033 8.73724 11.9773 8.61568 11.9268C8.49413 11.8764 8.38374 11.8024 8.29084 11.7092L4.9575 8.37583C4.76964 8.18797 4.6641 7.93317 4.6641 7.6675C4.6641 7.40182 4.76964 7.14703 4.9575 6.95917C5.14536 6.7713 5.40016 6.66576 5.66584 6.66576C5.93151 6.66576 6.18631 6.7713 6.37417 6.95917L9 9.58333L11.6258 6.95667C11.7189 6.86385 11.8293 6.79029 11.9508 6.74018C12.0723 6.69007 12.2024 6.6644 12.3339 6.66463C12.4653 6.66486 12.5953 6.69099 12.7167 6.74153C12.838 6.79207 12.9481 6.86602 13.0408 6.95917Z"
                                        fill="#889AB1" />
                                </svg>
                            </div>
                        </td>
                        <td>
                            <div className="align-center">
                                <span className="mr-1 table-subheading">Last Run</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                    fill="none">
                                    <path
                                        d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389957 7.20038 -0.17433 9.00998 0.172936 10.7558C0.520203 12.5016 1.37737 14.1053 2.63604 15.364C3.89471 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9974 6.61386 17.0483 4.32622 15.361 2.63896C13.6738 0.951708 11.3861 0.00264685 9 0ZM9 16C7.61553 16 6.26216 15.5895 5.11101 14.8203C3.95987 14.0511 3.06266 12.9579 2.53285 11.6788C2.00303 10.3997 1.86441 8.99223 2.13451 7.63437C2.4046 6.2765 3.07129 5.02922 4.05026 4.05025C5.02922 3.07128 6.2765 2.4046 7.63437 2.1345C8.99224 1.86441 10.3997 2.00303 11.6788 2.53284C12.9579 3.06266 14.0511 3.95986 14.8203 5.11101C15.5895 6.26215 16 7.61553 16 9C15.998 10.8559 15.2599 12.6352 13.9476 13.9476C12.6352 15.2599 10.8559 15.998 9 16ZM13.0408 6.95917C13.1341 7.05207 13.208 7.16246 13.2585 7.28401C13.309 7.40556 13.335 7.53588 13.335 7.6675C13.335 7.79911 13.309 7.92943 13.2585 8.05098C13.208 8.17253 13.1341 8.28293 13.0408 8.37583L9.7075 11.7092C9.6146 11.8024 9.50421 11.8764 9.38265 11.9268C9.2611 11.9773 9.13078 12.0033 8.99917 12.0033C8.86755 12.0033 8.73724 11.9773 8.61568 11.9268C8.49413 11.8764 8.38374 11.8024 8.29084 11.7092L4.9575 8.37583C4.76964 8.18797 4.6641 7.93317 4.6641 7.6675C4.6641 7.40182 4.76964 7.14703 4.9575 6.95917C5.14536 6.7713 5.40016 6.66576 5.66584 6.66576C5.93151 6.66576 6.18631 6.7713 6.37417 6.95917L9 9.58333L11.6258 6.95667C11.7189 6.86385 11.8293 6.79029 11.9508 6.74018C12.0723 6.69007 12.2024 6.6644 12.3339 6.66463C12.4653 6.66486 12.5953 6.69099 12.7167 6.74153C12.838 6.79207 12.9481 6.86602 13.0408 6.95917Z"
                                        fill="#889AB1" />
                                </svg>
                            </div>
                        </td>
                        <td>
                            <div className="align-center">
                                <span className="mr-1 table-subheading">Status</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                    fill="none">
                                    <path
                                        d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389957 7.20038 -0.17433 9.00998 0.172936 10.7558C0.520203 12.5016 1.37737 14.1053 2.63604 15.364C3.89471 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9974 6.61386 17.0483 4.32622 15.361 2.63896C13.6738 0.951708 11.3861 0.00264685 9 0ZM9 16C7.61553 16 6.26216 15.5895 5.11101 14.8203C3.95987 14.0511 3.06266 12.9579 2.53285 11.6788C2.00303 10.3997 1.86441 8.99223 2.13451 7.63437C2.4046 6.2765 3.07129 5.02922 4.05026 4.05025C5.02922 3.07128 6.2765 2.4046 7.63437 2.1345C8.99224 1.86441 10.3997 2.00303 11.6788 2.53284C12.9579 3.06266 14.0511 3.95986 14.8203 5.11101C15.5895 6.26215 16 7.61553 16 9C15.998 10.8559 15.2599 12.6352 13.9476 13.9476C12.6352 15.2599 10.8559 15.998 9 16ZM13.0408 6.95917C13.1341 7.05207 13.208 7.16246 13.2585 7.28401C13.309 7.40556 13.335 7.53588 13.335 7.6675C13.335 7.79911 13.309 7.92943 13.2585 8.05098C13.208 8.17253 13.1341 8.28293 13.0408 8.37583L9.7075 11.7092C9.6146 11.8024 9.50421 11.8764 9.38265 11.9268C9.2611 11.9773 9.13078 12.0033 8.99917 12.0033C8.86755 12.0033 8.73724 11.9773 8.61568 11.9268C8.49413 11.8764 8.38374 11.8024 8.29084 11.7092L4.9575 8.37583C4.76964 8.18797 4.6641 7.93317 4.6641 7.6675C4.6641 7.40182 4.76964 7.14703 4.9575 6.95917C5.14536 6.7713 5.40016 6.66576 5.66584 6.66576C5.93151 6.66576 6.18631 6.7713 6.37417 6.95917L9 9.58333L11.6258 6.95667C11.7189 6.86385 11.8293 6.79029 11.9508 6.74018C12.0723 6.69007 12.2024 6.6644 12.3339 6.66463C12.4653 6.66486 12.5953 6.69099 12.7167 6.74153C12.838 6.79207 12.9481 6.86602 13.0408 6.95917Z"
                                        fill="#889AB1" />
                                </svg>
                            </div>
                        </td>
                        <td className='checkbox-table-cell'>
                            <div className='cell-checkbox-wrapper'>
                                <label className="form-field-checkbox">
                                    <input type="checkbox" defaultChecked={selectedItems?.isCheckedAll} onChange={handleAllChecked} />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </td>
                    </tr>
                    {
                        paginationList && paginationList.map((collection, idx) => {
                            return (
                                <tr className="table-content" key={'row-' + idx + 'c' + collection.randomId}>
                                    <td className='para-2'>
                                        <a onClick={e => navigateToDetailPage(e, collection)}>
                                            <div className="justify-between">
                                                <div className='align-center'>
                                                    <span class="table-text">
                                                        {(((paginationData.pageNo - 1) * paginationData.pageSize) + (1)) + idx}. {collection.name}
                                                    </span>
                                                </div>
                                                <div className='align-center'>
                                                    {
                                                        collection.apiType && collection.apiType == 'CHAINED' && (
                                                            <svg className='mr-3' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g id="Group 216">
                                                                    <rect id="Rectangle 915" width="24" height="24" rx="3" fill="#26A5A5" />
                                                                    <path id="Vector" d="M17.3887 5.488C16.7384 4.83711 15.6829 4.83744 15.0316 5.48834L11.9681 8.55163C11.9678 8.55196 11.9675 8.55263 11.9668 8.5533C11.3175 9.20436 11.3187 10.2587 11.9688 10.9096L12.5575 11.4978L11.4971 12.5584L10.9085 11.9702C10.2578 11.3193 9.20201 11.3191 8.55135 11.9705L5.48739 15.0333C5.48739 15.0337 5.48706 15.0337 5.48672 15.0343C4.83707 15.6859 4.8379 16.741 5.48839 17.3912L7.61036 19.5117C8.26119 20.1628 9.31668 20.1628 9.9675 19.5117L13.0315 16.4477C13.0318 16.4474 13.0321 16.4471 13.0328 16.4464C13.6818 15.7955 13.6811 14.7412 13.0308 14.0905L12.4416 13.5019L13.502 12.4411L14.0914 13.0298C14.7419 13.6806 15.7974 13.6811 16.4483 13.0295L19.5122 9.96622C19.5129 9.96588 19.5132 9.96521 19.5135 9.96454C20.1629 9.31365 20.1622 8.25902 19.5112 7.60829L17.3887 5.488ZM8.78735 18.3277L6.66521 16.216L9.73167 13.154L10.3183 13.7374L9.73084 14.3253C9.73084 14.3253 9.7305 14.3253 9.73017 14.3256C9.47034 14.5866 9.46967 15.0096 9.72984 15.2706C9.99 15.5318 10.4123 15.5325 10.6727 15.2713L11.265 14.6787L11.8541 15.2648L8.78735 18.3277ZM15.2679 11.8464L14.6806 11.2622L15.2683 10.6741C15.2683 10.6741 15.2686 10.6738 15.2689 10.6738C15.5291 10.4126 15.5294 9.99011 15.2693 9.72875C15.0091 9.46739 14.5869 9.46706 14.3264 9.72775L13.734 10.3205L13.1453 9.73494L16.2121 6.67198L18.3347 8.78308L15.2679 11.8464Z" fill="white" />
                                                                </g>
                                                            </svg>
                                                        )
                                                    }


                                                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" width="12" height="18" viewBox="0 0 12 18"
                                                        fill="none">
                                                        <path
                                                            d="M2.91958 0.34627L10.743 8.16973C10.8525 8.27876 10.9393 8.40831 10.9985 8.55095C11.0577 8.69359 11.0882 8.84653 11.0882 9.00098C11.0882 9.15543 11.0577 9.30836 10.9985 9.45101C10.9393 9.59365 10.8525 9.7232 10.743 9.83222L2.91958 17.6557C2.69912 17.8761 2.40011 18 2.08834 18C1.77656 18 1.47755 17.8761 1.25709 17.6557C1.03663 17.4352 0.912782 17.1362 0.912782 16.8244C0.912782 16.5127 1.03663 16.2137 1.25709 15.9932L8.2503 9L1.25612 2.0068C1.03566 1.78634 0.911803 1.48734 0.911803 1.17556C0.911803 0.863781 1.03566 0.564774 1.25612 0.344315C1.47658 0.123856 1.77558 -3.7757e-08 2.08736 -5.13852e-08C2.39914 -6.50134e-08 2.69814 0.123856 2.9186 0.344314L2.91958 0.34627Z"
                                                            fill="#1B72E6" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </a>
                                    </td>
                                    <td class="font-xs font-status-color">
                                        {collection.status == 'IN_PROGRESS' ?
                                            'Generating...'
                                            : (
                                                collection.testcasesCount || '-'
                                            )}
                                    </td>
                                    <td>
                                        <div className="justify-between">
                                            <span class="font-xs font-status-color">
                                                {collection.lastRunDate}
                                            </span>
                                            <div className="flex align-items">
                                                <ScheduleButton display="ICON" mode="GLIDE_LIST" collectionId={collection._id} />
                                                {
                                                    collection.status == 'IN_PROGRESS' ? (null) : (
                                                        <a onClick={e => runApiTests(e, collection)} className="svg-icon-holder ml-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                                                viewBox="0 0 12 12" fill="none">
                                                                <path
                                                                    d="M10.7818 5.0593L2.42954 0.16242C2.25439 0.0600694 2.05403 0.00409737 1.84893 0.000216522C1.64383 -0.00366432 1.44133 0.044685 1.26209 0.14033C1.08286 0.235974 0.933306 0.375489 0.828704 0.544635C0.724101 0.71378 0.668194 0.906499 0.666687 1.10312V10.8969C0.668194 11.0935 0.724101 11.2862 0.828704 11.4554C0.933306 11.6245 1.08286 11.764 1.26209 11.8597C1.44133 11.9553 1.64383 12.0037 1.84893 11.9998C2.05403 11.9959 2.25439 11.9399 2.42954 11.8376L10.7818 6.9407C10.9502 6.84244 11.0894 6.70429 11.186 6.53954C11.2826 6.37479 11.3334 6.18898 11.3334 6C11.3334 5.81102 11.2826 5.62521 11.186 5.46046C11.0894 5.29571 10.9502 5.15756 10.7818 5.0593Z"
                                                                    fill="#1D4273" />
                                                            </svg>
                                                        </a>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {
                                            collection.status == 'IN_PROGRESS' ? (<span>Generating</span>) :
                                                (
                                                    collection.lastRunStatus == '-' ? (<>
                                                        <span>-</span>
                                                    </>) : (
                                                        collection.lastRunStatus == 'Failed' ? (
                                                            <span className='font-danger font-xs'>Failed</span>
                                                        ) : (
                                                            <>
                                                                Passed
                                                            </>
                                                        )
                                                    )
                                                )
                                        }

                                    </td>
                                    <td>
                                        <div className='cell-checkbox-wrapper'>
                                            <label className={'form-field-checkbox ' + (collection.disable || (collection.status == 'IN_PROGRESS') ? 'disabled' : '')}>
                                                <input type="checkbox" className={(collection.disable || (collection.status == 'IN_PROGRESS') ? 'disabled' : '')} checked={selectedItems?.ids?.includes(collection?._id)} onChange={e => handleToggleChecked(e, collection?._id)} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

            {
                filteredList && filteredList.length > 0 && (
                    <TablePagination onChange={handlePaginationChange} pageNo={paginationData.pageNo} pageSize={paginationData.pageSize} totalSize={totalCount}></TablePagination>
                )
            }

            {
                showDeleteModal && (
                    <ConfirmModal
                        confirmationTitle={'Are you sure you want to delete the test case?'}
                        confirmButtonLabel={'Delete'}
                        onOk={() => onConfirmation()}
                        onCancel={e => setShowDeleteModal(false)}
                    />
                )
            }
        </>
    );
}