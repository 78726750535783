import React from "react";

export const ChevronIcon = (props) => {
  const { type = "down", variant = "default" } = props;
	
  switch (type) {
    case "down":
      return (
        <svg
          className="post-svg-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="11"
          viewBox="0 0 18 11"
          fill="none"
        >
          <path
            d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z"
            fill="#1B72E6"
          />
        </svg>
      );
    case "right":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={variant === 'small' ? "8" : "12"}
          height={variant === 'small' ? "12" : "18"}
          viewBox="0 0 12 18"
          fill="none"
        >
          <path
            d="M2.91958 0.34627L10.743 8.16973C10.8525 8.27876 10.9393 8.40831 10.9985 8.55095C11.0577 8.69359 11.0882 8.84653 11.0882 9.00098C11.0882 9.15543 11.0577 9.30836 10.9985 9.45101C10.9393 9.59365 10.8525 9.7232 10.743 9.83222L2.91958 17.6557C2.69912 17.8761 2.40011 18 2.08834 18C1.77656 18 1.47755 17.8761 1.25709 17.6557C1.03663 17.4352 0.912782 17.1362 0.912782 16.8244C0.912782 16.5127 1.03663 16.2137 1.25709 15.9932L8.2503 9L1.25612 2.0068C1.03566 1.78634 0.911803 1.48734 0.911803 1.17556C0.911803 0.863781 1.03566 0.564774 1.25612 0.344315C1.47658 0.123856 1.77558 -3.7757e-08 2.08736 -5.13852e-08C2.39914 -6.50134e-08 2.69814 0.123856 2.9186 0.344314L2.91958 0.34627Z"
            fill="#1C1B1F"
          ></path>
        </svg>
      );
    case "right-white":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={variant === 'small' ? "8" : "12"}
            height={variant === 'small' ? "12" : "18"}
            viewBox="0 0 12 18"
            fill="fff"
          >
            <path
              d="M2.91958 0.34627L10.743 8.16973C10.8525 8.27876 10.9393 8.40831 10.9985 8.55095C11.0577 8.69359 11.0882 8.84653 11.0882 9.00098C11.0882 9.15543 11.0577 9.30836 10.9985 9.45101C10.9393 9.59365 10.8525 9.7232 10.743 9.83222L2.91958 17.6557C2.69912 17.8761 2.40011 18 2.08834 18C1.77656 18 1.47755 17.8761 1.25709 17.6557C1.03663 17.4352 0.912782 17.1362 0.912782 16.8244C0.912782 16.5127 1.03663 16.2137 1.25709 15.9932L8.2503 9L1.25612 2.0068C1.03566 1.78634 0.911803 1.48734 0.911803 1.17556C0.911803 0.863781 1.03566 0.564774 1.25612 0.344315C1.47658 0.123856 1.77558 -3.7757e-08 2.08736 -5.13852e-08C2.39914 -6.50134e-08 2.69814 0.123856 2.9186 0.344314L2.91958 0.34627Z"
              fill="#fff"
            ></path>
          </svg>
        );
    case "left":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={variant === 'small' ? "8" : "12"}
          height={variant === 'small' ? "12" : "18"}
          viewBox="0 0 12 18"
          fill="none"
          style={{ transform: 'rotate(180deg)' }}
        >
          <path
            d="M2.91958 0.34627L10.743 8.16973C10.8525 8.27876 10.9393 8.40831 10.9985 8.55095C11.0577 8.69359 11.0882 8.84653 11.0882 9.00098C11.0882 9.15543 11.0577 9.30836 10.9985 9.45101C10.9393 9.59365 10.8525 9.7232 10.743 9.83222L2.91958 17.6557C2.69912 17.8761 2.40011 18 2.08834 18C1.77656 18 1.47755 17.8761 1.25709 17.6557C1.03663 17.4352 0.912782 17.1362 0.912782 16.8244C0.912782 16.5127 1.03663 16.2137 1.25709 15.9932L8.2503 9L1.25612 2.0068C1.03566 1.78634 0.911803 1.48734 0.911803 1.17556C0.911803 0.863781 1.03566 0.564774 1.25612 0.344315C1.47658 0.123856 1.77558 -3.7757e-08 2.08736 -5.13852e-08C2.39914 -6.50134e-08 2.69814 0.123856 2.9186 0.344314L2.91958 0.34627Z"
            fill="#1B72E6"
          ></path>
        </svg>
      );
    default:
      return null;
  }
};
