import React, { useEffect, useState, useRef } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import utilsService from "../services/utils.service";
import LeftNav from "../comps/atoms/LeftNav";
import apiService from "../services/api.service.js";
import dataService from "../services/data.service.js";
import storeService from "../services/store.service.js";
import AppIcon from "../comps/atoms/AppIcon/AppIcon.jsx";
import ChatBot from "../comps/atoms/ChatBot.jsx";
import SettingsModal from "../comps/atoms/SettingsModal.jsx";
import Logout from "../comps/atoms/Logout.jsx";
import EnvironmentSelector from "../comps/atoms/EnvironmentSelect/EnvironementSelect.jsx";
import SectionLoader from '../comps/atoms/SectionLoader';
import SvgIcon from '../comps/atoms/SvgIcon.jsx';
import LicenseWarning from "../comps/atoms/LicenseWarning.jsx";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Tooltip as InfoTooltip } from 'react-tooltip';

const today = utilsService.getYMDFormat(new Date());
const dt1 = new Date();
dt1.setDate(dt1.getDate() - 30);
const fromDate = utilsService.getYMDFormat(dt1)
export default () => {
    const [usertype, setUsertype] = useState((localStorage.getItem('userType') || '').toLowerCase() === 'admin');
    const [settingsModalOpen, setOpenSettingsModal] = useState(false);
    const [selectedEnvName, setselectedEnvName] = useState('default-default')
    const [moduleName, setmoduleName] = useState('pageblazer')
    const [deviceType, setdeviceType] = useState('Desktop')
    const [loading, setLoading] = useState(true)
    const [startDate, setstartDate] = useState(fromDate)
    const [endDate, setendDate] = useState(today)
    const [statsData, setstatsData] = useState({})
    const [error, setError] = useState('');
    const localStartDate = useRef(fromDate)
    const localEndDate = useRef(today)
    const localDeviceType = useRef(deviceType)
    const localModuleName = useRef(moduleName)
    const links = [
        { moduleName: 'apiglide' },
        { moduleName: 'pageblazer' },
        { moduleName: 'sightsense' },
        { deviceType: 'Desktop' },
        { deviceType: 'Mobile' },
    ];

    const dateWiseData = statsData?.dateWiseData || [];

    const startedDate = new Date(startDate);
    const endedDate = new Date(endDate);

    const timeDiff = endedDate - startedDate;
    const numberOfDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    // Determine the X-axis label based on the number of days
    let xAxisLabel;
    if (numberOfDays <= 10) {
        xAxisLabel = 'Date';
    } else if (numberOfDays <= 70) {
        xAxisLabel = 'Week';
    } else {
        xAxisLabel = 'Month';
    }
    // Determine the maximum value for the Y-axis
    const maxYValue = Math.max(
        ...dateWiseData.flatMap(item => [
            Math.round(item.passed),
            Math.round(item.failed),
            Math.round(item.p0),
            Math.round(item.p1),
            Math.round(item.p2),
            Math.round(item.p3),
            Math.round(item.p4),
        ])
    );

    // Create an array of integers for the Y-axis ticks
    const yAxisTicks = Array.from({ length: maxYValue + 1 }, (_, i) => i); // Generates [0, 1, 2, ..., maxYValue]

    // Custom Tooltip Component
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                    <p className="label mb-2" style={{ fontSize: '16px' }}>{`Date: ${label}`}</p>
                    {payload.map((entry, index) => {
                        // Define colors for each data key
                        const colors = {
                            passed: '#26A5A5',
                            failed: '#C74068',
                            p0: '#E1EAF7',
                            p1: '#8BADDC',
                            p2: '#1B72E6',
                            p3: '#1D4273',
                            p4: '#052145',
                            p0Desktop: '#800000',
                            p1Desktop: '#808080',
                            p2Desktop: '#e1ad01',
                            p3Desktop: '#0000FF',
                            p4Desktop: '#00987F',
                            p0Mobile: '#800000',
                            p1Mobile: '#808080',
                            p2Mobile: '#e1ad01',
                            p3Mobile: '#0000FF',
                            p4Mobile: '#00987F',
                            passedDesktop: '#1b72e6',
                            failedDesktop: '#dc1e40',
                            passedMobile: '#1b72e6',
                            failedMobile: '#dc1e40',
                        };
                        return (
                            <p key={`item-${index}`} className="intro mb-1" style={{ color: colors[entry.name] }}>
                                {`${entry.name}: ${entry.value}`}
                            </p>
                        );
                    })}
                </div>
            );
        }

        return null;
    };

    const openSettingsModal = () => {
        setOpenSettingsModal(true);
    };
    const handleLoadData = () => {
        fetchData();
        setError('');

        // Validate dates
        const start = new Date(startDate);
        const end = new Date(endDate);

        if (start > new Date()) {
            setError('Start date cannot be in the future.');
            return;
        }

        if (end > new Date()) {
            setError('End date cannot be in the future.');
            return;
        }

        if (end < start) {
            setError('End date cannot be before start date.');
            return;
        }
    }
    const fetchData = () => {
        storeService.store.TENANTS.items.forEach(tenant => {
            if (tenant.environments && tenant.environments.length > 0) {
                tenant.environments.forEach(env => {
                    let val = env._id;
                    if(val === storeService.store.ENVIRONMENTS.selectedEnvId){
                        setselectedEnvName(`${tenant.name}-${env.name}`)
                    }
                })
            }
        })
        setLoading(true)
        apiService.post({
            url: utilsService.END_POINTS.getqastats,
            data: {
                startDate: localStartDate.current,
                endDate: localEndDate.current,
                moduleName: localModuleName.current,
                deviceType: localDeviceType.current,
                selectedEnvId: storeService.store.ENVIRONMENTS.selectedEnvId
            }
        }).then(apiResp => {
            const { data } = apiResp;
            if (data) {
                setstatsData(data)
            } else {
                // TODO: Implement error scenario
            }
        }).catch(() => {
            // TODO: Implement error scenario
        }).finally(() => {
            setLoading(false)
        })
    }
    useEffect(() => {
        fetchData();
    }, [moduleName, deviceType])
    useEffect(() => {
        storeService.subscribe('ENVIRONMENTS', ({ detail }) => {
            fetchData();
        })
        fetchData();
    }, [])
    return (
        <div className="flex main-layout">
            <div className="main-layout__left-ribbon-nav">
                <LeftNav />
            </div>
            <div className="main-layout__content relative dashboard-module">
                <div className="main-header-nav fluid-container py-4 header-bg2 dashboard-header absolute w-full">
                    <div>
                    </div>
                    <div className="main-header-nav__links w-100 flex justify-between">
                        <h1>QA Dashboard</h1>
                        <ul>
                            <li>
                                <LicenseWarning />
                            </li>
                            <li className="home-layout-banner">
                                <EnvironmentSelector />
                            </li>
                            {usertype ?
                                <li>
                                    <button onClick={openSettingsModal} className="bg-none no-border">
                                        <AppIcon iconName="dashboardSettingsIcon" iconColor="#fff" />
                                    </button>
                                </li> : null
                            }
                            <li>
                                <a href="/">
                                    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path id="Vector" d="M28.5952 25.1287C26.8178 22.0093 24.0425 19.5787 20.7159 18.2282C22.3702 16.9875 23.5922 15.2578 24.2087 13.284C24.8253 11.3103 24.8052 9.19253 24.1513 7.23081C23.4974 5.2691 22.2428 3.56286 20.5653 2.35378C18.8878 1.1447 16.8724 0.49408 14.8046 0.49408C12.7367 0.49408 10.7213 1.1447 9.04382 2.35378C7.36631 3.56286 6.11175 5.2691 5.45784 7.23081C4.80394 9.19253 4.78384 11.3103 5.4004 13.284C6.01696 15.2578 7.23892 16.9875 8.89318 18.2282C5.56663 19.5787 2.79128 22.0093 1.01392 25.1287C0.903792 25.3058 0.83039 25.5032 0.798081 25.7093C0.765772 25.9153 0.775215 26.1257 0.82585 26.328C0.876485 26.5303 0.967277 26.7203 1.09283 26.8869C1.21838 27.0534 1.37613 27.1929 1.5567 27.2973C1.73727 27.4016 1.93697 27.4685 2.14393 27.4941C2.35089 27.5197 2.56089 27.5035 2.76144 27.4463C2.96198 27.3891 3.14899 27.2922 3.31133 27.1613C3.47368 27.0304 3.60805 26.8682 3.70646 26.6844C6.05545 22.6242 10.2038 20.2026 14.8046 20.2026C19.4053 20.2026 23.5537 22.6255 25.9027 26.6844C26.1159 27.0273 26.4542 27.2738 26.8459 27.3719C27.2377 27.4699 27.6522 27.4118 28.0019 27.2098C28.3516 27.0078 28.609 26.6777 28.7197 26.2893C28.8304 25.901 28.7858 25.4848 28.5952 25.1287ZM8.06351 10.3503C8.06351 9.01702 8.45886 7.71371 9.19958 6.60515C9.9403 5.49659 10.9931 4.63257 12.2249 4.12236C13.4566 3.61214 14.812 3.47865 16.1197 3.73875C17.4273 3.99886 18.6285 4.64088 19.5712 5.58363C20.514 6.52638 21.156 7.72753 21.4161 9.03516C21.6762 10.3428 21.5427 11.6982 21.0325 12.93C20.5223 14.1617 19.6582 15.2145 18.5497 15.9553C17.4411 16.696 16.1378 17.0913 14.8046 17.0913C13.0174 17.0893 11.3039 16.3784 10.0402 15.1146C8.77644 13.8509 8.06557 12.1375 8.06351 10.3503Z" fill="#1B72E6" />
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <Logout iconColor="#1B72E6" />
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='qa-dashboard dashboard-summary'>
                    <div className='fluid-container py-4'>
                        <div className="section-box rounded-l dashboard-module qa-dashboard-module">

                            <div className="flex justify-content-end w-50 ml-6 mt-2">
                                {error && <div className="error-message">{error}</div>}
                            </div>
                            <div className='mt-6 w-100 dashboard-status'>
                                <div className='mb-4 w-100 total-section'>
                                    <div className="w-100 flex justify-between dashboard-env-details">
                                        <div className="w-30 p-5" id="env-tags">
                                            <h1 className="mb-2 pointer sub-heading-1">{selectedEnvName}
                                                <img src="/icons/info-svg.svg" className="svg-icon ml-2 mt-2" />
                                                <InfoTooltip
                                                    anchorSelect="#env-tags"
                                                    content="Selected Environment"
                                                    place="top-right"
                                                    style={{
                                                        backgroundColor: "#000",
                                                        color: "#ffffff",
                                                        borderRadius: "5px",
                                                        zIndex: 22,
                                                        fontWeight: "bold",
                                                        width: "auto",
                                                    }}
                                                />
                                            </h1>
                                        </div>
                                        <div className="w-60 flex justify-between p-5">
                                            <div className="pr-7 border-left">
                                                <p className="sub-heading-4">Tracked Data</p>
                                                <p className="sub-heading-1 font-bold mt-1">{statsData?.trackedData || 0}</p>
                                            </div>
                                            <div className="pr-7 pl-7 border-left">
                                                <p className="sub-heading-4">Tests Created</p>
                                                <p className="sub-heading-1 font-bold mt-1">{statsData?.testsCreated || 0}</p>
                                            </div>
                                            <div className="flex justify-between ml-7">
                                                <div className="form-field w-20">
                                                    <label className="label">From<sub>*</sub>
                                                        <input
                                                            className="input-date ml-2"
                                                            type="date"
                                                            name="startDate"
                                                            defaultValue={startDate}
                                                            max={endDate}
                                                            onChange={e => {
                                                                setstartDate(e.target.value);
                                                                localStartDate.current = e.target.value;
                                                            }}
                                                        />
                                                    </label>
                                                </div>
                                                <div className="form-field w-20">
                                                    <label className="label">To <sub>*</sub>
                                                        <input
                                                            className="input-date ml-2"
                                                            type="date"
                                                            name="endDate"
                                                            defaultValue={endDate}
                                                            max={today}
                                                            onChange={e => {
                                                                setendDate(e.target.value)
                                                                localEndDate.current = e.target.value;
                                                            }}
                                                        />
                                                    </label>
                                                </div>
                                                <div className="mt-5">
                                                    <button type="button" className="btn-medium-2" onClick={handleLoadData}>Load Data</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='flex p-5'>
                                        <div className="header-tile mr-7 w-2/6 px-7 py-4">
                                            <p className="para-label">Total</p>
                                            <p className="heading-text font-bold mt-1">{statsData?.failure?.total || 0}</p>
                                        </div>
                                        <div className="header-tile text-center w-3/6 mr-7">
                                            <div className="anchor-link">
                                                <div className={moduleName === 'pageblazer' ? 'active' : ''}>
                                                    <a href={'pageblazer'} className="para-label flex align-center justify-between px-7 py-4"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setmoduleName('pageblazer')
                                                            localModuleName.current = 'pageblazer'
                                                        }}>
                                                        <div>
                                                            <p className="para-label">Page Blazer</p>
                                                            <p className="heading-text font-bold mt-1">
                                                                {statsData?.pageBlazer?.testRun || 0} / {statsData?.pageBlazer?.testCreated || 0}
                                                                <img src="/icons/arrow-right.svg" className="arrow-svg-icon ml-2" />
                                                            </p>
                                                        </div>
                                                        <div className="dashboard-icon" >
                                                            <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path id="Union" className="fill-color" fill-rule="evenodd" clip-rule="evenodd" d="M2.5 6.10352e-05C1.39543 6.10352e-05 0.5 0.895494 0.5 2.00006V19.3334C0.5 20.438 1.39543 21.3334 2.5 21.3334H19.8333C20.9379 21.3334 21.8333 20.438 21.8333 19.3334V2.00006C21.8333 0.895492 20.9379 6.10352e-05 19.8333 6.10352e-05H2.5ZM20.5885 31.7009C21.024 31.7009 21.4427 31.629 21.8333 31.4964V45.9999C21.8333 47.1045 20.9379 47.9999 19.8333 47.9999H2.5C1.39543 47.9999 0.5 47.1045 0.5 45.9999V28.6666C0.5 27.562 1.39543 26.6666 2.5 26.6666H16.8954C16.7798 27.0338 16.7175 27.4246 16.7175 27.8299C16.7175 29.9678 18.4506 31.7009 20.5885 31.7009ZM27.1667 2.00006C27.1667 0.895494 28.0621 6.10352e-05 29.1667 6.10352e-05H46.5C47.6046 6.10352e-05 48.5 0.895492 48.5 2.00006V19.3334C48.5 20.438 47.6046 21.3334 46.5 21.3334H29.1667C28.0621 21.3334 27.1667 20.438 27.1667 19.3334V2.00006ZM29.1667 26.6666C28.0621 26.6666 27.1667 27.562 27.1667 28.6666V45.9999C27.1667 47.1045 28.0621 47.9999 29.1667 47.9999H46.5C47.6046 47.9999 48.5 47.1045 48.5 45.9999V28.6666C48.5 27.562 47.6046 26.6666 46.5 26.6666H29.1667Z"
                                                                    fill="url(#paint0_linear_206_319)" />
                                                                <path id="Subtract" className="stroke-color" fill-rule="evenodd" clip-rule="evenodd" d="M11.9117 9.77777C10.8072 9.77777 9.91174 10.6732 9.91174 11.7778V36.2222C9.91174 37.3268 10.8072 38.2222 11.9117 38.2222H36.3562C37.4607 38.2222 38.3562 37.3268 38.3562 36.2222V11.7778C38.3562 10.6732 37.4607 9.77777 36.3562 9.77777H11.9117ZM28.271 21.7898C28.7744 21.7898 29.1399 22.0302 29.3448 22.4837C29.5478 22.9282 29.4975 23.3808 29.1986 23.8278C28.1791 25.3738 23.6033 32.314 23.6033 32.314C23.3624 32.679 23.0184 32.8882 22.6592 32.889C22.38 32.889 22.1187 32.7671 21.9022 32.5362C21.6114 32.2272 21.5143 31.8314 21.6034 31.3283L22.5098 26.2874C22.515 26.2605 22.5198 26.2327 22.5238 26.2071H22.4675C22.1639 26.2088 19.9762 26.2088 19.9762 26.2088C19.4888 26.2071 19.1245 25.9688 18.9223 25.5209C18.7218 25.0743 18.7685 24.6212 19.0641 24.1746C19.0641 24.1746 23.628 17.2518 24.6763 15.6668C24.9076 15.3135 25.2532 15.1113 25.6191 15.1113C26.053 15.1113 26.4349 15.3964 26.6143 15.8565C26.7317 16.1581 26.6978 16.4597 26.6618 16.6594C26.6618 16.6594 25.9839 20.4773 25.7414 21.7958C25.8529 21.7951 26.2273 21.7946 26.6572 21.7939C27.305 21.793 28.0789 21.7918 28.271 21.7898Z"
                                                                    fill="#626DFE" />
                                                                <defs>
                                                                    <linearGradient id="paint0_linear_206_319" x1="-2.83333" y1="6.07876e-05" x2="79.8284" y2="80.0437" gradientUnits="userSpaceOnUse">
                                                                        <stop stop-color="#D6C0FF" />
                                                                        <stop offset="1" stop-color="#5CA0FF" />
                                                                    </linearGradient>
                                                                </defs>
                                                            </svg>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="header-tile text-center w-3/6 mr-7">
                                            <div className="anchor-link">
                                                <div className={moduleName === 'sightsense' ? 'active' : ''}>
                                                    <a className="para-label flex align-center justify-between px-7 py-4"
                                                        href={'sightsense'}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setmoduleName('sightsense')
                                                            localModuleName.current = 'sightsense'
                                                        }}>
                                                        <div>
                                                            <p className="para-label"> Sight Sense</p>
                                                            <p className="heading-text font-bold mt-1">
                                                                {statsData?.sightSense?.testRun || 0} / {statsData?.sightSense?.testCreated || 0}
                                                                <img src="/icons/arrow-right.svg" className="arrow-svg-icon ml-2" />
                                                            </p>
                                                        </div>
                                                        <div className="dashboard-icon" >
                                                            <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g id="Signtsense">
                                                                    <path className="fill-color" id="Subtract" fill-rule="evenodd" clip-rule="evenodd" d="M48.5 8.57147H0.5V37.4285C0.5 38.5331 1.39543 39.4285 2.5 39.4285H15.6357C15.1617 38.1092 14.9 36.6615 14.9 35.1428C14.9 28.9888 19.1981 24 24.5 24C29.8019 24 34.1 28.9888 34.1 35.1428C34.1 36.6615 33.8382 38.1092 33.3642 39.4285H46.5C47.6046 39.4285 48.5 38.5331 48.5 37.4285V8.57147Z" fill="url(#paint0_linear_206_352)" />
                                                                    <path className="stroke-color" id="Subtract_2" fill-rule="evenodd" clip-rule="evenodd" d="M2.5 6.10352e-05C1.39543 6.10352e-05 0.5 0.895492 0.5 2.00006V12H48.5V2.00006C48.5 0.895492 47.6046 6.10352e-05 46.5 6.10352e-05H2.5ZM7.70003 8.57158C9.02551 8.57158 10.1 7.42032 10.1 6.00016C10.1 4.58 9.02551 3.42874 7.70003 3.42874C6.37454 3.42874 5.30003 4.58 5.30003 6.00016C5.30003 7.42032 6.37454 8.57158 7.70003 8.57158ZM18.1 6.00016C18.1 7.42032 17.0255 8.57158 15.7 8.57158C14.3745 8.57158 13.3 7.42032 13.3 6.00016C13.3 4.58 14.3745 3.42874 15.7 3.42874C17.0255 3.42874 18.1 4.58 18.1 6.00016ZM23.7 8.57158C25.0255 8.57158 26.1 7.42032 26.1 6.00016C26.1 4.58 25.0255 3.42874 23.7 3.42874C22.3745 3.42874 21.3 4.58 21.3 6.00016C21.3 7.42032 22.3745 8.57158 23.7 8.57158Z" fill="url(#paint1_linear_206_352)" />
                                                                    <path className="outline-color" id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M24.5 22.2857C31.8391 22.2857 38.2074 26.275 41.372 30.0553C42.9183 31.9035 43.7 33.7535 43.7 35.1428C43.7 36.5322 42.9184 38.3821 41.372 40.2303C38.2075 44.0107 31.8387 47.9999 24.5 47.9999C17.1614 47.9999 10.7927 44.0106 7.62807 40.2303C6.08179 38.3821 5.30005 36.5322 5.30005 35.1428C5.30005 33.7535 6.08175 31.9036 7.62807 30.0553C10.7926 26.275 17.1614 22.2857 24.5 22.2857ZM24.5 28C20.7166 28 17.6429 31.2 17.6429 35.1428C17.6429 39.0857 20.7166 42.2857 24.5 42.2857C28.2835 42.2857 31.3572 39.0857 31.3572 35.1428C31.3572 31.2 28.2835 28 24.5 28Z" fill="#626DFE" />
                                                                </g>
                                                                <defs>
                                                                    <linearGradient id="paint0_linear_206_352" x1="-2.7" y1="10.2858" x2="59.7831" y2="72.7732" gradientUnits="userSpaceOnUse">
                                                                        <stop stop-color="#D6C0FF" />
                                                                        <stop offset="1" stop-color="#5CA0FF" />
                                                                    </linearGradient>
                                                                    <linearGradient id="paint1_linear_206_352" x1="2.82258" y1="-1.16123" x2="-4.90107" y2="45.4514" gradientUnits="userSpaceOnUse">
                                                                        <stop stop-color="#73A1FF" />
                                                                        <stop offset="1" stop-color="#0015FF" />
                                                                    </linearGradient>
                                                                </defs>
                                                            </svg>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="header-tile text-center w-3/6 ">
                                            <div className="anchor-link">
                                                <div className={moduleName === 'apiglide' ? 'active' : ''}>
                                                    <a className="para-label flex align-center justify-between px-7 py-4"
                                                        href={'apiglide'}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setmoduleName('apiglide')
                                                            localModuleName.current = 'apiglide'
                                                        }}>
                                                        <div>
                                                            <p className="para-label">API Glide</p>
                                                            <p className="heading-text font-bold mt-1">
                                                                {statsData?.apiGlide?.testRun || 0} / {statsData?.apiGlide?.testCreated || 0}
                                                                <img src="/icons/arrow-right.svg" className="arrow-svg-icon ml-2" />
                                                            </p>
                                                        </div>
                                                        <div className="dashboard-icon">
                                                            <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g id="APIGlide">
                                                                    <path id="Vector" className="fill-color" d="M38.9018 29.2766H10.1005C4.801 29.2766 0.5 24.9048 0.5 19.5177C0.5 14.7025 3.95606 10.6819 8.47394 9.90117C10.4319 4.07258 15.8971 6.10352e-05 22.0929 6.10352e-05C28.5455 6.10352e-05 33.997 4.29364 35.8145 10.2787C36.7994 9.92757 37.8376 9.75905 38.8995 9.75905C44.199 9.75905 48.5 14.1309 48.5 19.5179C48.502 24.9048 44.2014 29.2766 38.9018 29.2766Z" fill="url(#paint0_linear_206_394)" />
                                                                    <path id="Subtract" className="stroke-color" fill-rule="evenodd" clip-rule="evenodd" d="M27.798 16.5118C27.7946 15.1821 28.8437 14.1351 30.1759 14.1385C31.5081 14.1419 32.5626 15.1943 32.566 16.524L32.5816 22.6123L38.191 22.6267C38.9271 22.5935 39.5246 23.1899 39.5266 23.9597L39.5318 25.9892C39.5687 26.7241 38.9743 27.3174 38.203 27.3154L37.5018 27.3136L37.5127 31.5825C37.5246 36.2013 33.7479 39.9706 29.1202 39.9588L27.788 39.9554L27.8054 46.7436C27.8072 47.4434 27.2477 48.0018 26.5465 48L23.6717 47.9927C22.9706 47.9909 22.4082 47.4296 22.4064 46.7298L22.389 39.9416L21.0568 39.9382C16.4291 39.9264 12.6331 36.1377 12.6212 31.519L12.6103 27.2501L11.9091 27.2483C11.173 27.2814 10.5755 26.6851 10.5735 25.9153L10.5683 23.8858C10.5313 23.1509 11.1258 22.5576 11.8971 22.5595L17.5065 22.5739L17.4909 16.4855C17.4874 15.1558 18.5365 14.1088 19.8687 14.1122C21.201 14.1156 22.2554 15.168 22.2588 16.4976L22.2744 22.586L27.8136 22.6002L27.798 16.5118ZM17.8309 29.2766C17.096 29.2766 16.5002 29.8724 16.5002 30.6074C16.5002 33.5472 18.888 35.9304 21.8335 35.9304H28.5002C31.4457 35.9304 33.8335 33.5472 33.8335 30.6074C33.8335 29.8724 33.2377 29.2766 32.5027 29.2766H17.8309Z" fill="#626DFE" />
                                                                </g>
                                                                <defs>
                                                                    <linearGradient id="paint0_linear_206_394" x1="-2.83333" y1="6.08842e-05" x2="42.6635" y2="72.2308" gradientUnits="userSpaceOnUse">
                                                                        <stop stop-color="#D6C0FF" />
                                                                        <stop offset="1" stop-color="#5CA0FF" />
                                                                    </linearGradient>
                                                                </defs>
                                                            </svg>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='flex mb-4 w-100 barchart-section'>
                                    <div className='status-chart w-50 mr-5'>
                                        <div className="dashboard-content passed">
                                            <div className="flex justify-between status-selection">
                                                <div>
                                                    <p className="mt-2 sub-heading-1">Passed/Failed - Total</p>
                                                </div>
                                                <div className="flex status-details">
                                                    <div className={deviceType === 'Total' ? 'active' : ''} >
                                                        <p className="status-text para-3">
                                                            <a href="#" className="device-details">Total</a>
                                                        </p>
                                                    </div>
                                                    <div className={deviceType === 'Desktop' ? 'active' : ''} >
                                                        <p className="status-text para-3">
                                                            <a
                                                                href={'Desktop'}
                                                                className="device-details"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setdeviceType('Desktop')
                                                                    localDeviceType.current = 'Desktop'
                                                                }}>
                                                                Desktop
                                                            </a>
                                                        </p>
                                                    </div>
                                                    <div className={deviceType === 'Mobile' ? 'active' : ''}>
                                                        <p className="status-text para-3" >
                                                            <a
                                                                href={'Mobile'}
                                                                className="device-details"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setdeviceType('Mobile')
                                                                    localDeviceType.current = 'Mobile'
                                                                }}>
                                                                Mobile
                                                            </a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="p-6 bar-chart">
                                                <BarChart className="para-5"
                                                    width={600}
                                                    height={350}
                                                    data={statsData?.dateWiseData}
                                                    margin={{
                                                        top: 20,
                                                        right: 30,
                                                        left: 20,
                                                        bottom: 20,
                                                    }}>
                                                    <CartesianGrid stroke="#f5f5f5" />
                                                    <XAxis dataKey="date"
                                                        label={{ value: xAxisLabel, position: 'bottom', offset: 0 }} />
                                                    <YAxis
                                                        tickFormatter={(value) => Math.round(value)} // Round the tick values
                                                        label={{ value: 'Test Count', angle: -90, position: 'outside' }}
                                                        domain={[0, maxYValue]} // Set domain based on the maximum Y value
                                                        ticks={yAxisTicks} // Use the generated integer ticks
                                                    />
                                                    <Tooltip content={<CustomTooltip />} />
                                                    <Legend />
                                                    <Bar dataKey="passed" fill="#26A5A5" radius={[20, 20, 0, 0]} />
                                                    <Bar dataKey="failed" fill="#C74068" radius={[20, 20, 0, 0]} />
                                                </BarChart>
                                                {
                                                    loading && (<p className="text-center para-2">Loading...</p>)
                                                }
                                                {
                                                    !loading && statsData?.dateWiseData?.length === 0 && (
                                                        <p className="text-center para-2">No data found.</p>
                                                    )
                                                }
                                            </div>
                                            <div class="dashboard-status_details mt-2 mb-6">
                                                <div class="status">
                                                    <div class="circle passed"></div>
                                                    <span class="status-text">Passed</span>
                                                </div>
                                                <div class="status">
                                                    <div class="circle failed"></div>
                                                    <span class="status-text">Failed</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='status-chart w-50'>
                                        <div className="dashboard-content">
                                            <div className="flex justify-between status-selection">
                                                <div>
                                                    <p className="mt-2 sub-heading-1">P1 to P4 - Total</p>
                                                </div>
                                                <div className="flex status-details">
                                                    <div className={deviceType === 'Total' ? 'active' : ''} >
                                                        <p className="status-text para-3">
                                                            <a href="#" className="device-details">Total</a>
                                                        </p>
                                                    </div>
                                                    <div className={deviceType === 'Desktop' ? 'active' : ''} >
                                                        <p className="status-text para-3">
                                                            <a
                                                                href={'Desktop'}
                                                                className="device-details"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setdeviceType('Desktop')
                                                                    localDeviceType.current = 'Desktop'
                                                                }}>
                                                                Desktop
                                                            </a>
                                                        </p>
                                                    </div>
                                                    <div className={deviceType === 'Mobile' ? 'active' : ''}>
                                                        <p className="status-text para-3" >
                                                            <a
                                                                href={'Mobile'}
                                                                className="device-details"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setdeviceType('Mobile')
                                                                    localDeviceType.current = 'Mobile'
                                                                }}>
                                                                Mobile
                                                            </a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='bar-chart p-6'>
                                                <BarChart className="para-5" width={600} height={350} data={statsData?.dateWiseData}
                                                    margin={{
                                                        top: 20,
                                                        right: 30,
                                                        left: 20,
                                                        bottom: 20,
                                                    }}
                                                >
                                                    <XAxis dataKey="date"
                                                        label={{ value: xAxisLabel, position: 'bottom', offset: 0 }} />
                                                    <YAxis
                                                        tickFormatter={(value) => Math.round(value)} // Round the tick values
                                                        label={{
                                                            value: 'Test Count', angle: -90, position: 'outside',
                                                            style: {
                                                                fontWeight: 'bold',
                                                                fontSize: '12px',
                                                                fill: '#5E6E82',
                                                                fontFamily: 'Schibsted Grotesk',
                                                                paddingLeft: '20px',
                                                            }
                                                        }}
                                                        domain={[0, maxYValue]} // Set domain based on the maximum Y value
                                                        ticks={yAxisTicks} // Use the generated integer ticks
                                                    />
                                                    <CartesianGrid stroke="#f5f5f5" />
                                                    <Tooltip content={<CustomTooltip />} />
                                                    <Legend />
                                                    <Bar dataKey="p0" fill="#E1EAF7" radius={[20, 20, 0, 0]} />
                                                    <Bar dataKey="p1" fill="#8BADDC" radius={[20, 20, 0, 0]} />
                                                    <Bar dataKey="p2" fill="#1B72E6" radius={[20, 20, 0, 0]} />
                                                    <Bar dataKey="p3" fill="#1D4273" radius={[20, 20, 0, 0]} />
                                                    <Bar dataKey="p4" fill="#052145" radius={[20, 20, 0, 0]} />
                                                </BarChart>
                                                {
                                                    loading && (<p className="text-center para-2">Loading...</p>)
                                                }
                                                {
                                                    !loading && statsData?.dateWiseData?.length === 0 && (
                                                        <p className="text-center para-2">No data found.</p>
                                                    )
                                                }
                                            </div>
                                            <div class="dashboard-status_details mt-3 mb-6">
                                                <div class="status">
                                                    <div class="circle p0"></div>
                                                    <span class="status-text">P0</span>
                                                </div>
                                                <div class="status">
                                                    <div class="circle p1"></div>
                                                    <span class="status-text">P1</span>
                                                </div>
                                                <div class="status">
                                                    <div class="circle p2"></div>
                                                    <span class="status-text">P2</span>
                                                </div>
                                                <div class="status">
                                                    <div class="circle p3"></div>
                                                    <span class="status-text">P3</span>
                                                </div>
                                                <div class="status">
                                                    <div class="circle p4"></div>
                                                    <span class="status-text">P4</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {settingsModalOpen ? <SettingsModal /> : null}
                <ChatBot />
            </div>
            {
                loading && (
                    <SectionLoader isFullPage={true}></SectionLoader>
                )
            }
        </div>
    );
};
