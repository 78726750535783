import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SvgIcon from '../../comps/atoms/SvgIcon';
import TestCasesList from '../../comps/organisms/TestCasesList';
import storeService from '../../services/store.service';
import dataService from '../../services/data.service';
import utilsService from '../../services/utils.service';
import { Tooltip } from 'react-tooltip';

export default () => {
    let todayDate = new Date();
    let fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 30);
    const [lastDateRan, setLastDateRan] = useState(utilsService.getYMDFormat(todayDate))
    
    const [collectionItem, setCollectionItem] = useState(null)
    const [overviewData, setOverviewData] = useState({
        status: '-',
        failedCasesCount: 0,
        totalCasesCount: 0,
        avgResponseTime: 0,
        errorsReportedCount: 0,
        testcasesLogged: 0,
        failedConsistently: 0,
        historyStatus: '-',
        lastTestDate: '-',
        noOfFailures: 0,
        noOfRuns: 0
    });
    const params = useParams();
    useEffect(() => {
        let pageStore = storeService.getStore('COLLECTIONS_WITH_DETAILS');
        storeService.subscribe('COLLECTIONS_WITH_DETAILS', ({ detail }) => {
            parseListData(detail.items);
        });
        dataService.getCollectionsWithDetails({ id: params.collectionId, invokedBy: 'line 36' });
        storeService.subscribe('ENVIRONMENTS', ({ detail }) => {
            dataService.getCollectionsWithDetails({ id: params.collectionId, invokedBy: 'line 38' });
        })
        storeService.sub('COLLECTION_UPDATED', () => {
            dataService.getCollectionsWithDetails({ id: params.collectionId, invokedBy: 'line 41' });
        })
    }, [])

    const updateOverviewData = (data) => {
        let _id = data._id;
        if (collectionItem) {
            let item = collectionItem;
            let status = '-';
            let testcasesCount = (item.testcases || []).length;
            let avgResponseTime = 0;
            let failedCasesCount = 0;
            let passedCasesCount = 0;
            let failedRuns = 0;
            let lastTestRan = '-';
            if (item.testresults && item.testresults.length > 0) {
                let recentTestRun = item?.testresults?.find(x => x._id == _id);
                if (recentTestRun) {
                    lastTestRan = utilsService.getDisplayDateTime(recentTestRun.createdAt);
                    let testCaseMap = {};
                    let totalResponseTime = 0;
                    recentTestRun.results.forEach(test => {
                        testCaseMap[test.testId] = test;
                        totalResponseTime += test.responseTime || 0;
                    });
                    avgResponseTime = ((totalResponseTime / testcasesCount) * 1000).toFixed(2);
                    item.testcases.forEach(test => {
                        if (testCaseMap[test.testId]) {
                            test.result = testCaseMap[test.testId];
                        }
                    });
                    failedCasesCount = recentTestRun?.results?.filter(x => x.result == 'Failed')?.length;
                    passedCasesCount = recentTestRun?.results?.filter(x => x.result == 'Passed')?.length;
                    if (failedCasesCount == 0 && passedCasesCount > 0) {
                        status = 'Passed';
                    } else {
                        status = 'Failed';
                    }
                    item.testresults.forEach(item => {
                        let failedCasesCount = item?.results?.filter(x => x.result == 'Failed')?.length;
                        if (failedCasesCount > 0) {
                            failedRuns += 1;
                        }
                    });
                    if (recentTestRun.results.length == 0) {
                        status = 'Not Run';
                        failedCasesCount = 0;
                        avgResponseTime = 0;
                    }
                }
            } else {
                status = collectionItem.status == "IN_PROGRESS" ? 'Generating' : 'Not Run';
            }
            setOverviewData({
                ...overviewData,
                totalCasesCount: testcasesCount,
                failedCasesCount,
                status,
                avgResponseTime,
                errorsReportedCount: failedCasesCount,
                lastTestDate: lastTestRan
            })
        }
    }
    const parseListData = (items) => {
        let failedCasesCount = 0;
        if (params.collectionId) {
            let item = items.find(item => item._id === params.collectionId);
            if (item) {
                storeService.saveStore({
                    name: item.name,
                    apiType: item.apiType
                }, 'CURRENT_PAGE');
                let status = 'Failed';
                let testcasesCount = (item.testcases || []).length;
                let avgResponseTime = 0;
                let totalRuns = (item.testresults || []).length;
                let failedRuns = 0;
                let lastTestRan = '-';
                if (item.testresults && item.testresults.length > 0) {
                    let recentTestRun = item.testresults[0];
                    lastTestRan = utilsService.getDisplayDateTime(recentTestRun.createdAt);
                    let testCaseMap = {};
                    let totalResponseTime = 0;
                    recentTestRun.results.forEach(test => {
                        testCaseMap[test.testId] = test;
                        totalResponseTime += test.responseTime || 0;
                    });
                    if (recentTestRun.results.length > 0) {
                        avgResponseTime = ((totalResponseTime / testcasesCount) * 1000).toFixed(2);
                    }
                    item.testcases.forEach(test => {
                        if (testCaseMap[test.testId]) {
                            test.result = testCaseMap[test.testId];
                        }
                    });
                    failedCasesCount = recentTestRun?.results?.filter(x => !x.result || x.result == 'Failed')?.length;
                    if (failedCasesCount == 0 && recentTestRun.results.length > 0) {
                        status = 'Passed';
                    }
                    item.testresults.forEach(item => {
                        let failedCasesCount = item?.results?.filter(x => !x.result || x.result == 'Failed')?.length;
                        if (failedCasesCount > 0 || item.results.length == 0) {
                            failedRuns += 1;
                        }
                    });
                    if (recentTestRun.results.length == 0) {
                        status = 'Failed';
                        failedCasesCount = testcasesCount;
                        avgResponseTime = 0;
                    }
                } else {
                    status = item.status == "IN_PROGRESS" ? 'Generating' : 'Not Run';
                }
                setCollectionItem(item);
                setOverviewData({
                    ...overviewData,
                    totalCasesCount: testcasesCount,
                    failedCasesCount,
                    status,
                    avgResponseTime,
                    errorsReportedCount: failedCasesCount,
                    noOfRuns: totalRuns,
                    noOfFailures: failedRuns,
                    lastTestDate: lastTestRan
                })
            }
        }
    }
    const setDateFromElement = (value, fieldName) => {
        setLastDateRan(value)
    }
    return (
        <>
            <div className="section-box p-6 rounded-l">
                <div className="flex justify-between align-center">
                    <div className="flex align-center pointer" id="api-detailpage-tooltip">
                        <h2 className='mr-1'>Overview</h2> 
                        <img src="/icons/info-svg.svg" className="svg-icon-l" />
                        <Tooltip
                        anchorSelect="#api-detailpage-tooltip"
                        content="View detailed stats for the latest and past runs. Filter results by environment and date."
                        place="right"
                        style={{
                            backgroundColor: "#000",
                            color: "#ffffff",
                            borderRadius: "5px",
                            zIndex: 22,
                            fontWeight: "bold",
                            width: "30%",
                        }}
                        />
                    </div> 
                </div>
                <div className="flex box-tiles mt-4">
                    <div className="box-tile w-4/6 pr-2">
                        <div className={'box-tile__item box-tile__left p-6 ' + (overviewData.status == 'Failed' ? 'error' : '')} >
                            <h4 className="h4-heading subheading-color">Result</h4>
                            <div className="flex card-section mt-4">
                                <div className="card-item">
                                    <div className="card-item__no align-center">
                                        <a className="align-center">
                                            <span className="mr-3">{overviewData.lastTestDate}</span>
                                        </a>
                                    </div>
                                    <div className="card-item__text mt-2">Test Date</div>
                                </div>
                                <div className="card-item">
                                    <div className="card-item__no align-center">
                                        <a className="align-center">
                                            <span className="mr-3">{overviewData.status}</span>
                                        </a>
                                    </div>
                                    <div className="card-item__text mt-2">Status</div>
                                </div>

                                <div className="card-item">
                                    <div className="card-item__no align-center">
                                        {
                                            overviewData.status == 'Not Run' ? (
                                                '-'
                                            ) : (
                                                <a className="align-center">
                                                    <span className="mr-3">{overviewData.failedCasesCount}/{overviewData.totalCasesCount}</span>
                                                    {/* <SvgIcon path="/icons/arrow-right.svg"></SvgIcon> */}
                                                </a>
                                            )
                                        }

                                    </div>
                                    <div className="card-item__text mt-2">Failed Cases</div>
                                </div>

                                <div className="card-item">
                                    <div className="card-item__no align-center">
                                        <a className="align-center">
                                            <span className="mr-3">{overviewData.avgResponseTime ? overviewData.avgResponseTime + 'ms' : '-'}</span>
                                            {/* <SvgIcon path="/icons/arrow-right.svg"></SvgIcon> */}
                                        </a>
                                    </div>
                                    <div className="card-item__text mt-2">Avg. Response Time</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-tile w-2/6 pr-2">
                        <div className={'box-tile__item box-tile__left p-6 ' + (overviewData.noOfFailures > 0 ? 'error' : '')}>
                            <h4 className="h4-heading subheading-color">History</h4>
                            <div className="flex card-section mt-4">
                                <div className="card-item">
                                    <div className="card-item__no align-center">
                                        <a className="align-center">
                                            <span className="mr-3">{overviewData.noOfRuns}</span>
                                        </a>
                                    </div>
                                    <div className="card-item__text mt-2">Runs</div>
                                </div>
                                <div className="card-item">
                                    <div className="card-item__no align-center">
                                        <a className="align-center">
                                            <span className="mr-3">{overviewData.noOfFailures}</span>
                                        </a>
                                    </div>
                                    <div className="card-item__text mt-2">Failures</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="table-list-section p-6 rounded-l mt-6">
                {
                    collectionItem && (
                        <TestCasesList collectionId={params.collectionId} onchange={updateOverviewData} runs={collectionItem.testresults} collection={collectionItem} list={collectionItem.testcases}></TestCasesList>
                    )
                }
            </div>
        </>
    );
}