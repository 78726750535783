import storeService from "./store.service.js";
var makeRequest = async (opts) => {
    let fetchObject = {
        method: opts.method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    };
    if (opts.data) {
        fetchObject['body'] = JSON.stringify(opts.data);
    }
    let responseData = await fetch(opts.url, fetchObject).then(resp => (opts.isPlain ? resp.text() : resp.json()));
    if (!opts.isPlain) {
        const { serverTime } = responseData;
        if (serverTime) {
            window.serverTime = serverTime;
        }
    }
    return responseData;
};
var checkForInvalidUser = (resp, skipCheck, opts) => {
    if (!(skipCheck || opts.skipCheck) && resp.error && resp.error.code &&
        (resp.error.code === 'INVALID_ACCESS')) {
        location.href = '/login';
    }
};
const apiService = {
    post: async (opts) => {
        opts.method = 'POST';
        return await makeRequest(opts).then(resp => {
            checkForInvalidUser(resp, opts.isPlain, { skipCheck: opts.skipCheck });
            return resp;
        });
    },
    get: async (opts) => {
        opts.method = 'GET';
        return await makeRequest(opts).then(resp => {
            checkForInvalidUser(resp, opts.isPlain, { skipCheck: opts.skipCheck });
            return resp;
        });
    },
    delete: async (opts) => {
        opts.method = 'DELETE';
        return await makeRequest(opts).then(resp => {
            checkForInvalidUser(resp, opts.isPlain, { skipCheck: opts.skipCheck });
            return resp;
        });
    }
};

export default apiService;