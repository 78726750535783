import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import utilsService from "../../../services/utils.service.js";
import dataService from "../../../services/data.service.js";
import storeService from "../../../services/store.service.js";
import PageFilter from "../PageFilter.jsx";
import AppModal from "../AppModal.jsx";
import LeftNav from "../../atoms/LeftNav.jsx";
import { getCurrentDate } from "../../../helpers/helpers.js";
import ConfirmModal from '../../molecules/ConfirmModal';
import Logout from "../../atoms/Logout.jsx";
import PageActionJob from "./PageActionJob.jsx";
import TablePagination from '../../molecules/TablePagination.jsx';
import apiService from "../../../services/api.service.js";
import SvgIcon from '../../atoms/SvgIcon.jsx';
import PageContentSideBar from "./PageContentSideBar.jsx";
import EnvironmentSelector from "../../atoms/EnvironmentSelect/EnvironementSelect.jsx";
import PageActionHeader from "./PageActionHeader.jsx";
import SectionLoader from '../../atoms/SectionLoader.jsx';
import { Tooltip } from 'react-tooltip';
import SearchAndFilter from "../../molecules/SearchAndFilter.jsx";
import AppIcon from "../../atoms/AppIcon/AppIcon.jsx";
import ImportJourneyModal from "../ImportJourneyModal.jsx";
import workflowsService from "../../../services/workflows.service.js";

const JOB_STATUS = {
  SUCCESS: "Success",
  FAILURE: "FAILED"
}
const today = utilsService.getYMDFormat(new Date());
let initialCall = true;
const PageActionContent = () => {
  const params = useParams();
  const id = params.pageId;
  const [currentPage, setCurrentPage] = useState(id || 0);
  const [filter, setFilter] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [date, setDate] = useState(storeService.store.WORKFLOWS_WITH_DETAILS.filterData.selectedDate);
  const [selectedItems, setSelectedItems] = useState({ refId: null, deviceIds: [], isCheckedAll: false });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [showSideModal, setShowSideModal] = useState(false);
  const [runsFilterData, setRunsFilterData] = useState({
    selectedFilter: 'TOTAL_RUNS'
  })
  const [filterData, setFilterData] = useState(storeService.store.WORKFLOWS_WITH_DETAILS.filterData || {
    searchText: '',
    selectedTags: [],
    selectedDevices: [],
    byScheduled: null,
    jobStatus: []
  })
  const [userFlows, setUserFlows] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [filteredList, setFilteredList] = useState([])
  const [paginationList, setPaginationList] = useState([])
  const [paginationData, setPaginationData] = useState({
    pageNo: 1,
    pageSize: 5
  })
  const [pageBlazzerstats, setPageBlazzerstats] = useState({});
  const [selectedTenantEnv, setselectedTenantEnv] = useState(storeService.store.ENVIRONMENTS.selectedTenantEnv);
  const [loading, setloading] = useState(true);
  const [showImportModal, setShowImportModal] = useState(false)
  const getPageBlazzerStats = async (precompute = false) => {
    const pageBlazerStats = await apiService.post({
      url: utilsService.END_POINTS.getstats,
      data: {
        "filter_date": date || '',
        selectedTenantEnv: selectedTenantEnv,
        module_name: "pageblazer"
      }
    });
    setPageBlazzerstats(pageBlazerStats?.data || {});
    if (initialCall) {
      getPageBlazzerStats(false);
      initialCall = false;
    }
    setLoading(false);
  }

  const handlePaginationChange = (data) => {
    if (data.perPage) {
      setPaginationData({
        ...paginationData,
        pageSize: data.perPage,
        pageNo: 1
      })
    }
    if (data.pageNo) {
      setPaginationData({
        ...paginationData,
        pageNo: data.pageNo
      })
    }
  }
  useEffect(() => {
    setPaginatedRecords(paginationData.pageNo, paginationData.pageSize, filteredList);
  }, [paginationData.pageSize, paginationData.pageNo, filteredList]);

  const setPaginatedRecords = (pageNo, pageSize, list) => {
    if (pageSize < 0 || pageNo < 0 || list?.length < 0) return null;
    let newList = [];
    let filterLen = list.length;
    let start = (((pageNo - 1) * pageSize) + (1))
    let lastItem = ((pageNo - 1) * pageSize) + (pageSize);
    let end = (lastItem < filterLen ? lastItem : filterLen)
    for (let i = start - 1; i < end; i++) {
      list[i].devicesCount = list[i].devices.length;
      list[i].passedDevicesCount = list[i]?.devices?.filter(device => device.jobs?.[0]?.status === 'DONE').length || 0;
      list[i].failedDevicesCount = list[i]?.devices?.filter(device => device.jobs?.[0]?.status === 'FAILED').length || 0;
      list[i].notRunYetDevicesCount = list[i]?.devices?.filter(device => device.jobs?.[0]?.status === 'IN_PROGRESS' || device.jobs?.[0]?.status === 'TODO').length || 0;
      newList.push(list[i])
    }
    setloading(false);
    setPaginationList([...newList]);
  };

  const handleSearchText = (value) => {
    let updatedFilterData = {
      ...filterData,
      searchText: value
    };
    applyFilterDataOnList(updatedFilterData)
    setPaginationData({
      ...paginationData,
      pageNo: 1
    })
  }
  const applyFilterDataOnList = (data, workflowList = null) => {
    let newList = [];
    let selectedTenantEnv = storeService.store.ENVIRONMENTS.selectedTenantEnv;
    let selectedTags = data.selectedTags.map(x => x.value);
    let selectedDeviceIds = data.selectedDevices.map(x => x.value);
    let onlySchedule = data.byScheduled == 'ONLY_SCHEDULE';
    let onlyNonSchedule = data.byScheduled == 'ONLY_NON_SCHEDULE';
    let byWorkflowIdHash = storeService.store.SCHEDULED_JOB_LIST.byWorkflowIdHash;
    let failedWorkflowMap = storeService.store.WORKFLOW_FAILED_JOBS.itemsMap;
    let selectedJobStatus = storeService.store.WORKFLOWS_WITH_DETAILS.filterData.jobStatus;
    (workflowList || userFlows).forEach(item => {
      let flag = false;
      if (data?.searchText?.length > 2) {
        if ((item.name || '').toLowerCase().includes(data.searchText.toLowerCase())) {
          flag = true;
        }
      } else {
        flag = true;
      }

      if (flag) {
        if (selectedTags.length > 0) {
          flag = false;
          let itemTags = typeof item.tags == 'string' ? item.tags.split(',') : item.tags;
          const hasCommonItems = itemTags.some(iTag => selectedTags.includes(iTag));
          if (hasCommonItems) {
            flag = true;
          }
        }
      }
      if (flag) {
        if (onlySchedule || onlyNonSchedule) {
          flag = false;
          let scheduleList = (byWorkflowIdHash.get(item._id) || []).filter(x => x.selectedTenantEnv == selectedTenantEnv);
          if (onlySchedule && scheduleList.length > 0) {
            let isUpcomingFlag = false;
            let currentDate = new Date();
            let upcomingSchedules = scheduleList.filter(sItem => {
              let upFlag = false;
              if (sItem.tillDate && sItem.type != 'ONE_TIME') {
                let tillDate = new Date(sItem.tillDate);
                if (tillDate > currentDate) {
                  upFlag = true;
                }
              } else {
                if (sItem.type == 'ONE_TIME') {
                  let jobTime = new Date(sItem.date);
                  if (jobTime > currentDate) {
                    upFlag = true;
                  }
                }
              }
              return upFlag;
            })

            if (upcomingSchedules.length > 0) {
              isUpcomingFlag = true;
            }
            if (isUpcomingFlag && selectedDeviceIds.length > 0) {
              isUpcomingFlag = false;
              let atleastOne = false;
              let fDevices = [];
              selectedDeviceIds.forEach(deviceId => {
                let deviceSItem = upcomingSchedules.find(x => x.deviceId == deviceId);
                if (deviceSItem) {
                  atleastOne = true;
                  fDevices.push(deviceId);
                }
              });
              if (atleastOne) {
                isUpcomingFlag = true;
                item.scheduledDevices = fDevices;
              }
            }
            if (isUpcomingFlag) {
              flag = true;
            }
          }
          if (onlyNonSchedule && scheduleList.length == 0) {
            flag = true;
          }
        }
      }
      if (flag) {
        if (selectedJobStatus && selectedJobStatus.length > 0) {
          flag = false;
          let isAllFailed = selectedJobStatus.find(x => x == 'FAILED');
          if (isAllFailed) {
            if (selectedDeviceIds.length > 0) {
              let atleastOne = false;
              let fDevices = [];
              selectedDeviceIds.forEach(sItem => {
                if (failedWorkflowMap.get(item._id).has(sItem)) {
                  atleastOne = true;
                  fDevices.push(sItem)
                }
              })
              if (atleastOne) {
                flag = true;
                item.faileddevices = fDevices;
              }
            } else {
              if (failedWorkflowMap.has(item._id)) {
                flag = true;
              }
            }
          } else {
            if (failedWorkflowMap.has(item._id)) {
              let atleastOne = false;
              let fDevices = [];
              if (selectedDeviceIds.length > 0) {
                selectedDeviceIds.forEach(sItem => {
                  if (failedWorkflowMap.get(item._id).has(sItem)) {
                    let fdItem = failedWorkflowMap.get(item._id).get(sItem);
                    if (selectedJobStatus.find(x => x == fdItem.finalPriority)) {
                      atleastOne = true;
                      fDevices.push(sItem)
                    }
                  }
                })
              } else {
                failedWorkflowMap.get(item._id).forEach(dItem => {
                  if (selectedJobStatus.find(x => x == dItem.finalPriority)) {
                    atleastOne = true;
                  }
                })
              }
              if (atleastOne) {
                flag = true;
                item.failedDevices = fDevices;
              }
            }
          }
        }
      }
      if (flag) {
        newList.push({ ...item });
      }
    });
    setFilteredList([...newList]);
    setPaginatedRecords(paginationData.pageNo, paginationData.pageSize, [...newList]);
  }


  // Make an API call to get the use flows here 
  const getUserFlows = async (data = [], currentPageNo) => {
    setUserFlows(data);
    setFilteredData({ ...data, ...storeService.store.WORKFLOWS_WITH_DETAILS.filterData });
    applyFilterDataOnList({ ...data, ...storeService.store.WORKFLOWS_WITH_DETAILS.filterData }, data)
  }

  /**
   * This function will update the sticky position class
   */
  const updateStickyHeader = useCallback(() => {
    document.addEventListener("scroll", () => {
      let headerElem = document.querySelector(".main-header-nav");
      headerElem && headerElem.classList.remove("sticky");
      if (window.scrollY > 5) {
        headerElem & headerElem.classList.add("sticky");
      }
    });
  });

  useEffect(() => {
    getPageBlazzerStats(true);
    updateStickyHeader();
    let userflowStore = storeService.getStore('WORKFLOWS_WITH_DETAILS');
    storeService.subscribe('WORKFLOWS_WITH_DETAILS', ({ detail }) => {
      getUserFlows(detail.items, detail?.currentPageNo);
    });
    if (!userflowStore.loaded) {
      dataService.getWorkflowsWithDetails();
    } else {
      getUserFlows(userflowStore.items);
    }
    storeService.subscribe('ENVIRONMENTS', ({ detail }) => {
      setselectedTenantEnv(detail.selectedTenantEnv)
    })
  }, []);

  useEffect(() => {
    getPageBlazzerStats();
    dataService.getWorkflowsWithDetails();
  }, [selectedTenantEnv])

  const onSubmitFilter = () => { };
  const closeFilterModal = () => { setFilter(false) };

  const dateChange = event => {
    setDate(event.target.value)
    storeService.store.WORKFLOWS_WITH_DETAILS.filterData.selectedDate = event.target.value;
    if (!event.target.value) {
      setFilteredData(userFlows);
      // updateOverviewData(userFlows);
    } else {
      const filteredData = userFlows.filter(page => {
        if (!page?.devices && !page?.devices?.length) {
          return false;
        }
        let devices = page?.devices?.filter(device => {
          const jobs = device.jobs?.filter(job => {
            return job?.jobCompletedAt && utilsService.getYMDFormat(job?.jobCompletedAt) === event.target.value;
          })
          return !!jobs.length;
        })
        return !!devices?.length;
      });
      setFilteredData(filteredData);
      // updateOverviewData(filteredData);
    }
  }

  useEffect(() => {
    getPageBlazzerStats();
    storeService.sub(utilsService.CONSTANTS.EVENT_NAME.OPEN_TESTCASES_SIDEBAR_MODAL, ({ detail }) => {
      setRunsFilterData({ selectedFilter: detail.selectedFilter })
      setShowSideModal(detail.showModal);
    });
  }, [date])

  const handleToggleChecked = (e, refId, deviceId, page, pagerefId) => {
    const selectedDevices = e.target.checked ? [...selectedItems.deviceIds, deviceId] : selectedItems.deviceIds.filter(it => it !== deviceId);
    setSelectedItems({
      refId: e.target.checked ? (pagerefId ? pagerefId : selectedDevices?.length ? refId : null) : null,
      deviceIds: selectedDevices,
      isCheckedAll: page.devices.length == selectedDevices.length
    });
  }
  const handleToggleAllChecked = (e, page) => {
    setSelectedItems({
      refId: e.target.checked ? page.refId : null,
      deviceIds: e.target.checked ? (page?.devices?.map(it => it._id) || []) : [],
      isCheckedAll: e.target.checked
    });
  };

  const runPageActionsOnDevice = async (e) => {
    e.preventDefault();
    let selectedFlow = paginationList.find(x => x.refId == selectedItems.refId);
    setActionInProgress(true);
    await dataService.runpageactionsondevices({
      items: selectedItems.deviceIds.map(x => {
        return {
          _id: selectedFlow._id,
          device: selectedFlow.devices.find(d => d._id == x).jobs[0]?.device ? selectedFlow.devices.find(d => d._id == x).jobs[0]?.device : selectedFlow.devices.find(d => d._id == x),
          deviceId: x,
          refId: selectedFlow.refId,
          steps: selectedFlow.steps,
          configs: selectedFlow.configs
        };
      })
    })
    await dataService.getWorkflowsWithDetails({ selectedTenantEnv: selectedTenantEnv });
    setSelectedItems({ refId: null, deviceIds: [], isCheckedAll: false });
    setActionInProgress(false);
  }

  const showSideBarModal = (e, value) => {
    e.preventDefault();
    storeService.pub(utilsService.CONSTANTS.EVENT_NAME.OPEN_TESTCASES_SIDEBAR_MODAL, {
      showModal: true,
      selectedFilter: value
    })
  }

  const deleteActionsOnDevice = async (e) => {
    e.preventDefault();
    setActionInProgress(true);
    if (selectedItems.isCheckedAll) {
      // Delete workflowitem and workflowjobs
      await dataService.deleteuserflowdevices({
        refId: selectedItems.refId,
        isWorkflowDelete: true
      })
    } else {
      await dataService.deleteuserflowdevices({
        refId: selectedItems.refId,
        deviceIds: selectedItems.deviceIds,
        isWorkflowDelete: false
      })
    }
    await dataService.getWorkflowsWithDetails({
      currentPageNo: paginationData.pageNo,
      selectedTenantEnv: selectedTenantEnv
    });
    setSelectedItems({ refId: null, deviceIds: [], isCheckedAll: false });
    setActionInProgress(false);
    setShowConfirmModal(false);
  }
  useEffect(() => {
    const prevData = storeService.store.WORKFLOWS_WITH_DETAILS.filterData
    storeService.store.WORKFLOWS_WITH_DETAILS.filterData = { ...filterData, selectedDate: prevData.selectedDate };
    applyFilterDataOnList(filterData)
    setPaginationData({
      ...paginationData,
      pageNo: 1
    })
  }, [filterData])
  const onFilterUpdate = async (updatedFilterData) => {
    const prevData = storeService.store.WORKFLOWS_WITH_DETAILS.filterData
    storeService.store.WORKFLOWS_WITH_DETAILS.filterData = { ...updatedFilterData, selectedDate: prevData.selectedDate };
    if (updatedFilterData.jobStatus.length > 0) {
      await workflowsService.getfailedworkflowjobs({})
    }
    setFilterData({
      searchText: updatedFilterData.searchText,
      selectedTags: updatedFilterData.selectedTags,
      selectedDevices: [...updatedFilterData.selectedDevices],
      byScheduled: updatedFilterData.byScheduled,
      jobStatus: updatedFilterData.jobStatus
    })
  }
  const handleImportJourneys = (e) => {
    e.preventDefault();
    setShowImportModal(true)
  }
  return (
    <div className="flex main-layout">
      <div className="main-layout__left-ribbon-nav">
        <LeftNav />
      </div>

      <div className="main-layout__content">
        <div className="main-header-nav fluid-container py-4">
          <div className="flex align-center">
            <ul className="flex no-disc">
              <li className="ml-2">
                <div className="align-center">
                  <h1 className="heading-text ml-2">PageBlazer</h1>
                  <a
                    onClick={handleImportJourneys}
                    href="/"
                    className={`link-nav px-4 py-1 align-center ml-3 `}
                  >
                    <AppIcon iconName="download" iconColor="#1B72E6" />
                    <span className="ml-3 font-btn-large btn-font-color">Import journeys</span>
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div className="main-header-nav__links">
            <ul>
              <li>
                <div>
                  <EnvironmentSelector />
                </div>
              </li>
              <li>
                <a href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M27.8159 24.6347C26.0385 21.5152 23.2632 19.0846 19.9366 17.7342C21.5909 16.4935 22.8129 14.7637 23.4294 12.7899C24.046 10.8162 24.0259 8.69845 23.372 6.73673C22.7181 4.77502 21.4635 3.06878 19.786 1.8597C18.1085 0.65062 16.0931 0 14.0253 0C11.9574 0 9.94203 0.65062 8.26452 1.8597C6.58701 3.06878 5.33245 4.77502 4.67854 6.73673C4.02464 8.69845 4.00454 10.8162 4.6211 12.7899C5.23766 14.7637 6.45962 16.4935 8.11388 17.7342C4.78733 19.0846 2.01199 21.5152 0.234627 24.6347C0.124495 24.8117 0.0510929 25.0092 0.0187838 25.2152C-0.0135253 25.4212 -0.00408184 25.6316 0.0465531 25.8339C0.097188 26.0362 0.187981 26.2263 0.313533 26.3928C0.439086 26.5593 0.596835 26.6989 0.777404 26.8032C0.957972 26.9075 1.15767 26.9745 1.36463 27.0001C1.5716 27.0257 1.78159 27.0094 1.98214 26.9522C2.18269 26.895 2.36969 26.7981 2.53204 26.6672C2.69438 26.5363 2.82875 26.3741 2.92716 26.1903C5.27616 22.1301 9.4245 19.7085 14.0253 19.7085C18.626 19.7085 22.7744 22.1314 25.1234 26.1903C25.3366 26.5332 25.6749 26.7798 26.0667 26.8778C26.4584 26.9759 26.8729 26.9177 27.2226 26.7157C27.5723 26.5137 27.8297 26.1836 27.9404 25.7953C28.0511 25.4069 28.0065 24.9907 27.8159 24.6347ZM7.28421 9.8562C7.28421 8.52294 7.67957 7.21963 8.42028 6.11107C9.161 5.00251 10.2138 4.13849 11.4456 3.62828C12.6773 3.11806 14.0327 2.98457 15.3404 3.24467C16.648 3.50478 17.8492 4.1468 18.7919 5.08955C19.7347 6.03231 20.3767 7.23345 20.6368 8.54108C20.8969 9.84872 20.7634 11.2041 20.2532 12.4359C19.743 13.6677 18.879 14.7205 17.7704 15.4612C16.6618 16.2019 15.3585 16.5972 14.0253 16.5972C12.2381 16.5952 10.5246 15.8843 9.26089 14.6206C7.99715 13.3568 7.28627 11.6434 7.28421 9.8562Z"
                      fill="#1B72E6"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <Logout iconColor="#1B72E6" />
              </li>
            </ul>
          </div>
        </div>

        <div className="fluid-container py-4">
          <div className="section-box p-6 rounded-l">
            <div className="flex justify-between align-center">
              <div className="flex align-center pointer" id="pageblazer-tooltip">
                <h2 className="mr-3">Overview</h2>
                <img src="/icons/info-svg.svg" className="svg-icon-l" />
                <Tooltip
                  anchorSelect="#pageblazer-tooltip"
                  content="Each test follows a user journey across pages. View test stats for created, executed, total runs, and failure rates (overall and by device). Filter results by environment and date."
                  place="right"
                  style={{
                    backgroundColor: "#000",
                    color: "#ffffff",
                    borderRadius: "5px",
                    zIndex: 22,
                    fontWeight: "bold",
                    width: "50%",
                  }}
                />
              </div>
              <div className="align-center">
                <span className="h5-heading para-label mr-2">
                  Select Date
                </span>
                <input
                  className="input-date"
                  type="date"
                  name="lasttestrun"
                  id="lasttestrun"
                  defaultValue={date}
                  value={date}
                  max={today}
                  onChange={e => dateChange(e)}
                />
              </div>
            </div>
            <div className="flex box-tiles mt-4">
              <div className="box-tile w-3/6 pr-2">
                <div className="box-tile__item box-tile__left p-6">
                  <h4 className="h4-heading subheading-color">Total Tests</h4>
                  <div className="flex card-section mt-4">
                    <div className="card-item">
                      <div className="card-item__no align-center">
                        <span className="mr-1">{pageBlazzerstats?.total_pages}</span>
                      </div>
                      <div className="card-item__text align-center mt-2 pointer" id="createdtests">
                        <span className="mr-1">Created Tests</span>
                        <SvgIcon path="/icons/info-svg.svg"></SvgIcon>
                        <Tooltip
                          anchorSelect="#createdtests"
                          content="Total number of tests created (not all tests may have been executed)."
                          place="bottom-start"
                          style={{
                            backgroundColor: "#000",
                            color: "#ffffff",
                            borderRadius: "5px",
                            zIndex: 22,
                            fontWeight: "bold",
                            width:"20%",
                          }}
                        />
                      </div>
                    </div>
                    <div className="card-item">
                      <div className="card-item__no align-center">
                        <span className="mr-1">{pageBlazzerstats?.total_pages_tested}</span>
                      </div>
                      <div className="card-item__text mt-2 pointer" id="testsran">
                        <span className="mr-1">Executed Tests</span>
                        <SvgIcon path="/icons/info-svg.svg"></SvgIcon>
                        <Tooltip
                          anchorSelect="#testsran"
                          content="The number of unique tests that have been run at least once."
                          place="bottom"
                          style={{
                            backgroundColor: "#000",
                            color: "#ffffff",
                            borderRadius: "5px",
                            zIndex: 22,
                            fontWeight: "bold",
                            width:"20%",
                          }}
                        />
                      </div>
                    </div>
                    <div className="card-item">
                      <div className="card-item__no align-center">
                        <span className="mr-1">{pageBlazzerstats?.total_runs}</span>
                      </div>
                      <div className="card-item__text mt-2 pointer" id="totalruns">
                        <span className="mr-1">Total Runs</span>
                        <SvgIcon path="/icons/info-svg.svg"></SvgIcon>
                        <Tooltip
                          anchorSelect="#totalruns"
                          content="Total count of test executions (some tests may run multiple times)."
                          place="bottom"
                          style={{
                            backgroundColor: "#000",
                            color: "#ffffff",
                            borderRadius: "5px",
                            zIndex: 22,
                            fontWeight: "bold",
                            width:"20%",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="box-tile w-3/6 pl-2">
                <div className="box-tile__item box-tile__right p-6">
                  <h4 className="h4-heading subheading-color">Failed Tests</h4>
                  <div className="flex card-section mt-4">
                    <div className="card-item">
                      <div className="card-item__no">
                        <a href="#" onClick={e => showSideBarModal(e, 'FAILED_PAGES')} className="align-center">
                          <span className="mr-3">{pageBlazzerstats?.failed_pages}</span>
                          <SvgIcon path="../icons/arrow-right.svg"></SvgIcon>
                        </a>
                      </div>
                      <div className="card-item__text mt-2">Failed Tests</div>
                    </div>


                    <div className="card-item">
                      <div className="card-item__no">
                        <a href="#" onClick={e => showSideBarModal(e, 'FAILED_ON_DESKTOP')} className="align-center">
                          <span className="mr-3">{pageBlazzerstats?.failed_devices_with_count?.DESKTOP}</span>
                          <SvgIcon path="../icons/arrow-right.svg"></SvgIcon>
                        </a>
                      </div>
                      <div className="card-item__text mt-2">Desktop</div>
                    </div>

                    <div className="card-item">
                      <div className="card-item__no">
                        <a href="#" onClick={e => showSideBarModal(e, 'FAILED_ON_MOBILE')} className="align-center">
                          <span className="mr-3">{pageBlazzerstats?.failed_devices_with_count?.MOBILE}</span>
                          <SvgIcon path="../icons/arrow-right.svg"></SvgIcon>
                        </a>
                      </div>
                      <div className="card-item__text mt-2">Mobile</div>
                    </div>
                    <div className="card-item">
                      <div className="card-item__no">
                        <a href="#" onClick={e => showSideBarModal(e, 'CONSECUTIVE_FAILURE')} className="align-center">
                          <span className="mr-3">{pageBlazzerstats.consecutive_failed_cases}</span>
                          <SvgIcon path="../icons/arrow-right.svg"></SvgIcon>
                        </a>
                      </div>
                      <div className="card-item__text mt-2">
                        Consecutive Failures
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="table-list-section px-6 py-7 rounded-l mt-6 relative">
            <div className="flex justify-between">
              <div className="flex align-center">
                <div className="flex align-center pointer" id="pageblazer-table">
                  <h2 className="mr-2">Page List</h2>
                  <img src="/icons/info-svg.svg" className="svg-icon " />
                  <Tooltip
                    anchorSelect="#pageblazer-table"
                    content="Each test follows a user journey across pages. View tests created and executed across devices, check results, trigger new runs, and filter by environment or date. Use the Advanced Filter for device, priority, tags, or schedule-based filtering."
                    place="top-start"
                    style={{
                      backgroundColor: "#000",
                      color: "#ffffff",
                      borderRadius: "5px",
                      zIndex: 22,
                      fontWeight: "bold",
                      width: "50%",
                    }}
                  />
                </div>
                <div className="flex align-center ml-4">
                  <SearchAndFilter selectedFilterData={filterData} onFilterUpdate={onFilterUpdate} mode="WORKFLOW_LIST" />
                </div>
              </div>
              <div className="flex align-center justify-end">
                <a
                  href="#"
                  className={`py-2 px-4 flex align-center no-underline edit-link-option ${selectedItems?.refId == null || actionInProgress ? 'disabled' : ''}`}
                  onClick={runPageActionsOnDevice}
                >
                  <svg className="svg-icon-l"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM9.98682 6.14618L17.5039 10.5534C17.6554 10.6418 17.7807 10.7661 17.8677 10.9144C17.9546 11.0627 18.0002 11.2299 18.0002 11.4C18.0002 11.5701 17.9546 11.7373 17.8677 11.8856C17.7807 12.0339 17.6554 12.1582 17.5039 12.2466L9.98682 16.6538C9.82918 16.7459 9.64886 16.7963 9.46427 16.7998C9.27968 16.8033 9.09743 16.7598 8.93612 16.6737C8.7748 16.5876 8.64021 16.4621 8.54607 16.3098C8.45192 16.1576 8.40161 15.9842 8.40025 15.8072V6.99281C8.40161 6.81585 8.45192 6.6424 8.54607 6.49017C8.64021 6.33794 8.7748 6.21238 8.93612 6.1263C9.09743 6.04022 9.27968 5.9967 9.46427 6.0002C9.64886 6.00369 9.82918 6.05406 9.98682 6.14618Z"
                    ></path>
                  </svg>
                  <span>Run</span>
                </a>
                <a
                  href="#"
                  className={`py-2 px-4  flex align-center no-underline edit-link-option ${selectedItems?.refId == null || actionInProgress ? 'disabled' : ''}`}
                  onClick={e => {
                    e.preventDefault();
                    setShowConfirmModal(true);
                  }}
                >
                  <svg
                    className="svg-icon-l"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="22"
                    viewBox="0 0 20 22"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.0021 1.92008H18.4668C19.022 1.92008 19.4616 2.35962 19.4616 2.91483C19.4616 3.47003 19.022 3.90957 18.4668 3.90957H1.53307C0.977867 3.90957 0.53833 3.47003 0.53833 2.91483C0.53833 2.35962 0.977867 1.92008 1.53307 1.92008H5.99785L7.06199 0.439537C7.27019 0.161935 7.57093 0 7.91793 0H12.082C12.429 0 12.7528 0.161935 12.9379 0.439537L14.0021 1.92008ZM2.48159 5.27441H17.5184C17.796 5.27441 18.0273 5.50575 18.0273 5.78335V19.9873C18.0273 21.0978 17.1251 22 16.0147 22H3.98527C2.87486 22 1.97266 21.0978 1.97266 19.9873V5.78335C1.97266 5.50575 2.20399 5.27441 2.48159 5.27441ZM6.09043 18.5531C6.46056 18.5531 6.7613 18.2523 6.7613 17.8822V9.34592C6.7613 8.97578 6.46056 8.67505 6.09043 8.67505C5.72029 8.67505 5.41955 8.97578 5.41955 9.34592V17.8822C5.41955 18.2523 5.72029 18.5531 6.09043 18.5531ZM10 18.5531C10.3701 18.5531 10.6709 18.2523 10.6709 17.8822V9.34592C10.6709 8.97578 10.3701 8.67505 10 8.67505C9.62986 8.67505 9.32912 8.97578 9.32912 9.34592V17.8822C9.32912 18.2523 9.62986 18.5531 10 18.5531ZM13.9096 18.5531C14.2797 18.5531 14.5804 18.2523 14.5804 17.8822V9.34592C14.5804 8.97578 14.2797 8.67505 13.9096 8.67505C13.5394 8.67505 13.2387 8.97578 13.2387 9.34592V17.8822C13.2387 18.2523 13.5394 18.5531 13.9096 18.5531Z"
                    ></path>
                  </svg>
                  <span>Delete</span>
                </a>
              </div>
            </div>
            <table className="app-table my-4">
              <tbody>
                {paginationList.map((page, index) => {
                  return (
                    <PageActionHeader key={page._id + '' + page.randomId}
                      handleClick={e => {
                        setSelectedItems({ refId: null, deviceIds: [], isCheckedAll: false });
                        setCurrentPage(currentPage === page._id ? "" : page._id);
                      }}
                      selectedItems={selectedItems}
                      selectedDate={date}
                      page={page}
                      filterData={filterData}
                      paginationData={paginationData} index={index}
                      scheduledDevices={page.scheduledDevices}
                      failedDevices={page.failedDevices}
                      handleToggleChecked={handleToggleChecked}
                      handleToggleAllChecked={handleToggleAllChecked}
                      currentPage={currentPage}></PageActionHeader>
                  );
                })}
              </tbody>
            </table>
            {
              filteredList && filteredList.length > 0 ? (
                <TablePagination onChange={handlePaginationChange} pageNo={paginationData.pageNo} pageSize={paginationData.pageSize} totalSize={filteredList.length}></TablePagination>
              ) : (
                <>No items found.</>
              )
            }
            {
              loading && (
                <div className="section-loader2">
                  <div class="wave">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
        {
          isLoading && (
            <SectionLoader></SectionLoader>
          )
        }
      </div>
      {filter ? (
        <AppModal>
          <PageFilter
            filterData={filterData}
            onSubmit={onSubmitFilter}
            onClose={closeFilterModal}
          ></PageFilter>
        </AppModal>
      ) : null}
      {showConfirmModal ? (
        <ConfirmModal
          confirmationTitle={'Are you sure you want to delete selected devices?'}
          confirmButtonLabel={'Delete'}
          onOk={(e) => deleteActionsOnDevice(e)}
          onCancel={e => setShowConfirmModal(false)}
        />
      ) : null}
      {showSideModal ?
        <PageContentSideBar
          testsfilter={runsFilterData.selectedFilter}
          selectedTenantEnv={selectedTenantEnv}
          dateSelected={date} /> : null}
      {
        showImportModal && (
          <ImportJourneyModal closeModal={e => {
            setShowImportModal(false)
          }} />
        )
      }
    </div>
  );
};

export default PageActionContent;
