export default () => {
    return (
        <>
            <div className="para-2 warn-message">
                This product is running on trail version license. You might loose access to the product soon. please reach out to AstraQ team.
            </div>
            <div className="para-2 warn-message mt-4">
                Use activation key received from AstraQ team to continue using the product.
            </div>
        </>
    )
}