export default {
    featuresList() {
        return [
            ["DEFAULT", { value: true, section: 'GLOBAL', description: 'Default Flag to test flag functionality' }],
            ["ACTIONED_CARD", { value: true, section: 'USAGE_PULSE', description: 'Display actioned journey in recommendation widget' }],
        ]
    },
    sections: ['GLOBAL', 'USAGE_PULSE'],
    mapSectionCode: {
        'USAGE_PULSE': 'Usage Pulse',
        'GLOBAL': 'Global'
    },
    mapFlagCode: {
        'ACTIONED_CARD': 'Actioned Card'
    }
}