import { useEffect, useState, useRef } from "react"
import storeService from "../../services/store.service"
import utilsService from "../../services/utils.service";
import AppModal from "../organisms/AppModal";
import AppIcon from "./AppIcon/AppIcon";
const { padStart } = utilsService;
export default (props) => {
    const scheduleInterval = storeService.store.GLOBAL.schedularInterval;
    const [selectedTime, setSelectedTime] = useState(null)
    const [timeHours, settimeHours] = useState('')
    const [timeMinutes, settimeMinutes] = useState('')
    const [timeMod, settimeMod] = useState('AM')

    const [popTime, setpopTime] = useState({
        hours: '01',
        minutes: '00',
        mod: 'AM'
    })
    const [showPopModal, setshowPopModal] = useState(false)
    const getParsedVal = (e) => {
        let val = e.target.value || '';
        let [d1, d2, d3] = val.split('')
        if (d1) {
            if (isNaN(d1 - 0)) {
                d1 = '0'
            }
        }
        if (d2) {
            if (isNaN(d2 - 0)) {
                d2 = '0'
            }
        }
        val = (d1 || '') + '' + (d2 || '');
        if(d3) {
            if (!isNaN(d3 - 0)) {
                return d2 + '' + d3;
            }
        }
        return val;
    }
    const handlePickerSet = () => {
        settimeHours(popTime.hours)
        settimeMinutes(popTime.minutes)
        settimeMod(popTime.mod);
        setshowPopModal(false)
    }
    useEffect(() => {
        if (props.value != selectedTime) {
            setSelectedTime(props.value)
            let [hours, minutes] = props.value.split(':').map(Number);
            const { hours12, minutes12, modifier } = utilsService.get12From24Time(hours, minutes);
            settimeHours(hours12)
            settimeMinutes(minutes12)
            settimeMod(modifier)
        }
    }, [props.value])
    const handleSelectedTime = (e) => {
        let timeValue = e.target.value;
        let [hours, minutes] = timeValue.split(':').map(Number);
        if (minutes % scheduleInterval !== 0) {
            const closest = Math.round(minutes / scheduleInterval) * scheduleInterval;
            minutes = closest === 60 ? 0 : closest;
        }
        const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
        setSelectedTime(formattedTime);
        props.onChange && props.onChange(formattedTime);
    }
    useEffect(() => {
        if (timeHours && timeMinutes && timeMod) {
            const { strVal } = utilsService.get24From12Time(timeHours - 0, timeMinutes - 0, timeMod);
            props.onChange && props.onChange(strVal);
        }
    }, [timeHours, timeMinutes, timeMod])

    // return (<input value={selectedTime} onChange={handleSelectedTime} type="time" step="900" />);
    return (
        <>
            <div className="flex">
                <div className="custom-time-picker text-hours">
                    <input type="text" value={timeHours} onChange={e => {
                        settimeHours(getParsedVal(e))
                    }} onBlur={e => {
                        let val = timeHours - 0;
                        if (isNaN(timeHours - 0)) {
                            val = 0;
                        }
                        if (val == 0) {
                            val = 1;
                        }
                        if (val > 12) {
                            val = 12;
                        }
                        settimeHours(utilsService.padStart(val))
                    }} className="custom-time-picker__input" placeholder="hh" />
                </div>
                <div className="custom-time-picker text-minutes">
                    <input type="text" value={timeMinutes} onChange={e => {
                        settimeMinutes(getParsedVal(e))
                    }} onBlur={e => {
                        let val = timeMinutes - 0;
                        if (isNaN(timeMinutes - 0)) {
                            val = 0;
                        }
                        if(val < 10) {
                            settimeMinutes(val + '')
                            return;
                        }
                        if (val % scheduleInterval !== 0) {
                            const closest = Math.round(val / scheduleInterval) * scheduleInterval;
                            val = closest === 60 ? 0 : closest;
                        }
                        if (val >= 60) {
                            val = 0;
                        }
                        settimeMinutes(utilsService.padStart(val))
                    }} className="custom-time-picker__input" placeholder="mm" />
                </div>
                <select className="custom-time-picker-select" value={timeMod} onChange={e => {
                    settimeMod(e.target.value)
                }}>
                    <option>AM</option>
                    <option>PM</option>
                </select>
                <a className="flex align-center ml-2" href="" onClick={e => {
                    e.preventDefault()
                    setpopTime({
                        hours: timeHours,
                        minutes: timeMinutes,
                        mod: timeMod
                    })
                    setshowPopModal(true)
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 48 48">
                        <path d="M 21.5 3 A 1.50015 1.50015 0 1 0 21.5 6 L 22.5 6 L 22.5 8.0761719 C 13.276989 8.842722 6 16.582823 6 26 C 6 35.923357 14.076636 44 24 44 C 33.923364 44 42 35.923357 42 26 C 42 21.577219 40.3896 17.528171 37.732422 14.390625 L 39.556641 12.564453 A 1.50015 1.50015 0 0 0 38.466797 9.9902344 A 1.50015 1.50015 0 0 0 37.435547 10.443359 L 35.609375 12.267578 C 32.830917 9.9145087 29.335848 8.3949799 25.5 8.0761719 L 25.5 6 L 26.5 6 A 1.50015 1.50015 0 1 0 26.5 3 L 21.5 3 z M 23.822266 11.009766 A 1.50015 1.50015 0 0 0 24.185547 11.009766 C 28.220081 11.058889 31.863047 12.68515 34.527344 15.308594 A 1.5012209 1.5012209 0 0 0 34.685547 15.466797 C 37.353026 18.172834 39 21.887902 39 26 C 39 34.302039 32.302045 41 24 41 C 15.697955 41 9 34.302039 9 26 C 9 17.757971 15.603517 11.105631 23.822266 11.009766 z M 30.470703 17.986328 A 1.50015 1.50015 0 0 0 29.439453 18.439453 L 24.773438 23.105469 A 3 3 0 0 0 24 23 A 3 3 0 0 0 24 29 A 3 3 0 0 0 26.896484 25.224609 L 31.560547 20.560547 A 1.50015 1.50015 0 0 0 30.470703 17.986328 z"></path>
                    </svg>
                </a>
            </div>
            {
                showPopModal && (
                    <AppModal autoWidth={true}>
                        <div className="p-3 timepicker-modal">
                            <div className="justify-between align-center">
                                <div className="align-center">
                                    <h2 className="h4-heading mr-6">
                                        Timepicker
                                    </h2>
                                </div>
                                <button
                                    type="button"
                                    className="bg-none no-border"
                                    onClick={e => {
                                        setshowPopModal(false)
                                    }}
                                >
                                    <AppIcon iconName="closeIcon" iconColor="#1B72E6" />
                                </button>
                            </div>
                            <div className="flex justify-center pt-2">
                                <label className="label text-center">{popTime.hours}:{popTime.minutes} {popTime.mod}</label>
                            </div>
                            <div className="flex py-3">
                                <select size="6" className="hour-list mr-3 pointer" value={popTime.hours} onChange={e => {
                                    let val = e.target.value;
                                    setpopTime({
                                        ...popTime,
                                        hours: val
                                    })
                                }}>
                                    {
                                        new Array(12).fill(0).map((x, xi) => {
                                            return (<option value={padStart(xi + 1)}>{padStart(xi + 1)}</option>)
                                        })
                                    }
                                </select>
                                <select size="6" className="hour-list mr-3 pointer" value={popTime.minutes} onChange={e => {
                                    let val = e.target.value;
                                    setpopTime({
                                        ...popTime,
                                        minutes: val
                                    })
                                }}>
                                    {
                                        ['00', '10', '20', '30', '40', '50'].map((x, xi) => {
                                            return (<option value={x}>{x}</option>)
                                        })
                                    }
                                </select>
                                <select size="6" className="hour-list pointer" value={popTime.mod} onChange={e => {
                                    let val = e.target.value;
                                    setpopTime({
                                        ...popTime,
                                        mod: val
                                    })
                                }}>
                                    {
                                        ['AM', 'PM'].map((x, xi) => {
                                            return (<option value={x}>{x}</option>)
                                        })
                                    }
                                </select>
                            </div>
                            <div className="flex justify-center">
                                <button type="button" onClick={handlePickerSet} className="btn-medium-2">Set</button>
                            </div>
                        </div>
                    </AppModal>
                )
            }
        </>
    )
}