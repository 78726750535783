import { useState, useEffect } from 'react'
import Select from 'react-select';
import dataService from '../../services/data.service';
import ConfirmModal from '../molecules/ConfirmModal';
export default ({ openModal, onCloseClick, collection }) => {
    const [isOpen, setIsOpen] = useState(openModal)
    const [loading, setLoading] = useState(false)

    const [testCaseList, setTestCaseList] = useState([])
    const [selectedTestCase, setSelectedTestCase] = useState(null)
    const [inputParams, setInputParams] = useState('')
    const [expectedOutput, setExpectedOutput] = useState('')
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [confirmType, setConfirmType] = useState('delete')
    const handleSave = (e) => {
        e.preventDefault();
        try {
            JSON.parse(inputParams)
            JSON.parse(expectedOutput)
        } catch (e) {
            return
        }
        setConfirmType('save')
        setShowConfirmModal(true)
    }
    const handleDelete = async (e) => {
        e.preventDefault();
        setConfirmType('delete')
        setShowConfirmModal(true)
    }
    const handleConfirmDelete = async (e) => {
        setLoading(true);
        await dataService.deleteTestCase({
            collectionId: collection._id,
            testcaseId: selectedTestCase.value
        });
        await dataService.getCollectionsWithDetails();
        setShowConfirmModal(false)
        setLoading(false);
        onCloseClick && onCloseClick()
    }
    const handleConfirmSave = async (e) => {
        setLoading(true);
        await dataService.updatetestcase({
            collectionId: collection._id,
            testcaseId: selectedTestCase.value,
            testCaseToUpdate: {
                input: JSON.parse(inputParams),
                expectedOutput: JSON.parse(expectedOutput)
            }
        })
        await dataService.getCollectionsWithDetails();
        setShowConfirmModal(false)
        setLoading(false);
        onCloseClick && onCloseClick()
    }
    const closeModal = (e) => {
        e.preventDefault();
        onCloseClick && onCloseClick()
    }
    const handleOnOkConfirm = (e) => {
        if(confirmType == 'delete'){
            handleConfirmDelete(e)
        } else {
            handleConfirmSave(e)
        }
    }
    const handleTestcaseChange = (changedVal) => {
        setSelectedTestCase(changedVal);
        setInputParams(JSON.stringify(changedVal.input, null, 2))
        setExpectedOutput(JSON.stringify(changedVal.expectedOutput, null, 2))
    }
    const parseTestCaseList = (testcases) => {
        let testcasesToSet = testcases.map(x => {
            return {
                label: x.testId + ' : ' + x.name,
                value: x.testId,
                input: x.input,
                expectedOutput: x.expectedOutput
            }
        });
        setTestCaseList(testcasesToSet);
        if (testcasesToSet) {
            let firstTestCase = testcasesToSet[0]
            setSelectedTestCase(firstTestCase)
            setInputParams(JSON.stringify(firstTestCase.input, null, 2))
            setExpectedOutput(JSON.stringify(firstTestCase.expectedOutput, null, 2))
        }
    }
    useEffect(() => {
        if (collection && collection.testcases) {
            parseTestCaseList(collection.testcases)
        }
    }, [])
    useEffect(() => {
        if (collection && collection.testcases) {
            parseTestCaseList(collection.testcases)
        }
    }, [collection])
    useEffect(() => {
        setIsOpen(openModal)
    }, [openModal])
    if (!isOpen) return null;
    return (
        <>
            <div className="app-side-modal show">
                <div className="app-side-modal__bg"></div>
                <div className="app-side-modal__content">
                    <div className="align-center p-4 modal-head">
                        <a href="#" onClick={closeModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="close-svg-icon" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <path d="M27.4101 24.5813C27.7857 24.9569 27.9967 25.4663 27.9967 25.9974C27.9967 26.5285 27.7857 27.0379 27.4101 27.4134C27.0345 27.789 26.5251 28 25.9939 28C25.4628 28 24.9534 27.789 24.5778 27.4134L14 16.833L3.41888 27.4101C3.0433 27.7857 2.53389 27.9967 2.00273 27.9967C1.47158 27.9967 0.962173 27.7857 0.586588 27.4101C0.211002 27.0346 7.91487e-09 26.5252 0 25.9941C-7.91487e-09 25.4629 0.211002 24.9536 0.586588 24.578L11.1677 14.0008L0.589919 3.42034C0.214334 3.04478 0.00333218 2.53541 0.00333219 2.00428C0.00333219 1.47315 0.214334 0.963782 0.589919 0.588218C0.965505 0.212655 1.47491 0.00166568 2.00607 0.00166568C2.53722 0.00166567 3.04663 0.212655 3.42221 0.588218L14 11.1687L24.5811 0.586552C24.9567 0.210989 25.4661 -8.84857e-09 25.9973 0C26.5284 8.84857e-09 27.0378 0.210989 27.4134 0.586552C27.789 0.962115 28 1.47149 28 2.00261C28 2.53374 27.789 3.04312 27.4134 3.41868L16.8323 14.0008L27.4101 24.5813Z" fill="#1B72E6" />
                            </svg>
                        </a>
                        <div className="w-2/5 ml-4">
                            <h3 className='h3-heading align-center'>
                                Edit Test Case
                            </h3>
                        </div>
                    </div>

                    <div className="model-main-content p-3">
                        <div className="section-box rounded-sm">
                            <form className="app-form">
                                <div className='app-form__fields modal-form-content '>
                                    <div className="form-field-select mb-8">
                                        <label className="label mb-2">Test Case</label>
                                        <div className="align-center">
                                            <div className="form-field">
                                                <Select
                                                    name="testcaselist"
                                                    value={selectedTestCase}
                                                    options={testCaseList}
                                                    onChange={handleTestcaseChange}
                                                    className="basic-multi-select select-text"
                                                    classNamePrefix="select"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="test-case-info__field mb-8">
                                        <label className="test-case-info__label">Input</label>
                                        <textarea value={inputParams} onChange={e => setInputParams(e.target.value)} className="w-full no-resize text-area mt-2" rows="5"></textarea>
                                    </div>
                                    <div className="test-case-info__field mb-8">
                                        <label className="test-case-info__label">Output</label>
                                        <textarea value={expectedOutput} onChange={e => setExpectedOutput(e.target.value)} className="w-full no-resize text-area mt-2" rows="5"></textarea>
                                    </div>
                                </div>

                                <div className="app-form__controls px-6 py-4 align-center">
                                    <button className="btn-medium-2 warning btn-medium-width" type='button' onClick={e => handleDelete(e)} disabled={loading}>Delete</button>
                                    <button className="ml-4 btn-medium-2 btn-medium-width" type='button' onClick={e => handleSave(e)} disabled={loading}>Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {
                showConfirmModal && (
                    <ConfirmModal
                        confirmationTitle={confirmType == 'delete' ? 'Are you sure you want to delete the test case?' : 'Are you sure you want to save the test case details?'}
                        confirmationMessage={'The changes will apply from next run'}
                        confirmButtonLabel={confirmType == 'delete' ? 'Delete' : 'Save'}
                        onOk={handleOnOkConfirm}
                        onCancel={e => setShowConfirmModal(false)}
                    />
                )
            }
        </>
    )
}