import { useState, useEffect } from 'react';
import SvgIcon from '../../comps/atoms/SvgIcon';
import ApiCollectionList from '../../comps/organisms/ApiCollectionList';
import dataService from '../../services/data.service';
import storeService from '../../services/store.service';
import utilsService from '../../services/utils.service';
import StackedBarChart from '../../comps/organisms/StackedBarChart';
import LineChart from '../../comps/atoms/LineChart';
import AppIcon from '../../comps/atoms/AppIcon/AppIcon';
import APISettingsModal from '../../comps/atoms/APISettingsModal';
import apiService from "../../services/api.service.js";
import ApiRegressionSideBar from './ApiRegressionSideBar.jsx';
import ApiDefinitionModal from './ApiDefinitionModal.jsx';
import SectionLoader from '../../comps/atoms/SectionLoader'; 
import { Tooltip } from 'react-tooltip';

let initialCall = true;
export default () => {
    const today = utilsService.getYMDFormat(new Date());
    let historyData = [
        { total: 31 + 19, pass: 31, fail: 19, date: "1/2" },
        { total: 35 + 15, pass: 35, fail: 15, date: "1/3" },
        { total: 25 + 15, pass: 25, fail: 15, date: "1/4" },
        { total: 34 + 25, pass: 34, fail: 25, date: "1/8" },
        { total: 34 + 16, pass: 34, fail: 16, date: "1/9" },
        { total: 37 + 16, pass: 37, fail: 16, date: "1/10" },
        { total: 36 + 13, pass: 36, fail: 13, date: "1/11" },
        { total: 39 + 14, pass: 39, fail: 14, date: "1/12" },
        { total: 35 + 11, pass: 35, fail: 11, date: "1/15" },
        { total: 44 + 15, pass: 44, fail: 15, date: "1/16" },
        { total: 41 + 16, pass: 41, fail: 16, date: "1/17" },
        { total: 40 + 19, pass: 40, fail: 19, date: "1/18" },
        { total: 46 + 10, pass: 46, fail: 10, date: "1/19" },
        { total: 38 + 14, pass: 38, fail: 14, date: "1/22" },
    ]
    let todayDate = new Date();
    let fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 30);
    const [overviewData, setOverviewData] = useState({
        totalCollectionsCount: 0,
        failedCollectionsCount: 0,
        consecutiveFailuresCount: 0,
        totalTestcasesCount: 0,
        failedTestcasesCount: 0,
        consecutiveTestcasesFailuresCount: 0,
        lastDateRan: storeService.store.COLLECTIONS_WITH_DETAILS.filterData.selectedDate || null,
        rangeFromDate: utilsService.getYMDFormat(fromDate),
        rangeEndDate: utilsService.getYMDFormat(todayDate),
        collectionsTestedCount: 0
    })
    const [collections, setCollections] = useState([]);
    const [showTestDataButton, setShowTestDataButton] = useState(false);
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [showApiDefinitionModal, setShowApiDefinitionModal] = useState(false);
    const [apiGlidestats, setAPIGlidestats] = useState({});
    const [date, setDate] = useState(storeService.store.COLLECTIONS_WITH_DETAILS.filterData.selectedDate);
    const [showSideModal, setShowSideModal] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [runsFilterData, setRunsFilterData] = useState({
        selectedFilter: 'allCollections'
    })
    const [selectedEnvId, setselectedEnvId] = useState(storeService.store.ENVIRONMENTS.selectedEnvId);
    const showSideBarModal = (e, value) => {
        e.preventDefault();
        storeService.pub(utilsService.CONSTANTS.EVENT_NAME.OPEN_TESTCASES_DETAILS_SIDEBAR_MODAL, {
            showModal: true,
            selectedFilter: value
        })
    }

    getAPIGlideData = async (precompute = false) => {
        const apiGlideStats = await apiService.post({
            url: utilsService.END_POINTS.getstats,
            data: {
                "filter_date": date || '',
                selectedEnvId: selectedEnvId,
                module_name: "apiglide"
            }
        });
        setAPIGlidestats(apiGlideStats?.data || {}); 
        if (initialCall) {
            getAPIGlideData(false);
            initialCall = false;
        }
        setLoading(false);
    }
    useEffect(() => {
        getAPIGlideData(true);
        let pageStore = storeService.getStore('COLLECTIONS_WITH_DETAILS');
        storeService.subscribe('COLLECTIONS_WITH_DETAILS', ({ detail }) => {
            parseListData([...detail.items]);
        });
        storeService.sub(utilsService.CONSTANTS.EVENT_NAME.OPEN_SETTINGS_MODAL, ({ detail }) => {
            setShowSettingsModal(detail);
        });
        storeService.subscribe('ENVIRONMENTS', ({detail}) => {
            setselectedEnvId(detail.selectedEnvId)
        })
       
        storeService.sub(utilsService.CONSTANTS.EVENT_NAME.OPEN_TESTCASES_DETAILS_SIDEBAR_MODAL, ({ detail }) => {
            setRunsFilterData({selectedFilter: detail.selectedFilter })
            setShowSideModal(detail.showModal);
        });
    }, [])
    const countConsecutiveOnes = (arr) => {
        let consecutiveCount = 0;
        let maxConsecutiveCount = 0;
      
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] === 1) {
            consecutiveCount++;
            if (consecutiveCount > maxConsecutiveCount) {
              maxConsecutiveCount = consecutiveCount;
            }
          } else {
            consecutiveCount = 0;
          }
        }
        return maxConsecutiveCount;
    }
    const parseListData = (items) => {
        let testcaseCount = 0, collectionsTestedCount = 0,
            failedTestcasesCount = 0,
            consecutiveFailedCount = 0,
            consecutiveTestcasesFailuresCount = 0,
            failedCollectionsCount = 0;
        items &&items.forEach(item => {
            item.testcasesCount = (item.testcases || []).length;
            collectionsTestedCount++;
            testcaseCount += item.testcasesCount;  
            if(item.testresults && item.testresults.length > 0) {
                let recentJob = item.testresults[0];
                let failedCount = recentJob.results?.filter(x => x.result == 'Failed')?.length;
                if(failedCount != 0) {
                    failedCollectionsCount += 1;
                }
                failedTestcasesCount += failedCount;
                  

                let allJobStatusList = item?.testresults?.map(x => {
                    return x.results?.find(y => y.result == 'Failed') ? 1 : 0;
                });
                if(countConsecutiveOnes(allJobStatusList) > 1) {
                    consecutiveFailedCount += 1;
                }
            }
            if(item?.testcasesCount > 0) {
                item?.testcases?.forEach(test => {
                    let testresults = [];
                    if(item?.testresults && item?.testresults.length) {
                        for(let i = 0; i < item?.testresults.length; i++) {
                            let testresult = item?.testresults[i]?.results?.find(x => x.testId == test.testId);
                            if(testresult) {
                                testresults.push(testresult);
                            }
                        }
                    }
                    test.results = testresults;
                    let statusList = testresults?.map(x => x.result == 'Failed' ? 1 : 0);
                    if(testresults?.length > 0 && countConsecutiveOnes(statusList) > 1 && testresults[0]?.result == 'Failed') {
                        consecutiveTestcasesFailuresCount += 1;
                    }
                });
            }
        });

        // setOverviewData({
        //     ...overviewData,
        //     totalCollectionsCount: items.length,
        //     totalTestcasesCount: testcaseCount,
        //     collectionsTestedCount,
        //     failedCollectionsCount,
        //     failedTestcasesCount,
        //     consecutiveFailuresCount: consecutiveFailedCount,
        //     consecutiveTestcasesFailuresCount
        // })
        // setCollections(items);
    }
    useEffect(() => {

    }, [])
    const setDateFromElement = (value, fieldName) => {
        setOverviewData({
            ...overviewData,
            [fieldName]: value
        })
        setDate(value)
        storeService.store.COLLECTIONS_WITH_DETAILS.filterData.selectedDate = value;
    }

    useEffect(() => {
        getAPIGlideData();
    },[date, selectedEnvId])

    const openApiGlideModal = () => {
        setShowTestDataButton(false);
        setShowSettingsModal(true);
    }

    const apiDefinitionModal = (flag) => {
        setShowApiDefinitionModal(flag);
        setShowTestDataButton(false);
    }
    
    return (
        <>
            <div className="section-box p-6 rounded-l">
                <div className="flex justify-between align-center">
                    <div className="flex align-center pointer" id="apiglide-tooltip">
                        <h2 className="mr-3">Overview</h2>
                        <img src="/icons/info-svg.svg" className="svg-icon-l" />
                        <Tooltip
                            anchorSelect="#apiglide-tooltip"
                            content="An API maps to one collection with multiple test cases. View stats for API tests (created, executed, failed) and detailed counts for test cases (created, run, failed). Filter results by environment and date."
                            place="right"
                            style={{
                                backgroundColor: "#000",
                                color: "#ffffff",
                                borderRadius: "5px",
                                zIndex: 22,
                                fontWeight: "bold",
                                width: "50%",
                            }}
                        />
                    </div>
                    <div className="flex align-center relative">
                        <span className="h5-heading para-label mr-2 subheading-color">Select Date</span>
                        <input className="input-date" onChange={e => setDateFromElement(e.target.value, 'lastDateRan')} value={overviewData.lastDateRan}  type="date" name="lasttestrun" id="lasttestrun" max={today} />
                        <div>
                            <button type='button' className="bg-none no-border p-2 ml-2 relative" 
                                onClick={() => setShowTestDataButton(!showTestDataButton)}>
                                    <AppIcon iconName="options" />
                            </button>
                            {showTestDataButton ?
                                <div class="apiglide-setting-menu absolute">
                                    <div>
                                        <ul>
                                            <li>
                                                <button 
                                                    type='button' 
                                                    onClick={() => openApiGlideModal()}
                                                    className='menu-btn p-3'>Test Data
                                                </button>
                                            </li>
                                            <li>
                                                <button 
                                                    type='button' 
                                                    onClick={() => apiDefinitionModal(true)}
                                                    className='menu-btn p-3'>Convert API Definition
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div> : null 
                            }
                        </div>
                    </div>
                </div>
                <div className="flex box-tiles mt-4">
                    <div className="box-tile w-2/4 pr-2">
                        <div className={'box-tile__item box-tile__left p-6 ' + (apiGlidestats?.failed_collections - 0 > 0 ? 'error': '')}>
                            <div>
                                <h4 className="h4-heading subheading-color pointer d-inline" id="collectionText">
                                    <span className="mr-2">Collections</span>
                                    <SvgIcon path="/icons/info-svg.svg"></SvgIcon>
                                </h4>
                                <Tooltip
                                    anchorSelect="#collectionText"
                                    content="Each API represents a collection, containing multiple test cases."
                                    place="right"
                                    style={{
                                        backgroundColor: "#000",
                                        color: "#fff",
                                        borderRadius: "5px",
                                        zIndex: 22,
                                        fontWeight: "bold",
                                        width:"30%",
                                    }}
                                />
                            </div>
                            <div className="flex card-section mt-4">
                                <div className="card-item">
                                    <div className="card-item__no align-center">
                                        <a href="#" onClick={e => showSideBarModal(e, 'allCollections')} className="align-center">
                                            <span className="mr-3">{apiGlidestats?.collections}</span>
                                            <SvgIcon path="../icons/arrow-right.svg"></SvgIcon>
                                        </a>
                                    </div>
                                    <div className="card-item__text align-center">
                                        <span className="mr-1 mt-2">Collections</span>
                                    </div>
                                </div>
                                <div className="card-item">
                                    <div className="card-item__no align-center">
                                        <a href="#" onClick={e => showSideBarModal(e, 'collectionsTested')} className="align-center">
                                            <span className="mr-3">{ apiGlidestats?.collections_tested }</span>
                                            <SvgIcon path="../icons/arrow-right.svg"></SvgIcon>
                                        </a>
                                    </div>
                                    <div className="card-item__text align-center">
                                        <span className="mr-1 mt-2">Executed Collections</span>
                                    </div>
                                </div>
                                <div className="card-item">
                                    <div className="card-item__no align-center">
                                        <a href="#" onClick={e => showSideBarModal(e, 'failedCollections')} className="align-center">
                                            <span className="mr-3">{ apiGlidestats?.failed_collections }</span>
                                            <SvgIcon path="../icons/arrow-right.svg"></SvgIcon>
                                        </a>
                                    </div>
                                    <div className="card-item__text align-center">
                                        <span className="mr-1 mt-2">Failed Collections</span>
                                    </div>
                                </div>
                                <div className="card-item">
                                    <div className="card-item__no align-center">
                                        <a href="#" onClick={e => showSideBarModal(e, 'consecutiveFailedCollections')} className="align-center">
                                            <span className="mr-3">{ apiGlidestats?.consecutive_failed_collections }</span>
                                            <SvgIcon path="../icons/arrow-right.svg"></SvgIcon>
                                        </a>
                                    </div>
                                    <div className="card-item__text align-center">
                                        <span className="mr-1 mt-2">Consecutive Failures</span>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <div className="box-tile w-2/4 pr-2">
                        <div className={'box-tile__item box-tile__left p-6 ' + (apiGlidestats?.failed_cases - 0 > 0 ? 'error': '')}>
                            <h4 className="h4-heading subheading-color">Cases</h4>
                            <div className="flex card-section mt-4">
                                <div className="card-item">
                                    <div className="card-item__no align-center">
                                        <a href="#" onClick={e => showSideBarModal(e, 'allCases')} className="align-center">
                                            <span className="mr-3">{ apiGlidestats?.cases }</span>
                                            <SvgIcon path="../icons/arrow-right.svg"></SvgIcon>
                                        </a>
                                    </div>
                                    <div className="card-item__text align-center">
                                        <span className="mr-1 mt-2">Cases</span>
                                    </div>
                                </div>

                                <div className="card-item">
                                    <div className="card-item__no align-center">
                                        <a href="#" onClick={e => showSideBarModal(e, 'failedCases')} className="align-center">
                                            <span className="mr-3">{ apiGlidestats?.failed_cases }</span>
                                            <SvgIcon path="../icons/arrow-right.svg"></SvgIcon>
                                        </a>
                                    </div>
                                    <div className="card-item__text align-center">
                                        <span className="mr-1 mt-2">Failed Cases</span>
                                    </div>
                                </div>

                                <div className="card-item">
                                    <div className="card-item__no align-center">
                                        <a href="#" onClick={e => showSideBarModal(e, 'consecutiveFailedCases')} className="align-center">
                                            <span className="mr-3">{ apiGlidestats?.consecutive_failed_cases }</span>
                                            <SvgIcon path="../icons/arrow-right.svg"></SvgIcon>
                                        </a>
                                    </div>
                                    <div className="card-item__text align-center">
                                        <span className="mr-1 mt-2">Consecutive Failures</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isLoading && (
                        <SectionLoader></SectionLoader>
                    )
                }
            </div>

            <div className="table-list-section px-6 py-7 rounded-l mt-6">
                <ApiCollectionList selectedEnvId={selectedEnvId} date={date}></ApiCollectionList>
            </div>

            <div className="section-box p-6 rounded-l mt-6 mb-6 page-trends hidden">
                <div className="flex justify-between align-center">
                    <div className="flex align-center">
                        <h2>Collection Trends</h2>
                        <svg className="ml-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                            viewBox="0 0 20 20" fill="none">
                            <path
                                d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433281 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 7.34783 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 17.5C8.51664 17.5 7.06659 17.0601 5.83323 16.236C4.59986 15.4119 3.63856 14.2406 3.07091 12.8701C2.50325 11.4997 2.35472 9.99168 2.64411 8.53682C2.9335 7.08196 3.64781 5.74559 4.6967 4.6967C5.7456 3.6478 7.08197 2.9335 8.53683 2.64411C9.99168 2.35472 11.4997 2.50325 12.8701 3.0709C14.2406 3.63856 15.4119 4.59985 16.236 5.83322C17.0601 7.06659 17.5 8.51664 17.5 10C17.5 11.9891 16.7098 13.8968 15.3033 15.3033C13.8968 16.7098 11.9891 17.5 10 17.5Z"
                                fill="#869DBB" fillOpacity="0.7" />
                            <path
                                d="M10 8.75C9.66848 8.75 9.35054 8.8817 9.11612 9.11612C8.8817 9.35054 8.75 9.66848 8.75 10V13.75C8.75 14.0815 8.8817 14.3995 9.11612 14.6339C9.35054 14.8683 9.66848 15 10 15C10.3315 15 10.6495 14.8683 10.8839 14.6339C11.1183 14.3995 11.25 14.0815 11.25 13.75V10C11.25 9.66848 11.1183 9.35054 10.8839 9.11612C10.6495 8.8817 10.3315 8.75 10 8.75Z"
                                fill="#869DBB" fillOpacity="0.7" />
                            <path
                                d="M10 7.1875C10.6904 7.1875 11.25 6.62786 11.25 5.9375C11.25 5.24714 10.6904 4.6875 10 4.6875C9.30964 4.6875 8.75 5.24714 8.75 5.9375C8.75 6.62786 9.30964 7.1875 10 7.1875Z"
                                fill="#869DBB" fillOpacity="0.7" />
                        </svg>
                    </div>
                    <div className="flex">
                        <div className="mr-4">
                            <span className="h5-heading para-label mr-2">From Date:</span>
                            <input onChange={e => setDateFromElement(e.target.value, 'rangeFromDate')} value={overviewData.rangeFromDate} className="input-date" type="date" name="lasttestrun" id="lasttestrun" />
                        </div>
                        <div>
                            <span className="h5-heading para-label mr-2">To Date:</span>
                            <input onChange={e => setDateFromElement(e.target.value, 'rangeEndDate')} value={overviewData.rangeEndDate} className="input-date" type="date" name="lasttestrun" id="lasttestrun" />
                        </div>
                    </div>
                </div>

                <div className="mt-4 flex flex-layout">
                    <div className="flex-layout__col50 pr-2">
                        <StackedBarChart minheight="480" mode="API" data={historyData}></StackedBarChart>
                    </div>
                    <div className="flex-layout__col50 pl-2">
                        <LineChart></LineChart>
                    </div>
                </div>
            </div>
            {showSettingsModal ? <APISettingsModal /> : null }
            {showApiDefinitionModal ? <ApiDefinitionModal onClose={()=> apiDefinitionModal(false)}/> : null }
            {showSideModal ? 
                <ApiRegressionSideBar testsfilter={runsFilterData.selectedFilter} dateSelected={date} selectedEnvId={selectedEnvId}/> : null}
        </>
    );
}