import React, { useState } from "react";
import Select from 'react-select';
import AppIcon from "./AppIcon.jsx";
import dataService from "../../services/data.service.js";
import EditDeviceModal from "./EditDeviceModal.jsx";
import AppModal from "../organisms/AppModal.jsx";
import ConfirmModal from "./ConfirmModal.jsx";

export default ({ devices, fetchUpdate }) => {

  const [addNewDevice, setAddNewDevice] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [editDevice, setEditDevice] = useState(false);
  const [deviceDetails, setDeviceDetails] = useState({
    NAME: "iPhone",
    OS: "Android",
    OS_VERSION: "Android 12",
    BROWSER: "Google Chrome",
    BROWSER_VERSION: "Chrome 98",
    RESOLUTION: "1440x1080",
    MODE: "Landscape",
    DEVICE_GLASS: "Virtual"
  });
  const [checkedDevices, setCheckedDevices] = useState([]);
  
  const closeModal = () => {
    setAddNewDevice(false);
    fetchUpdate && fetchUpdate();
  };
  
  const deviceOptions = [
    { label: 'iPhone', value: 'iPhone' },
    { label: 'Samsung Galaxy', value: 'Samsung Galaxy' },
    { label: 'Google Pixel', value: 'Google Pixel' },
    { label: 'Huawei Mate', value: 'Huawei Mate' },
    { label: 'OnePlus', value: 'OnePlus' },
    { label: 'Xiaomi Mi', value: 'Xiaomi Mi' },
    { label: 'Sony Xperia', value: 'Sony Xperia' },
    { label: 'LG G Series', value: 'LG G Series' },
    { label: 'Motorola Moto', value: 'Motorola Moto' },
    { label: 'Nokia Lumia', value: 'Nokia Lumia' }
  ];
  
  const osOptions = [
    { label: 'Android', value: 'Android' },
    { label: 'iOS (iPhone Operating System)', value: 'iOS' },
    { label: 'Windows', value: 'Windows' },
    { label: 'macOS', value: 'macOS' },
    { label: 'Linux', value: 'Linux' },
    { label: 'Chrome OS', value: 'Chrome OS' }
  ];
  
  const osVersionOptions = [
    { label: 'Android 12', value: 'Android 12' },
    { label: 'Android 11', value: 'Android 11' },
    { label: 'Android 10', value: 'Android 10' },
    { label: 'Android 9 (Pie)', value: 'Android 9' },
    { label: 'iOS 15', value: 'iOS 15' },
    { label: 'iOS 14', value: 'iOS 14' },
    { label: 'iOS 13', value: 'iOS 13' },
    { label: 'iOS 12', value: 'iOS 12' }
  ];
  
  const browserOptions = [
    { label: 'Google Chrome', value: 'Google Chrome' },
    { label: 'Mozilla Firefox', value: 'Mozilla Firefox' },
    { label: 'Apple Safari', value: 'Apple Safari' },
    { label: 'Microsoft Edge', value: 'Microsoft Edge' },
    { label: 'Opera', value: 'Opera' },
    { label: 'Brave', value: 'Brave' },
    { label: 'Vivaldi', value: 'Vivaldi' }
  ];
  
  const browserVersionOptions = [
    { label: 'Chrome 98', value: 'Chrome 98' },
    { label: 'Firefox 98', value: 'Firefox 98' },
    { label: 'Safari 15', value: 'Safari 15' },
    { label: 'Edge 98', value: 'Edge 98' },
    { label: 'Opera 82', value: 'Opera 82' },
    { label: 'Brave 1.36', value: 'Brave 1.36' },
    { label: 'Vivaldi 5.0', value: 'Vivaldi 5.0' }
  ];
  
  const resolutionOptions = [
    { label: '1440x1080 (Full HD)', value: '1440x1080' },
    { label: '2560x1440 (QHD)', value: '2560x1440' },
    { label: '3840x2160 (4K UHD)', value: '3840x2160' },
    { label: '1280x720 (HD)', value: '1280x720' },
    { label: '1366x768', value: '1366x768' },
    { label: '1600x900', value: '1600x900' },
    { label: '1680x1050', value: '1680x1050' },
    { label: '1440x900', value: '1440x900' },
    { label: '1280x800', value: '1280x800' }
  ];
  
  const modeOptions = [
    { label: 'Landscape', value: 'Landscape' },
    { label: 'Portrait', value: 'Portrait' }
  ];
  
  const glassOptions = [
    { label: 'Virtual', value: 'Virtual' },
    { label: 'Physical', value: 'Physical' }
  ];
  
  const addNewDeviceToList = async () => {
    await dataService.addNewDevice({
        deviceToAdd: deviceDetails
    }); 
    setDeviceDetails({...deviceDetails, deviceId: ''});
    closeModal();
  }

  const deleteDevices = async () => {
    await dataService.deleteDevice({
        devicesToDelete: checkedDevices
    }); 
    setShowConfirmModal(false);
    setCheckedDevices([]);
    fetchUpdate && fetchUpdate();
  }

  const handleCheck = async device => {
     const { _id: deviceId } = device;
     const deviceAdded = checkedDevices.some(checkedDevice => checkedDevice._id === deviceId);
     if (deviceAdded) {
        setCheckedDevices(checkedDevices.filter(item => item._id !== deviceId));
      } else {
        setCheckedDevices([...checkedDevices, device]);
      }
  };
  

  return (
    <div className="table-list-section p-5 rounded-l mt-6">
      <div className="flex align-center justify-between">
        <div className="flex align-center">
          <div className="flex align-center form-field">
            <h2 className="mr-1">Test Devices</h2>
          </div>
        </div>
        <div className="flex align-center justify-end">
          <button
            onClick={() => setAddNewDevice(true)}
            className={
              "py-1 no-border bg-none flex align-center no-underline edit-link-option "
            }
          >
            <AppIcon iconName="groupIcon" iconColor="#185cb9" />
            <span className="ml-2"> Add</span>
          </button>
          <button
            onClick={() => setEditDevice(true)} 
            className={`ml-8 py-1 no-border bg-none flex align-center no-underline edit-link-option ${checkedDevices.length !== 1 ? 'disabled' : ''}`}>
            <AppIcon
              iconName="editIcon"
              iconColor="#889ab1"
              iconColorr="#889ab1"
            />
            <span className="ml-2">Edit</span>
          </button>
          <button
            onClick={() => setShowConfirmModal(true)}
            className={`ml-8 py-1 no-border bg-none flex align-center no-underline edit-link-option ${checkedDevices.length === 0? 'disabled' : ''}`}
          >
            <AppIcon
              iconName="delete"
              iconColor="#889ab1"
              iconColorr="#889ab1"
            />
            <span className="ml-2">Delete</span>
          </button>
        </div>
      </div>
      <table className="app-table my-4">
        <tbody>
          <tr className="tr-head">
            <td>
              <div className="align-center">
                <span className="mr-1">ID</span>
                <AppIcon iconName="filterIcon" />
              </div>
            </td>
            <td>
              <div className="align-center">
                <span className="mr-1">Device</span>
                <AppIcon iconName="filterIcon" />
              </div>
            </td>
            <td>
              <div className="align-center">
                <span className="mr-1">OS</span>
                <AppIcon iconName="filterIcon" />
              </div>
            </td>
            <td>
              <div className="align-center">
                <span className="mr-1">OS Version</span>
                <AppIcon iconName="filterIcon" />
              </div>
            </td>
            <td>
              <div className="align-center">
                <span className="mr-1">Browser</span>
                <AppIcon iconName="filterIcon" />
              </div>
            </td>
            <td>
              <div className="align-center">
                <span className="mr-1">Browser version</span>
                <AppIcon iconName="filterIcon" />
              </div>
            </td>
            <td>
              <div className="align-center">
                <span className="mr-1">Resolution</span>
                <AppIcon iconName="filterIcon" />
              </div>
            </td>
            <td>
              <div className="align-center">
                <span className="mr-1">Mode</span>
                <AppIcon iconName="filterIcon" />
              </div>
            </td>
            <td>
              <div className="align-center">
                <span className="mr-1">Glass</span>
                <AppIcon iconName="filterIcon" />
              </div>
            </td>
            <td>
              {/* <input type="checkbox" name="checkAll" id="check-all" /> */}
            </td>
          </tr>
          {devices?.map((device, rowIndex) => (
            <tr key={rowIndex}>
              <td className="px-0">{rowIndex + 1}</td>
              <td className="px-0">{device?.name}</td>
              <td className="px-0">{device?.bstack?.os}</td>
              <td className="px-0">{device?.bstack?.osVersion}</td>
              <td className="px-0">{device?.bstack?.browserName}</td>
              <td className="px-0">{device?.bstack?.browserVersion}</td>
              <td className="px-0">
                {device?.dimensions?.width} X {device?.dimensions?.height}
              </td>
              <td className="px-0">{device?.deviceMode}</td>
              <td className="px-0">{device?.deviceGlass}</td>
              <td className="px-0">
                <input 
                    type="checkbox" 
                    name={`check${rowIndex}`} 
                    onChange={() => handleCheck(device)}
                    checked={checkedDevices.some(checkedDevice => checkedDevice._id === device._id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {addNewDevice ? (
        <AppModal>
           <div className="justify-between align-center px-4 py-3">
                <div className="align-center">
                    <h2 className="h2-heading">Add Device</h2>
                </div>
                <button type="button" onClick={closeModal} className="bg-none no-border">
                    <AppIcon iconName="closeIcon" iconColor="#1B72E6" />
                </button>
            </div>
            <div className="p-4">
                <label className="white-space-no-wrap mr-2 bold">
                    Device
                </label>
                <Select
                    name="deviceName"
                    value={deviceOptions.find(x => x.value == deviceDetails.NAME)}
                    options={deviceOptions}
                    onChange={deviceName => setDeviceDetails({...deviceDetails, NAME: deviceName.value})}
                    className="basic-multi-select mt-1"
                    classNamePrefix="select"
                />
            </div>
            <div className="align-center">
                <div className="p-4 w-full">
                    <label className="white-space-no-wrap mr-2 bold">
                        OS
                    </label>
                    <Select
                        name="os"
                        value={osOptions.find(x => x.value == deviceDetails.OS)}
                        options={osOptions}
                        onChange={os => setDeviceDetails({...deviceDetails, OS: os.value})}
                        className="basic-multi-select mt-1"
                        classNamePrefix="select"
                    />
                </div>
                <div className="p-4 w-full">
                    <label className="white-space-no-wrap mr-2 bold">
                        OS Version
                    </label>
                    <Select
                        name="osVersion"
                        value={osVersionOptions.find(x => x.value == deviceDetails.OS_VERSION)}
                        options={osVersionOptions}
                        onChange={osVersion => setDeviceDetails({...deviceDetails, OS_VERSION: osVersion.value})}
                        className="basic-multi-select mt-1"
                        classNamePrefix="select"
                    />
                </div>
            </div>
            <div className="align-center">
                <div className="p-4 w-full">
                    <label className="white-space-no-wrap mr-2 bold">
                        Browser
                    </label>
                    <Select
                        name="browser"
                        value={browserOptions.find(x => x.value == deviceDetails.BROWSER)}
                        options={browserOptions}
                        onChange={browser => setDeviceDetails({...deviceDetails, BROWSER: browser.value})}
                        className="basic-multi-select mt-1"
                        classNamePrefix="select"
                    />
                </div>
                <div className="p-4 w-full">
                    <label className="white-space-no-wrap mr-2 bold">
                        Browser Version
                    </label>
                    <Select
                        name="browserVersion"
                        value={browserVersionOptions.find(x => x.value == deviceDetails.BROWSER_VERSION)}
                        options={browserVersionOptions}
                        onChange={browserVersion => setDeviceDetails({...deviceDetails, BROWSER_VERSION: browserVersion.value})}
                        className="basic-multi-select mt-1"
                        classNamePrefix="select"
                    />
                </div>
            </div>
            <div className="align-center">
                <div className="p-4 w-full">
                    <label className="white-space-no-wrap mr-2 bold">
                        Resolution
                    </label>
                    <Select
                        name="resolution"
                        value={resolutionOptions.find(x => x.value == deviceDetails.RESOLUTION)}
                        options={resolutionOptions}
                        onChange={resolution => setDeviceDetails({...deviceDetails, RESOLUTION: resolution.value})}
                        className="basic-multi-select mt-1"
                        classNamePrefix="select"
                    />
                </div>
                <div className="p-4 w-full">
                    <label className="white-space-no-wrap mr-2 bold">
                        Mode
                    </label>
                    <Select
                        name="mode"
                        value={modeOptions.find(x => x.value == deviceDetails.MODE)}
                        options={modeOptions}
                        onChange={modeOption => setDeviceDetails({...deviceDetails, MODE: modeOption.value})}
                        className="basic-multi-select mt-1"
                        classNamePrefix="select"
                    />
                </div>
            </div>
            <div className="align-center">
                <div className="p-4 w-50">
                    <label className="white-space-no-wrap mr-2 bold">
                        Glass
                    </label>
                    <Select
                        name="glass"
                        value={glassOptions.find(x => x.value == deviceDetails.DEVICE_GLASS)}
                        options={glassOptions}
                        onChange={glass => setDeviceDetails({...deviceDetails, DEVICE_GLASS: glass.value})}
                        className="basic-multi-select mt-1"
                        classNamePrefix="select"
                    />
                </div>
            </div>
            <div className="flex justify-end px-4 mb-4">
                <button onClick={addNewDeviceToList}type="button" className="btn-medium-2">Save</button>
            </div>
        </AppModal>
      ) : null}
      {showConfirmModal && <ConfirmModal onDelete={deleteDevices} onClose={() => setShowConfirmModal(false)}/>}
      {editDevice && 
        <EditDeviceModal 
          checkedDevices={checkedDevices} 
          fetchUpdate={fetchUpdate}
          deviceOptions={deviceOptions}
          osOptions={osOptions}
          osVersionOptions={osVersionOptions}
          browserOptions={browserOptions}
          browserVersionOptions={browserVersionOptions}
          resolutionOptions={resolutionOptions}
          modeOptions={modeOptions}
          glassOptions={glassOptions}
          onClose={() => setEditDevice(false)}
        />}
    </div>
  );
};