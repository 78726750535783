import AppIcon from "../atoms/AppIcon/AppIcon.jsx";
import * as XLSX from 'xlsx';

const ExcelDownload = ({ sheetsData,  data, title = '', additionalClass = '', fileName = 'data.xlsx', sheetName = 'Sheet1', fontClass="" }) => {
  const exportToExcel = () => {
    if(sheetsData) {
      const wb = XLSX.utils.book_new();
      sheetsData.forEach(sheet => {
        const ws = XLSX.utils.json_to_sheet(sheet.data);
        XLSX.utils.book_append_sheet(wb, ws, sheet.name);
      })
      XLSX.writeFile(wb, fileName);
    } else {
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      XLSX.writeFile(wb, fileName);
    }
  };

  return (
    <button
      type="button"
      onClick={exportToExcel}
      className={`no-border bg-none radius-8 font-urbanist mr-1 ${additionalClass}`}
    >
      <AppIcon iconName="download" iconColor="#1B72E6" />
      <span className={`ml-2 font-color ${fontClass}`}>{title?.length > 0 ? title : ""}</span>
    </button>
  );
};

export default ExcelDownload;
