import React, { useState } from 'react';

const ImageComparisonSlider = ({
    baseline,
    implemented,
    onChange,
    onPointerMove,
    onPointerEnter,
    onPointerLeave,
    pointerMove = false,
    className = 'before-after-slider',
    beforeClassName = 'before',
    afterClassName = 'after',
    buttonClassName = 'resize-button',
    style,
    beforeStyle,
    afterStyle,
    buttonStyle
  }) => {

  const [rangeValue, setRangeValue] = useState(50);

  const handleChange = (event) => {
    const value = Number(event.target.value);
    setRangeValue(value);

    if (onChange) onChange(event);
  };

  const handlePointerMove = (event) => {
    const { clientX, currentTarget } = event;
    const { left, width } = currentTarget.getBoundingClientRect();
    const positionX = clientX - left;

    if (positionX >= 0) setRangeValue(Math.round((positionX / width) * 100));

    if (onPointerMove) onPointerMove(event);
  };

  const handlePointerEnter = (event) => {
    if (onPointerEnter) onPointerEnter(event);
  };

  const handlePointerLeave = (event) => {
    if (onPointerLeave) onPointerLeave(event);
  };

  return (
    <div
      className={className}
      style={{
        position: 'relative',
        overflow: 'hidden',
        width: 'fit-content',
        cursor: 'e-resize',
        userSelect: 'none',
        ...style
      }}
      onPointerMove={pointerMove ? handlePointerMove : undefined}
      onPointerEnter={handlePointerEnter}
      onPointerLeave={handlePointerLeave}
    >
      <div
        className={beforeClassName}
        style={{
          position: 'absolute',
          overflow: 'hidden',
          width: `${rangeValue}%`,
          height: '100%',
          top: 0,
          left: 0,
          borderRight: '3px solid #BAC7D8',
          ...beforeStyle
        }}
      >
        <img src={baseline} alt="before" style={{ height: '100%' }} />
      </div>

      <div className={afterClassName} style={afterStyle}>
        <img src={implemented} alt="after" style={{ maxWidth: '100%', display: 'block' }} />
      </div>

      {!pointerMove && (
        <>
          <input
            type="range"
            min={0}
            max={100}
            value={rangeValue}
            name="slider"
            onChange={handleChange}
            style={{
              appearance: 'none',
              backgroundColor: 'transparent',
              width: '100%',
              height: ' 100%',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              cursor: 'inherit'
            }}
          />
          <div
            className={buttonClassName}
            style={{
              backgroundColor: '#fff',
              pointerEvents: 'none',
              position: 'absolute',
              top: '50%',
              left: `${rangeValue}%`,
              transform: `translate(-50%,-50%)`,
              borderRadius: '50%',
              border: '2px solid #BAC7D8',
              width: 30,
              height: 30,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              ...buttonStyle
            }}
          >
          </div>
        </>
      )}
    </div>
  );
}

export default ImageComparisonSlider;