import { useEffect, useState } from 'react'
import Select from 'react-select'
import storeService from '../../services/store.service'
import pagesService from '../../services/pages.service'
import collectionsService from '../../services/collections.service'
import { Tooltip } from 'react-tooltip';
const apiTypeOptions = [
    {
        label: 'Select',
        value: ''
    },
    {
        label: 'Single',
        value: 'SINGLE'
    },
    {
        label: 'Chained',
        value: 'CHAINED'
    }
]
export default (props) => {
    const { mode } = props;
    const [loading, setloading] = useState(false)
    const [deviceOptions, setdeviceOptions] = useState(storeService.store.WORKFLOW_DEVICES.items.map(x => ({
        label: x.name,
        value: x._id
    })))
    const [availableTags, setavailableTags] = useState([])
    const [filterData, setfilterData] = useState({
        selectedDevices: [],
        selectedTags: [],
        byScheduled: null,
        selectedType: null,
        jobStatus: []
    })
    const closeModal = (e) => {
        e.preventDefault()
        props.onClose && props.onClose();
    }
    const handleformSubmit = (e) => {
        e.preventDefault();
        props.handleUpdateFilterData && props.handleUpdateFilterData(filterData)
    }
    const clearAllFilters = (e) => {
        e.preventDefault();
        const updatedFilterData = {
            selectedDevices: [],
            selectedTags: [],
            byScheduled: null,
            selectedType: null,
            jobStatus: []
        };
        setfilterData({ ...updatedFilterData })
        props.handleUpdateFilterData && props.handleUpdateFilterData(updatedFilterData)
    }
    const handleDeviceChange = (vals) => {
        filterData.selectedDevices = vals;
        setfilterData({ ...filterData })
    }
    const handleApiTypeChange = (val) => {
        filterData.selectedType = (val && val.value == '') ? null : val;
        setfilterData({ ...filterData })
    }
    const handleTagsChange = (vals) => {
        filterData.selectedTags = vals;
        setfilterData({ ...filterData })
    }
    const handleToggleBySchedule = (val) => {
        filterData.byScheduled = val;
        setfilterData({ ...filterData })
    }
    const handleJobStatusChange = (val, isChecked) => {
        if (filterData.jobStatus.find(x => x == val)) {
            filterData.jobStatus = filterData.jobStatus.filter(x => x != val);
        } else {
            filterData.jobStatus.push(val)
        }
        setfilterData({ ...filterData })
    }
    const loadMetaData = () => {
        pagesService.loadmetaData().then(apiResp => {
            const { data = {} } = apiResp;
            if (data) {
                const { tags = [] } = data;
                setavailableTags(tags.map(item => {
                    return {
                        label: item,
                        value: item
                    }
                }))
            }
        })
    }
    const loadApiGlideMetaData = () => {
        collectionsService.loadmetaData().then(apiResp => {
            const { data = {} } = apiResp;
            if (data) {
                const { tags = [] } = data;
                setavailableTags(tags.map(item => {
                    return {
                        label: item,
                        value: item
                    }
                }))
            }
        })
    }
    useEffect(() => {
        const { searchText, selectedDevices, selectedTags, byScheduled, selectedType, jobStatus } = props.selectedFilterData;
        setfilterData({
            selectedDevices,
            selectedTags,
            byScheduled,
            selectedType,
            jobStatus
        })
    }, [props.selectedFilterData])
    useEffect(() => {
        if (props.mode == 'PAGE_LIST') {
            loadMetaData();
        }
        if (mode == 'COLLECTION_LIST') {
            loadApiGlideMetaData();
        }
        if (props.mode == 'WORKFLOW_LIST') {
            setavailableTags(storeService.store.WORKFLOWS_WITH_DETAILS.allowedTags.map(item => {
                return {
                    label: item,
                    value: item
                }
            }))
        }
    }, [])
    return (
        <>
            <div className="justify-between align-center px-4 py-3 filter-popup-content">
                <div className="align-center">
                    <h2 className="h2-heading">Filter</h2>
                </div>
                <a onClick={closeModal} className="" href="">
                    <svg xmlns="http://www.w3.org/2000/svg" className="close-svg-icon" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path d="M21.5365 19.3139C21.8316 19.609 21.9974 20.0092 21.9974 20.4265C21.9974 20.8438 21.8316 21.2441 21.5365 21.5391C21.2414 21.8342 20.8411 22 20.4238 22C20.0065 22 19.6062 21.8342 19.3111 21.5391L11 13.2259L2.68626 21.5365C2.39116 21.8316 1.99092 21.9974 1.57358 21.9974C1.15624 21.9974 0.755993 21.8316 0.46089 21.5365C0.165787 21.2414 6.21883e-09 20.8412 0 20.4239C-6.21883e-09 20.0066 0.165787 19.6064 0.46089 19.3113L8.77463 11.0007L0.463508 2.68741C0.168405 2.39233 0.00261814 1.99211 0.00261815 1.57479C0.00261815 1.15748 0.168405 0.757257 0.463508 0.462172C0.758611 0.167086 1.15886 0.00130875 1.5762 0.00130875C1.99353 0.00130874 2.39378 0.167086 2.68888 0.462172L11 8.77541L19.3137 0.460862C19.6088 0.165777 20.0091 -6.95245e-09 20.4264 0C20.8438 6.95245e-09 21.244 0.165777 21.5391 0.460862C21.8342 0.755948 22 1.15617 22 1.57348C22 1.9908 21.8342 2.39102 21.5391 2.6861L13.2254 11.0007L21.5365 19.3139Z" fill="#1B72E6" />
                    </svg>
                </a>
            </div>
            <div className="filter-form-content">
                <form className="app-form" onSubmit={handleformSubmit}>
                    <div className="app-form__fields">
                        {
                            mode == 'COLLECTION_LIST' ? (
                                <div className="form-content">
                                     <div id="pick-type" className="display-block">
                                        <label className="label mb-2 pointer">Pick API Type 
                                        <img src="/icons/info-svg.svg" className="svg-icon ml-2 mt-2" />
                                        <Tooltip
                                        anchorSelect="#pick-type"
                                        content="Filter results by API Type."
                                        place="right"
                                        style={{
                                            backgroundColor: "#000",
                                            color: "#ffffff",
                                            borderRadius: "5px",
                                            zIndex: 22,
                                            fontWeight: "bold", 
                                            width: "auto",
                                        }}
                                        />
                                        </label>
                                    </div>  
                                    <div className="form-field-select">     
                                        <Select onChange={handleApiTypeChange} value={filterData.selectedType} options={apiTypeOptions} />
                                    </div>
                                </div>
                            ) : (
                                <div className="form-content" >
                                    <div id="pick-devices" className="display-block">
                                        <label className="label mb-2 pointer">Pick Devices 
                                        <img src="/icons/info-svg.svg" className="svg-icon ml-2 mt-2" />
                                        <Tooltip
                                        anchorSelect="#pick-devices"
                                        content="Filter results by device."
                                        place="right"
                                        style={{
                                            backgroundColor: "#000",
                                            color: "#ffffff",
                                            borderRadius: "5px",
                                            zIndex: 22,
                                            fontWeight: "bold", 
                                            width: "auto",
                                        }}
                                        />
                                        </label>
                                    </div> 
                                    <div className="form-field-select">                                   
                                        <Select onChange={handleDeviceChange} value={filterData.selectedDevices} isMulti options={deviceOptions} />
                                    </div>
                                </div>
                            )
                        }

                        <div className="form-content">
                            <div id="tags-tooltip" className="display-block">
                                <label className="label mb-2 pointer">Tags
                                    <img src="/icons/info-svg.svg" className="svg-icon ml-2 mt-2" />
                                    <Tooltip
                                    anchorSelect="#tags-tooltip"
                                    content="Filter results by tags."
                                    place="right"
                                    style={{
                                        backgroundColor: "#000",
                                        color: "#ffffff",
                                        borderRadius: "5px",
                                        zIndex: 22,
                                        fontWeight: "bold",
                                        width: "auto",
                                    }}
                                    />
                                </label>
                            </div>
                            <div className="form-field-select">  
                                <Select onChange={handleTagsChange} value={filterData.selectedTags} isMulti options={availableTags} />
                            </div>
                        </div>
                        {
                            (mode == 'PAGE_LIST' || mode == 'WORKFLOW_LIST') && (
                                <div className="form-field-group-checkbox form-content-checkbox">
                                    <div className="form-field-group-checkbox__label mb-4 field-label-heading">
                                        <div id="status-tooltip" className="display-block">
                                            <label className="label mb-2 pointer">Status
                                                <img src="/icons/info-svg.svg" className="svg-icon ml-2 mt-2" />
                                                <Tooltip
                                                anchorSelect="#status-tooltip"
                                                content="Filter results by failed priority."
                                                place="right"
                                                style={{
                                                    backgroundColor: "#000",
                                                    color: "#ffffff",
                                                    borderRadius: "5px",
                                                    zIndex: 22,
                                                    fontWeight: "bold",
                                                    width: "auto",
                                                }}
                                                />
                                            </label>
                                        </div>                                        
                                    </div>
                                    <div className="form-field-group-checkbox__items checkbox-group">
                                        <div className="form-field-group-checkbox__item mr-4">
                                            <label className="form-field-checkbox">
                                                <input id="jobStatusFailed" type="checkbox" onChange={e => {
                                                    handleJobStatusChange(e.target.value, e.target.checked)
                                                }} value="FAILED"  {...(filterData?.jobStatus?.find(x => x == 'FAILED') ? { checked: true } : {})} />
                                                <span className="checkmark"></span>
                                            </label>
                                            <label className="ml-2" htmlFor='jobStatusFailed'>Failed</label>
                                        </div>
                                        <div className="form-field-group-checkbox__item mr-4">
                                            <label className="form-field-checkbox">
                                                <input type="checkbox" onChange={e => {
                                                    handleJobStatusChange(e.target.value, e.target.checked)
                                                }} value="P0" {...(filterData?.jobStatus?.find(x => x == 'P0') ? { checked: true } : {})} />
                                                <span className="checkmark" ></span>
                                            </label>
                                            <label className="ml-2">P0</label>
                                        </div>
                                        <div className="form-field-group-checkbox__item mr-4">
                                            <label className="form-field-checkbox">
                                                <input type="checkbox" onChange={e => {
                                                    handleJobStatusChange(e.target.value, e.target.checked)
                                                }} value="P1" {...(filterData?.jobStatus?.find(x => x == 'P1') ? { checked: true } : {})} />
                                                <span className="checkmark" ></span>
                                            </label>
                                            <label className="ml-2">P1</label>
                                        </div>
                                        <div className="form-field-group-checkbox__item mr-4">
                                            <label className="form-field-checkbox">
                                                <input type="checkbox" onChange={e => {
                                                    handleJobStatusChange(e.target.value, e.target.checked)
                                                }} value="P2" {...(filterData?.jobStatus?.find(x => x == 'P2') ? { checked: true } : {})} />
                                                <span className="checkmark" ></span>
                                            </label>
                                            <label className="ml-2">P2</label>
                                        </div>
                                        <div className="form-field-group-checkbox__item mr-4">
                                            <label className="form-field-checkbox ">
                                                <input type="checkbox" onChange={e => {
                                                    handleJobStatusChange(e.target.value, e.target.checked)
                                                }} value="P3"  {...(filterData?.jobStatus?.find(x => x == 'P3') ? { checked: true } : {})} />
                                                <span className="checkmark" ></span>
                                            </label>
                                            <label className="ml-2">P3</label>
                                        </div>
                                        <div className="form-field-group-checkbox__item mr-4">
                                            <label className="form-field-checkbox">
                                                <input type="checkbox" onChange={e => {
                                                    handleJobStatusChange(e.target.value, e.target.checked)
                                                }} value="P4"  {...(filterData?.jobStatus?.find(x => x == 'P4') ? { checked: true } : {})} />
                                                <span className="checkmark" ></span>
                                            </label>
                                            <label className="ml-2">P4</label>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        <div className="form-field-group-checkbox form-content-checkbox">
                            <div className="form-field-group-checkbox__label mb-4 field-label-heading">
                                <div id="jobs-tooltip" className="display-block">
                                    <label className="label mb-2 pointer"> Schedule Jobs
                                        <img src="/icons/info-svg.svg" className="svg-icon ml-2 mt-2" />
                                        <Tooltip
                                        anchorSelect="#jobs-tooltip"
                                        content="Filter results by schedule."
                                        place="right"
                                        style={{
                                            backgroundColor: "#000",
                                            color: "#ffffff",
                                            borderRadius: "5px",
                                            zIndex: 22,
                                            fontWeight: "bold",
                                            width: "auto",
                                        }}
                                        />
                                    </label>
                                </div>                               
                            </div>
                            <div className="form-field-group-checkbox__items radio-group">
                                <div className="form-field-group-checkbox__item width-auto">
                                    <label htmlFor="onlyScheduled" className="form-field-checkbox mr-2">
                                        <input type="radio" name="byScheduled" id="onlyScheduled" onChange={e => {
                                            handleToggleBySchedule('ONLY_SCHEDULE')
                                        }} {...(filterData.byScheduled == 'ONLY_SCHEDULE' ? { checked: true } : {})} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <label htmlFor="onlyScheduled">Only scheduled</label>
                                </div>
                                <div className="form-field-group-checkbox__item radio-group width-auto ml-3">
                                    <label htmlFor="onlyNotScheduled" className="form-field-checkbox mr-2">
                                        <input type="radio" name="byScheduled" id="onlyNotScheduled" onChange={e => {
                                            handleToggleBySchedule('ONLY_NON_SCHEDULE')
                                        }} {...(filterData.byScheduled == 'ONLY_NON_SCHEDULE' ? { checked: true } : {})} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <label htmlFor="onlyNotScheduled">Only not scheduled</label>
                                </div>
                                <div className="form-field-group-checkbox__item radio-group width-auto ml-3">
                                    <label htmlFor="bothall" className="form-field-checkbox mr-2">
                                        <input type="radio" name="byScheduled" id="bothall" onChange={e => {
                                            handleToggleBySchedule(null)
                                        }} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <label htmlFor="bothall">Both</label>
                                </div>
                            </div>
                        </div>




                    </div>
                    <div className="app-form__controls align-center">
                        <a href="" onClick={e => clearAllFilters(e)} className="btn-link-medium mr-8">Clear All</a>
                        <button className="btn-medium-2 btn-medium-width" type='submit' disabled={loading}>Apply Filter</button>
                    </div>
                </form>
            </div>
        </>
    )
}