import React, { useEffect, useState } from "react";
import CreatableSelect from 'react-select/creatable';
import storeService from "../../services/store.service.js";
import utilsService from "../../services/utils.service.js";
import { useParams } from "react-router-dom";
import { Tooltip } from 'react-tooltip';
import apiService from "../../services/api.service.js";
import ConfirmSaveModal from '../../../src/comps/molecules/ConfirmSaveModal.jsx';
import workflowsService from "../../services/workflows.service.js";
import XpathRecommendation from '../../comps/molecules/XpathRecommendation.jsx'
import SvgIcon from "../../comps/atoms/SvgIcon.jsx";
const createOption = (label) => ({
  label,
  value: label,
});
const checkAndReturn = (valToCheck, prefix) => {
  if (valToCheck) {
    return prefix + valToCheck;
  }
  return "";
};
const getStepName = (step) => {
  if (step.action === "OPEN_URL" || step.page_name) {
    return (
      "Open " +
      (step.page_name || step.url.substr(step.url.lastIndexOf("/") + 1))
    );
  }
  if (step.action === "INPUT") {
    return (
      "Input " +
      step.value +
      checkAndReturn(
        step.elementText || step.elementId || step.element,
        " in "
      )
    );
  }
  if (
    ["CHECKBOX", "RADIO", "SELECT", "MULTI_SELECT"].indexOf(step.action) >=
    0
  ) {
    return "Select " + step.value + " as " + step.element;
  }
  if (["ANCHOR_CLICK", "BUTTON_CLICK"].indexOf(step.action) >= 0) {
    return (
      "Click " +
      (step.elementLinkText ||
        step.elementText ||
        step.elementId ||
        step.element ||
        step.action)
    );
  }
  return step.pageName || step.action;
};
export default ({ editablePageData, job }) => {
  const params = useParams();
  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [btnDisabled, setbtnDisabled] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState([]);
  const [selectedTags, setSelectedTags] = useState([])
  const [allTags, setAllTags] = useState(storeService.store.WORKFLOWS_WITH_DETAILS.allowedTags.map(item => {
    return {
      label: item,
      value: item
    }
  }) || [])
  const [selectedStepData, setSelectedStepData] = useState({
    stepNumber: 0,
    stepName: editablePageData?.steps[0]?.stepName,
    stepJson: {},
    stepCustomPrompt: '',
    stepTimeout: null,
    stepSkipThis: false
  });
  const [flowName, setFlowName] = useState({
    value: editablePageData?.name,
    showError: false
  })
  const [errorMap, seterrorMap] = useState({})
  // const [steps, setSteps] = useState(() => editablePageData?.steps || []);
  const [steps, setSteps] = useState(editablePageData?.steps);
  useEffect(() => {
    if (editablePageData?.steps) {
      setSteps(editablePageData.steps);
    }
    if (editablePageData?.tags) {
      let selTags = editablePageData?.tags.split(',')
      if (selTags.length > 0) {
        setValue(selTags.map(x => createOption(x)))
        setSelectedTags(selTags)
      }
    }

    setAllTags(storeService.store.WORKFLOWS_WITH_DETAILS.allowedTags.map(item => {
      return {
        label: item,
        value: item
      }
    }))
  }, [editablePageData]);

  useEffect(() => {

    if (steps && steps[selectedStepData.stepNumber]) {
      const { stepName, ...stepJson } = steps[selectedStepData.stepNumber];
      setSelectedStepData((prevData) => ({
        ...prevData,
        stepJson: JSON.stringify(stepJson, null, 2),
        stepName: steps[prevData.stepNumber].stepName,
        stepCustomPrompt: steps[prevData.stepNumber].customprompt,
        stepTimeout: steps[prevData.stepNumber].stepTimeout,
        stepSkipThis: steps[prevData.stepNumber].stepSkipThis
      }));
    }
  }, [selectedStepData.stepNumber, steps]);

  const closeEditModal = (e) => {
    e && e.preventDefault();
    storeService.pub(
      utilsService.CONSTANTS.EVENT_NAME.OPEN_ADD_PAGE_MODAL,
      false
    );
  };
  const handleSelect = (selectedStep) => {
    setSelectedStepData({ ...selectedStepData, stepNumber: selectedStep });
  };
  const onConfirmation = async (e) => {
    setShowSaveModal(false);
    setbtnDisabled(true);
    if (flowName.value == '') {
      setFlowName({
        value: '',
        showError: true
      })
      return;
    }
    if (steps[selectedStepData.stepNumber]?.stepName && !selectedStepData.stepName.length) {
      setShowError(true);
      return;
    }
    const updatedStep = {
      ...JSON.parse(selectedStepData.stepJson),
      stepName: selectedStepData.stepName,
      customprompt: selectedStepData?.stepCustomPrompt,
      stepTimeout: selectedStepData?.stepTimeout,
      stepSkipThis: selectedStepData?.stepSkipThis
    };
    setShowLoader(true);
    const resp = await apiService.post({
      url: utilsService.END_POINTS.updateuserflowjobbyid,
      data: {
        userflowId: params.pageId,
        stepNumber: selectedStepData?.stepNumber,
        updatedStep: updatedStep,
        stepName: selectedStepData?.stepName,
        name: flowName.value,
        tags: selectedTags.join(',')
      },
    });
    if (resp) {
      closeEditModal();
    }
  }
  const handleSaveChanges = (e) => {
    e.preventDefault();
    let validCount = 0;
    let errorMapUpdated = {}
    if (!(flowName.value && flowName.value.length > 3)) {
      validCount += 1;
      errorMapUpdated['flowName'] = true;
    }
    if (!(selectedStepData.stepName && selectedStepData.stepName.length > 3)) {
      validCount += 1;
      errorMapUpdated['stepName'] = true;
    }
    try {
      let stepData = JSON.parse(selectedStepData.stepJson)
      if (stepData['action']) {
        const { action, url, page_name, element, elementId, xPath, value } = stepData;
        if (action == 'OPEN_URL') {
          if (!(url.length > 3)) {
            validCount += 1;
            errorMapUpdated['stepJson'] = true;
          }
        } else {
          if (!((element && element.length > 3) || (elementId && elementId.length > 0) || (xPath && xPath.length > 3))) {
            validCount += 1;
            errorMapUpdated['stepJson'] = true;
          }
        }
      } else {
        validCount += 1;
        errorMapUpdated['stepJson'] = true;
      }
    } catch (ex) {
      validCount += 1;
      errorMapUpdated['stepJson'] = true;
    }
    if (validCount == 0) {
      setShowSaveModal(true);
    } else {
      seterrorMap({ ...errorMapUpdated })
    }
  }
  const handleSelectSuggestionStep = (stepDataToUse) => {
    let stepMappedData = {};
    if (stepDataToUse['action'] == 'OPEN_URL' || stepDataToUse['actionType'] == 'OPEN_URL') {
      stepMappedData = {
        "action": "OPEN_URL",
        "url": stepDataToUse['pageURL'],
        "page_name": stepDataToUse['page_title']
      }
    } else {
      stepMappedData = {
        "action": stepDataToUse['action'] || stepDataToUse['actionType'],
        "element": stepDataToUse['elementName'],
        "elementId": stepDataToUse['elementId'],
        "xPath": stepDataToUse['elementXPath'],
        "value": stepDataToUse['elementValue'],
        "elementText": stepDataToUse['elementLinkText']
      }
    }
    seterrorMap({})
    setSelectedStepData({
      ...selectedStepData,
      stepName: getStepName(stepMappedData),
      stepJson: JSON.stringify(stepMappedData, null, 2)
    })
  }
  return (
    <div className="app-modal">
      <div className="app-modal__content">
        <div className="add-page-comp">
          <div className="add-page-comp__head justify-between align-center px-4 py-3 filter-popup-content">
            <div className="flex align-center">
              <div className="align-center">
                <h2 className="h2-heading">Edit Steps</h2>
              </div>
            </div>

            <a onClick={closeEditModal} href="/index.html">
              <svg className="close-svg-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M21.5365 19.3139C21.8316 19.609 21.9974 20.0092 21.9974 20.4265C21.9974 20.8438 21.8316 21.2441 21.5365 21.5391C21.2414 21.8342 20.8411 22 20.4238 22C20.0065 22 19.6062 21.8342 19.3111 21.5391L11 13.2259L2.68626 21.5365C2.39116 21.8316 1.99092 21.9974 1.57358 21.9974C1.15624 21.9974 0.755993 21.8316 0.46089 21.5365C0.165787 21.2414 6.21883e-09 20.8412 0 20.4239C-6.21883e-09 20.0066 0.165787 19.6064 0.46089 19.3113L8.77463 11.0007L0.463508 2.68741C0.168405 2.39233 0.00261814 1.99211 0.00261815 1.57479C0.00261815 1.15748 0.168405 0.757257 0.463508 0.462172C0.758611 0.167086 1.15886 0.00130875 1.5762 0.00130875C1.99353 0.00130874 2.39378 0.167086 2.68888 0.462172L11 8.77541L19.3137 0.460862C19.6088 0.165777 20.0091 -6.95245e-09 20.4264 0C20.8438 6.95245e-09 21.244 0.165777 21.5391 0.460862C21.8342 0.755948 22 1.15617 22 1.57348C22 1.9908 21.8342 2.39102 21.5391 2.6861L13.2254 11.0007L21.5365 19.3139Z"
                  fill="#1B72E6"
                />
              </svg>
            </a>
          </div>
          <div className="mt-2 filter-form-content">
            <form className="app-form">
              <div className="app-form__fields px-4 pb-4 form-content">

                <div className="form-field mb-4">
                  <label className="label pb-2">
                    Flow name
                  </label>
                  <input
                    type="text"
                    className="input-txt input-field w-full p-2 input-txt"
                    value={flowName.value}
                    onChange={e => {
                      setFlowName({
                        value: e.target.value || '',
                        showError: false
                      })
                    }}
                  />
                  {
                    errorMap['flowName'] && (
                      <p className="error-message">This field is required.</p>
                    )
                  }
                </div>

                <div className="form-field-select mb-2">
                  <label className="label mb-2">Create or Select a Tag</label>
                  <div className="align-center mb-2">
                    <div className="form-field">
                      <CreatableSelect
                        inputValue={inputValue}
                        isClearable
                        isMulti
                        onChange={(tagValues) => {
                          let uList = tagValues.map((item) => item.value);
                          setSelectedTags(uList)
                          setValue(tagValues);
                        }}
                        onBlur={(e) => {
                          const currentTargetVal = e.currentTarget.value;
                          if (currentTargetVal) {
                            let tagText = currentTargetVal;
                            tagText = tagText.replace(/[^a-zA-Z0-9 ]/g, '')
                            setValue((prev) => [...prev, createOption(tagText)]);
                            setInputValue('');
                            let uList = [...selectedTags, tagText];
                            setSelectedTags(uList)
                          }
                        }}
                        onInputChange={(newValue) => {
                          setInputValue(newValue.replace(/[^a-zA-Z0-9 ]/g, ''))
                        }}
                        onKeyDown={event => {
                          if (!inputValue) return;
                          switch (event.key) {
                            case 'Enter':
                            case 'Tab':
                              let tagText = inputValue;
                              tagText = tagText.replace(/[^a-zA-Z0-9 ]/g, '')
                              setValue((prev) => [...prev, createOption(tagText)]);
                              setInputValue('');
                              let uList = [...selectedTags, tagText];
                              setSelectedTags(uList)
                              event.preventDefault();
                          }
                        }}
                        placeholder="Create or Select a Tag and press enter..."
                        value={value}
                        defaultInputValue={[]}
                        options={allTags}
                      />
                    </div>
                  </div>
                </div>

                <label className="label pb-2">Step Number</label>
                <div className="form-field">
                  <select className="select-text"
                    value={selectedStepData.stepNumber}
                    onChange={(e) => handleSelect(e.target.value)}
                  >
                    {steps?.map((x, index) => {
                      return (
                        <option key={"dropdown-item"} value={index}>
                          Step {index + 1}
                        </option>
                      );
                    })}
                  </select>
                  <svg
                    className="post-svg-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="11"
                    viewBox="0 0 18 11"
                    fill="none"
                  >
                    <path
                      d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z"
                      fill="#1B72E6"
                    />
                  </svg>
                </div>
                {steps[selectedStepData.stepNumber]?.stepName && (
                  <div className="form-field py-6">
                    <label className="pb-2 white-space-no-wrap mr-2 bold w-full d-block mb-2 label">
                      Step Name
                    </label>
                    <input
                      type="text"
                      className="input-txt input-field w-full p-2 input-txt"
                      value={selectedStepData.stepName}
                      onChange={(e) =>
                        setSelectedStepData((prevData) => ({
                          ...prevData,
                          stepName: e.target.value,
                        }))
                      }
                    />
                    {
                      errorMap['stepName'] && (
                        <p className="error-message">This field is required.</p>
                      )
                    }
                  </div>
                )}
                <div className="form-field">
                  <div className="flex justify-between">
                    <label className=" py-2 white-space-no-wrap mr-2 bold w-full d-block mb-2 label">
                      Step Definition(JSON)
                    </label>
                    <XpathRecommendation handleSelectSuggestionStep={handleSelectSuggestionStep} workflow={editablePageData} job={job} stepNo={(selectedStepData.stepNumber - 0) + 1} />
                  </div>
                  <textarea
                    rows="8"
                    onChange={(e) =>
                      setSelectedStepData({
                        ...selectedStepData,
                        stepJson: e.target.value,
                      })
                    }
                    className={`text-area w-full p-2 ` + (errorMap['stepJson'] ? 'error' : '')}
                    id="description"
                    name="description"
                    value={selectedStepData.stepJson}
                    required
                  ></textarea>
                  <p class={`field-note mt-1 ` + (errorMap['stepJson'] ? 'font-danger' : '')}>Double check the json value, check all fields properly</p>
                </div>
                <div className="py-2 flex mt-2">
                  <label htmlFor="skipstep" className="form-field-checkbox">
                    <input id="skipstep" type="checkbox" checked={selectedStepData.stepSkipThis} onChange={e => {
                      setSelectedStepData({
                        ...selectedStepData,
                        stepSkipThis: e.target.checked,
                      })
                    }} />
                    <span className="checkmark"></span>
                  </label>
                  <label htmlFor="skipstep" className="ml-2">
                    Skip this step
                  </label>
                </div>
                <div className="form-field mt-2">
                  <div className="flex">
                    <label className="white-space-no-wrap mr-2 bold mb-2 label flex align-center" id="timed-catpture-id">
                      <span className="mr-1">Timed Screenshot Capture (in sec)</span>
                      <SvgIcon path="/icons/info-svg.svg"></SvgIcon>
                      <Tooltip
                        anchorSelect={'#timed-catpture-id'}
                        content={'Delays the screenshot by the configured timeout duration.'}
                        place="left-start"
                        style={{
                          backgroundColor: "#000",
                          color: "#fff",
                          borderRadius: "5px",
                          zIndex: 22,
                          fontWeight: "bold",
                        }}
                      />
                    </label>
                  </div>
                  <input
                    type="text"
                    value={selectedStepData.stepTimeout}
                    className="input-field w-full p-2 input-txt"
                    onChange={(event) => {
                      let val = (event.target.value || '').replace(new RegExp('[^0-9]', 'g'), '')
                      setSelectedStepData((prevData) => ({
                        ...prevData,
                        stepTimeout: val,
                      }))
                    }}
                  />
                </div>
                <div className='form-field mt-2'>
                  <label className="label mb-2">Additional question/insight to verify in this page</label>
                  <textarea rows="3" className='w-full text-area'
                    value={selectedStepData.stepCustomPrompt}
                    onChange={e => {
                      setSelectedStepData((prevData) => ({
                        ...prevData,
                        stepCustomPrompt: e.target.value,
                      }))
                    }}
                  >
                  </textarea>
                  <p class="field-note mt-1">Example: Verify if &lt;enter specifics&gt; is present..</p>
                </div>
              </div>
              <div className="app-form__controls mt-4 px-6 py-4 flex-end">
                <button onClick={e => handleSaveChanges(e)} disabled={btnDisabled}
                  className="ml-4 btn-medium-2 flex justify-center align-center btn-medium-width"
                  type="button"
                >
                  Save Changes{" "}
                  <span
                    className={`${showLoader ? "loading--icon flex" : ""}`}
                  ></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {
        showSaveModal && (
          <ConfirmSaveModal
            confirmationTitle={'Are you sure you want to save the changes?'}
            confirmButtonLabel={'Save'}
            onOk={() => onConfirmation()}
            onCancel={e => setShowSaveModal(false)}
          />
        )
      }
    </div>
  );
};
