import React, { useEffect, useState } from "react";
import CreatableSelect from 'react-select/creatable';
import storeService from "../../services/store.service.js";
import utilsService from "../../services/utils.service.js";
import { useParams } from "react-router-dom";
import { Tooltip } from 'react-tooltip';
import apiService from "../../services/api.service.js";
import ConfirmSaveModal from '../../../src/comps/molecules/ConfirmSaveModal.jsx';
import ConfirmModal from '../../../src/comps/molecules/ConfirmModal.jsx';
import workflowsService from "../../services/workflows.service.js";
import XpathRecommendation from '../../comps/molecules/XpathRecommendation.jsx'
import SvgIcon from "../../comps/atoms/SvgIcon.jsx";
import AppIcon from "../../comps/atoms/AppIcon/AppIcon.jsx";
import ToolTipV2 from "../../comps/atoms/ToolTipV2.jsx";
const createOption = (label) => ({
  label,
  value: label,
});
const checkAndReturn = (valToCheck, prefix) => {
  if (valToCheck) {
    return prefix + valToCheck;
  }
  return "";
};
const getStepName = (step) => {
  if (step.action === "OPEN_URL" || step.page_name) {
    return (
      "Open " +
      (step.page_name || step.url.substr(step.url.lastIndexOf("/") + 1))
    );
  }
  if (step.action === "INPUT") {
    return (
      "Input " +
      step.value +
      checkAndReturn(
        step.elementText || step.elementId || step.element,
        " in "
      )
    );
  }
  if (
    ["CHECKBOX", "RADIO", "SELECT", "MULTI_SELECT"].indexOf(step.action) >=
    0
  ) {
    return "Select " + step.value + " as " + step.element;
  }
  if (["ANCHOR_CLICK", "BUTTON_CLICK"].indexOf(step.action) >= 0) {
    return (
      "Click " +
      (step.elementLinkText ||
        step.elementText ||
        step.elementId ||
        step.element ||
        step.action)
    );
  }
  return step.pageName || step.action;
};
const stepNameMap = {
  stepName: 'Step name',
  stepJson: 'Step Definition',
  customprompt: 'Additional question',
  stepTimeout: 'Timed screenshot',
  stepSkipThis: 'Skip step flag',
  enableMouseClick: 'Mouse click flag',
  enforceStepTimeout: 'Enforce step timeout',
  skipScreenshot: 'Skip screenshot for step'
}
const defaultcopyText = 'Copy all steps to clipboard';
export default ({ editablePageData, job, suggestedStepName }) => {
  const params = useParams();
  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [btnDisabled, setbtnDisabled] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState([]);
  const [selectedTags, setSelectedTags] = useState([])
  const [nextSelectedStep, setnextSelectedStep] = useState(null)
  const [modifiedFields, setmodifiedFields] = useState([])
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isExpand, setIsExpand] = useState(true)
  const [allTags, setAllTags] = useState(storeService.store.WORKFLOWS_WITH_DETAILS.allowedTags.map(item => {
    return {
      label: item,
      value: item
    }
  }) || [])
  const [copyText, setCopyText] = useState(defaultcopyText)
  const [stepList, setStepList] = useState([]);
  const [expandList, setExpandList] = useState([]);
  const [errorStepList, seterrorStepList] = useState([]);
  const [selectedStepData, setSelectedStepData] = useState({
    stepNumber: 0,
    stepName: editablePageData?.steps[0]?.stepName,
    stepJson: {},
    customprompt: '',
    stepTimeout: '',
    stepSkipThis: false,
    skipScreenshot: false
  });
  const [flowName, setFlowName] = useState({
    value: editablePageData?.name,
    showError: false
  })
  const [errorMap, seterrorMap] = useState({})
  // const [steps, setSteps] = useState(() => editablePageData?.steps || []);
  const [steps, setSteps] = useState(editablePageData?.steps);
  const [deleteItemIndex, setdeleteItemIndex] = useState(-1)
  useEffect(() => {
    if (editablePageData?.steps) {
      setSteps(editablePageData.steps);
    }
    if (editablePageData?.tags) {
      let selTags = editablePageData?.tags.split(',')
      if (selTags.length > 0) {
        setValue(selTags.map(x => createOption(x)))
        setSelectedTags(selTags)
      }
    }

    setAllTags(storeService.store.WORKFLOWS_WITH_DETAILS.allowedTags.map(item => {
      return {
        label: item,
        value: item
      }
    }))
    if (editablePageData?.steps) {
      let stepsToUpdate = [], expandListData = [];
      editablePageData.steps.forEach((step, index) => {
        const { _id, stepName, stepTimeout, stepSkipThis, enableMouseClick, customprompt, enforceStepTimeout, skipScreenshot, ...stepJson } = step;
        stepsToUpdate.push({
          stepName,
          stepTimeout,
          stepSkipThis,
          enableMouseClick,
          customprompt,
          enforceStepTimeout,
          skipScreenshot,
          stepJson: JSON.stringify(stepJson, null, 2),
          _id
        });
        expandListData.push(stepName == suggestedStepName);
      });
      setStepList(stepsToUpdate);
      setExpandList(expandListData);
    }
  }, [editablePageData]);

  useEffect(() => {
    if (steps && steps[selectedStepData.stepNumber]) {
      const { _id, stepName, stepTimeout, stepSkipThis, enableMouseClick, customprompt, enforceStepTimeout, skipScreenshot, ...stepJson } = steps[selectedStepData.stepNumber];
      setSelectedStepData((prevData) => ({
        ...prevData,
        stepJson: JSON.stringify(stepJson, null, 2),
        stepName: stepName,
        customprompt: customprompt || "",
        stepTimeout: stepTimeout || "",
        stepSkipThis: stepSkipThis,
        enableMouseClick: enableMouseClick,
        enforceStepTimeout: enforceStepTimeout,
        skipScreenshot: skipScreenshot,
        _id
      }));
    }
  }, [selectedStepData.stepNumber, steps]);

  const handleCopyAllSteps = (e) => {
    e.preventDefault();
    try {
      let codeToCopy = JSON.stringify(editablePageData?.steps)
      if (navigator.clipboard) {
        navigator.clipboard.writeText(codeToCopy)
          .then(() => {
            setCopyText("Copied!");
            setTimeout(() => setCopyText(defaultcopyText), 2000);
          })
          .catch((err) => {
            console.error("Clipboard API failed:", err);
            fallbackCopyTextToClipboard(codeToCopy);
          });
      } else {
        console.warn("Clipboard API not available, using fallback.");
        fallbackCopyTextToClipboard(codeToCopy);
      }
    } catch (ex) {

    }
  }
  const fallbackCopyTextToClipboard = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand("copy");
      setCopyText("Copied!");
    } catch (err) {
      setCopyText("Failed to copy");
    }
    document.body.removeChild(textarea);
    setTimeout(() => setCopyText(defaultcopyText), 2000);
  };
  const closeEditModal = (e) => {
    e && e.preventDefault();
    storeService.pub(
      utilsService.CONSTANTS.EVENT_NAME.OPEN_ADD_PAGE_MODAL,
      false
    );
  };
  const compareWithOriginal = () => {
    let updatedData = selectedStepData;
    const { stepName, stepTimeout, stepSkipThis, enableMouseClick, customprompt, enforceStepTimeout, skipScreenshot, ...stepJson } = steps[selectedStepData.stepNumber];
    const originalData = { stepName, stepTimeout, stepSkipThis, enableMouseClick, customprompt, enforceStepTimeout, skipScreenshot, stepJson };
    let isUpdated = false, updatedFields = [];
    ['stepName', 'customprompt', 'stepTimeout', 'stepSkipThis', 'enableMouseClick', 'stepJson', 'enforceStepTimeout', 'skipScreenshot'].forEach(fieldName => {
      let val1 = updatedData[fieldName];
      let val2 = originalData[fieldName];
      if (fieldName == 'stepJson') {
        try {
          val2 = JSON.stringify(val2, null, 2);
        } catch (ex) {

        }
      }
      if (val1 != val2) {
        if (!((val1 == undefined || val1 == '') && (val2 == '' || val2 == null))) {
          updatedFields.push(fieldName);
          isUpdated = true;
        }
      }
    });
    return { isUpdated, updatedFields };
  }
  const onConfirmation = async (e) => {
    setShowSaveModal(false);
    setbtnDisabled(true);
    if (flowName.value == '') {
      setFlowName({
        value: '',
        showError: true
      })
      return;
    }
    if (steps[selectedStepData.stepNumber]?.stepName && !selectedStepData.stepName.length) {
      setShowError(true);
      return;
    }
    const updatedStep = {
      ...JSON.parse(selectedStepData.stepJson),
      stepName: selectedStepData.stepName,
      customprompt: selectedStepData?.customprompt,
      stepTimeout: selectedStepData?.stepTimeout,
      stepSkipThis: selectedStepData?.stepSkipThis,
      enableMouseClick: selectedStepData?.enableMouseClick,
      enforceStepTimeout: selectedStepData?.enforceStepTimeout,
      skipScreenshot: selectedStepData?.skipScreenshot,
      _id: selectedStepData?._id
    };
    setShowLoader(true);
    const resp = await apiService.post({
      url: utilsService.END_POINTS.updateuserflowjobbyid,
      data: {
        userflowId: params.pageId,
        stepNumber: selectedStepData?.stepNumber,
        updatedStep: updatedStep,
        stepName: selectedStepData?.stepName,
        name: flowName.value,
        tags: selectedTags.join(',')
      },
    });
    if (resp) {
      closeEditModal();
    }
  }
  const handleSaveChanges = (e) => {
    e.preventDefault();
    if (!(editablePageData?.steps?.length > 0)) {
      return;
    }
    let validCount = 0;
    let errorStepListUpdated = [];
    let errorMapUpdated = {}
    if (!(flowName.value && flowName.value.length > 3)) {
      validCount += 1;
      errorMapUpdated['flowName'] = true;
    }
    let currentStepIndex = -1;
    try {
      stepList.forEach((step, index) => {
        currentStepIndex = index;
        let stepData = JSON.parse(step.stepJson);
        if (!(step.stepName && step.stepName.length > 3)) {
          validCount += 1;
          errorStepListUpdated[index] = {
            stepName: true,
            stepJson: false
          }
        }
        if (stepData['action']) {
          const { action, url, page_name, element, elementId, xPath, value } = stepData;
          if (action == 'OPEN_URL') {
            if (!(url.length > 3)) {
              validCount += 1;
              errorStepListUpdated[index] = {
                stepName: false,
                stepJson: true
              }
            }
          } else {
            // if (!((element && element.length > 3) || (elementId && elementId.length > 0) || (xPath && xPath.length > 3))) {
            //   validCount += 1;
            //   errorStepListUpdated[index] = {
            //     stepName: false,
            //     stepJson: true
            //   }
            // }
          }
        } else {
          validCount += 1;
          errorStepListUpdated[index] = {
            stepName: false,
            stepJson: true
          }
        }
      });
    } catch (ex) {
      validCount += 1;
      errorStepListUpdated[currentStepIndex] = {
        ...errorStepListUpdated[currentStepIndex],
        stepJson: true
      }
    }
    if (validCount > 0) {
      seterrorStepList([...errorStepListUpdated]);
      seterrorMap({ ...errorMapUpdated })
      return;
    }
    let stepsToUpdate = [];
    stepList.forEach(updatedStep => {
      updatedStep = {
        ...(JSON.parse(updatedStep.stepJson)),
        stepName: updatedStep.stepName,
        customprompt: updatedStep?.customprompt,
        stepTimeout: updatedStep?.stepTimeout,
        stepSkipThis: updatedStep?.stepSkipThis,
        enableMouseClick: updatedStep?.enableMouseClick,
        enforceStepTimeout: updatedStep?.enforceStepTimeout,
        skipScreenshot: updatedStep?.skipScreenshot,
        _id: updatedStep?._id
      }
      stepsToUpdate.push(updatedStep);
    })
    setShowLoader(true);
    apiService.post({
      url: utilsService.END_POINTS.updateuserflowjobbyid,
      data: {
        userflowId: params.pageId,
        name: flowName.value,
        tags: selectedTags.join(','),
        steps: stepsToUpdate
      },
    }).then((resp) => {
      if (resp) {
        closeEditModal();
      }
    }).catch((err) => {
      closeEditModal();
    });
  }
  const handleCloneClick = (index) => {
    const stepsToUpdate = [...stepList];
    let newStepItem = { ...(stepsToUpdate[index]) };
    newStepItem._id = utilsService.guid(4);
    stepsToUpdate.splice(index, 0, newStepItem)
    stepsToUpdate[index + 1].stepName = stepsToUpdate[index + 1].stepName + ' Copy';
    setStepList(stepsToUpdate)
  }
  const handleDeleteClick = (index) => {
    setdeleteItemIndex(index)
    setShowDeleteModal(true);
  }
  const handleconfirmDeleteClick = () => {
    const stepsToUpdate = [...stepList];
    stepsToUpdate.splice(deleteItemIndex, 1)
    setStepList(stepsToUpdate)
    setShowDeleteModal(false);
  }
  return (
    <div className="app-modal">
      <div className="app-modal__content">
        <div className="add-page-comp">
          <div className="add-page-comp__head justify-between align-center px-4 py-3 filter-popup-content">
            <div className="flex align-center">
              <div className="align-center">
                <h2 className="h2-heading">Edit Steps</h2>
              </div>
            </div>

            <a onClick={closeEditModal} href="/index.html">
              <svg className="close-svg-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M21.5365 19.3139C21.8316 19.609 21.9974 20.0092 21.9974 20.4265C21.9974 20.8438 21.8316 21.2441 21.5365 21.5391C21.2414 21.8342 20.8411 22 20.4238 22C20.0065 22 19.6062 21.8342 19.3111 21.5391L11 13.2259L2.68626 21.5365C2.39116 21.8316 1.99092 21.9974 1.57358 21.9974C1.15624 21.9974 0.755993 21.8316 0.46089 21.5365C0.165787 21.2414 6.21883e-09 20.8412 0 20.4239C-6.21883e-09 20.0066 0.165787 19.6064 0.46089 19.3113L8.77463 11.0007L0.463508 2.68741C0.168405 2.39233 0.00261814 1.99211 0.00261815 1.57479C0.00261815 1.15748 0.168405 0.757257 0.463508 0.462172C0.758611 0.167086 1.15886 0.00130875 1.5762 0.00130875C1.99353 0.00130874 2.39378 0.167086 2.68888 0.462172L11 8.77541L19.3137 0.460862C19.6088 0.165777 20.0091 -6.95245e-09 20.4264 0C20.8438 6.95245e-09 21.244 0.165777 21.5391 0.460862C21.8342 0.755948 22 1.15617 22 1.57348C22 1.9908 21.8342 2.39102 21.5391 2.6861L13.2254 11.0007L21.5365 19.3139Z"
                  fill="#1B72E6"
                />
              </svg>
            </a>
          </div>
          <div className="mt-2 filter-form-content">
            <form className="app-form">
              <div className="app-form__fields px-4 pb-4 form-content">

                <div className="form-field mb-4">
                  <label className="label pb-2">
                    Flow name
                  </label>
                  <input
                    type="text"
                    className="input-txt input-field w-full p-2 input-txt"
                    value={flowName.value}
                    onChange={e => {
                      setFlowName({
                        value: e.target.value || '',
                        showError: false
                      })
                    }}
                  />
                  {
                    errorMap['flowName'] && (
                      <p className="error-message">This field is required.</p>
                    )
                  }
                </div>

                <div className="form-field-select mb-2">
                  <label className="label mb-2">Create or Select a Tag</label>
                  <div className="align-center mb-2">
                    <div className="form-field">
                      <CreatableSelect
                        inputValue={inputValue}
                        isClearable
                        isMulti
                        onChange={(tagValues) => {
                          let uList = tagValues.map((item) => item.value);
                          setSelectedTags(uList)
                          setValue(tagValues);
                        }}
                        onBlur={(e) => {
                          const currentTargetVal = e.currentTarget.value;
                          if (currentTargetVal) {
                            let tagText = currentTargetVal;
                            tagText = tagText.replace(/[^a-zA-Z0-9 ]/g, '')
                            setValue((prev) => [...prev, createOption(tagText)]);
                            setInputValue('');
                            let uList = [...selectedTags, tagText];
                            setSelectedTags(uList)
                          }
                        }}
                        onInputChange={(newValue) => {
                          setInputValue(newValue.replace(/[^a-zA-Z0-9 ]/g, ''))
                        }}
                        onKeyDown={event => {
                          if (!inputValue) return;
                          switch (event.key) {
                            case 'Enter':
                            case 'Tab':
                              let tagText = inputValue;
                              tagText = tagText.replace(/[^a-zA-Z0-9 ]/g, '')
                              setValue((prev) => [...prev, createOption(tagText)]);
                              setInputValue('');
                              let uList = [...selectedTags, tagText];
                              setSelectedTags(uList)
                              event.preventDefault();
                          }
                        }}
                        placeholder="Create or Select a Tag and press enter..."
                        value={value}
                        defaultInputValue={[]}
                        options={allTags}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-between mb-2 mt-4">
                  <div className="flex align-center">
                    <a onClick={() => {

                      let stepListUpdated = [...stepList];
                      stepListUpdated.forEach((step, index) => {
                        step.enforceStepTimeout = true;
                        if (!step.stepTimeout) {
                          step.stepTimeout = 5;
                        }
                      });
                      setStepList(stepListUpdated);
                    }} className="flex no-underline edit-link-option mr-2">
                      <ToolTipV2 toolTipContent={'Enforce timeout for all steps with 5 sec if not mentioned on the step.'}>Enforce timeout for all steps</ToolTipV2>
                    </a>
                  </div>
                  <div className="flex align-center">
                    <a onClick={() => {
                      stepList.forEach((step, index) => {
                        expandList[index] = true;
                      });
                      setExpandList([...expandList]);
                    }} className="flex no-underline edit-link-option mr-2">Expand All</a>
                    <a onClick={() => {
                      stepList.forEach((step, index) => {
                        expandList[index] = false;
                      });
                      setExpandList([...expandList]);
                    }} className="flex no-underline edit-link-option">Collapse All</a>
                  </div>
                </div>
                <table className="app-table mb-4 sightsense">
                  <tbody>
                    {
                      stepList && stepList.length > 0 && stepList.map((step, index) => {
                        return (
                          <>
                            <tr className="table-row-title">
                              <td colSpan="9" className="table-row-details">
                                <div className="flex w-100 align-center">
                                  <div className='pointer'>
                                    <a className='flex' onClick={e => {
                                      e.preventDefault();
                                      expandList[index] = !expandList[index];
                                      setExpandList([...expandList]);
                                    }}>
                                      {expandList[index] ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" className="svg-icon"
                                          viewBox="0 0 18 12" fill="none">
                                          <path
                                            d="M0.34627 9.08042L8.16974 1.25695C8.27876 1.14755 8.40831 1.06074 8.55095 1.00151C8.69359 0.942283 8.84653 0.911793 9.00098 0.911793C9.15543 0.911793 9.30836 0.942283 9.45101 1.00151C9.59365 1.06074 9.7232 1.14755 9.83222 1.25695L17.6557 9.08042C17.8761 9.30088 18 9.59989 18 9.91166C18 10.2234 17.8761 10.5224 17.6557 10.7429C17.4352 10.9634 17.1362 11.0872 16.8244 11.0872C16.5127 11.0872 16.2137 10.9634 15.9932 10.7429L9 3.7497L2.0068 10.7439C1.78634 10.9643 1.48734 11.0882 1.17556 11.0882C0.863781 11.0882 0.564774 10.9643 0.344315 10.7439C0.123856 10.5234 7.5514e-08 10.2244 1.0277e-07 9.91264C1.30027e-07 9.60086 0.123856 9.30185 0.344315 9.08139L0.34627 9.08042Z"
                                            fill="#1B72E6" />
                                        </svg>
                                      ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9"
                                          viewBox="0 0 18 12" fill="none" className="svg-icon">
                                          <path
                                            d="M17.6537 2.91958L9.83027 10.743C9.72124 10.8524 9.59169 10.9393 9.44905 10.9985C9.30641 11.0577 9.15347 11.0882 8.99902 11.0882C8.84457 11.0882 8.69164 11.0577 8.54899 10.9985C8.40635 10.9393 8.2768 10.8524 8.16778 10.743L0.344312 2.91958C0.123853 2.69912 -3.2851e-09 2.40011 0 2.08834C3.2851e-09 1.77656 0.123853 1.47755 0.344312 1.25709C0.564772 1.03663 0.863779 0.912782 1.17556 0.912782C1.48733 0.912782 1.78634 1.03663 2.0068 1.25709L9 8.25029L15.9932 1.25612C16.2137 1.03566 16.5127 0.911804 16.8244 0.911804C17.1362 0.911804 17.4352 1.03566 17.6557 1.25612C17.8761 1.47658 18 1.77558 18 2.08736C18 2.39914 17.8761 2.69814 17.6557 2.9186L17.6537 2.91958Z"
                                            fill="#1B72E6" />
                                        </svg>
                                      )}
                                      <span className='caption-1 ml-4'>{index + 1}. {step.stepName} </span>
                                    </a>
                                  </div>
                                  <div className='flex justify-end flex-1 align-center'>
                                    <div className="flex align-center">
                                      {
                                        step.enableMouseClick && (
                                          <span className='body-4 ml-6'>Mouse click</span>
                                        )
                                      }
                                      {
                                        (step.stepSkipThis || step.skipScreenshot) && (
                                          <span className='body-4 ml-6'>
                                            {
                                              (step.stepSkipThis) ? 'Step skipped' : 'Screenshot skipped'
                                            }
                                          </span>
                                        )
                                      }
                                      {
                                        (step.stepTimeout || step.enforceStepTimeout) && (
                                          <span className='body-4 ml-6'>{step.enforceStepTimeout ? 'Enforced ' : ''} Time out - {step.stepTimeout} sec</span>
                                        )
                                      }
                                      <a className="ml-6" onClick={() => {
                                        handleCloneClick(index)
                                      }}>
                                        <AppIcon iconName="duplicate" iconColor="#1B72E6" />
                                      </a>
                                      <a className="ml-6" onClick={() => {
                                        handleDeleteClick(index)
                                      }}>
                                        <AppIcon iconName="delete" iconColor="rgba(209, 48, 77, 1)" />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            {
                              expandList[index] && (
                                <tr className="table-content">
                                  <td>
                                    <div className="form-field py-6">
                                      <label className="pb-2 white-space-no-wrap mr-2 bold w-full d-block mb-2 label">
                                        Step Name
                                      </label>
                                      <input
                                        type="text"
                                        className="input-txt input-field w-full p-2 input-txt"
                                        value={step.stepName}
                                        onChange={(e) => {
                                          let stepListUpdated = [...stepList];
                                          stepListUpdated[index].stepName = e.target.value;
                                          setStepList(stepListUpdated);
                                        }}
                                      />
                                      {
                                        errorStepList[index]?.stepName && (
                                          <p className="error-message">This field is required.</p>
                                        )
                                      }
                                    </div>
                                    <div className="form-field">
                                      <div className="flex justify-between">
                                        <label className=" py-2 white-space-no-wrap mr-2 bold w-full d-block mb-2 label">
                                          Step Definition(JSON)
                                        </label>
                                        <XpathRecommendation handleSelectSuggestionStep={(stepDataToUse) => {
                                          let stepMappedData = {};
                                          if (stepDataToUse['action'] == 'OPEN_URL' || stepDataToUse['actionType'] == 'OPEN_URL') {
                                            stepMappedData = {
                                              "action": "OPEN_URL",
                                              "url": stepDataToUse['pageURL'],
                                              "page_name": stepDataToUse['page_title']
                                            }
                                          } else {
                                            stepMappedData = {
                                              "action": stepDataToUse['action'] || stepDataToUse['actionType'],
                                              "element": stepDataToUse['elementName'],
                                              "elementId": stepDataToUse['elementId'],
                                              "xPath": stepDataToUse['elementXPath'],
                                              "value": stepDataToUse['elementValue'],
                                              "elementText": stepDataToUse['elementLinkText']
                                            }
                                          }
                                          let stepListUpdated = [...stepList];
                                          stepListUpdated[index].stepJson = JSON.stringify(stepMappedData, null, 2);
                                          setStepList(stepListUpdated);
                                        }} workflow={editablePageData} job={job} stepNo={index + 1} />
                                      </div>
                                      <textarea
                                        rows="8"
                                        onChange={(e) => {
                                          let stepListUpdated = [...stepList];
                                          stepListUpdated[index].stepJson = e.target.value;
                                          setStepList(stepListUpdated);
                                        }}
                                        className={`text-area w-full p-2 ` + (errorStepList[index]?.stepJson ? 'error' : '')}
                                        id="description"
                                        name="description"
                                        value={step.stepJson}
                                        required
                                      ></textarea>
                                      <p className={`field-note mt-1 ` + (errorStepList[index]?.stepJson ? 'font-danger' : '')}>Double check the json value, check all fields properly</p>
                                      <p className="field-note mt-1">Fields to search : [findByText or findByValue or findById or findByName or findByHref] and findByTagName and/or findByParentXpath</p>
                                    </div>

                                    <div className="py-2 flex mt-2">
                                      <label htmlFor={"skipstep" + index} className="form-field-checkbox">
                                        <input id={"skipstep" + index} type="checkbox" checked={step.stepSkipThis} onChange={e => {
                                          let stepListUpdated = [...stepList];
                                          stepListUpdated[index].stepSkipThis = e.target.checked;
                                          setStepList(stepListUpdated);
                                        }} />
                                        <span className="checkmark"></span>
                                      </label>
                                      <label htmlFor={"skipstep" + index} className="ml-2">
                                        Skip this step
                                      </label>
                                    </div>
                                    <div className="py-2 flex mt-2">
                                      <label htmlFor={"enablemouseclick" + index} className="form-field-checkbox">
                                        <input id={"enablemouseclick" + index} type="checkbox" checked={step.enableMouseClick} onChange={e => {
                                          let stepListUpdated = [...stepList];
                                          stepListUpdated[index].enableMouseClick = e.target.checked;
                                          setStepList(stepListUpdated);
                                        }} />
                                        <span className="checkmark"></span>
                                      </label>
                                      <label htmlFor={"enablemouseclick" + index} className="ml-2">
                                        Enable mouse click (use only for custom components)
                                      </label>
                                    </div>

                                    <div className="py-2 flex mt-2">
                                      <label htmlFor={"enforceStepTimeout" + index} className="form-field-checkbox">
                                        <input id={"enforceStepTimeout" + index} type="checkbox" checked={step.enforceStepTimeout} onChange={e => {
                                          let stepListUpdated = [...stepList];
                                          stepListUpdated[index].enforceStepTimeout = e.target.checked;
                                          setStepList(stepListUpdated);
                                        }} />
                                        <span className="checkmark"></span>
                                      </label>
                                      <label htmlFor={"enforceStepTimeout" + index} className="ml-2">
                                        Enforce step timeout (helpful in case of partial load in page)
                                      </label>
                                    </div>

                                    <div className="py-2 flex mt-2">
                                      <label htmlFor={"skipScreenshot" + index} className="form-field-checkbox">
                                        <input id={"skipScreenshot" + index} type="checkbox" checked={step.skipScreenshot} onChange={e => {
                                          let stepListUpdated = [...stepList];
                                          stepListUpdated[index].skipScreenshot = e.target.checked;
                                          setStepList(stepListUpdated);
                                        }} />
                                        <span className="checkmark"></span>
                                      </label>
                                      <label htmlFor={"skipScreenshot" + index} className="ml-2">
                                        Skip screenshot for this step
                                      </label>
                                    </div>

                                    <div className="form-field mt-2">
                                      <div className="flex">
                                        <label className="white-space-no-wrap mr-2 bold mb-2 label flex align-center pointer" id="timed-catpture-id">
                                          <span className="mr-1">Timed Screenshot Capture (in sec)</span>
                                          <SvgIcon path="/icons/info-svg.svg"></SvgIcon>
                                          <Tooltip
                                            anchorSelect={'#timed-catpture-id'}
                                            content={'Delays the screenshot by the configured timeout duration.'}
                                            place="left-center"
                                            style={{
                                              backgroundColor: "#000",
                                              color: "#fff",
                                              borderRadius: "5px",
                                              zIndex: 22,
                                              fontWeight: "bold",
                                            }}
                                          />
                                        </label>
                                      </div>
                                      <input
                                        type="text"
                                        value={step.stepTimeout}
                                        className="input-field w-full p-2 input-txt"
                                        onChange={(event) => {
                                          let val = (event.target.value || '').replace(new RegExp('[^0-9]', 'g'), '')
                                          let stepListUpdated = [...stepList];
                                          stepListUpdated[index].stepTimeout = val;
                                          setStepList(stepListUpdated);
                                        }}
                                      />
                                    </div>
                                    <div className='form-field mt-2'>
                                      <label className="label mb-2">Additional question/insight to verify in this page</label>
                                      <textarea rows="3" className='w-full text-area'
                                        value={step.customprompt}
                                        onChange={e => {
                                          let stepListUpdated = [...stepList];
                                          stepListUpdated[index].customprompt = e.target.value;
                                          setStepList(stepListUpdated);
                                        }}
                                      >
                                      </textarea>
                                      <p class="field-note mt-1">Example: Verify if &lt;enter specifics&gt; is present..</p>
                                    </div>
                                  </td>
                                </tr>
                              )
                            }
                          </>
                        )
                      })
                    }
                  </tbody>
                </table>
                <div className="flex align-center">
                  <a onClick={handleCopyAllSteps} className="py-2 flex no-underline edit-link-option">{copyText}</a>
                </div>
              </div>
              <div className="app-form__controls mt-4 px-6 py-4 flex-end">
                <button onClick={e => handleSaveChanges(e)} disabled={btnDisabled}
                  className="ml-4 btn-medium-2 flex justify-center align-center btn-medium-width"
                  type="button"
                >
                  Save Changes{" "}
                  <span
                    className={`${showLoader ? "loading--icon flex" : ""}`}
                  ></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {
        showSaveModal && (
          <ConfirmSaveModal
            confirmationTitle={'Are you sure you want to save the changes?'}
            confirmButtonLabel={'Save'}
            onOk={() => onConfirmation()}
            onCancel={e => setShowSaveModal(false)}
          />
        )
      }
      {
        showDeleteModal && (
          <ConfirmModal
            confirmationTitle={'Are you sure you want to delete?'}
            confirmButtonLabel={'Delete'}
            onOk={() => handleconfirmDeleteClick()}
            onCancel={e => setShowDeleteModal(false)}
          />
        )
      }
      {
        showConfirmationModal && (
          <ConfirmSaveModal
            confirmationTitle={'Are you sure you want to exit without saving the changes?'}
            messageBody={(
              <>
                <p className="sub-heading-2">Below fields(s) are updated</p>
                <ul className="pl-4 mt-4">
                  {
                    modifiedFields.map(x => stepNameMap[x]).map(x => {
                      return (
                        <li>{x}</li>
                      )
                    })
                  }

                </ul>
              </>
            )}
            confirmButtonLabel={'Continue'}
            onOk={() => {
              setSelectedStepData({ ...selectedStepData, stepNumber: nextSelectedStep });
              setShowConfirmationModal(false)
            }}
            onCancel={e => {
              setShowConfirmationModal(false)
            }}
          />
        )
      }
    </div>
  );
};
