import { useState, useEffect } from "react";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import featureflagsService from "../services/featureflags.service";
import tenantsService from "../services/tenants.service";
import storeService from "../services/store.service";
import authService from "../services/auth.service";
export default () => {
    const [params, setParams] = useSearchParams();
    if (params.get('env')) {
        let decodeEnv = decodeURIComponent(params.get('env'));
        storeService.store.ENVIRONMENTS.selectedEnvId = decodeEnv;
        localStorage.setItem('selectedEnvId', decodeEnv);
    }

    if (params.get('source')) {
        let decodeSource = decodeURIComponent(params.get('source'));
        localStorage.setItem('source', decodeSource);
    }

    // Remove query and reload if any of the query param env or source
    if (params.get('env') || params.get('source')) {
        location.href = location.href.split('?')[0];
    }

    const [isLoading, setIsLoading] = useState(true);
    const checkTenantsList = async () => {
        const tenantsList = await tenantsService.loadTenants();
        const { data = {} } = tenantsList || {};
        const { items = [] } = data || {};
        let storedenvId = localStorage.getItem('selectedEnvId');
        if (storedenvId) {
            storeService.store.ENVIRONMENTS.selectedEnvId = storedenvId;
        } else {
            localStorage.setItem('selectedEnvId', items.find(x => x.name == 'default')?.environments?.[0]?._id);
            storeService.store.ENVIRONMENTS.selectedEnvId = items.find(x => x.name == 'default')?.environments?.[0]?._id;
        }
    }
    const checkAuth = async () => {
        let toeknResp = await authService.tokeninfo({})
        const { data, error } = toeknResp;
        const { licenseData } = data || {};
        if (licenseData) {
            storeService.store.LICENSE_DATA.daysToExpire = licenseData.daysToExpire;
            storeService.store.LICENSE_DATA.failedCount = licenseData.failedCount;
        }
        if (error) {
            const { code, message } = error;
            if (code == 'INVALID_LICENSE_ACCESS') {
                location.href = "/license";
                return;
            }
            location.href = "/login?redirectUrl=" + encodeURIComponent(location.pathname);
            return;
        }
        await checkTenantsList();
        setIsLoading(false);
    }
    useEffect(() => {
        if (localStorage.getItem('token')) {
            checkAuth();
        } else {
            location.href = "/login?redirectUrl=" + encodeURIComponent(location.pathname);
        }
    }, [])
    if (isLoading) {
        return (
            <div className="root-layout">
                <div>
                    <div className="pos-rel">
                        &nbsp;
                        <div className="section-loader2">
                            <div class="wave">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                    <div>
                        Loading environments...
                    </div>
                </div>
            </div>
        )
    }
    return (<Outlet />)
}