import { useState, useEffect } from 'react'
import storeService from "../../services/store.service"
export default () => {
    const [showWarning, setshowWarning] = useState(null)
    useEffect(() => {
        let licenseData = storeService.store.LICENSE_DATA;
        if (licenseData.daysToExpire && licenseData.daysToExpire < 7) {
            setshowWarning(`${licenseData.daysToExpire} days to expire the License`)
        } 
        if (licenseData.failedCount) {
            setshowWarning(`License check is failed in ${licenseData.failedCount} times`)
        }
    }, [])
    if (!showWarning) return null;
    return (
        <div className="bg-white p-2 flex align-center rounded-m">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="-2 -2 29 29" fill="none">
                <path d="M4.16884 20.6021H24.8313C26.3418 20.6021 27.3084 18.9104 26.4626 17.6417L16.1918 0.90625C15.4063 -0.302083 13.6543 -0.302083 12.8688 0.90625L2.47718 17.6417C1.69176 18.9104 2.65843 20.6021 4.16884 20.6021ZM12.9293 6.1625C12.9293 5.25625 13.6543 4.59167 14.5605 4.59167C15.4668 4.59167 16.1313 5.31667 16.1313 6.1625V6.82708L15.648 12.2646C15.5876 12.8687 15.1043 13.2917 14.5001 13.2917C13.8959 13.2917 13.4126 12.8083 13.3522 12.2042L12.9293 6.76667V6.1625ZM14.5001 14.1979C15.4063 14.1979 16.0709 14.9229 16.0709 15.7688C16.0709 16.675 15.3459 17.3396 14.5001 17.3396C13.6543 17.3396 12.9293 16.6146 12.9293 15.7688C12.8688 14.8625 13.5938 14.1979 14.5001 14.1979Z" fill="url(#paint1_linear_1_521)" />
            </svg>
            <div className="ml-3">{showWarning}</div>
        </div>
    )
}