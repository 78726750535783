import React, { useState, useEffect, Fragment, useCallback } from 'react';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import SvgIcon from '../../comps/atoms/SvgIcon';
import EditTestCaseModal from './EditTestCaseModal';
import SvgCode from '../atoms/SvgCode';
import storeService from '../../services/store.service';
import utilsService from '../../services/utils.service';
import dataService from '../../services/data.service';
import ScheduleButton from '../../comps/molecules/ScheduleButton.jsx'
import collectionsService from '../../services/collections.service.js';
import { Tooltip } from 'react-tooltip';

const createOption = (label) => ({
    label,
    value: label,
});
export default ({ list, collection, runs, onchange, collectionId }) => {
    let todayDate = new Date();
    let fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 30);
    const [lastDateRan, setLastDateRan] = useState(utilsService.getYMDFormat(todayDate))
    const [selectedDate, setSelectedDate] = useState(storeService.store.COLLECTIONS_WITH_DETAILS.filterData.selectedDate)
    const [items, setItems] = useState([])
    const [selectedTenantEnv, setselectedTenantEnv] = useState(storeService.store.ENVIRONMENTS.selectedTenantEnv);
    const [testRunInProgress, settestRunInProgress] = useState(false)
    const [testRunList, setTestRunList] = useState([])
    const [showSideModal, setshowSideModal] = useState(false);
    const [testdataList, setTestdataList] = useState([])
    const [loading, setLoading] = useState(false);
    const [collections, setCollections] = useState([])
    const [showEditTestCase, setShowEditTestCase] = useState(false)
    const _defaults = {
        name: {
            showError: false,
            value: ''
        },
        functionalRequirements: {
            showError: false,
            value: ''
        },
        apiDefinition: {
            showError: false,
            value: ''
        },
        testDataId: {
            showError: false,
            value: ''
        },
        selectedTags: {
            showError: false,
            value: ''
        },
        apiDataType: {
            label: 'JSON',
            value: 'JSON',
            showError: false
        }
    }
    const [formData, setFormData] = useState(_defaults)
    const [chainedApis, setChainedApis] = useState([]);
    const [chainedTestCases, setChainedTestCases] = useState([]);
    const [inputValue, setInputValue] = React.useState('');
    const [selectedTags, setSelectedTags] = useState([]);
    const [value, setValue] = React.useState([]);
    const [allTags, setAllTags] = useState([])
    const [defaultTags, setDefaultTags] = useState([]);
    const handleInputChange = (value, key) => {
        setFormData({
            ...formData,
            [key]: {
                ...formData[key],
                value
            }
        })
    }
    useEffect(() => {
        setFormData({
            ...formData,
            name: {
                value: collection.name,
                showError: false
            },
            functionalRequirements: {
                value: collection.functionalRequirements,
                showError: false
            },
            apiDefinition: {
                value: collection.apiDefinition,
                showError: false
            },
            testDataId: {
                value: collection.testDataId,
                showError: false
            },
            apiDataType: {
                value: collection.apiDataType,
                label: collection.apiDataType,
                showError: false
            }
        })
        storeService.subscribe('ENVIRONMENTS', ({ detail }) => {
            setselectedTenantEnv(detail.selectedTenantEnv)
        })
        let pageStore = storeService.getStore('COLLECTIONS_WITH_DETAILS');
        storeService.subscribe('COLLECTIONS_WITH_DETAILS', ({ detail }) => {
            updateChainApis(detail.items);
        });
        if (!pageStore.loaded) {
            dataService.getCollectionsWithDetails({ id: collectionId, invokedBy: 'test case list line 146'});
        } else {
            updateChainApis(pageStore.items);
        }
        collectionsService.loadmetaData().then(apiResp => {
            const { data = {} } = apiResp;
            if (data) {
                const { tags = [] } = data;
                setAllTags(tags.map(item => {
                    return {
                        label: item,
                        value: item
                    }
                }))
            }
        })
        let getApiTestData = storeService.getStore('API_TEST_DATA');
        if (!getApiTestData.loaded) {
            storeService.subscribe('API_TEST_DATA', ({ detail }) => {
                setTestdataList(detail.items);
            });
            dataService.getApiTestData();
        } else {
            setTestdataList(getApiTestData.items);
        }
    }, []);

    const updateTestCasesWithApis = useCallback(() => {
        let data = [];
        collection?.testcases?.forEach((val, index) => {
            const _idArray = val.testId ? val.testId.split("_") : [];
            const _id = _idArray.length ? _idArray[0] : "";
            const _apiId = _idArray.length > 1 ? _idArray[1] : "";
            const _index = data.findIndex((item) => item._id === _id);
            if (_index === -1) {
                data.push({
                    _id: _id,
                    _apiIds: [_apiId],
                    name: val.name,
                    testCases: [{ ...val }],
                    testIds: [val.testId],
                    apis: [],
                    expanded: index === 0
                });
            } else {
                data[_index].testIds.push(val.testId);
                data[_index].testCases.push({ ...val });
                data[_index]._apiIds.push(_apiId);
            }
        });

        collection?.chainApiIds?.forEach((api, index) => {
            data.map((test, _index) => {
                if (test._apiIds.indexOf("api" + (index + 1)) > -1) {
                    test.apis.push(api);
                }
                return test;
            });
        });
        setChainedTestCases(data);
    });

    const updateChainApis = useCallback((list) => {
        if (collection.apiType && collection.apiType == 'CHAINED') {
            updateTestCasesWithApis();
            let data = collection.chainApiIds.map((x, idx) => {
                let currentTestCases = collection.testcases.filter(testItem => {
                    let testId = testItem.testId;
                    if (testId && testId.indexOf('_') > 0) {
                        if (testId.split('_')[1] == 'api' + (idx + 1)) {
                            return true;
                        }
                    }
                    return false;
                })

                return {
                    apiId: x,
                    apiIndex: idx + 1,
                    expanded: true,
                    testcases: currentTestCases
                }
            });
            setChainedApis(data);
        } else {
            setChainedApis([])
        }
        setCollections(list);
    })

    const toggleChainTestApi = useCallback((e, idx) => {
        e.preventDefault();
        chainedTestCases[idx].expanded = !chainedTestCases[idx].expanded;
        setChainedTestCases([...chainedTestCases]);
    })

    const toggleChainedApi = useCallback((e, idx) => {
        e.preventDefault();
        chainedApis[idx].expanded = !chainedApis[idx].expanded;
        setChainedApis([...chainedApis]);
    });

    useEffect(() => {
        setItems(list)
        settestRunInProgress(false);
    }, [list])
    useEffect(() => {
        if (runs && runs.length > 0) {
            let runList = [];
            let selectedIndex = 0;
            runs.forEach((run, idx) => {
                let newItem = { ...run };
                let testcasesCount = (run.results || []).length;
                let totalResponseTime = 0;
                let failedCount = 0;
                run.results.forEach(test => {
                    totalResponseTime += test.responseTime || 0;
                    if (test.result != 'Passed') {
                        failedCount += 1;
                    }
                });
                let avgResponseTime = ((totalResponseTime / testcasesCount) * 1000).toFixed(2);
                newItem.avgResponseTime = avgResponseTime;
                newItem.testcasesCount = testcasesCount;
                newItem.failedCount = failedCount;
                newItem.testStatus = failedCount == 0 && run.results.length > 0 ? 'Passed' : 'Failed';
                newItem.testDate = utilsService.getDisplayDateTime(run.createdAt, true);
                if(selectedDate == newItem.testDate && selectedIndex == 0) {
                    selectedIndex = idx
                }
                runList.push(newItem);
            })
            runList[selectedIndex].selected = true;
            runList[selectedIndex].expand = true;
            if(selectedIndex != 0) {
                onchange && onchange({ _id: runList[selectedIndex]._id })
            }
            setTestRunList(runList)
        } else {
            setTestRunList([])
        }
    }, [runs])
    useEffect(() => {
        if (collection) {
            const tags = collection.tags;
            if (tags) {
                let sList = (typeof tags == 'string' ? tags.split(',') : tags);
                setDefaultTags(sList);
                setValue(sList.map(x => createOption(x)))
                setSelectedTags(sList)
            }
        }
    }, [collection])
    const formatTestCaseAndOpenTestCaseDetails = useCallback((e, testId, testCases = [], itemResults = []) => {
        e.preventDefault();
        const selectedTestCase = testCases.find(val => val.testId === testId);
        const selectedTestResult = itemResults.find(val => val.testId === testId);
        openTestCaseDetails(e, selectedTestCase, selectedTestResult);
    });

    const openTestCaseDetails = useCallback((e, item, result) => {
        e.preventDefault();
        let testCaseItem = { ...item };
        if (result) {
            testCaseItem.result = result;
        }
        storeService.pub(utilsService.CONSTANTS.EVENT_NAME.OPEN_TESTCASES_SIDEBAR_MODAL, {
            showModal: true,
            modalData: {
                testCaseItem
            }
        });
    })

    const runApiTests = async (e) => {
        e.preventDefault();
        settestRunInProgress(true);
        await dataService.runApiTests({
            "_id": collection._id,
            "apiType": collection.apiType
        });
        await dataService.getCollectionsWithDetails();
    }
    const generateTestcases = (e) => {
        e.preventDefault()
        dataService.generateTestcases({
            collectionId: collection._id
        })
    }
    const expandRow = (e, item) => {
        e.preventDefault();
        testRunList.forEach(run => {
            if (item._id == run._id) {
                run.expand = !run.expand;
                run.selected = true;
            } else {
                run.expand = false;
                run.selected = false;
            }
        });
        onchange && onchange({ _id: item._id });
        setTestRunList([...testRunList])
    }
    const openEditModal = (e) => {
        e.preventDefault();

        setshowSideModal(true)
    }
    const closeModal = (e) => {
        e.preventDefault();
        setshowSideModal(false)
    }
    const handleEditTestCase = (e) => {
        e.preventDefault();
        setShowEditTestCase(true)
    }
    const testCaseListTag = (
        <table className="app-table mt-2 mb-2 nested-table">
            <tbody>
                <tr className="table-row-subtitle">
                    <td>
                        <div className="align-center">
                            <span className="mr-2 table-subheading">Test Id</span>
                            <SvgCode name="sort" />
                        </div>
                    </td>
                    <td>
                        <div className="align-center">
                            <span className="mr-2 table-subheading">Test Case Name</span>
                            <SvgCode name="sort" />
                        </div>
                    </td>
                    <td>
                        <div className="align-center">
                            <span className="mr-2 table-subheading">Status</span>
                            <SvgCode name="sort" />
                        </div>
                    </td>
                    <td>
                        <div className="align-center">
                            <span className="mr-2 table-subheading">Jira Ticket</span>
                            <SvgCode name="sort" />
                        </div>
                    </td>
                </tr>

                {
                    items && items.map((item, index) => {
                        return (
                            <tr className="table-content" key={'testcase-' + index}>
                                <td className="table-text">{item.testId}</td>
                                <td className='para-2'>
                                    <a onClick={e => openTestCaseDetails(e, item, null)}>
                                        <div className="justify-between">
                                            <div className='align-center'>
                                                <span className="table-text">
                                                    {item.name}
                                                </span>
                                                {
                                                    item.version && item.version > 1 && (
                                                        <span className='ml-2'>(v{item.version})</span>
                                                    )
                                                }

                                            </div>
                                            <div className='align-center'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="18" viewBox="0 0 12 18"
                                                    fill="none">
                                                    <path
                                                        d="M2.91958 0.34627L10.743 8.16973C10.8525 8.27876 10.9393 8.40831 10.9985 8.55095C11.0577 8.69359 11.0882 8.84653 11.0882 9.00098C11.0882 9.15543 11.0577 9.30836 10.9985 9.45101C10.9393 9.59365 10.8525 9.7232 10.743 9.83222L2.91958 17.6557C2.69912 17.8761 2.40011 18 2.08834 18C1.77656 18 1.47755 17.8761 1.25709 17.6557C1.03663 17.4352 0.912782 17.1362 0.912782 16.8244C0.912782 16.5127 1.03663 16.2137 1.25709 15.9932L8.2503 9L1.25612 2.0068C1.03566 1.78634 0.911803 1.48734 0.911803 1.17556C0.911803 0.863781 1.03566 0.564774 1.25612 0.344315C1.47658 0.123856 1.77558 -3.7757e-08 2.08736 -5.13852e-08C2.39914 -6.50134e-08 2.69814 0.123856 2.9186 0.344314L2.91958 0.34627Z"
                                                        fill="#1B72E6" />
                                                </svg>
                                            </div>
                                        </div>
                                    </a>
                                </td>
                                <td className={'' + (item.result && item.result.result == 'Failed' ? 'font-danger' : '')}>
                                    <span className="font-xs">{(item.result && item.result.result) || '-'}</span>
                                </td>
                                <td>
                                    <div className='justify-between'>
                                        {(item.result && item.result.jiraTicket) ? (<>
                                            <a className='btn-link' href={item.result.jiraTicket.link}>{item.result.jiraTicket.text}</a>
                                        </>) : (
                                            (item.result && item.result.result == 'Failed') ? (
                                                <>
                                                    <a className='table-text' href='https://cognitiveaitech.atlassian.net/jira/software/projects/CAR/boards/2'>Log Jira Ticket</a>
                                                </>
                                            ) : (<>-</>)
                                        )}
                                    </div>
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    );
    const chainedTestCaseListTag = (
        <table className="app-table mt-2 nested-table">
            <tbody>
                <tr className="table-row-subtitle">
                    <td>
                        <div className="flex align-center">
                            <span className="mr-2 table-subheading align-center flex">
                                Chained APIs
                            </span>

                        </div>
                    </td>
                    <td>
                        <div className="align-center">
                            <span className="mr-2 table-subheading">Id</span>
                            <SvgCode name="sort" />
                        </div>
                    </td>
                    <td>
                        <div className="align-center">
                            <span className="mr-2 table-subheading">Failed Test Cases</span>
                            <SvgCode name="sort" />
                        </div>
                    </td>
                    <td>
                        <div className="align-center">
                            <span className="mr-2 table-subheading">API Status</span>
                        </div>
                    </td>
                </tr>
                {
                    chainedApis.map((chainApi, idx) => {
                        let chainApiItem = collections.find(x => x._id == chainApi.apiId);
                        if (chainApiItem) {
                            return (
                                <React.Fragment key={'api-key-' + idx}>
                                    <tr className="table-content">
                                        <td>
                                            <a href="#" onClick={e => toggleChainedApi(e, idx)}>
                                                <div className='justify-between align-center'>
                                                    <span className='font-medium'>{chainApiItem.name}</span>
                                                    {
                                                        chainApi.expanded ? (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12"
                                                                viewBox="0 0 18 12" fill="none">
                                                                <path
                                                                    d="M0.34627 9.08042L8.16974 1.25695C8.27876 1.14755 8.40831 1.06074 8.55095 1.00151C8.69359 0.942283 8.84653 0.911793 9.00098 0.911793C9.15543 0.911793 9.30836 0.942283 9.45101 1.00151C9.59365 1.06074 9.7232 1.14755 9.83222 1.25695L17.6557 9.08042C17.8761 9.30088 18 9.59989 18 9.91166C18 10.2234 17.8761 10.5224 17.6557 10.7429C17.4352 10.9634 17.1362 11.0872 16.8244 11.0872C16.5127 11.0872 16.2137 10.9634 15.9932 10.7429L9 3.7497L2.0068 10.7439C1.78634 10.9643 1.48734 11.0882 1.17556 11.0882C0.863781 11.0882 0.564774 10.9643 0.344315 10.7439C0.123856 10.5234 7.5514e-08 10.2244 1.0277e-07 9.91264C1.30027e-07 9.60086 0.123856 9.30185 0.344315 9.08139L0.34627 9.08042Z"
                                                                    fill="#1B72E6" />
                                                            </svg>
                                                        ) : (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12"
                                                                viewBox="0 0 18 12" fill="none">
                                                                <path
                                                                    d="M17.6537 2.91958L9.83027 10.743C9.72124 10.8524 9.59169 10.9393 9.44905 10.9985C9.30641 11.0577 9.15347 11.0882 8.99902 11.0882C8.84457 11.0882 8.69164 11.0577 8.54899 10.9985C8.40635 10.9393 8.2768 10.8524 8.16778 10.743L0.344312 2.91958C0.123853 2.69912 -3.2851e-09 2.40011 0 2.08834C3.2851e-09 1.77656 0.123853 1.47755 0.344312 1.25709C0.564772 1.03663 0.863779 0.912782 1.17556 0.912782C1.48733 0.912782 1.78634 1.03663 2.0068 1.25709L9 8.25029L15.9932 1.25612C16.2137 1.03566 16.5127 0.911804 16.8244 0.911804C17.1362 0.911804 17.4352 1.03566 17.6557 1.25612C17.8761 1.47658 18 1.77558 18 2.08736C18 2.39914 17.8761 2.69814 17.6557 2.9186L17.6537 2.91958Z"
                                                                    fill="#1B72E6" />
                                                            </svg>
                                                        )
                                                    }

                                                </div>
                                            </a>
                                        </td>
                                        <td>
                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    {
                                        chainApi.expanded && chainApi.testcases && chainApi.testcases.map((testItem, tIdx) => {
                                            return (
                                                <tr className="table-content" key={'api-' + idx + '-test-id' + tIdx}>
                                                    <td>
                                                        <a onClick={e => openTestCaseDetails(e, testItem, null)}>
                                                            <div className="justify-between">
                                                                <div className='align-center'>
                                                                    <span className="table-text">
                                                                        {tIdx + 1}. {testItem.name}
                                                                    </span>
                                                                    {
                                                                        testItem.version && testItem.version - 0 > 1 && (
                                                                            <span className='ml-2'>(v{testItem.version - 0})</span>
                                                                        )
                                                                    }
                                                                </div>
                                                                <div className='align-center'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="18" viewBox="0 0 12 18"
                                                                        fill="none">
                                                                        <path
                                                                            d="M2.91958 0.34627L10.743 8.16973C10.8525 8.27876 10.9393 8.40831 10.9985 8.55095C11.0577 8.69359 11.0882 8.84653 11.0882 9.00098C11.0882 9.15543 11.0577 9.30836 10.9985 9.45101C10.9393 9.59365 10.8525 9.7232 10.743 9.83222L2.91958 17.6557C2.69912 17.8761 2.40011 18 2.08834 18C1.77656 18 1.47755 17.8761 1.25709 17.6557C1.03663 17.4352 0.912782 17.1362 0.912782 16.8244C0.912782 16.5127 1.03663 16.2137 1.25709 15.9932L8.2503 9L1.25612 2.0068C1.03566 1.78634 0.911803 1.48734 0.911803 1.17556C0.911803 0.863781 1.03566 0.564774 1.25612 0.344315C1.47658 0.123856 1.77558 -3.7757e-08 2.08736 -5.13852e-08C2.39914 -6.50134e-08 2.69814 0.123856 2.9186 0.344314L2.91958 0.34627Z"
                                                                            fill="#1B72E6" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </td>
                                                    <td className="table-text">
                                                        {testItem.testId}
                                                    </td>
                                                    <td className="table-text">

                                                    </td>
                                                    <td className="table-text">

                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </React.Fragment>
                            )
                        }
                        return null;
                    })
                }
            </tbody>
        </table>
    );
    const renderTestCaseList = (testItem) => {
        let testResults = testItem.results;
        return (
            <table className="app-table mt-2 mb-2 nested-table">
                <tbody>
                    <tr className="table-row-subtitle">
                        <td>
                            <div className="align-center">
                                <span className="mr-2 table-subheading">Test Id</span>
                                <SvgCode name="sort" />
                            </div>
                        </td>
                        <td>
                            <div className="align-center">
                                <span className="mr-2 table-subheading">Test Case Name</span>
                                <SvgCode name="sort" />
                            </div>
                        </td>
                        <td>
                            <div className="align-center">
                                <span className="mr-2 table-subheading">Status</span>
                                <SvgCode name="sort" />
                            </div>
                        </td>
                        <td>
                            <div className="align-center">
                                <span className="mr-2 table-subheading">Jira Ticket</span>
                                <SvgCode name="sort" />
                            </div>
                        </td>
                    </tr>

                    {
                        testResults && testResults.map((item, index) => {
                            let testCaseItem = items.find(x => x.testId == item.testId);
                            return (
                                <tr classname="table-content" key={'testcase-' + index}>
                                    <td className='table-text'>{item.testId}</td>
                                    <td className='table-text'>
                                        <a onClick={e => openTestCaseDetails(e, testCaseItem || item, item)}>
                                            <div className="justify-between">
                                                <div className='align-center'>
                                                    <span className="table-text">
                                                        {item.name || testCaseItem?.name}
                                                    </span>
                                                    {
                                                        item.version && item.version > 1 && (
                                                            <span className='ml-2'>(v{item.version})</span>
                                                        )
                                                    }
                                                </div>
                                                <div className='align-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="18" viewBox="0 0 12 18"
                                                        fill="none">
                                                        <path
                                                            d="M2.91958 0.34627L10.743 8.16973C10.8525 8.27876 10.9393 8.40831 10.9985 8.55095C11.0577 8.69359 11.0882 8.84653 11.0882 9.00098C11.0882 9.15543 11.0577 9.30836 10.9985 9.45101C10.9393 9.59365 10.8525 9.7232 10.743 9.83222L2.91958 17.6557C2.69912 17.8761 2.40011 18 2.08834 18C1.77656 18 1.47755 17.8761 1.25709 17.6557C1.03663 17.4352 0.912782 17.1362 0.912782 16.8244C0.912782 16.5127 1.03663 16.2137 1.25709 15.9932L8.2503 9L1.25612 2.0068C1.03566 1.78634 0.911803 1.48734 0.911803 1.17556C0.911803 0.863781 1.03566 0.564774 1.25612 0.344315C1.47658 0.123856 1.77558 -3.7757e-08 2.08736 -5.13852e-08C2.39914 -6.50134e-08 2.69814 0.123856 2.9186 0.344314L2.91958 0.34627Z"
                                                            fill="#1B72E6" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </a>
                                    </td>
                                    <td className={'' + (item && item.result == 'Failed' ? 'font-danger' : '')}>
                                        <span className="font-xs">{(item && item.result) || '-'}</span>
                                    </td>
                                    <td className="table-text">
                                        <div className='justify-between'>
                                            {(item && item.jiraTicket) ? (<>
                                                <a className='btn-link' href={item.jiraTicket.link}>{item.jiraTicket.text}</a>
                                            </>) : (
                                                (item && item.result == 'Failed') ? (
                                                    <>
                                                        <a className='table-text' href='https://cognitiveaitech.atlassian.net/jira/software/projects/CAR/boards/2'>Log Jira Ticket</a>
                                                    </>
                                                ) : (<>-</>)
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        )
    }
    const handleformSubmit = async (generateNow) => {
        setLoading(true);
        let collectionToUpdate = {
            name: formData.name.value,
            functionalRequirements: formData.functionalRequirements.value,
            apiDefinition: formData.apiDefinition.value,
            testDataId: formData.testDataId.value,
            tags: selectedTags.join(','),
            _id: collection._id,
            apiDataType: formData.apiDataType.value
        }
        if (generateNow) {
            collectionToUpdate['reGenerate'] = true;
        }
        await collectionsService.updatecollection(collectionToUpdate);
        storeService.pub('COLLECTION_UPDATED', {});
        setshowSideModal(false)
    }
    const toggleTestRunExpand = (e, index) => {
        e.preventDefault();
        let newTestRunList = [...testRunList];
        newTestRunList[index].expand = !newTestRunList[index].expand;
        setTestRunList(newTestRunList);
    }
    const getApiChainedFailedCount = (item) => {
        let failedCount = 0;
        for (let i = 0; i < chainedApis.length; i++) {
            let apiItem = chainedApis[i];
            let isFailed = false;
            if (apiItem) {
                let testcases = apiItem.testcases;
                if (testcases && item.results && item.results.length > 0) {
                    testcases.forEach(testItem => {
                        let resultItem = item.results.find(x => x.testId == testItem.testId);
                        if (!(resultItem && resultItem.result == 'Passed')) {
                            isFailed = true;
                        }
                    });
                }
            }
            if (isFailed) {
                failedCount += 1;
            }
        }
        return failedCount + '/' + chainedApis.length;
    }
    const getChainTestApiStatus = useCallback((testcases = [], item) => {
        let isFailed = false, failedCount = 0;
        if (testcases && item.results && item.results.length > 0) {
            testcases.forEach(testItem => {
                let resultItem = item.results.find(x => x.testId == testItem.testId);
                if (!(resultItem && resultItem.result == 'Passed') || item.results.length == 0) {
                    isFailed = true;
                    failedCount += 1;
                }
            });
        } else {
            isFailed = true;
            failedCount = testcases.length
        }
        return {
            status: isFailed ? 'Failed' : 'Passed',
            failedTestCaseCount: failedCount,
            totalTestCaseCount: testcases.length
        }
    })
    let runListTag = (<></>);
    if (testRunList.length > 0) {
        if (collection.apiType && collection.apiType == 'CHAINED') {
            runListTag = (
                <table className="app-table mt-2">
                    <tbody>
                        {
                            testRunList.map((item, index) => {
                                return (
                                    <Fragment key={'row-head-' + index}>
                                        <tr className='tr-head'>
                                            <td colSpan="4">
                                                <div className='justify-between'>
                                                    <a onClick={e => toggleTestRunExpand(e, index)}>
                                                        <div className='flex align-center '>
                                                            <span className='mr-3'>{utilsService.getDisplayDateTime(item.createdAt)}</span>
                                                            {
                                                                !item.expand ? (
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12"
                                                                        viewBox="0 0 18 12" fill="none">
                                                                        <path
                                                                            d="M17.6537 2.91958L9.83027 10.743C9.72124 10.8524 9.59169 10.9393 9.44905 10.9985C9.30641 11.0577 9.15347 11.0882 8.99902 11.0882C8.84457 11.0882 8.69164 11.0577 8.54899 10.9985C8.40635 10.9393 8.2768 10.8524 8.16778 10.743L0.344312 2.91958C0.123853 2.69912 -3.2851e-09 2.40011 0 2.08834C3.2851e-09 1.77656 0.123853 1.47755 0.344312 1.25709C0.564772 1.03663 0.863779 0.912782 1.17556 0.912782C1.48733 0.912782 1.78634 1.03663 2.0068 1.25709L9 8.25029L15.9932 1.25612C16.2137 1.03566 16.5127 0.911804 16.8244 0.911804C17.1362 0.911804 17.4352 1.03566 17.6557 1.25612C17.8761 1.47658 18 1.77558 18 2.08736C18 2.39914 17.8761 2.69814 17.6557 2.9186L17.6537 2.91958Z"
                                                                            fill="#1B72E6" />
                                                                    </svg>
                                                                ) : (
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12"
                                                                        viewBox="0 0 18 12" fill="none">
                                                                        <path
                                                                            d="M0.34627 9.08042L8.16974 1.25695C8.27876 1.14755 8.40831 1.06074 8.55095 1.00151C8.69359 0.942283 8.84653 0.911793 9.00098 0.911793C9.15543 0.911793 9.30836 0.942283 9.45101 1.00151C9.59365 1.06074 9.7232 1.14755 9.83222 1.25695L17.6557 9.08042C17.8761 9.30088 18 9.59989 18 9.91166C18 10.2234 17.8761 10.5224 17.6557 10.7429C17.4352 10.9634 17.1362 11.0872 16.8244 11.0872C16.5127 11.0872 16.2137 10.9634 15.9932 10.7429L9 3.7497L2.0068 10.7439C1.78634 10.9643 1.48734 11.0882 1.17556 11.0882C0.863781 11.0882 0.564774 10.9643 0.344315 10.7439C0.123856 10.5234 7.5514e-08 10.2244 1.0277e-07 9.91264C1.30027e-07 9.60086 0.123856 9.30185 0.344315 9.08139L0.34627 9.08042Z"
                                                                            fill="#1B72E6" />
                                                                    </svg>
                                                                )
                                                            }
                                                        </div>
                                                    </a>
                                                    <div className='flex'>
                                                        {
                                                            item.results && item.results.length > 0 && (
                                                                <>
                                                                    <div className='mr-4 caption-1'>Avg. Response Time <span className='txt-heading-color'>{item.avgResponseTime}ms</span></div>
                                                                    <div className='mr-4 caption-1'>Failed Tests <span className='txt-heading-color'>{item.failedCount}/{item.testcasesCount}</span></div>
                                                                    <div className='mr-4 caption-1'>Failed APIs <span className='txt-heading-color'>{getApiChainedFailedCount(item)}</span></div>
                                                                </>
                                                            )
                                                        }

                                                        <div className='caption-1'>Status  {item.testStatus == 'Passed' ? (<span>Passed</span>) : (<span className='error-text'>Failed</span>)}</div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        {
                                            item.expand && (
                                                <>
                                                    <tr className="table-row-subtitle">
                                                        <td>
                                                            <div className="flex align-center">
                                                                <span className="mr-1 align-center flex">
                                                                    Chained APIs
                                                                </span>

                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="align-center">
                                                                <span className="mr-1">Id</span>
                                                                <SvgCode name="sort" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="align-center">
                                                                <span className="mr-1">Failed Test Cases</span>
                                                                <SvgCode name="sort" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="align-center">
                                                                <span className="mr-1">API Status</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {/* Chain test Cases */}
                                                    {chainedTestCases?.map((testApi, index) => {
                                                        let chainApiStatus = getChainTestApiStatus(testApi.testCases, item);
                                                        return (
                                                            <React.Fragment key={'test-key-' + index}>
                                                                <tr className="table-content">
                                                                    <td>
                                                                        <a href="#" onClick={e => toggleChainTestApi(e, index)}>
                                                                            <div className='justify-between align-center'>
                                                                                <span className='font-medium'>{testApi.name}</span>
                                                                                {
                                                                                    testApi.expanded ? (
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12"
                                                                                            viewBox="0 0 18 12" fill="none">
                                                                                            <path
                                                                                                d="M0.34627 9.08042L8.16974 1.25695C8.27876 1.14755 8.40831 1.06074 8.55095 1.00151C8.69359 0.942283 8.84653 0.911793 9.00098 0.911793C9.15543 0.911793 9.30836 0.942283 9.45101 1.00151C9.59365 1.06074 9.7232 1.14755 9.83222 1.25695L17.6557 9.08042C17.8761 9.30088 18 9.59989 18 9.91166C18 10.2234 17.8761 10.5224 17.6557 10.7429C17.4352 10.9634 17.1362 11.0872 16.8244 11.0872C16.5127 11.0872 16.2137 10.9634 15.9932 10.7429L9 3.7497L2.0068 10.7439C1.78634 10.9643 1.48734 11.0882 1.17556 11.0882C0.863781 11.0882 0.564774 10.9643 0.344315 10.7439C0.123856 10.5234 7.5514e-08 10.2244 1.0277e-07 9.91264C1.30027e-07 9.60086 0.123856 9.30185 0.344315 9.08139L0.34627 9.08042Z"
                                                                                                fill="#1B72E6" />
                                                                                        </svg>
                                                                                    ) : (
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12"
                                                                                            viewBox="0 0 18 12" fill="none">
                                                                                            <path
                                                                                                d="M17.6537 2.91958L9.83027 10.743C9.72124 10.8524 9.59169 10.9393 9.44905 10.9985C9.30641 11.0577 9.15347 11.0882 8.99902 11.0882C8.84457 11.0882 8.69164 11.0577 8.54899 10.9985C8.40635 10.9393 8.2768 10.8524 8.16778 10.743L0.344312 2.91958C0.123853 2.69912 -3.2851e-09 2.40011 0 2.08834C3.2851e-09 1.77656 0.123853 1.47755 0.344312 1.25709C0.564772 1.03663 0.863779 0.912782 1.17556 0.912782C1.48733 0.912782 1.78634 1.03663 2.0068 1.25709L9 8.25029L15.9932 1.25612C16.2137 1.03566 16.5127 0.911804 16.8244 0.911804C17.1362 0.911804 17.4352 1.03566 17.6557 1.25612C17.8761 1.47658 18 1.77558 18 2.08736C18 2.39914 17.8761 2.69814 17.6557 2.9186L17.6537 2.91958Z"
                                                                                                fill="#1B72E6" />
                                                                                        </svg>
                                                                                    )
                                                                                }

                                                                            </div>
                                                                        </a>
                                                                    </td>
                                                                    <td>
                                                                    </td>
                                                                    <td>
                                                                        <span className='body-2'>{chainApiStatus.failedTestCaseCount}/{chainApiStatus.totalTestCaseCount}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span className={'body-2 ' + (chainApiStatus.status == 'Failed' ? 'error-text' : '')}>{chainApiStatus.status}</span>
                                                                    </td>
                                                                </tr>
                                                                {/* Expanded apis here.. */}
                                                                {
                                                                    testApi.expanded && testApi.apis?.map((apiId, _apiIndex) => {
                                                                        let chainApiItem = collections.find(x => x._id == apiId);
                                                                        let testCaseStatus = '';
                                                                        let testCaseResult = item?.results?.find(x => x.testId == testApi.testIds[_apiIndex]) || {};
                                                                        if (testCaseResult && testCaseResult.result == 'Passed') {
                                                                            testCaseStatus = 'Passed';
                                                                        } else {
                                                                            testCaseStatus = 'Failed';
                                                                        }
                                                                        if (!chainApiItem) {
                                                                            return null;
                                                                        }
                                                                        return (
                                                                            <tr key={'api-key-' + _apiIndex}>
                                                                                <td>
                                                                                    {/* TODO: add click to anchor onClick={e => openTestCaseDetails(e, testItem, testCaseResult)} */}
                                                                                    <a onClick={e => formatTestCaseAndOpenTestCaseDetails(e, testApi.testIds[_apiIndex], testApi.testCases, item?.results)}>
                                                                                        <div className="justify-between">
                                                                                            <div className='align-center'>
                                                                                                <span className="table-text">
                                                                                                    {chainApiItem.name}
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className='align-center'>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="18" viewBox="0 0 12 18"
                                                                                                    fill="none">
                                                                                                    <path
                                                                                                        d="M2.91958 0.34627L10.743 8.16973C10.8525 8.27876 10.9393 8.40831 10.9985 8.55095C11.0577 8.69359 11.0882 8.84653 11.0882 9.00098C11.0882 9.15543 11.0577 9.30836 10.9985 9.45101C10.9393 9.59365 10.8525 9.7232 10.743 9.83222L2.91958 17.6557C2.69912 17.8761 2.40011 18 2.08834 18C1.77656 18 1.47755 17.8761 1.25709 17.6557C1.03663 17.4352 0.912782 17.1362 0.912782 16.8244C0.912782 16.5127 1.03663 16.2137 1.25709 15.9932L8.2503 9L1.25612 2.0068C1.03566 1.78634 0.911803 1.48734 0.911803 1.17556C0.911803 0.863781 1.03566 0.564774 1.25612 0.344315C1.47658 0.123856 1.77558 -3.7757e-08 2.08736 -5.13852e-08C2.39914 -6.50134e-08 2.69814 0.123856 2.9186 0.344314L2.91958 0.34627Z"
                                                                                                        fill="#1B72E6" />
                                                                                                </svg>
                                                                                            </div>
                                                                                        </div>
                                                                                    </a>
                                                                                </td>
                                                                                <td>
                                                                                    {testApi.testIds[_apiIndex]}
                                                                                </td>
                                                                                <td>
                                                                                    {testCaseStatus.length > 0 ? (
                                                                                        testCaseStatus == 'Passed' ? 'Passed' : (<span className='error-text'>Failed</span>)
                                                                                    ) : ''}
                                                                                </td>
                                                                                <td>

                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </>
                                            )
                                        }

                                    </Fragment>
                                )
                            })
                        }
                    </tbody>
                </table>
            )
        } else {
            runListTag = (
                <table className="app-table mt-2">
                    <tbody>
                        <tr className='table-row-title'>
                            <td className='checkbox-table-cell'>
                                <span className='table-heading'>No</span>
                            </td>
                            <td>
                                <span className='table-heading'>Test Date</span>
                            </td>
                            <td>
                                <span className='table-heading'>Avg Response time (ms)</span>
                            </td>
                            <td>
                                <span className='table-heading'># Cases Failed</span>
                            </td>
                            <td className='table-heading'>
                                <span className='table-heading'>Status</span>
                            </td>
                        </tr>
                        {
                            testRunList.map((item, index) => {
                                return (
                                    <Fragment key={'row-head-' + index}>
                                        <tr className='table-content'>
                                            <td className="table-heading">{index + 1}</td>
                                            <td className="table-heading">
                                                <a onClick={e => expandRow(e, item)}>
                                                    <div className='flex justify-between table-subheading'>
                                                        <span className={item.selected ? 'font-bold' : ''}>{item.testDate}</span>
                                                        {!item.expand ? (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12"
                                                                viewBox="0 0 18 12" fill="none">
                                                                <path
                                                                    d="M17.6537 2.91958L9.83027 10.743C9.72124 10.8524 9.59169 10.9393 9.44905 10.9985C9.30641 11.0577 9.15347 11.0882 8.99902 11.0882C8.84457 11.0882 8.69164 11.0577 8.54899 10.9985C8.40635 10.9393 8.2768 10.8524 8.16778 10.743L0.344312 2.91958C0.123853 2.69912 -3.2851e-09 2.40011 0 2.08834C3.2851e-09 1.77656 0.123853 1.47755 0.344312 1.25709C0.564772 1.03663 0.863779 0.912782 1.17556 0.912782C1.48733 0.912782 1.78634 1.03663 2.0068 1.25709L9 8.25029L15.9932 1.25612C16.2137 1.03566 16.5127 0.911804 16.8244 0.911804C17.1362 0.911804 17.4352 1.03566 17.6557 1.25612C17.8761 1.47658 18 1.77558 18 2.08736C18 2.39914 17.8761 2.69814 17.6557 2.9186L17.6537 2.91958Z"
                                                                    fill="#1B72E6" />
                                                            </svg>
                                                        ) : (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12"
                                                                viewBox="0 0 18 12" fill="none">
                                                                <path
                                                                    d="M0.34627 9.08042L8.16974 1.25695C8.27876 1.14755 8.40831 1.06074 8.55095 1.00151C8.69359 0.942283 8.84653 0.911793 9.00098 0.911793C9.15543 0.911793 9.30836 0.942283 9.45101 1.00151C9.59365 1.06074 9.7232 1.14755 9.83222 1.25695L17.6557 9.08042C17.8761 9.30088 18 9.59989 18 9.91166C18 10.2234 17.8761 10.5224 17.6557 10.7429C17.4352 10.9634 17.1362 11.0872 16.8244 11.0872C16.5127 11.0872 16.2137 10.9634 15.9932 10.7429L9 3.7497L2.0068 10.7439C1.78634 10.9643 1.48734 11.0882 1.17556 11.0882C0.863781 11.0882 0.564774 10.9643 0.344315 10.7439C0.123856 10.5234 7.5514e-08 10.2244 1.0277e-07 9.91264C1.30027e-07 9.60086 0.123856 9.30185 0.344315 9.08139L0.34627 9.08042Z"
                                                                    fill="#1B72E6" />
                                                            </svg>
                                                        )}
                                                    </div>
                                                </a>

                                            </td>
                                            <td className="table-subheading">{item.testcasesCount > 0 ? (item.avgResponseTime + 'ms') : '-'}</td>
                                            <td className="table-subheading">{item.testcasesCount > 0 ? item.failedCount : '-'}</td>
                                            <td className={item.testStatus == 'Failed' || item.failedReason ? 'font-danger' : ''}>
                                                <span className="font-xs">{item.testcasesCount > 0 || item.failedReason ? item.testStatus : '-'}</span>
                                            </td>
                                        </tr>
                                        {
                                            item.expand && (
                                                item.failedReason ? (
                                                    <tr>
                                                        <td colSpan="6" className='nested-table-wrapper-cell'>
                                                            <div className='p-4 error-message'>
                                                                <span>{typeof item.failedReason == 'string' ? item.failedReason : item.failedReason.message}</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <tr>
                                                        <td colSpan="6" className='nested-table-wrapper-cell'>
                                                            {renderTestCaseList(item)}
                                                        </td>
                                                    </tr>
                                                )
                                            )
                                        }
                                    </Fragment>
                                )
                            })
                        }
                    </tbody>
                </table>
            );
        }
    }

    return (
        <>
            <div className="flex justify-between">
                <div className="flex align-center">
                    <div className="flex align-center pointer" id="test-history-tooltip">
                        <h2 className='mr-2'>Test History</h2>
                        <SvgIcon path="/icons/info-svg.svg"></SvgIcon>
                        <Tooltip
                            anchorSelect="#test-history-tooltip"
                            content="View past run details, search, and run, edit, or schedule future runs. Filter results by environment and date."
                            place="top-start"
                            style={{
                                backgroundColor: "#000",
                                color: "#ffffff",
                                borderRadius: "5px",
                                zIndex: 22,
                                fontWeight: "bold",
                                width: "50%",
                            }}
                        />
                    </div>
                    <div className="flex align-center ml-4">
                        {/* <a href="/filter-modal.html" className="link-icon">
                            <SvgIcon extraclass='svg-size-l' path="/icons/filter-icon.svg"></SvgIcon>
                        </a> */}
                        <a onClick={e => handleEditTestCase(e)} className="link-icon ml-2">
                            <SvgIcon extraclass='svg-size-m ml-1 mt-1' path="/icons/edit-icon.svg"></SvgIcon>
                        </a>
                    </div>
                </div>
                <div className="flex align-center">
                    <ScheduleButton module="API_GLIDE" mode="DEVICE_DETAIL" collectionId={collection._id} apiType={collection.apiType} />
                    <a href="#" onClick={openEditModal} className={'py-2 px-4 flex align-center no-underline edit-link-option' + (testRunInProgress ? 'disabled' : '')}>
                        <SvgIcon extraclass='svg-icon-l edit-icon' path="/icons/edit-icon.svg"></SvgIcon>
                        <span>Edit</span>
                    </a>
                    <a href="#" onClick={generateTestcases} className={'py-2 px-4 flex align-center no-underline edit-link-option' + (testRunInProgress ? 'disabled' : '')}>
                        <span className="ml-1">Generate Test cases</span>
                    </a>
                    <a href="#" onClick={e => runApiTests(e)} className={'py-2 px-4 flex align-center no-underline edit-link-option' + (testRunInProgress ? 'disabled' : '')}>
                        <SvgCode name="play"></SvgCode>
                        <span className="ml-1">Run</span>
                    </a>
                </div>
            </div>
            {
                testRunList.length > 0 ?
                    (
                        runListTag
                    ) :
                    (
                        collection.apiType && collection.apiType == 'CHAINED' ? (
                            chainedTestCaseListTag
                        ) : testCaseListTag
                    )
            }
            {
                showSideModal && (
                    <div className="app-side-modal show">
                        <div className="app-side-modal__bg"></div>
                        <div className="app-side-modal__content">
                            <div className="align-center p-4 modal-head">
                                <a href="#" onClick={closeModal}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="close-svg-icon" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                        <path d="M27.4101 24.5813C27.7857 24.9569 27.9967 25.4663 27.9967 25.9974C27.9967 26.5285 27.7857 27.0379 27.4101 27.4134C27.0345 27.789 26.5251 28 25.9939 28C25.4628 28 24.9534 27.789 24.5778 27.4134L14 16.833L3.41888 27.4101C3.0433 27.7857 2.53389 27.9967 2.00273 27.9967C1.47158 27.9967 0.962173 27.7857 0.586588 27.4101C0.211002 27.0346 7.91487e-09 26.5252 0 25.9941C-7.91487e-09 25.4629 0.211002 24.9536 0.586588 24.578L11.1677 14.0008L0.589919 3.42034C0.214334 3.04478 0.00333218 2.53541 0.00333219 2.00428C0.00333219 1.47315 0.214334 0.963782 0.589919 0.588218C0.965505 0.212655 1.47491 0.00166568 2.00607 0.00166568C2.53722 0.00166567 3.04663 0.212655 3.42221 0.588218L14 11.1687L24.5811 0.586552C24.9567 0.210989 25.4661 -8.84857e-09 25.9973 0C26.5284 8.84857e-09 27.0378 0.210989 27.4134 0.586552C27.789 0.962115 28 1.47149 28 2.00261C28 2.53374 27.789 3.04312 27.4134 3.41868L16.8323 14.0008L27.4101 24.5813Z" fill="#1B72E6" />
                                    </svg>
                                </a>
                                <div className="w-2/5 ml-4">
                                    <h3 className='h3-heading align-center'>
                                        API Details
                                        {
                                            collection.apiType && collection.apiType == 'CHAINED' && (
                                                <svg className='ml-3' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g id="Group 216">
                                                        <rect id="Rectangle 915" width="24" height="24" rx="3" fill="#26A5A5" />
                                                        <path id="Vector" d="M17.3887 5.488C16.7384 4.83711 15.6829 4.83744 15.0316 5.48834L11.9681 8.55163C11.9678 8.55196 11.9675 8.55263 11.9668 8.5533C11.3175 9.20436 11.3187 10.2587 11.9688 10.9096L12.5575 11.4978L11.4971 12.5584L10.9085 11.9702C10.2578 11.3193 9.20201 11.3191 8.55135 11.9705L5.48739 15.0333C5.48739 15.0337 5.48706 15.0337 5.48672 15.0343C4.83707 15.6859 4.8379 16.741 5.48839 17.3912L7.61036 19.5117C8.26119 20.1628 9.31668 20.1628 9.9675 19.5117L13.0315 16.4477C13.0318 16.4474 13.0321 16.4471 13.0328 16.4464C13.6818 15.7955 13.6811 14.7412 13.0308 14.0905L12.4416 13.5019L13.502 12.4411L14.0914 13.0298C14.7419 13.6806 15.7974 13.6811 16.4483 13.0295L19.5122 9.96622C19.5129 9.96588 19.5132 9.96521 19.5135 9.96454C20.1629 9.31365 20.1622 8.25902 19.5112 7.60829L17.3887 5.488ZM8.78735 18.3277L6.66521 16.216L9.73167 13.154L10.3183 13.7374L9.73084 14.3253C9.73084 14.3253 9.7305 14.3253 9.73017 14.3256C9.47034 14.5866 9.46967 15.0096 9.72984 15.2706C9.99 15.5318 10.4123 15.5325 10.6727 15.2713L11.265 14.6787L11.8541 15.2648L8.78735 18.3277ZM15.2679 11.8464L14.6806 11.2622L15.2683 10.6741C15.2683 10.6741 15.2686 10.6738 15.2689 10.6738C15.5291 10.4126 15.5294 9.99011 15.2693 9.72875C15.0091 9.46739 14.5869 9.46706 14.3264 9.72775L13.734 10.3205L13.1453 9.73494L16.2121 6.67198L18.3347 8.78308L15.2679 11.8464Z" fill="white" />
                                                    </g>
                                                </svg>
                                            )
                                        }
                                    </h3>
                                </div>
                            </div>
                            <div className="model-main-content p-3">
                                <div className="section-box rounded-sm">
                                    <form className="app-form">
                                        <div className="app-form__fields modal-form-content">
                                            <div className="form-field mb-8">
                                                <label className="label mb-2 flex align-center">
                                                    Collection Name

                                                </label>
                                                <input value={formData.name.value} onChange={e => handleInputChange(e.target.value, 'name')} className="input-txt" type="text" />
                                                {
                                                    formData.name.showError && (
                                                        <p className="error-message">This field is required.</p>
                                                    )
                                                }
                                            </div>
                                            {
                                                !(collection.apiType && collection.apiType == 'CHAINED') && (
                                                    <>
                                                        <div className="form-field mb-8">
                                                            <label className="label mb-2">Functional Requirement</label>
                                                            <textarea rows="8" value={formData.functionalRequirements.value} onChange={e => handleInputChange(e.target.value, 'functionalRequirements')} className="text-area"></textarea>
                                                            {
                                                                formData.functionalRequirements.showError && (
                                                                    <p className="error-message">This field is required.</p>
                                                                )
                                                            }
                                                        </div>
                                                        <div className="form-field mb-8">
                                                            <label className="label mb-2">API Definition</label>
                                                            <textarea rows="8" value={formData.apiDefinition.value} onChange={e => handleInputChange(e.target.value, 'apiDefinition')} className="text-area"></textarea>
                                                            {
                                                                formData.apiDefinition.showError && (
                                                                    <p className="error-message">This field is required.</p>
                                                                )
                                                            }
                                                        </div>
                                                    </>
                                                )
                                            }

                                            {
                                                (collection.apiType && collection.apiType == 'CHAINED') && chainedApis.map((chainApi, idx) => {
                                                    let chainApiItem = collections.find(x => x._id == chainApi.apiId);
                                                    if (chainApiItem) {
                                                        return (
                                                            <div key={'chained-api-' + idx} className='mt-2'>
                                                                <label className="label mb-1">API {idx + 1}: {chainApiItem.name}</label>
                                                                <div className='pl-4'>
                                                                    <div className="form-field mb-8">
                                                                        <label className="label mb-2">Functional Requirement</label>
                                                                        <textarea rows="8" disabled defaultValue={chainApiItem.functionalRequirements} className="input-textarea"></textarea>
                                                                    </div>
                                                                    <div className="form-field mb-8">
                                                                        <label className="label mb-2">API Definition</label>
                                                                        <textarea rows="8" disabled defaultValue={chainApiItem.apiDefinition} className="input-textarea"></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    return null;
                                                })
                                            }

                                            <div className="form-field-select mb-8">
                                                <label className="label mb-2">Pick Test Data </label>
                                                <div className="align-center">
                                                    <div className="form-field">
                                                        <select className="select-text" value={formData.testDataId.value} onChange={e => handleInputChange(e.target.value, 'testDataId')}>
                                                            <option>Select Testdata</option>
                                                            {
                                                                testdataList.map(x => {
                                                                    return (
                                                                        <option className="select-text" key={'testdata-' + x._id} value={x._id}>{x.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        <svg className="post-svg-icon" xmlns="http://www.w3.org/2000/svg" width="18"
                                                            height="11" viewBox="0 0 18 11" fill="none">
                                                            <path
                                                                d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z"
                                                                fill="#1B72E6" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-field-select mb-8">
                                                <label className="label mb-2">Create or Select Tags {defaultTags}</label>
                                                <div className="align-center">
                                                    <div className="form-field">
                                                        <CreatableSelect
                                                            inputValue={inputValue}
                                                            isClearable
                                                            isMulti
                                                            onChange={(tagValues) => {
                                                                let uList = tagValues.map((item) => item.value);
                                                                setSelectedTags(uList)
                                                                setValue(tagValues);
                                                            }}
                                                            onBlur={(e) => {
                                                                const currentTargetVal = e.currentTarget.value;
                                                                if (currentTargetVal) {
                                                                    let tagText = currentTargetVal;
                                                                    tagText = tagText.replace(/[^a-zA-Z0-9 ]/g, '')
                                                                    setValue((prev) => [...prev, createOption(tagText)]);
                                                                    setInputValue('');
                                                                    let uList = [...selectedTags, currentTargetVal];
                                                                    setSelectedTags(uList)
                                                                }
                                                            }}
                                                            onInputChange={(newValue) => {
                                                                setInputValue(newValue.replace(/[^a-zA-Z0-9 ]/g, ''))
                                                            }}
                                                            onKeyDown={(event) => {
                                                                if (!inputValue) return;
                                                                switch (event.key) {
                                                                    case 'Enter':
                                                                    case 'Tab':
                                                                        let tagText = inputValue;
                                                                        tagText = tagText.replace(/[^a-zA-Z0-9 ]/g, '')
                                                                        setValue((prev) => [...prev, createOption(tagText)]);
                                                                        setInputValue('');
                                                                        let uList = [...selectedTags, inputValue];
                                                                        setSelectedTags(uList)
                                                                        event.preventDefault();
                                                                }
                                                            }}
                                                            placeholder="Create or Select a Tag and press enter..."
                                                            value={value}
                                                            defaultInputValue={defaultTags}
                                                            options={allTags}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-field-select mt-3">
                                                <label className="label mb-1">API data type</label>
                                                <div className="align-center">
                                                    <div className="form-field">
                                                        <Select
                                                            value={formData.apiDataType}
                                                            onChange={(dataObj) => {
                                                                setFormData({
                                                                    ...formData,
                                                                    apiDataType: {
                                                                        showError: false,
                                                                        ...dataObj,
                                                                    }
                                                                })
                                                            }}
                                                            name="apidatatype"
                                                            options={['JSON', 'XML'].map(x => ({ label: x, value: x }))}
                                                            className={`basic-select`}
                                                            classNamePrefix="select"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="app-form__controls px-6 py-4 align-center">
                                            <a className="btn-link-medium mr-8" onClick={e => closeModal(e)}>Cancel</a>
                                            <button className="btn-medium-2 mr-3" type='button' onClick={e => handleformSubmit(true)} disabled={loading}>Re-generate Testcases</button>
                                            <button className="btn-medium-2" type='button' onClick={e => handleformSubmit(false)} disabled={loading}>Save changes</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            <EditTestCaseModal collection={collection} openModal={showEditTestCase} onCloseClick={e => setShowEditTestCase(false)}></EditTestCaseModal>
        </>
    );
} 