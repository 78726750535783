import storeService from "./store.service";
import utilsService from "./utils.service";
import dataService from "./data.service";
import pagesService from "./pages.service";
import workflowsService from "./workflows.service";
var wClient = null;
let storeElem = document.createElement('div');
const moduleName = 'SOCKET_MESSAGE'
export default {
    init() {
        let socketUrl = (process.env.ENABLE_SSL ? 'wss://' : 'ws://') + location.hostname + ':' + (process.env.WS_PORT || location.port);
        wClient = new WebSocket(socketUrl, 'echo-protocol');
        wClient.onopen = (e) => {
            utilsService.log({}, {}, 'Socket connected!!!!')
        };
        wClient.onmessage = async (e) => {
            try {
                let dataFromServer = JSON.parse(e.data);
                if (dataFromServer.action === 'BUILD_SYNC') {
                    if (config && config.BUILD_SYNC && config.BUILD_SYNC['ENABLED']) {
                        if (location.hostname === 'localhost') {
                            location.reload();
                        }
                    }
                }
                if (dataFromServer.action === 'CONNECTED') {
                    utilsService.log({}, {}, 'Build version: ' + dataFromServer.data.buildVersion);
                    wClient.send(JSON.stringify({
                        action: 'REGISTER_CLIENT',
                        data: {
                            isDeveloper: location.hostname === 'localhost'
                        }
                    }));
                }
                if (dataFromServer.action === 'LOG') {
                    utilsService.log({}, {}, 'Server Log: ', dataFromServer.data);
                    storeService.pub(utilsService.CONSTANTS.EVENT_NAME.SERVER_LOG, dataFromServer);
                    if (dataFromServer.data.code == 'ANALYSIS_JOB_DONE') {
                        await dataService.getWorkflowsWithDetails()
                        storeService.pub(utilsService.CONSTANTS.EVENT_NAME.ANALYSIS_JOB_DONE, {});
                    }
                    if (dataFromServer.data.code == 'USERFLOW_JOB_DONE') {
                        await dataService.getWorkflowsWithDetails()
                        storeService.pub(utilsService.CONSTANTS.EVENT_NAME.USERFLOW_JOB_DONE, {});
                    }
                    // COMPARE_JOB_DONE or SCREENSHOT_JOB_DONE
                    if (dataFromServer.data.code == 'COMPARE_JOB_DONE') {
                        await pagesService.loadPageCompareJobs()
                        storeService.pub('RELOAD_STATS', {});
                    }
                    if (dataFromServer.data.code == 'SCREENSHOT_JOB_DONE') {
                        await pagesService.loadPageJobs()
                        storeService.pub('RELOAD_STATS', {});
                    }
                    // COMPARE_JOB_STARTED
                    if (dataFromServer.data.code == 'COMPARE_JOB_STARTED') {
                        await pagesService.loadPageCompareJobs()
                        storeService.pub('RELOAD_STATS', {});
                    }
                    // USERFLOW_JOB_STARTED
                    if (dataFromServer.data.code == 'USERFLOW_JOB_STARTED') {
                        storeService.pub(utilsService.CONSTANTS.EVENT_NAME.USERFLOW_JOB_STARTED, {});
                    }
                }
                // storeElem.dispatchEvent(new CustomEvent(moduleName, { detail: dataFromServer }));
            } catch (e) {
                //swallow
            }
        };
        wClient.onclose = () => {

        }
    },
    sendMessageToSocket(message) {
        wClient.send(JSON.stringify(message));
    },
    sub(eventFn) {
        storeElem.addEventListener(moduleName, eventFn);
    }
}