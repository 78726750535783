
import React, { useState } from "react";
import AppModal from "../organisms/AppModal.jsx";
import AppIcon from "../atoms/AppIcon/AppIcon.jsx";
import dataService from "../../services/data.service.js";

export default ({ onClose }) => {
  const [environmentDetails, setEnvironmentDetails] = useState({
    host: '',
    authenticationURL: '',
    webAuthenticationURL: '',
    apiKey: '',
    model3: '',
    model4: '',
    environmentName: '',
    LogoName: '',
    LogoFileName: '',
    NextRelease: ''
  });
  

  const hasEmptyFields = (obj) => {
    return Object.values(obj).some(value => {
        // Check if the value is empty, null, or undefined
        return value === '' || value === null || value === undefined;
    });
 };

 const addNewEnvironment = async () => {
  
  const environmentData = {
    name: environmentDetails.environmentName,
    host: environmentDetails.host,
    authenticationURL: environmentDetails.authenticationURL,
    webAuthenticationURL: environmentDetails.webAuthenticationURL,
    model3: environmentDetails.model3,
    model4: environmentDetails.model4,
    apiKey: environmentDetails.apiKey,
    LogoName: environmentDetails.LogoName,
    LogoFileName: environmentDetails.LogoFileName,
    NextRelease: environmentDetails.NextRelease
  };
  await dataService.addEnvironmentList({ environments: [environmentData] });
  onClose();
 };

  return (
    <AppModal autoWidth={false}>
      <div>
            <div className="justify-between align-center modal-title">
              <div className="align-center">
                <h2 className="h2-heading">
                  Add New Environment
                </h2>
              </div>
              <button
                type="button"
                onClick={onClose}
                className="bg-none no-border"
              >
                <AppIcon iconName="closeIcon" iconColor="#1B72E6" />
              </button>
            </div>
            <div className="env-config-modal-content modal-form-content">
              <p className="label mb-6">All fields are mandatory *</p>
              <div className="mb-8">
                <label className="white-space-no-wrap mr-2 bold w-full d-block label mb-2">
                  Environment Name
                </label>
                <input
                  type="text"
                  value={environmentDetails.environmentName}
                  className="input-field w-full input-txt"
                  onChange={(event) =>
                    setEnvironmentDetails({
                      ...environmentDetails,
                      environmentName: event.target.value,
                    })
                  }
                />
                {/* {environmentDetails?.environmentName?.length === 0 ? <p className="font-danger bold">This field is required</p> : null } */}
              </div>
              <div className="mb-8">
                <label className="white-space-no-wrap mr-2 bold w-full d-block label mb-2">
                  Web Host URL
                </label>
                <input
                  type="text"
                  value={environmentDetails.host}
                  className="input-field w-full input-txt"
                  onChange={(event) =>
                    setEnvironmentDetails({
                      ...environmentDetails,
                      host: event.target.value,
                    })
                  }
                />
                {/* {environmentDetails?.host?.length === 0 ? <p className="font-danger bold">This field is required</p> : null } */}
              </div>
              <div className="mb-8 form-field">
                <label className="white-space-no-wrap mr-2 bold w-full d-block label mb-2">
                  API Authentication URL
                </label>
                <input
                  type="text"
                  value={environmentDetails.authenticationURL}
                  className="input-field w-full input-txt word-break-all"
                  onChange={(event) =>
                    setEnvironmentDetails({
                      ...environmentDetails,
                      authenticationURL: event.target.value,
                    })
                  }
                />
                {/* {environmentDetails.authenticationURL?.length === 0 ? <p className="font-danger bold">This field is required</p> : null } */}
              </div>
              <div className="mb-8 form-field">
                <label className="white-space-no-wrap mr-2 bold w-full d-block label mb-2">
                  Web Authentication URL
                </label>
                <div className="flex align-center">
                  <input
                    type="text"
                    value={environmentDetails.webAuthenticationURL}
                    className="input-field flex-1 input-txt word-break-all"
                    onChange={(event) =>
                      setEnvironmentDetails({
                        ...environmentDetails,
                        webAuthenticationURL: event.target.value,
                      })
                    }
                  />
                </div>
                {/* {environmentDetails.webAuthenticationURL?.length === 0 ? <p className="font-danger bold">This field is required</p> : null } */}
                
              </div>
              <div className="mb-8 form-field">
                <label className="white-space-no-wrap mr-2 bold w-full d-block label mb-2">
                  API Key
                </label>
                <input
                  type="text"
                  value={environmentDetails.apiKey}
                  className="input-field w-full input-txt"
                  onChange={(event) =>
                    setEnvironmentDetails({
                      ...environmentDetails,
                      apiKey: event.target.value,
                    })
                  }
                />
                 {/* {environmentDetails.apiKey?.length === 0 ? <p className="font-danger bold">This field is required</p> : null } */}
              </div>
              <div className="mb-8 form-field">
                <label className="white-space-no-wrap mr-2 bold w-full d-block label mb-2">
                OpenAI Model Name 3
                </label>
                <input
                  type="text"
                  value={environmentDetails.model3}
                  className="input-field w-full input-txt"
                  onChange={(event) =>
                    setEnvironmentDetails({
                      ...environmentDetails,
                      model3: event.target.value,
                    })
                  }
                />
                {/* {environmentDetails.model3?.length === 0 ? <p className="font-danger bold">This field is required</p> : null } */}
              </div>
              <div className="mb-8 form-field">
                <label className="white-space-no-wrap mr-2 bold w-full d-block label mb-2">
                OpenAI Model Name 4
                </label>
                <input
                  type="text"
                  value={environmentDetails.model4}
                  className="input-field w-full input-txt"
                  onChange={(event) =>
                    setEnvironmentDetails({
                      ...environmentDetails,
                      model4: event.target.value,
                    })
                  }
                />
                {/* {environmentDetails.model4.length === 0 ? <p className="font-danger bold">This field is required</p> : null } */}
              </div>
              <div className="mb-8 form-field">
                <label className="white-space-no-wrap mr-2 bold w-full d-block label mb-2">
                  Logo Name
                </label>
                <input
                  type="text"
                  value={environmentDetails.LogoName}
                  className="input-field w-full input-txt"
                  onChange={(event) =>
                    setEnvironmentDetails({
                      ...environmentDetails,
                      LogoName: event.target.value,
                    })
                  }
                />
                </div>
                <div className="mb-8 form-field">
                  <label className="white-space-no-wrap mr-2 bold w-full d-block label mb-2">
                    Logo File Name
                  </label>
                  <input
                    type="text"
                    value={environmentDetails.LogoFileName}
                    className="input-field w-full input-txt"
                    onChange={(event) =>
                      setEnvironmentDetails({
                        ...environmentDetails,
                        LogoFileName: event.target.value,
                      })
                    }
                  />
                </div>
                <div className="mb-8 form-field">
                <label className="white-space-no-wrap mr-2 bold w-full d-block label mb-2">
                  Next Release Date
                </label>
                <input
                  type="text"
                  value={environmentDetails.NextRelease}
                  className="input-field w-full input-txt"
                  onChange={(event) =>
                    setEnvironmentDetails({
                      ...environmentDetails,
                      NextRelease: event.target.value,
                    })
                  }
                />
                </div>
            </div>

            <div className="flex justify-end px-6 py-4">
              <button
                onClick={addNewEnvironment}
                type="button"
                className={`btn-medium-2 btn-medium-width px-4 ${hasEmptyFields(environmentDetails) ? 'disabled': ''}`}
              >
                Save
              </button>
            </div>
          </div>
    </AppModal>
  );
};
