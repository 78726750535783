import { useState, useEffect } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import storeService from "../../services/store.service";
export default () => {
    const [isRoot, setIsRoot] = useState(true)
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [currentPageName, setCurrentPageName] = useState('Create Collection');
    const [apiType, setApiType] = useState('')
    useEffect(() => {
        let storeData = storeService.getStore('CURRENT_PAGE');
        if (storeData && storeData.name) {
            setCurrentPageName(storeData.name);
            setApiType(storeData.apiType);
        }
        storeService.subscribe('CURRENT_PAGE', ({ detail }) => {
            setCurrentPageName(detail.name);
            setApiType(detail.apiType);
        });
    }, [])
    useEffect(() => {
        if (params.collectionId) {
            setIsRoot(false);
        } else if (location.pathname.indexOf('create-collection') >= 0) {
            setIsRoot(false);
        } else {
            setIsRoot(true);
        }
    }, [location])
    const navigateToHome = (e) => {
        e.preventDefault();
        navigate('/api-regression');
    }
    return (
        <div className="api-breadcrumb">
            {
                isRoot ? (
                    <div className="align-center">
                        <h1 className="heading-text ml-2">APIGlide</h1>
                    </div>
                ) :
                    (
                        <a onClick={e => navigateToHome(e)}>
                            <div className="flex align-center">
                                <div className="align-center mr-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 14 24" fill="none">
                                        <path d="M11.1073 23.5383L0.675978 13.107C0.530108 12.9617 0.414368 12.7889 0.335395 12.5987C0.256421 12.4085 0.215767 12.2046 0.215767 11.9987C0.215767 11.7928 0.256421 11.5888 0.335395 11.3987C0.414368 11.2085 0.530108 11.0357 0.675978 10.8904L11.1073 0.459083C11.4012 0.165137 11.7999 1.92838e-08 12.2156 1.87067e-08C12.6313 1.81296e-08 13.03 0.165137 13.3239 0.459083C13.6179 0.753029 13.783 1.15171 13.783 1.56741C13.783 1.98311 13.6179 2.38179 13.3239 2.67573L3.99965 12L13.3252 21.3243C13.6192 21.6182 13.7843 22.0169 13.7843 22.4326C13.7843 22.8483 13.6192 23.247 13.3252 23.5409C13.0313 23.8349 12.6326 24 12.2169 24C11.8012 24 11.4025 23.8349 11.1086 23.5409L11.1073 23.5383Z" fill="#1D4273" />
                                    </svg>
                                </div>
                                <div className="flex align-center">
                                    {
                                        apiType && apiType == 'CHAINED' && (
                                            <svg className="mr-3" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g id="Group 216">
                                                    <rect id="Rectangle 915" width="24" height="24" rx="3" fill="#26A5A5" />
                                                    <path id="Vector" d="M17.3887 5.488C16.7384 4.83711 15.6829 4.83744 15.0316 5.48834L11.9681 8.55163C11.9678 8.55196 11.9675 8.55263 11.9668 8.5533C11.3175 9.20436 11.3187 10.2587 11.9688 10.9096L12.5575 11.4978L11.4971 12.5584L10.9085 11.9702C10.2578 11.3193 9.20201 11.3191 8.55135 11.9705L5.48739 15.0333C5.48739 15.0337 5.48706 15.0337 5.48672 15.0343C4.83707 15.6859 4.8379 16.741 5.48839 17.3912L7.61036 19.5117C8.26119 20.1628 9.31668 20.1628 9.9675 19.5117L13.0315 16.4477C13.0318 16.4474 13.0321 16.4471 13.0328 16.4464C13.6818 15.7955 13.6811 14.7412 13.0308 14.0905L12.4416 13.5019L13.502 12.4411L14.0914 13.0298C14.7419 13.6806 15.7974 13.6811 16.4483 13.0295L19.5122 9.96622C19.5129 9.96588 19.5132 9.96521 19.5135 9.96454C20.1629 9.31365 20.1622 8.25902 19.5112 7.60829L17.3887 5.488ZM8.78735 18.3277L6.66521 16.216L9.73167 13.154L10.3183 13.7374L9.73084 14.3253C9.73084 14.3253 9.7305 14.3253 9.73017 14.3256C9.47034 14.5866 9.46967 15.0096 9.72984 15.2706C9.99 15.5318 10.4123 15.5325 10.6727 15.2713L11.265 14.6787L11.8541 15.2648L8.78735 18.3277ZM15.2679 11.8464L14.6806 11.2622L15.2683 10.6741C15.2683 10.6741 15.2686 10.6738 15.2689 10.6738C15.5291 10.4126 15.5294 9.99011 15.2693 9.72875C15.0091 9.46739 14.5869 9.46706 14.3264 9.72775L13.734 10.3205L13.1453 9.73494L16.2121 6.67198L18.3347 8.78308L15.2679 11.8464Z" fill="white" />
                                                </g>
                                            </svg>
                                        )
                                    }


                                    <h1 className="h1-heading ml-2">{currentPageName}</h1>
                                </div>
                            </div>
                        </a>
                    )
            }
        </div>
    )
}