import { useState, useEffect } from "react"
import utilsService from "../../../services/utils.service"
import licenseService from "../../../services/license.service"
import SectionLoader from "../../atoms/SectionLoader"
let failedLimitVal = 7;
try {
    failedLimitVal = process.env.FAIL_COUNT_LIMIT
} catch (ex) {

}
export default ({ activatedLicense, onSuccessLicense }) => {
    const [pingData, setPingData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [licenseKey, setLicenseKey] = useState('')
    const [licenseKeyError, setlicenseKeyError] = useState(false)
    const [invalidKeyError, setinvalidKeyError] = useState(false)
    const [enableLicenceUpdate, setenableLicenceUpdate] = useState(false)
    useEffect(() => {
        licenseService.getLicensePings({
            _id: activatedLicense._id
        }).then(apiResp => {
            const { data = [], } = apiResp || {};
            const { items, failedCount = 0, totalCount = 0 } = data;
            setPingData({
                totalCount: totalCount,
                failedCount: failedCount
            });
            setLoading(false)
        })
    }, [activatedLicense._id])
    const handleActivateKey = () => {
        if (!(licenseKey && licenseKey.length > 0)) {
            setlicenseKeyError(true)
            return
        }
        setLoading(true)
        licenseService.activatelicense({
            licenseKey
        }).then(apiResp => {
            const { data = {} } = apiResp || {};
            const { customerId, expiryDate } = data;
            if (customerId) {
                setLoading(true)
                setenableLicenceUpdate(false)
                onSuccessLicense && onSuccessLicense();
            } else {
                setinvalidKeyError(true)
            }
        }).finally(() => {
            setLoading(false)
        })
    }
    return (
        <div className="">
            <div className="flex align-center mb-2">
                <div className="para-1 mr-2">License Key:</div>
                <div className="para-2">
                    {activatedLicense.licenseKey}
                    {
                        !enableLicenceUpdate && (
                            <a href="" className="ml-4 btn-link" onClick={e => {
                                e.preventDefault();
                                setLicenseKey('')
                                setinvalidKeyError(false)
                                setlicenseKeyError(false)
                                setenableLicenceUpdate(true)
                            }}>Update</a>
                        )
                    }

                </div>
            </div>
            <div className="flex align-center mb-2">
                <div className="para-1 mr-2">Customer Id:</div>
                <div className="para-2">{activatedLicense.customerId}</div>
            </div>
            <div className="flex align-center mb-2">
                <div className="para-1 mr-2">Expiry Date:</div>
                <div className="para-2">{utilsService.getDisplayDateTime(activatedLicense.expiryDate, true, false)}</div>
            </div>
            <div className="flex align-center mb-2">
                <div className="para-1 mr-2">Days to expire:</div>
                <div className="para-2">{utilsService.getDaysToTillDate(activatedLicense.expiryDate)}</div>
            </div>
            {
                activatedLicense.isDisabled && (
                    <div className="flex align-center mb-2">
                        <div className="para-1 mr-2 error-message">Alert:</div>
                        <div className="para-2 error-message">Your license is disabled, please reach out to Astraq support team</div>
                    </div>
                )
            }
            {
                pingData && (
                    <>
                        {
                            !enableLicenceUpdate && (
                                <>
                                    <div className="flex align-center mb-2">
                                        <div className="para-1 mr-2">Failed pings:</div>
                                        <div className="para-2">
                                            {pingData.failedCount}
                                        </div>
                                    </div>

                                    <div className="flex align-center mb-2">
                                        <div className="para-1 mr-2">Total pings:</div>
                                        <div className="para-2">{pingData.totalCount} </div>
                                    </div>
                                </>
                            )
                        }
                        {
                            (pingData.failedCount > 0 || enableLicenceUpdate) && (
                                <>
                                    {
                                        ((failedLimitVal - pingData.failedCount) > 0 && !enableLicenceUpdate) ? (
                                            <>
                                                <div className="flex align-center mb-2">
                                                    <div className="para-1 mr-2 warn-message">Warning:</div>
                                                    <div className="para-2 warn-message">
                                                        {(failedLimitVal - pingData.failedCount) + ' remaining failed pings, please check with AstraQ support team.'}
                                                    </div>
                                                </div>

                                            </>
                                        ) : (
                                            <>
                                                {
                                                    !enableLicenceUpdate && (
                                                        <div className="flex align-center mb-2">
                                                            <div className="para-1 mr-2 error-message">Alert:</div>
                                                            <div className="para-2 error-message">
                                                                {'Your license validation has failed. Please reach out to Astraq support team'}
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                <div className="mt-3 license-form">
                                                    <div className={`mb-4 ` + (licenseKeyError ? 'error ' : '')}>
                                                        <label className="white-space-no-wrap mr-2 bold w-full d-block mb-2 label">
                                                            {enableLicenceUpdate ? 'New ': ''}License Key
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder="xxxx-xxxx-xxxx-xxxx"
                                                            value={licenseKey}
                                                            onChange={e => {
                                                                setlicenseKeyError(false)
                                                                setLicenseKey(e.target.value)
                                                            }}
                                                            className={`input-field w-full p-2 input-txt ` + ((licenseKeyError || invalidKeyError) ? ' invalid' : '')}
                                                        />
                                                        {
                                                            (licenseKeyError || invalidKeyError) && (
                                                                <>
                                                                    {
                                                                        invalidKeyError ? (
                                                                            <p className="error-message">Invalid license key, please double check it.</p>
                                                                        ) : (
                                                                            <p className="error-message">This is required field</p>
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                    <div className="flex justify-between align-center">
                                                        <button
                                                            disabled={loading}
                                                            onClick={handleActivateKey}
                                                            type="button"
                                                            className="btn-medium-2 btn-medium-width">
                                                            Activate now
                                                        </button>
                                                        {
                                                            enableLicenceUpdate && (
                                                                <a href="" className="ml-4 btn-link" onClick={e => {
                                                                    e.preventDefault();
                                                                    setenableLicenceUpdate(false)
                                                                }}>Cancel</a>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </>
                            )
                        }

                    </>
                )
            }
            {
                loading && (
                    <SectionLoader />
                )
            }
        </div>
    )
}