import React from 'react';
import { ChevronIcon } from "../ChevronIcon/ChevronIcon";

const TreeDropdown = ({handleToggleView, treeViewSelected}) => {
  return (
    <div>
        
    <div className="page-action-toggle-tree-view">
        <button
          type="button"
          class="btn-medium-2 m-2"
          onClick={handleToggleView}
        >
          {!treeViewSelected ? ('Switch to Tree View') : ('Switch to Default View') }
        </button>
      </div>
    </div>
  )
}

export default TreeDropdown
