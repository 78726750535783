import React, { useState, useEffect } from "react";
import AppIcon from "../atoms/AppIcon.jsx";
import { useNavigate } from 'react-router-dom';
import storeService from '../../services/store.service.js';
import utilsService from '../../services/utils.service.js';
import dataService from "../../services/data.service.js";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

export default () => {
  const [formData, setFormData] = useState({
    apiType: "",
    title: "",
    description: "",
    selectedTags: [],
    testDataId: null,
    apiSteps: [],
    chainApiIds: []
  });
  const [chainApiStep, setChainApiStep] = useState(1);
  const [showApiChoiceError, setShowApiChoiceError] = useState(false);
  const [createFormError, setCreateFormError] = useState(false)
  const [selectedTestData, setSelectedTestData] = useState(null)
  const [apisCount, setApiCount] = useState([
    { label: "API 1", id: "1" },
    { label: "API 2", id: "2" }
  ]);
  const [collections, setCollections] = useState([])
  const [testdataList, setTestdataList] = useState([])
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [stepsError, setStepsError] = useState(false)
  const [selectedTags, setSelectedTags] = useState([])
  const navigate = useNavigate();
  const closeChainAPIModal = () => {
    storeService.pub(utilsService.CONSTANTS.EVENT_NAME.OPEN_CHAIN_API_MODAL, false);
  };

  const tags = [];

  const createTestData = data => {
    setFormData({ ...formData, testDataId: data.value });
  };
  const validateForm = (updatedFormData)=> {
    let formD = updatedFormData || formData;
    if(formD.title && formD.title.length > 0 && 
    formD.apiSteps.map(x => x.value).length> 1) {
      setCreateFormError(false);
      setDisableSubmit(false)
    }
  }
  const handleformSubmit = async () => {
    setDisableSubmit(true);
    let collectionToAdd = {
      name: formData.title,
      description: formData.description,
      testDataId: (selectedTestData && selectedTestData.value) || '',
      tags: selectedTags.map(x => x.value),
      apiType: 'CHAINED',
      chainApiIds: formData.apiSteps.map(x => x.value)
    };
    if(collectionToAdd.name && collectionToAdd.name.length > 0 && 
      collectionToAdd.chainApiIds.length> 1) {
      await dataService.addCollections({
        "items": [
          collectionToAdd
        ]
      });
      dataService.getCollectionsWithDetails();
      closeChainAPIModal()
    } else {
      setCreateFormError(true);
      setNameError(!(collectionToAdd.name && collectionToAdd.name.length > 0))
      setStepsError(!(collectionToAdd.chainApiIds.length > 1))
    }
  };

  const apiSelected = (api, index) => {
    const updatedSteps = [...formData.apiSteps];
    const isNewAPI = typeof api === "string";
    if (isNewAPI) {
      updatedSteps[index] = {
        value: api, label: api
      };
    } else {
      updatedSteps[index] = api
    }
    let updateForm = { ...formData, apiSteps: updatedSteps };
    setFormData(updateForm);
    validateForm(updateForm)
    if(updatedSteps.length> 1){
      setStepsError(false)
    }
  };

  const selectApiType = apiType => {
    setFormData({
      ...formData,
      apiType: apiType,
    });
    setShowApiChoiceError(false);
  };

  const createAPI = () => {
    if (formData.apiType == "single") {
      closeChainAPIModal()
      navigate(`/api-regression/create-collection`);
    } else if (formData.apiType == "chain") {
      setChainApiStep(2);
    } else {
      setShowApiChoiceError(true);
    }
  };

  useEffect(() => {
    let pageStore = storeService.getStore('COLLECTIONS_WITH_DETAILS');
    storeService.subscribe('COLLECTIONS_WITH_DETAILS', ({ detail }) => {
      setCollections(detail.items);
    });
    if (!pageStore.loaded) {
      dataService.getCollectionsWithDetails();
    } else {
      setCollections(pageStore.items);
    }
    let testDataStore = storeService.getStore('API_TEST_DATA');
    if (!testDataStore.loaded) {
      storeService.subscribe('API_TEST_DATA', ({ detail }) => {
        setTestdataList(detail.items);
      });
      dataService.getApiTestData();
    } else {
      setTestdataList(testDataStore.items);
    }
  }, [])
  const getChainApiStepText = step => {
    switch (step) {
      case 1:
        return "Select APIGlide Type to Test";
      case 2:
        return "Create API Chain";
      default:
        return "Select API Type";
    }
  };

  const loadAPIDropdown = () => {
    return apisCount.map((api, index) => (
      <div key={api.id} className="mb-4 pl-4">
        <label className="relative step-indicator d-block bold font-urbanist" htmlFor={api.id}>API {index + 1}</label>
        <CreatableSelect
          options={collections.map(collection => ({ value: collection._id, label: collection.name }))}
          value={formData?.apiSteps[index] ? formData?.apiSteps[index] : { value: "", label: "Select API" }}
          onChange={api => apiSelected(api, index)}
          onCreateOption={api => apiSelected(api, index)}
        />
      </div>
    ))
  };

  const handleCreateTag = (tagName) => {
    setSelectedTags([...selectedTags, { value: tagName, label: tagName }])
  }

  return (
    <div className="app-modal">
      <div className="app-modal__content select-api">
        <div className="add-page-comp">
          <div className="add-page-comp__head px-8 py-6">
            <div className="flex justify-between">
              <h3 className="h3-heading">
                {getChainApiStepText(chainApiStep)}
              </h3>
              <button
                onClick={closeChainAPIModal}
                className="no-border bg-none"
              >
              <svg xmlns="http://www.w3.org/2000/svg" className="close-svg-icon" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path d="M21.5365 19.3139C21.8316 19.609 21.9974 20.0092 21.9974 20.4265C21.9974 20.8438 21.8316 21.2441 21.5365 21.5391C21.2414 21.8342 20.8411 22 20.4238 22C20.0065 22 19.6062 21.8342 19.3111 21.5391L11 13.2259L2.68626 21.5365C2.39116 21.8316 1.99092 21.9974 1.57358 21.9974C1.15624 21.9974 0.755993 21.8316 0.46089 21.5365C0.165787 21.2414 6.21883e-09 20.8412 0 20.4239C-6.21883e-09 20.0066 0.165787 19.6064 0.46089 19.3113L8.77463 11.0007L0.463508 2.68741C0.168405 2.39233 0.00261814 1.99211 0.00261815 1.57479C0.00261815 1.15748 0.168405 0.757257 0.463508 0.462172C0.758611 0.167086 1.15886 0.00130875 1.5762 0.00130875C1.99353 0.00130874 2.39378 0.167086 2.68888 0.462172L11 8.77541L19.3137 0.460862C19.6088 0.165777 20.0091 -6.95245e-09 20.4264 0C20.8438 6.95245e-09 21.244 0.165777 21.5391 0.460862C21.8342 0.755948 22 1.15617 22 1.57348C22 1.9908 21.8342 2.39102 21.5391 2.6861L13.2254 11.0007L21.5365 19.3139Z" fill="#1B72E6" />
              </svg>
              </button>
            </div>
            {chainApiStep === 1 ? (
              <div className="chain-api-step-1">
                <label className="rectangle-block my-8 p-6 flex justify-between align-baseline pointer">
                  <div>
                    <p className="sub-heading-2">Create API Collection</p>
                    <p className="pt-2">
                      Use this option to create detailed test cases for a single API by entering API Definiton.
                    </p>
                  </div>
                  <input
                    className="pt-5 collection-radio-option"
                    type="radio"
                    name="apitype"
                    value="single"
                    onChange={() => selectApiType("single")}
                  /> 
                </label>
                <label className="rectangle-block my-8 p-6 flex justify-between align-baseline pointer">
                  <div>
                    <p className="sub-heading-2">Create API Chain</p>
                    <p className="pt-2">
                      Use this option to chain 2 APIs where output of the 1st API is passed as the input to the 2nd API
                    </p>
                  </div>
                  <input
                    className="collection-radio-option"
                    type="radio"
                    name="apitype"
                    value="chained"
                    onChange={() => selectApiType("chain")}
                  />
                </label>
                <p
                  className={`error-text text-center m-4 ${showApiChoiceError ? "" : "visibility-hidden"
                    }`}
                >
                  Please select the type of API to create
                </p>
                <div className="button-layout mt-8">
                  <button
                    className="btn-medium-2 btn-medium-width"
                    onClick={() => createAPI()}
                  >
                    Next
                  </button>
                </div>
              </div>
            ) : null}
            {chainApiStep === 2 ? (
              <div className="chain-api-step-2">
                <div className="chain-api-step-2__scroll p-2">
                  <div className="mt-4">
                    <label
                      htmlFor="title"
                      className="d-block bold font-urbanist mb-1"
                    >
                      Title
                    </label>
                    <input
                      id="title"
                      type="text"
                      name="titleInput"
                      onChange={(e) => {
                        let updatedForm = { ...formData, title: e.target.value };
                        setFormData(updatedForm)
                        validateForm(updatedForm)
                        setNameError(false)
                      }}
                      className={'w-full p-2 ' + (nameError? 'invalid': '')}
                    />
                    {
                      nameError && (
                        <span className="error-message">This is required field.</span>
                      )
                    }

                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="description"
                      className="d-block bold font-urbanist mb-1"
                    >
                      Description
                    </label>
                    <textarea
                      id="description"
                      onChange={(e) =>
                        setFormData({ ...formData, description: e.target.value })
                      }
                      rows={5}
                      name="descriptionInput"
                      className="w-full p-2"
                    />
                  </div>
                  <div className="mt-4">
                    <p className="bold font-urbanist mb-1">Configure API Chain</p>
                    <div className={'steps p-8 flex ' + (stepsError ? 'invalid': '')}>
                      <div className="steps_bg"></div>
                      <div className="steps_details w-11/12">
                        {loadAPIDropdown()}
                        <button
                          className="steps_create ml-4 p-2 no-border step-indicator relative disabled"
                          disabled
                          onClick={() =>
                            setApiCount([
                              ...apisCount,
                              { label: "API", id: `${apisCount.length + 1}` },
                            ])
                          }
                        >
                          Add Next API
                        </button>
                        <span className="ml-2">Multi level chained API is disabled for now.</span>
                      </div>
                    </div>
                    {
                      stepsError && (
                        <span className="error-message">This is required field. please select atleast two chained APIs.</span>
                      )
                    }
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="test-data"
                      className="d-block bold font-urbanist mb-1"
                    >
                      Select Test Data
                    </label>
                    {/* <CreatableSelect
                      id="test-data"
                      options={testdataList.map(data => ({ value: data._id, label: data.name }))}
                      onChange={(data) => createTestData(data)}
                      onCreateOption={(data) => createTestData({ value: data, label: data })}
                    /> */}
                    <Select
                      value={selectedTestData}
                      onChange={setSelectedTestData}
                      name="testdata"
                      options={testdataList.map(x => ({ label: x.name, value: x._id }))}
                      className="basic-select"
                      classNamePrefix="select"
                    />
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="tags"
                      className="d-block bold font-urbanist mb-1"
                    >
                      Create or Select Tags
                    </label>
                    <CreatableSelect
                          value={selectedTags}
                          isMulti
                          onChange={setSelectedTags}
                          onCreateOption={handleCreateTag}
                          name="colors"
                          options={[].map(x => ({ value: x, label: x }))}
                          className="basic-multi-select"
                          classNamePrefix="select"
                      />
                  </div>
                </div>
                <div className="mt-4 flex justify-between align-center">
                  <div>
                    {
                      createFormError && (
                        <span className="server-error-message">Please fix the errors in the form.</span>
                      )
                    }
                  </div>
                  <button type="button" disabled={disableSubmit} className="btn-medium-2 btn-medium-width" onClick={() => handleformSubmit()}>Create</button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
