import { useEffect, useState } from "react";
import workflowsService from "../../../services/workflows.service.js";
import storeService from "../../../services/store.service.js";
import PageActionJob from "./PageActionJob.jsx";
import { Tooltip } from 'react-tooltip';
import SvgCode from "../../atoms/SvgCode.jsx";
import ScheduleButton from "../../molecules/ScheduleButton.jsx";
const JOB_STATUS = {
    THRESHOLD_VALUE: 3000,
    SUCCESS: "Passed",
    FAILURE: "Failed"
}
// Function to update the job status
const checkPerformance = (metrics = []) => {
    try {
        const propertiesToCheck = ['fcp', 'ttfb', 'ntt', 'lcp', 'ncp', 'pageLoadTime'];
        for (const prop of propertiesToCheck) {
            const value = metrics[prop] ? parseInt(metrics[prop]) : 0;
            if (!isNaN(value) && value > JOB_STATUS.THRESHOLD_VALUE) {
                return JOB_STATUS.FAILURE;
            }
        }
        return JOB_STATUS.SUCCESS;
    } catch (e) {
        return JOB_STATUS.FAILURE;
    }
};
export default (props) => {
    const { page, index, paginationData, currentPage, handleToggleChecked, handleToggleAllChecked, selectedItems, selectedDate } = props;
    const [loading, setloading] = useState(true)
    const [localDate, setLocalDate] = useState(null);
    const [workflow, setworkflow] = useState(null);
    const [workflowId, setworkflowId] = useState(null);
    const [randomId, setrandomId] = useState(null)
    const [scheduledDevices, setscheduledDevices] = useState([])
    const [failedDevices, setfailedDevices] = useState([])
    const [deviceList, setdeviceList] = useState([])
    const [allowedDevices, setAlloweedDevices] = useState([])
    const renderWorkflowStatus = (job) => {
        let status = '-'
        if (!job) return status;
        if (job.status == 'FAILED' || (job.analysisStatus || '').toUpperCase() == 'FAILED') {
            return 'Failed';
        }
        if (job.status == 'IN_PROGRESS') {
            return 'Running';
        }
        if (job.analysisStatus == 'IN_PROGRESS') {
            return 'Analyzing';
        }
        if (job.status == 'TODO' || (job.analysisStatus || '').toUpperCase() == 'TODO') {
            return 'Waiting';
        }
        if (job.status == 'DONE' && (job.analysisStatus || '').toUpperCase() == 'DONE') {
            if (job.results && job.results.length > 0) {
                return checkPerformance(job.results[0].metrics);
            }
            return 'Failed';
        }
        return status;
    }
    const updateDeviceData = (workflow) => {
        workflow.filterDevices = workflow.devices;
        if (storeService.store.WORKFLOWS_WITH_DETAILS.filterData.selectedDevices.length > 0) {
            workflow.filterDevices = workflow.devices.filter(device => {
                if ((storeService.store.WORKFLOWS_WITH_DETAILS.filterData.selectedDevices).find(sItem => sItem.value == device._id)) {
                    return true;
                }
                return false;
            })
            let sDevices = (props.scheduledDevices || scheduledDevices || []);
            if (sDevices.length > 0) {
                workflow.filterDevices = workflow.filterDevices.filter(device => {
                    return sDevices.find(x => x == device._id);
                })
            }
            let fDevices = (props.failedDevices || failedDevices || []);
            if (fDevices.length > 0) {
                workflow.filterDevices = workflow.filterDevices.filter(device => {
                    return fDevices.find(x => x == device._id);
                })
            }
        }
        workflow.notRunYetDevicesCount = workflow.filterDevices.filter(device => {
            if (device.jobs?.length == 0) return true;
            let recentJob = device.jobs?.[0];
            if (recentJob) {
                return (recentJob.status == 'TODO' || recentJob.status == 'READY') && (recentJob.analysisStatus == 'TODO' || recentJob.analysisStatus == 'READY');
            }
            return false;
        }).length || 0;
        workflow.inProgressCount = workflow.filterDevices.filter(device => {
            if (device.jobs?.length == 0) return false;
            let recentJob = device.jobs?.[0];
            if (recentJob) {
                if (recentJob.status == 'IN_PROGRESS') return true;
                if (recentJob.status == 'DONE') {
                    return (recentJob.analysisStatus == 'IN_PROGRESS' || recentJob.analysisStatus == 'TODO' || recentJob.analysisStatus == 'READY');
                }
            }
            return false;
        }).length || 0;

        workflow.failedDevicesCount = workflow.filterDevices.filter(device => {
            if (device.jobs?.length == 0) return false;
            let recentJob = device.jobs?.[0];
            if (recentJob) {
                return recentJob.status == 'FAILED' || (recentJob.analysisStatus || '').toUpperCase() == 'FAILED';
            }
            return false;
        }).length || 0;

        workflow.passedDevicesCount = workflow.filterDevices.filter(device => {
            if (device.jobs?.length == 0) return false;
            let recentJob = device.jobs?.[0];
            if (recentJob) {
                return recentJob.status == 'DONE' && (recentJob.analysisStatus || '').toUpperCase() == 'DONE';
            }
            return false;
        }).length || 0;
        setworkflow(workflow);
        setdeviceList([...workflow.filterDevices])
    }
    const reloadJobs = (date) => {
        if (workflow && workflow._id) {
            workflowsService.getjobsbyuserflowid({ userflowId: workflow._id, selectedDate: date }).then(resp => {
                if (resp.data) {
                    let jobs = resp.data.filter(x => x.job).map(x => x.job);
                    workflow.devices.forEach(device => {
                        device.jobs = jobs.filter(x => x.deviceId === device._id);
                        device.randomId = Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
                        device.recentJobStatus = renderWorkflowStatus(device.jobs?.[0]);
                        device.finalPriority = device.jobs?.[0]?.finalPriority;
                    });
                    updateDeviceData(workflow);
                }
                setloading(false)
            });
        }
    }
    useEffect(() => {
        if (workflow) {
            reloadJobs(localDate);
        }
    }, [workflow])
    useEffect(() => {
        if (localDate != selectedDate) {
            setLocalDate(selectedDate)
            reloadJobs(selectedDate);
        }
    }, [selectedDate])
    useEffect(() => {
        let isUpdateDevices = false;
        if (allowedDevices.length != props.filterData.selectedDevices.length) {
            isUpdateDevices = true;
        } else {
            let newDeviceIds = props.filterData.selectedDevices.map(x => x.value);
            let existDeviceIds = allowedDevices.map(x => x.value);
            let pendingDeviceIds = newDeviceIds.filter(x => existDeviceIds.indexOf(x) <= 0);
            if (pendingDeviceIds.length > 0) {
                isUpdateDevices = true;
            }
        }
        if (isUpdateDevices && workflow) {
            setAlloweedDevices(props.filterData.selectedDevices);
            updateDeviceData(workflow)
        }
    }, [props.filterData])
    useEffect(() => {
        if (props.page) {
            let workflowItem = props.page;
            if (workflowId != workflowItem._id) {
                setworkflowId(workflowItem._id);
                setworkflow({
                    name: workflowItem.name,
                    _id: workflowItem._id,
                    status: workflowItem.status,
                    devices: workflowItem.devices,
                    passedDevicesCount: 0,
                    failedDevicesCount: 0,
                    inProgressCount: 0,
                    notRunYetDevicesCount: 0,
                    steps: workflowItem.steps,
                    isScheduled: workflowItem.isScheduled
                })
            }
        }
    }, [props.page])
    const isDifferentList = (list1, list2) => {
        return list1.length != list2.length
    }
    useEffect(() => {
        setscheduledDevices(props.scheduledDevices || []);
        if (workflow) {
            updateDeviceData(workflow)
        }
    }, [props.scheduledDevices])
    useEffect(() => {
        setfailedDevices(props.failedDevices || [])
        if (workflow) {
            updateDeviceData(workflow);
        }
    }, [props.failedDevices])
    useEffect(() => {
        if (props.page?.randomId && randomId !== props.page?.randomId) {
            setrandomId(props.page?.randomId)
            if (workflow) {
                reloadJobs(localDate);
            }
        }
    }, [props.page?.randomId])
    if (!workflow) {
        return null;
    }
    return (
        <>
            <tr className="table-row-title">
                <td colSpan="9" className="table-row-details">
                    <div className={`flex justify-between ${loading ? 'flow-item-overview' : ''}`}>
                        <div className="flex-basis-60">
                            <div className="align-center">
                                <div
                                    className="align-center mr-2 font-1rem pointer w-100"
                                    onClick={() => {
                                        props.handleClick();
                                    }}
                                >
                                    <p className="mr-2 table-heading pageblazer-table-text w-90" id={'text-' + (((paginationData.pageNo - 1) * paginationData.pageSize) + (1)) + index}>
                                        {(((paginationData.pageNo - 1) * paginationData.pageSize) + (1)) + index}. {workflow.name}
                                    </p>
                                    <Tooltip
                                        anchorSelect={'#text-' + (((paginationData.pageNo - 1) * paginationData.pageSize) + (1)) + index}
                                        content={workflow.name}
                                        place="top"
                                        style={{
                                            maxWidth: "500px"
                                        }}
                                    />
                                    <p>
                                        <svg
                                            className="table-svg-arrow"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="12"
                                            viewBox="0 0 18 12"
                                            fill="none"
                                        >
                                            {workflow._id === currentPage ? (
                                                <path
                                                    d="M0.34627 9.08042L8.16974 1.25695C8.27876 1.14755 8.40831 1.06074 8.55095 1.00151C8.69359 0.942283 8.84653 0.911793 9.00098 0.911793C9.15543 0.911793 9.30836 0.942283 9.45101 1.00151C9.59365 1.06074 9.7232 1.14755 9.83222 1.25695L17.6557 9.08042C17.8761 9.30088 18 9.59989 18 9.91166C18 10.2234 17.8761 10.5224 17.6557 10.7429C17.4352 10.9634 17.1362 11.0872 16.8244 11.0872C16.5127 11.0872 16.2137 10.9634 15.9932 10.7429L9 3.7497L2.0068 10.7439C1.78634 10.9643 1.48734 11.0882 1.17556 11.0882C0.863781 11.0882 0.564774 10.9643 0.344315 10.7439C0.123856 10.5234 7.5514e-08 10.2244 1.0277e-07 9.91264C1.30027e-07 9.60086 0.123856 9.30185 0.344315 9.08139L0.34627 9.08042Z"
                                                    fill="#1B72E6"
                                                ></path>
                                            ) : (
                                                <path
                                                    d="M17.6537 2.91958L9.83027 10.743C9.72124 10.8524 9.59169 10.9393 9.44905 10.9985C9.30641 11.0577 9.15347 11.0882 8.99902 11.0882C8.84457 11.0882 8.69164 11.0577 8.54899 10.9985C8.40635 10.9393 8.2768 10.8524 8.16778 10.743L0.344312 2.91958C0.123853 2.69912 -3.2851e-09 2.40011 0 2.08834C3.2851e-09 1.77656 0.123853 1.47755 0.344312 1.25709C0.564772 1.03663 0.863779 0.912782 1.17556 0.912782C1.48733 0.912782 1.78634 1.03663 2.0068 1.25709L9 8.25029L15.9932 1.25612C16.2137 1.03566 16.5127 0.911804 16.8244 0.911804C17.1362 0.911804 17.4352 1.03566 17.6557 1.25612C17.8761 1.47658 18 1.77558 18 2.08736C18 2.39914 17.8761 2.69814 17.6557 2.9186L17.6537 2.91958Z"
                                                    fill="#1B72E6"
                                                ></path>
                                            )}
                                        </svg>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-content-end flex-basis-40">
                            <ScheduleButton display="ICON" mode="WORK_FLOW_LIST" randomId={randomId} workflowId={workflow._id} />
                            <span className="caption-1 ml-4">Devices - {workflow?.filterDevices?.length || 0}</span>
                            <span className='body-4 ml-6'>Pass - {workflow?.passedDevicesCount}</span>
                            <span className='body-4 ml-6'>Fail - {workflow?.failedDevicesCount}</span>
                            <span className='body-4 ml-6'>In Progress - {workflow?.inProgressCount || 0}</span>
                            <span className='body-4 ml-6'>Not Run Yet - {workflow?.notRunYetDevicesCount}</span>
                        </div>
                    </div>
                </td>
            </tr>
            <>
                {workflow._id === currentPage ? (
                    <tr className={`table-row-subtitle ${loading ? 'flow-item-overview' : ''}`}>
                        <td colSpan={3}>
                            <div className="align-center">
                                <span className="mr-1 table-subheading">Devices</span>
                            </div>
                        </td>
                        <td>
                            <div className="align-center">
                                <span className="mr-1 table-subheading">Last Run</span>
                            </div>
                        </td>

                        <td>
                            <div className="align-center">
                                <span className="mr-1 table-subheading">Status</span>
                            </div>
                        </td>

                        <td className='checkbox-table-cell'>
                            <label className={'form-field-checkbox ' + (workflow.status == 'IN_PROGRESS' ? 'disabled' : '')}>
                                <input type="checkbox"
                                    className={(workflow.status == 'IN_PROGRESS' ? 'disabled' : '')}
                                    checked={selectedItems.isCheckedAll}
                                    onChange={e => handleToggleAllChecked(e, page)}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </td>
                    </tr>
                ) : null}
                {workflow._id === currentPage &&
                    deviceList?.map((device) => {
                        return (
                            <PageActionJob
                                key={device?._id}
                                recentJobStatus={device.recentJobStatus}
                                finalPriority={device.finalPriority}
                                randomId={device.randomId}
                                workflowId={workflow._id}
                                deviceId={device._id}
                                data={{
                                    page: workflow,
                                    currentPage,
                                    device,
                                    selectedItems,
                                    handleToggleAllChecked,
                                    handleToggleChecked,
                                    refId: page?.refId
                                }}
                            />
                        );
                    })}
            </>
        </>

    )
}