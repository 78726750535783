import apiService from './api.service.js'
import utils from './utils.service.js'
import storeService from './store.service.js';
export default {
    async getlicenses(payload = {}) {
        return await apiService.post({
            url: utils.END_POINTS.getlicenses,
            data: payload
        });
    },
    async loadlicense(payload) {
        return this.getlicenses(payload).then(apiResp => {
            if (apiResp && apiResp.data) {
                storeService.saveStore({
                    items: (apiResp.data?.items || []).map(x => {
                        x['loadedGuid'] = utils.guid(8);
                        return x;
                    }),
                    totalCount: apiResp.data?.totalCount || 0,
                    lastLoaded: new Date(),
                    loaded: true,
                    isLoading: false,
                }, 'LICENSE_LIST');
            }
            return apiResp;
        })
    },
    async activatelicense(payload) {
        return await apiService.post({
            url: utils.END_POINTS.activatelicense,
            data: payload
        });
    },
    async getLicensePings(payload) {
        return await apiService.post({
            url: utils.END_POINTS.getlicensepings,
            data: payload
        });
    }
}