import React, { useEffect, useState } from "react";
import { ChevronIcon } from "../ChevronIcon/ChevronIcon";
import flowService from '../../../services//flow.service.js'
import TreeViewSection from "../../molecules/TreeViewSection.jsx";
import TreeViewSectionV2 from "../../molecules/TreeViewSectionV2.jsx";
const journeyCountList = new Array(9).fill(1).map((x, xi) => ({ value: 2 + (xi), label: 2 + (xi) }));
journeyCountList.push({ value: 'ALL', label: 'All' })
const checkAndReturn = (valToCheck, prefix) => {
  if (valToCheck) {
    return prefix + valToCheck;
  }
  return "";
};
const getStepMessage = (step) => {
  if (step.actionType === "OPEN_URL") {
    return "Url: " + step.pageUrl;
  }
  if (step.actionType === "INPUT") {
    return "Input value: " + step.elementValue;
  }
  if (
    ["CHECKBOX", "RADIO", "SELECT", "MULTI_SELECT"].indexOf(
      step.actionType
    ) >= 0
  ) {
    return "Selected " + step.elementValue;
  }
  if (["ANCHOR_CLICK", "BUTTON_CLICK"].indexOf(step.actionType) >= 0) {
    //  + (step.elementLink && step.elementLink != '' ? ` (${step.elementLink})`: ``)
    return (
      <>
        <p>
          {
            "Click " +
            (step.elementLinkText ||
              step.elementText ||
              step.elementId ||
              step.elementName ||
              step.actionType)
          }
        </p>
        {
          step.elementLink && step.elementLink != '' && (
            <p>
              {
                `Url: ` + (step.elementLink && step.elementLink != '' ? ` ${step.elementLink}` : ``)
              }
            </p>
          )
        }
        <p>

        </p>
      </>
    );
  }
  return "";
};
const getStepsPath = (steps) => {
  let completePath = "";
  let totalNodeLen = steps.length;
  for (let i = 0; i < totalNodeLen; i++) {
    let stepData = steps[i];
    completePath += getStepName(stepData) + (stepData.no_of_users != null ? ` (${stepData.no_of_users})` : '') + (i < totalNodeLen - 1 ? " -> " : "");
  }
  return completePath;
};
const getStepName = (step) => {
  if (step.actionType === "OPEN_URL" || step.page_title) {
    return (
      "Open " +
      (step.page_title || step.pageUrl.substr(step.pageUrl.lastIndexOf("/") + 1))
    );
  }
  if (step.actionType === "INPUT") {
    return (
      "Input " +
      step.elementValue +
      checkAndReturn(
        step.elementText || step.elementId || step.elementName,
        " in "
      )
    );
  }
  if (
    ["CHECKBOX", "RADIO", "SELECT", "MULTI_SELECT"].indexOf(step.actionType) >=
    0
  ) {
    return "Select " + step.elementValue + " as " + step.elementName;
  }
  if (["ANCHOR_CLICK", "BUTTON_CLICK"].indexOf(step.actionType) >= 0) {
    return (
      "Click " +
      (step.elementLinkText ||
        step.elementText ||
        step.elementId ||
        step.elementName ||
        step.actionType)
    );
  }
  return step.pageName || step.actionType;
};
const PageJourney = ({ onRegressionSelect,
  journeyData,
  selectedSteps,
  treeViewSelected,
  displayType,
  recommendationId
}) => {
  const [tree, setTree] = useState(null)
  const [selectedList, setSelectedList] = useState([])
  const { data = [] } = journeyData || { data: [] };
  const [activeAction, setActiveAction] = useState(0);
  const [selectedStepList, setSelectedStepList] = useState([]);
  const [selectedJourneyLevel, setselectedJourneyLevel] = useState(2)
  const [filteredList, setfilteredList] = useState([])
  const [filterListCount, setfilterListCount] = useState(0)
  const [showTreeView, setshowTreeView] = useState(false)
  const [pathCount, setPathCount] = useState(0)
  useEffect(() => {
    if (selectedSteps && JSON.stringify(selectedSteps) !== JSON.stringify(selectedStepList)) {
      setSelectedStepList(selectedSteps);
    }
  }, [selectedSteps]);

  useEffect(() => {
    setshowTreeView(treeViewSelected)
    if (treeViewSelected) {
      if (journeyData) {
        let { tree: treeUpdated, count = 0 } = flowService.getJourneyList(journeyData.data);
        setPathCount(count)
        setTree(treeUpdated)
        onRegressionSelect([])
      }
    } else {
      if (data && data.length > 0) {
        let firstJourney = data[0];
        setJourneySteps(firstJourney);
      }
    }

  }, [treeViewSelected])

  const setJourneySteps = (journey) => {
    let pagesInJourney = journey.pages;
    if (pagesInJourney && pagesInJourney.length > 0) {
      let stepsToExecute = [];
      let regressionName = [];
      pagesInJourney.forEach(x => {
        if (x.steps && x.steps.length > 0) {
          stepsToExecute.push(x.steps[0])
          regressionName.push(x.steps[0].page_title);
        } else {
          stepsToExecute.push({
            action: x.page_title ? 'OPEN_URL' : x.actionType,
            url: x.pageURL,
            page_name: x.page_title,
            stepName: getStepName(x),

            element: x.elementName,
            elementId: x.elementId,
            xPath: x.elementXPath,

            value: x.elementValue,
          })
          regressionName.push(getStepName(x));
        }
      })
      if (stepsToExecute.length > 0) {
        onRegressionSelect([
          {
            regressionName: regressionName.join(' -> '),
            steps: stepsToExecute
          }
        ])
      }
    }
  }
  const switchPages = (index) => {
    setActiveAction(index);
    if (filteredList[index]) {
      setJourneySteps(filteredList[index]);
    }
  }
  const handleJourneyLevelChange = (e) => {
    let val = e.target.value;
    setselectedJourneyLevel(val)
  }
  useEffect(() => {
    if (displayType == 'treeview') {
     
    }
    if (data && data.length > 0) {
      let firstJourney = data[0];
      setJourneySteps(firstJourney);
    }
  }, [])
  useEffect(() => {
    let filterItems = data.filter(x => {
      if (selectedJourneyLevel == 'ALL') return true;
      if (x.journey_level) {
        if (x.journey_level == selectedJourneyLevel) {
          return true;
        }
        return false;
      }
      return true;
    })
    setfilterListCount(filterItems.length)
    setfilteredList([...filterItems])
    if (filterItems.length > 0) {
      setJourneySteps(filterItems[0]);
      setActiveAction(0)
    } else {
      onRegressionSelect([])
    }
  }, [selectedJourneyLevel])
  useEffect(() => {
    if (journeyData) {
      let { tree: treeUpdated, count = 0 } = flowService.getJourneyList(journeyData.data)
      setPathCount(count)
      setTree(treeUpdated)
    }
  }, [journeyData])
  const handleOnTestCaseUpdate = (list) => {
    setSelectedList(list)
    onRegressionSelect(list)
  }
  const handleLevelChange = (level) => {
    let { tree: treeUpdated, count = 0 } = flowService.getJourneyList(journeyData.data, level);
    setPathCount(count)
    setTree(treeUpdated)
  }
  if (displayType == 'treeview') {
    return (
      <TreeViewSectionV2 recommendationId={recommendationId} onRegressionSelect={handleOnTestCaseUpdate} selectedSteps={selectedSteps} handleLevelChange={handleLevelChange} />
    )
  }
  return (
    <>
      {
        showTreeView ? (
          <>
            {
              tree && (
                <TreeViewSection pathCount={pathCount} tree={tree} onRegressionSelect={handleOnTestCaseUpdate} selectedSteps={selectedSteps} handleLevelChange={handleLevelChange} />
              )
            }
          </>
        ) : (
          <div className="page-actions flex">
            <div className="page-actions-list w-50 useage-pulse-recom-modal page-journey-body">
              <div className="py-2 px-6 align-center top-sticky-scroll bg-white">
                <div className="flex align-center">
                  <div className="white-space-no-wrap mr-3">Select page level: </div>
                  <div className="form-field form-field--select align-center">
                    <select className="select-w-full" onChange={(event) => handleJourneyLevelChange(event)} value={selectedJourneyLevel}>
                      {
                        journeyCountList.map((x, xi) => {
                          return <option key={'option-' + xi} value={x.value}>{x.label}</option>
                        })
                      }
                    </select>
                    <ChevronIcon type="down" />
                  </div>
                </div>
                <div className="flex-1 flex justify-end align-center">Count: {filterListCount}</div>
              </div>
              <div className="page-actions-list-info">
                <ul>
                  {filteredList.map((page, actionIndex) => {
                    return (
                      <li
                        key={actionIndex}
                        className={`pl-8 pr-2 pt-2 pb-3 ${actionIndex === activeAction ? "active" : ""}`}
                        onClick={() => switchPages(actionIndex)}
                      >
                        <div className="flex justify-between align-center">
                          <p className="block sub-heading-3 page-actions-list-name">
                            {displayType == 'actioned' ? (getStepsPath(page.pages)) : page.journey_flow}
                          </p>
                          <ChevronIcon type="right" />
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="page-actions-regressions w-50 useage-pulse-recom-modal page-journey-body">
              <div className="page-actions-container">
                <div className="pl-3 pr-6 py-4 justify-between align-center w-full page-actions-regressions-title">
                  <p className="sub-heading-2">Select Automated Regression</p>
                </div>
                <div className="page-actions-regressions-list">
                  {filteredList[activeAction] ? (<ul>
                    {filteredList[activeAction]?.pages?.map((page, i) => {
                      return (
                        <li className="my-4 mx-3 p-3 justify-between align-center" key={i}>
                          <div className="w-70">
                            <p className="info-title pb-2 pr-4 sub-heading-3">
                              {getStepName(page)}
                            </p>
                            <p className="info-desc sub-heading-3 word-break-all">
                              {getStepMessage(page)}
                            </p>
                            {
                              page?.no_of_users && (
                                <p className="info-desc sub-heading-3">{page?.no_of_users} click</p>
                              )
                            }
                          </div>
                        </li>
                      )
                    })}
                  </ul>) : null}
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default PageJourney;
