import React, { useState } from "react";
import Select from "react-select";
import AppIcon from "./AppIcon.jsx";
import dataService from "../../services/data.service.js";
import AppModal from "../organisms/AppModal.jsx";

export default ({ checkedDevices, fetchUpdate, deviceOptions, osOptions, osVersionOptions, 
    browserVersionOptions, resolutionOptions, modeOptions, browserOptions, glassOptions, onClose }) => {
    const {
        _id,
        name,
        dimensions: {
            width,
            height
        },
        bstack: {
            deviceName,
            osVersion,
            os,
            browserName,
            browserVersion
        },
        deviceMode,
        deviceGlass,
    } = checkedDevices[0];
  const [deviceDetails, setDeviceDetails] = useState({
    NAME: deviceName,
    OS: os,
    OS_VERSION: osVersion,
    BROWSER: browserName,
    BROWSER_VERSION: browserVersion,
    RESOLUTION: `${width}x${height}`,
    MODE: deviceMode,
    DEVICE_GLASS: deviceGlass,
    _id: _id
  });

  const closeModal = () => {
    onClose && onClose();
    fetchUpdate && fetchUpdate();
  };

  const updateDeviceDetails = async (e) => {
    await dataService.updateDevice({
        deviceDetails: deviceDetails
    }); 
    closeModal();
  };

  return (
    <AppModal>
      <div className="table-list-section p-5 rounded-l mt-6">
        <div className="justify-between align-center px-4 py-3">
          <div className="align-center">
            <h2 className="h2-heading">Add Device</h2>
          </div>
          <button
            type="button"
            onClick={closeModal}
            className="bg-none no-border"
          >
            <AppIcon iconName="closeIcon" iconColor="#1B72E6" />
          </button>
        </div>
        <div className="p-4">
          <label className="white-space-no-wrap mr-2 bold">Device</label>
          <Select
            name="deviceName"
            value={deviceOptions.find((x) => x.value == deviceDetails.NAME)}
            options={deviceOptions}
            onChange={(deviceName) =>
              setDeviceDetails({ ...deviceDetails, NAME: deviceName.value })
            }
            className="basic-multi-select mt-1"
            classNamePrefix="select"
          />
        </div>
        <div className="align-center">
          <div className="p-4 w-full">
            <label className="white-space-no-wrap mr-2 bold">OS</label>
            <Select
              name="os"
              value={osOptions.find((x) => x.value == deviceDetails.OS)}
              options={osOptions}
              onChange={(os) =>
                setDeviceDetails({ ...deviceDetails, OS: os.value })
              }
              className="basic-multi-select mt-1"
              classNamePrefix="select"
            />
          </div>
          <div className="p-4 w-full">
            <label className="white-space-no-wrap mr-2 bold">OS Version</label>
            <Select
              name="osVersion"
              value={osVersionOptions.find(
                (x) => x.value == deviceDetails.OS_VERSION
              )}
              options={osVersionOptions}
              onChange={(osVersion) =>
                setDeviceDetails({
                  ...deviceDetails,
                  OS_VERSION: osVersion.value,
                })
              }
              className="basic-multi-select mt-1"
              classNamePrefix="select"
            />
          </div>
        </div>
        <div className="align-center">
          <div className="p-4 w-full">
            <label className="white-space-no-wrap mr-2 bold">Browser</label>
            <Select
              name="browser"
              value={browserOptions.find(
                (x) => x.value == deviceDetails.BROWSER
              )}
              options={browserOptions}
              onChange={(browser) =>
                setDeviceDetails({ ...deviceDetails, BROWSER: browser.value })
              }
              className="basic-multi-select mt-1"
              classNamePrefix="select"
            />
          </div>
          <div className="p-4 w-full">
            <label className="white-space-no-wrap mr-2 bold">
              Browser Version
            </label>
            <Select
              name="browserVersion"
              value={browserVersionOptions.find(
                (x) => x.value == deviceDetails.BROWSER_VERSION
              )}
              options={browserVersionOptions}
              onChange={(browserVersion) =>
                setDeviceDetails({
                  ...deviceDetails,
                  BROWSER_VERSION: browserVersion.value,
                })
              }
              className="basic-multi-select mt-1"
              classNamePrefix="select"
            />
          </div>
        </div>
        <div className="align-center">
          <div className="p-4 w-full">
            <label className="white-space-no-wrap mr-2 bold">Resolution</label>
            <Select
              name="resolution"
              value={resolutionOptions.find(
                (x) => x.value == deviceDetails.RESOLUTION
              )}
              options={resolutionOptions}
              onChange={(resolution) =>
                setDeviceDetails({
                  ...deviceDetails,
                  RESOLUTION: resolution.value,
                })
              }
              className="basic-multi-select mt-1"
              classNamePrefix="select"
            />
          </div>
          <div className="p-4 w-full">
            <label className="white-space-no-wrap mr-2 bold">Mode</label>
            <Select
              name="mode"
              value={modeOptions.find((x) => x.value == deviceDetails.MODE)}
              options={modeOptions}
              onChange={(modeOption) =>
                setDeviceDetails({ ...deviceDetails, MODE: modeOption.value })
              }
              className="basic-multi-select mt-1"
              classNamePrefix="select"
            />
          </div>
        </div>
        <div className="align-center">
          <div className="p-4 w-50">
            <label className="white-space-no-wrap mr-2 bold">Glass</label>
            <Select
              name="glass"
              value={glassOptions.find(
                (x) => x.value == deviceDetails.DEVICE_GLASS
              )}
              options={glassOptions}
              onChange={(glass) =>
                setDeviceDetails({
                  ...deviceDetails,
                  DEVICE_GLASS: glass.value,
                })
              }
              className="basic-multi-select mt-1"
              classNamePrefix="select"
            />
          </div>
        </div>
        <div className="flex justify-end px-4 mb-4">
          <button
            onClick={() => updateDeviceDetails()}
            type="button"
            className="btn-medium-2"
          >
            Save
          </button>
        </div>
      </div>
    </AppModal>
  );
};
