import React, { useEffect, useState } from "react";
import { ChevronIcon } from "../ChevronIcon/ChevronIcon";

const PageHits = ({ onRegressionSelect, deviceData, selectedSteps }) => {
  const { data = [] } = deviceData || { data: [] };
  const [activeAction, setActiveAction] = useState(0);
  const [selectAllCheck, setSelectAllCheck] = useState(false);
  const [selectedStepList, setSelectedStepList] = useState([]);

  useEffect(() => {
    if (selectedSteps && JSON.stringify(selectedSteps) !== JSON.stringify(selectedStepList)) {
      setSelectedStepList(selectedSteps);
    }
  }, [selectedSteps]);

  const onRegressionChange = (e) => {
    setSelectAllCheck(e.target.checked);
    if (onRegressionSelect) {
      const obj = {
        regressionName: data[activeAction].name,
        regressionIndex: 0,
        pageId: data[activeAction].page_id || data[activeAction].name,
        steps: data[activeAction].steps
      };
      const steps = e.target.checked && data[activeAction]?.steps ? [...selectedStepList, obj] : selectedStepList.filter(step => step?.pageId !== obj?.pageId);
      setSelectedStepList(steps);
      onRegressionSelect(steps);
    }
  }

  const switchPages = (index) => {
    setActiveAction(index);
    setSelectAllCheck(false);
    // onRegressionSelect && onRegressionSelect([]);
  }

  return (
    <div className="page-actions flex">
      <div className="page-actions-list w-50">
        <div className="page-actions-list-info">
          <ul>
            {data.map((page, actionIndex) => {
              return (
                <li
                  key={page.actionIndex}
                  className={`pl-8 pr-2 pt-2 pb-3 ${actionIndex === activeAction ? "active" : ""
                    }`}
                  onClick={() => switchPages(actionIndex)}
                >
                  <div className="flex justify-between align-center">
                    <p className="flex justify-between sub-heading-3 page-actions-list-name">{page.name}</p>
                    <ChevronIcon type="right" />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="page-actions-regressions w-50">
        <div className="page-actions-container">
          <div className="pl-3 pr-6 py-4 justify-between align-center w-full page-actions-regressions-title">
            <p className="sub-heading-2">Select Automated Regression</p>
            <input type="checkbox" name="all" checked={!!selectedStepList.find(stepItem => stepItem.pageId === (data[activeAction]?.page_id || data[activeAction]?.name))} onChange={onRegressionChange} />
          </div>
          <div className="page-actions-regressions-list">
            {data[activeAction] ? (<ul>
              <li className="my-4 mx-3 p-3 justify-between align-center">
                <div className="w-70">
                  <p className="info-title pb-2 pr-4 sub-heading-3">
                    {data[activeAction].name}
                  </p>
                  <p className="info-desc sub-heading-3">{data[activeAction].no_of_users} clicks</p>
                </div>
                <input type="checkbox" checked={!!selectedStepList.find(stepItem => stepItem.pageId === (data[activeAction]?.page_id || data[activeAction]?.name))} onChange={onRegressionChange} />
              </li>
            </ul>) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHits;
