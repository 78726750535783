import { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Outlet } from "react-router-dom";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import HomePage from './src/views/Home.jsx'
import Layout from './src/views/Layout.jsx'
import NoMatch from './src/views/NoMatch.jsx'
import NoImplementation from "./src/views/NoImplementation.jsx";
import Dashboard from './src/views/Dashboard.jsx'

import UiRegressionLayout from "./src/views/ui-regression/UiRegressionLayout.jsx";
import ApiRegressionLayout from "./src/views/api-regression/ApiRegressionLayout.jsx";
import UiRegression from "./src/views/ui-regression/UiRegression.jsx";
import DeviceDetail from "./src/views/ui-regression/DeviceDetail.jsx";
import ApiRegression from "./src/views/api-regression/ApiRegression.jsx";
import LoginPage from "./src/views/LoginPage.jsx";
import RootLayout from "./src/views/RootLayout.jsx";

import socketService from "./src/services/socket.service.js";
import longPollingService from "./src/services/long-polling.service.js";
import CollectionDetail from "./src/views/api-regression/CollectionDetail.jsx";
import CreateCollection from "./src/views/api-regression/CreateCollection.jsx";
import UsageBasedRegression from './src/views/usage-pulse/UsageBasedRegression.jsx';
import PageActionPerformance from './src/views/page-blazer/PageActionPerformance.jsx';
import PageActionDetails from './src/views/page-blazer/PageActionDetails.jsx';
import AdminLayout from "./src/views/admin/AdminLayout.jsx";
import Admin from "./src/views/admin/Admin.jsx";
import Prompt from "./src/views/Prompt.jsx";


import storeService from "./src/services/store.service.js"; 
import LicensePage from "./src/views/LicensePage.jsx";

if (!process.env.DISABLE_WS) {
    socketService.init();
} else {
    longPollingService.init();
}
const root = createRoot(document.getElementById("root"));

const Protected = ({ children }) => {
    // let location = useLocation();
    // if (!localStorage.getItem('token')) {
    //     return <Navigate to="/login" state={{ from: location }} replace />;
    // }
    return children;
}

const AdminAccess = ({ children }) => {
    let location = useLocation();
    if (localStorage.getItem('userType')
        && localStorage.getItem('userType').toLowerCase() === 'admin') {
        return children;
    } else {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
}

root.render(
    <BrowserRouter>
        <Routes>
            <Route path="login" element={<LoginPage />} />
            <Route path="license" id="expired" key="expired" element={<LicensePage />}>
            </Route>
            <Route path="/" element={<RootLayout />}>
                <Route path="admin" element={<AdminLayout />}>
                    <Route index element={<Admin />} />
                </Route>
                <Route path="usage-regression" id="ui-regression-home" key="uihome" element={<UsageBasedRegression />}>
                </Route>
                <Route path="page-action" id="page-action-home" key="pagehome" element={<PageActionPerformance />}>
                </Route>
                <Route path="page-action/page/:pageId" id="page-action-home" key="pagehome" element={<PageActionPerformance />}>
                </Route>
                <Route path="page-action/page/:pageId/device/:deviceId" id="page-action-details" key="pagedetails" element={<PageActionDetails />}>
                </Route>
                <Route path="ui-regression" id="ui-regression-home" key="uihome" element={<UiRegressionLayout />}>
                    <Route path="page/:pageId/device/:deviceId" id="device-detail" element={<DeviceDetail />} />
                    <Route index element={
                        <UiRegression />
                    } />
                </Route>
                <Route path="api-regression" element={<ApiRegressionLayout />}>
                    <Route path="create-collection" id="create-collection" element={<CreateCollection />} />
                    <Route path="collection/:collectionId" id="collection-detail" element={<CollectionDetail />} />
                    <Route index element={
                        <ApiRegression />
                    } />
                </Route>

                <Route path="prompt" element={
                    <AdminAccess>
                        <Prompt />
                    </AdminAccess>
                } />
                <Route path="/qa-dashboard" element={<Dashboard />}/>
                <Route path="/" element={<Layout />}>
                    <Route path="no-implementation" element={<NoImplementation />} />
                    <Route index element={
                        <HomePage />
                    } />
                    <Route path="*" element={<NoMatch />} />
                </Route>
            </Route>
        </Routes>
    </BrowserRouter>
);