import { useState } from "react";
import authService from "../services/auth.service";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
export default () => {
  const [params, setParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [formData, setformData] = useState({
    username: {
      showError: false,
      value: "",
    },
    password: {
      showError: false,
      value: "",
    },
  });
  const [serverError, setServerError] = useState(null);
  const handleformSubmit = async (e) => {
    e.preventDefault();
    if (
      formData.username.value.length > 3 &&
      formData.password.value.length > 3
    ) {
      setLoading(true);
      let authResp = await authService.authenticate({
        username: formData.username.value,
        password: formData.password.value,
      });
      if (authResp.data && authResp.data.isValid) {
        localStorage.setItem("token", authResp.data.token);
        localStorage.setItem("userType", authResp.data?.role)
        location.href = params.get('redirectUrl') ? decodeURIComponent(params.get('redirectUrl')) : "/";
        return;
      }
      setLoading(false);
      setformData({
        ...formData,
        username: {
          ...formData.username,
          value: "",
        },
        password: {
          ...formData.password,
          value: "",
        },
      });
      setServerError("Invalid credentials, please try again.");
    } else {
      setformData({
        ...formData,
        username: {
          ...formData.username,
          showError: formData.username.value.length < 4,
        },
        password: {
          ...formData.password,
          showError: formData.password.value.length < 4,
        },
      });
    }
  };

  const handleInputChange = (value, fieldName) => {
    if (fieldName == "username") {
      setformData({
        ...formData,
        username: {
          showError: false,
          value,
        },
      });
    }
    if (fieldName == "password") {
      setformData({
        ...formData,
        password: {
          showError: false,
          value,
        },
      });
    }
  };
  return (
    <div className="login-wrapper flex justify-between">
      <div className="login-section flex justify-center align-center">
        <div className="login-section__logo">
        <img src="icons/logoWithName.svg" />
        </div>
        
        <div className="login-box p-6 rounded-l mt-8">
          <form className="app-form" onSubmit={handleformSubmit}>
            <div className="app-form__fields">
                <h1 className="py-4">Sign In</h1>
              <div className="form-field">
                <label className="label mb-1">Username</label>
                <input
                  value={formData.username.value}
                  onChange={(e) =>
                    handleInputChange(e.target.value, "username")
                  }
                  placeholder="email@company.com"
                  className={`input-txt ${formData.username.showError || serverError ? 'input-txt--error' : ''}`}
                  type="text"
                />
                {formData.username.showError && (
                  <p className="error-message">This field is required.</p>
                )}
              </div>
              <div className="form-field mt-7">
                <label className="label mb-1">Password</label>
                <input
                  value={formData.password.value}
                  onChange={(e) =>
                    handleInputChange(e.target.value, "password")
                  }
                  className={`input-txt ${formData.username.showError || serverError ? 'input-txt--error' : ''}`}
                  type="password"
                  placeholder="• • • • • • • • • •"
                />
                {formData.password.showError && (
                  <p className="error-message">This field is required.</p>
                )}
              </div>
            </div>
            <div className="mt-5 py-4 align-center justify-center">
              <button
                className="ml-4 btn-medium-2 signIn-btn"
                type="submit"
                disabled={loading}
              >
                Sign In
              </button>
             
            </div>
            {serverError && (
              <p className="server-error-message">{serverError}</p>
            )}
          </form>
        </div>
      </div>
      <div className="image-section">
      </div>
    </div>
  );
};
