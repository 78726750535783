import { useState, useEffect } from "react"
import Select from 'react-select'
import TimePicker from '../atoms/TimePicker';
import DatePicker from '../atoms/DatePicker';
import DateTimePicker from '../atoms/DateTimePicker';
import utilsService from "../../services/utils.service";
import AppIcon from "../atoms/AppIcon/AppIcon.jsx";
import AppModal from "../../comps/organisms/AppModal.jsx"
import scheduleService from "../../services/schedule.service.js";
import apiService from "../../services/api.service.js";
import storeService from "../../services/store.service.js";
import SectionLoader from "./SectionLoader.jsx";
import TimePickerV2 from "./TimePickerV2.jsx";
import ConfirmModal from '../molecules/ConfirmModal.jsx';
import tenantsService from "../../services/tenants.service.js";

const priorityTypeOptions = [
    {
        label: 'P0',
        value: 'P0'
    },
    {
        label: 'P1',
        value: 'P1'
    },
    {
        label: 'All',
        value: 'All'
    }
]
const notificationTypeOptions = [
    {
        label: 'DAILY',
        value: 'DAILY'
    },
    {
        label: 'WEEKLY',
        value: 'WEEKLY'
    },
    {
        label: 'PERIODICALLY',
        value: 'PERIODICALLY'
    }
]

export default (props) => {
    const [selectedTime, setSelectedTime] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [scheduleType, setScheduleType] = useState({ label: 'DAILY', value: 'DAILY' })
    const [showModal, setShowModal] = useState(false)
    const [weekdays, setweekdays] = useState([]);
    const [weekdaysError, seteweekdaysError] = useState(false)
    const [deleteId, setdeleteId] = useState(null)
    const [mailListString, setmailListString] = useState('')
    const [selectedNotificationType, setselectedNotificationType] = useState({
        label: 'All',
        value: 'All'
    })
    const [disableSubmit, setdisableSubmit] = useState(false)
    const [tillDateError, settillDateError] = useState(false)
    const [runTillDate, setRunTillDate] = useState(null)
    const [tillDateMinDate, settillDateMinDate] = useState(utilsService.getDateFormat(new Date(), 'YMD'))
    const [loading, setloading] = useState(true)
    const [notifications, setnotifications] = useState(storeService.store.EMAILNOTIFICATIONS.items || [])
    const [tenantEnvs, settenantEnvs] = useState([])
    const [selectedTenantEnvs, setselectedTenantEnvs] = useState([])
    const [interval, setinterval] = useState(2)
    const [errorMap, seterrorMap] = useState({})
    const scheduleInterval = storeService.store.GLOBAL.schedularInterval;
    const [editItem, seteditItem] = useState(null)
    const constructOptions = (tenants) => {
        let items = [], availableTenentEnvs = [];
        tenants.forEach(tenant => {
            if (tenant.environments && tenant.environments.length > 0) {
                tenant.environments.forEach(env => {
                    let val = `${tenant.name}||${env.name}`;
                    items.push({
                        value: val,
                        label: `${tenant.name}-${env.name}`
                    })
                    availableTenentEnvs.push(val)
                })
            }
        })
        settenantEnvs([...items])
    }
    const fetchEnvironments = async () => {
        tenantsService.loadTenants({}).then(resp => {
            constructOptions(storeService.store.TENANTS.items);
        })
    }
    const fetchNotifications = async () => {
        const apiResp = await scheduleService.getnotificationschedules({})
        const { data = [] } = apiResp || {};
        setnotifications(data);
        setloading(false)
    }
    useEffect(() => {
        fetchEnvironments();
        fetchNotifications();
        setDefaultTime();
    }, [])
    const setDefaultTime = () => {
        let dateToSet = new Date();
        let hours = dateToSet.getHours();
        let minutes = dateToSet.getMinutes();
        if (minutes % scheduleInterval !== 0) {
            const closest = Math.round(minutes / scheduleInterval) * scheduleInterval;
            minutes = closest === 60 ? 0 : closest;
        }
        setSelectedTime(`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`)
    }
    const handleWeekDayChange = (e) => {
        if (e.target.checked) {
            setweekdays([...weekdays, e.target.value])
            seteweekdaysError(false)
        } else {
            setweekdays([...weekdays.filter(x => x != e.target.value)])
        }
    }
    const clearAllFilters = (e) => {
        e && e.preventDefault();
        setselectedNotificationType({
            label: 'All',
            value: 'All'
        })
        setmailListString('')
        setScheduleType({
            label: 'DAILY',
            value: 'DAILY'
        })
        setRunTillDate(null)
        setselectedTenantEnvs([])
        setweekdays([])
        setDefaultTime();
        seterrorMap({})
    }
    const handleSaveChanges = () => {
        let errorCount = 0;
        let mailList = [];
        let updatederrorMap = {};
        try {
            mailList = mailListString.split(new RegExp('[\\n, ]'));
            mailList = mailList.filter(x => x && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(x));
        } catch (ex) {
            errorCount += 1;
            updatederrorMap['mailListString'] = true;
        }
        if (mailList.length == 0) {
            errorCount += 1;
            updatederrorMap['mailListString'] = true;
        }
        if (scheduleType.value == 'DAILY' || scheduleType.value == 'WEEKLY') {
            if (selectedTime == null) {
                errorCount += 1;
                updatederrorMap['selectedTime'] = true;
            }
            if (scheduleType.value == 'WEEKLY') {
                if (weekdays.length == 0) {
                    errorCount += 1;
                    updatederrorMap['weekdays'] = true;
                }
            }
        } else {
            if (interval == null || interval < 1) {
                errorCount += 1;
                updatederrorMap['interval'] = true;
            }
        }
        let tillDateValue = null;
        if (runTillDate) {
            var dt1 = new Date();
            const [yyyy, mm, dd] = runTillDate.split('-')
            dt1.setFullYear(yyyy - 0)
            dt1.setMonth(mm - 1)
            dt1.setDate(dd - 0)
            tillDateValue = dt1;
            if (dt1 < new Date()) {
                errorCount += 1;
                updatederrorMap['tillDate'] = true;
            }
        }
        if (errorCount > 0) {
            seterrorMap({ ...updatederrorMap });
            return;
        }
        setloading(true)
        let formData = {
            "notificationPriority": selectedNotificationType.value,
            "mailList": mailList,
            "notificationType": scheduleType.value,
            "tillDate": tillDateValue,
            "environments": selectedTenantEnvs.map(x => x.value)
        };
        if (scheduleType.value == 'DAILY' || scheduleType.value == 'WEEKLY') {
            const [hours, minutes] = selectedTime.split(':').map(Number);
            var daily = new Date();
            daily.setHours(hours, minutes, 0, 0);
            formData['notificationTime'] = daily;
            if (scheduleType.value == 'WEEKLY') {
                formData['weekdays'] = weekdays;
            }
        } else {
            formData['intervalMin'] = interval * 60;
        }
        if (editItem) {
            scheduleService.updatesemailschedule({
                id: editItem._id,
                fieldsToUpdate: formData
            }).then(async (apiResp) => {
                await fetchNotifications();
                setloading(false)
                setShowModal(false)
            })
        } else {
            scheduleService.addnotificationschedule(formData).then(async (apiResp) => {
                await fetchNotifications();
                setloading(false)
                setShowModal(false)
            })
        }
    }
    const openDeleteNotification = (e, id) => {
        e.preventDefault();
        setdeleteId(id)
        setShowDeleteModal(true);
    }
    const handleDeleteModal = () => {
        setloading(true);
        scheduleService.deleteemailschedule({ id: deleteId }).then(async (apiresp) => {
            await fetchNotifications()
        }).finally(() => {
            setloading(false);
            setShowDeleteModal(false);
            setdeleteId(null);
        })
    }
    const openEditNotification = (e, item) => {
        e.preventDefault();
        seteditItem(item)
        settillDateError(false)
        setselectedNotificationType({
            label: item.notificationPriority,
            value: item.notificationPriority
        })
        setmailListString((item.mailList || []).join('\n') || '')
        setScheduleType({
            label: item.notificationType,
            value: item.notificationType
        })
        setRunTillDate(item.tillDate ? utilsService.getYMDFormat(new Date(item.tillDate)) : null)
        setselectedTenantEnvs(item.environments ? item.environments.map(x => ({ label: x.replace('||', '-'), value: x })) : [])
        setweekdays(item.weekdays ? item.weekdays : [])
        if (item.notificationTime || item.daily) {
            let dateToSet = new Date(item.notificationTime || item.daily);
            let hours = dateToSet.getHours();
            let minutes = dateToSet.getMinutes();
            setSelectedTime(`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`)
        } else {
            setDefaultTime();
        }
        setShowModal(true)
        setinterval(item.intervalMin ? Math.floor(item.intervalMin / 60) : '2')
    }

    return (
        <div className="modal-form-content">
            <h2 className="h2-heading mb-8">Email Notifications</h2>
            <div className="flex justify-end">
                <button type="button" class="btn-medium-2" onClick={() => {
                    seteditItem(null)
                    clearAllFilters();
                    setShowModal(true)
                }}>Add Email notification</button>
            </div>
            <table className="app-table my-4">
                <tbody>
                    <tr className='table-row-title'>
                        <td>
                            <div className="align-center">
                                <span class="mr-1 table-subheading">Notification Priority</span>
                            </div>
                        </td>
                        <td>
                            <div className="align-center">
                                <span class="mr-1 table-subheading">Email List</span>
                            </div>
                        </td>
                        <td>
                            <div className="align-center">
                                <span class="mr-1 table-subheading">Details</span>
                            </div>
                        </td>
                        <td>
                            <div className="align-center">
                                <span class="mr-1 table-subheading">Till date</span>
                            </div>
                        </td>
                        <td>
                            <div className="align-center">
                                <span class="mr-1 table-subheading">Action</span>
                            </div>
                        </td>
                    </tr>
                    {
                        notifications && notifications.length > 0 ? notifications.map(item => {
                            return (
                                <tr className="table-content">
                                    <td>
                                        <span className="table-text">{item.notificationPriority}</span>
                                    </td>
                                    <td>
                                        <span className="table-text">
                                            {
                                                item.mailList && item.mailList.length > 0 ? item.mailList.map(email => {
                                                    return (
                                                        <div>
                                                            {email}
                                                        </div>
                                                    )
                                                }) : (
                                                    <div>-</div>
                                                )
                                            }
                                        </span>
                                    </td>
                                    <td>
                                        <div className="table-text flex">
                                            {
                                                (item.notificationType == 'DAILY' || item.notificationType == 'WEEKLY') && (
                                                    <div>Runs @ </div>
                                                )
                                            }
                                            {
                                                (item.notificationType == 'DAILY' || item.notificationType == 'WEEKLY') && (
                                                    <div>{utilsService.getTimeFromUtc(item.isoHour, item.isoMinutes)}</div>
                                                )
                                            }
                                            {
                                                item.notificationType == 'WEEKLY' && (
                                                    <div className="pl-1"> on every {(item.weekdays || []).join(',')}</div>
                                                )
                                            }
                                            {
                                                item.notificationType == 'PERIODICALLY' && (
                                                    <div>On every {item.intervalMin / 60} hour(s)</div>
                                                )
                                            }
                                        </div>
                                        {
                                            item.invokedAt && (
                                                <div className="table-text">Invoked At: {utilsService.getDateAndTime(item.invokedAt).fullDateTimeStr}</div>
                                            )
                                        }
                                        {
                                            item.invokedCount && (
                                                <div className="table-text">Invoked Count: {item.invokedCount}</div>
                                            )
                                        }
                                    </td>
                                    <td>
                                        <span className="table-text">
                                            {utilsService.getDisplayDateTime(item.tillDate, true, false)}
                                        </span>
                                    </td>
                                    <td>
                                        <div className="flex align-center">
                                            <a onClick={e => {
                                                openEditNotification(e, item)
                                            }} className='py-1 flex align-center no-underline edit-link-option mr-4'>
                                                Edit
                                            </a>
                                            <a onClick={e => {
                                                openDeleteNotification(e, item._id)
                                            }} className='py-1 flex align-center no-underline edit-link-option mr-4'>
                                                Delete
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }) : (
                            null
                        )
                    }

                </tbody>
            </table>
            {
                loading && (
                    <SectionLoader></SectionLoader>
                )
            }
            {
                showDeleteModal && (
                    <ConfirmModal
                        confirmationTitle={'Are you sure you want to delete?'}
                        confirmButtonLabel={'Delete'}
                        onOk={() => handleDeleteModal()}
                        onCancel={e => setShowDeleteModal(false)}
                    />
                )
            }
            {
                showModal && (
                    <AppModal>
                        <div className="justify-between align-center modal-title">
                            <div className="align-center">
                                <h2 className="h2-heading">{editItem ? 'Edit' : 'Add'} Email Notification</h2>
                            </div>
                            <button
                                type="button"
                                onClick={e => {
                                    setShowModal(false)
                                }}
                                className="bg-none no-border">
                                <AppIcon iconName="closeIcon" iconColor="#1B72E6" />
                            </button>
                        </div>
                        <div className="modal-content modal-form-content overflow-y-scroll min-h-60vh max-h-60vh">
                            <div className="mb-4">
                                <label className="label mb-2">Notification Priority</label>
                                <Select value={selectedNotificationType} onChange={setselectedNotificationType} className="select-text" options={priorityTypeOptions}>
                                </Select>
                            </div>
                            <div className="form-field mb-4">
                                <label className="label mb-2" htmlFor="description">Email list</label>
                                <textarea rows="4"
                                    value={mailListString}
                                    onChange={e => {
                                        let val = e.target.value;

                                        setmailListString(val);
                                    }}
                                    className={`text-area ` + (errorMap['mailListString'] ? 'error' : '')}
                                    placeholder="Enter email address"
                                    id="description" name="description" required></textarea>
                                <p className={`field-note mt-1 ` + (errorMap['mailListString'] ? 'font-danger' : '')}>Line separated, atleast one valid email is required.</p>
                            </div>
                            <div className="notification-content mb-8">
                                <div className="form-field-select form-content mb-8">
                                    <label className="label mb-2">Notification Type</label>
                                    <Select className="select-text"
                                        value={scheduleType}
                                        options={notificationTypeOptions}
                                        onChange={setScheduleType}>
                                        <option>DAILY</option>
                                        <option>WEEKLY</option>
                                        <option>PERIODICALLY</option>
                                    </Select>
                                </div>

                                {
                                    scheduleType.value == 'WEEKLY' && (
                                        <div className={`form-field mb-8  ${weekdaysError ? 'error-field' : ''}`}>
                                            <label className="white-space-no-wrap mr-2 bold label mb-2">
                                                On which day you want to run weekly?
                                            </label>
                                            <div className='mt-2 flex'>
                                                <label className='flex align-center mr-4' for="mon">
                                                    <input onChange={handleWeekDayChange} checked={weekdays.indexOf('MON') >= 0} id="mon" type="checkbox" value="MON" />
                                                    <span className='ml-1'>Mon</span>
                                                </label>
                                                <label className='flex align-center mr-4' for="tue">
                                                    <input onChange={handleWeekDayChange} checked={weekdays.indexOf('TUE') >= 0} id="tue" type="checkbox" value="TUE" />
                                                    <span className='ml-1'>Tue</span>
                                                </label>
                                                <label className='flex align-center mr-4' for="wed">
                                                    <input onChange={handleWeekDayChange} checked={weekdays.indexOf('WED') >= 0} id="wed" type="checkbox" value="WED" />
                                                    <span className='ml-1'>Wed</span>
                                                </label>
                                                <label className='flex align-center mr-4' for="thu">
                                                    <input onChange={handleWeekDayChange} checked={weekdays.indexOf('THU') >= 0} id="thu" type="checkbox" value="THU" />
                                                    <span className='ml-1'>Thur</span>
                                                </label>
                                                <label className='flex align-center mr-4' for="fri">
                                                    <input onChange={handleWeekDayChange} checked={weekdays.indexOf('FRI') >= 0} id="fri" type="checkbox" value="FRI" />
                                                    <span className='ml-1'>Fri</span>
                                                </label>
                                                <label className='flex align-center mr-4' for="sat">
                                                    <input onChange={handleWeekDayChange} checked={weekdays.indexOf('SAT') >= 0} id="sat" type="checkbox" value="SAT" />
                                                    <span className='ml-1'>Sat</span>
                                                </label>
                                                <label className='flex align-center' for="sun">
                                                    <input onChange={handleWeekDayChange} checked={weekdays.indexOf('SUN') >= 0} id="sun" type="checkbox" value="SUN" />
                                                    <span className='ml-1'>Sun</span>
                                                </label>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    (scheduleType.value == 'DAILY' || scheduleType.value == 'WEEKLY') && (
                                        <div className="form-field mb-8">
                                            <label className="white-space-no-wrap mr-2 bold label mb-2">
                                                At what time on every day you want to run? (10 min intervals)
                                            </label>
                                            <div className='mb-8'>
                                                <TimePickerV2 onChange={(val) => {
                                                    setSelectedTime(val)
                                                }} value={selectedTime}></TimePickerV2>
                                                {
                                                    errorMap['selectedTime'] && (
                                                        <div className="font-danger">This is required field</div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    scheduleType.value == 'PERIODICALLY' && (
                                        <>
                                            <div className={`form-field mb-4 ${tillDateError ? 'error-field' : ''}`}>
                                                <label className="white-space-no-wrap mr-2 bold label mb-2">
                                                    What interval you want to run? (in Hours)
                                                </label>
                                                <div className='mt-2'>
                                                    <input type="text"
                                                        value={interval}
                                                        className="input-field w-full p-2 input-txt"
                                                        onChange={(event) => {
                                                            let val = (event.target.value || '').replace(new RegExp('[^0-9]', 'g'), '')
                                                            setinterval(val)
                                                        }}
                                                        placeholder="Enter number" />
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                <div className={`mb-8 form-field ${tillDateError ? 'error-field' : ''}`}>
                                    <label className="white-space-no-wrap mr-2 bold label mb-2">
                                        When you want to stop this schedule?
                                    </label>
                                    <div className=''>
                                        <DatePicker onChange={(val) => {
                                            setRunTillDate(val)
                                            if (val) {
                                                const [yyyy, mm, dd] = val.split('-')
                                                var dt1 = new Date(yyyy - 0, mm - 1, dd - 0);
                                                if (dt1 < new Date()) {
                                                    errorMap['tillDate'] = true;
                                                } else {
                                                    errorMap['tillDate'] = false;
                                                }
                                                seterrorMap({ ...errorMap })
                                            }
                                        }} minDate={tillDateMinDate} value={runTillDate}></DatePicker>
                                    </div>
                                    {
                                        errorMap['tillDate'] && (
                                            <div className='error-message mt-1'>You must select future dates.</div>
                                        )
                                    }
                                </div>
                                <div className="form-field-select form-content">
                                    <label className="label mb-2">Pick Environments</label>
                                    <Select onChange={setselectedTenantEnvs}
                                        value={selectedTenantEnvs} isMulti options={tenantEnvs} />
                                </div>
                            </div>
                        </div>
                        <div className="px-8 py-6 flex justify-end align-center">
                            <a href="" onClick={e => clearAllFilters(e)} className="btn-link-medium mr-8">Clear All</a>
                            <button disabled={disableSubmit} type="button" onClick={handleSaveChanges} className="btn-medium-2 btn-medium-width">Save</button>
                        </div>
                    </AppModal>
                )
            }
        </div>
    )
}