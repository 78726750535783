import { useEffect, useState } from 'react'
import { Tooltip } from 'react-tooltip'
export default (props) => {
    const [filterCount, setfilterCount] = useState(0)
    const [filterText, setfilterText] = useState('')
    useEffect(() => {
        let count = 0;
        let filterList = [];
        const { searchText, selectedDevices, selectedTags, byScheduled, selectedType, jobStatus = [] } = props.selectedFilterData;
        if (searchText != null && searchText != '') {
            count += 1;
            filterList.push(`Search text : ${searchText}`)
        }
        if (selectedType != null) {
            count += 1;
            filterList.push(` with ${selectedType.value} type`)
        }
        if (byScheduled != null) {
            count += 1;
            if (byScheduled == 'ONLY_SCHEDULE') {
                filterList.push(` with only sheduled`)
            }
            if (byScheduled == 'ONLY_NON_SCHEDULE') {
                filterList.push(` without scheduled`)
            }
        }
        if (selectedDevices && selectedDevices.length > 0) {
            count += 1;
            filterList.push(`for ` + selectedDevices.map(x => x.label).join(', ') + ' device' + (selectedDevices.length > 1 ? 's' : ''))
        }
        if (selectedTags.length > 0) {
            count += 1;
            filterList.push(`tagged with ` + selectedTags.map(x => x.label).join(', '))
        }
        let jobStatusLen = jobStatus.length;
        if (jobStatusLen > 0) {
            count += 1;
            let isAllFailed = jobStatus.find(x => x == 'FAILED');
            if (isAllFailed) {
                filterList.push(`with Failed status`)
            } else {
                filterList.push(`failed with ` + jobStatus.map(x => (x)).join(', ') + (jobStatusLen > 1 ? ' priorities' : ' priority'))
            }
        }
        setfilterCount(count)
        setfilterText(filterList.join(' and '))
    }, [props.selectedFilterData])
    if (filterCount > 0) {
        return (
            <div className='ml-2'>
                <a id="filtertextid" onClick={e => {
                    e.preventDefault();
                    props.handleUpdateFilterData({
                        searchText: [],
                        selectedDevices: [],
                        selectedTags: [],
                        byScheduled: null,
                        selectedType: null,
                        jobStatus: []
                    })
                }} href="" className='white-space-no-wrap font-link-color table-heading'>
                    Clear {filterCount}  {'filter' + (filterCount > 1 ? 's' : '')}
                </a>
                <Tooltip
                    anchorSelect={'#filtertextid'}
                    content={filterText}
                    place="top"
                    style={{
                        backgroundColor: "#000",
                        color: "#ff",
                        borderRadius: "5px",
                        zIndex: 22,
                        fontWeight: "bold",
                    }}
                />
            </div>
        )
    }
    return null;
}