import apiService from './api.service.js'
import storeService from './store.service.js';
import utils from './utils.service.js'
export default {
    async loadmetaData(payload = {}) {
        let apiResp = await apiService.post({
            url: utils.END_POINTS.loadcollectionsmetadata,
            data: payload
        })
        return apiResp;
    },
    mergeTestResults() {
        let collections = storeService.store.COLLECTIONS_WITH_DETAILS;
        let testResults = storeService.store.API_TEST_RESULTS
        collections.items.forEach(collection => {
            collection.testresults = testResults.items.filter(x => x.collectionId.toString() === collection._id.toString()).map(x => {
                if (!x['results']) {
                    x['results'] = [];
                }
                return x;
            })
            collection.randomId = utils.guid(4)
        });
        storeService.saveStore({
            items: collections.items
        }, 'COLLECTIONS_WITH_DETAILS');
    },
    async getcollections() {
        return await apiService.post({
            url: utils.END_POINTS.getCollections,
            data: {
                "selectedEnvId": storeService.store.ENVIRONMENTS.selectedEnvId,
                "find": {},
                "options": {
                    "sort": {
                        "createdAt": -1
                    }
                }
            }
        });
    },
    async getcollectionsv2(payload) {
        payload['selectedEnvId'] = storeService.store.ENVIRONMENTS.selectedEnvId;
        return await apiService.post({
            url: utils.END_POINTS.getCollectionsv2,
            data: payload
        });
    },
    async gettestresults() {
        return await apiService.post({
            url: utils.END_POINTS.gettestresults,
            data: {
                "selectedEnvId": storeService.store.ENVIRONMENTS.selectedEnvId,
                "find": {},
                "options": {
                    "sort": {
                        "createdAt": -1
                    }
                }
            }
        });
    },
    async gettestresultsv2(payload) {
        return await apiService.post({
            url: utils.END_POINTS.gettestresultsv2,
            data: payload
        });
    },
    async updatecollection(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.updatecollection,
            data: payload
        });
        return apiResp;
    }
}