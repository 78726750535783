import React, { useState, useEffect } from "react";
import AppIcon from "../atoms/AppIcon.jsx";
import { useNavigate } from "react-router-dom";
import storeService from "../../services/store.service.js";
import utilsService from "../../services/utils.service.js";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

export default () => {
  const [formData, setFormData] = useState({
    apiType: "",
    title: "",
    description: "",
    selectedTags: [],
    testData: [],
    apiSteps: [],
  });
  const [apisCount, setApiCount] = useState([
    { label: "API 1", id: "1" },
    { label: "API 2", id: "2" },
  ]);

  const closeEditCollectionModal = () => {
    storeService.pub(
      utilsService.CONSTANTS.EVENT_NAME.OPEN_EDIT_CHAIN_API_MODAL,
      false
    );
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const tags = [
    { value: "tag1", label: "Tag 1" },
    { value: "tag2", label: "Tag 2" },
    { value: "tag3", label: "Tag 3" },
    { value: "tag4", label: "Tag 4" },
    { value: "tag5", label: "Tag 5" },
    { value: "tag6", label: "Tag 6" },
  ];

  const createTestData = (data) => {
    setFormData({ ...formData, testData: data });
  };

  useEffect(() => {
    // get the collection details to be edited
    setFormData({
      title: "Edit the title",
      description: "Edit the description",
      selectedTags: [
        { value: "tag1", label: "Tag 1" },
        { value: "tag2", label: "Tag 2" },
      ],
      testData: [{ value: "tag1", label: "Test Data 1" }],
      apiSteps: [
        { label: "API to fetch token", id: "1" },
        { label: "API to fetch users", id: "2" },
      ],
    });
  }, []);

  const handleformSubmit = () => {
  };

  const apiSelected = (api, index) => {
    const updatedSteps = [...formData.apiSteps];
    const isNewAPI = typeof api === "string";
    if (isNewAPI) {
      updatedSteps[index] = {
        value: api,
        label: api,
      };
    } else {
      updatedSteps[index] = api;
    }
    setFormData({ ...formData, apiSteps: updatedSteps });
  };

  const selectApiType = (apiType) => {
    setFormData({
      ...formData,
      apiType: apiType,
    });
    setShowApiChoiceError(false);
  };

  const loadAPIDropdown = () => {
    return apisCount.map((api, index) => (
      <div key={api.id} className="mb-4 pl-4">
        <label
          className="relative step-indicator d-block bold font-urbanist"
          htmlFor={api.id}
        >
          API {index + 1}
        </label>
        <CreatableSelect
          options={options}
          value={
            formData?.apiSteps[index]
              ? formData?.apiSteps[index]
              : { value: "", label: "Select API" }
          }
          onChange={(api) => apiSelected(api, index)}
          onCreateOption={(api) => apiSelected(api, index)}
        />
      </div>
    ));
  };

  return (
    <div className="app-modal">
      <div className="app-modal__content select-api">
        <div className="add-page-comp">
          <div className="add-page-comp__head px-8 py-6">
            <div className="flex justify-between">
              <h3 className="h3-heading">Details</h3>
              <button
                onClick={closeEditCollectionModal}
                className="no-border bg-none"
              >
                <AppIcon iconName="closeIcon" iconColor="#000000"></AppIcon>
              </button>
            </div>
            <div className="chain-api-step-2 edit">
              <div className="mt-4">
                <label
                  htmlFor="title"
                  className="d-block bold font-urbanist mb-1"
                >
                  Title
                </label>
                <input
                  id="title"
                  type="text"
                  name="titleInput"
                  value={formData.title}
                  onChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                  className="w-full p-2"
                />
              </div>
              <div className="mt-4">
                <label
                  htmlFor="description"
                  className="d-block bold font-urbanist mb-1"
                >
                  Description
                </label>
                <textarea
                  id="description"
                  value={formData.description}
                  onChange={(e) =>
                    setFormData({ ...formData, description: e.target.value })
                  }
                  rows={5}
                  name="descriptionInput"
                  className="w-full p-2"
                />
              </div>
              <div className="mt-4">
                <p className="bold font-urbanist mb-1">Details</p>
                <div className="steps p-8 flex">
                  <div className="steps_bg"></div>
                  <div className="steps_details w-11/12">
                    {loadAPIDropdown()}
                    <button
                      className="steps_create ml-4 p-2 no-border step-indicator relative"
                      onClick={() =>
                        setApiCount([
                          ...apisCount,
                          { label: "API", id: `${apisCount.length + 1}` },
                        ])
                      }
                    >
                      Add Next API
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <label
                  htmlFor="test-data"
                  className="d-block bold font-urbanist mb-1"
                >
                  Select Test Data
                </label>
                <CreatableSelect
                  id="test-data"
                  options={options}
                  value={formData.testData}
                  onChange={(data) => createTestData(data)}
                  onCreateOption={(data) =>
                    createTestData({ value: data, label: data })
                  }
                />
              </div>
              <div className="mt-4">
                <label
                  htmlFor="tags"
                  className="d-block bold font-urbanist mb-1"
                >
                  Select Tags
                </label>
                <Select
                  isMulti
                  name="tags"
                  options={tags}
                  value={formData.selectedTags}
                  onChange={(data) =>
                    setFormData({ ...formData, selectedTags: data })
                  }
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
              <div className="mt-4 flex justify-end">
                <button
                  type="button"
                  className="font-medium next-button"
                  onClick={() => handleformSubmit()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
