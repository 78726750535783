import apiService from './api.service.js'
import storeService from './store.service.js';
import utils from './utils.service.js'
export default {
    async loadmetaData() {
        let apiResp = await apiService.post({
            url: utils.END_POINTS.loadmetadata,
            data: {}
        })
        return apiResp;
    },
    async getpages() {
        return await apiService.post({
            url: utils.END_POINTS.getpages,
            data: {
                "find": {},
                "options": {
                    "sort": {
                        "createdAt": -1
                    }
                }
            }
        });
    },
    async getpagesv2(payload) {
        return await apiService.post({
            url: utils.END_POINTS.getpagesv2,
            data: payload
        });
    },
    async getpagejobsv2(payload) {
        return await apiService.post({
            url: utils.END_POINTS.getpagejobsv2,
            data: payload
        });
    },
    async getpagecomparejobsv2(payload) {
        return await apiService.post({
            url: utils.END_POINTS.getpagecomparejobsv2,
            data: payload
        });
    },
    mergePagesJobsCompareJobs() {
        let pageWithDetails = storeService.store.PAGES_WITH_DETAILS;
        let pageJobs = storeService.store.PAGE_JOBS;
        let pageCompareJobs = storeService.store.PAGE_COMPARE_JOBS;
        pageJobs.items.forEach(job => {
            job.screentest = pageCompareJobs.items.find(x => x.jobId.toString() === job._id.toString());
        });
        pageWithDetails.items.forEach(item => {
            item['randomGuid'] = utils.guid(8);
            item.isScheduled = false;
            [...item.virtualDevices, ...item.physicalDevices].forEach(device => {
                device.jobs = pageJobs.items.filter(x => x.deviceId.toString() === device._id.toString() && x.pageId.toString() === item._id.toString());
            })
            let byPageIdHash = storeService.store.SCHEDULED_JOB_LIST.byPageIdHash;
            if(byPageIdHash.has(item._id)) {
                item.isScheduled = byPageIdHash.get(item._id).find(x => x.selectedTenantEnv == storeService.store.ENVIRONMENTS.selectedTenantEnv);
            }
        });
        storeService.saveStore({
            items: pageWithDetails.items
        }, 'PAGES_WITH_DETAILS');
    },
    async loadPageCompareJobs() {
        this.getpagecomparejobs().then(compareJobrep => {
            if (compareJobrep && compareJobrep.data) {
                storeService.saveStore({
                    items: compareJobrep.data,
                    lastLoaded: new Date(),
                    loaded: true,
                    isLoading: false,
                }, 'PAGE_COMPARE_JOBS');
                this.mergePagesJobsCompareJobs()
            }
        });
    },
    async loadPageJobs() {
        this.getpagejobs().then(jobResp => {
            if (jobResp && jobResp.data) {
                storeService.saveStore({
                    items: jobResp.data,
                    lastLoaded: new Date(),
                    loaded: true,
                    isLoading: false,
                }, 'PAGE_JOBS');
                this.mergePagesJobsCompareJobs()
            }
        });
    },
    async getpagejobs() {
        return await apiService.post({
            url: utils.END_POINTS.getpagejobs,
            data: {
                "selectedTenantEnv": storeService.store.ENVIRONMENTS.selectedTenantEnv,
                "find": {},
                "options": {
                    "sort": {
                        "createdAt": -1
                    }
                }
            }
        });
    },
    async getpagecomparejobs() {
        return await apiService.post({
            url: utils.END_POINTS.getpagecomparejobs,
            data: {
                "selectedTenantEnv": storeService.store.ENVIRONMENTS.selectedTenantEnv,
                "find": {},
                "options": {
                    "sort": {
                        "createdAt": -1
                    }
                }
            }
        });
    },
}