import apiService from './api.service.js'
import utils from './utils.service.js'
export default {
    async authenticate(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.authenticate,
            data: payload
        });
        return apiResp;
    },
    async tokeninfo(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.tokeninfo,
            data: payload
        });
        return apiResp;
    }
}