import React, { useEffect, useState } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import utilsService from "../services/utils.service";
import LeftNav from "../comps/atoms/LeftNav";
import apiService from "../services/api.service.js";
import dataService from "../services/data.service.js";
import storeService from "../services/store.service.js";
import AppIcon from "../comps/atoms/AppIcon/AppIcon.jsx";
import ChatBot from "../comps/atoms/ChatBot.jsx";
import SettingsModal from "../comps/atoms/SettingsModal.jsx";
import Logout from "../comps/atoms/Logout.jsx";
import { Tooltip } from 'react-tooltip';
import EnvironmentSelector from "../comps/atoms/EnvironmentSelect/EnvironementSelect.jsx";
import SectionLoader from '../comps/atoms/SectionLoader'; 
import SvgIcon from '../comps/atoms/SvgIcon.jsx';

const today = utilsService.getYMDFormat(new Date());
let initialCall = true;
export default () => {
  const [selectedTenantEnv, setselectedTenantEnv] = useState(storeService.store.ENVIRONMENTS.selectedTenantEnv);
  const aiHostname = utilsService.getAIRegressionHostname();
  const [homeStats, setHomeStats] = useState({});
  const [usertype, setUsertype] = useState((localStorage.getItem('userType') || '').toLowerCase() === 'admin');
  const [disableReload, setDisableReload] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const [settingsModalOpen, setOpenSettingsModal] = useState(false);
  const [date, setDate] = useState();
  const navigate = useNavigate();

  const getPagesSummary = async (precompute) => {
    let payload = {
      "filter_date": date || '',
      precompute: precompute,
      selectedTenantEnv: selectedTenantEnv,
    }
    const home = await apiService.post({
      url: utilsService.END_POINTS.homePageStats,
      data: payload
    });
    setHomeStats(home?.data || {});
    setDisableReload(false)
    setLoading(false)
  }
  useEffect(() => {
    storeService.subscribe('ENVIRONMENTS', ({ detail }) => {
      setselectedTenantEnv(detail.selectedTenantEnv)
    })
    storeService.sub(utilsService.CONSTANTS.EVENT_NAME.OPEN_SETTINGS_MODAL, ({ detail }) => {
      setOpenSettingsModal(detail);
    });
  }, []);

  const getPosition = failedNumber => {
    const total = homeStats?.failure?.total < 100 ? 100 : homeStats?.failure?.total;
    const pos = (failedNumber / total) * 360;
    return isNaN(pos) ? 0 : pos;
  };

  const openSettingsModal = () => {
    setOpenSettingsModal(true);
  };

  const getIntervals = () => {
    const { intervals = 25 } = homeStats?.failure || {};
    const total = homeStats?.failure?.total < 100 ? 100 : homeStats?.failure?.total;
    const points = [];
    for (let i = 0; i <= total; i += intervals) {
      points.push(i);
    }
    return points;
  }

  const getXPos = (indx, interval) => {
    const totalIntervals = getIntervals().length;
    const total = homeStats?.failure?.total < 100 ? 100 : homeStats?.failure?.total;
    const xPos = indx === totalIntervals - 1 ? 50 + (interval / total) * (358) : 60 + (interval / total) * (358);
    return xPos;
  }
  const dateChange = event => {
    setDate(event.target.value);
  }

  useEffect(() => {
    getPagesSummary();
  }, [date, selectedTenantEnv])

  const navigateToPage = (event, routePath) => {
    event.preventDefault()
    navigate('/' + routePath);
  }

  const handleReloadEvent = async (e) => {
    e.preventDefault();
    setDisableReload(true)
    await dataService.reloadhomestats({});
    getPagesSummary(false);
    setLoading(true);
  }

  return (
    <div className="flex main-layout">
      <div className="main-layout__left-ribbon-nav">
        <LeftNav />
      </div>
      <div className="main-layout__content relative">
        <div className="main-header-nav fluid-container py-4 header-bg absolute w-full">
          <div>

          </div>
          <div className="main-header-nav__links absolute">
            <ul>
              <li className="home-layout-banner">
                <EnvironmentSelector />
              </li>
              {usertype ?
                <li>
                  <button onClick={openSettingsModal} className="bg-none no-border">
                    <AppIcon iconName="settingsIcon" iconColor="#fff" />
                  </button>
                </li> : null
              }
              <li>
                <a href="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M27.8159 24.6347C26.0385 21.5152 23.2632 19.0846 19.9366 17.7342C21.5909 16.4935 22.8129 14.7637 23.4294 12.7899C24.046 10.8162 24.0259 8.69845 23.372 6.73673C22.7181 4.77502 21.4635 3.06878 19.786 1.8597C18.1085 0.65062 16.0931 0 14.0253 0C11.9574 0 9.94203 0.65062 8.26452 1.8597C6.58701 3.06878 5.33245 4.77502 4.67854 6.73673C4.02464 8.69845 4.00454 10.8162 4.6211 12.7899C5.23766 14.7637 6.45962 16.4935 8.11388 17.7342C4.78733 19.0846 2.01199 21.5152 0.234627 24.6347C0.124495 24.8117 0.0510929 25.0092 0.0187838 25.2152C-0.0135253 25.4212 -0.00408184 25.6316 0.0465531 25.8339C0.097188 26.0362 0.187981 26.2263 0.313533 26.3928C0.439086 26.5593 0.596835 26.6989 0.777404 26.8032C0.957972 26.9075 1.15767 26.9745 1.36463 27.0001C1.5716 27.0257 1.78159 27.0094 1.98214 26.9522C2.18269 26.895 2.36969 26.7981 2.53204 26.6672C2.69438 26.5363 2.82875 26.3741 2.92716 26.1903C5.27616 22.1301 9.4245 19.7085 14.0253 19.7085C18.626 19.7085 22.7744 22.1314 25.1234 26.1903C25.3366 26.5332 25.6749 26.7798 26.0667 26.8778C26.4584 26.9759 26.8729 26.9177 27.2226 26.7157C27.5723 26.5137 27.8297 26.1836 27.9404 25.7953C28.0511 25.4069 28.0065 24.9907 27.8159 24.6347ZM7.28421 9.8562C7.28421 8.52294 7.67957 7.21963 8.42028 6.11107C9.161 5.00251 10.2138 4.13849 11.4456 3.62828C12.6773 3.11806 14.0327 2.98457 15.3404 3.24467C16.648 3.50478 17.8492 4.1468 18.7919 5.08955C19.7347 6.03231 20.3767 7.23345 20.6368 8.54108C20.8969 9.84872 20.7634 11.2041 20.2532 12.4359C19.743 13.6677 18.879 14.7205 17.7704 15.4612C16.6618 16.2019 15.3585 16.5972 14.0253 16.5972C12.2381 16.5952 10.5246 15.8843 9.26089 14.6206C7.99715 13.3568 7.28627 11.6434 7.28421 9.8562Z"
                      fill="#fff"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <Logout />
              </li>
            </ul>
          </div>
        </div>

        <div className="module-summary relative mx-auto">
          <div className="module-summary_consumer mb-4 flex align-center">
            <div className="module-summary_consumer_img align-center justify-center">
              <img className="image-wrapper__image-base p-2" src={`/?action=screenshots&method=download&name=${homeStats?.consumerLogoName}`} />
            </div>
            <span>{homeStats?.consumerName}</span>
          </div>
          <div className="module-summary_head mb-4">
            <div className="section-box rounded-l p-8 module-summary_head_total w-full">
              {/* <h3 className="pb-6">Yesterday’s Test Update</h3> */}
              <div className="align-center justify-between mb-6">
                <div className="flex align-center">
                  <h3 className="mr-3">
                    Select Date
                  </h3>
                  <input
                    className="input-date"
                    type="date"
                    name="lasttestrun"
                    id="lasttestrun"
                    defaultValue={date}
                    max={today}
                    onChange={e => dateChange(e)}
                  />
                </div>
                {/* <a href="/" disabled={disableReload} onClick={e => handleReloadEvent(e)}>
                  <AppIcon iconName="reload" iconColor="#1B72E6" />
                </a> */}
              </div>

              <div className="align-center justify-center section-box-data">
                <div className="card-section flex">
                  <div className="card-item__icon total align-center justify-center mr-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="28" viewBox="0 0 25 28" fill="none">
                      <path d="M18.62 8.33C17.021 8.33 15.72 7.029 15.72 5.43V0H3C1.34 0 0 1.34 0 3V25C0 26.66 1.34 28 3 28H21.04C22.7 28 24.04 26.66 24.04 25V8.33H18.62ZM11.185 21.449H5.522C5.025 21.449 4.622 21.046 4.622 20.549C4.622 20.052 5.025 19.649 5.522 19.649H11.185C11.682 19.649 12.085 20.052 12.085 20.549C12.085 21.046 11.682 21.449 11.185 21.449ZM11.185 16.334H5.522C5.025 16.334 4.622 15.931 4.622 15.434C4.622 14.937 5.025 14.534 5.522 14.534H11.185C11.682 14.534 12.085 14.937 12.085 15.434C12.085 15.931 11.682 16.334 11.185 16.334ZM19.083 19.815L16.205 22.134C16.044 22.264 15.845 22.333 15.641 22.333C15.607 22.333 15.573 22.331 15.539 22.327C15.3 22.3 15.081 22.178 14.933 21.988L13.802 20.545C13.495 20.154 13.564 19.588 13.955 19.282C14.346 18.975 14.913 19.044 15.218 19.435L15.787 20.16L17.955 18.413C18.341 18.101 18.909 18.163 19.22 18.549C19.531 18.937 19.47 19.503 19.083 19.815ZM19.083 14.701L16.205 17.02C16.044 17.15 15.845 17.219 15.641 17.219C15.607 17.219 15.573 17.217 15.539 17.213C15.3 17.186 15.081 17.064 14.933 16.874L13.802 15.431C13.495 15.04 13.564 14.474 13.955 14.168C14.346 13.862 14.913 13.93 15.218 14.321L15.787 15.046L17.955 13.299C18.341 12.987 18.909 13.049 19.22 13.435C19.531 13.822 19.47 14.389 19.083 14.701Z" fill="url(#paint0_linear_1_513)" />
                      <path d="M17.52 5.43C17.52 6.047 18.003 6.53 18.62 6.53H24.04V5.81C24.04 5.28 23.83 4.78 23.46 4.4L19.64 0.59C19.27 0.21 18.76 0 18.23 0H17.52V5.43Z" fill="url(#paint1_linear_1_513)" />
                      <defs>
                        <linearGradient id="paint0_linear_1_513" x1="-1.5" y1="-3.5" x2="32.0054" y2="36.4653" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#42E797" />
                          <stop offset="1" stop-color="#0E98C2" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_1_513" x1="25" y1="-3" x2="15.5174" y2="8.51429" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#46F291" />
                          <stop offset="1" stop-color="#1458CE" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div className="card-item__details">
                    <p className="align-center count">
                      <span className="mr-2">
                        {homeStats?.totalRuns}
                      </span>
                      <img src="/icons/arrow-right.svg" className="arrow-svg-icon" />
                    </p>
                    <p className="text align-center mt-1">Total Test Runs</p>
                    <p className="banner align-center mt-1">Avg. {homeStats?.totalRunsAvg ? Math.round(homeStats?.totalRunsAvg) : ''}/per day last
                      month</p>
                  </div>
                </div>
                <div className="card-section module-summary_head_failed_tests flex">
                  <div className="card-item__icon failed align-center justify-center mr-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="28" viewBox="0 0 32 28" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0108 0C25.3261 0 31.2578 5.93042 31.2578 13.247C31.2578 20.5623 25.3261 26.494 18.0108 26.494C15.5013 26.494 13.1553 25.7957 11.1545 24.5835L9.13413 22.5631L12.1235 19.5737C12.8493 18.8479 12.8493 17.6657 12.1235 16.9399L11.0604 15.8768C10.3333 15.1497 9.15244 15.1497 8.42666 15.8768L5.83349 18.4686C5.14564 16.8667 4.76379 15.1013 4.76379 13.247C4.76379 5.93042 10.6942 0 18.0108 0ZM19.3329 11.6346C19.72 11.8791 20.0482 12.2074 20.2927 12.5945H25.5431C26.4442 12.5945 27.1739 13.3255 27.1739 14.2252C27.1739 15.1262 26.4442 15.8559 25.5431 15.8559H20.2927C19.7513 16.715 18.7941 17.2865 17.7022 17.2865C16.0113 17.2865 14.6409 15.916 14.6409 14.2252C14.6409 13.1345 15.2123 12.176 16.0715 11.6346V6.11219C16.0715 5.21249 16.8025 4.48149 17.7022 4.48149C18.6019 4.48149 19.3329 5.21249 19.3329 6.11219V11.6346ZM17.7022 13.094C18.3273 13.094 18.8333 13.6001 18.8333 14.2252C18.8333 14.8502 18.3273 15.3563 17.7022 15.3563C17.0771 15.3563 16.571 14.8502 16.571 14.2252C16.571 13.6001 17.0771 13.094 17.7022 13.094Z" fill="url(#paint0_linear_1_516)" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.247449 17.8313L1.20661 16.8733C1.53772 16.5422 2.07739 16.5422 2.40733 16.8733L5.68779 20.1526L8.96708 16.8733C9.29819 16.5422 9.83787 16.5422 10.1678 16.8733L11.127 17.8313C11.4569 18.1624 11.4569 18.7021 11.127 19.0332L7.84649 22.3125L11.127 25.593C11.4569 25.9229 11.4569 26.4626 11.127 26.7937L10.1678 27.7517C9.83787 28.0828 9.29819 28.0828 8.96708 27.7517L5.68779 24.4724L2.40733 27.7517C2.07739 28.0828 1.53772 28.0828 1.20661 27.7517L0.247449 26.7937C-0.082483 26.4626 -0.082483 25.9229 0.247449 25.593L3.52792 22.3125L0.247449 19.0332C-0.082483 18.7021 -0.082483 18.1624 0.247449 17.8313Z" fill="url(#paint1_linear_1_516)" />
                      <defs>
                        <linearGradient id="paint0_linear_1_516" x1="3.0625" y1="-6.5625" x2="41.125" y2="38.0625" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#FF4747" />
                          <stop offset="1" stop-color="#FF67CC" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_1_516" x1="-1.3125" y1="16.625" x2="14.875" y2="32.8125" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#B20000" />
                          <stop offset="1" stop-color="#FF0000" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div className="card-item__details">
                    <p className="align-center count">
                      <span className="mr-2">
                        {homeStats?.totalFailed}
                      </span>
                      <img src="/icons/arrow-right.svg" className="arrow-svg-icon" />
                    </p>
                    <p className="text align-center mt-1">Failed Tests</p>
                    <p className="banner align-center mt-1">Avg. {homeStats?.consecutiveFailAvg ? Math.round(homeStats?.consecutiveFailAvg) : ''}/per day last
                      month</p>
                  </div>
                </div>
                <div className="card-section module-summary_failed_consecutive flex">
                  <div className="card-item__icon consecutive align-center justify-center mr-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                      <path d="M27.7313 25.1938H17.8229C17.8229 24.4688 17.2792 23.925 16.5542 23.925H15.7083V21.8104H13.1708V23.925H12.325C11.6 23.925 11.0562 24.4688 11.0562 25.1938H1.26875C0.54375 25.1938 0 25.7375 0 26.4625C0 27.1875 0.54375 27.7313 1.26875 27.7313H11.1167C11.1167 28.4563 11.6604 29 12.3854 29H16.5542C17.2792 29 17.8229 28.4563 17.8229 27.7313H27.7313C28.4563 27.7313 29 27.1875 29 26.4625C29 25.7979 28.4563 25.1938 27.7313 25.1938Z" fill="url(#paint0_linear_1_521)" />
                      <path d="M4.16884 20.6021H24.8313C26.3418 20.6021 27.3084 18.9104 26.4626 17.6417L16.1918 0.90625C15.4063 -0.302083 13.6543 -0.302083 12.8688 0.90625L2.47718 17.6417C1.69176 18.9104 2.65843 20.6021 4.16884 20.6021ZM12.9293 6.1625C12.9293 5.25625 13.6543 4.59167 14.5605 4.59167C15.4668 4.59167 16.1313 5.31667 16.1313 6.1625V6.82708L15.648 12.2646C15.5876 12.8687 15.1043 13.2917 14.5001 13.2917C13.8959 13.2917 13.4126 12.8083 13.3522 12.2042L12.9293 6.76667V6.1625ZM14.5001 14.1979C15.4063 14.1979 16.0709 14.9229 16.0709 15.7688C16.0709 16.675 15.3459 17.3396 14.5001 17.3396C13.6543 17.3396 12.9293 16.6146 12.9293 15.7688C12.8688 14.8625 13.5938 14.1979 14.5001 14.1979Z" fill="url(#paint1_linear_1_521)" />
                      <defs>
                        <linearGradient id="paint0_linear_1_521" x1="-1.86222" y1="20.0296" x2="2.37209" y2="40.0539" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#FF0E0E" />
                          <stop offset="1" stop-color="#B30E11" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_1_521" x1="0.619276" y1="-5.10309" x2="28.9804" y2="34.5673" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#FF4747" />
                          <stop offset="1" stop-color="#FF67CC" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div className="card-item__details">
                    <p className="align-center count">
                      <span className="mr-2">
                        {Math.round(homeStats?.consecutiveFail)}
                      </span>
                      <img src="/icons/arrow-right.svg" className="arrow-svg-icon" />
                    </p>
                    <p className="text align-center mt-1">Consecutive Failed Tests</p>
                    <p className="banner align-center mt-1">Avg. {homeStats?.consecutiveFailAvg ? Math.round(homeStats?.consecutiveFailAvg) : ''}/per day last
                      month</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="module-summary_upcoming flex justify-between mb-4">
            <div className="section-box rounded-l module-summary_upcoming_release align-center justify-center">
              <div className="module-summary_upcoming_release_info text-center card-section">
                <h4 className="card-item__no mb-1">{homeStats?.upcomingReleaseDate}</h4>
                <p className="sub-heading-2 card-item__desc">Upcoming release</p>
              </div>
            </div>
            <div className="module-summary_upcoming_release_failure section-box rounded-l align-center">
              <div className="module-summary_upcoming_release_details">
                <ul className="module-summary_upcoming_release_details_list no-disc card-section">
                  <li className="title pt-4 pb-2 px-5 align-center heading pointer" id="failuretext">
                    <h4 className="h4-heading mr-2">Failures</h4>
                    <SvgIcon path="/icons/info-svg.svg"></SvgIcon>
                    <Tooltip
                      anchorSelect="#failuretext"
                      content="Number of failed tests out of total tests ran per module."
                      place="top"
                      style={{
                        backgroundColor: "#000",
                        color: "#fff",
                        borderRadius: "5px",
                        zIndex: 22,
                        fontWeight: "bold",
                      }}
                    />
                  </li>

                  <li className="title mx-7 py-5 align-center justify-between">
                    <p className="sub-heading-2">API Glide</p>
                    <p className="flex"> <b>{homeStats?.API_Collections_Failed}/{homeStats?.APIGlideCollectionsTested}</b>
                      <a onClick={e => navigateToPage(e, 'api-regression')} className="align-center pl-2">
                        <img src="/icons/arrow-right.svg" className="arrow-svg-icon" />
                      </a>
                    </p>
                  </li>

                  <li className="title mx-7 py-5 align-center justify-between">
                    <p className="sub-heading-2">Sight Sense</p>
                    <p className="flex"> <b>{homeStats?.UI_Pages_Failed}/{homeStats?.SightSensePagesTested}</b>
                      <a onClick={e => navigateToPage(e, 'ui-regression')} className="align-center pl-2">
                        <img src="/icons/arrow-right.svg" className="arrow-svg-icon" />
                      </a>
                    </p>
                  </li>

                  <li className="title mx-7 pt-5 pb-8 align-center justify-between">
                    <p className="sub-heading-2">Page Blazer</p>
                    <p className="flex"> <b>{homeStats?.User_Journeys_Failed}/{homeStats?.PageBlazerPagesTested}</b>
                      <a onClick={e => navigateToPage(e, 'page-action')} className="align-center pl-2">
                        <img src="/icons/arrow-right.svg" className="arrow-svg-icon" />
                      </a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div className="section-box rounded-l module-summary_failure_devices p-5">
              <div className="trend-box">
                <div className="py-4 barchart">
                  <div className="flex">
                    <h4 className="h4-heading module-summary_failure_devices-heading"> Failures on Devices</h4>
                  </div>
                  <div className="trends_svg">
                    {homeStats?.failure && <svg width="350" height="114" preserveAspectRatio="none" viewBox="0 0 450 114">
                      <text x="45" y="19" textAnchor="end">
                        Desktop
                      </text>
                      <text x="45" y="66" textAnchor="end">
                        Mobile
                      </text>
                      {getIntervals().map((interval, index) => (
                        <g key={index}>
                          <text x={getXPos(index, interval)} y="100" textAnchor="middle">
                            {interval}
                          </text>
                          <line x1={getXPos(index, interval)} y1="0" x2={getXPos(index, interval)} y2="84" stroke="#D2DFF6" stroke-width="1" />
                          <path d={`M${60 + (interval / homeStats?.failure?.total < 100 ? 100 : homeStats?.failure?.total || 100) * (418 - 60)},0 V72`} stroke="#D2DFF6" />
                        </g>
                      ))}
                      <path
                        d="M60,10 h350 q5,0 5,5 v0 q0,5 -5,5 h-350 z"
                        fill="#E6E8F3"
                      ></path>
                      <path
                        d="M60,57 h350 q5,0 5,5 v0 q0,5 -5,5 h-350 z"
                        fill="#E6E8F3"
                      ></path>
                      <path
                        d={`M60,10 h${getPosition(homeStats?.failure?.desktop)} q5,0 5,5 v0 q0,5 -5,5 h-${getPosition(homeStats?.failure?.desktop)} z`}
                        fill="#C74068"
                        data-tooltip-id="desktop-tooltip"
                        data-tooltip-content={`Desktop Failure: ${homeStats?.failure?.desktop}`}
                      ></path>
                      <path
                        d={`M60,57 h${getPosition(homeStats?.failure?.mobile)} q5,0 5,5 v0 q0,5 -5,5 h-${getPosition(homeStats?.failure?.mobile)} z`}
                        fill="#C74068"
                        data-tooltip-id="mobile-tooltip"
                        data-tooltip-content={`Mobile Failure: ${homeStats?.failure?.mobile}`}
                      ></path>
                    </svg>}
                    <Tooltip id="desktop-tooltip" />
                    <Tooltip id="mobile-tooltip" />
                  </div>
                </div>
              </div>
              <div class="trend-status_details">
                <div class="status">
                  <div class="circle total"></div>
                  <span class="status-text">Total</span>
                </div>
                <div class="status">
                  <div class="circle failed"></div>
                  <span class="status-text">Failed</span>
                </div>
              </div>
            </div>
          </div>
          <div className="section-box rounded-l p-8">
            <div className="pointer moduletext-tooltip">
              <h4 className="pb-6 h4-heading mr-2" id="moduletext">
              Tests Created by Modules{" "}
                {/* {homeStats?.lastRunDate && <span>Last Updated on {homeStats?.lastRunDate}</span>} */}
                <SvgIcon path="/icons/info-svg.svg"></SvgIcon>
              </h4>                
              <Tooltip
                anchorSelect="#moduletext"
                content="Total number of tests generated within each module."
                place="right-start"
                style={{
                  backgroundColor: "#000",
                  color: "#fff",
                  borderRadius: "5px",
                  zIndex: 22,
                  fontWeight: "bold",
                }}
              />              
            </div>          
            <div className="section-box testruns_results align-center justify-between">
              <div className="align-center testrun-cards">
                <div className="align-center justify-center svg mr-4">
                  <svg width="28" height="36" viewBox="0 0 28 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="UsagePulse">
                      <path id="Subtract" fill-rule="evenodd" clip-rule="evenodd" d="M14 28C21.732 28 28 21.732 28 14C28 6.26802 21.732 0 14 0C6.26802 0 0 6.26802 0 14C0 21.732 6.26802 28 14 28ZM8.00001 14.0001C8.00001 10.6864 10.6863 8.00008 14 8.00008C17.3137 8.00008 20 10.6864 20 14.0001C20 17.3138 17.3137 20.0001 14 20.0001C10.6863 20.0001 8.00001 17.3138 8.00001 14.0001ZM14 4.00007C8.47717 4.00007 4.00001 8.47723 4.00001 14.0001C4.00001 19.5229 8.47717 24.0001 14 24.0001C19.5229 24.0001 24 19.5229 24 14.0001C24 8.47723 19.5229 4.00007 14 4.00007Z" fill="url(#paint0_linear_1_480)" />
                      <path id="Vector" d="M11.8573 13.5367V24.9962L11.1808 23.9606L10.1471 22.3786C9.60116 21.5465 8.48614 21.3136 7.66006 21.8644C7.25936 22.131 6.98785 22.5397 6.89059 23.0114C6.79331 23.4845 6.88623 23.9679 7.15047 24.3722L8.90138 27.0455L11.5554 31.106C12.351 32.3247 12.7749 33.7426 12.7749 35.2047C12.7749 35.6441 13.1277 36.0001 13.5632 36.0001H22.4355C22.871 36.0001 23.2238 35.6441 23.2238 35.2047V34.9908C23.2238 33.6666 23.5737 32.3658 24.2387 31.2262C24.9298 30.0368 25.2941 28.6803 25.2941 27.3034V21.4954C25.2941 20.4979 24.4869 19.6849 23.4997 19.6849C22.8826 19.6849 22.3367 19.9999 22.013 20.4833V20.2621C22.013 19.2645 21.2057 18.4515 20.2185 18.4515C19.6014 18.4515 19.0556 18.7665 18.7318 19.2499V19.1971C18.7318 18.1996 17.9246 17.3866 16.9373 17.3866C16.3203 17.3866 15.7744 17.7016 15.4506 18.1849V13.5459C15.4506 12.5483 14.6434 11.7354 13.6518 11.7354C13.1727 11.7354 12.7226 11.9228 12.3843 12.2642C12.0461 12.6055 11.8602 13.0596 11.8602 13.543L11.8573 13.5367Z" fill="#626DFE" />
                    </g>
                    <defs>
                      <linearGradient id="paint0_linear_1_480" x1="-1.94445" y1="-1.44392e-07" x2="46.2751" y2="46.6922" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#D6C0FF" />
                        <stop offset="1" stop-color="#5CA0FF" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="module-name">
                  <a className="module-name__link align-center" href="/usage-regression">
                    {homeStats?.usagePulse}
                    <img
                      src="/icons/arrow-right.svg"
                      className="arrow-svg-icon ml-3"
                    ></img>
                  </a>
                  <p>UsagePulse</p>
                </div>
              </div>
              <div className="align-center testrun-cards">
                <div className="align-center justify-center svg mr-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="30" viewBox="0 0 32 30" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M32 5.33337H0V22.5334C0 23.6379 0.895431 24.5334 2 24.5334H10.0905C9.7745 23.7125 9.59999 22.8117 9.59999 21.8667C9.59999 18.0375 12.4654 14.9334 16 14.9334C19.5346 14.9334 22.4 18.0375 22.4 21.8667C22.4 22.8117 22.2255 23.7125 21.9095 24.5334H30C31.1046 24.5334 32 23.6379 32 22.5334V5.33337Z" fill="url(#paint0_linear_1_485)" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895431 0 0 0.895432 0 2V7.46667H32V2C32 0.895431 31.1046 0 30 0H2ZM4.80002 5.3334C5.68367 5.3334 6.40002 4.61706 6.40002 3.7334C6.40002 2.84975 5.68367 2.1334 4.80002 2.1334C3.91636 2.1334 3.20002 2.84975 3.20002 3.7334C3.20002 4.61706 3.91636 5.3334 4.80002 5.3334ZM11.7334 3.7334C11.7334 4.61706 11.017 5.3334 10.1334 5.3334C9.2497 5.3334 8.53335 4.61706 8.53335 3.7334C8.53335 2.84975 9.2497 2.1334 10.1334 2.1334C11.017 2.1334 11.7334 2.84975 11.7334 3.7334ZM15.4667 5.3334C16.3503 5.3334 17.0667 4.61706 17.0667 3.7334C17.0667 2.84975 16.3503 2.1334 15.4667 2.1334C14.583 2.1334 13.8667 2.84975 13.8667 3.7334C13.8667 4.61706 14.583 5.3334 15.4667 5.3334Z" fill="url(#paint1_linear_1_485)" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16 13.8667C20.8927 13.8667 25.1383 16.3489 27.248 18.7011C28.2789 19.8511 28.8 21.0022 28.8 21.8667C28.8 22.7312 28.2789 23.8822 27.248 25.0323C25.1383 27.3845 20.8924 29.8667 16 29.8667C11.1076 29.8667 6.86177 27.3845 4.75203 25.0323C3.72117 23.8823 3.20001 22.7312 3.20001 21.8667C3.20001 21.0022 3.72114 19.8512 4.75203 18.7011C6.86174 16.3489 11.1076 13.8667 16 13.8667ZM16 17.4223C13.4777 17.4223 11.4286 19.4134 11.4286 21.8667C11.4286 24.32 13.4777 26.3111 16 26.3111C18.5223 26.3111 20.5715 24.32 20.5715 21.8667C20.5715 19.4134 18.5223 17.4223 16 17.4223Z" fill="#626DFE" />
                    <defs>
                      <linearGradient id="paint0_linear_1_485" x1="-2.13333" y1="6.40004" x2="36.6526" y2="47.9592" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#D6C0FF" />
                        <stop offset="1" stop-color="#5CA0FF" />
                      </linearGradient>
                      <linearGradient id="paint1_linear_1_485" x1="1.54838" y1="-0.72258" x2="-2.95258" y2="28.3811" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#73A1FF" />
                        <stop offset="1" stop-color="#0015FF" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="module-name">
                  <a className="module-name__link align-center" href="/ui-regression">
                    {homeStats?.SightSense}
                    <img
                      src="/icons/arrow-right.svg"
                      className="arrow-svg-icon ml-3"
                    ></img>
                  </a>
                  <p>SightSense</p>
                </div>
              </div>
              <div className="align-center testrun-cards">
                <div className="align-center justify-center svg mr-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
                    <path d="M28.0013 21.3888H7.00033C3.13615 21.3888 0 18.1948 0 14.2592C0 10.7413 2.52005 7.8039 5.81433 7.23353C7.24203 2.97529 11.2271 0 15.7449 0C20.4498 0 24.4249 3.1368 25.7502 7.50934C26.4683 7.25283 27.2254 7.12971 27.9997 7.12971C31.8639 7.12971 35 10.3237 35 14.2593C35.0014 18.1948 31.8656 21.3888 28.0013 21.3888Z" fill="url(#paint0_linear_1_495)" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9048 12.0632C19.9023 11.0917 20.6672 10.3268 21.6386 10.3293C22.61 10.3318 23.3789 11.1006 23.3814 12.072L23.3928 16.5201L27.4829 16.5306C28.0197 16.5064 28.4554 16.9421 28.4568 17.5044L28.4606 18.9871C28.4876 19.524 28.0541 19.9575 27.4917 19.9561L26.9804 19.9548L26.9884 23.0735C26.997 26.4479 24.2432 29.2017 20.8689 29.193L19.8974 29.1906L19.9101 34.1499C19.9114 34.6611 19.5035 35.0691 18.9922 35.0678L16.896 35.0624C16.3847 35.0611 15.9747 34.6511 15.9734 34.1398L15.9607 29.1805L14.9893 29.178C11.6149 29.1694 8.84697 26.4015 8.83834 23.0271L8.83036 19.9083L8.31909 19.907C7.78232 19.9312 7.34663 19.4955 7.34519 18.9331L7.3414 17.4504C7.31446 16.9135 7.74793 16.4801 8.31033 16.4815L12.4005 16.492L12.3891 12.0439C12.3866 11.0725 13.1516 10.3076 14.123 10.3101C15.0944 10.3125 15.8633 11.0814 15.8657 12.0528L15.8771 16.5009L19.9161 16.5112L19.9048 12.0632ZM12.6389 21.3889C12.102 21.3889 11.6667 21.8242 11.6667 22.3611C11.6667 24.5089 13.4078 26.25 15.5556 26.25H20.4167C22.5645 26.25 24.3056 24.5089 24.3056 22.3611C24.3056 21.8242 23.8703 21.3889 23.3334 21.3889H12.6389Z" fill="#626DFE" />
                    <defs>
                      <linearGradient id="paint0_linear_1_495" x1="-2.43056" y1="-1.10299e-07" x2="30.8361" y2="52.7122" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#D6C0FF" />
                        <stop offset="1" stop-color="#5CA0FF" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="module-name">
                  <a className="module-name__link align-center" href="/api-regression">
                    {homeStats?.APIGlide}
                    <img
                      src="/icons/arrow-right.svg"
                      className="arrow-svg-icon ml-3"
                    ></img>
                  </a>
                  <p>APIGlide</p>
                </div>
              </div>
              <div className="align-center testrun-cards">
                <div className="align-center justify-center svg mr-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 2C0 0.895433 0.89543 0 2 0H11.6005C12.705 0 13.6005 0.89543 13.6005 2V10.2176C13.2314 9.99765 12.8 9.87132 12.3392 9.87132C10.9762 9.87132 9.87132 10.9762 9.87132 12.3392C9.87132 12.8 9.99765 13.2314 10.2176 13.6005H2C0.895433 13.6005 0 12.705 0 11.6005V2ZM13.3263 20.7298C13.419 20.7298 13.5105 20.7247 13.6005 20.7147V28.601C13.6005 29.7056 12.705 30.601 11.6005 30.601H2C0.895433 30.601 0 29.7056 0 28.601V19.0006C0 17.896 0.89543 17.0006 2 17.0006H11.2047C10.9848 17.3697 10.8585 17.8011 10.8585 18.2619C10.8585 19.6249 11.9633 20.7298 13.3263 20.7298ZM17.0007 2C17.0007 0.895433 17.8961 0 19.0007 0H28.6011C29.7057 0 30.6011 0.89543 30.6011 2V11.6005C30.6011 12.705 29.7057 13.6005 28.6011 13.6005H19.0007C17.8961 13.6005 17.0007 12.705 17.0007 11.6005V2ZM19.0007 17.0006C17.8961 17.0006 17.0007 17.896 17.0007 19.0006V28.601C17.0007 29.7056 17.8961 30.601 19.0007 30.601H28.6011C29.7057 30.601 30.6011 29.7056 30.6011 28.601V19.0006C30.6011 17.896 29.7057 17.0006 28.6011 17.0006H19.0007Z" fill="url(#paint0_linear_1_500)" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.66693 5.66675C6.56236 5.66675 5.66693 6.56218 5.66693 7.66675V21.8007C5.66693 22.9053 6.56236 23.8007 7.66693 23.8007H21.8009C22.9055 23.8007 23.8009 22.9053 23.8009 21.8007V7.66675C23.8009 6.56218 22.9055 5.66675 21.8009 5.66675H7.66693ZM17.3714 13.3246C17.6923 13.3246 17.9253 13.4779 18.056 13.767C18.1854 14.0504 18.1533 14.3389 17.9628 14.6239C17.3128 15.6095 14.3956 20.034 14.3956 20.034C14.242 20.2667 14.0227 20.4001 13.7938 20.4006C13.6157 20.4006 13.4492 20.3229 13.3111 20.1757C13.1257 19.9787 13.0638 19.7264 13.1206 19.4057L13.6985 16.1919C13.7018 16.1748 13.7049 16.1571 13.7074 16.1407H13.6715C13.478 16.1418 12.0833 16.1418 12.0833 16.1418C11.7726 16.1407 11.5403 15.9888 11.4114 15.7033C11.2835 15.4186 11.3133 15.1297 11.5018 14.845C11.5018 14.845 14.4114 10.4315 15.0797 9.42107C15.2272 9.19585 15.4475 9.06691 15.6808 9.06691C15.9574 9.06691 16.2008 9.2487 16.3152 9.54199C16.3901 9.73429 16.3684 9.92659 16.3455 10.0539C16.3455 10.0539 15.9133 12.4879 15.7587 13.3285C15.8298 13.328 16.0685 13.3277 16.3427 13.3273C16.7556 13.3267 17.2489 13.3259 17.3714 13.3246Z" fill="#626DFE" />
                    <defs>
                      <linearGradient id="paint0_linear_1_500" x1="-2.12508" y1="-1.57805e-07" x2="50.5738" y2="51.0297" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#D6C0FF" />
                        <stop offset="1" stop-color="#5CA0FF" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="module-name">
                  <a className="module-name__link align-center" href="/page-action">
                    {homeStats?.PageBlazer}
                    <img
                      src="/icons/arrow-right.svg"
                      className="arrow-svg-icon ml-3"
                    ></img>
                  </a>
                  <p>PageBlazer</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {settingsModalOpen ? <SettingsModal /> : null}
        <div className="fluid-container py-4">
          <Outlet />
        </div>
        <ChatBot />
        {
          isLoading && (
            <SectionLoader></SectionLoader>
          )
        }
      </div>
    </div>
  );
};
