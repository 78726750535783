import { useState, useEffect } from "react";
import apiService from "../../services/api.service";
import utilsService from "../../services/utils.service";
import storeService from "../../services/store.service";
import { Link } from "react-router-dom";
import AppIcon from "./AppIcon";
import ChatDetailsModal from "./ChatDetailsModal";
export default () => {
  const storedMessages = JSON.parse(localStorage.getItem("chatMessages")) || [];
  const [messages, setMessages] = useState(storedMessages);
  const [showMessages, toggleMessages] = useState(false);
  const [indexToshow, updateIndexesToShow] = useState();
  const [inputText, setInputText] = useState("");
  const [chatOpen, setChatOpen] = useState(storedMessages.length > 1);
  const [showMoreDetails, setShowDetails] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const visualUIRegression = 'http://' + window.location.hostname + '/';
  const usageUIRegression = 'http://' + window.location.hostname + ':3100/';

  const adjustChatScroll = () => {
    const chatContainer = document.querySelector('.chatbot-messages-body');
    if(chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }
  useEffect(() => {
    // Automatically scroll to the bottom of the chat window when messages update
    adjustChatScroll();
    localStorage.setItem('chatMessages', JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
      storeService.sub(utilsService.CONSTANTS.EVENT_NAME.OPEN_CHAT_DETAILS_MODAL, ({ detail }) => {
          setShowDetails(detail.showModal);
      });
  }, []);

  

  const getBotResponse = async (inputText) => {
    const botResponse = await apiService.post({
      url: utilsService.END_POINTS.getBotResponse,
      data: {"question": inputText}
    });
    return botResponse.data || {};
  }

  const sendMessage = async () => {
    if (!inputText.trim()) return;
    setInputText("");
    getBotResponse(inputText).then((res) => {
      setMessages([
        ...messages,
        { text: inputText, isUser: true },
        { text: res.answer, isUser: false, details: res.details || [] },
      ]);
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  const toggleList = index => {
    setShowDetails(true);
    updateIndexesToShow([index]);
  };

  const getDetailsLink = detail => {
    const { TestType = 1, PageID = '', DeviceID = ''  } = detail;
    let linkURL = visualUIRegression;
    if(TestType === 1) {
      linkURL = `${visualUIRegression}ui-regression/page/${PageID}/device/${DeviceID}`;
    } else if(TestType === 2) {
      linkURL = `${usageUIRegression}page-action/page/${PageID}/device/${DeviceID}`;
    } else if(TestType === 3) {
      linkURL = `${visualUIRegression}api-regression/collection/${PageID}`;
    }
    return linkURL;
  };

  const clearSession = () => {
    setShowWarning(false);
    setMessages([]);
    toggleMessages(false);
    setChatOpen(false);
  }

  const openChat = () => {
    if(!chatOpen) {
      const storedMessages = JSON.parse(localStorage.getItem("chatMessages"));
      if(storedMessages && storedMessages.length === 0) {
        setMessages([{ text: "Hi there! How can I help you?", isUser: false }]);
       }
      setChatOpen(true);
    }
    toggleMessages(!showMessages);
  }

  return (
    <div className={`chat-container fixed ${chatOpen ? '' : 'closed-chat'}`}>
    {showMessages ?
      <div className={`chatbot-messages`}>
        <div className="chatbot-messages-header align-center justify-between w-full">
            <h3>Chatbot</h3>
            <div className="align-center">
            <button type="button" className="mr-8" onClick={() => toggleMessages(false)}>
                <AppIcon iconName="downArrow" />
            </button>
            <button type="button" onClick={() => setShowWarning(true)}>
                <AppIcon iconName="closeIcon" iconColor="#434343" />
            </button>
            </div>
        </div>
        <div className={`chatbot-messages-body`}>
          {!showWarning && messages.map((message, index) => (
            <div
              key={index}
              className={`conversation ${message.isUser ? "user-message" : "bot-message"}`}
            >
              <p>{message.text}</p>
              {!message.isUser && message.details?.length > 0 ? 
                <div className="page-details">
                  <ul className="no-disc">
                    {message.details?.slice(0, 3).map((detail, msgIndex) => {
                      return <li key={`${detail.DeviceID}_${detail.PageID}_${msgIndex}`}>
                          <Link to={getDetailsLink(detail)} className="p-3 w-full align-center justify-between">
                             <p>{detail.PageName}</p>
                             <AppIcon iconName="rightArrow"></AppIcon>
                          </Link>
                        </li>
                    })}
                  </ul>
                  {message.details?.length > 3 ? 
                    <button 
                      onClick={() => toggleList(index)}
                      className="btn-medium-2 w-full mt-2" 
                      type="button">
                      View {message.details?.length - 3} More
                    </button> :
                     null }
                </div>: 
              null }
            </div>
          ))}
          {showWarning ? 
          <div className="close-warning text-center">
               <p className="close-warning_heading font-urbanist ">Are you sure you want to close the chat window?</p>
               <p className="close-warning_msg font-urbanist mb-8">You will lose the chat if you close the chat window</p>
               <button 
                  className="close-warning_confirm font-urbanist px-2 py-4 mb-2 w-full no-border" 
                  type="button"
                  onClick={() => clearSession()}
                >Yes, Close</button>
               <button 
                  className="close-warning_cancel font-urbanist px-2 py-4 w-full no-border"
                  type="button"
                  onClick={() => setShowWarning(false)}
                >
                    Cancel
                </button>
          </div> : null }
        </div>
        <div className="input-container flex w-full justify-between">
          <textarea
            type="text"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Type your query here..."
            className="w-full"
          ></textarea>
          <button type="" onClick={sendMessage}>
            <AppIcon iconName="chatMessageEnterIcon" />
          </button>
        </div>
      </div>
      :
      <button type="button" 
        className={`chatbot-button ${chatOpen ? 'align-center justify-between w-full' : ''}`}
        onClick={openChat}>
        { chatOpen ? <p>Chatbot</p> : null }
        { chatOpen ? <AppIcon iconName="upArrow" iconColor="#ffffff" /> :
        <AppIcon iconName="chatIcon" iconColor="#ffffff" /> }
      </button> }
      {showMoreDetails ? <ChatDetailsModal detailsToDisplay={messages[indexToshow]} /> : null }
    </div>
  );
};
