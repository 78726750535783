import React, { useEffect, useState } from 'react'
import { Outlet, Link, useLocation, useParams } from "react-router-dom";
import storeService from '../../services/store.service.js';
import utilsService from '../../services/utils.service.js';
import apiService from '../../services/api.service.js';
import ApiBreadCurmbs from '../../comps/molecules/ApiBreadCurmbs';
import TestCaseInfo from '../../comps/molecules/TestCaseInfo';
import EditCollectionModal from '../../comps/organisms/EditCollectionModal';
import CreateAPIModal from '../../comps/organisms/CreateAPIModal';
import LeftNav from '../../comps/atoms/LeftNav.jsx';
import Logout from '../../comps/atoms/Logout.jsx';
import EnvironmentSelector from "../../comps/atoms/EnvironmentSelect/EnvironementSelect.jsx";

export default () => {
    const [openCreateCollectionModal, setOpenCreateCollectionModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showSideModal, setShowSideModal] = useState(false)
    const location = useLocation();
    const params = useParams();
    const [showCreateBtn, setShowCreateBtn] = useState(true);
    const [testcaseItem, setTestcaseItem] = useState(null)
    useEffect(() => {
        if(params.collectionId) {
            setShowCreateBtn(false)
        } else if(location.pathname.indexOf('create-collection') >= 0) {
            setShowCreateBtn(false)
        } else {
            setShowCreateBtn(true)
        }
    }, [location])
    const { pathname = '' } = useLocation();
    useEffect(() => {
        storeService.sub(utilsService.CONSTANTS.EVENT_NAME.OPEN_TESTCASES_SIDEBAR_MODAL, ({ detail }) => {
            setTestcaseItem(detail.modalData?.testCaseItem)
            setShowSideModal(detail.showModal);
        });

        storeService.sub(utilsService.CONSTANTS.EVENT_NAME.OPEN_CHAIN_API_MODAL, ({ detail }) => {
            setOpenCreateCollectionModal(detail);
        });

        storeService.sub(utilsService.CONSTANTS.EVENT_NAME.OPEN_EDIT_CHAIN_API_MODAL, ({ detail }) => {
            setShowEditModal(detail);
       });

        const loadDevices = async () => {
            const devicesResp = await apiService.get({
                url: utilsService.END_POINTS.getDevices
            });
            storeService.saveStore({
                items: devicesResp.data.items,
                lastLoaded: new Date(),
                isLoading: false,
                loaded: true
            }, 'DEVICES')
        }
        loadDevices()
        document.addEventListener('scroll', () => {
            let headerElem = document.querySelector('.main-header-nav');
            headerElem.classList.remove('sticky');
            if (window.scrollY > 5) {
                headerElem.classList.add('sticky');
            }
        })
    }, [])
 
    const closeModal = (e) => {
        e && e.preventDefault();
        setShowSideModal(false)
    }

    return (
      <>
        <div className="flex main-layout">
          <div className="main-layout__left-ribbon-nav">
            <nav className="nav-left-ribbon left-nav">
              <LeftNav />
            </nav>
          </div>

          <div className="main-layout__content">
            <div className="main-header-nav fluid-container py-4">
              <div className="flex align-center">
                <ApiBreadCurmbs></ApiBreadCurmbs>
                {showCreateBtn && (
                  <button
                    // href="/api-regression/create-collection"
                    className="link-nav px-4 py-2 align-center ml-3 no-border"
                    onClick={() => setOpenCreateCollectionModal(true)}
                  >
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="Group 216">
                      <path id="Vector" d="M22.75 4.0625C22.75 3.614 23.1156 3.25 23.5625 3.25H25.1875C25.6376 3.25 26 3.614 26 4.0625V21.9375C26 22.386 25.6376 22.75 25.1875 22.75H23.5625C23.1156 22.75 22.75 22.386 22.75 21.9375V4.0625Z" fill="#1D4273"/>
                      <path id="Vector_2" d="M16.25 2.4375C16.25 1.989 16.6156 1.625 17.0625 1.625H18.6875C19.1376 1.625 19.5 1.989 19.5 2.4375V23.5625C19.5 24.011 19.1376 24.375 18.6875 24.375H17.0625C16.6156 24.375 16.25 24.011 16.25 23.5625V2.4375Z" fill="#1D4273"/>
                      <path id="Vector_3" d="M0 0.8125C0 0.364 0.365625 0 0.8125 0H12.1875C12.6376 0 13 0.364 13 0.8125V25.1875C13 25.636 12.6376 26 12.1875 26H0.8125C0.365625 26 0 25.636 0 25.1875V0.8125Z" fill="#1D4273"/>
                      </g>
                    </svg>
                    <span className="ml-3 font-btn-large btn-font-color">Add Collection</span>
                  </button>
                )}
              </div>
              <div className="main-header-nav__links">
                <ul>
                  {
                    <li>
                      <div>
                        <EnvironmentSelector />
                      </div>
                    </li>
                  }
                  <li>
                    <a href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                      >
                        <path
                          d="M27.8159 24.6347C26.0385 21.5152 23.2632 19.0846 19.9366 17.7342C21.5909 16.4935 22.8129 14.7637 23.4294 12.7899C24.046 10.8162 24.0259 8.69845 23.372 6.73673C22.7181 4.77502 21.4635 3.06878 19.786 1.8597C18.1085 0.65062 16.0931 0 14.0253 0C11.9574 0 9.94203 0.65062 8.26452 1.8597C6.58701 3.06878 5.33245 4.77502 4.67854 6.73673C4.02464 8.69845 4.00454 10.8162 4.6211 12.7899C5.23766 14.7637 6.45962 16.4935 8.11388 17.7342C4.78733 19.0846 2.01199 21.5152 0.234627 24.6347C0.124495 24.8117 0.0510929 25.0092 0.0187838 25.2152C-0.0135253 25.4212 -0.00408184 25.6316 0.0465531 25.8339C0.097188 26.0362 0.187981 26.2263 0.313533 26.3928C0.439086 26.5593 0.596835 26.6989 0.777404 26.8032C0.957972 26.9075 1.15767 26.9745 1.36463 27.0001C1.5716 27.0257 1.78159 27.0094 1.98214 26.9522C2.18269 26.895 2.36969 26.7981 2.53204 26.6672C2.69438 26.5363 2.82875 26.3741 2.92716 26.1903C5.27616 22.1301 9.4245 19.7085 14.0253 19.7085C18.626 19.7085 22.7744 22.1314 25.1234 26.1903C25.3366 26.5332 25.6749 26.7798 26.0667 26.8778C26.4584 26.9759 26.8729 26.9177 27.2226 26.7157C27.5723 26.5137 27.8297 26.1836 27.9404 25.7953C28.0511 25.4069 28.0065 24.9907 27.8159 24.6347ZM7.28421 9.8562C7.28421 8.52294 7.67957 7.21963 8.42028 6.11107C9.161 5.00251 10.2138 4.13849 11.4456 3.62828C12.6773 3.11806 14.0327 2.98457 15.3404 3.24467C16.648 3.50478 17.8492 4.1468 18.7919 5.08955C19.7347 6.03231 20.3767 7.23345 20.6368 8.54108C20.8969 9.84872 20.7634 11.2041 20.2532 12.4359C19.743 13.6677 18.879 14.7205 17.7704 15.4612C16.6618 16.2019 15.3585 16.5972 14.0253 16.5972C12.2381 16.5952 10.5246 15.8843 9.26089 14.6206C7.99715 13.3568 7.28627 11.6434 7.28421 9.8562Z"
                          fill="#1B72E6"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <Logout iconColor="#1B72E6" />
                  </li>
                </ul>
              </div>
            </div>

            <div className="fluid-container py-4">
              <Outlet />
            </div>
          </div>
        </div>
        {openCreateCollectionModal ? <CreateAPIModal /> : null }
        {showEditModal ? <EditCollectionModal /> : null }
        {showSideModal && (
          <div className="app-side-modal show">
            <div className="app-side-modal__bg"></div>
            <div className="app-side-modal__content">
              <div className="align-center p-4 modal-head">
                <a href="#" onClick={closeModal}>
                  <svg className="close-svg-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M27.4101 24.5813C27.7857 24.9569 27.9967 25.4663 27.9967 25.9974C27.9967 26.5285 27.7857 27.0379 27.4101 27.4134C27.0345 27.789 26.5251 28 25.9939 28C25.4628 28 24.9534 27.789 24.5778 27.4134L14 16.833L3.41888 27.4101C3.0433 27.7857 2.53389 27.9967 2.00273 27.9967C1.47158 27.9967 0.962173 27.7857 0.586588 27.4101C0.211002 27.0346 7.91487e-09 26.5252 0 25.9941C-7.91487e-09 25.4629 0.211002 24.9536 0.586588 24.578L11.1677 14.0008L0.589919 3.42034C0.214334 3.04478 0.00333218 2.53541 0.00333219 2.00428C0.00333219 1.47315 0.214334 0.963782 0.589919 0.588218C0.965505 0.212655 1.47491 0.00166568 2.00607 0.00166568C2.53722 0.00166567 3.04663 0.212655 3.42221 0.588218L14 11.1687L24.5811 0.586552C24.9567 0.210989 25.4661 -8.84857e-09 25.9973 0C26.5284 8.84857e-09 27.0378 0.210989 27.4134 0.586552C27.789 0.962115 28 1.47149 28 2.00261C28 2.53374 27.789 3.04312 27.4134 3.41868L16.8323 14.0008L27.4101 24.5813Z"
                      fill="#1B72E6"
                    />
                  </svg>
                </a>
                <div className="w-2/5 ml-4">
                  <h3 className="h3-heading">Case Details</h3>
                </div>
              </div>
              <TestCaseInfo data={testcaseItem}></TestCaseInfo>
            </div>
          </div>
        )}
      </>
    );
}