import React from 'react';
import { Chart } from 'react-google-charts';

const LineChart = () => {
  const chartData = [
    ['date', 'responsetime'],
    ['12/2/2023', 0.5],
    ['12/3/2023', 3],
    ['12/4/2023', 4],
    ['12/5/2023', 2],
    ['12/10/2023', 2.5],
    ['12/11/2023', 3.0],
    ['12/12/2024', .5],
    ['12/1/2024', 4.0],
  ];

  const options = {
    curveType: 'function',
    series: [{ color: "#4CE8B9" }],
    legend: 'none',
    vAxis: {
      title: 'Sec',
      viewWindow: {
        min: 0,
        max: 5,
      },
      ticks: [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5],
    },
    tooltip: { isHtml: true },
  };

  return (
    <div className="trend-box">
      <div className="flex justify-between align-center trend-box__head">
        <div className="trend-box__title flex justify-between align-center">
          <p>
            Pages Tested on <span className="pass"></span>
            <span className="fail"></span>
          </p>
        </div>
      </div>
      <div className="px-8 py-4 stacked_chart">
        <div className="trends_svg relative">
            <Chart
            height={'400px'}
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            data={chartData}
            options={options}
        />
        </div>
      </div>
    </div>
  );
}

export default LineChart;
