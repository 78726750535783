import React, { useState, useEffect } from "react";
import AppIcon from "../atoms/AppIcon/AppIcon.jsx";
import storeService from "../../services/store.service.js";
import utilsService from "../../services/utils.service.js";
import dataService from "../../services/data.service.js";
import apiService from "../../services/api.service.js";
import DevicesList from "./DevicesList.jsx";
import EnvironmentsList from "./EnvironmentsList.jsx";
import * as XLSX from "xlsx";
// import EnvironmentsConfig from "./EnvironmentsConfig.jsx";
import EnvironmentsConfig from "./GlobalConfig.jsx";
import PageUrlMapping from "./PageUrlMapping.jsx";
import UserManagement from "./UserManagement.jsx";
import ExcelDownload from "./ExcelDownload.jsx";
import FeatureFlagsSection from "./FeatureFlagsSection.jsx";
import TenantConfig from "../../views/tenantconfig/TenantConfig.jsx";
import TrackingScripts from "./TrackingScripts/TrackingScripts.jsx";
import NotificationSection from "./NotificationSection.jsx";
import LicenseManager from "../organisms/LicenseManager/LicenseManager.jsx";
export default () => {
  const [activeSetting, setActiveSetting] = useState("tenant");
  const [devices, setDevices] = useState([]);
  const [showFormatError, setFormatError] = useState({
    environmentFileError: false,
    enviromentConfigFileError: false,
    devicesFileError: false
  })
  const [environments, setEnvironments] = useState([]);
  const [environmentsConfig, setEnvironmentsConfig] = useState({});

  const getDevices = async () => {
    const devicesData = await apiService.get({
      url: utilsService.END_POINTS.getDevicesList,
    });
    const { data = [] } = devicesData;
    setDevices(data);
  };

  const getEnvironments = async () => {
    const environmentsResp = await apiService.get({
      url: utilsService.END_POINTS.getEnvironments,
    });
    const { data = [] } = environmentsResp;
    setEnvironments(data);
  };

  const getEnvironmentsConfig = async () => {
    const environmentsConfig = await apiService.get({
      url: utilsService.END_POINTS.getEnvironmentsConfigs,
    });
    const { data = [] } = environmentsConfig;
    if (data.length > 0) {
      data[0].screenshotTimeout = data[0].screenshotTimeout || 15;
      setEnvironmentsConfig(data[0]);
    }
  };

  useEffect(() => {
    getDevices();
    getEnvironments();
    getEnvironmentsConfig();
  }, []);

  const fetchUpdatedDevices = () => {
    getDevices();
  };


  const hasValidKeys = (type, excelData) => {
    const requiredEnvironmentKeys = ['name', 'host', 'apiHost', 'webAuthenticationURL', 'model3', 'model4', 'apiKey', 'authenticationURL', 'LogoName', 'LogoFileName', 'NextRelease'];
    const requiredDeviceKeys = ['NAME', 'OS', 'OS_VERSION', 'BROWSER', 'BROWSER_VERSION', 'WIDTH', 'HEIGHT', 'MODE', 'DEVICE_GLASS'];
    const requiredEnvConfigKeys = ['usernameXPath', 'passwordXPath', 'submitBtnXPath', 'successCheckXPath', 'tokenexpiration_mins', 'userIDFieldName', 'passwordFieldName', 'tokenFieldName', 'LogoName', 'LogoFileName', 'NextRelease'];
    let keysToValidate = requiredEnvironmentKeys;
    if (type === 'devices') {
      keysToValidate = requiredDeviceKeys;
    } else if (type === 'environments_config') {
      keysToValidate = requiredEnvConfigKeys;
    }
    const hasAllKeys = excelData.every(obj => keysToValidate.every(key => key in obj));
    const hasAllValues = excelData.every(obj => Object.keys(obj).every(key => obj[key]));
    return hasAllKeys && hasAllValues;
  };

  const sampleEnvironmentData = {
    name: "environment name",
    host: "https://environment.example.com/authenticate",
    authenticationURL: "http://111.0.0.2222/login",
    webAuthenticationURL: "http://222.0.0.3333/login",
    model3: "3",
    model4: 4,
    apiKey: "apikey",
    LogoName: "LogoName",
    LogoFileName: "logo.jpg",
    NextRelease: "January 1, 2025",
  };

  const sampleDeviceData = {
    NAME: "Windows Desktop",
    OS: "Windows",
    OS_VERSION: 10,
    BROWSER: "Chrome",
    BROWSER_VERSION: 97,
    WIDTH: 1440,
    HEIGHT: 1080,
    MODE: "Landscape",
    DEVICE_GLASS: "VIRTUAL"
  }

  const sampleEnvConfigData = {
    usernameXPath: "/html/body/section/div/div[2]/div[1]/form/div[5]/input",
    passwordXPath: "/html/body/section/div/div[2]/div[1]/form/div[1]/input",
    submitBtnXPath: "/html/body/section/div/div[2]/div[1]/form/div[6]/button",
    successCheckXPath: "/html/body/div/header/div/div/nav/div/ul/li[1]/a",
    tenantXPath: '/html/body/section/div/div[2]/div[1]/form/div[4]/input',
    UILoginURL: "http://dev.example.com/login",
    tokenexpiration_mins: 60,
    userIDFieldName: "UserID",
    passwordFieldName: "password",
    tokenFieldName: "token",
    LogoName: "Acme",
    LogoFileName: "Acme_logo.jpg",
    NextRelease: 45429
  };



  const handleFileUpload = async (e, type) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      let excelData = [];
      workbook.SheetNames.forEach((sheetName) => {
        excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { defval: null });
      });
      uploadData(type, excelData);
    };
    reader.readAsArrayBuffer(file);
  };

  const uploadData = async (type, excelData) => {
    switch (type) {
      case "devices":
        if (hasValidKeys("devices", excelData)) {
          setFormatError({ ...showFormatError, devicesFileError: false });
          await dataService.addDevicesList({ devices: excelData });
          getDevices();
        } else {
          setFormatError({ ...showFormatError, devicesFileError: true });
        }
        getDevices();
        break;
      case "environment":
        if (hasValidKeys("environment", excelData)) {
          setFormatError({ ...showFormatError, environmentFileError: false });
          await dataService.addEnvironmentList({ environments: excelData });
          getEnvironments();
        } else {
          setFormatError({ ...showFormatError, environmentFileError: true });
        }
        break;
      case "environments_config":
        if (hasValidKeys("environments_config", excelData)) {
          setFormatError({ ...showFormatError, enviromentConfigFileError: false });
          await dataService.addEnvironmentsConfig({ environmentsConfig: excelData });
          getEnvironmentsConfig();
        } else {
          setFormatError({ ...showFormatError, enviromentConfigFileError: true });
        }
        getEnvironmentsConfig();
        break;
      default:
        break;
    }
  };

  const closeSettingsModal = () => {
    storeService.pub(
      utilsService.CONSTANTS.EVENT_NAME.OPEN_SETTINGS_MODAL,
      false
    );
  };

  const fetchEnvironmentsUpdate = () => {
    getEnvironments();
  };

  return (
    <div className="app-side-modal show settings-screen">
      <div className="app-side-modal__bg"></div>
      <div className="app-side-modal__content p-4">
        <div className="align-center p-4 modal-head">
          <button
            onClick={closeSettingsModal}
            className="bg-none no-border mr-4"
          >
            <AppIcon iconName="closeIcon" iconColor="#1B72E6" />
          </button>
          <h3 className="font-urbanist line-height-140">Settings</h3>
        </div>
        <div className="p-2 model-main-content">
          <div className="settings_modal flex justify-between">
            <div className="settings_modal_options">
              <ul className="no-disc">
                <li className="mb-8">
                  <button
                    type="button"
                    className={`no-border bg-none align-center font-urbanist ${activeSetting === "tenant" ? "" : "inactive"
                      }`}
                    onClick={() => setActiveSetting("tenant")}
                  >
                    <AppIcon
                      iconName="tenant"
                      iconColor={
                        activeSetting === "tenant" ? "#1D4273" : "#889AB1"
                      }
                    ></AppIcon>
                    <span className="ml-3">Tenants</span>
                  </button>
                </li> 
                <li className="mb-8">
                  <button
                    type="button"
                    className={`no-border bg-none align-center font-urbanist ${activeSetting === "global" ? "" : "inactive"
                      }`}
                    onClick={() => setActiveSetting("global")}
                  >
                    <AppIcon
                      iconName="global"
                      iconColor={
                        activeSetting === "global" ? "#1D4273" : "#889AB1"
                      }
                    ></AppIcon>
                    <span className="ml-3">Global</span>
                  </button>
                </li> 
                <li className="mb-8">
                  <button
                    type="button"
                    className={`no-border bg-none align-center font-urbanist ${activeSetting === "notification" ? "" : "inactive"
                      }`}
                    onClick={() => setActiveSetting("notification")}
                  >
                    <AppIcon
                      iconName="notificationIcon"
                      iconColor={
                        activeSetting === "notification" ? "#1D4273" : "#889AB1"
                      }
                    ></AppIcon>
                    <span className="ml-3 white-space-no-wrap">Email Notifications</span>
                  </button>
                </li>
                <li className="mb-8">
                  <button
                    type="button"
                    className={`no-border bg-none align-center font-urbanist ${activeSetting === "trackingscripts" ? "" : "inactive"
                      }`}
                    onClick={() => setActiveSetting("trackingscripts")}
                  >
                    <AppIcon
                      iconName="trackingscript"
                      iconColor={
                        activeSetting === "trackingscripts" ? "#1D4273" : "#889AB1"
                      }
                    ></AppIcon>
                    <span className="ml-3 white-space-no-wrap">Tracking Scripts</span>
                  </button>
                </li>
                <li className="mb-8">
                  <button
                    type="button"
                    className={`no-border bg-none align-center font-urbanist ${activeSetting === "pageurlmappings" ? "" : "inactive"
                      }`}
                    onClick={() => setActiveSetting("pageurlmappings")}
                  >
                    <AppIcon
                      iconName="pageurlmapping"
                      iconColor={
                        activeSetting === "pageurlmappings" ? "#1D4273" : "#889AB1"
                      }
                    ></AppIcon>
                    <span className="ml-3 white-space-no-wrap">Page Url Mapping</span>
                  </button>
                </li>
                <li className="mb-8">
                  <button
                    type="button"
                    className={`no-border bg-none align-center font-urbanist ${activeSetting === "usermanagements" ? "" : "inactive"
                      }`}
                    onClick={() => setActiveSetting("usermanagements")}
                  >
                    <AppIcon
                      iconName="usermanagement"
                      iconColor={
                        activeSetting === "usermanagements" ? "#1D4273" : "#889AB1"
                      }
                    ></AppIcon>
                    <span className="ml-3 white-space-no-wrap">User Management</span>
                  </button>
                </li>
                {
                  process.env.LICENSE_CONTROL && (
                    <li className="mb-8">
                      <button
                        type="button"
                        className={`no-border bg-none align-center font-urbanist ${activeSetting === "license" ? "" : "inactive"
                          }`}
                        onClick={() => setActiveSetting("license")}
                      >
                        <AppIcon
                          iconName="license"
                          iconColor={
                            activeSetting === "license" ? "#1D4273" : "#889AB1"
                          }
                        ></AppIcon>
                        <span className="ml-3 white-space-no-wrap">License</span>
                      </button>
                    </li>
                  )
                }
              </ul>
            </div>
            <div className="settings_modal_data ml-4">
              {activeSetting === "environment" ? (
                <div
                  className={`settings_modal_data_environments ${environments?.length === 0 ? "text-center" : ""
                    }`}
                >
                  {environments?.length === 0 ? (
                    <>
                      <h4>Configure Environments</h4>
                      <p>
                        To configure the environment, upload the details in a
                        spreadsheet.
                      </p>

                      <div className={"mt-6"}>
                        {showFormatError.environmentFileError && <p className="font-danger bold mb-4">Please verify that the data is in the correct format. Download the sample data below for reference</p>}
                        <div className="text-center">
                          <label
                            htmlFor="file-upload"
                            className="custom-file-upload p-4 no-border"
                          >
                            <AppIcon iconName="uploadIcon" iconColor="#fff" />
                            <span className="ml-2">Choose File</span>
                          </label>
                          <input
                            id="file-upload"
                            className="file-upload"
                            type="file"
                            onChange={(event) =>
                              handleFileUpload(event, "environment")
                            }
                          />

                        </div>
                        <ExcelDownload data={[sampleEnvironmentData]} title="Download sample data" additionalClass="mt-4" />
                      </div>
                    </>
                  ) : (
                    <EnvironmentsList
                      environmentsConfig={environmentsConfig}
                      environments={environments}
                      fetchEnvironmentsUpdate={fetchEnvironmentsUpdate}
                      sampleEnvironmentData={sampleEnvironmentData}
                    />
                  )}
                </div>
              ) : null}
              {activeSetting === "devices" ? (
                <div
                  className={`settings_modal_data_devices ${devices?.length > 0 ? "" : "text-center"
                    }`}
                >
                  {devices?.length === 0 ? (
                    <>
                      <h4>Configure devices</h4>
                      <p>
                        To configure the devices, upload the details in a
                        spreadsheet.
                      </p>
                    </>
                  ) : null}
                  <div
                    className={`${devices?.length > 0
                      ? "flex justify-end mt-2"
                      : "mt-6"
                      }`}
                  >
                    {devices?.length === 0 ?
                      <div className="text-center">
                        {showFormatError.devicesFileError && <p className="font-danger bold mb-4">Please verify that the data is in the correct format. Download the sample data below for reference</p>}
                        <label
                          htmlFor="file-upload"
                          className="custom-file-upload p-4 no-border"
                        >
                          <AppIcon iconName="uploadIcon" iconColor="#fff" />
                          <span className="ml-2">Choose File</span>
                        </label>
                        <input
                          id="file-upload"
                          className="file-upload"
                          type="file"
                          onChange={(event) => handleFileUpload(event, "devices")}
                        />
                      </div> : null}
                    <ExcelDownload data={[sampleDeviceData]} title="Download sample data" additionalClass="mt-4" />
                  </div>
                  {devices?.length > 0 ? (
                    <DevicesList
                      devices={devices}
                      fetchUpdate={fetchUpdatedDevices}
                      sampleDeviceData={sampleDeviceData}
                    />
                  ) : null}
                </div>
              ) : null}
              {activeSetting === "global" ? (
                <div>
                  {environmentsConfig === undefined || Object.keys(environmentsConfig).length === 0 ? (
                    <div className="mt-6 text-center">
                      <h4>Configure the common environment settings here</h4>
                      <p>
                        To configure the devices, upload the details in a
                        spreadsheet.
                      </p>
                      <div className={"mt-6"}>
                        {showFormatError.enviromentConfigFileError && <p className="font-danger bold mb-4">Please verify that the data is in the correct format. Download the sample data below for reference</p>}
                        <div className="text-center">
                          <label
                            htmlFor="file-upload3"
                            className="custom-file-upload p-4 no-border"
                          >
                            <AppIcon iconName="uploadIcon" iconColor="#fff" />
                            <span className="ml-2">Choose File</span>
                          </label>
                          <input
                            id="file-upload3"
                            type="file"
                            className="file-upload"
                            onChange={(event) =>
                              handleFileUpload(event, "environments_config")
                            }
                          />
                        </div>
                        <ExcelDownload data={[sampleEnvConfigData]} title="Download sample data" additionalClass="mt-4" />
                      </div>
                    </div>
                  ) : (
                    <EnvironmentsConfig data={environmentsConfig} getEnvironmentsConfig={getEnvironmentsConfig} />
                  )}
                </div>
              ) : null}
              {
                activeSetting == 'featureflags' && (
                  <FeatureFlagsSection />
                )
              }
              {activeSetting == 'tenant' && (<TenantConfig />)}

              {activeSetting == 'notification' && (<NotificationSection />)}
              {activeSetting == 'trackingscripts' && (<TrackingScripts />)}
              {activeSetting == 'pageurlmappings' && (<PageUrlMapping />)}
              {activeSetting == 'usermanagements' && (<UserManagement />)}
              {activeSetting == 'license' && (<LicenseManager />)}
            </div>
          </div>
        </div>
        <div className="flex justify-end pt-2 pr-2">
          <span className="para-2">Build Version: {typeof process.env.BUILD_VERSION == 'string' ? process.env.BUILD_VERSION : ''}</span>
        </div>
      </div>
    </div>
  );
};
