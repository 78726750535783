import { useState, useEffect } from "react"
import AppModal from "../../comps/organisms/AppModal.jsx"
import AppIcon from "../../comps/atoms/AppIcon/AppIcon.jsx"
import tenantsService from "../../services/tenants.service.js"
import storeService from "../../services/store.service.js"
import utilsService from "../../services/utils.service.js"
import apiService from "../../services/api.service.js"
import TrackingCodeView from "../../comps/atoms/TrackingScripts/TrackingCodeView.jsx"
import TenentGlobalConfig from "./TenentGlobalConfig.jsx"
export default () => {
    const [name, setName] = useState({
        value: '',
        isError: null
    })
    const [siteId, setSiteId] = useState({
        value: '',
        isError: null
    })
    const [tenantId, settenantId] = useState({
        value: '',
        isError: null
    })

    const [tenantSiteList, settenantSiteList] = useState([])
    const [loadingLogData, setLoadingLogData] = useState(false)
    const [tenantGlobalConfig, settenantGlobalConfig] = useState({
        webAuthenticationFields: {
            "usernameXPath": "",
            "passwordXPath": "",
            "submitBtnXPath": "",
            "successCheckXPath": ""
        }
    })
    const [showModal, setShowModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [selectedId, setSelectedId] = useState(null)
    const clearForm = () => {
        setName({
            value: '',
            isError: null
        })
        setSiteId({
            value: '',
            isError: null
        })
        settenantId({
            value: '',
            isError: null
        })
    }
    const validateForm = () => {
        let inValidCount = 0;
        if (!(name.value && name.value.length > 3)) {
            inValidCount += 1;
            name.isError = true;
        }
        if (!(siteId.value && siteId.value.length > 0)) {
            inValidCount += 1;
            siteId.isError = true;
        }
        if (!(tenantId.value && tenantId.value.length > 0)) {
            inValidCount += 1;
            tenantId.isError = true;
        }
        return {
            inValidCount,
            name,
            siteId,
            tenantId
        }
    }
    const handleSubmit = async () => {
        let { inValidCount, name, siteId, tenantId } = validateForm()
        if (inValidCount > 0) {
            setName({ ...name })
            setSiteId({ ...siteId })
            settenantId({ ...tenantId })
            return;
        }
        let formData = {
            name: name.value,
            siteId: siteId.value,
            tenantId: tenantId.value,
            webAuthenticationFields: tenantGlobalConfig.webAuthenticationFields
        }

        if (isEdit) {
            await tenantsService.updatetenant({
                _id: selectedId,
                fieldsToUpdate: {
                    name: formData.name,
                    siteId: formData.siteId,
                    tenantId: formData.tenantId,
                    webAuthenticationFields: formData.webAuthenticationFields
                }
            });
        } else {
            await tenantsService.addtenants({
                items: [formData]
            })
        }
        storeService.pub(utilsService.CONSTANTS.EVENT_NAME.ADDED_TENANT, {})
        setShowModal(false)
    }
    const handleSelect = (item) => {
        settenantId({
            ...tenantId,
            value: item._id.tenantId
        })
        setSiteId({
            ...siteId,
            value: item._id.siteId
        });
    }
    const loadFromLogs = () => {
        setLoadingLogData(true)
        apiService.post({
            url: utilsService.END_POINTS.aggregateexecute,
            data: {
                stages: [
                    {
                        $group:
                        {
                            _id: {
                                tenantId: "$tenantId",
                                siteId: "$siteId",
                            },
                            count: {
                                $sum: 1,
                            },
                        },
                    },
                ],
                collectionName: 'analyticlogs'
            }
        }).then(apiresp => {
            settenantSiteList(apiresp.data);
            setLoadingLogData(false)
        })
    }
    useEffect(() => {
        storeService.sub(utilsService.CONSTANTS.EVENT_NAME.EDIT_TENANT, ({ detail }) => {
            setName({
                value: detail.name,
                isError: false
            })
            setSelectedId(detail._id)
            setSiteId({
                value: detail.siteId,
                isError: false
            })
            settenantId({
                value: detail.tenantId,
                isError: false
            })
            settenantGlobalConfig({
                webAuthenticationFields: detail.webAuthenticationFields
            })
            setIsEdit(true)
            setShowModal(true)
        })
    }, [])
    return (
        <>
            <div className="flex justify-end">
                <button type="button" class="btn-medium-2" onClick={() => {
                    clearForm()
                    setShowModal(true)
                    setIsEdit(false)
                }}>Add Tenant</button>
            </div>
            {
                showModal && (
                    <AppModal>
                        <div className="justify-between align-center modal-title">
                            <div className="align-center">
                                <h2 className="h2-heading">{isEdit ? 'Edit' : 'Add'} Tenant</h2>
                            </div>
                            <button
                                type="button"
                                onClick={e => {
                                    setShowModal(false)
                                }}
                                className="bg-none no-border"
                            >
                                <AppIcon iconName="closeIcon" iconColor="#1B72E6" />
                            </button>
                        </div>
                        <div className="modal-content modal-form-content overflow-y-scroll min-h-60vh max-h-60vh">
                            <div className={`mb-4 ` + (name.isError ? 'error ' : '')}>
                                <label className="white-space-no-wrap mr-2 bold w-full d-block mb-2 label">
                                    Tenant Name
                                </label>
                                <input
                                    type="text"
                                    value={name.value}
                                    disabled={name.value == 'default'}
                                    onChange={e => {
                                        setName({ ...name, value: e.target.value, isError: false })
                                    }}
                                    className="input-field w-full p-2 input-txt"
                                />
                                {
                                    name.isError && (
                                        <p className="error-message">This is required field</p>
                                    )
                                }
                            </div>
                            <div className="mb-8">
                                <div><a className={`btn-link ${loadingLogData ? 'disabled' : ''}`} onClick={e => { loadFromLogs() }}>Load from analytics logs</a></div>
                                <div className="my-2">
                                    {
                                        tenantSiteList && tenantSiteList.length > 0 &&
                                        (
                                            <table className="app-table">
                                                <tbody>
                                                    <tr className="table-row-title">
                                                        <td className="table-subheading">Tenant Id</td>
                                                        <td className="table-subheading">Site Id</td>
                                                        <td className="table-subheading">Log Count</td>
                                                        <td className="table-subheading"></td>
                                                    </tr>
                                                    {
                                                        tenantSiteList.filter(x => x._id && (x._id.tenantId || x._id.siteId)).map(x => {
                                                            return (
                                                                <tr className="table-content">
                                                                    <td className="table-text">{x._id.tenantId}</td>
                                                                    <td className="table-text">{x._id.siteId}</td>
                                                                    <td className="table-text">{x.count}</td>
                                                                    <td className="table-text">
                                                                        <a className="btn-link" onClick={e => { handleSelect(x) }}>Select</a>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="flex mb-8">
                                <div className={`flex-1 p-2 ` + (siteId.isError ? 'error ' : '')}>
                                    <label className="white-space-no-wrap mr-2 bold w-full d-block mb-2 label">
                                        Site Id
                                    </label>
                                    <input
                                        type="text"
                                        value={siteId.value}
                                        onChange={e => {
                                            setSiteId({ ...siteId, value: e.target.value, isError: false })
                                        }}
                                        className="input-field w-full p-2 input-txt"
                                    />
                                    {
                                        siteId.isError && (
                                            <p className="error-message">This is required field</p>
                                        )
                                    }
                                </div>
                                <div className={`flex-1 p-2 ` + (tenantId.isError ? 'error ' : '')}>
                                    <label className="white-space-no-wrap mr-2 bold w-full d-block mb-2 label">
                                        Tenant Id
                                    </label>
                                    <input
                                        type="text"
                                        value={tenantId.value}
                                        onChange={e => {
                                            settenantId({ ...tenantId, value: e.target.value, isError: false })
                                        }}
                                        className="input-field w-full p-2 input-txt"
                                    />
                                    {
                                        tenantId.isError && (
                                            <p className="error-message">This is required field</p>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="my-3">
                                <TenentGlobalConfig handleUpdate={(updatedConfig) => {
                                    settenantGlobalConfig({
                                        webAuthenticationFields: {
                                            ...updatedConfig
                                        }
                                    });
                                }} tenantGlobalConfig={tenantGlobalConfig} />
                            </div>
                            <TrackingCodeView siteId={siteId.value} tenantId={tenantId.value} />
                        </div>

                        <div className="flex justify-end px-8 py-6">
                            <button
                                onClick={() => {
                                    handleSubmit()
                                }}
                                type="button"
                                className="btn-medium-2 btn-medium-width"
                            >
                                Submit
                            </button>
                        </div>
                    </AppModal>
                )
            }
        </>
    )
}