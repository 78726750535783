import { useState, useEffect } from 'react';
import Select from 'react-select';
const options = [{ label: '5', value: 5 }, { label: '10', value: 10 }, { label: '15', value: 15 }, { label: '20', value: 20 }, { label: 'All', value: 10000 }];
export default ({ pageNo, pageSize, totalSize, onChange }) => {
    const [perPage, setPerPage] = useState(pageSize)
    const [pageCount, setPageCount] = useState(1)
    const [startNo, setStartNo] = useState(1)
    const [endNo, setEndNo] = useState(1)
    const [pageNum, setPageNum] = useState(pageNo)
    
    const updatePerPage = (selectedPerPage) => {
        setPerPage(selectedPerPage.value);
        onChange && onChange({ perPage: selectedPerPage.value })
    }
    const handlePageNoChange = (pageNumber) => {
        setPageNum(pageNumber)
        if(pageNumber < 1) {
            setPageNum(1)
            return;
        };
        if(pageNumber > pageCount) {
            setPageNum(pageCount)
            return;
        }
        onChange && onChange({ pageNo: pageNumber })
    }
    const handlePageNoClick = (e, pageNumber) => {
        e.preventDefault();
        onChange && onChange({ pageNo: pageNumber })
    }
    useEffect(() => {
        setPageNum(pageNo)
        
        setStartNo(((pageNo - 1) * pageSize) + (1))
        let lastItem = ((pageNo - 1) * pageSize) + (pageSize);
        setEndNo(lastItem < totalSize ? lastItem : totalSize)
        setPageCount(Math.ceil(totalSize / pageSize))
        setPerPage(pageSize)
    }, [totalSize, pageSize, pageNo])
    const handleKeyDown = (e) => {
        // allow only numbers and backspace
        if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode != 8) {
            e.preventDefault();
        }
    }
    return (
        <div className="pagination flex justify-between">
            <div className='align-center table-heading'>
                Showing {startNo} to {endNo} of {totalSize}
            </div>
            <div className='flex align-center'>
                <a onClick={e => handlePageNoClick(e, 1)} className="font-link-color mr-2 table-heading" disabled={pageNo == 1}>
                    1
                </a>
                <a onClick={e => handlePageNoClick(e, pageNo - 1)} className="font-link-color mr-2 table-heading" disabled={pageNo == 1}>
                    Prev
                </a>
                <input onKeyDown={handleKeyDown} className='input-txt input-page-no table-heading' type="text" value={pageNum} onChange={e => handlePageNoChange(e.target.value)} />
                <a onClick={e => handlePageNoClick(e, pageNo + 1)} className="font-link-color ml-2 table-heading" disabled={pageNo == pageCount}>
                    Next
                </a>
                <a onClick={e => handlePageNoClick(e, pageCount)} className="font-link-color ml-2 table-heading" disabled={pageNo == pageCount}>
                    {pageCount}
                </a>
            </div>
            <div className="flex align-center">
                <label className="white-space-no-wrap mr-2 table-heading">
                    Per Page:
                </label>
                <Select
                    name="perPage"
                    value={options.find(x => x.value == perPage)}
                    options={options}
                    onChange={updatePerPage}
                    className="basic-multi-select table-heading"
                    classNamePrefix="select"
                    menuPlacement="top"
                />
            </div>
        </div>
    );
}