import { useState, useEffect, Fragment } from "react"
import storeService from "../../../services/store.service"
const TreeViewItem = (props) => {
    const [list, setList] = useState([])
    const [item, setItem] = useState(null)
    const [isOpen, setisOpen] = useState(false)
    useEffect(() => {
        storeService.sub('TREE_EXPAND_ALL', ({ detail }) => {
            setisOpen(detail)
        })
    }, [])
    useEffect(() => {
        if (props.node) {
            let node = props.node;
            if (node) {
                setItem(node);
            }
        }
    }, [props.node])
    const handleAddTestCase = (path) => {
        props.handleAddTestcase && props.handleAddTestcase(path);
    }
    const handleAddTestCaseClick = (e) => {
        e.preventDefault();
        props.handleAddTestcase && props.handleAddTestcase(item.stepPath);
    }
    const handleToggleExpand = (e) => {
        e.preventDefault();
        setisOpen(!isOpen)
    }
    return (
        <>
            {
                item && (
                    <div className={`tree-path-item level-${props.level}`}>
                        <div className="tree-path-name flex justify-between" onClick={handleToggleExpand}>
                            <div>
                                {
                                    item.hasNext && (
                                        <a className="icon">
                                            <svg
                                                className="post-svg-icon tree-accordion-icon mr-2"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="11"
                                                height="11"
                                                viewBox="0 0 18 11"
                                                fill="none"
                                            >
                                                {isOpen ? (
                                                    <path
                                                        d="M0.34627 9.08042L8.16974 1.25695C8.27876 1.14755 8.40831 1.06074 8.55095 1.00151C8.69359 0.942283 8.84653 0.911793 9.00098 0.911793C9.15543 0.911793 9.30836 0.942283 9.45101 1.00151C9.59365 1.06074 9.7232 1.14755 9.83222 1.25695L17.6557 9.08042C17.8761 9.30088 18 9.59989 18 9.91166C18 10.2234 17.8761 10.5224 17.6557 10.7429C17.4352 10.9634 17.1362 11.0872 16.8244 11.0872C16.5127 11.0872 16.2137 10.9634 15.9932 10.7429L9 3.7497L2.0068 10.7439C1.78634 10.9643 1.48734 11.0882 1.17556 11.0882C0.863781 11.0882 0.564774 10.9643 0.344315 10.7439C0.123856 10.5234 7.5514e-08 10.2244 1.0277e-07 9.91264C1.30027e-07 9.60086 0.123856 9.30185 0.344315 9.08139L0.34627 9.08042Z"
                                                        fill="#1B72E6"
                                                    />
                                                ) : (
                                                    <path
                                                        d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z"
                                                        fill="#1B72E6"
                                                    />
                                                )}
                                            </svg>
                                        </a>
                                    )
                                }
                                <span className={`title ${isOpen ? 'is-open' : ''}`}>
                                    { item.pagename  } { item.count != null ? `(${item.count})`: '' }
                                </span>
                            </div>
                            {
                                !item.hasNext && (
                                    <a
                                        href=""
                                        class="d-flex justify-center align-center pr-2"
                                        onClick={handleAddTestCaseClick}
                                    >
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect width="24" height="24" rx="4" fill="#D7DFE8"></rect>
                                            <path
                                                d="M6.32791 13.3243H10.6721V17.6757C10.6721 18.4135 11.2791 19 12 19C12.7209 19 13.3279 18.3946 13.3279 17.6757V13.3243H17.6721C18.4119 13.3243 19 12.7189 19 12C19 11.2811 18.393 10.6757 17.6721 10.6757H13.3469V6.32432C13.3469 5.58649 12.7398 5 12.019 5C11.2981 5 10.6911 5.60541 10.6911 6.32432V10.6757H6.32791C5.58808 10.6757 5 11.2811 5 12C5 12.7189 5.60705 13.3243 6.32791 13.3243Z"
                                                fill="#1D4273"
                                            ></path>
                                        </svg>
                                    </a>
                                )
                            }
                        </div>
                        {
                            item.hasNext && (
                                <div className={`tree-path-items pl-5 ${isOpen ? 'expand' : ''}`}>
                                    {
                                        item.children.map(itm => {
                                            return (
                                                <TreeViewItem handleAddTestcase={handleAddTestCase} node={itm} level={props.level + 1} />
                                            )
                                        })
                                    }
                                </div>
                            )
                        }

                    </div>
                )
            }
        </>

    )
}

export default TreeViewItem;