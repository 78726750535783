import { useState, useEffect } from "react"
const priorityList = ['P1', 'P2', 'P3', 'P4', 'P0']
export default ({ job }) => {
    const [displayText, setdisplayText] = useState('')
    useEffect(() => {
        if (job) {
            let pMap = new Map();
            job.priority.map((stepP, stepIndex) => {
                if (stepP == "" || stepP == undefined || stepP == null) return '';
                if(!pMap.has(stepP)) {
                    pMap.set(stepP, []);    
                }
                let list = pMap.get(stepP);
                list.push(stepIndex + 1);
                pMap.set(stepP, list);
            })
            let textToDisplay = priorityList.map(pItem => {
                let list = pMap.get(pItem);
                if(list && list.length > 0) {
                    return pItem + ' (' + list.join(', ')  +')'
                }
                return null;
            }).filter(x => x).join(' | ')
            setdisplayText(textToDisplay)
        }

    }, [job])
    return (
        <>
            {displayText}
        </>
    )
}