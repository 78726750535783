import apiService from './api.service.js'
import storeService from './store.service.js';
import utilsService from './utils.service.js';
import utils from './utils.service.js'
import flowService from './flow.service.js';
const workflowLoadedData = {}
export default {
    async getworkflows() {
        return await apiService.post({
            url: utils.END_POINTS.getworkflows,
            data: {
                "find": {},
                "options": {
                    "sort": {
                        "updatedAt": -1,
                        "createdAt": -1
                    }
                }
            }
        });
    },
    async getworkflowjobs() {
        return await apiService.post({
            url: utils.END_POINTS.getworkflowjobs,
            data: {
                "selectedEnvId": storeService.store.ENVIRONMENTS.selectedEnvId,
                "find": {},
                "options": {
                    "sort": {
                        "createdAt": -1
                    }
                }
            }
        });
    },
    async getjobsbyuserflowid({ userflowId, selectedDate }) {
        return await apiService.post({
            url: utils.END_POINTS.getjobsbyuserflowid,
            data: {
                stages: [
                    {
                        '$match': {
                            'userflowId': userflowId,
                            'selectedEnvId': storeService.store.ENVIRONMENTS.selectedEnvId
                        }
                    }, {
                        '$sort': {
                            'createdAt': -1
                        }
                    }, {
                        '$group': {
                            '_id': {
                                'userflowId': '$userflowId',
                                'deviceId': '$deviceId'
                            },
                            'job': {
                                '$first': '$$ROOT'
                            }
                        }
                    }
                ],
                selectedDate: utilsService.getUtcYMD(selectedDate)
            }
        });
    },
    async loadworkflowjobs() {
        this.getworkflowjobs().then(jobResp => {
            if (jobResp && jobResp.data) {
                storeService.saveStore({
                    items: jobResp.data,
                    lastLoaded: new Date(),
                    loaded: true,
                    isLoading: false,
                }, 'WORKFLOWS_JOBS');
                let deviceJobs = jobResp.data;
                let workflowStore = storeService.store.WORKFLOWS_WITH_DETAILS;
                if (workflowStore.loaded) {
                    let workflows = workflowStore.items
                    workflows.forEach(workflow => {
                        let workflowJobs = deviceJobs.filter(x => x.userflowId?.toString() === workflow._id?.toString());
                        let devices = [];
                        workflowJobs.forEach(job => {
                            let deviceItem = devices.find(x => {
                                return x._id === job.deviceId
                            });
                            if (!deviceItem) {
                                devices.push({
                                    ...job.device,
                                    _id: job.deviceId,
                                    jobs: [job]
                                });
                            }
                            else {
                                deviceItem.jobs.push(job);
                            }
                        });
                        workflow.devices = devices;
                    });
                    storeService.saveStore({
                        items: workflows
                    }, 'WORKFLOWS_WITH_DETAILS');
                }
            }
        });
    },
    async getworkflowdevices() {
        return await apiService.post({
            url: utils.END_POINTS.getworkflowdevices,
            data: {
                "find": {},
                "options": {
                    "sort": {
                        "createdAt": -1
                    }
                }
            }
        });
    },
    async generaterecommendation(payload) {
        let topPageJourneyDataMap = storeService.store.USAGE_DATA_MAP.topPageJourneyDataMap;
        const apiResp = await apiService.post({
            url: utils.END_POINTS.generaterecommendation,
            data: payload
        });
        const { data } = apiResp || {};
        if(data && data.tree_structure) {
            let allRootNodes = data.tree_structure || [];
            if (allRootNodes.length > 0) {
                let threeNodes = allRootNodes.slice(0, 3);
                topPageJourneyDataMap[payload.recommendationId] = {
                    firstThreeNodes: threeNodes,
                    firstThreePaths: flowService.getTreePaths(threeNodes),
                    allNodes: allRootNodes,
                    parsedAllNodes: null
                }
                return topPageJourneyDataMap[payload.recommendationId].firstThreePaths;
            }
        }
        return null;
    },
    async loadrecommendation(findData) {
        let topPageJourneyDataMap = storeService.store.USAGE_DATA_MAP.topPageJourneyDataMap;
        // if (topPageJourneyDataMap[findData._id]) {
        //     return topPageJourneyDataMap[findData._id].firstThreePaths;
        // }
        const apiResp = await apiService.post({
            url: utils.END_POINTS.loadrecommendation,
            data: {
                recommendationId: findData._id
            }
        });
        const { data = [] } = apiResp || {};
        if (data.length > 0) {
            let allRootNodes = apiResp.data[0].toppagejourneytree_json || [];
            if (allRootNodes.length > 0) {
                let threeNodes = allRootNodes.slice(0, 3);
                topPageJourneyDataMap[findData._id] = {
                    firstThreeNodes: threeNodes,
                    firstThreePaths: flowService.getTreePaths(threeNodes),
                    allNodes: allRootNodes,
                    parsedAllNodes: null
                }
                return topPageJourneyDataMap[findData._id].firstThreePaths;
            }
        }
        return null;
    },
    async getxpathrecommendations(payload) {
        payload['selectedEnvId'] = storeService.store.ENVIRONMENTS.selectedEnvId;
        return await apiService.post({
            url: utils.END_POINTS.getxpathrecommendations,
            data: payload
        });
    },
    async logxpathrecommendations(payload) {
        return await apiService.post({
            url: utils.END_POINTS.logxpathrecommendations,
            data: payload
        });
    },
    async getfailedworkflowjobs(payload) {
        payload['selectedEnvId'] = storeService.store.ENVIRONMENTS.selectedEnvId;
        let apiResp = await apiService.post({
            url: utils.END_POINTS.getfailedworkflowjobs,
            data: payload
        });
        const { data = {} } = apiResp || {};
        const { items = [] } = data;
        let faildWorkflowMap = storeService.store.WORKFLOW_FAILED_JOBS.itemsMap;
        items.forEach(item => {
            const { _id, recentJob } = item;
            const { userflowId, deviceId } = _id;
            if(!faildWorkflowMap.has(userflowId)) {
                faildWorkflowMap.set(userflowId, new Map())
            }
            let pageItem = faildWorkflowMap.get(userflowId);
            if(!pageItem.has(deviceId)) {
                pageItem.set(deviceId, {});
            }
            let deviceItem = pageItem.get(deviceId);
            deviceItem.finalStatus = recentJob.finalStatus;
            deviceItem.finalPriority = recentJob.finalPriority;
        })
        return apiResp;
    },
    async getrecommendationcardsummary(payload) {
        return await apiService.post({
            url: utils.END_POINTS.getrecommendationcardsummary,
            data: payload
        });
    }
}