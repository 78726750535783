import { useState, useEffect } from 'react'
import SvgIcon from "../SvgIcon";
import AppIcon from "../AppIcon/AppIcon";

const displayValue = (value, field) => {
    if (field.dataType == 'json') {
        return JSON.stringify(value, null, 2);
    }
    if (field.fieldType == 'checkbox') {
        if (value == null || value == undefined) return null;
        return value ? 'True' : 'False'
    } else {
        return value;
    }
}
export default (props) => {
    const { field } = props;
    const [value, setValue] = useState(props.value || '')
    const [edit, setEdit] = useState(false)
    const [tmpValue, settmpValue] = useState(props.value || '')
    const [jsonStr, setjsonStr] = useState(props.value ? JSON.stringify(props.value) : '')
    useEffect(() => {
        setValue(props.value)
        settmpValue(props.value)
    }, [props.value])
    const saveChange = () => {
        let valueToSet = tmpValue;
        if (field.dataType == 'json') {
            try {
                valueToSet = JSON.parse(jsonStr);
            } catch (ex) {

            }
        }
        props.saveChange && props.saveChange(valueToSet)
        setValue(valueToSet)
        setEdit(false)
    }
    const renderEditField = () => {
        if (field.fieldType == 'select') {
            return (
                <div className="form-field">
                    <select className="settings-field select-text" value={tmpValue}
                        onChange={(event) => {
                            settmpValue(event.target.value)
                        }}>
                        {
                            field.options && field.options.map(opt => {
                                return (<option value={opt.value}>{opt.display}</option>)
                            })
                        }
                    </select>
                    <svg className="post-svg-icon" xmlns="http://www.w3.org/2000/svg" width="18"
                        height="11" viewBox="0 0 18 11" fill="none">
                        <path
                            d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z"
                            fill="#1B72E6" />
                    </svg>
                </div>
            )
        }
        if (field.fieldType == 'checkbox') {
            return (
                <input className="settings-field"
                    type="checkbox"
                    value={tmpValue}
                    checked={tmpValue}
                    onChange={(event) => {
                        settmpValue(event.target.checked)
                    }}
                    autoFocus
                />
            )
        }
        //  let val = (event.target.value || '').replace(new RegExp('[^0-9]', 'g'), '')
        if (field.fieldType == 'number') {
            return (
                <input
                    type="text"
                    value={tmpValue}
                    className="settings-field"
                    onChange={(event) => {
                        let val = (event.target.value || '').replace(new RegExp('[^0-9]', 'g'), '')
                        settmpValue(val)
                    }}
                />
            )
        }
        if (field.fieldType == 'textarea') {
            return (
                <textarea
                    type="text"
                    value={jsonStr}
                    className="input-field w-full text-area"
                    rows={6}
                    onChange={(event) =>
                        setjsonStr(event.target.value)
                    }
                />
            )
        }
        return (
            <input className="settings-field"
                type="text"
                value={tmpValue}
                onChange={
                    (event) => {
                        settmpValue(event.target.value)
                    }
                }
            />
        )
    }
    return (
        <div className="w-50 p-4 display-block align-top">
            <p className="line-height-140 mb-1">{field.display}</p>
            <span className="line-height-140 word-break-all">
                {edit ? (
                    <>
                        {
                            renderEditField()
                        }
                        <div class="flex justify-end my-2">
                            <button
                                type="button"
                                onClick={saveChange}
                                className="settings-btn mr-3">
                                <AppIcon iconName="settingsEditIcon" iconColor="#1B72E6" />
                            </button>
                            <button
                                type="button"
                                onClick={e => {
                                    setEdit(false)
                                }}
                                className="settings-btn delete">
                                <AppIcon iconName="settingsCloseIcon" iconColor="#1B72E6" />
                            </button>
                        </div>
                    </>
                ) : (
                    <span>
                        {displayValue(value, props.field)}
                        <a className="ml-2" onClick={e => {
                            e.preventDefault();
                            if (field.dataType == 'json') {
                                try {
                                    setjsonStr(JSON.stringify(value, null, 2))
                                } catch (ex) {

                                }
                            }
                            setEdit(true)
                        }}>
                            <SvgIcon extraclass='svg-size-m mx-3 mt-1' path="/icons/edit-icon.svg"></SvgIcon>
                        </a>
                    </span>
                )}
            </span>
        </div>
    )
}