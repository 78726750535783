import React, { useState, useRef, useEffect } from "react";
import apiService from "../../services/api.service.js";
import utils from "../../services/utils.service.js";

export default ({ onClose }) => {
  const [showOutput, setShowOutput] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const textareaRef = useRef(null);
  const inputRef = useRef(null);
  const [apiDefinition, setApiDefinition] = useState({
    apiInput: [],
    apiType: "Swagger 2.0",
    apiOutput: [],
  });
  const [output, setOutput] = useState("");

  const closeModal = () => {
    onClose();
  };

  const handleCopy = async () => {
    if (textareaRef?.current) {
      try {
        await navigator.clipboard.writeText(textareaRef.current.value);
      } catch (err) {
        console.error("Failed to copy: ", err);
        try {
          textareaRef.current.select();
          document.execCommand("copy");
        } catch (err) {
          console.error("Could not copy text: ", err);
        }
      }
    }
  };

  const handleSelectedFormat = (selectedFormat) => {
    setApiDefinition({
      ...apiDefinition,
      apiType: selectedFormat,
    });
  };

  const changeOutput = (selectedFormat) => {
    const filteredDefinitions = apiDefinition?.apiOutput[selectedFormat]
    filteredDefinitions && setOutput(JSON.stringify(filteredDefinitions, null, 2));
  };

  const handleSave = () => {
    saveAPIData();
  };

  useEffect(() => {
    if (dataLoaded) {
      setShowOutput(true);
    }
  }, [dataLoaded]);

  saveAPIData = async () => {
    try {
      if (inputRef.current.value.length) {
        setShowLoader(true);
        const apiToAdd = {
          apiInput:
            inputRef.current && inputRef.current.value
              ? JSON.parse(inputRef.current.value)
              : [],
          apiType: apiDefinition.apiType,
        };
        const apiResp = await apiService.post({
          url: utils.END_POINTS.addapiimportdata,
          data: apiToAdd,
        });
        const collectionAPI = await apiService.post({
          url: utils.END_POINTS.generateapicollection,
          data: { collection_id: apiResp?.data?.insertedId },
        });
        if (collectionAPI) {
          const collectionOutputAPI = await apiService.post({
            url: utils.END_POINTS.readapioutput,
            data: { collection_id: apiResp?.data?.insertedId },
          });
          setApiDefinition({
            ...apiDefinition,
            apiOutput: collectionOutputAPI?.data[0]?.output_api_definitions,
          });
          setDataLoaded(true);
          setShowLoader(false);
        }
      } else {
        setShowError(true);
        setShowLoader(false);
      }
    } catch (e) {
      setShowError(true);
      setShowLoader(false);
    }
  };

  useEffect(() => {
    changeOutput(0);
  }, [apiDefinition.apiOutput]);
  return (
    <div className="app-modal">
      <div className="app-modal__content select-api">
        <div className="add-page-comp">
          <div className="add-page-comp__head">
            <div className="flex justify-between px-7 py-5">
              <h3 className="h3-heading">Convert API Definition</h3>
              <a onClick={closeModal} className="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M21.5365 19.3139C21.8316 19.609 21.9974 20.0092 21.9974 20.4265C21.9974 20.8438 21.8316 21.2441 21.5365 21.5391C21.2414 21.8342 20.8411 22 20.4238 22C20.0065 22 19.6062 21.8342 19.3111 21.5391L11 13.2259L2.68626 21.5365C2.39116 21.8316 1.99092 21.9974 1.57358 21.9974C1.15624 21.9974 0.755993 21.8316 0.46089 21.5365C0.165787 21.2414 6.21883e-09 20.8412 0 20.4239C-6.21883e-09 20.0066 0.165787 19.6064 0.46089 19.3113L8.77463 11.0007L0.463508 2.68741C0.168405 2.39233 0.00261814 1.99211 0.00261815 1.57479C0.00261815 1.15748 0.168405 0.757257 0.463508 0.462172C0.758611 0.167086 1.15886 0.00130875 1.5762 0.00130875C1.99353 0.00130874 2.39378 0.167086 2.68888 0.462172L11 8.77541L19.3137 0.460862C19.6088 0.165777 20.0091 -6.95245e-09 20.4264 0C20.8438 6.95245e-09 21.244 0.165777 21.5391 0.460862C21.8342 0.755948 22 1.15617 22 1.57348C22 1.9908 21.8342 2.39102 21.5391 2.6861L13.2254 11.0007L21.5365 19.3139Z"
                    fill="#1B72E6"
                  />
                </svg>
              </a>
            </div>
            <div className="app-form__fields px-7 pb-7">
              {!showOutput && (
                <>
                  <div className="form-field-select mt-3">
                    <label className="label mb-1">Select Format</label>
                    <div className="align-center">
                      <div className="form-field">
                        <select className="select-text"
                          value={apiDefinition.apiType}
                          onChange={(e) => handleSelectedFormat(e.target.value)}
                        >
                          <option value="swagger">Swagger 2.0</option>
                          <option value="openAPI">OpenAPI 3.0.1</option>
                        </select>
                        <svg
                          className="post-svg-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="11"
                          viewBox="0 0 18 11"
                          fill="none"
                        >
                          <path
                            d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z"
                            fill="#1B72E6"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="form-field mt-4">
                    <label class="label mb-1">Input</label>
                    <textarea
                      ref={inputRef}
                      rows="8"
                      class="input-textarea"
                    ></textarea>
                    {showError && (
                      <p className="error-message">Invalid input. Please enter the valid input</p>
                    )}
                  </div>
                </>
              )}

              {showOutput && (
                <>
                  <div className="form-field-select mt-3">
                    <label className="label mb-1">API Name</label>
                    <div className="align-center">
                      <div className="form-field">
                        <select className="select-text" onChange={(e) => changeOutput(e.target.value)}>
                          {apiDefinition?.apiOutput?.map((eachApiInput, idx) => {
                            return (
                              <option value={idx}>
                                {eachApiInput.name ? eachApiInput.name : `Endpoint ${idx}`}
                              </option>
                            );
                          })}
                        </select>
                        <svg
                          className="post-svg-icon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="11"
                          viewBox="0 0 18 11"
                          fill="none"
                        >
                          <path
                            d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z"
                            fill="#1B72E6"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="form-field mt-4">
                    <label class="label mb-1">Output</label>
                    <textarea
                      ref={textareaRef}
                      rows="8"
                      class="input-textarea "
                      value={output}
                    ></textarea>
                  </div>

                  <div
                    className="px-3 py-2 flex align-center pointer"
                    onClick={handleCopy}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="Duplicate">
                        <path
                          id="Vector"
                          d="M16.9485 19.1788H17.5256C18.7626 19.1788 19.7688 18.1722 19.7688 16.9351V6.88281C19.7688 6.09491 19.4622 5.3541 18.9051 4.79744L16.9714 2.86373C16.4143 2.30666 15.6739 2 14.8864 2H9.06406C7.827 2 6.82036 3.00665 6.82036 4.2437V4.82077H6.24329C5.00623 4.82077 4 5.82742 4 7.06448V19.7563C4 20.9934 5.00665 22 6.24329 22H14.7048C15.9418 22 16.9485 20.9934 16.9485 19.7563V19.1788ZM8.48699 4.2437C8.48699 3.92538 8.74574 3.66663 9.06406 3.66663H14.8864C15.2289 3.66663 15.551 3.79996 15.7931 4.04204L17.7268 5.97575C17.9651 6.2145 18.1022 6.54491 18.1022 6.88281V16.9355C18.1022 17.2538 17.8435 17.5126 17.5256 17.5126H9.06406C8.74574 17.5126 8.48699 17.2538 8.48699 16.9355V4.2437ZM14.7048 20.333H6.24329C5.92538 20.333 5.66663 20.0742 5.66663 19.7559V7.06406C5.66663 6.74573 5.92538 6.48699 6.24329 6.48699H6.82036V16.9351C6.82036 18.1722 7.827 19.1788 9.06406 19.1788H15.2818V19.7559C15.2818 20.0742 15.0231 20.333 14.7048 20.333Z"
                          fill="#1D4273"
                        />
                      </g>
                    </svg>

                    <p className="pl-2">Copy</p>
                  </div>
                </>
              )}
            </div>

            {!showOutput && (
              <div className="app-form__controls mt-4 px-6 py-4 align-center">
                <button
                  className="ml-4 btn-medium-2 flex justify-center align-center"
                  type="submit"
                  onClick={handleSave}
                >
                  <span
                    className={`${showLoader ? "loading--icon flex" : ""}`}
                  ></span>
                  Save
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
