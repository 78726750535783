import { useState, useEffect } from "react"
import featureflagsService from "../../services/featureflags.service"
import featureflagsHelper from "../../helpers/featureflags"
import storeService from "../../services/store.service";
const mapFlagCode = featureflagsHelper.mapFlagCode;
export default () => {
    const [flags, setFlags] = useState([])
    const [isSaving, setIsSaving] = useState(false)
    const mapDataToState = (data) => {
        const itemMap = storeService.store.FEATURE_FLAGS.itemMap;
        data.forEach(item => {
            let localFlag = itemMap.get(item.name) || {};
            itemMap.set(item.name, { value: item.value, section: item.section || 'GLOBAL', description: item.description || localFlag.description || '' })
        });
        const list = [];
        itemMap.forEach((item, name) => {
            list.push({
                name,
                value: item.value,
                description: item.description || mapFlagCode[name] || ''
            })
        })
        setFlags(list)
    }
    useEffect(() => {
        featureflagsService.getAllFeatures({}).then(apiResp => {
            const { data = [] } = apiResp;
            mapDataToState(data)
        })
    }, [])

    const handleChecked = (e, item) => {
        let isChecked = e.target.checked;
        let updatedFlags = flags;
        updatedFlags.find(x => x.name == item.name).value = isChecked;
        setFlags([...updatedFlags])
    }
    const saveChanges = () => {
        setIsSaving(true)
        featureflagsService.saveflags({ items: flags }).then(apiResp => {
            const { data = [] } = apiResp;
            mapDataToState(data)
            setIsSaving(false)
        })
    }
    return (
        <div className="mt-6">
            <h2 className="mb-4">Configure Feature flags</h2>
            {
                flags.map((item, idx) => {
                    let htmlFor = 'flag-' + idx
                    return (
                        <div className="p-2 flex mb-2">
                            <label htmlFor={htmlFor} className="form-field-checkbox">
                                <input id={htmlFor} type="checkbox" checked={item.value} onChange={e => handleChecked(e, item)} />
                                <span className="checkmark"></span>
                            </label>
                            <label htmlFor={htmlFor} className="ml-2">
                                {item.name} {item.description ? ' - ' + item.description : ''}
                            </label>
                        </div>
                    )
                })
            }
            <div className="text-center">
                <button type="button" disabled={isSaving} onClick={saveChanges} className="btn-medium-2 btn-medium-width">
                    Save Changes
                </button>
            </div>
        </div>
    )
}