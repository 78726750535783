import { useState, useEffect } from 'react'
import apiService from '../../services/api.service';
import utilsService from '../../services/utils.service';
export default ({ tenantGlobalConfig, handleUpdate }) => {
    const [expandMoreConfig, setexpandMoreConfig] = useState(false)
    const webAuthenticationFields = [
        {
            name: 'usernameXPath',
            tooltipContent: '',
            display: 'usernameXPath',
            fieldType: 'text'
        },
        {
            name: 'passwordXPath',
            tooltipContent: '',
            display: 'passwordXPath',
            fieldType: 'text'
        },
        {
            name: 'submitBtnXPath',
            tooltipContent: '',
            display: 'submitBtnXPath',
            fieldType: 'text'
        },
        {
            name: 'successCheckXPath',
            tooltipContent: '',
            display: 'successCheckXPath',
            fieldType: 'text'
        }
    ];
    const [webAuthenticationFieldsValues, setwebAuthenticationFieldsValues] = useState({})

    useEffect(() => {
        if (tenantGlobalConfig) {
            setwebAuthenticationFieldsValues({
                ...(tenantGlobalConfig.webAuthenticationFields)
            })
        }
    }, [tenantGlobalConfig])
    return (
        <>
            <a onClick={e => {
                e.preventDefault();
                setexpandMoreConfig(!expandMoreConfig)
            }} className='flex mt-3 no-underline edit-link-option align-center'>
                <span className="mr-1"> Advanced config (override global configs)</span>
                {expandMoreConfig ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" className="svg-icon"
                        viewBox="0 0 18 12" fill="none">
                        <path
                            d="M0.34627 9.08042L8.16974 1.25695C8.27876 1.14755 8.40831 1.06074 8.55095 1.00151C8.69359 0.942283 8.84653 0.911793 9.00098 0.911793C9.15543 0.911793 9.30836 0.942283 9.45101 1.00151C9.59365 1.06074 9.7232 1.14755 9.83222 1.25695L17.6557 9.08042C17.8761 9.30088 18 9.59989 18 9.91166C18 10.2234 17.8761 10.5224 17.6557 10.7429C17.4352 10.9634 17.1362 11.0872 16.8244 11.0872C16.5127 11.0872 16.2137 10.9634 15.9932 10.7429L9 3.7497L2.0068 10.7439C1.78634 10.9643 1.48734 11.0882 1.17556 11.0882C0.863781 11.0882 0.564774 10.9643 0.344315 10.7439C0.123856 10.5234 7.5514e-08 10.2244 1.0277e-07 9.91264C1.30027e-07 9.60086 0.123856 9.30185 0.344315 9.08139L0.34627 9.08042Z"
                            fill="#1B72E6" />
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9"
                        viewBox="0 0 18 12" fill="none" className="svg-icon">
                        <path
                            d="M17.6537 2.91958L9.83027 10.743C9.72124 10.8524 9.59169 10.9393 9.44905 10.9985C9.30641 11.0577 9.15347 11.0882 8.99902 11.0882C8.84457 11.0882 8.69164 11.0577 8.54899 10.9985C8.40635 10.9393 8.2768 10.8524 8.16778 10.743L0.344312 2.91958C0.123853 2.69912 -3.2851e-09 2.40011 0 2.08834C3.2851e-09 1.77656 0.123853 1.47755 0.344312 1.25709C0.564772 1.03663 0.863779 0.912782 1.17556 0.912782C1.48733 0.912782 1.78634 1.03663 2.0068 1.25709L9 8.25029L15.9932 1.25612C16.2137 1.03566 16.5127 0.911804 16.8244 0.911804C17.1362 0.911804 17.4352 1.03566 17.6557 1.25612C17.8761 1.47658 18 1.77558 18 2.08736C18 2.39914 17.8761 2.69814 17.6557 2.9186L17.6537 2.91958Z"
                            fill="#1B72E6" />
                    </svg>
                )}
            </a>
            {
                expandMoreConfig && (
                    <div className="p-2">
                        <h3 className="mb-2">Web Authentication Fields</h3>
                        {
                            webAuthenticationFields.map(variable => {
                                return (
                                    <div className="mb-4 pl-2">
                                        <label className="white-space-no-wrap mr-2 bold w-full d-block mb-2 label">
                                            {variable.display}
                                        </label>
                                        <input
                                            type="text"
                                            value={webAuthenticationFieldsValues[variable.name]}
                                            onChange={e => {
                                                let updatedConfig = {
                                                    ...webAuthenticationFieldsValues,
                                                    [variable.name]: e.target.value
                                                }
                                                setwebAuthenticationFieldsValues(updatedConfig)
                                                handleUpdate && handleUpdate(updatedConfig)
                                            }}
                                            className="input-field w-full p-2 input-txt"
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
        </>
    )
}