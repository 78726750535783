import { useState, useEffect } from 'react'
import AppIcon from '../../comps/atoms/AppIcon/AppIcon.jsx';
import storeService from '../../services/store.service.js';
import AddEditEnvironment from './AddEditEnvironment.jsx';
import EnvironmentListItem from './EnvironmentListItem.jsx'
export default (props) => {
    // const { item } = props;
    const [item, setItem] = useState(null)
    useEffect(() => {
        if (props.id) {
            let tItem = storeService.store.TENANTS.items.find(x => x._id == props.id);
            if (tItem) {
                setItem(tItem);
            }
        }
    }, [props.id])
    if (!item) return null;
    return (
        <>
            <tr className='table-row-title'>
                <td colSpan={3} className="table-row-details">
                    <div className='flex justify-between'>
                        <div className='para-3 mr-2 font-1rem flex align-center'>
                            <span className="table-heading capitalize">{item.name}</span>
                            <span className='ml-4'>
                                <AppIcon iconName='arrowdown'></AppIcon>
                            </span>
                        </div>
                        <div className='flex align-center'>
                            <span className='caption-1 pr-3'>Tenant Id - {item.tenantId || 'N/A'}</span>
                            <span className='caption-1 pr-3'>Site Id - {item.siteId || 'N/A'}</span>
                            <a onClick={e => {
                                props.openAddEditEnvironment(item, null)
                            }} className='py-1 flex align-center no-underline edit-link-option mr-4'>
                                Add Environment
                            </a>
                            <div className='mr-4'>
                                <span className='caption-1'>Environments - </span>
                                <span className='caption-1'>{item?.environments?.length || 0}</span>
                            </div>
                            {
                                <a onClick={e => {
                                    props.handleEditTenant(item)
                                }} className='py-1 flex align-center no-underline edit-link-option mr-4'>
                                    Edit
                                </a>
                            }
                            {
                                item.name != 'default' && (<a onClick={e => {
                                    props.handleDeleteTenant(item)
                                }} className='py-1 flex align-center no-underline edit-link-option mr-4'>
                                    Delete
                                </a>)
                            }
                        </div>
                    </div>
                </td>
            </tr>
            {
                item.environments ? (
                    item.environments.length > 0 ? (
                        item.environments.map(environment => {
                            return (
                                <EnvironmentListItem openDeleteEnvironment={props.openDeleteEnvironment} tenant={item} environment={environment} openAddEditEnvironment={props.openAddEditEnvironment}></EnvironmentListItem>
                            )
                        })
                    ) :
                        (
                            <tr className="table-content">
                                <td colSpan={3}>
                                    <div className='flex justify-center'>
                                        No environments configured.
                                    </div>
                                </td>
                            </tr>
                        )
                ) :
                    (
                        <tr className="table-content">
                            <td colSpan={3}>
                                <div className='flex justify-center'>
                                    No environments configured.
                                </div>
                            </td>
                        </tr>
                    )
            }
        </>
    )
}