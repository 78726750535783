import React, { useState, useEffect } from 'react';
import utilsService from '../../../services/utils.service';
import workflowsService from '../../../services/workflows.service';
import dataService from '../../../services/data.service';
import { Link } from 'react-router-dom';
import ScheduleButton from '../../molecules/ScheduleButton';
const JOB_STATUS = {
	THRESHOLD_VALUE: 3000,
	SUCCESS: "Passed",
	FAILURE: "Failed"
}
// Function to update the job status
const checkPerformance = (metrics = []) => {
	try {
		const propertiesToCheck = ['fcp', 'ttfb', 'ntt', 'lcp', 'ncp', 'pageLoadTime'];
		for (const prop of propertiesToCheck) {
			const value = metrics[prop] ? parseInt(metrics[prop]) : 0;
			if (!isNaN(value) && value > JOB_STATUS.THRESHOLD_VALUE) {
				return JOB_STATUS.FAILURE;
			}
		}
		return JOB_STATUS.SUCCESS;
	} catch (e) {
		return JOB_STATUS.FAILURE;
	}
};
const PageActionJob = (props) => {
	const { page, device, selectedItems, handleToggleChecked, refId } = props?.data || {};
	const [firstJob, setfirstJob] = useState({});
	const [disableRun, setDisableRun] = useState(false);
	const [jobStatus, setjobStatus] = useState(props.recentJobStatus)
	
	const renderWorkflowStatus = (job) => {
		let status = '-'
		if (job.status == 'FAILED' || (job.analysisStatus || '').toUpperCase() == 'FAILED') {
			return 'Failed';
		}
		if (job.status == 'IN_PROGRESS') {
			return 'Running';
		}
		if (job.analysisStatus == 'IN_PROGRESS') {
			return 'Analyzing';
		}
		if (job.status == 'TODO' || (job.analysisStatus || '').toUpperCase() == 'TODO') {
			return 'Waiting';
		}
		if (job.status == 'DONE' && (job.analysisStatus || '').toUpperCase() == 'DONE') {
			if (job.results && job.results.length > 0) {
				return checkPerformance(job.results[0].metrics);
			}
			return 'Failed';
		}
		return status;
	}
	const handleRunJob = async (e) => {
		e.preventDefault();
		setDisableRun(true);
		await dataService.runpageactionsondevices({
			items: [
				{
					_id: page._id,
					device: firstJob ? firstJob.device : device,
					deviceId: firstJob ? firstJob.deviceId : device._id,
					refId: page.refId,
					steps: page.steps,
					configs: page.configs
				}
			]
		})
		await dataService.getWorkflowsWithDetails();
		setDisableRun(false);
	}
	useEffect(() => {
		if (firstJob) {
			// jobStatus = renderWorkflowStatus(firstJob);
		}
	}, [firstJob])
	useEffect(() => {
		if (props.data && props.data.device) {
			setfirstJob(props.data.device?.jobs?.[0])
		}
	}, [props.data])
	 
	useEffect(() => {
		setjobStatus(props.recentJobStatus);
	}, [props.recentJobStatus])
	return (
		<tr className={`table-content ${page?.jobsLoading ? 'flow-item-overview' : ''}`}>
			<td className="table-text" colSpan={3}>
				<Link to={`/page-action/page/${page?._id}/device/${device?._id}`}>
					<div className="justify-between align-center">
						<span className="table-text">{device?.name}</span>
						<img src="/icons/arrow-right.svg" className="svg-icon" />
					</div>
				</Link>
			</td>
			<td className="">
				<div className="justify-between align-center font-xs font-status-color">
					{
						firstJob ? (
							<span>{utilsService.getDisplayDateTime(firstJob?.jobCompletedAt || firstJob.createdAt, true)}</span>
						): (
							<span>Not Yet</span>
						)
					}
					<div className="flex align-center">
						<ScheduleButton display="ICON" mode="WORKFLOW_PAGE_LIST_DEVICE" workflowId={page._id} deviceId={device._id}/>
						{
							(!(jobStatus == 'Waiting' || jobStatus == 'Running' || jobStatus == 'Analyzing') && !disableRun) ? (
								<a onClick={e => handleRunJob(e)} href="#" className="svg-icon-holder ml-2">
									<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
										viewBox="0 0 12 12" fill="none">
										<path
											d="M10.7818 5.0593L2.42954 0.16242C2.25439 0.0600694 2.05403 0.00409737 1.84893 0.000216522C1.64383 -0.00366432 1.44133 0.044685 1.26209 0.14033C1.08286 0.235974 0.933306 0.375489 0.828704 0.544635C0.724101 0.71378 0.668194 0.906499 0.666687 1.10312V10.8969C0.668194 11.0935 0.724101 11.2862 0.828704 11.4554C0.933306 11.6245 1.08286 11.764 1.26209 11.8597C1.44133 11.9553 1.64383 12.0037 1.84893 11.9998C2.05403 11.9959 2.25439 11.9399 2.42954 11.8376L10.7818 6.9407C10.9502 6.84244 11.0894 6.70429 11.186 6.53954C11.2826 6.37479 11.3334 6.18898 11.3334 6C11.3334 5.81102 11.2826 5.62521 11.186 5.46046C11.0894 5.29571 10.9502 5.15756 10.7818 5.0593Z"
											fill="#1D4273" />
									</svg>
								</a>
							) : (
								<span>

								</span>
							)
						}
					</div>
				</div>
			</td>
			 
			<td
				className={`${jobStatus == 'Failed' ? 'danger' : ''}`}
			>
				<div className="justify-between align-center font-xs">
					<span className='capitalize' id="failed-status">
						{jobStatus} { jobStatus == 'Failed' && props.finalPriority ? `- ${props.finalPriority}`: '' }
					</span>
				</div>
			</td>
		 
			<td>
				 
				<label className={'form-field-checkbox ' + (page.status == 'IN_PROGRESS' ? 'disabled' : '')}>
					<input type="checkbox"
						className={(page.status == 'IN_PROGRESS' ? 'disabled' : '')}
						checked={selectedItems?.deviceIds?.length ? selectedItems.deviceIds.includes(device?._id) : false}
						onChange={e => handleToggleChecked && handleToggleChecked(e, page.refId, device?._id, page, refId)}
					/>
					<span className="checkmark"></span>
				</label>
			</td>
		</tr>
	)
}

export default PageActionJob;