import LicenseManager from "../comps/organisms/LicenseManager/LicenseManager"

export default () => {
    return (
        <div className="login-wrapper flex justify-center align-center page-wrapper">
            <div>
                <LicenseManager mode="PAGE" onSuccessLicense={() => {
                    location.href = "/";
                }} />
            </div>
        </div>
    )
}