import React, { useRef, useState, useEffect } from "react";

const BarChart = (props) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);

  const handleResize = () => {
    const { offsetWidth } = ref.current;
    setWidth(parseInt(offsetWidth - 48));
  };
  
  useEffect(() => {
    const { offsetWidth } = ref.current;
    // deduct the padding of the container 48px
    setWidth(parseInt(offsetWidth - 48));
    // Resize event to udpate the svg dimensions
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { total = 10, pass = 5, fail = 5 } = props;

  const getMultiplesOfTen = (total) => {
    const result = [];
    const roundedUp = Math.ceil(total / 10) * 10;
    for (let i = 0; i <= roundedUp; i += 10) {
      result.push(i);
    }
    return result;
  };

  const getPositions = (numPoints) => {
    const spacing = (width - 60) / (numPoints - 1);
    const pointers = [];
    for (let i = 0; i < numPoints; i++) {
      let position = parseInt(i * spacing) + 60;
      pointers.push(position);
    }
    return pointers;
  };

  const colors = ["#E6E8F3", "#26A5A5", "#C74068"];
  const YAxisPositions = [3, 30, 57];
  const xAxisLabels = getMultiplesOfTen(total);
  const highestQty = xAxisLabels[xAxisLabels.length - 1];
  const positions = getPositions(xAxisLabels.length);
  const widthWithoutLabels = width - 60;
  const barWidths = [
    parseInt(widthWithoutLabels * (total / highestQty)),
    parseInt(widthWithoutLabels * (pass / highestQty)),
    parseInt(widthWithoutLabels * (fail / highestQty)),
  ];
 
  return (
    <div className="px-6 py-4 barchart" ref={ref}>
      <div className="flex">
        <div className="px-4">
          <div className="trend-icon-holder">
            {
              props.icon == 'mobile' ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="22" viewBox="0 0 14 22" fill="none">
                  <path
                      d="M10.5521 0H3.44791C2.62925 0.00599775 1.84583 0.333871 1.26693 0.912767C0.688035 1.49166 0.360162 2.27509 0.354164 3.09375V18.9062C0.360162 19.7249 0.688035 20.5083 1.26693 21.0872C1.84583 21.6661 2.62925 21.994 3.44791 22H10.5521C11.3707 21.994 12.1542 21.6661 12.7331 21.0872C13.312 20.5083 13.6398 19.7249 13.6458 18.9062V3.09375C13.6398 2.27509 13.312 1.49166 12.7331 0.912767C12.1542 0.333871 11.3707 0.00599775 10.5521 0ZM3.44791 1.60417H10.5521C10.9453 1.61008 11.3208 1.76892 11.5988 2.047C11.8769 2.32508 12.0357 2.70053 12.0417 3.09375V3.36875H1.95833V3.09375C1.96424 2.70053 2.12308 2.32508 2.40116 2.047C2.67924 1.76892 3.05469 1.61008 3.44791 1.60417ZM12.0417 4.97292V15.95H1.95833V4.97292H12.0417ZM10.5521 20.3958H3.44791C3.05469 20.3899 2.67924 20.2311 2.40116 19.953C2.12308 19.6749 1.96424 19.2995 1.95833 18.9062V17.5542H12.0417V18.9062C12.0357 19.2995 11.8769 19.6749 11.5988 19.953C11.3208 20.2311 10.9453 20.3899 10.5521 20.3958Z"
                      fill="#8BADDC" />
                  <path
                      d="M8.94804 18.0354H5.05221C4.83948 18.0354 4.63547 18.1199 4.48505 18.2703C4.33463 18.4207 4.25012 18.6248 4.25012 18.8375C4.25012 19.0502 4.33463 19.2542 4.48505 19.4046C4.63547 19.5551 4.83948 19.6396 5.05221 19.6396H8.94804C9.16076 19.6396 9.36478 19.5551 9.5152 19.4046C9.66562 19.2542 9.75012 19.0502 9.75012 18.8375C9.75012 18.6248 9.66562 18.4207 9.5152 18.2703C9.36478 18.1199 9.16076 18.0354 8.94804 18.0354Z"
                      fill="#8BADDC" />
              </svg>
              ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.8 12.3892C1.8 12.3905 6.60015 12.3909 16.2 12.3909C16.1996 12.3909 16.1994 11.8259 16.1993 10.696H1.80021L1.8 12.3892ZM1.8004 9.00148H16.1993C16.1994 7.34788 16.1996 5.08925 16.2 2.22561C16.2 2.22434 11.4003 2.22391 1.8 2.22391C1.80073 2.22391 1.80087 4.48318 1.8004 9.00148ZM0 2.22561C0 1.28898 0.8082 0.529419 1.8 0.529419H16.2C17.194 0.529419 18 1.28728 18 2.22561V12.3892C18 13.3258 17.1913 14.0854 16.2 14.0854H12.1449L12.5412 15.5952C12.8022 16.5881 12.0687 17.471 10.986 17.4706H6.99269C5.90054 17.4706 5.17559 16.5632 5.48249 15.5727L5.94317 14.0854H1.8C0.80595 14.0854 0 13.3275 0 12.3892V2.22561ZM7.81831 14.0854L7.29463 15.7761H10.7353L10.2911 14.0854H7.81831Z"
                  fill="#8BADDC"
                />
              </svg>
              )
            }
            
          </div>
        </div>
        <div className="trend-top-head">
          <div className="trend-top-head__no">{ total }</div>
          <div className="trend-top-head__device">{props.label}</div>
        </div>
      </div>
      <div className="trends_svg">
        <svg width={width} height="93">
          <text x="53" y="12" textAnchor="end">
            Total Run
          </text>
          <text x="53" y="39" textAnchor="end">
            Passed
          </text>
          <text x="53" y="66" textAnchor="end">
            Failed
          </text>
          {positions.map((position, index) => (
            <React.Fragment key={'frag-' + index}>
              <text key={`xlabel_${index}`} x={position} y="84" textAnchor={index === positions.length -1 ? 'end': 'middle'}>
                {xAxisLabels[index]}
              </text>
              <path key={`xpath_${index}`} d={`M${position},0 V72`} stroke="#D2DFF6" />
            </React.Fragment>
          ))}
          {YAxisPositions.map((yPos, index) => (
            <path
              key={'path2' + index}
              d={`M60,${yPos} h${barWidths[index]} q5,0 5,5 v0 q0,5 -5,5 h${-1 * barWidths[index]} z`}
              fill={colors[index]}
            />
          ))}
        </svg>
      </div>
    </div>
  );
};

export default BarChart;
