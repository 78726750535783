import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import storeService from "../../services/store.service";

export default () => {
    const [pages, setPages] = useState([]);
    const [routeParams, setRouteParams] = useState({})
    const [navigationList, setNavigationList] = useState([
        {
            to: "/ui-regression",
            name: "SightSense",
        }
    ]);
    const location = useLocation();
    const params = useParams();
    useEffect(() => {
        let pageStore = storeService.getStore('PAGES_WITH_DETAILS');
        storeService.subscribe('PAGES_WITH_DETAILS', ({ detail }) => {
            loadPages(detail.items);
        });
        if (pageStore.loaded) {
            loadPages(pageStore.items);
        }
    }, [])
    useEffect(() => {
        setRouteParams(params);
        if(params.pageId) {
            let page = pages.find(x => x._id == params.pageId);
            if (page) {
                let deviceItem = [...page.physicalDevices, ...page.virtualDevices].find(x => x._id == params.deviceId);
                if (deviceItem) {
                    let navigationList = [
                        {
                            to: "/ui-regression",
                            name: "SightSense",
                        },
                        {
                            to: `/ui-regression/pages/${page._id}`,
                            name: page.name + ' (' + deviceItem.name + ')',
                        }
                    ];
                    setNavigationList(navigationList);
                    return;
                }
            }
        }
        setNavigationList([
            {
                to: "/ui-regression",
                name: "SightSense",
            }
        ]);
    }, [location, pages])
    const loadPages = (items) => {
        setPages(items);
    }
    const removeDeviceName = pageName => {
        const index = pageName.indexOf('(');
        return index !== -1 ? pageName.substring(0, index) : pageName;
    }
    return (
        <ul className="flex no-disc">
            {
                navigationList && navigationList.map((item, idx) => {
                    if (navigationList.length - 1 == idx || navigationList.length == 1) {
                        return (
                            <li className="ml-2" key={'breadcrumbs-' + idx}>
                                <Link to="/ui-regression">
                                <div className={'align-center '}>
                                    {
                                        navigationList.length > 0 && idx != 0 && (
                                            <svg className="svg-icon-m" xmlns="http://www.w3.org/2000/svg" width="14"
                                                height="24" viewBox="0 0 14 24" fill="#1b72e6">
                                                <path
                                                    d="M11.1073 23.5383L0.675978 13.107C0.530108 12.9617 0.414368 12.7889 0.335395 12.5987C0.256421 12.4085 0.215767 12.2046 0.215767 11.9987C0.215767 11.7928 0.256421 11.5888 0.335395 11.3987C0.414368 11.2085 0.530108 11.0357 0.675978 10.8904L11.1073 0.459083C11.4012 0.165137 11.7999 1.92838e-08 12.2156 1.87067e-08C12.6313 1.81296e-08 13.03 0.165137 13.3239 0.459083C13.6179 0.753029 13.783 1.15171 13.783 1.56741C13.783 1.98311 13.6179 2.38179 13.3239 2.67573L3.99965 12L13.3252 21.3243C13.6192 21.6182 13.7843 22.0169 13.7843 22.4326C13.7843 22.8483 13.6192 23.247 13.3252 23.5409C13.0313 23.8349 12.6326 24 12.2169 24C11.8012 24 11.4025 23.8349 11.1086 23.5409L11.1073 23.5383Z" />
                                            </svg>
                                        )
                                    }
                                    <h1 className="heading-text ml-2">{removeDeviceName(item.name)}</h1>
                                </div>
                                </Link>
                            </li>
                        )
                    }
                    // return (
                    //     <li key={'breadcrumbs-' + idx}>
                    //         <Link to={item.to}>
                    //             <div className="align-center">
                    //                 <svg className="svg-icon-m" xmlns="http://www.w3.org/2000/svg" width="14" height="24"
                    //                     viewBox="0 0 14 24" fill="none">
                    //                     <path
                    //                         d="M11.1073 23.5383L0.675978 13.107C0.530108 12.9617 0.414368 12.7889 0.335395 12.5987C0.256421 12.4085 0.215767 12.2046 0.215767 11.9987C0.215767 11.7928 0.256421 11.5888 0.335395 11.3987C0.414368 11.2085 0.530108 11.0357 0.675978 10.8904L11.1073 0.459083C11.4012 0.165137 11.7999 1.92838e-08 12.2156 1.87067e-08C12.6313 1.81296e-08 13.03 0.165137 13.3239 0.459083C13.6179 0.753029 13.783 1.15171 13.783 1.56741C13.783 1.98311 13.6179 2.38179 13.3239 2.67573L3.99965 12L13.3252 21.3243C13.6192 21.6182 13.7843 22.0169 13.7843 22.4326C13.7843 22.8483 13.6192 23.247 13.3252 23.5409C13.0313 23.8349 12.6326 24 12.2169 24C11.8012 24 11.4025 23.8349 11.1086 23.5409L11.1073 23.5383Z"
                    //                         fill="#1D4273" />
                    //                 </svg>
                    //                 <h1 className="h1-heading ml-2">{item.name}</h1>
                    //             </div>
                    //         </Link>
                    //     </li>
                    // )
                })
            }
        </ul>
    )
}