import apiService from './api.service.js'
import utils from './utils.service.js';
import storeService from './store.service.js';
export default {
    async getAllFeatures(payload = {}) {
        if (storeService.store.FEATURE_FLAGS.loaded) {
            let lastLoaded = storeService.store.FEATURE_FLAGS.lastLoaded;
            let diff = new Date() - lastLoaded;
            if (diff < 100) {
                return;
            }
        }
        const apiResp = await apiService.post({
            url: utils.END_POINTS.getfeatureflags,
            data: payload
        });
        const { data = [] } = apiResp;
        const itemMap = storeService.store.FEATURE_FLAGS.itemMap;
        data.forEach(item => {
            let localFlag = itemMap.get(item.name) || {};
            itemMap.set(item.name, { value: item.value, section: item.section || 'GLOBAL', description: item.description || localFlag.description || '' })
        });
        storeService.store.FEATURE_FLAGS.lastLoaded = new Date();
        storeService.store.FEATURE_FLAGS.loaded = true;
        return apiResp;
    },
    async saveflags(payload) {
        const apiResp = await apiService.post({
            url: utils.END_POINTS.savefeatureflags,
            data: payload
        });
        const { data = [] } = apiResp;
        const itemMap = storeService.store.FEATURE_FLAGS.itemMap;
        data.forEach(item => {
            itemMap.set(item.name, { value: item.value, section: item.section || 'GLOBAL', description: item.description || '' })
        });
        return apiResp;
    }
}