const getPath = (action, method) => {
    return `/?action=${action}&method=${method}`
}
const getAIRegressionHostname = () => {
    return 'http://' + location.hostname + ':3100/'
}
const padStart = (num) => {
    return num.toString().padStart(2, '0');
}
export default {
    GLOBALS: {
        skipApiTimeDelta: 1000 * 30
    },
    CONSTANTS: {
        EVENT_NAME: {
            OPEN_ADD_PAGE_MODAL: 'OPEN_ADD_PAGE_MODAL',
            OPEN_TESTCASES_SIDEBAR_MODAL: 'OPEN_TESTCASES_SIDEBAR_MODAL',
            CHANGE_API_COLLECTION_TEST_RUN: 'CHANGE_API_COLLECTION_TEST_RUN',
            OPEN_CHAT_DETAILS_MODAL: 'OPEN_CHAT_DETAILS_MODAL',
            OPEN_CHAIN_API_MODAL: 'OPEN_CHAIN_API_MODAL',
            OPEN_EDIT_CHAIN_API_MODAL: 'OPEN_EDIT_CHAIN_API_MODAL',
            OPEN_FILTER_MODAL: 'OPEN_FILTER_MODAL',
            SERVER_LOG: 'SERVER_LOG',
            OPEN_SETTINGS_MODAL: 'OPEN_SETTINGS_MODAL',
            ANALYSIS_JOB_DONE: 'ANALYSIS_JOB_DONE',
            USERFLOW_JOB_DONE: 'USERFLOW_JOB_DONE',
            USERFLOW_JOB_STARTED: 'USERFLOW_JOB_STARTED',
            OPEN_TESTCASES_DETAILS_SIDEBAR_MODAL: 'OPEN_TESTCASES_DETAILS_SIDEBAR_MODAL'
        }
    },
    END_POINTS: {
        getConfigs: getPath('global', 'configs'),
        getDevices: getPath('global', 'devices'),
        addDevicesList: getPath('global', 'adddeviceslist'),
        getDevicesList: getPath('global', 'getdeviceslist'),
        addNewDevice: getPath('global', 'addnewdevice'),
        updateDevice: getPath('global', 'updatedevice'),
        deleteDevice: getPath('global', 'deletedevice'),
        addEnvironmentList: getPath('global', 'addenvironmentlist'),
        getEnvironments: getPath('global', 'getenvironments'),
        updateEnvironmentItem: getPath('global', 'updateenvironmentitem'),
        addEnvironmentsConfig: getPath('global', 'addenvironmentsconfig'),
        getEnvironmentsConfigs: getPath('global', 'getenvironmentsconfigs'),
        updateEnvironmentsConfig: getPath('global', 'updateenvironmentsconfig'),
        deleteEnvironmentConfig: getPath('global', 'deleteenvironmentconfig'),
        updateCredentials: getPath('global', 'updatecredentials'),
        deleteCredentials: getPath('global', 'deletecredentials'),
        deleteEnvironmentItem: getPath('global', 'deleteenvironmentitem'),
        getApiData: getPath('global', 'getapidata'),
        addApiData: getPath('global', 'addapidata'),
        deleteApiData: getPath('global', 'deleteapidata'),
        addPages: getPath('pages', 'addpages'),
        updatePages: getPath('pages', 'updatepage'),
        getPagesWithDetails: getPath('pages', 'withdetails'),
        getpages: getPath('pages', 'getpages'),
        getpagejobs: getPath('pages', 'getpagejobs'),
        getpagecomparejobs: getPath('pages', 'getpagecomparejobs'),

        getpagesv2: getPath('pages', 'getpagesv2'),
        getpagesv3: getPath('pages', 'getpagesv3'),
        getpagejobsv2: getPath('pages', 'getpagejobsv2'),
        getpagecomparejobsv2: getPath('pages', 'getpagecomparejobsv2'),

        addjobs: getPath('pages', 'addjobs'),
        adddevices: getPath('pages', 'adddevices'),
        addcollections: getPath('collections', 'addcollections'),
        updatecollection: getPath('collections', 'updatecollection'),
        getCollectionsWithDetails: getPath('collections', 'withdetails'),
        getCollections: getPath('collections', 'getCollections'),
        getCollectionsv2: getPath('collections', 'getCollectionsv2'),
        gettestresults: getPath('collections', 'gettestresults'),
        gettestresultsv2: getPath('collections', 'gettestresultsv2'),
        getWorkflowsWithDetails: getPath('pageactions', 'workflowswithdetails'),
        getworkflows: getPath('pageactions', 'getworkflows'),
        getworkflowjobs: getPath('pageactions', 'getworkflowjobs'),
        getjobsbyuserflowid: getPath('pageactions', 'getjobsbyuserflowid'),
        getworkflowdevices: getPath('pageactions', 'workflowdevices'),
        runApiTests: getPath('collections', 'runtests'),
        deletetestcase: getPath('collections', 'deletetestcase'),
        updatetestcase: getPath('collections', 'updatetestcase'),
        authenticate: getPath('users', 'authenticate'),
        tokeninfo: getPath('users', 'tokeninfo'),
        licenseinfo: getPath('users', 'licenseinfo'),
        addTestData: getPath('testdata', 'addtestdata'),
        getTestData: getPath('testdata', 'gettestdata'),
        generateTestcases: getPath('collections', 'generatetestcases'),
        deletePages: getPath('pages', 'deletepages'),
        getModulesSummary: getPath('modules', 'getmodulessummary'),
        addRegressionPage: getPath('usagedata', 'addregressionpage'),
        deleteCollections: getPath('collections', 'deletecollections'),
        addNewPageAction: getPath('usagedata', 'addnewpageaction'),
        getBotResponse: getPath('usagedata', 'getresponseforchat'),
        saveUsageTestCase: getPath('usagedata', 'saveusagetestcase'),
        deleteTestCases: getPath('usagedata', 'deletetestcases'),
        getUsagePulseOverview: getPath('usagedata', 'getusagepulseoverview'),
        deleteUsageData: getPath('usagedata', 'deleteusagedata'),
        executeUserflowRegression: getPath('userflowspage', 'executeflow'),
        testwebauthentication: getPath('userflowspage', 'testwebauthentication'),
        // page actions APIs goes here
        getuserflows: getPath('pageactions', 'getuserflows'),
        getuserflowjobbyid: getPath('pageactions', 'getuserflowjobbyid'),
        updateuserflowjobbyid: getPath('pageactions', 'updateuserflowjobbyid'),
        getUsageData: getPath('usagedata', 'getUsageData'),
        getusagedatabyid: getPath('usagedata', 'getusagedatabyid'),
        generaterecommendation: getPath('usagedata', 'generaterecommendation'),
        loadrecommendation: getPath('usagedata', 'loadrecommendation'),
        getPagePerformanceData: getPath('usagedata', 'getPagePerformanceData'),
        longpolling: getPath('usagedata', 'longpolling'),
        getxpathrecommendations: getPath('usagedata', 'getxpathrecommendations'),
        logxpathrecommendations: getPath('usagedata', 'logxpathrecommendations'),
        getfailedworkflowjobs: getPath('pageactions', 'getfailedworkflowjobs'),
        runpageactionsondevices: getPath('pageactions', 'runpageactionsondevices'),
        deleteworkflows: getPath('pageactions', 'deleteworkflows'),
        updateBaseLineJobs: getPath('pages', 'updatebaselinejobs'),
        loadmetadata: getPath('pages', 'loadmetadata'),
        loadcollectionsmetadata: getPath('collections', 'loadmetadata'),
        getuserflowjobdevices: getPath('pageactions', 'getuserflowjobdevices'),
        getapiglidedetails: getPath('pageactions', 'getapiglidedetails'),
        getsightsensedetails: getPath('pageactions', 'getsightsensedetails'),
        getpageblazerdetails: getPath('pageactions', 'getpageblazerdetails'),

        getrecommendationcardsummary: getPath('userflows', 'getrecommendationcardsummary'),

        // Stats API here
        homePageStats: getPath('stats', 'gethomepagestats'),
        apiGlideStats: getPath('stats', 'getapiglidestats'),
        sightSenseStats: getPath('stats', 'getsightsensestats'),
        getstats: getPath('stats', 'getstats'),
        pageblazzerStats: getPath('stats', 'getpageblazzerstats'),
        reloadhomestats: getPath('stats', 'reloadhomestats'),
        getqastats: getPath('stats', 'getqastats'),
        // apiimport
        addapiimportdata: getPath('apiimport', 'addapiimportdata'),
        generateapicollection: getPath('apiimport', 'generateapicollection'),
        readapioutput: getPath('apiimport', 'readapioutput'),
        getsiteconfig: getPath('siteconfig', 'getsiteconfig'),
        // gptPrompts
        getgptprompts: getPath('gptprompts', 'getgptprompts'),
        updateprompt: getPath('gptprompts', 'updateprompt'),

        addschedulejob: getPath('schedule', 'addschedulejob'),
        getschedulejobs: getPath('schedule', 'getschedulejobs'),
        getschedulejobsbypage: getPath('schedule', 'getschedulejobsbypage'),
        deleteschedulejobs: getPath('schedule', 'deleteschedulejobs'),
        updateschedulejob: getPath('schedule', 'updateschedulejob'),

        getfeatureflags: getPath('featureflags', 'getallflags'),
        updatefeatureflag: getPath('featureflags', 'updateflag'),
        savefeatureflags: getPath('featureflags', 'saveflags'),

        gettenants: getPath('tenants', 'gettenants'),
        addtenants: getPath('tenants', 'addtenants'),
        updatetenant: getPath('tenants', 'updatetenant'),
        deletetenant: getPath('tenants', 'deletetenant'),
        aggregateexecute: getPath('aggregate', 'execute'),

        getnotificationschedules: getPath('schedule', 'getnotificationschedules'),
        addnotificationschedule: getPath('schedule', 'addnotificationschedule'),
        updatesemailschedule: getPath('schedule', 'updatesemailschedule'),
        deleteemailschedule: getPath('schedule', 'deleteemailschedule'),


        getlicenses: getPath('license', 'getlicenses'),
        activatelicense: getPath('license', 'activatelicense'),
        getlicensepings: getPath('license', 'getlicensepings'),

        getusers: getPath('tenants', 'getusers'),
        deleteuser: getPath('tenants', 'deleteuser'),
        adduser: getPath('tenants', 'adduser'),
        updateuser: getPath('tenants', 'updateuser'),

        loadmeta: getPath('stats', 'loadmeta'),
        getpagemapurlsbytenent: getPath('tenants', 'getpagemapurlsbytenent'),
        addpageurlmapping: getPath('tenants', 'addpageurlmapping'),
        copypageurlmappings: getPath('tenants', 'copypageurlmappings'),

    },
    ALLOWED_PROPS: {
        DEVICE_PROPS: ['_id', 'createdAt', 'createdBy', 'deviceGlass', 'deviceMode', 'dimensions', 'lastRunDate', 'name']
    },
    getDisplayDateTime(dateToConvert, withDateObject = false, withtime = true) {
        if (!dateToConvert) return;
        if (withDateObject) {
            let localDate = new Date(dateToConvert);
            let hours = localDate.getHours();
            let minutes = localDate.getMinutes();
            const modifier = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12 || 12;
            const formattedHours = hours.toString().padStart(2, '0');
            let formattedMinutes = minutes.toString().padStart(2, '0');
            let timePart = `${formattedHours}:${formattedMinutes} ${modifier}`;
            return (localDate.getMonth() + 1).toString().padStart(2, '0') + '/' + (localDate.getDate()).toString().padStart(2, '0') + '/' + localDate.getFullYear() + (withtime ? (' @ ' + timePart) : '');
        }
        let [date1, time1] = dateToConvert.split('T');
        let [yyyy, mm, dd] = date1.split('-');
        return mm + '/' + dd + '/' + yyyy;
    },
    getDisplayDateTimeObject(dateToConvert) {
        let [yyyy, mm, dd] = [dateToConvert.getFullYear(), dateToConvert.getMonth() + 1, dateToConvert.getDate()]
        return (mm < 10 ? '0' + mm : mm) + '/' + (dd < 10 ? '0' + dd : dd) + '/' + yyyy;
    },
    getYMDFormat(dateToConvert, withDateObject = false) {
        if (typeof dateToConvert == 'string') {
            if (withDateObject) {
                let dt = new Date(dateToConvert);
                let mnth = dt.getMonth() + 1;
                let date1 = dt.getDate();
                return dt.getFullYear() + '-' + (mnth < 10 ? '0' : '') + mnth + '-' + (date1 < 10 ? '0' : '') + dt.getDate();
            }
            let [date1, time1] = dateToConvert.split('T');
            let [yyyy, mm, dd] = date1.split('-');
            return yyyy + '-' + mm + '-' + dd;
        }
        let dt = dateToConvert;
        let mnth = dt.getMonth() + 1;
        let date1 = dt.getDate();
        return dt.getFullYear() + '-' + (mnth < 10 ? '0' : '') + mnth + '-' + (date1 < 10 ? '0' : '') + dt.getDate();
    },
    getAIRegressionHostname,
    log(logOpts, logData, ...msg) {
        let { level, color } = logOpts;
        if (logData && logData.exception) {
            console.log('ERROR: ', logData.exception);
            return;
        }
        console.log(...msg);
    },
    guid(len) {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        if (len == 8) {
            return s4() + s4();
        }
        switch (len) {
            case 4:
                return s4();
                break;
            case 8:
                return s4() + s4();
                break;
            case 12:
                return s4() + s4() + s4();
                break;
        }
        return s4() + s4() + s4() + s4() + s4() + s4() + (new Date).getTime().toString(16);
    },
    getAllowedProps(obj, allowedProps) {
        let updateObj = {};
        allowedProps.forEach(key => {
            updateObj[key] = obj[key];
        })
        return updateObj;
    },
    filterPageObject(page) {
        return this.getAllowedProps(page, ['_id', 'name', 'createdAt', 'url'])
    },
    filterDeviceObject(device) {
        return this.getAllowedProps(device, ['_id', 'name', 'deviceGlass', 'deviceMode', 'dimensions'])
    },
    getDateFormat(dateToConvert, format, opts = {}) {
        if (format == 'YMD') {
            let dtStr = dateToConvert.getFullYear() + '-' + (dateToConvert.getMonth() + 1).toString().padStart(2, '0') + '-' + (dateToConvert.getDate()).toString().padStart(2, '0');
            return dtStr;
        }
        if (format == 'YMDHS') {
            let dateStr = dateToConvert.getFullYear() + '-' + (dateToConvert.getMonth() + 1).toString().padStart(2, '0') + '-' + (dateToConvert.getDate()).toString().padStart(2, '0');
            return dateStr + 'T' + dateToConvert.getHours().toString().padStart(2, '0') + ':' + dateToConvert.getMinutes().toString().padStart(2, '0');
        }
        return dateToConvert;
    },
    getDateAndTime(dateStr, opts = {}) {
        let date1 = new Date(dateStr);
        date1.setMilliseconds(0)
        let hour = date1.getHours();
        let minutes = date1.getMinutes();
        let year = date1.getFullYear();
        let month = date1.getMonth() + 1;
        let date = date1.getDate();

        let onlyDateStr = `${year}-${padStart(month)}-${padStart(date)}`
        let onlyDate = new Date(dateStr);
        onlyDate.setHours(0, 0, 0, 0);

        let dateTimeStr = `${onlyDateStr}T${padStart(hour)}:${padStart(minutes)}`;
        const modifier = hour >= 12 ? 'PM' : 'AM';
        hour = hour % 12 || 12;
        const formattedHours = hour.toString().padStart(2, '0');
        let formattedMinutes = minutes.toString().padStart(2, '0');
        let fullDateTimeStr = `${onlyDateStr} ${formattedHours}:${formattedMinutes} ${modifier}`
        return {
            onlyDateStr,
            onlyDate,
            dateTimeStr,
            hour,
            minutes,
            fullDateTimeStr
        }
    },
    padStart,
    get12From24Time(hours, minutes) {
        const modifier = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        const formattedHours = hours.toString().padStart(2, '0');
        let formattedMinutes = minutes.toString().padStart(2, '0');
        return {
            strVal: `${formattedHours}:${formattedMinutes} ${modifier}`,
            hours12: formattedHours,
            minutes12: formattedMinutes,
            hm12Format: `${formattedHours}:${formattedMinutes}`,
            modifier
        };
    },
    get24From12Time(hours, minutes, modifier) {
        let hour = parseInt(hours, 10);
        let minute = parseInt(minutes, 10);
        let period = modifier.toLowerCase();
        if (period === 'am' && hour === 12) {
            hour = 0;
        } else if (period === 'pm' && hour !== 12) {
            hour += 12;
        }
        const hourStr = hour.toString().padStart(2, '0');
        const minuteStr = minute.toString().padStart(2, '0');
        return {
            strVal: `${hourStr}:${minuteStr}`,
            hours24: hour,
            minutes24: minute
        };
    },
    getUtcYMD(ymdDate) {
        if (!ymdDate) return null;
        const [y, m, d] = ymdDate.split('-')
        const dt1 = new Date();
        console.log(dt1)
        dt1.setFullYear(y - 0)
        dt1.setMonth(m - 1)
        dt1.setDate(d - 0)
        console.log(dt1)
        return dt1.getUTCFullYear() + '-' + (dt1.getUTCMonth() + 1).toString().padStart(2, '0') + '-' + dt1.getUTCDate().toString().padStart(2, '0');
    },
    getTimeFromUtc(isoHour, isoMinutes) {
        // Create a Date object in UTC with the provided ISO hour and minute
        const now = new Date();
        const utcDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), isoHour, isoMinutes));

        // Convert to local timezone
        const localHour = utcDate.getHours();
        const localMinute = utcDate.getMinutes();

        // Format the hour in 12-hour format with AM/PM
        let hour12 = localHour % 12 || 12;  // Convert 24-hour format to 12-hour
        const period = localHour >= 12 ? 'PM' : 'AM';  // Determine AM/PM

        // Add leading zero to minutes if necessary
        const minuteFormatted = localMinute < 10 ? `0${localMinute}` : localMinute;

        return `${hour12}:${minuteFormatted} ${period}`;
    },
    getDaysToTillDate(tillDate) {
        if (!tillDate) return;
        let expirationDate = tillDate;
        if(typeof tillDate == 'string') {
            expirationDate = new Date(tillDate)
        }
        let daysToExpire = expirationDate - new Date();
        daysToExpire = Math.ceil(daysToExpire / (1000 * 60 * 60 * 24));
        return daysToExpire;
    }
}