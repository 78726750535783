import React, { useEffect, useState, Fragment, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import storeService from '../../services/store.service.js';
import utilsService from '../../services/utils.service.js';
import dataService from '../../services/data.service.js';
import pageutilService from '../../services/pageutil.service.js';
import { Tooltip } from 'react-tooltip';
import SvgIcon from '../atoms/SvgIcon.jsx';
import SvgCode from '../atoms/SvgCode.jsx';
import AppModal from './AppModal.jsx';
import AddDevices from './AddDevices.jsx';
import PageFilter from './PageFilter.jsx';

import TablePagination from '../molecules/TablePagination.jsx';
import SectionLoader from '../atoms/SectionLoader.jsx';
import SectionWithHeight from './SectionWithHeight.jsx';
import ConfirmModal from '../molecules/ConfirmModal.jsx';
import ScheduleButton from '../molecules/ScheduleButton.jsx';
import SearchAndFilter from '../molecules/SearchAndFilter.jsx';
let maskList = [
    {
        _id: "1231231231231231",
        name: "Name of the page 1",
        createdAt: new Date(),
        virtualDevices: [],
        physicalDevices: []
    },
    {
        _id: "1231231231231232",
        name: "Name of the page 2",
        createdAt: new Date(),
        virtualDevices: [],
        physicalDevices: []
    },
    {
        _id: "1231231231231233",
        name: "Name of the page 3",
        createdAt: new Date(),
        virtualDevices: [],
        physicalDevices: []
    },
    {
        _id: "1231231231231234",
        name: "Name of the page 4",
        createdAt: new Date(),
        virtualDevices: [],
        physicalDevices: []
    },
    {
        _id: "1231231231231235",
        name: "Name of the page 5",
        createdAt: new Date(),
        virtualDevices: [],
        physicalDevices: []
    }
]
export default ({ environment, date }) => {
    const [pages, setPages] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null)
    const [loadingPages, setLoadingPages] = useState(false)
    const [filterPages, setFilterPages] = useState([])
    const navigate = useNavigate();
    const [showAddDevices, setShowDevices] = useState(false);
    const [selectedPage, setSelectedPage] = useState(null)
    const [filterData, setFilterData] = useState(storeService.store.PAGES_WITH_DETAILS.filterData || {
        searchText: '',
        selectedTags: [],
        selectedDevices: [],
        byScheduled: null
    })
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);

    const [paginationData, setPaginationData] = useState({
        pageNo: 1,
        pageSize: 5
    })

    const [totalCount, settotalCount] = useState(0)
    const [paginationList, setPaginationList] = useState([])
    const [localTenantEnv, setLocalTenantEnv] = useState(storeService.store.ENVIRONMENTS.selectedEnvId)
    const localTenantEnvRef = useRef(storeService.store.ENVIRONMENTS.selectedEnvId)
    const [deleteDevices, setDeletedDevices] = useState([])
    const [deletePages, setDeletedPages] = useState([])
    const [disableDelete, setDisableDelete] = useState(true)
    const parsePageData = (items) => {
        let storeFilter = storeService.store.PAGES_WITH_DETAILS.filterData;
        items.forEach((page, pageIndex) => {
            page.expanded = false;
            page.devices = [...page.physicalDevices, ...page.virtualDevices];
            if (storeFilter.selectedDevices.length > 0) {
                page.devices = page.devices.filter(item => {
                    if (storeFilter.selectedDevices.find(x => x.value == item._id)) {
                        if (page.scheduledDevices && page.scheduledDevices.length > 0) {
                            return page.scheduledDevices.indexOf(item._id) >= 0;
                        } else if (page.failedDevices && page.failedDevices.length > 0) {
                            return page.failedDevices.indexOf(item._id) >= 0;
                        } else {
                            return true;
                        }
                    }
                    return false;
                })
            }
            page.devicesCount = page.devices.length;
            if (page.devices.length > 0) {
                page.devices.forEach(device => {
                    device.source = '';
                    if (device.jobs && device.jobs.length > 0) {
                        let recentjob = device.jobs[0];
                        device.source = recentjob.source;
                        device.lastRunDate = utilsService.getDisplayDateTime(recentjob.createdAt, true);
                        device.finalStatus = '-'
                        let baselineFound = device.jobs.find(x => x.isBaseline);
                        let {
                            layoutStatus,
                            imageStatus,
                            textStatus,
                            differences,
                            finalStatus,
                            failedPriority
                        } = pageutilService.getJobStatus(recentjob);
                        if (baselineFound) {
                            if (baselineFound._id == recentjob._id) {
                                device.finalStatus = recentjob.status == "DONE" ? "Passed" : "Failed";
                            } else {
                                device.finalStatus = finalStatus;
                                device.failedPriority = failedPriority;
                            }
                        } else {
                            if (recentjob.status == "DONE") {
                                device.finalStatus = finalStatus
                                device.failedPriority = failedPriority;
                            }
                            if (recentjob.status == "FAILED") {
                                device.finalStatus = "Failed";
                                device.failedPriority = failedPriority;
                            }
                            if (recentjob.status == "IN_PROGRESS") {
                                device.finalStatus = "Running";
                            }
                            if (recentjob.status == "READY") {
                                device.finalStatus = "Waiting";
                            }
                        }
                    } else {
                        device.lastRunDate = 'Not Run Yet';
                        device.textStatus = '-';
                        device.imageStatus = '-';
                        device.layoutStatus = '-';
                        device.finalStatus = '-';
                    }
                });
                page.passedDevicesCount = page.devices.filter(x => x.finalStatus == 'Passed').length;
                page.failedDevicesCount = page.devices.filter(x => x.finalStatus == 'Failed').length;
                page.notRunYetDevicesCount = page.devices.filter(x => x.finalStatus == '-').length;
            }
        })
        setFilterPages(items)
        setPages(items);
        setPaginationList([...items])
        settotalCount(storeService.store.PAGES_WITH_DETAILS.totalCount)
        setLoadingPages(false)
    }

    useEffect(() => {
        let pageStore = storeService.getStore('PAGES_WITH_DETAILS');
        storeService.subscribe('PAGES_WITH_DETAILS', ({ detail }) => {
            parsePageData(detail.items);
        });
        storeService.subscribe('ENVIRONMENTS', ({ detail }) => {
            if(storeService.store.ENVIRONMENTS.selectedEnvId != localTenantEnvRef.current) {
                localTenantEnvRef.current = storeService.store.ENVIRONMENTS.selectedEnvId
                let storeFilterData = storeService.store.PAGES_WITH_DETAILS.filterData;
                dataService.getPageWithDetails({
                    invokedBy: '169',
                    searchText: storeFilterData.searchText,
                    selectedTags: storeFilterData.selectedTags.map(x => x.value),
                    byScheduled: storeFilterData.byScheduled,
                    selectedDevices: storeFilterData.selectedDevices,
                    jobStatus: storeFilterData.jobStatus,
                });
            }
        })
        dataService.getPageWithDetails({
            invokedBy: '172',
            searchText: filterData.searchText,
            selectedTags: filterData.selectedTags.map(x => x.value),
            byScheduled: filterData.byScheduled,
            selectedDevices: filterData.selectedDevices,
            jobStatus: filterData.jobStatus,
        });
    }, [])

    const handlePaginationChange = (data) => {
        if (data.perPage) {
            setPaginationData({
                ...paginationData,
                pageSize: data.perPage,
                pageNo: 1
            })
        }
        if (data.pageNo) {
            setPaginationData({
                ...paginationData,
                pageNo: data.pageNo
            })
        }
    }

    const toggleExpandPage = (e, pageId, expand) => {
        e.preventDefault();
        const field = filterPages.find(it => it._id === pageId);
        if (field) {
            field.expanded = !expand;
        }
        setFilterPages([...filterPages]);
    }
    const navigateToDeviceDetailPage = (e, page, device) => {
        e.preventDefault();
        navigate(`/ui-regression/page/${page._id}/device/${device._id}`);
    }
    const addNewJob = async (e, page, device) => {
        e.preventDefault();
        await dataService.addJobs({
            items: [
                {
                    page: {
                        _id: page._id,
                        name: page.name,
                        url: page.url,
                        createdAt: page.createdAt
                    },
                    device: {
                        _id: device._id,
                        name: device.name,
                        dimensions: device.dimensions,
                        deviceMode: device.deviceMode,
                        deviceGlass: device.deviceGlass
                    }
                }
            ],
            environment
        });
        await dataService.getPageWithDetails({
            invokedBy: '232',
            searchText: filterData.searchText,
            selectedTags: filterData.selectedTags.map(x => x.value),
            byScheduled: filterData.byScheduled,
            selectedDevices: filterData.selectedDevices,
            jobStatus: filterData.jobStatus,
        });
    }
    const closeAddDeviceModal = () => {
        setShowDevices(false);
    }

    const handleDelete = (e) => {
        e && e.preventDefault();
        setShowDeleteModal(true);
    }
    const onConfirmation = async () => {
        let apiResponse = await dataService.deletePages({
            deleteDevices: deleteDevices,
            deletePages: deletePages
        });
        dataService.getPageWithDetails({
            invokedBy: '247',
            searchText: filterData.searchText,
            selectedTags: filterData.selectedTags.map(x => x.value),
            byScheduled: filterData.byScheduled,
            selectedDevices: filterData.selectedDevices,
            jobStatus: filterData.jobStatus,
        });
        setShowDeleteModal(false);
    }
    const handleRun = async (e) => {
        setDisableDelete(true)
        e && e.preventDefault()
        let items = [];
        deleteDevices.forEach(item => {
            let firstPage = { ...pages.find(x => x._id == item.pageId) };
            let page = {
                ...firstPage,
                physicalDevices: [...firstPage.physicalDevices].map(x => {
                    let newItem = { ...x };
                    delete newItem.jobs;
                    return newItem;
                }),
                virtualDevices: [...firstPage.virtualDevices].map(x => {
                    let newItem = { ...x };
                    delete newItem.jobs;
                    return newItem;
                })
            }
            delete page.devices;
            let device = [...page.virtualDevices, ...page.physicalDevices].find(x => x._id == item.deviceId);
            items.push({
                page: {
                    _id: page._id,
                    name: page.name,
                    url: page.url
                },
                device: {
                    _id: device._id,
                    name: device.name,
                    dimensions: device.dimensions,
                    deviceMode: device.deviceMode,
                    deviceGlass: device.deviceGlass
                }
            })
        });
        await dataService.addJobs({
            items, environment
        });
        await dataService.getPageWithDetails({
            invokedBy: '289',
            searchText: filterData.searchText,
            selectedTags: filterData.selectedTags.map(x => x.value),
            byScheduled: filterData.byScheduled,
            selectedDevices: filterData.selectedDevices,
        });
        setDeletedDevices([])
    }
    const updateDeleteDisable = (items1, items2) => {
        if (items1.length > 0 || items2.length > 0) {
            setDisableDelete(false)
        } else {
            setDisableDelete(true)
        }
    }
    const chooseDeleteDevice = (device, page) => {
        let deviceToDelete = {
            deviceId: device._id,
            pageId: page._id
        }
        let index = deleteDevices.findIndex(x => x.deviceId === deviceToDelete.deviceId && x.pageId === deviceToDelete.pageId);
        if (index > -1) {
            deleteDevices.splice(index, 1);
        } else {
            deleteDevices.push(deviceToDelete);
        }
        updateDeleteDisable(deleteDevices, []);
        setDeletedDevices([...deleteDevices]);
    }
    const chooseAllDevices = (page, targetValue) => {
        let finalItems = deleteDevices;
        if (targetValue) {
            let devices = [...page.physicalDevices, ...page.virtualDevices];
            finalItems = [...finalItems, ...devices.map(x => {
                return {
                    deviceId: x._id,
                    pageId: page._id
                }
            })]
        } else {
            finalItems = finalItems.filter(x => x.pageId != page._id);
        }
        updateDeleteDisable(finalItems, []);
        setDeletedDevices([...finalItems]);
    }
    useEffect(() => {
        if (selectedDate != date) {
            setSelectedDate(date);
            let start = (((paginationData.pageNo - 1) * paginationData.pageSize) + (1))
            dataService.getPageWithDetails({
                skip: start - 1,
                limit: paginationData.pageSize,
                searchText: filterData.searchText,
                selectedDate: date,
                byScheduled: filterData.byScheduled,
                selectedDevices: filterData.selectedDevices,
                selectedTags: filterData.selectedTags.map(x => x.value),
                jobStatus: filterData.selectedDevices,
                invokedBy: '341'
            });
        }
    }, [date])
    useEffect(() => {
        let prevData = storeService.store.PAGES_WITH_DETAILS.filterData;
        storeService.store.PAGES_WITH_DETAILS.filterData = { ...filterData, selectedDate: prevData.selectedDate };
        let start = (((paginationData.pageNo - 1) * paginationData.pageSize) + (1))
        dataService.getPageWithDetails({
            skip: start - 1,
            limit: paginationData.pageSize,
            searchText: filterData.searchText,
            selectedDate: date,
            selectedTags: filterData.selectedTags.map(x => x.value),
            byScheduled: filterData.byScheduled,
            selectedDevices: filterData.selectedDevices,
            jobStatus: filterData.jobStatus,
            invokedBy: '356'
        });
    }, [filterData])
    useEffect(() => {
        let start = (((paginationData.pageNo - 1) * paginationData.pageSize) + (1))
        dataService.getPageWithDetails({
            skip: start - 1,
            limit: paginationData.pageSize,
            selectedDate: date,
            searchText: filterData.searchText,
            selectedTags: filterData.selectedTags.map(x => x.value),
            byScheduled: filterData.byScheduled,
            selectedDevices: filterData.selectedDevices,
            jobStatus: filterData.jobStatus,
            invokedBy: '369'
        });
    }, [paginationData])
    const onFilterUpdate = (updatedFilterData) => {
        let prevData = storeService.store.PAGES_WITH_DETAILS.filterData;
        storeService.store.PAGES_WITH_DETAILS.filterData = { ...updatedFilterData, selectedDate: prevData.selectedDate };
        setFilterData({
            searchText: updatedFilterData.searchText,
            selectedTags: updatedFilterData.selectedTags,
            selectedDevices: [...updatedFilterData.selectedDevices],
            byScheduled: updatedFilterData.byScheduled,
            jobStatus: updatedFilterData.jobStatus
        })
    }
    return (
        <>
            <div className="flex justify-between">
                <div className="flex align-center">
                    <div className="flex align-center pointer" id="sightsense-table">
                        <h2 className='mr-2'>Page List</h2>
                        <SvgIcon path="/icons/info-svg.svg"></SvgIcon>
                        <Tooltip
                            anchorSelect="#sightsense-table"
                            content="View a list of tests created and executed across devices. Check results, trigger new runs, and filter by environment or date. Use the Advanced Filter for device, priority, tags, or schedule-based filtering."
                            place="top-start"
                            style={{
                                backgroundColor: "#000",
                                color: "#ffffff",
                                borderRadius: "5px",
                                zIndex: 22,
                                fontWeight: "bold",
                                width: "50%",
                            }}
                        />
                    </div>
                    <div className="flex align-center ml-4">
                        <SearchAndFilter selectedFilterData={filterData} onFilterUpdate={onFilterUpdate} mode="PAGE_LIST" />
                    </div>
                </div>
                <div className="flex align-center justify-end">
                    <a href="#" onClick={handleRun} className={'py-2 px-4 flex align-center no-underline edit-link-option ' + (disableDelete ? 'disabled' : '')}>
                        <SvgCode name="play"></SvgCode>
                        <span>Run</span>
                    </a>
                    <a href="#" onClick={handleDelete} className={'py-2 px-4 flex align-center no-underline edit-link-option ' + (disableDelete ? 'disabled' : '')}>
                        <SvgCode name="delete"></SvgCode>
                        <span>Delete</span>
                    </a>
                </div>
            </div>
            <div className='relative'>
                <table className="app-table my-4 sightsense">
                    <tbody>
                        {
                            paginationList?.length > 0 && paginationList.map((page, pageIndex) => {
                                return (
                                    <Fragment key={'page-row-' + pageIndex + '' + page.randomGuid}>
                                        <tr className="table-row-title">
                                            <td colSpan="9" className="table-row-details">
                                                <div className="flex justify-between">
                                                    <div className="flex-basis-60">
                                                        {/* <div className="row-no para-3 font-1rem">{pageIndex + 1}</div> */}
                                                        <div className='pointer'>
                                                            <a className='mt-1 flex' onClick={e => {
                                                                toggleExpandPage(e, page._id, page.expanded);
                                                                setCurrentPage(currentPage === page._id ? "" : page._id);
                                                            }}>
                                                                <p className="mr-2 table-heading capitalize w-100">{(((paginationData.pageNo - 1) * paginationData.pageSize) + (1)) + pageIndex}. {page.name}</p>
                                                                {page._id === currentPage ? (
                                                                     <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" className="svg-icon"
                                                                     viewBox="0 0 18 12" fill="none">
                                                                     <path
                                                                         d="M0.34627 9.08042L8.16974 1.25695C8.27876 1.14755 8.40831 1.06074 8.55095 1.00151C8.69359 0.942283 8.84653 0.911793 9.00098 0.911793C9.15543 0.911793 9.30836 0.942283 9.45101 1.00151C9.59365 1.06074 9.7232 1.14755 9.83222 1.25695L17.6557 9.08042C17.8761 9.30088 18 9.59989 18 9.91166C18 10.2234 17.8761 10.5224 17.6557 10.7429C17.4352 10.9634 17.1362 11.0872 16.8244 11.0872C16.5127 11.0872 16.2137 10.9634 15.9932 10.7429L9 3.7497L2.0068 10.7439C1.78634 10.9643 1.48734 11.0882 1.17556 11.0882C0.863781 11.0882 0.564774 10.9643 0.344315 10.7439C0.123856 10.5234 7.5514e-08 10.2244 1.0277e-07 9.91264C1.30027e-07 9.60086 0.123856 9.30185 0.344315 9.08139L0.34627 9.08042Z"
                                                                         fill="#1B72E6" />
                                                                 </svg>
                                                                   
                                                                ) : (
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9"
                                                                    viewBox="0 0 18 12" fill="none" className="svg-icon">
                                                                    <path
                                                                        d="M17.6537 2.91958L9.83027 10.743C9.72124 10.8524 9.59169 10.9393 9.44905 10.9985C9.30641 11.0577 9.15347 11.0882 8.99902 11.0882C8.84457 11.0882 8.69164 11.0577 8.54899 10.9985C8.40635 10.9393 8.2768 10.8524 8.16778 10.743L0.344312 2.91958C0.123853 2.69912 -3.2851e-09 2.40011 0 2.08834C3.2851e-09 1.77656 0.123853 1.47755 0.344312 1.25709C0.564772 1.03663 0.863779 0.912782 1.17556 0.912782C1.48733 0.912782 1.78634 1.03663 2.0068 1.25709L9 8.25029L15.9932 1.25612C16.2137 1.03566 16.5127 0.911804 16.8244 0.911804C17.1362 0.911804 17.4352 1.03566 17.6557 1.25612C17.8761 1.47658 18 1.77558 18 2.08736C18 2.39914 17.8761 2.69814 17.6557 2.9186L17.6537 2.91958Z"
                                                                        fill="#1B72E6" />
                                                                </svg>
                                                                )}
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-basis-40 justify-content-end'>
                                                        <ScheduleButton display="ICON" mode="PAGE_LIST" pageId={page._id} />
                                                        <span className='caption-1 ml-4'>Devices - {page.devicesCount}</span>
                                                        <span className='body-4 ml-6'>Pass - {page.passedDevicesCount}</span>
                                                        <span className='body-4 ml-6'>Fail - {page.failedDevicesCount}</span>
                                                        <span className='body-4 ml-6'>Not Run Yet - {page.notRunYetDevicesCount}</span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        {
                                            page._id === currentPage && (
                                                <Fragment key={'tr-head-' + pageIndex}>
                                                    <tr className="table-row-subtitle">
                                                        <td>
                                                            <div className="justify-between">
                                                                <div className="align-center">
                                                                    <span className="mr-1 table-subheading">Devices</span>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                                        viewBox="0 0 18 18" fill="none" className="svg-icon">
                                                                        <path
                                                                            d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389957 7.20038 -0.17433 9.00998 0.172936 10.7558C0.520203 12.5016 1.37737 14.1053 2.63604 15.364C3.89471 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9974 6.61386 17.0483 4.32622 15.361 2.63896C13.6738 0.951708 11.3861 0.00264685 9 0ZM9 16C7.61553 16 6.26216 15.5895 5.11101 14.8203C3.95987 14.0511 3.06266 12.9579 2.53285 11.6788C2.00303 10.3997 1.86441 8.99223 2.13451 7.63437C2.4046 6.2765 3.07129 5.02922 4.05026 4.05025C5.02922 3.07128 6.2765 2.4046 7.63437 2.1345C8.99224 1.86441 10.3997 2.00303 11.6788 2.53284C12.9579 3.06266 14.0511 3.95986 14.8203 5.11101C15.5895 6.26215 16 7.61553 16 9C15.998 10.8559 15.2599 12.6352 13.9476 13.9476C12.6352 15.2599 10.8559 15.998 9 16ZM13.0408 6.95917C13.1341 7.05207 13.208 7.16246 13.2585 7.28401C13.309 7.40556 13.335 7.53588 13.335 7.6675C13.335 7.79911 13.309 7.92943 13.2585 8.05098C13.208 8.17253 13.1341 8.28293 13.0408 8.37583L9.7075 11.7092C9.6146 11.8024 9.50421 11.8764 9.38265 11.9268C9.2611 11.9773 9.13078 12.0033 8.99917 12.0033C8.86755 12.0033 8.73724 11.9773 8.61568 11.9268C8.49413 11.8764 8.38374 11.8024 8.29084 11.7092L4.9575 8.37583C4.76964 8.18797 4.6641 7.93317 4.6641 7.6675C4.6641 7.40182 4.76964 7.14703 4.9575 6.95917C5.14536 6.7713 5.40016 6.66576 5.66584 6.66576C5.93151 6.66576 6.18631 6.7713 6.37417 6.95917L9 9.58333L11.6258 6.95667C11.7189 6.86385 11.8293 6.79029 11.9508 6.74018C12.0723 6.69007 12.2024 6.6644 12.3339 6.66463C12.4653 6.66486 12.5953 6.69099 12.7167 6.74153C12.838 6.79207 12.9481 6.86602 13.0408 6.95917Z"
                                                                            fill="#889AB1" />
                                                                    </svg>
                                                                </div>
                                                                {/* <a onClick={e => openAddDevicesModal(e, page)} href="#" className="btn-medium align-center">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" className="svg-icon"
                                                                    viewBox="0 0 14 14" fill="none">
                                                                    <path
                                                                        d="M1.32791 8.32432H5.67209V12.6757C5.67209 13.4135 6.27913 14 7 14C7.72087 14 8.32791 13.3946 8.32791 12.6757V8.32432H12.6721C13.4119 8.32432 14 7.71892 14 7C14 6.28108 13.393 5.67568 12.6721 5.67568H8.34688V1.32432C8.34688 0.586486 7.73984 0 7.01897 0C6.2981 0 5.69106 0.605405 5.69106 1.32432V5.67568H1.32791C0.588076 5.67568 0 6.28108 0 7C0 7.71892 0.607046 8.32432 1.32791 8.32432Z"
                                                                        fill="#1D4273" />
                                                                </svg>
                                                                <span className="ml-2">Add Devices</span>
                                                            </a> */}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="align-center">
                                                                <span className="mr-1 table-subheading">Last Run</span>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                                                    fill="none" className="svg-icon">
                                                                    <path
                                                                        d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389957 7.20038 -0.17433 9.00998 0.172936 10.7558C0.520203 12.5016 1.37737 14.1053 2.63604 15.364C3.89471 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9974 6.61386 17.0483 4.32622 15.361 2.63896C13.6738 0.951708 11.3861 0.00264685 9 0ZM9 16C7.61553 16 6.26216 15.5895 5.11101 14.8203C3.95987 14.0511 3.06266 12.9579 2.53285 11.6788C2.00303 10.3997 1.86441 8.99223 2.13451 7.63437C2.4046 6.2765 3.07129 5.02922 4.05026 4.05025C5.02922 3.07128 6.2765 2.4046 7.63437 2.1345C8.99224 1.86441 10.3997 2.00303 11.6788 2.53284C12.9579 3.06266 14.0511 3.95986 14.8203 5.11101C15.5895 6.26215 16 7.61553 16 9C15.998 10.8559 15.2599 12.6352 13.9476 13.9476C12.6352 15.2599 10.8559 15.998 9 16ZM13.0408 6.95917C13.1341 7.05207 13.208 7.16246 13.2585 7.28401C13.309 7.40556 13.335 7.53588 13.335 7.6675C13.335 7.79911 13.309 7.92943 13.2585 8.05098C13.208 8.17253 13.1341 8.28293 13.0408 8.37583L9.7075 11.7092C9.6146 11.8024 9.50421 11.8764 9.38265 11.9268C9.2611 11.9773 9.13078 12.0033 8.99917 12.0033C8.86755 12.0033 8.73724 11.9773 8.61568 11.9268C8.49413 11.8764 8.38374 11.8024 8.29084 11.7092L4.9575 8.37583C4.76964 8.18797 4.6641 7.93317 4.6641 7.6675C4.6641 7.40182 4.76964 7.14703 4.9575 6.95917C5.14536 6.7713 5.40016 6.66576 5.66584 6.66576C5.93151 6.66576 6.18631 6.7713 6.37417 6.95917L9 9.58333L11.6258 6.95667C11.7189 6.86385 11.8293 6.79029 11.9508 6.74018C12.0723 6.69007 12.2024 6.6644 12.3339 6.66463C12.4653 6.66486 12.5953 6.69099 12.7167 6.74153C12.838 6.79207 12.9481 6.86602 13.0408 6.95917Z"
                                                                        fill="#889AB1" />
                                                                </svg>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="align-center">
                                                                <span className="mr-1 table-subheading">Status</span>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                                                    fill="none" className="svg-icon">
                                                                    <path
                                                                        d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389957 7.20038 -0.17433 9.00998 0.172936 10.7558C0.520203 12.5016 1.37737 14.1053 2.63604 15.364C3.89471 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9974 6.61386 17.0483 4.32622 15.361 2.63896C13.6738 0.951708 11.3861 0.00264685 9 0ZM9 16C7.61553 16 6.26216 15.5895 5.11101 14.8203C3.95987 14.0511 3.06266 12.9579 2.53285 11.6788C2.00303 10.3997 1.86441 8.99223 2.13451 7.63437C2.4046 6.2765 3.07129 5.02922 4.05026 4.05025C5.02922 3.07128 6.2765 2.4046 7.63437 2.1345C8.99224 1.86441 10.3997 2.00303 11.6788 2.53284C12.9579 3.06266 14.0511 3.95986 14.8203 5.11101C15.5895 6.26215 16 7.61553 16 9C15.998 10.8559 15.2599 12.6352 13.9476 13.9476C12.6352 15.2599 10.8559 15.998 9 16ZM13.0408 6.95917C13.1341 7.05207 13.208 7.16246 13.2585 7.28401C13.309 7.40556 13.335 7.53588 13.335 7.6675C13.335 7.79911 13.309 7.92943 13.2585 8.05098C13.208 8.17253 13.1341 8.28293 13.0408 8.37583L9.7075 11.7092C9.6146 11.8024 9.50421 11.8764 9.38265 11.9268C9.2611 11.9773 9.13078 12.0033 8.99917 12.0033C8.86755 12.0033 8.73724 11.9773 8.61568 11.9268C8.49413 11.8764 8.38374 11.8024 8.29084 11.7092L4.9575 8.37583C4.76964 8.18797 4.6641 7.93317 4.6641 7.6675C4.6641 7.40182 4.76964 7.14703 4.9575 6.95917C5.14536 6.7713 5.40016 6.66576 5.66584 6.66576C5.93151 6.66576 6.18631 6.7713 6.37417 6.95917L9 9.58333L11.6258 6.95667C11.7189 6.86385 11.8293 6.79029 11.9508 6.74018C12.0723 6.69007 12.2024 6.6644 12.3339 6.66463C12.4653 6.66486 12.5953 6.69099 12.7167 6.74153C12.838 6.79207 12.9481 6.86602 13.0408 6.95917Z"
                                                                        fill="#889AB1" />
                                                                </svg>
                                                            </div>
                                                        </td>

                                                        <td className='checkbox-table-cell'>
                                                            <div className='cell-checkbox-wrapper'>
                                                                <label className="form-field-checkbox">
                                                                    <input checked={
                                                                        deleteDevices.filter(dx => dx.pageId == page._id).length == page.virtualDevices.length ? true : false
                                                                    } type="checkbox" onClick={e => chooseAllDevices(page, e.target.checked)} />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        page.devices && page.devices.map((device, deviceIndex) => {
                                                            return (
                                                                <Fragment key={'page-' + pageIndex + '-device-' + deviceIndex}>
                                                                    <tr className="table-content">
                                                                        <td>
                                                                            <a onClick={e => navigateToDeviceDetailPage(e, page, device)} href={`/ui-regression/page/${page._id}/device/${device._id}`}>
                                                                                <div className="justify-between align-center">
                                                                                    <span className="table-text">
                                                                                        {device.name}
                                                                                    </span>
                                                                                    <img src="/icons/arrow-right.svg" class="svg-icon"></img>
                                                                                </div>
                                                                            </a>
                                                                        </td>
                                                                        <td>
                                                                            <div className="justify-between">
                                                                                <span className="table-text">
                                                                                    {device.lastRunDate}
                                                                                </span>
                                                                                <div className="flex align-items">
                                                                                    <ScheduleButton display="ICON" mode="PAGE_LIST_DEVICE" deviceId={device._id} pageId={page._id} />
                                                                                    {
                                                                                        device.finalStatus != 'Running' && (
                                                                                            <a href="#" onClick={e => addNewJob(e, page, device)} className={' svg-icon-holder ml-2 sightsense'}>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                                                                                    viewBox="0 0 12 12" fill="none">
                                                                                                    <path
                                                                                                        d="M10.7818 5.0593L2.42954 0.16242C2.25439 0.0600694 2.05403 0.00409737 1.84893 0.000216522C1.64383 -0.00366432 1.44133 0.044685 1.26209 0.14033C1.08286 0.235974 0.933306 0.375489 0.828704 0.544635C0.724101 0.71378 0.668194 0.906499 0.666687 1.10312V10.8969C0.668194 11.0935 0.724101 11.2862 0.828704 11.4554C0.933306 11.6245 1.08286 11.764 1.26209 11.8597C1.44133 11.9553 1.64383 12.0037 1.84893 11.9998C2.05403 11.9959 2.25439 11.9399 2.42954 11.8376L10.7818 6.9407C10.9502 6.84244 11.0894 6.70429 11.186 6.53954C11.2826 6.37479 11.3334 6.18898 11.3334 6C11.3334 5.81102 11.2826 5.62521 11.186 5.46046C11.0894 5.29571 10.9502 5.15756 10.7818 5.0593Z"
                                                                                                        fill="#1D4273" />
                                                                                                </svg>
                                                                                            </a>
                                                                                        )
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td className={device.finalStatus == 'Failed' ? 'font-danger' : ''}>
                                                                            <div className='flex justify-between'>
                                                                                <span className="font-xs">{device.finalStatus ? device.finalStatus : '-'} {device.finalStatus == 'Failed' && device.failedPriority ? `- ${device.failedPriority}` : ''}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className='cell-checkbox-wrapper'>
                                                                                <label className={'form-field-checkbox ' + (device.disable ? 'disabled' : '')}>
                                                                                    <input checked={deleteDevices.find(dx => dx.deviceId == device._id && dx.pageId == page._id) ? true : false} type="checkbox" className={(device.disable ? 'disabled' : '')} onChange={e => chooseDeleteDevice(device, page)} />
                                                                                    <span className="checkmark"></span>
                                                                                </label>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </Fragment>
                                                            );
                                                        })
                                                    }

                                                </Fragment>
                                            )
                                        }
                                    </Fragment>
                                )
                            })
                        }
                    </tbody>
                </table>

                {
                    totalCount > 0 ? (
                        <TablePagination onChange={handlePaginationChange} pageNo={paginationData.pageNo} pageSize={paginationData.pageSize} totalSize={totalCount}></TablePagination>
                    ) : (
                        <>
                            No items found.
                        </>
                    )
                }
                {
                    loadingPages && (
                        <SectionLoader></SectionLoader>
                    )
                }
            </div>

            {
                showAddDevices && (
                    <AppModal>
                        <AddDevices onClose={closeAddDeviceModal} page={selectedPage}></AddDevices>
                    </AppModal>
                )
            }
            {
                showDeleteModal && (
                    <ConfirmModal
                        confirmationTitle={'Are you sure you want to delete the test case?'}
                        confirmButtonLabel={'Delete'}
                        onOk={() => onConfirmation()}
                        onCancel={e => setShowDeleteModal(false)}
                    />
                )
            }
        </>
    );
}