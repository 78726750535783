import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import Modal from "../../atoms/Modal/Modal.jsx";
import Recommendations from "../../atoms/Recommendations/Recommendations.jsx";
import PageActions from "../../atoms/PageActions/PageActions.jsx";
import Collections from "../../atoms/Collections/Collections.jsx";
import DeviceUsage from "../../atoms/DeviceUsage/DeviceUsage.jsx";
import PageJourney from "../../atoms/PageJourney/PageJourney.jsx";
import PageHits from "../../atoms/PageHits/PageHits.jsx";
import utilsService from "../../../services/utils.service.js";
import apiService from "../../../services/api.service.js";
import storeService from "../../../services/store.service.js";
import PageFilter from "../PageFilter.jsx";
import AppModal from "../AppModal.jsx";
import LeftNav from "../../atoms/LeftNav.jsx";
import { getCurrentDate, formatDate } from "../../../helpers/helpers.js";
import dataService from "../../../services/data.service.js";
import { ChevronIcon } from "../../atoms/ChevronIcon/ChevronIcon.jsx";
import TablePagination from '../../molecules/TablePagination.jsx';
import ConfirmModal from '../../molecules/ConfirmModal';
import Logout from "../../atoms/Logout.jsx";
import TreeDropdown from "../../atoms/PageActions/TreeDropdown.jsx";
import SectionLoader from '../../atoms/SectionLoader.jsx';
import flowService from "../../../services/flow.service.js";
import tenantService from "../../../services/tenants.service.js"
const defaultRecentRange = [
  { name: '15 min', value: 15 },
  { name: '30 min', value: 30 },
  { name: '1 hour', value: 60 },
  { name: '2 hours', value: 60 * 2 }
]
const defaultCategory = {
  type: '',
  value: ''
};
let modalLabels = {
  recommendations: {
    title: "Recommendation to create Automated Regressions",
    buttonText: "Next",
  },
  actions: {
    title: "Top actions on pages",
    buttonText: "Create",
  },
  collection: {
    title: "Create Automated Regression",
    buttonText: "Create",
  },
  devices: {
    title: "Device Usage",
    buttonText: "Create",
  },
  journey: {
    title: "User Journeys",
    buttonText: "Create",
  },
  hits: {
    title: "Page Usage",
    buttonText: "Create",
  },
  actioned: {
    title: "User actioned journeys",
    buttonText: "Create",
  },
  treeview: {
    title: "Top Page Journeys",
    buttonText: "Create",
  },
};
const defaultTestRecFormData = {
  startDate: {
    value: '',
    showError: false,
    isRequired: true,
    errorMessage: 'Start date is required'
  },
  endDate: {
    value: '',
    showError: false,
    isRequired: true,
    errorMessage: 'End date is required'
  },
  site: {
    value: '',
    showError: false,
    isRequired: true,
    errorMessage: 'Site information is required'
  },
  title: {
    value: '',
    showError: false,
    isRequired: true,
    errorMessage: 'Title is required'
  },
  recentTime: {
    value: 15,
    showError: false,
    isRequired: true,
    errorMessage: 'Must choose minutes'
  }
};
const checkAndReturn = (valToCheck, prefix) => {
  if (valToCheck) {
    return prefix + valToCheck;
  }
  return "";
};
const getStepName = (step) => {
  if (step.actionType === "OPEN_URL" || step.page_title) {
    return step.page_title + (step.no_of_users ? `(${step.no_of_users})` : '')
  }
  if (step.actionType === "INPUT") {
    return (
      "Input " +
      step.elementValue +
      checkAndReturn(
        step.elementText || step.elementId || step.elementName,
        " in "
      )
    );
  }
  if (
    ["CHECKBOX", "RADIO", "SELECT", "MULTI_SELECT"].indexOf(step.actionType) >=
    0
  ) {
    return "Select " + step.elementValue + " as " + step.elementName;
  }
  if (["ANCHOR_CLICK", "BUTTON_CLICK"].indexOf(step.actionType) >= 0) {
    return (
      "Click " +
      (step.elementLinkText ||
        step.elementText ||
        step.elementId ||
        step.elementName ||
        step.actionType)
    );
  }
  return step.pageName || step.actionType;
};
const MainContent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isTestRecommendationModalOpen, setTestRecommendationModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("recommendations");
  const [category, setCategory] = useState(defaultCategory);
  const [selectedSteps, setSelectedSteps] = useState([]);
  const [collectionSaved, saveCollection] = useState(false);
  const [modalTitle, setModalTitle] = useState(false);
  const [usageRecommendationModalData, setUsageRecommendationModalData] = useState(null);
  const [filter, setFilter] = useState(false);
  // const [regressionToAdd, addRegression] = useState('');
  const [moduleSummary, setModuleSummary] = useState({});
  const [treeViewSelected, setTreeViewSelected] = useState(false);
  const [date, setDate] = useState(getCurrentDate());
  const [userFlowRegressionTags, setUserFlowRegressionTags] = useState([]);
  const [overviewData, setOverviewData] = useState({
    datapoints: 0,
    user_sessions: 0,
    months_of_usage: 0,
    number_of_recommendations: 0,
    number_of_tests: 0
  });
  const [showUsageDropdown, setShowUsageDropdown] = useState({});
  const [recommendationFormData, setRecommendationFormData] = useState(defaultTestRecFormData);
  const [loadedDataMap, setLoadedDataMap] = useState({})
  const [recommendationFormError, setRecommendationFormError] = useState('');
  const [listRecommendations, setListRecommendations] = useState([]);
  const [tags, setTags] = useState([]);
  const [idToDelete, setIdToDelete] = useState(false);
  const [selectedRecommendationsList, setSelectedRecommendationsList] = useState([]);
  const [rangeType, setRangeType] = useState('CUSTOM')
  const [environemntConfigs, setenvironemntConfigs] = useState({
    toggleTreeView: {
      action: true,
      journey: false
    }
  })
  const [filterData, setFilterData] = useState({
    searchText: ''
  })
  const [paginationData, setPaginationData] = useState({
    pageNo: 1,
    pageSize: 5
  })
  const [siteData, setSiteData] = useState([]);
  const [filteredList, setFilteredList] = useState([])
  const [paginationList, setPaginationList] = useState([])
  const [saveRecommendationLoading, setSaveRecommendationLoading] = useState(false);
  const [confirmModalObj, setConfirmModalObj] = useState({
    isVisible: false,
    message: '',
    id: ''
  });
  const [runNow, setrunNow] = useState(false);
  const handlePaginationChange = (data) => {
    if (data.perPage) {
      setPaginationData({
        ...paginationData,
        pageSize: data.perPage,
        pageNo: 1
      })
    }
    if (data.pageNo) {
      setPaginationData({
        ...paginationData,
        pageNo: data.pageNo
      })
    }
  }
  const getEnvironmentsConfig = async () => {
    const environmentsConfig = await apiService.get({
      url: utilsService.END_POINTS.getEnvironmentsConfigs,
    });
    const { data = [] } = environmentsConfig;
    setenvironemntConfigs({ ...environemntConfigs, ...data[0] });
  };
  const handleSearchText = (value) => {
    let updatedFilterData = {
      ...filterData,
      searchText: value
    };
    setFilterData(updatedFilterData);
    applyFilterDataOnList(updatedFilterData)
    setPaginationData({
      ...paginationData,
      pageNo: 1
    })
  }
  const applyFilterDataOnList = (data) => {
    let newList = [];
    listRecommendations.forEach(item => {
      let flag = false;
      if (data?.searchText?.length > 2) {
        if ((item.label || '').toLowerCase().includes(data.searchText.toLowerCase())) {
          flag = true;
        }
      } else {
        flag = true;
      }
      if (flag) {
        newList.push(item);
      }
    });
    setFilteredList(newList);
  }
  useEffect(() => {
    let newList = [];
    let filterLen = filteredList.length;
    let start = (((paginationData.pageNo - 1) * paginationData.pageSize) + (1))
    let lastItem = ((paginationData.pageNo - 1) * paginationData.pageSize) + (paginationData.pageSize);
    let end = (lastItem < filterLen ? lastItem : filterLen)
    for (let i = start - 1; i < end; i++) {
      newList.push(filteredList[i])
    }
    setPaginationList(newList)
  }, [paginationData.pageSize, paginationData.pageNo, filteredList]);


  const getUsagePulseOverview = async () => {
    const resp = await dataService.getUsagePulseOverview({});
    if (resp?.data) {
      setOverviewData(resp.data);
    }
    setLoading(false);
  }
  const getPagesSummary = async () => {
    const modulesSummary = await apiService.get({
      url: utilsService.END_POINTS.getModulesSummary
    });
    setModuleSummary(modulesSummary.data[0]?.summary);
  }


  const getUsageTestCasesData = async () => {
    const resp = await apiService.get({
      url: utilsService.END_POINTS.getUsageData
    });
    const { data = [] } = resp;
    for (let i = 0; i < data.length; i++) {
      if (data[i].dataVersion == null) {
        data[i]?.['top_journey']?.data?.forEach(journey => {
          journey.journey_flow = journey.pages?.map((page, i) => (
            <React.Fragment>
              {page.page_title} ({page.no_of_users})
              {(i + 1) !== journey.pages.length ? <span className="mx-2">
                <ChevronIcon type="right" variant="small" />
              </span> : null}
            </React.Fragment>
          ));
        });
        if (data[i]?.['top_actions']?.data?.length) {
          data[i]['top_actions'].data = data[i]?.['top_actions']?.data.map(topActionData => {
            const actions = removeNan(topActionData.actions);
            if (actions?.length === 0) {
              return null;
            }
            return {
              ...topActionData,
              actions
            }
          }).filter(it => it !== null);
        }
      }
    }
    setShowUsageDropdown({
      ...(data[0]?._id ? {
        [data[0]._id]: true
      } : {})
    });
    setListRecommendations(data);
    setFilteredList(data);
  }

  const removeNan = (json) => {
    if (Array.isArray(json)) {
      return json.map(obj => removeNan(obj)).filter(obj => obj !== null);
    } else if (typeof json === 'object' && json !== null) {
      if (json.option_value === 'nan' || json.option_value === null) {
        return null;
      }
      if (json.next_actions && json.next_actions.some(action => action.option_value === 'nan' || action.option_value === null)) {
        return null;
      }
      const newObj = { ...json };
      for (const key in newObj) {
        newObj[key] = removeNan(newObj[key]);
      }
      return newObj;
    }
    return json;
  }

  const onSubmitFilter = () => { };
  const closeFilterModal = () => { setFilter(false) };

  useEffect(() => {
    document.addEventListener("scroll", () => {
      let headerElem = document.querySelector(".main-header-nav");
      headerElem.classList.remove("sticky");
      if (window.scrollY > 5) {
        headerElem.classList.add("sticky");
      }
    });
    getUsagePulseOverview();
    getUsageTestCasesData();
    getPagesSummary();
    getEnvironmentsConfig();
    let listStore = storeService.getStore('WORKFLOWS_WITH_DETAILS');
    storeService.subscribe('WORKFLOWS_WITH_DETAILS', ({ detail }) => {
      parseListData(detail.items);
    });
    if (!listStore.loaded) {
      dataService.getWorkflowsWithDetails();
    } else {
      parseListData(listStore.items);
    }

    getSiteData();

  }, []);

  const getSiteData = async () => {
    const tenantsList = await tenantService.loadTenants();
    const { data = {} } = tenantsList || {};
    const { items = [] } = data;
    setSiteData(items.map(x => ({ _id: x._id, name: x.name, siteId: x.siteId, tenantId: x.tenantId })))
  }

  const parseListData = (list) => {
    setTags(list);
  }
  // const getRegressionStatus = (item) => {
  //   if(item.devices && item.devices.length > 0) {
  //     let passed = 0, failed = 0, notRun = 0, inProgress = 0;
  //     item.devices.forEach(device => {
  //       if(device.jobs.length > 0) {
  //         let recentJob = device.jobs[0];
  //         if(recentJob.status == 'IN_PROGRESS' || recentJob.analysisStatus == 'IN_PROGRESS') {
  //           inProgress += 1
  //         } else if(recentJob.status == 'DONE' || recentJob.analysisStatus == 'DONE') {
  //           passed += 1;
  //         } else {
  //           failed += 1
  //         }
  //       } else {
  //         notRun++;
  //       }
  //     });
  //     if(inProgress > 0 || notRun > 0) {
  //       return 'Not Run';
  //     }
  //     if(failed > 0) {
  //       return 'Failed';
  //     }
  //     return 'Passed';
  //   }
  //   return '-';
  // }
  // const getRegressionLastRun = (item) => {
  //   let itemStatus = getRegressionStatus(item);
  //   if(itemStatus == 'Passed') {
  //     let lastRun = new Date();
  //     lastRun.setDate(lastRun.getDate()-100);
  //     item.devices.forEach(device => {
  //       let recentJob = device.jobs[0];
  //       lastRun = lastRun.getTime() < new Date(recentJob.createdAt).getTime() ? new Date(recentJob.createdAt) : lastRun;
  //     });
  //     return utilsService.getDisplayDateTimeObject(lastRun);
  //   }
  //   return '-';
  // }
  const handleItemExpand = (e, id) => {
    e.preventDefault()
    setShowUsageDropdown({
      ...showUsageDropdown,
      [id]: !showUsageDropdown[id]
    });
  }

  // const toggleAccordion = (pageId) => {
  //   setCurrentPage(currentPage == pageId ? "" : pageId);
  // };

  const nextModalContent = async () => {
    if (category?.value?.length > 0) {
      if (modalContent === "collection" && selectedSteps?.length > 0) {
        saveCollection(true);
        await dataService.executeUserflowRegression({
          "items": selectedSteps?.map(it => {
            return {
              "name": it.regressionName,
              "tags": userFlowRegressionTags?.join(','),
              "steps": it.steps,
              "recommendationId": usageRecommendationModalData?._id,
              "configs": {
                "screenOnEveryStep": true
              }
            }
          }),
          runNow: runNow
        });
        await dataService.getWorkflowsWithDetails()
        await getUsagePulseOverview();
      } else if (selectedSteps.length > 0) {
        setModalContent("collection");
      } else {
        setModalContent(category.value.split("_")[0]);
      }
    }
  };

  const backModalContent = () => {
    if (modalContent === "hits" || 
      modalContent === "journey" || 
      modalContent === "actions" || 
      modalContent === "devices" || 
      modalContent === "treeview" || 
      modalContent === "actioned") {
      setModalContent("recommendations");
      setSelectedSteps([]);
    } else if (modalContent === "collection") {
      setModalContent(category.value.split("_")[0]);
      // setSelectedSteps([]);
      saveCollection(false)
      setUserFlowRegressionTags([]);
    }
    setTreeViewSelected(false);
  }

  const closeModal = () => {
    setModalOpen(false);
    setSelectedSteps([]);
    setModalContent("");
    setCategory(defaultCategory);
    saveCollection(false);
    setModalTitle("");
    setTreeViewSelected(false)
  };

  const categorySelected = (category, title) => {
    setModalTitle(title);
    setCategory(category);
  };

  const dateChange = event => {
    setDate(event.target.value);
  }

  const regressionSelected = (steps) => {
    console.log(steps)
    setSelectedSteps(steps);
  };

  const handleToggleView = () => {
    setTreeViewSelected(!treeViewSelected);
  };

  const closeTestRecommendationModal = () => {
    setRecommendationFormData({
      ...defaultTestRecFormData
    });
    setTestRecommendationModalOpen(false);
  };

  const saveTestRecommendation = async () => {
    const selectedSiteData = siteData.filter((x) => x._id === recommendationFormData.site.value);
    if (rangeType == 'CUSTOM') {
      if (selectedSiteData.length > 0 && recommendationFormData.startDate.value && recommendationFormData.endDate.value && recommendationFormData.title.value) {
        setRecommendationFormError('');
        setSaveRecommendationLoading(true);
        const resp = await dataService.saveUsageTestCase({
          "startDate": recommendationFormData.startDate.value,
          "endDate": recommendationFormData.endDate.value,
          "label": recommendationFormData.title.value,
          "siteId": selectedSiteData[0]?.siteId,
          "tenantId": selectedSiteData[0]?.tenantId
        });
        if (resp.data) {
          const { pythonResponse, dbResp } = resp.data;
          if (dbResp) {
            await getUsageTestCasesData();
            await getUsagePulseOverview();
            closeTestRecommendationModal();
          } else {
            setRecommendationFormError(pythonResponse?.warning || 'API Failed to Save the Recommendation');
          }
        } else {
          setRecommendationFormError(resp?.data?.warning || 'API Failed to Save the Recommendation');
        }
        setSaveRecommendationLoading(false);
      } else {
        setRecommendationFormData({
          ...recommendationFormData,
          startDate: {
            ...recommendationFormData.startDate,
            showError: !recommendationFormData.startDate.value ? true : false
          },
          endDate: {
            ...recommendationFormData.endDate,
            showError: !recommendationFormData.endDate.value ? true : false
          },
          title: {
            ...recommendationFormData.title,
            showError: !recommendationFormData.title.value ? true : false
          },
          site: {
            ...recommendationFormData.site,
            showError: !recommendationFormData.site.value ? true : false
          }
        });
        setSaveRecommendationLoading(false);
      }
    } else {
      if (selectedSiteData.length > 0 && recommendationFormData.recentTime.value && recommendationFormData.title.value) {
        setRecommendationFormError('');
        setSaveRecommendationLoading(true);
        const resp = await dataService.saveUsageTestCase({
          "recentTime": recommendationFormData.recentTime.value - 0,
          "label": recommendationFormData.title.value,
          "siteId": selectedSiteData[0]?.siteId,
          "tenantId": selectedSiteData[0]?.tenantId
        });

        if (resp.data) {
          const { pythonResponse, dbResp } = resp.data;
          if (dbResp) {
            await getUsageTestCasesData();
            await getUsagePulseOverview();
            closeTestRecommendationModal();
          } else {
            setRecommendationFormError(pythonResponse?.warning || 'API Failed to Save the Recommendation');
          }
        } else {
          setRecommendationFormError(resp?.data?.warning || 'API Failed to Save the Recommendation');
        }
        setSaveRecommendationLoading(false);
      } else {
        setRecommendationFormData({
          ...recommendationFormData,
          recentTime: {
            ...recommendationFormData.recentTime,
            showError: !recommendationFormData.recentTime.value ? true : false
          },
          title: {
            ...recommendationFormData.title,
            showError: !recommendationFormData.title.value ? true : false
          },
          site: {
            ...recommendationFormData.site,
            showError: !recommendationFormData.site.value ? true : false
          }
        });
        setSaveRecommendationLoading(false);
      }
    }

  };

  const onTestRecFormChange = e => {
    setRecommendationFormData({
      ...recommendationFormData,
      [e.target.name]: {
        ...recommendationFormData[e.target.name],
        value: e.target.value,
        showError: recommendationFormData[e.target.name]?.isRequired ? (e.target.value ? false : true) : false
      }
    })
  };

  const onViewRecommendations = (selectedRecommendationData) => {
    if (!selectedRecommendationData) return null;
    setSelectedSteps([]);
    setModalContent("recommendations");
    setCategory(defaultCategory);
    saveCollection(false);
    setModalTitle("");
    setModalOpen(true);
    setUsageRecommendationModalData(selectedRecommendationData);
  };

  const onDeleteTestCases = async () => {
    const resp = await dataService.deleteTestCases({
      items: selectedRecommendationsList
    });
    if (resp?.data?.success) {
      setSelectedRecommendationsList([]);
      dataService.getWorkflowsWithDetails();
      setConfirmModalObj({
        isVisible: false,
        message: '',
        id: ''
      });
      await getUsagePulseOverview();
    }
  };

  const onDeleteRecommendation = async (id) => {
    if (!id) return null;
    const resp = await dataService.deleteUsageData({
      items: [{ _id: id }]
    });
    if (resp?.data?.success) {
      setPaginationList([...paginationList?.filter(it => it._id !== id)]);
      dataService.getWorkflowsWithDetails();
      setConfirmModalObj({
        isVisible: false,
        message: '',
        id: ''
      })
      await getUsagePulseOverview();
    }
  };

  const compareIds = (array1, array2) => {
    // Check if lengths are equal and all id properties match
    return array1.length === array2.length && array1.every((obj, index) => obj._id === array2[index]._id);
  }

  const getSiteName = (siteId, tenantId) => {
    const filteredData = siteData.filter((eachdata) => (eachdata.siteId === siteId && eachdata.tenantId === tenantId));
    return filteredData && filteredData[0]?.name
  }
  let showTreeToggleClass = '';
  if (modalContent == 'actions' && environemntConfigs.toggleTreeView.action) {
    showTreeToggleClass = 'show-tree-toggle'
  }
  if (modalContent == 'journey' && environemntConfigs.toggleTreeView.journey) {
    showTreeToggleClass = 'show-tree-toggle'
  }
  if (modalContent == 'actioned' && environemntConfigs.toggleTreeView.journey) {
    showTreeToggleClass = 'show-tree-toggle'
  }
  return (
    <div className="flex main-layout">
      <div className="main-layout__left-ribbon-nav">
        <LeftNav />
      </div>

      <div className="main-layout__content">
        <div className="main-header-nav fluid-container py-4">
          <div className="flex align-center">
            <ul className="flex no-disc">
              <li className="ml-2">
                <div className="align-center ">
                  <h1 className="heading-text ml-2">UsagePulse</h1>
                </div>
              </li>
            </ul>
            <button
              onClick={() => setTestRecommendationModalOpen(true)}
              className="link-nav px-4 py-2 align-center ml-3"
            >
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Subtract">
                  <mask id="path-1-inside-1_122_810" fill="white">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14ZM14 21C17.866 21 21 17.866 21 14C21 10.134 17.866 7 14 7C10.134 7 7 10.134 7 14C7 17.866 10.134 21 14 21ZM14 24C19.5228 24 24 19.5228 24 14C24 8.47715 19.5228 4 14 4C8.47715 4 4 8.47715 4 14C4 19.5228 8.47715 24 14 24ZM14 17C15.6569 17 17 15.6569 17 14C17 12.3431 15.6569 11 14 11C12.3431 11 11 12.3431 11 14C11 15.6569 12.3431 17 14 17Z" />
                  </mask>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14ZM14 21C17.866 21 21 17.866 21 14C21 10.134 17.866 7 14 7C10.134 7 7 10.134 7 14C7 17.866 10.134 21 14 21ZM14 24C19.5228 24 24 19.5228 24 14C24 8.47715 19.5228 4 14 4C8.47715 4 4 8.47715 4 14C4 19.5228 8.47715 24 14 24ZM14 17C15.6569 17 17 15.6569 17 14C17 12.3431 15.6569 11 14 11C12.3431 11 11 12.3431 11 14C11 15.6569 12.3431 17 14 17Z" fill="#1D4273" />
                  <path d="M14 29C22.2843 29 29 22.2843 29 14H27C27 21.1797 21.1797 27 14 27V29ZM-1 14C-1 22.2843 5.71573 29 14 29V27C6.8203 27 1 21.1797 1 14H-1ZM14 -1C5.71573 -1 -1 5.71573 -1 14H1C1 6.8203 6.8203 1 14 1V-1ZM29 14C29 5.71573 22.2843 -1 14 -1V1C21.1797 1 27 6.8203 27 14H29ZM20 14C20 17.3137 17.3137 20 14 20V22C18.4183 22 22 18.4183 22 14H20ZM14 8C17.3137 8 20 10.6863 20 14H22C22 9.58172 18.4183 6 14 6V8ZM8 14C8 10.6863 10.6863 8 14 8V6C9.58172 6 6 9.58172 6 14H8ZM14 20C10.6863 20 8 17.3137 8 14H6C6 18.4183 9.58172 22 14 22V20ZM23 14C23 18.9706 18.9706 23 14 23V25C20.0751 25 25 20.0751 25 14H23ZM14 5C18.9706 5 23 9.02944 23 14H25C25 7.92487 20.0751 3 14 3V5ZM5 14C5 9.02944 9.02944 5 14 5V3C7.92487 3 3 7.92487 3 14H5ZM14 23C9.02944 23 5 18.9706 5 14H3C3 20.0751 7.92487 25 14 25V23ZM16 14C16 15.1046 15.1046 16 14 16V18C16.2091 18 18 16.2091 18 14H16ZM14 12C15.1046 12 16 12.8954 16 14H18C18 11.7909 16.2091 10 14 10V12ZM12 14C12 12.8954 12.8954 12 14 12V10C11.7909 10 10 11.7909 10 14H12ZM14 16C12.8954 16 12 15.1046 12 14H10C10 16.2091 11.7909 18 14 18V16Z" fill="#1D4273" mask="url(#path-1-inside-1_122_810)" />
                </g>
              </svg>

              <span className="ml-3 font-btn-large btn-font-color">Create Test Recommendations</span>
            </button>
          </div>
          {/* <div className="main-header-nav__links">
            <ul>
              <li>
                <div>
                  <div className="form-field form-field--select align-center">
                    <div className="form-field__label align-center">
                      Environment
                    </div>
                    <select>
                      <option>Development</option>
                      <option>QA</option>
                      <option>Production</option>
                    </select>
                    <svg class="header-svg-icon" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path id="Vector" d="M13.7307 1.60408L7.64576 7.689C7.56097 7.77409 7.46021 7.84161 7.34926 7.88767C7.23832 7.93374 7.11937 7.95746 6.99924 7.95746C6.87911 7.95746 6.76016 7.93374 6.64922 7.88767C6.53827 7.84161 6.43751 7.77409 6.35272 7.689L0.267799 1.60408C0.0963301 1.43262 -2.55508e-09 1.20005 0 0.957562C2.55508e-09 0.715069 0.0963301 0.482508 0.267799 0.311039C0.439267 0.139571 0.671828 0.0432412 0.914321 0.0432412C1.15681 0.0432412 1.38938 0.139571 1.56084 0.311039L7 5.75019L12.4392 0.310279C12.6106 0.138811 12.8432 0.0424805 13.0857 0.0424805C13.3282 0.0424805 13.5607 0.138811 13.7322 0.310279C13.9037 0.481748 14 0.714309 14 0.956802C14 1.19929 13.9037 1.43186 13.7322 1.60332L13.7307 1.60408Z" fill="#1B72E6" />
                    </svg>
                    <svg class="header-svg-icon" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path id="Vector" d="M13.7307 1.60408L7.64576 7.689C7.56097 7.77409 7.46021 7.84161 7.34926 7.88767C7.23832 7.93374 7.11937 7.95746 6.99924 7.95746C6.87911 7.95746 6.76016 7.93374 6.64922 7.88767C6.53827 7.84161 6.43751 7.77409 6.35272 7.689L0.267799 1.60408C0.0963301 1.43262 -2.55508e-09 1.20005 0 0.957562C2.55508e-09 0.715069 0.0963301 0.482508 0.267799 0.311039C0.439267 0.139571 0.671828 0.0432412 0.914321 0.0432412C1.15681 0.0432412 1.38938 0.139571 1.56084 0.311039L7 5.75019L12.4392 0.310279C12.6106 0.138811 12.8432 0.0424805 13.0857 0.0424805C13.3282 0.0424805 13.5607 0.138811 13.7322 0.310279C13.9037 0.481748 14 0.714309 14 0.956802C14 1.19929 13.9037 1.43186 13.7322 1.60332L13.7307 1.60408Z" fill="#1B72E6" />
                    </svg>
                  </div>
                </div>
              </li>
              <li>
                <a href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M27.8159 24.6347C26.0385 21.5152 23.2632 19.0846 19.9366 17.7342C21.5909 16.4935 22.8129 14.7637 23.4294 12.7899C24.046 10.8162 24.0259 8.69845 23.372 6.73673C22.7181 4.77502 21.4635 3.06878 19.786 1.8597C18.1085 0.65062 16.0931 0 14.0253 0C11.9574 0 9.94203 0.65062 8.26452 1.8597C6.58701 3.06878 5.33245 4.77502 4.67854 6.73673C4.02464 8.69845 4.00454 10.8162 4.6211 12.7899C5.23766 14.7637 6.45962 16.4935 8.11388 17.7342C4.78733 19.0846 2.01199 21.5152 0.234627 24.6347C0.124495 24.8117 0.0510929 25.0092 0.0187838 25.2152C-0.0135253 25.4212 -0.00408184 25.6316 0.0465531 25.8339C0.097188 26.0362 0.187981 26.2263 0.313533 26.3928C0.439086 26.5593 0.596835 26.6989 0.777404 26.8032C0.957972 26.9075 1.15767 26.9745 1.36463 27.0001C1.5716 27.0257 1.78159 27.0094 1.98214 26.9522C2.18269 26.895 2.36969 26.7981 2.53204 26.6672C2.69438 26.5363 2.82875 26.3741 2.92716 26.1903C5.27616 22.1301 9.4245 19.7085 14.0253 19.7085C18.626 19.7085 22.7744 22.1314 25.1234 26.1903C25.3366 26.5332 25.6749 26.7798 26.0667 26.8778C26.4584 26.9759 26.8729 26.9177 27.2226 26.7157C27.5723 26.5137 27.8297 26.1836 27.9404 25.7953C28.0511 25.4069 28.0065 24.9907 27.8159 24.6347ZM7.28421 9.8562C7.28421 8.52294 7.67957 7.21963 8.42028 6.11107C9.161 5.00251 10.2138 4.13849 11.4456 3.62828C12.6773 3.11806 14.0327 2.98457 15.3404 3.24467C16.648 3.50478 17.8492 4.1468 18.7919 5.08955C19.7347 6.03231 20.3767 7.23345 20.6368 8.54108C20.8969 9.84872 20.7634 11.2041 20.2532 12.4359C19.743 13.6677 18.879 14.7205 17.7704 15.4612C16.6618 16.2019 15.3585 16.5972 14.0253 16.5972C12.2381 16.5952 10.5246 15.8843 9.26089 14.6206C7.99715 13.3568 7.28627 11.6434 7.28421 9.8562Z"
                      fill="#1B72E6"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <Logout iconColor="#1B72E6" />
              </li>
            </ul>
          </div> */}
        </div>

        <div className="fluid-container py-4">
          <div className="section-box p-6 rounded-l">
            <div className="flex justify-between align-center">
              <div className="flex align-center">
                <h2 className="mr-3">Overview</h2>
                <img src="/icons/info-svg.svg" className="svg-icon-l" />
              </div>
            </div>
            <div className="flex box-tiles mt-4">
              <div className="box-tile w-full">
                <div className="box-tile__item box-tile__left p-6">
                  {/* <h4 className="h4-heading subheading-color">Total Pages</h4> */}
                  <div className="flex card-section usage-pulse-card mt-4">
                    <div className="card-item">
                      <div className="card-item__no align-center">
                        <span className="mr-1">{overviewData.datapoints}</span>
                      </div>
                      <div className="card-item__text align-center mt-2">
                        <span className="mr-1">Historical Data Points Tracked</span>
                        {/* <img src="/icons/info-svg.svg" className="svg-icon " /> */}
                      </div>
                    </div>
                    <div className="card-item">
                      <div className="card-item__no align-center">
                        <span className="mr-1">{overviewData.user_sessions}</span>
                      </div>
                      <div className="card-item__text mt-2">User Sessions</div>
                    </div>
                    <div className="card-item">
                      <div className="card-item__no align-center">
                        <span className="mr-1">{overviewData.months_of_usage}</span>
                      </div>
                      <div className="card-item__text mt-2">Months of Usage Data</div>
                    </div>
                    <div className="card-item">
                      <div className="card-item__no align-center">
                        <span className="mr-1">{overviewData.number_of_recommendations}</span>
                      </div>
                      <div className="card-item__text mt-2">No. of Recommendations Created</div>
                    </div>
                    <div className="card-item">
                      <div className="card-item__no align-center">
                        <span className="mr-1">{overviewData.number_of_tests}</span>
                      </div>
                      <div className="card-item__text mt-2">No. of Tests Actually Created</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {listRecommendations?.length ? (
            <div className="table-list-section px-6 py-7 rounded-l mt-6">
              <div className="flex justify-between">
                <div className="flex align-center">
                  <div className="flex align-center">
                    <h2 className="mr-2">Usage Driven Test Cases</h2>
                    <img src="/icons/info-svg.svg" className="svg-icon " />
                  </div>
                  <div className="flex align-center ml-4">
                    <div className="form-field with-pre-icon mr-3">
                      <img
                        src="/icons/search-icon.svg"
                        className="svg-icon pre-svg-icon"
                      />
                      <input
                        type="text"
                        value={filterData.searchText}
                        onChange={e => handleSearchText(e.target.value)}
                        placeholder="Search"
                        className="input-txt width-20vw"
                      />
                    </div>
                    <a
                      href="#"
                      className="link-icon"
                      onClick={() => setFilter(true)}
                    >
                      <img
                        src="/icons/filter-icon.svg"
                        className="svg-icon svg-size-l"
                      />
                    </a>
                  </div>
                </div>
                <div className="flex align-center justify-end">
                  {/* <a
                    href="#"
                    className="py-2 px-4 flex align-center no-underline edit-link-option disabled"
                    className="py-2 px-4 flex align-center no-underline edit-link-option disabled"
                  >
                    <svg classname="svg-icon-l"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM9.98682 6.14618L17.5039 10.5534C17.6554 10.6418 17.7807 10.7661 17.8677 10.9144C17.9546 11.0627 18.0002 11.2299 18.0002 11.4C18.0002 11.5701 17.9546 11.7373 17.8677 11.8856C17.7807 12.0339 17.6554 12.1582 17.5039 12.2466L9.98682 16.6538C9.82918 16.7459 9.64886 16.7963 9.46427 16.7998C9.27968 16.8033 9.09743 16.7598 8.93612 16.6737C8.7748 16.5876 8.64021 16.4621 8.54607 16.3098C8.45192 16.1576 8.40161 15.9842 8.40025 15.8072V6.99281C8.40161 6.81585 8.45192 6.6424 8.54607 6.49017C8.64021 6.33794 8.7748 6.21238 8.93612 6.1263C9.09743 6.04022 9.27968 5.9967 9.46427 6.0002C9.64886 6.00369 9.82918 6.05406 9.98682 6.14618Z"
                      ></path>
                    </svg>
                    <span>Run</span>
                  </a>
                  <a
                    href="#"
                    className="py-2 px-4 flex align-center no-underline edit-link-option disabled"
                    className="py-2 px-4 flex align-center no-underline edit-link-option disabled"
                  >
                    <svg
                      className="svg-icon-l"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="22"
                      viewBox="0 0 20 22"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.01276 0.56735L1.02183 2.36931C0.0335144 3.26387 0.0779219 4.92792 0.972486 5.91623L1.42248 6.4136C1.5224 6.52387 1.69213 6.53226 1.80192 6.43284L6.97835 1.74686C7.08888 1.64744 7.09727 1.4777 6.9976 1.36743L6.5476 0.870061C5.65304 -0.118252 4.00132 -0.326474 3.01276 0.56735ZM16.9875 0.56735L18.9784 2.36931C19.9667 3.26387 19.9223 4.92792 19.0278 5.91623L18.5775 6.4136C18.4778 6.52387 18.3079 6.53226 18.1983 6.43284L13.0216 1.74686C12.9114 1.64744 12.9027 1.4777 13.0026 1.36743L13.4526 0.870061C14.3472 -0.118252 15.9987 -0.326474 16.9875 0.56735ZM18.9126 12.1169C18.9126 14.5019 17.9692 16.6694 16.4386 18.2701L17.8153 20.2951C18.1543 20.7937 18.025 21.4721 17.5269 21.8111C17.3386 21.9391 17.1252 22.0001 16.914 22.0001C16.5647 22.0001 16.2215 21.833 16.0106 21.5222L14.743 19.6581C13.3679 20.5257 11.7423 21.0303 10.0001 21.0303C8.2314 21.0303 6.58265 20.5099 5.19491 19.6183L3.90019 21.5222C3.68901 21.8328 3.34584 22.0001 2.9965 22.0001C2.78531 22.0001 2.57191 21.9391 2.38392 21.8111C1.88532 21.4721 1.75605 20.7937 2.09502 20.2951L3.50891 18.2156C2.00892 16.6201 1.08698 14.4747 1.08698 12.1169C1.08698 7.20226 5.08538 3.2041 9.9998 3.2041C14.9145 3.2041 18.9126 7.20226 18.9126 12.1169ZM10.6084 12.7821L13.6553 9.73422C13.9397 9.45002 13.9397 8.98966 13.6558 8.7052C13.3718 8.42099 12.9112 8.42099 12.627 8.7052L10.0943 11.2384L8.2235 9.36835C7.9393 9.08415 7.47869 9.08415 7.19448 9.36835C6.91052 9.65256 6.91052 10.1134 7.19473 10.3971L9.57966 12.7821C9.72176 12.9242 9.90778 12.9952 10.094 12.9952C10.2801 12.9952 10.4668 12.9242 10.6084 12.7821Z"
                      ></path>
                    </svg>
                    <span>Schedule</span>
                  </a> */}
                  <a
                    href="#"
                    className={`ml-8 py-1 flex align-center no-underline edit-link-option ${selectedRecommendationsList?.length ? '' : 'disabled'}`}
                    onClick={e => {
                      e.preventDefault();
                      setConfirmModalObj({
                        isVisible: true,
                        message: 'Are you sure you want to delete selected test cases?',
                        id: ''
                      });
                    }}
                  >
                    <svg
                      className="svg-icon-l"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="22"
                      viewBox="0 0 20 22"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.0021 1.92008H18.4668C19.022 1.92008 19.4616 2.35962 19.4616 2.91483C19.4616 3.47003 19.022 3.90957 18.4668 3.90957H1.53307C0.977867 3.90957 0.53833 3.47003 0.53833 2.91483C0.53833 2.35962 0.977867 1.92008 1.53307 1.92008H5.99785L7.06199 0.439537C7.27019 0.161935 7.57093 0 7.91793 0H12.082C12.429 0 12.7528 0.161935 12.9379 0.439537L14.0021 1.92008ZM2.48159 5.27441H17.5184C17.796 5.27441 18.0273 5.50575 18.0273 5.78335V19.9873C18.0273 21.0978 17.1251 22 16.0147 22H3.98527C2.87486 22 1.97266 21.0978 1.97266 19.9873V5.78335C1.97266 5.50575 2.20399 5.27441 2.48159 5.27441ZM6.09043 18.5531C6.46056 18.5531 6.7613 18.2523 6.7613 17.8822V9.34592C6.7613 8.97578 6.46056 8.67505 6.09043 8.67505C5.72029 8.67505 5.41955 8.97578 5.41955 9.34592V17.8822C5.41955 18.2523 5.72029 18.5531 6.09043 18.5531ZM10 18.5531C10.3701 18.5531 10.6709 18.2523 10.6709 17.8822V9.34592C10.6709 8.97578 10.3701 8.67505 10 8.67505C9.62986 8.67505 9.32912 8.97578 9.32912 9.34592V17.8822C9.32912 18.2523 9.62986 18.5531 10 18.5531ZM13.9096 18.5531C14.2797 18.5531 14.5804 18.2523 14.5804 17.8822V9.34592C14.5804 8.97578 14.2797 8.67505 13.9096 8.67505C13.5394 8.67505 13.2387 8.97578 13.2387 9.34592V17.8822C13.2387 18.2523 13.5394 18.5531 13.9096 18.5531Z"
                      ></path>
                    </svg>
                    <span>Delete</span>
                  </a>
                </div>
              </div>

              <table className="app-table mt-4 mb-7">
                <tbody>
                  {
                    paginationList && paginationList.map((item, index) => {
                      const recommendationsByID = tags?.filter(it => it.recommendationId === item._id);
                      return (
                        <Fragment key={'row-head-' + index}>
                          <tr className='table-row-title'>
                            <td colSpan="9" className="table-row-details">
                              <div className='justify-between'>
                                <a onClick={e => handleItemExpand(e, item._id)}>
                                  <div className='flex align-center '>
                                    <span className='mr-2 py-1 sub-heading-3 tr-head-row-title capitalize table-heading'>{(((paginationData.pageNo - 1) * paginationData.pageSize) + (1)) + index}. {`${item.label} - ${getSiteName(item.siteId, item.tenantId)} ${item.start_date && item.end_date ? ` - ${formatDate(item.start_date)} - ${formatDate(item.end_date)}` : ''}`}</span>
                                    {
                                      !showUsageDropdown[item._id] ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" className="svg-icon"
                                          viewBox="0 0 18 12" fill="none">
                                          <path
                                            d="M17.6537 2.91958L9.83027 10.743C9.72124 10.8524 9.59169 10.9393 9.44905 10.9985C9.30641 11.0577 9.15347 11.0882 8.99902 11.0882C8.84457 11.0882 8.69164 11.0577 8.54899 10.9985C8.40635 10.9393 8.2768 10.8524 8.16778 10.743L0.344312 2.91958C0.123853 2.69912 -3.2851e-09 2.40011 0 2.08834C3.2851e-09 1.77656 0.123853 1.47755 0.344312 1.25709C0.564772 1.03663 0.863779 0.912782 1.17556 0.912782C1.48733 0.912782 1.78634 1.03663 2.0068 1.25709L9 8.25029L15.9932 1.25612C16.2137 1.03566 16.5127 0.911804 16.8244 0.911804C17.1362 0.911804 17.4352 1.03566 17.6557 1.25612C17.8761 1.47658 18 1.77558 18 2.08736C18 2.39914 17.8761 2.69814 17.6557 2.9186L17.6537 2.91958Z"
                                            fill="#1B72E6" />
                                        </svg>
                                      ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" className="svg-icon"
                                          viewBox="0 0 18 12" fill="none">
                                          <path
                                            d="M0.34627 9.08042L8.16974 1.25695C8.27876 1.14755 8.40831 1.06074 8.55095 1.00151C8.69359 0.942283 8.84653 0.911793 9.00098 0.911793C9.15543 0.911793 9.30836 0.942283 9.45101 1.00151C9.59365 1.06074 9.7232 1.14755 9.83222 1.25695L17.6557 9.08042C17.8761 9.30088 18 9.59989 18 9.91166C18 10.2234 17.8761 10.5224 17.6557 10.7429C17.4352 10.9634 17.1362 11.0872 16.8244 11.0872C16.5127 11.0872 16.2137 10.9634 15.9932 10.7429L9 3.7497L2.0068 10.7439C1.78634 10.9643 1.48734 11.0882 1.17556 11.0882C0.863781 11.0882 0.564774 10.9643 0.344315 10.7439C0.123856 10.5234 7.5514e-08 10.2244 1.0277e-07 9.91264C1.30027e-07 9.60086 0.123856 9.30185 0.344315 9.08139L0.34627 9.08042Z"
                                            fill="#1B72E6" />
                                        </svg>
                                      )
                                    }
                                  </div>
                                </a>
                                {recommendationsByID?.length === 0 ? (
                                  <div className='flex'>
                                    <a
                                      href="#"
                                      className={`ml-8 flex align-center no-underline edit-link-option usage-pulse-delete-icon`}
                                      onClick={e => {
                                        e.preventDefault();
                                        setConfirmModalObj({
                                          isVisible: true,
                                          message: 'Are you sure you want to delete recommendation?',
                                          id: item._id
                                        });
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="18"
                                        viewBox="0 0 20 22"
                                        fill="none"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          d="M14.0021 1.92008H18.4668C19.022 1.92008 19.4616 2.35962 19.4616 2.91483C19.4616 3.47003 19.022 3.90957 18.4668 3.90957H1.53307C0.977867 3.90957 0.53833 3.47003 0.53833 2.91483C0.53833 2.35962 0.977867 1.92008 1.53307 1.92008H5.99785L7.06199 0.439537C7.27019 0.161935 7.57093 0 7.91793 0H12.082C12.429 0 12.7528 0.161935 12.9379 0.439537L14.0021 1.92008ZM2.48159 5.27441H17.5184C17.796 5.27441 18.0273 5.50575 18.0273 5.78335V19.9873C18.0273 21.0978 17.1251 22 16.0147 22H3.98527C2.87486 22 1.97266 21.0978 1.97266 19.9873V5.78335C1.97266 5.50575 2.20399 5.27441 2.48159 5.27441ZM6.09043 18.5531C6.46056 18.5531 6.7613 18.2523 6.7613 17.8822V9.34592C6.7613 8.97578 6.46056 8.67505 6.09043 8.67505C5.72029 8.67505 5.41955 8.97578 5.41955 9.34592V17.8822C5.41955 18.2523 5.72029 18.5531 6.09043 18.5531ZM10 18.5531C10.3701 18.5531 10.6709 18.2523 10.6709 17.8822V9.34592C10.6709 8.97578 10.3701 8.67505 10 8.67505C9.62986 8.67505 9.32912 8.97578 9.32912 9.34592V17.8822C9.32912 18.2523 9.62986 18.5531 10 18.5531ZM13.9096 18.5531C14.2797 18.5531 14.5804 18.2523 14.5804 17.8822V9.34592C14.5804 8.97578 14.2797 8.67505 13.9096 8.67505C13.5394 8.67505 13.2387 8.97578 13.2387 9.34592V17.8822C13.2387 18.2523 13.5394 18.5531 13.9096 18.5531Z"
                                        ></path>
                                      </svg>
                                      <span>Delete</span>
                                    </a>
                                  </div>
                                ) : null}
                              </div>
                            </td>
                          </tr>
                          {
                            showUsageDropdown[item._id] ? (
                              recommendationsByID?.length > 0 ? (
                                <>
                                  <tr className="table-row-subtitle">
                                    <td colSpan={1}>
                                      <div className="align-center">
                                        <span className="mr-1 table-subheading">ID</span>
                                      </div>
                                    </td>
                                    <td colSpan={5}>
                                      <div className="justify-between align-center">
                                        <span className="mr-1 table-subheading">
                                          Test Cases
                                        </span>
                                        <a href="#" className="d-flex" onClick={e => {
                                          e.preventDefault();
                                          onViewRecommendations(item);
                                        }}>
                                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="24" height="24" rx="4" fill="#D7DFE8" />
                                            <path d="M6.32791 13.3243H10.6721V17.6757C10.6721 18.4135 11.2791 19 12 19C12.7209 19 13.3279 18.3946 13.3279 17.6757V13.3243H17.6721C18.4119 13.3243 19 12.7189 19 12C19 11.2811 18.393 10.6757 17.6721 10.6757H13.3469V6.32432C13.3469 5.58649 12.7398 5 12.019 5C11.2981 5 10.6911 5.60541 10.6911 6.32432V10.6757H6.32791C5.58808 10.6757 5 11.2811 5 12C5 12.7189 5.60705 13.3243 6.32791 13.3243Z" fill="#1D4273" />
                                          </svg>
                                        </a>
                                      </div>
                                    </td>
                                    {/* <td>
                                      <div className="align-center">
                                        <span className="mr-1">Last Run</span>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="align-center">
                                        <span className="mr-1">Status</span>
                                      </div>
                                    </td> */}
                                    <td>
                                      <div className="align-center">
                                        <label className="form-field-checkbox">
                                          <input type="checkbox" checked={selectedRecommendationsList?.length > 0 && compareIds(selectedRecommendationsList, recommendationsByID)} onChange={e => {
                                            const selectedIDs = e.target.checked ? recommendationsByID?.map(it => ({
                                              _id: it._id
                                            })) : [];
                                            setSelectedRecommendationsList(selectedIDs);
                                          }} />
                                          <span className="checkmark"></span>
                                        </label>
                                      </div>
                                    </td>
                                  </tr>
                                  {
                                    recommendationsByID?.map((rItem, rIndex) => {
                                      const isRowSelected = !!selectedRecommendationsList.find(it => it?._id === rItem._id);
                                      return (
                                        <tr key={'tr-row-' + rIndex} className="table-content">
                                          <td colSpan={1} style={{ width: '125px' }}>
                                            <div className="align-center">
                                              <span className="font-xs font-status-color">{rItem._id.substring(10)}</span>
                                            </div>
                                          </td>
                                          <td colSpan={5}>
                                            <Link to={`/page-action/page/${rItem._id}/device/35f84a7cedb7814c92d0a9ba18d4cac0169`}>
                                              <div className="align-center justify-between">
                                                <span className="mr-3 button-medium table-text table-row-data">
                                                  {rItem.name}
                                                </span>
                                                <img
                                                  src="/icons/arrow-right.svg"
                                                  className="svg-icon"
                                                />
                                              </div>
                                            </Link>
                                          </td>
                                          {/* <td>
                                            <div className="align-center">
                                              <span className="mr-1">{ getRegressionLastRun(rItem) }</span>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="align-center">
                                              <span className={ 'mr-1 ' + (rItem.lastStatus == 'Failed' ? 'font-danger' : '')}>{ getRegressionStatus(rItem) }</span>
                                            </div>
                                          </td> */}
                                          <td style={{ width: '50px' }}>
                                            <div className="align-center">
                                              <label className="form-field-checkbox">
                                                <input type="checkbox" name="" checked={isRowSelected} onChange={e => {
                                                  setSelectedRecommendationsList([
                                                    ...(e.target.checked ? [...selectedRecommendationsList, {
                                                      _id: rItem._id
                                                    }] : selectedRecommendationsList.filter(rec => rec?._id !== rItem?._id))
                                                  ]);
                                                }} />
                                                <span className="checkmark"></span>
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    })
                                  }
                                </>
                              ) : (
                                <>
                                  <tr className="tr-head-row">
                                    <td colSpan="9" className="empty-table-usage-list">
                                      <div className="empty-table-usage-list-container d-flex align-items-center justify-content-center">
                                        <div className="sub-heading-3 mb-4">Review the recommendations to pick the test cases you would like to run automatically</div>
                                        {
                                          (item.status == 'READY' || item.status == 'IN_PROGRESS') && (
                                            <div className="sub-heading-3 mb-4">Cards are being generated, might take a while.</div>
                                          )
                                        }
                                        {
                                          (item.status == 'FAILED') && (
                                            <>
                                              <div className="sub-heading-3 mb-4 font-danger">Failed to generate recommendations</div>
                                              {
                                                item.failedReason && (
                                                  <div className="sub-heading-3 mb-4 font-danger">{ item.failedReason}</div>
                                                )
                                              }
                                            </>
                                          )
                                        }
                                        {
                                          (item.status != 'FAILED') && (
                                            <button
                                              onClick={() => onViewRecommendations(item)}
                                              type="button"
                                              className="primary-btn font-small px-4 py-2 align-center"
                                            >
                                              <span>View Recommendations</span>
                                            </button>
                                          )
                                        }

                                      </div>
                                    </td>
                                  </tr>
                                </>
                              )
                            ) : null
                          }
                        </Fragment>
                      )
                    })
                  }
                </tbody>
              </table>
              {
                filteredList && filteredList.length > 0 && (
                  <TablePagination onChange={handlePaginationChange} pageNo={paginationData.pageNo} pageSize={paginationData.pageSize} totalSize={filteredList.length}></TablePagination>
                )
              }

            </div>
          ) : (
            <div className="table-list-section p-6 rounded-l mt-6 d-flex align-items-center justify-content-center">
              <button
                onClick={() => setTestRecommendationModalOpen(true)}
                className="primary-btn font-small px-4 py-2 align-center ml-3"
              >
                <span className="ml-2">Start Creating Recommendations</span>
              </button>
            </div>
          )}
        </div>
        {
          isLoading && (
            <SectionLoader></SectionLoader>
          )
        }
      </div>
      {filter ? (
        <AppModal>
          <PageFilter
            filterData={filterData}
            onSubmit={onSubmitFilter}
            onClose={closeFilterModal}
          ></PageFilter>
        </AppModal>
      ) : null}
      {isModalOpen && usageRecommendationModalData ? (
        <Modal
          onClose={closeModal}
          onNext={nextModalContent}
          // title={modalTitle || 'Please select any ONE category from below list'}
          // subtitle={modalContent !== 'collection' ? `Based on ${usageRecommendationModalData?.createdAt ? getMonthYear(usageRecommendationModalData?.createdAt) : ''} Usage` : ''}
          title={(modalContent !== "recommendations" && modalTitle) || usageRecommendationModalData?.label}
          subtitle={usageRecommendationModalData?.start_date && usageRecommendationModalData?.end_date ? `${formatDate(usageRecommendationModalData.start_date)} - ${formatDate(usageRecommendationModalData.end_date)}` : ''}
          buttonText={modalLabels[modalContent]?.buttonText}
          customClass={`${modalContent} create-recommendation-modal ${showTreeToggleClass}`}
          collectionSaved={collectionSaved}
          disableNext={modalContent === "recommendations" ? !modalTitle : (modalContent === category.value.split("_")[0] ? selectedSteps?.length === 0 : (modalContent === 'collection' ? (!!selectedSteps.find(it => it.regressionName === '')) : false))}
          onBack={modalContent === "recommendations" ? null : backModalContent}
          hideFooter={false}
          customComponent={<TreeDropdown handleToggleView={handleToggleView} treeViewSelected={treeViewSelected} />}
        >
          {modalContent === "recommendations" ? (
            <Recommendations
              onCategorySelect={categorySelected}
              onDataLoaded={e => {
                loadedDataMap[e._id] = e;
                loadedDataMap[e._id]?.['top_actioned_journey']?.data?.filter((x, xi) => xi < 3).forEach(journey => {
                  journey.journey_flow = journey.pages?.map((page, i) => (
                    <React.Fragment key={'re-' + i}>
                      {
                        getStepName(page)
                      }
                      {(i + 1) !== journey.pages.length ? <span className="mx-2">
                        <ChevronIcon type="right" variant="small" />
                      </span> : null}
                    </React.Fragment>
                  ));
                  journey.journey_flow_white = journey.pages?.map((page, i) => (
                    <React.Fragment key={'re-' + i}>
                      {
                        getStepName(page)
                      }
                      {(i + 1) !== journey.pages.length ? <span className="mx-2">
                        <ChevronIcon type="right-white" variant="small" />
                      </span> : null}
                    </React.Fragment>
                  ));
                });

                loadedDataMap[e._id]?.['top_journey']?.data?.forEach(journey => {
                  journey.journey_flow = journey.pages?.map((page, i) => (
                    <React.Fragment key={'re-' + i}>
                      {page.page_title} ({page.no_of_users})
                      {(i + 1) !== journey.pages.length ? <span className="mx-2">
                        <ChevronIcon type="right" variant="small" />
                      </span> : null}
                    </React.Fragment>
                  ));
                  journey.journey_flow_white = journey.pages?.map((page, i) => (
                    <React.Fragment key={'re-' + i}>
                      {page.page_title} ({page.no_of_users})
                      {(i + 1) !== journey.pages.length ? <span className="mx-2">
                        <ChevronIcon type="right-white" variant="small" />
                      </span> : null}
                    </React.Fragment>
                  ));
                });
                setLoadedDataMap(loadedDataMap);
              }}
              data={usageRecommendationModalData}
              selectedCategory={category.value}
            />
          ) : null}
          {modalContent === "actions" ? (
            <PageActions
              treeViewSelected={treeViewSelected}
              onRegressionSelect={regressionSelected}
              selectedSteps={selectedSteps}
              actionData={
                category?.type ?
                  loadedDataMap[usageRecommendationModalData._id]?.[category.type]
                  : null
              }
            />
          ) : null}
          {modalContent === "devices" ? (
            <DeviceUsage
              onRegressionSelect={regressionSelected}
              selectedSteps={selectedSteps}
              deviceData={category?.type ? loadedDataMap[usageRecommendationModalData._id]?.[category.type] : null}
            />
          ) : null}
          {modalContent === "journey" ? (
            <PageJourney
              treeViewSelected={treeViewSelected}
              onRegressionSelect={regressionSelected}
              selectedSteps={selectedSteps}
              journeyData={category?.type ? loadedDataMap[usageRecommendationModalData._id]?.[category.type] : null}
            />
          ) : null}
          {modalContent === "actioned" ? (
            <PageJourney
              displayType="actioned"
              treeViewSelected={treeViewSelected}
              onRegressionSelect={regressionSelected}
              selectedSteps={selectedSteps}
              journeyData={category?.type ? loadedDataMap[usageRecommendationModalData._id]?.[category.type] : null}
            />
          ) : null}
          {modalContent === "treeview" ? (
            <PageJourney
              displayType="treeview"
              recommendationId={usageRecommendationModalData._id}
              treeViewSelected={true}
              onRegressionSelect={regressionSelected}
              selectedSteps={selectedSteps}
            />
          ) : null}
          {modalContent === "hits" ? (
            <PageHits
              onRegressionSelect={regressionSelected}
              selectedSteps={selectedSteps}
              pageData={category?.type ? loadedDataMap[usageRecommendationModalData._id]?.[category.type] : null}
            />
          ) : null}
          {modalContent === "collection" ? (
            <Collections
              collectionSaved={collectionSaved}
              runNow={runNow}
              updateRunNow={val => setrunNow(val)}
              selectedSteps={selectedSteps}
              setUserFlowRegressionTags={setUserFlowRegressionTags}
              setSelectedSteps={setSelectedSteps}
              records={[]}
            />
          ) : null}
        </Modal>
      ) : null}
      {isTestRecommendationModalOpen ? (
        <Modal
          onClose={closeTestRecommendationModal}
          onNext={saveTestRecommendation}
          customClass={'test-recommendation'}
          title={'Test Recommendations'}
          buttonText={'Save'}
          disableNext={saveRecommendationLoading}
        >
          <div className="modal-form-content">
            <div className="form-field-group-checkbox__items radio-group w-full mb-8">
              <div className="form-field-group-checkbox__item radio-group mr-4">
                <label htmlFor="range-custom" className="form-field-checkbox mr-2">
                  <input type="radio" name="rangeType" id="range-custom" onChange={e => setRangeType('CUSTOM')} checked={rangeType == 'CUSTOM'} />
                  <span className="checkmark"></span>
                </label>
                <label htmlFor="range-custom">Custom date</label>
              </div>
              <div className="form-field-group-checkbox__item radio-group mr-4">
                <label htmlFor="range-recent" className="form-field-checkbox mr-2">
                  <input type="radio" name="rangeType" id="range-recent" onChange={e => setRangeType('RECENT')} checked={rangeType == 'RECENT'} />
                  <span className="checkmark"></span>
                </label>
                <label htmlFor="range-recent">Recent</label>
              </div>
            </div>
            {
              rangeType == 'CUSTOM' && (
                <div className="d-flex justify-content-between test-recommendation_dates-wrap">
                  <div className="form-field">
                    <label className="label mb-2">Start Date <sub>*</sub></label>
                    <input
                      className="input-date"
                      type="date"
                      name="startDate"
                      onChange={onTestRecFormChange}
                      value={recommendationFormData.startDate.value}
                      max={date}
                    />
                    {recommendationFormData.startDate.showError && <div className="error-message">{recommendationFormData.startDate.errorMessage}</div>}
                  </div>
                  <div className="form-field">
                    <label className="label mb-2">End Date <sub>*</sub></label>
                    <input
                      className="input-date"
                      type="date"
                      name="endDate"
                      onChange={onTestRecFormChange}
                      value={recommendationFormData.endDate.value}
                      max={date}
                    />
                    {recommendationFormData.endDate.showError && <div className="error-message">{recommendationFormData.endDate.errorMessage}</div>}
                  </div>
                </div>
              )
            }

            {
              rangeType == 'RECENT' && (
                <div className="form-field-select">
                  <label className="label mb-2">Time range</label>
                  <div className="form-field">
                    <select className="select-text" name="site" value={recommendationFormData.recentTime.value} onChange={e => {
                      let updateformData = recommendationFormData;
                      updateformData.recentTime.value = e.target.value - 0;
                      setRecommendationFormData({ ...updateformData })

                    }}>
                      {
                        defaultRecentRange.map((x, xi) => {
                          return (
                            <option key={'dropdown-item' + xi} value={x.value}>{x.name}</option>
                          )
                        })
                      }
                    </select>
                    <svg className="post-svg-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="11" viewBox="0 0 18 11" fill="none"><path d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z" fill="#1B72E6"></path></svg>
                  </div>
                </div>
              )
            }

            <div className="form-field-select">
              <label className="label mb-2">Tenant</label>
              <div className="form-field">
                <div className="pos-rel">
                  <select className="select-text" name="site" 
                    value={recommendationFormData.site.value} 
                    onChange={onTestRecFormChange}>
                    <option value="">Select Tenant</option>
                    {
                      siteData?.map(x => {
                        return (
                          <option key={'dropdown-item' + x._id} value={x._id}>{x.name}</option>
                        )
                      })
                    }
                  </select>
                  <svg className="post-svg-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="11" viewBox="0 0 18 11" fill="none"><path d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z" fill="#1B72E6"></path></svg>
                </div>
                {
                  !recommendationFormData.site.showError && (recommendationFormData.site.value) && (
                    <div className="mt-2 flex align-center">
                      <div>
                        <span className="para-2">SiteId:</span> 
                        <span className="ml-2">{siteData?.find(x => x._id == recommendationFormData.site.value)?.siteId}</span> 
                      </div>
                      <div className="ml-4">
                        <span className="para-2">TenantId:</span> 
                        <span className="ml-2">{siteData?.find(x => x._id == recommendationFormData.site.value)?.tenantId}</span> 
                      </div>
                    </div>
                  )
                }
                {recommendationFormData.site.showError && <div className="error-message">{recommendationFormData.site.errorMessage}</div>}
              </div>
            </div>
            <div className="form-field">
              <label className="label mb-2">Title <sub>*</sub></label>
              <input
                className="input-txt"
                type="text"
                name="title"
                onChange={onTestRecFormChange}
                value={recommendationFormData.title.value}
              />
              {recommendationFormData.title.showError && <div className="error-message">{recommendationFormData.title.errorMessage}</div>}
            </div>
            {recommendationFormError && (<div>
              <div className="error-message">{recommendationFormError}</div>
            </div>)}
          </div>
        </Modal>
      ) : null}
      {confirmModalObj?.isVisible ? (
        <ConfirmModal
          confirmationTitle={confirmModalObj?.message}
          confirmButtonLabel={'Delete'}
          onOk={() => confirmModalObj?.id ? onDeleteRecommendation(confirmModalObj.id) : onDeleteTestCases()}
          onCancel={e => setConfirmModalObj({
            isVisible: false,
            message: '',
            id: ''
          })}
        />
      ) : null}
    </div>
  );
};

export default MainContent;
