import { useState } from 'react'
export default ({ label, defaultLabel, list }) => {
    const [selectItem, setSelectedItem] = useState('')
    const [availableList, setAvailableList] = useState(list)
    const [selectedList, setSelectedList] = useState([])
    const handleSelect = (value) => {
        setSelectedItem(value);
    }
    const addItemToList = (e) => {
        e.preventDefault()
        let selectedListItem = availableList.find(x => x._id === selectItem);
        if(selectedListItem && !selectedList.find(x => x._id == selectItem)) {
            setSelectedList([...selectedList, selectedListItem ])
        }
    }
    const removeSelectedItem = (e, item) => {
        e.preventDefault()
        let newList = selectedList.filter(x => x._id !== item._id)
        setSelectedList(newList)
    }
    return (
        <>
            <div className="form-field-select mt-3">
                <label className="label mb-1">{label}</label>
                <div className="align-center">
                    <div className="form-field">
                        <select className="select-text" value={selectItem} onChange={e => handleSelect(e.target.value)}>
                            <option>{ defaultLabel }</option>
                            {
                                availableList.map(x => {
                                    return (
                                        <option key={'dropdown-item' + x._id} value={x._id}>{x.name}</option>
                                    )
                                })
                            }
                        </select>
                        <svg className="post-svg-icon" xmlns="http://www.w3.org/2000/svg" width="18"
                            height="11" viewBox="0 0 18 11" fill="none">
                            <path
                                d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z"
                                fill="#1B72E6" />
                        </svg>
                    </div>
                    <a onClick={addItemToList} href="/add-page.html" className="btn-icon ml-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <path d="M2.08672 13.0811H8.91328V19.9189C8.91328 21.0784 9.86721 22 11 22C12.1328 22 13.0867 21.0486 13.0867 19.9189V13.0811H19.9133C21.0759 13.0811 22 12.1297 22 11C22 9.87027 21.0461 8.91892 19.9133 8.91892H13.1165V2.08108C13.1165 0.921622 12.1626 0 11.0298 0C9.89702 0 8.94309 0.951351 8.94309 2.08108V8.91892H2.08672C0.924119 8.91892 0 9.87027 0 11C0 12.1297 0.95393 13.0811 2.08672 13.0811Z" fill="#1B72E6" />
                        </svg>
                    </a>
                </div>
            </div>
            {
                selectedList && selectedList.length > 0 && (
                    <div className="tags mt-2">
                        {
                            selectedList.map((x, xi) => {
                                return (
                                    <div key={'v-tags-' + xi} className="tag-item px-3 py-2 mr-2">
                                        <div className="tag-item__text mr-2">{x.name}</div>
                                        <a onClick={e => removeSelectedItem(e, x)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                <path d="M11.7472 10.5349C11.9081 10.6958 11.9986 10.9141 11.9986 11.1417C11.9986 11.3694 11.9081 11.5877 11.7472 11.7486C11.5862 11.9096 11.3679 12 11.1403 12C10.9126 12 10.6943 11.9096 10.5333 11.7486L6 7.21412L1.46523 11.7472C1.30427 11.9081 1.08595 11.9986 0.858315 11.9986C0.630676 11.9986 0.41236 11.9081 0.251395 11.7472C0.0904294 11.5862 3.39209e-09 11.3679 0 11.1403C-3.39209e-09 10.9127 0.0904295 10.6944 0.251395 10.5334L4.78616 6.00036L0.252822 1.46586C0.0918573 1.30491 0.00142808 1.0866 0.00142808 0.858978C0.00142808 0.631352 0.0918573 0.413049 0.252822 0.252094C0.413788 0.0911379 0.632104 0.000713864 0.859743 0.000713861C1.08738 0.000713859 1.3057 0.0911379 1.46666 0.252094L6 4.78659L10.5348 0.251379C10.6957 0.0904238 10.914 -3.79225e-09 11.1417 0C11.3693 3.79225e-09 11.5876 0.0904238 11.7486 0.251379C11.9096 0.412335 12 0.630638 12 0.858263C12 1.08589 11.9096 1.30419 11.7486 1.46515L7.21384 6.00036L11.7472 10.5349Z" fill="#556629" />
                                            </svg>
                                        </a>
                                    </div>
                                );
                            })
                        }
                    </div>
                )
            }
        </>
    )
}