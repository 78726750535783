import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DeviceDetailList from "../../comps/organisms/DeviceDetailList";
import dataService from "../../services/data.service";
import storeService from "../../services/store.service";
import SvgIcon from "../../comps/atoms/SvgIcon";
import utilsService from "../../services/utils.service";
import pageutilService from "../../services/pageutil.service";
import { Tooltip } from 'react-tooltip';

export default () => {
  const [selectedTenantEnv, setselectedTenantEnv] = useState(storeService.store.ENVIRONMENTS.selectedTenantEnv);
  const [page, setPage] = useState(null);
  const [device, setDevice] = useState("");
  const [devices, setDevices] = useState([]);
  const [overviewData, setOverviewData] = useState({
    lastJobDate: "12/20/2023",
    textIssuesCount: 0,
    imageIssuesCount: 0,
    layoutIssuesCount: 0,
    jobsCount: 0,
    failureCount: 0,
    differencesStatus: "Failed",
    bestPracticesStatus: "Failed",
    finalStatus: "Failed",
    failedPriority: null
  });
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    storeService.subscribe('ENVIRONMENTS', ({ detail }) => {
      setselectedTenantEnv(detail.selectedTenantEnv)
    })
    storeService.subscribe("PAGES_WITH_DETAILS", ({ detail }) => {
      parsePageData(detail.items);
    });
    fetchData();
  }, []);

  const fetchData = () => {
    if (params.pageId) {
      dataService.getPageWithDetails({ id: params.pageId });
    }
  }

  useEffect(() => {
    fetchData();
  }, [selectedTenantEnv])

  useEffect(() => {
    fetchData();
    storeService.subscribe("PAGES_WITH_DETAILS", ({ detail }) => {
      parsePageData(detail.items);
    });
  }, [params.pageId, params.deviceId])

  const parsePageData = (items) => {
    let pageItem = items.find((x) => x._id == params.pageId);
    if (pageItem) {
      setPage(pageItem);
      setDevices([...pageItem.physicalDevices, ...pageItem.virtualDevices]);
      let deviceItem = [
        ...pageItem.physicalDevices,
        ...pageItem.virtualDevices,
      ].find((x) => x._id == params.deviceId);
      if (deviceItem) {
        let jobCount = deviceItem.jobs.length;
        let finalStatusToSet = "-";
        let isBaselineFound = false;
        let failedPriorityToSet = null;
        let failureCount = 0;
        deviceItem.jobs.forEach((job) => {
          let createdAt = job.createdAt;
          // get date in mm/dd/yyyy format using 2024-01-25T22:32:58.035Z
          let [date1, time1] = createdAt.split("T");
          let [yyyy, mm, dd] = date1.split("-");
          job.testDate = mm + "/" + dd + "/" + yyyy;
          // job.testDate = createdAt;
          let {
            layoutStatus,
            imageStatus,
            textStatus,
            lastStatus,
            differences,
            differencesCount,
            bestPracticesStatus,
            differencesStatus,
            finalStatus,
            failedPriority
          } = pageutilService.getJobStatus(job);
          job.lastStatus = lastStatus;
          job.imageStatus = imageStatus;
          job.textStatus = textStatus;
          job.layoutStatus = layoutStatus;
          job.differencesCount = differencesCount;
          job.differences = differences;
          job.bestPracticesStatus = bestPracticesStatus;
          job.finalStatus = finalStatus;
          job.failedPriority = failedPriority;
          job.differencesStatus = differencesStatus;
          if (job.isBaseline) {
            isBaselineFound = true;
          }
        });
        if (!isBaselineFound) {
          //deviceItem.jobs[deviceItem.jobs.length - 1].isBaseline = true;
        }
        setDevice(deviceItem);
        if (jobCount > 0) {
          failureCount = deviceItem.jobs.filter(x => {
            return x.finalStatus == 'Failed'
          }).length;
          let recentJob = deviceItem.jobs[0];
          let baselineFound = deviceItem.jobs.find((x) => x.isBaseline);
          let {
            layoutStatus,
            imageStatus,
            textStatus,
            lastStatus,
            differences,
            finalStatus,
            failedPriority
          } = pageutilService.getJobStatus(recentJob);
          if (baselineFound) {
            if (recentJob._id == baselineFound._id) {
              if (recentJob.status == "DONE") {
                finalStatusToSet = "Passed";
              }
              if (recentJob.status == "FAILED") {
                finalStatusToSet = "Failed";
              }
              if (recentJob.status == "IN_PROGRESS") {
                finalStatusToSet = "Running";
              }
            } else {
              finalStatusToSet = finalStatus
              failedPriorityToSet = failedPriority;
            }
          } else {
            if (recentJob.status == "DONE") {
              let {
                layoutStatus,
                imageStatus,
                textStatus,
                lastStatus,
                differences,
                finalStatus,
                failedPriority
              } = pageutilService.getJobStatus(recentJob);
              finalStatusToSet = finalStatus
              failedPriorityToSet = failedPriority;
            }
            if (recentJob.status == "FAILED") {
              finalStatusToSet = "Failed";
              failedPriorityToSet = failedPriority;
            }
            if (recentJob.status == "IN_PROGRESS") {
              finalStatusToSet = "Running";
            }
          }
          if (recentJob.differencesCount) {
            setOverviewData({
              ...overviewData,
              textIssuesCount: recentJob.differencesCount.text,
              imageIssuesCount: recentJob.differencesCount.image,
              layoutIssuesCount: recentJob.differencesCount.layout,
              jobsCount: jobCount,
              failureCount: failureCount,
              lastJobDate: utilsService.getDisplayDateTime(recentJob.createdAt),
              differencesStatus: recentJob?.screentest?.ComparisonStatus ? recentJob?.screentest?.ComparisonStatus : '-',
              bestPracticesStatus: recentJob?.screentest?.bestpracticesStatus ? recentJob?.screentest?.bestpracticesStatus : '-',
              finalStatus: finalStatusToSet,
              failedPriority: failedPriorityToSet
            });
          } else {
            setOverviewData({
              ...overviewData,
              lastJobDate: utilsService.getDisplayDateTime(recentJob.createdAt),
              jobsCount: jobCount,
              failureCount: failureCount,
              textIssuesCount: 0,
              imageIssuesCount: 0,
              layoutIssuesCount: 0,
              differencesStatus: recentJob?.screentest?.ComparisonStatus ? recentJob?.screentest?.ComparisonStatus : '-',
              bestPracticesStatus: recentJob?.screentest?.bestpracticesStatus ? recentJob?.screentest?.bestpracticesStatus : '-',
              finalStatus: finalStatusToSet,
              failedPriority: failedPriorityToSet
            });
          }
        } else {
          setOverviewData({
            ...overviewData,
            textIssuesCount: 0,
            imageIssuesCount: 0,
            layoutIssuesCount: 0,
            jobsCount: 0,
            failureCount: 0,
            lastJobDate: '-',
            differencesStatus: '-',
            bestPracticesStatus: '-',
            finalStatus: 'Not Yet',
            failedPriority: failedPriorityToSet
          });
        }
      }
    }
  };
  const deviceChangeHandler = (event) => {
    const deviceSelected = devices?.find((device) => device.name == event.target.value);
    navigate(`/ui-regression/page/${params.pageId}/device/${deviceSelected._id}`);
  };

  return (
    <>
      <div className="section-box p-6 rounded-l">
        <div className="flex justify-between align-center">
        <div className="flex align-center pointer" id="ssDetailPage-tooltip">
            <h2 className="mr-3 test1">Overview</h2>
            <img src="/icons/info-svg.svg" className="svg-icon-l" /> 
              <Tooltip
              anchorSelect="#ssDetailPage-tooltip"
              content="View detailed stats for the latest and past runs. Filter results by environment and date."
              place="right"
              style={{
                backgroundColor: "#000",
                color: "#ffffff",
                borderRadius: "5px",
                zIndex: 22,
                fontWeight: "bold",
                width: "30%",
              }}
            />
         </div>
          <div className="flex align-center justify-between">
            <div className="form-field form-field--select align-center mr-8">
              <span className="h5-heading para-label mr-1">Device Type</span>
              <span className="para-gray">
                {" "}
                {device.deviceMode == "VIRTUAL" ? "Virtual" : "Physical"}
              </span>
            </div>
            <div className="form-field form-field--select align-center  mr-8 pageblazer-device">
              <div className="form-field__label align-center">Device:</div>
              <select
                value={device.name}
                onChange={(e) => deviceChangeHandler(e)}
              >
                {devices?.map((device, index) => (
                  <option
                    key={`${index}__${device.name}`}
                    value={device.name}
                    data-id={device._id}
                  >
                    {device.name}
                  </option>
                ))}
              </select>
              <svg
                className="post-svg-icon"
                xmlns="http:www.w3.org/2000/svg"
                width="18"
                height="11"
                viewBox="0 0 18 11"
                fill="none"
              >
                <path
                  d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z"
                  fill="#1B72E6"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="flex box-tiles mt-4">
          <div className="box-tile w-4/6 pr-2">
            <div
              className={
                "box-tile__item box-tile__left p-6 " +
                (overviewData.finalStatus == "Failed" ? "error" : "")
              }
            >
              <div className="flex card-section mt-4">
                <div className="card-item">
                  <div className="card-item__no">
                    {overviewData.lastJobDate}
                  </div>
                  <div className="card-item__text mt-2">Last Run</div>
                </div>
                <div className="card-item">
                  <div className="card-item__no">
                    {overviewData.finalStatus} {overviewData.finalStatus == "Failed" && overviewData.failedPriority ? `- ${overviewData.failedPriority}`: ''}
                  </div>
                  <div className="card-item__text mt-2">Status</div>
                </div>
                <div className="card-item">
                  <div className="card-item__no">
                    {overviewData.differencesStatus}
                  </div>
                  <div className="card-item__text mt-2">Differences</div>
                </div>
                <div className="card-item">
                  <div className="card-item__no">
                    {overviewData.bestPracticesStatus}
                  </div>
                  <div className="card-item__text mt-2">Best Practices </div>
                </div>
              </div>
            </div>
          </div>

          <div className="box-tile w-2/6 pl-2">
            <div
              className={
                "box-tile__item box-tile__left p-6 " +
                (overviewData.failureCount > 0 ? "error" : "")
              }
            >
              <div className="flex card-section mt-4">
                <div className="card-item">
                  <div className="card-item__no">{overviewData.jobsCount}</div>
                  <div className="card-item__text mt-2">Test Runs</div>
                </div>

                <div className="card-item">
                  <div className="card-item__no">
                    {overviewData.failureCount}
                  </div>
                  <div className="card-item__text mt-2">Test Failures</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {device && page && (
        <DeviceDetailList
          page={page}
          device={device}
          list={device.jobs}
        ></DeviceDetailList>
      )}
    </>
  );
};
