import React from 'react';
import { Link } from "react-router-dom";
import AppIcon from './AppIcon';
import storeService from '../../services/store.service';
import utilsService from '../../services/utils.service';

export default ({ detailsToDisplay }) => {
    
    const closeModal = (e) => {
        e && e.preventDefault();
        storeService.pub(utilsService.CONSTANTS.EVENT_NAME.OPEN_CHAT_DETAILS_MODAL, {
            showModal: false
        });
    }

    const getDetailsLink = detail => {
        const { TestType = 1, PageID = '', DeviceID = ''  } = detail;
        let linkURL = visualUIRegression;
        if(TestType === 1) {
          linkURL = `${visualUIRegression}ui-regression/page/${PageID}/device/${DeviceID}`;
        } else if(TestType === 2) {
          linkURL = `${usageUIRegression}page-action/page/${PageID}/device/${DeviceID}`;
        } else if(TestType === 3) {
          linkURL = `${visualUIRegression}api-regression/collection/${PageID}`;
        }
        return linkURL;
    };
    
    const visualUIRegression = 'http://' + window.location.hostname + '/';
    const usageUIRegression = 'http://' + window.location.hostname + ':3100/';
    
    return (
        <div className="app-side-modal show">
            <div className="app-side-modal__bg"></div>
            <div className="app-side-modal__content">
                <div className="align-center p-4 modal-head">
                    <button className="bg-none no-border mr-4" onClick={event => closeModal(event)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="close-svg-icon" width="16" height="16" viewBox="0 0 28 28" fill="none">
                            <path d="M27.4101 24.5813C27.7857 24.9569 27.9967 25.4663 27.9967 25.9974C27.9967 26.5285 27.7857 27.0379 27.4101 27.4134C27.0345 27.789 26.5251 28 25.9939 28C25.4628 28 24.9534 27.789 24.5778 27.4134L14 16.833L3.41888 27.4101C3.0433 27.7857 2.53389 27.9967 2.00273 27.9967C1.47158 27.9967 0.962173 27.7857 0.586588 27.4101C0.211002 27.0346 7.91487e-09 26.5252 0 25.9941C-7.91487e-09 25.4629 0.211002 24.9536 0.586588 24.578L11.1677 14.0008L0.589919 3.42034C0.214334 3.04478 0.00333218 2.53541 0.00333219 2.00428C0.00333219 1.47315 0.214334 0.963782 0.589919 0.588218C0.965505 0.212655 1.47491 0.00166568 2.00607 0.00166568C2.53722 0.00166567 3.04663 0.212655 3.42221 0.588218L14 11.1687L24.5811 0.586552C24.9567 0.210989 25.4661 -8.84857e-09 25.9973 0C26.5284 8.84857e-09 27.0378 0.210989 27.4134 0.586552C27.789 0.962115 28 1.47149 28 2.00261C28 2.53374 27.789 3.04312 27.4134 3.41868L16.8323 14.0008L27.4101 24.5813Z" fill="#1B72E6" />
                        </svg>
                    </button>
                    <h3 className='font-urbanist line-height-140'>Complete Results</h3>
                </div>
                <div className="p-2 model-main-content">
                    <table className="app-table mt-2">
                        <tbody>
                            <tr className="tr-head">
                                <td>
                                    <div className="align-center">
                                        <span className="mr-1">Date Run</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                            fill="none">
                                            <path
                                                d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389957 7.20038 -0.17433 9.00998 0.172936 10.7558C0.520203 12.5016 1.37737 14.1053 2.63604 15.364C3.89471 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9974 6.61386 17.0483 4.32622 15.361 2.63896C13.6738 0.951708 11.3861 0.00264685 9 0ZM9 16C7.61553 16 6.26216 15.5895 5.11101 14.8203C3.95987 14.0511 3.06266 12.9579 2.53285 11.6788C2.00303 10.3997 1.86441 8.99223 2.13451 7.63437C2.4046 6.2765 3.07129 5.02922 4.05026 4.05025C5.02922 3.07128 6.2765 2.4046 7.63437 2.1345C8.99224 1.86441 10.3997 2.00303 11.6788 2.53284C12.9579 3.06266 14.0511 3.95986 14.8203 5.11101C15.5895 6.26215 16 7.61553 16 9C15.998 10.8559 15.2599 12.6352 13.9476 13.9476C12.6352 15.2599 10.8559 15.998 9 16ZM13.0408 6.95917C13.1341 7.05207 13.208 7.16246 13.2585 7.28401C13.309 7.40556 13.335 7.53588 13.335 7.6675C13.335 7.79911 13.309 7.92943 13.2585 8.05098C13.208 8.17253 13.1341 8.28293 13.0408 8.37583L9.7075 11.7092C9.6146 11.8024 9.50421 11.8764 9.38265 11.9268C9.2611 11.9773 9.13078 12.0033 8.99917 12.0033C8.86755 12.0033 8.73724 11.9773 8.61568 11.9268C8.49413 11.8764 8.38374 11.8024 8.29084 11.7092L4.9575 8.37583C4.76964 8.18797 4.6641 7.93317 4.6641 7.6675C4.6641 7.40182 4.76964 7.14703 4.9575 6.95917C5.14536 6.7713 5.40016 6.66576 5.66584 6.66576C5.93151 6.66576 6.18631 6.7713 6.37417 6.95917L9 9.58333L11.6258 6.95667C11.7189 6.86385 11.8293 6.79029 11.9508 6.74018C12.0723 6.69007 12.2024 6.6644 12.3339 6.66463C12.4653 6.66486 12.5953 6.69099 12.7167 6.74153C12.838 6.79207 12.9481 6.86602 13.0408 6.95917Z"
                                                fill="#889AB1" />
                                        </svg>
                                    </div>
                                </td>
                                <td>
                                    <div className="align-center">
                                        <span className="mr-1">Page Name</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                            fill="none">
                                            <path
                                                d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389957 7.20038 -0.17433 9.00998 0.172936 10.7558C0.520203 12.5016 1.37737 14.1053 2.63604 15.364C3.89471 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9974 6.61386 17.0483 4.32622 15.361 2.63896C13.6738 0.951708 11.3861 0.00264685 9 0ZM9 16C7.61553 16 6.26216 15.5895 5.11101 14.8203C3.95987 14.0511 3.06266 12.9579 2.53285 11.6788C2.00303 10.3997 1.86441 8.99223 2.13451 7.63437C2.4046 6.2765 3.07129 5.02922 4.05026 4.05025C5.02922 3.07128 6.2765 2.4046 7.63437 2.1345C8.99224 1.86441 10.3997 2.00303 11.6788 2.53284C12.9579 3.06266 14.0511 3.95986 14.8203 5.11101C15.5895 6.26215 16 7.61553 16 9C15.998 10.8559 15.2599 12.6352 13.9476 13.9476C12.6352 15.2599 10.8559 15.998 9 16ZM13.0408 6.95917C13.1341 7.05207 13.208 7.16246 13.2585 7.28401C13.309 7.40556 13.335 7.53588 13.335 7.6675C13.335 7.79911 13.309 7.92943 13.2585 8.05098C13.208 8.17253 13.1341 8.28293 13.0408 8.37583L9.7075 11.7092C9.6146 11.8024 9.50421 11.8764 9.38265 11.9268C9.2611 11.9773 9.13078 12.0033 8.99917 12.0033C8.86755 12.0033 8.73724 11.9773 8.61568 11.9268C8.49413 11.8764 8.38374 11.8024 8.29084 11.7092L4.9575 8.37583C4.76964 8.18797 4.6641 7.93317 4.6641 7.6675C4.6641 7.40182 4.76964 7.14703 4.9575 6.95917C5.14536 6.7713 5.40016 6.66576 5.66584 6.66576C5.93151 6.66576 6.18631 6.7713 6.37417 6.95917L9 9.58333L11.6258 6.95667C11.7189 6.86385 11.8293 6.79029 11.9508 6.74018C12.0723 6.69007 12.2024 6.6644 12.3339 6.66463C12.4653 6.66486 12.5953 6.69099 12.7167 6.74153C12.838 6.79207 12.9481 6.86602 13.0408 6.95917Z"
                                                fill="#889AB1" />
                                        </svg>
                                    </div>
                                </td>
                                <td>
                                    <div className="align-center">
                                        <span className="mr-1">Status</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                            fill="none">
                                            <path
                                                d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389957 7.20038 -0.17433 9.00998 0.172936 10.7558C0.520203 12.5016 1.37737 14.1053 2.63604 15.364C3.89471 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9974 6.61386 17.0483 4.32622 15.361 2.63896C13.6738 0.951708 11.3861 0.00264685 9 0ZM9 16C7.61553 16 6.26216 15.5895 5.11101 14.8203C3.95987 14.0511 3.06266 12.9579 2.53285 11.6788C2.00303 10.3997 1.86441 8.99223 2.13451 7.63437C2.4046 6.2765 3.07129 5.02922 4.05026 4.05025C5.02922 3.07128 6.2765 2.4046 7.63437 2.1345C8.99224 1.86441 10.3997 2.00303 11.6788 2.53284C12.9579 3.06266 14.0511 3.95986 14.8203 5.11101C15.5895 6.26215 16 7.61553 16 9C15.998 10.8559 15.2599 12.6352 13.9476 13.9476C12.6352 15.2599 10.8559 15.998 9 16ZM13.0408 6.95917C13.1341 7.05207 13.208 7.16246 13.2585 7.28401C13.309 7.40556 13.335 7.53588 13.335 7.6675C13.335 7.79911 13.309 7.92943 13.2585 8.05098C13.208 8.17253 13.1341 8.28293 13.0408 8.37583L9.7075 11.7092C9.6146 11.8024 9.50421 11.8764 9.38265 11.9268C9.2611 11.9773 9.13078 12.0033 8.99917 12.0033C8.86755 12.0033 8.73724 11.9773 8.61568 11.9268C8.49413 11.8764 8.38374 11.8024 8.29084 11.7092L4.9575 8.37583C4.76964 8.18797 4.6641 7.93317 4.6641 7.6675C4.6641 7.40182 4.76964 7.14703 4.9575 6.95917C5.14536 6.7713 5.40016 6.66576 5.66584 6.66576C5.93151 6.66576 6.18631 6.7713 6.37417 6.95917L9 9.58333L11.6258 6.95667C11.7189 6.86385 11.8293 6.79029 11.9508 6.74018C12.0723 6.69007 12.2024 6.6644 12.3339 6.66463C12.4653 6.66486 12.5953 6.69099 12.7167 6.74153C12.838 6.79207 12.9481 6.86602 13.0408 6.95917Z"
                                                fill="#889AB1" />
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                            {detailsToDisplay.details.map((page, index) => {
                                return (
                                  <tr key={`${page.PageName}_${index}`}>
                                    <td>
                                      <div className="align-center">
                                        <span className="mr-1">
                                          {page.Date}
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="align-center">
                                        <Link
                                          to={getDetailsLink(page)}
                                          className="w-full align-center justify-between"
                                        >
                                          <p>{page.PageName}</p>
                                          <AppIcon iconName="rightArrow"></AppIcon>
                                        </Link>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="align-center">
                                        <span className="mr-1">
                                          {page.Status}
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}