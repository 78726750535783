import { useEffect, useState } from 'react';
import Select from 'react-select'
import storeService from '../../../services/store.service.js';
import apiService from '../../../services/api.service.js';
import utilsService from '../../../services/utils.service.js';
import featureFlagService from '../../../services/featureflags.service.js'
import tenantsService from '../../../services/tenants.service.js'
const EnvironmentSelector = () => {
  const [selectedEnvId, setselectedEnvId] = useState(storeService.store.ENVIRONMENTS.selectedEnvId);
  const [environments, setEnvironemts] = useState([{ name: 'Development' }])
  const [tenants, setTenants] = useState([])
  const [options, setOptions] = useState([])
  const constructOptions = (tenants) => {
    let items = [], availableTenentEnvs = [];
    tenants.forEach(tenant => {
      if (tenant.environments && tenant.environments.length > 0) {
        tenant.environments.forEach(env => {
          let val = env._id;
          items.push({
            value: val,
            label: `${tenant.name}-${env.name}`
          })
          availableTenentEnvs.push(val)
        })
      }
    })
    if (!availableTenentEnvs.find(x => x == selectedEnvId)) {
      storeService.saveStore({ selectedEnvId: availableTenentEnvs[0] }, 'ENVIRONMENTS')
      setselectedEnvId(availableTenentEnvs[0]);
      localStorage.setItem('selectedEnvId', availableTenentEnvs[0]);
    }
    setOptions([...items])
  }
  const loadTenants = async () => {
    storeService.subscribe('TENANTS', ({ detail }) => {
      setTenants(detail.items);
      constructOptions(detail.items);
    })
    tenantsService.loadTenants({})
  }
  useEffect(() => {
    storeService.subscribe('ENVIRONMENTS', ({ detail }) => {
      setEnvironemts(detail.items);
      setselectedEnvId(detail.selectedEnvId)
    })
    featureFlagService.getAllFeatures()
    loadTenants();
  }, [])


  const handleTenantChange = (option) => {
    storeService.saveStore({ selectedEnvId: option.value }, 'ENVIRONMENTS')
    setselectedEnvId(option.value);
    localStorage.setItem('selectedEnvId', option.value);
  }
  return (
    <div style={{ minWidth: '300px' }}>
      <Select onChange={handleTenantChange} value={options.find(x => x.value === selectedEnvId)} options={options} />
    </div>
  );
};

export default EnvironmentSelector;
