import React from 'react';
import MainContent from '../../comps/organisms/MainContent/MainContent.jsx';
const UsageBasedRegression = () => {
    return (
        <div className="">
          <MainContent></MainContent>
        </div>
    );
};

export default UsageBasedRegression;
