import AppModal from "../organisms/AppModal.jsx";  
export default ({ onDelete, onClose, confirmationTitle }) => {
  return (
    <AppModal autoWidth={true}>
      <div className="p-10">
        <div className="align-center">
          <h2 className="h3-heading mb-4">
          {confirmationTitle}
            
          </h2>
        </div>
        <div className="mt-8 align-center flex justify-end">
          <button type="button" onClick={onDelete} className="button-warning mr-3">
            Delete
          </button>
          <button type="button" onClick={onClose} className="button-primary">
            Cancel
          </button>
        </div>
      </div>
    </AppModal>
  );
};
