import React, { useEffect, useState } from "react";
import AccordionItem from "./AccordionItem";
// import SvgIcon from '../atoms/SvgIcon.jsx';
import SvgCode from '../../atoms/SvgCode.jsx';

const TreeView = ({ filteredStepPaths, getStepsPath, dataV2 }) => {
  const [parsedData, setParsedData] = useState([]);
  const [testCases, setTestCases] = useState([]);

  function nestChildren(obj) {
    if (obj.children && obj.children.length > 0) {
      let nested = obj.children.reduceRight((acc, child) => {
        return { ...child, children: [acc] };
      });

      obj.children = [nested];
    }
    return obj;
  }
  useEffect(() => {
    filteredStepPaths.forEach((steps) => {
      const childrenArray = steps.map((step) => ({
        name: step.stepName,
      }));
      setParsedData((prevData) => [
        ...prevData,
        {
          name: getStepsPath(steps),
          children: childrenArray,
        },
      ]);
    });
  }, [filteredStepPaths]);
  useEffect(() => {
  }, [dataV2])

  useEffect(() => {
    let output = [];
    parsedData.forEach((eachData) => {
      output.push(nestChildren(eachData));
    });
  }, [parsedData]);

  const handleExpandAll = () => {
    // setIsOpen(true);
  };

  const handleCollapseAll = () => {
    // setIsOpen(false);
  };

  const addTestCase = (testCase) => {
    setTestCases((prevTestCases) => [...prevTestCases, testCase]);
  };

  useEffect(() => {
  },[testCases])

  const handleDelete = (testcase) => {
    const newArray = testCases.filter(item => item !== testcase);
    setTestCases(newArray);
  }

  return (
    <div className="tree-view-wrapper">
      <div className="tree-accordion">
        <div className="tree-accordion-header">
          <h3>Some default title</h3>
          <button
            className="tree-accordion-button--expand"
            onClick={handleExpandAll}
          >
            Expand all
          </button>
          <button
            className="tree-accordion-button--collapse"
            onClick={handleCollapseAll}
          >
            Collapse all
          </button>
        </div>
        {parsedData.map((item, index) => (
          <AccordionItem
            key={index}
            item={item}
            dataIndex={index}
            parsedData={parsedData}
            firstTile={true}
            addTestCase={addTestCase} // Pass the function to AccordionItem
          />
        ))}
      </div>
      <div className="test-case-wrapper">
        <div className="test-case-header">
          <h3>Test Cases generated</h3>
          <button className="test-case-button--edit">Edit</button>
          <button className="test-case-button--save">Save</button>
          <button className="test-case-button--remove">Remove</button>
        </div>
        <div
          className={`test-case-list ${
            testCases.length === 0 ? "test-case-list--empty" : ""
          }`}
        >
          {testCases.length === 0 ? (
            <p>Add from the list to view test cases</p>
          ) : (
            testCases.map((testcase, index) => (
              <div key={index} className="test-case-item flex">
                <span className="flex-1 mr-5">{testcase}</span>
                <a href="#" className="ml-2 py-1 flex align-center no-underline edit-link-option" onClick={()=>handleDelete(testcase)}>
                    <SvgCode name="delete"></SvgCode>
                </a>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default TreeView;
