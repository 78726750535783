export default (props) => {
    switch (props.name) {
        case 'play':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="svg-icon-l"
                    viewBox="0 0 24 24" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd"
                        d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM9.98682 6.14618L17.5039 10.5534C17.6554 10.6418 17.7807 10.7661 17.8677 10.9144C17.9546 11.0627 18.0002 11.2299 18.0002 11.4C18.0002 11.5701 17.9546 11.7373 17.8677 11.8856C17.7807 12.0339 17.6554 12.1582 17.5039 12.2466L9.98682 16.6538C9.82918 16.7459 9.64886 16.7963 9.46427 16.7998C9.27968 16.8033 9.09743 16.7598 8.93612 16.6737C8.7748 16.5876 8.64021 16.4621 8.54607 16.3098C8.45192 16.1576 8.40161 15.9842 8.40025 15.8072V6.99281C8.40161 6.81585 8.45192 6.6424 8.54607 6.49017C8.64021 6.33794 8.7748 6.21238 8.93612 6.1263C9.09743 6.04022 9.27968 5.9967 9.46427 6.0002C9.64886 6.00369 9.82918 6.05406 9.98682 6.14618Z" />
                </svg>
            );
        case 'schedule':
            return (
                <svg className={`svg-icon-l ${props.extraclass}`} xmlns="http://www.w3.org/2000/svg" width="20" height="22"
                    viewBox="0 0 20 22" fill="none">
                    <path fill={props.fill || 'rgba(0, 126, 88, 1)'} fillRule="evenodd" clipRule="evenodd"
                        d="M3.01276 0.56735L1.02183 2.36931C0.0335144 3.26387 0.0779219 4.92792 0.972486 5.91623L1.42248 6.4136C1.5224 6.52387 1.69213 6.53226 1.80192 6.43284L6.97835 1.74686C7.08888 1.64744 7.09727 1.4777 6.9976 1.36743L6.5476 0.870061C5.65304 -0.118252 4.00132 -0.326474 3.01276 0.56735ZM16.9875 0.56735L18.9784 2.36931C19.9667 3.26387 19.9223 4.92792 19.0278 5.91623L18.5775 6.4136C18.4778 6.52387 18.3079 6.53226 18.1983 6.43284L13.0216 1.74686C12.9114 1.64744 12.9027 1.4777 13.0026 1.36743L13.4526 0.870061C14.3472 -0.118252 15.9987 -0.326474 16.9875 0.56735ZM18.9126 12.1169C18.9126 14.5019 17.9692 16.6694 16.4386 18.2701L17.8153 20.2951C18.1543 20.7937 18.025 21.4721 17.5269 21.8111C17.3386 21.9391 17.1252 22.0001 16.914 22.0001C16.5647 22.0001 16.2215 21.833 16.0106 21.5222L14.743 19.6581C13.3679 20.5257 11.7423 21.0303 10.0001 21.0303C8.2314 21.0303 6.58265 20.5099 5.19491 19.6183L3.90019 21.5222C3.68901 21.8328 3.34584 22.0001 2.9965 22.0001C2.78531 22.0001 2.57191 21.9391 2.38392 21.8111C1.88532 21.4721 1.75605 20.7937 2.09502 20.2951L3.50891 18.2156C2.00892 16.6201 1.08698 14.4747 1.08698 12.1169C1.08698 7.20226 5.08538 3.2041 9.9998 3.2041C14.9145 3.2041 18.9126 7.20226 18.9126 12.1169ZM10.6084 12.7821L13.6553 9.73422C13.9397 9.45002 13.9397 8.98966 13.6558 8.7052C13.3718 8.42099 12.9112 8.42099 12.627 8.7052L10.0943 11.2384L8.2235 9.36835C7.9393 9.08415 7.47869 9.08415 7.19448 9.36835C6.91052 9.65256 6.91052 10.1134 7.19473 10.3971L9.57966 12.7821C9.72176 12.9242 9.90778 12.9952 10.094 12.9952C10.2801 12.9952 10.4668 12.9242 10.6084 12.7821Z" />
                </svg>
            );
        case 'delete':
            return (
                <svg className="svg-icon-l" xmlns="http://www.w3.org/2000/svg" width="20" height="22"
                    viewBox="0 0 20 22" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd"
                        d="M14.0021 1.92008H18.4668C19.022 1.92008 19.4616 2.35962 19.4616 2.91483C19.4616 3.47003 19.022 3.90957 18.4668 3.90957H1.53307C0.977867 3.90957 0.53833 3.47003 0.53833 2.91483C0.53833 2.35962 0.977867 1.92008 1.53307 1.92008H5.99785L7.06199 0.439537C7.27019 0.161935 7.57093 0 7.91793 0H12.082C12.429 0 12.7528 0.161935 12.9379 0.439537L14.0021 1.92008ZM2.48159 5.27441H17.5184C17.796 5.27441 18.0273 5.50575 18.0273 5.78335V19.9873C18.0273 21.0978 17.1251 22 16.0147 22H3.98527C2.87486 22 1.97266 21.0978 1.97266 19.9873V5.78335C1.97266 5.50575 2.20399 5.27441 2.48159 5.27441ZM6.09043 18.5531C6.46056 18.5531 6.7613 18.2523 6.7613 17.8822V9.34592C6.7613 8.97578 6.46056 8.67505 6.09043 8.67505C5.72029 8.67505 5.41955 8.97578 5.41955 9.34592V17.8822C5.41955 18.2523 5.72029 18.5531 6.09043 18.5531ZM10 18.5531C10.3701 18.5531 10.6709 18.2523 10.6709 17.8822V9.34592C10.6709 8.97578 10.3701 8.67505 10 8.67505C9.62986 8.67505 9.32912 8.97578 9.32912 9.34592V17.8822C9.32912 18.2523 9.62986 18.5531 10 18.5531ZM13.9096 18.5531C14.2797 18.5531 14.5804 18.2523 14.5804 17.8822V9.34592C14.5804 8.97578 14.2797 8.67505 13.9096 8.67505C13.5394 8.67505 13.2387 8.97578 13.2387 9.34592V17.8822C13.2387 18.2523 13.5394 18.5531 13.9096 18.5531Z" />
                </svg>
            );
        case 'sort':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                    fill="none">
                    <path
                        d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389957 7.20038 -0.17433 9.00998 0.172936 10.7558C0.520203 12.5016 1.37737 14.1053 2.63604 15.364C3.89471 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9974 6.61386 17.0483 4.32622 15.361 2.63896C13.6738 0.951708 11.3861 0.00264685 9 0ZM9 16C7.61553 16 6.26216 15.5895 5.11101 14.8203C3.95987 14.0511 3.06266 12.9579 2.53285 11.6788C2.00303 10.3997 1.86441 8.99223 2.13451 7.63437C2.4046 6.2765 3.07129 5.02922 4.05026 4.05025C5.02922 3.07128 6.2765 2.4046 7.63437 2.1345C8.99224 1.86441 10.3997 2.00303 11.6788 2.53284C12.9579 3.06266 14.0511 3.95986 14.8203 5.11101C15.5895 6.26215 16 7.61553 16 9C15.998 10.8559 15.2599 12.6352 13.9476 13.9476C12.6352 15.2599 10.8559 15.998 9 16ZM13.0408 6.95917C13.1341 7.05207 13.208 7.16246 13.2585 7.28401C13.309 7.40556 13.335 7.53588 13.335 7.6675C13.335 7.79911 13.309 7.92943 13.2585 8.05098C13.208 8.17253 13.1341 8.28293 13.0408 8.37583L9.7075 11.7092C9.6146 11.8024 9.50421 11.8764 9.38265 11.9268C9.2611 11.9773 9.13078 12.0033 8.99917 12.0033C8.86755 12.0033 8.73724 11.9773 8.61568 11.9268C8.49413 11.8764 8.38374 11.8024 8.29084 11.7092L4.9575 8.37583C4.76964 8.18797 4.6641 7.93317 4.6641 7.6675C4.6641 7.40182 4.76964 7.14703 4.9575 6.95917C5.14536 6.7713 5.40016 6.66576 5.66584 6.66576C5.93151 6.66576 6.18631 6.7713 6.37417 6.95917L9 9.58333L11.6258 6.95667C11.7189 6.86385 11.8293 6.79029 11.9508 6.74018C12.0723 6.69007 12.2024 6.6644 12.3339 6.66463C12.4653 6.66486 12.5953 6.69099 12.7167 6.74153C12.838 6.79207 12.9481 6.86602 13.0408 6.95917Z"
                        fill="#889AB1" />
                </svg>
            );
        default:
            return (<></>);
    }
}