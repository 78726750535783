import { useState, useEffect } from "react";
import SvgIcon from "../atoms/SvgIcon";
import AppModal from "../../comps/organisms/AppModal";
import AppIcon from "../../comps/atoms/AppIcon/AppIcon.jsx";
import apiService from "../../services/api.service.js";
import tenantsService from "../../services/tenants.service.js";
import PageUrlMappingRow from "./PageUrlMapping/PageUrlMappingRow.jsx";
import utilsService from "../../services/utils.service.js";
import ConfirmModal from '../molecules/ConfirmModal.jsx';

export default () => {
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [items, setItems] = useState([]);
  const [newUrl, setNewUrl] = useState('');
  const [newPageName, setNewPageName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showMoveToModal, setMoveToModal] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [deleteItem, setdeleteItem] = useState(null)
  const [currentPage, setCurrentPage] = useState(0);
  const [tenantItems, settenantItems] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [showAddItemModal, setshowAddItemModal] = useState(false)
  const [selectedTenant, setSelectedTenant] = useState(null)
  const [selectedCopyTenant, setselectedCopyTenant] = useState(null)
  const [errorMap, setErrorMap] = useState({})
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedMaps, setselectedMaps] = useState([])

  const handleUpdate = () => {
    const [siteId, tenantId] = selectedTenant.split('||')
    let updatedErrorMap = {}, errorCount = 0;
    if (!(newUrl && newUrl.length > 0)) {
      errorCount += 1;
      updatedErrorMap['newUrl'] = true;
    }
    if (!(newPageName && newPageName.length > 0)) {
      errorCount += 1;
      updatedErrorMap['newPageName'] = true;
    }
    if (errorCount > 0) {
      setErrorMap(updatedErrorMap)
      return;
    }
    setErrorMap({})
    apiService.post({
      url: utilsService.END_POINTS.addpageurlmapping,
      data: {
        siteId,
        tenantId,
        url: newUrl,
        name: newPageName,
        isEdit: editItem != null,
        urlToReplace: editItem?.url
      }
    }).finally(() => {
      setNewUrl('')
      setNewPageName('')
      setshowAddItemModal(false);
      loadTenants()
    })
  };
  const handleDeleteUrl = (delItem) => {
    setdeleteItem(delItem)
    setShowDeleteModal(true);
  }
  const handleDeleteModal = () => {
    if (deleteItem) {
      const [_id, url, name] = deleteItem.itemKey.split('||')
      apiService.post({
        url: utilsService.END_POINTS.addpageurlmapping,
        data: {
          _id,
          isDelete: true,
          urlToReplace: url
        }
      }).finally(() => {
        setNewUrl('')
        setNewPageName('')
        setshowAddItemModal(false);
        loadTenants()
      })

    }
    setShowDeleteModal(false);
  }
  const handleCopyTo = () => {
    const [siteId, tenantId] = selectedCopyTenant.split('||')
    let mappings = {};
    selectedMaps.forEach(mapItem => {
      const [, url, name] = mapItem.split('||')
      mappings[url] = name;
    })
    apiService.post({
      url: utilsService.END_POINTS.copypageurlmappings,
      data: {
        siteId,
        tenantId,
        mappings
      }
    }).finally(() => {
      setShowModal(false);
      setselectedMaps([])
      loadTenants()
    })
  }
  const handleSearch = () => {
    setSearchTerm(searchString)
  };
  const loadTenants = () => {
    tenantsService.loadTenants({}).then(apiResp => {
      const { data: { items = [], totalCount = 0 } = {} } = apiResp;
      if (items.length > 0) {
        let firstItem = items[0];
        setSelectedTenant((firstItem.siteId || 'default') + '||' + (firstItem.tenantId || 'default'))
        setselectedCopyTenant((firstItem.siteId || 'default') + '||' + (firstItem.tenantId || 'default'))
      }
      settenantItems(items)
    })
  }
  useEffect(() => {
    loadTenants();
  }, [])
  return (
    <>
      <div className="url-mapping-module p-4">
        <h2 className="mb-8">Page URL Mapping</h2>
        <div className="flex align-center w-100 mb-6">
          <div className="w-50">
            <input
              className="input-txt w-50"
              type="text"
              placeholder="Search"
              value={searchString}
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  handleSearch()
                }
              }}
              onChange={(e) => setSearchString(e.target.value)}
            /> 
              <button className="btn-medium-2 ml-3" type='button' onClick={handleSearch}>Go</button> 
          </div>       
          <div className="w-50 justify-end flex">
            <div className="align-center">
              <button disabled={selectedMaps.length == 0} className="btn-medium-2 ml-3" type='button' onClick={() => {
                setShowModal(true)
              }}>Copy To</button>
            </div>
            <div className="align-center">
              <button className="btn-medium-2 ml-3" type='button' onClick={() => {
                setErrorMap({})
                setEditItem(null)
                setshowAddItemModal(true)
              }}>Add Page Mapping</button>
            </div>
          </div>
        </div>
        <table className="app-table my-4">
          <tbody>
            {
              tenantItems.map(tenantItem => {
                return (
                  <PageUrlMappingRow onRowDelete={handleDeleteUrl} onRowEdit={eItem => {
                    console.log('eItem', eItem)
                    setEditItem(eItem)
                    setErrorMap({})
                    setNewUrl(eItem.url)
                    setNewPageName(eItem.name)
                    setSelectedTenant((eItem.siteId) + '||' + (eItem.tenantId))
                    setshowAddItemModal(true)
                  }} tenant={tenantItem} searchTerm={searchTerm} selectedMaps={selectedMaps} onSelectionChange={itemKey => {
                    if (selectedMaps.includes(itemKey)) {
                      setselectedMaps([...selectedMaps.filter(x => x != itemKey)])
                    } else {
                      setselectedMaps([...selectedMaps, itemKey])
                    }
                  }} />
                )
              })
            }

          </tbody>
        </table>
      </div>
      {
        showAddItemModal && (
          <AppModal>
            <div className="add-modal-popup">
              <div className="justify-between align-center px-8 py-6">
                <div className="align-center">
                  <h2 className="h2-heading"> {editItem ? 'Edit' : 'Add'} Page URL Mapping</h2>
                </div>
                <button type="button"
                  onClick={e => {
                    setshowAddItemModal(false)
                  }}
                  className="bg-none no-border">
                  <AppIcon iconName="closeIcon" iconColor="#1B72E6" />
                </button>
              </div>
              <div className="p-4 edit-section modal-content modal-form-content overflow-y-scroll min-h-60vh max-h-60vh">
                <div className="mb-8">
                  <label className="label mb-2" htmlFor="urlInput">Page URL</label>
                  <input type="text" className="input-txt" id="urlInput" value={newUrl} onChange={(e) => setNewUrl(e.target.value)} />
                  {
                    errorMap['newUrl'] && (
                      <p className="error-message">This is required field</p>
                    )
                  }

                </div>
                <div className="mb-8">
                  <label className="label mb-2" htmlFor="pageNameInput">Page Name</label>
                  <input type="text" className="input-txt" id="pageNameInput" value={newPageName} onChange={(e) => setNewPageName(e.target.value)} />
                  {
                    errorMap['newPageName'] && (
                      <p className="error-message">This is required field</p>
                    )
                  }
                </div>
                <div className="mb-8">
                  <label className="label mb-2" htmlFor="pageNameInput">Tenant Name</label>
                  <div className="form-field">
                    <select disabled={editItem ? true : false} className="select-text" value={selectedTenant} onChange={e => {
                      setSelectedTenant(e.target.value);
                    }}>
                      {
                        tenantItems.map(tItem => {
                          return (
                            <option value={(tItem.siteId || 'default') + '||' + (tItem.tenantId || 'default')}>{tItem.name} - {tItem.siteId || 'default'} - {tItem.tenantId || 'default'}</option>
                          )
                        })
                      }
                    </select>
                    <svg className="post-svg-icon" xmlns="http://www.w3.org/2000/svg" width="18"
                      height="11" viewBox="0 0 18 11" fill="none">
                      <path
                        d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z"
                        fill="#1B72E6" />
                    </svg>
                  </div>
                </div>
                <div className="flex justify-end py-6">
                  <button className="btn-medium-2 w-20 mr-6 btn-secondary" type='button' onClick={(e) => {
                    setNewUrl('')
                    setNewPageName('')
                    setshowAddItemModal(false)
                    setEditItem(null)
                  }}>Cancel</button>
                  <button className="btn-medium-2 btn-medium-width" type='button' onClick={handleUpdate}>Save</button>
                </div>
              </div>
            </div>
          </AppModal>
        )
      }
      {
        showModal && (
          <AppModal>
            <div className="moveto-modal-popup">
              <div className="justify-between align-center px-8 py-6">
                <div className="align-center">
                  <h2 className="h2-heading"> Copy To</h2>
                </div>
                <button type="button"
                  onClick={e => {
                    setShowModal(false)
                  }}
                  className="bg-none no-border">
                  <AppIcon iconName="closeIcon" iconColor="#1B72E6" />
                </button>
              </div>
              <div className="p-4 edit-section modal-content modal-form-content overflow-y-scroll min-h-60vh max-h-60vh">
                <div className="mb-8">
                  <label className="label mb-2" htmlFor="pageNameInput">Tenant Name</label>
                  <div className="form-field">
                    <select className="select-text" value={selectedCopyTenant} onChange={e => {
                      setselectedCopyTenant(e.target.value);
                    }}>
                      {
                        tenantItems.map(tItem => {
                          return (
                            <option value={(tItem.siteId || 'default') + '||' + (tItem.tenantId || 'default')}>{tItem.name} - {tItem.siteId || 'default'} - {tItem.tenantId || 'default'}</option>
                          )
                        })
                      }
                    </select>
                    <svg className="post-svg-icon" xmlns="http://www.w3.org/2000/svg" width="18"
                      height="11" viewBox="0 0 18 11" fill="none">
                      <path
                        d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z"
                        fill="#1B72E6" />
                    </svg>
                  </div>
                </div>

                <table className="app-table my-4">
                  <tbody>
                    <tr className="table-row-subtitle ">
                      <td className="w-50 word-break-all">
                        <div className="align-center">
                          <span className="mr-1 table-subheading">Page URL</span>
                        </div>
                      </td>
                      <td className="w-30 word-break-all">
                        <div className="align-center">
                          <span className="mr-1 table-subheading">Page Name</span>
                        </div>
                      </td>
                    </tr>
                    {
                      selectedMaps.map(mapItem => {
                        const [, url, name] = mapItem.split('||')
                        return (
                          <tr className="table-content">
                            <td className="table-text w-50 word-break-all">
                              <div className="justify-between align-center">
                                <span className="table-text">{url}</span>
                              </div>
                            </td>
                            <td className="table-text w-30 word-break-all">
                              <div className="justify-between align-center">
                                <span className="table-text">{name}</span>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
                <div className="flex justify-end py-6">
                  <button className="btn-medium-2 w-20 mr-6 btn-secondary" type='button' onClick={() => {
                    setShowModal(false)
                    setIsEditing(false)
                  }}>Cancel</button>
                  <button className="btn-medium-2 btn-medium-width" type='button' onClick={handleCopyTo}>Save</button>
                </div>
              </div>
            </div>

          </AppModal>
        )
      }
      {
        showDeleteModal && (
          <ConfirmModal
            confirmationTitle={'Are you sure you want to delete?'}
            confirmButtonLabel={'Delete'}
            onOk={() => handleDeleteModal()}
            onCancel={e => setShowDeleteModal(false)}
          />
        )
      }
    </>
  );
};
