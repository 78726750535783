import { useState, useEffect } from "react";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import featureflagsService from "../services/featureflags.service";
import tenantsService from "../services/tenants.service";
import storeService from "../services/store.service";
import authService from "../services/auth.service";
export default () => {
    const [params, setParams] = useSearchParams();
    if (params.get('env')) {
        let decodeEnv = decodeURIComponent(params.get('env'));
        localStorage.setItem('selectedEnvironment', decodeEnv);
        storeService.store.ENVIRONMENTS.selectedEnvironment = decodeEnv;
        localStorage.setItem('selectedTenantEnv', decodeEnv);
        localStorage.setItem('selectedEnvID', decodeEnv);
        storeService.store.ENVIRONMENTS.selectedTenantEnv = decodeEnv;
        storeService.store.ENVIRONMENTS.selectedEnvID = decodeEnv;
    }

    if (params.get('source')) {
        let decodeSource = decodeURIComponent(params.get('source'));
        localStorage.setItem('source', decodeSource);
    }

    // Remove query and reload if any of the query param env or source
    if (params.get('env') || params.get('source')) {
        location.href = location.href.split('?')[0];
    }

    const [isLoading, setIsLoading] = useState(true);
    const checkAuth = async () => {
        let toeknResp = await authService.tokeninfo({})
        const { data, error } = toeknResp;
        if(error) {
            location.href = "/login?redirectUrl=" + encodeURIComponent(location.pathname);
            return;
        }
        // const tenantsList = await tenantsService.loadTenants();
        // console.log('tenantsList', storeService.store.ENVIRONMENTS.selectedTenantEnv, tenantsList)
        
        setIsLoading(false);
    }
    useEffect(() => {
        if (localStorage.getItem('token')) {
            checkAuth();
        } else {
            location.href = "/login?redirectUrl=" + encodeURIComponent(location.pathname);
        }
    }, [])
    if (isLoading) {
        return (
            <div className="root-layout">
                <div>
                    <div className="pos-rel">
                        &nbsp;
                        <div className="section-loader2">
                            <div class="wave">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                    <div>
                        Loading environments...
                    </div>
                </div>
            </div>
        )
    }
    return (<Outlet />)
}