import { Outlet, Link } from "react-router-dom";
import LeftNav from "../../comps/atoms/LeftNav";
export default () => {
    return (
        <div className="flex main-layout">
            <div className="main-layout__left-ribbon-nav">
                <LeftNav/>
            </div>
            <div className="main-layout__content relative">
                <div className="fluid-container py-4">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}