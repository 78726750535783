import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PageTableList from '../../comps/organisms/PageTableList';
import BarChart from '../../comps/organisms/BarChart';
import StackedBarChart from '../../comps/organisms/StackedBarChart';
import { Tooltip } from 'react-tooltip';
import SvgIcon from '../../comps/atoms/SvgIcon';
import storeService from '../../services/store.service';
import utilsService from '../../services/utils.service';
import pageutilService from '../../services/pageutil.service';
import SectionLoader from '../../comps/atoms/SectionLoader';
import apiService from "../../services/api.service.js";

let initialCall = true;
export default () => {
  let todayDate = new Date();
  const [selectedEnvId, setselectedEnvId] = useState(storeService.store.ENVIRONMENTS.selectedEnvId);
  const today = utilsService.getYMDFormat(new Date());
  let fromDate = new Date();
  fromDate.setDate(fromDate.getDate() - 30);
  const location = useLocation()
  const [overviewData, setOverviewData] = useState({
    totalJobsCount: 0,
    failedJobCount: 0,
    failedDesktopJobCount: 0,
    failedMobileJobCount: 0,
    consecutiveFailureCount: 0,
    failedMorethan3Times: 0,
    totalDeviceCount: 0,
    desktopRunCount: 0,
    mobileRunCount: 0,
    lastDateRan: storeService.store.PAGES_WITH_DETAILS.filterData.selectedDate || null,
    rangeFromDate: utilsService.getYMDFormat(fromDate),
    rangeEndDate: utilsService.getYMDFormat(todayDate),
    failedPageCount: 0,
    totalPagesCount: 0
  })

  const [compareMode, setCompareMode] = useState('slider');
  const [sightSenseStats, setSightSenseStat] = useState({});
  const [date, setDate] = useState(storeService.store.PAGES_WITH_DETAILS.filterData.selectedDate);
  const [isLoading, setLoading] = useState(true);

  const getSightSenseStats = async (precompute = false) => {
    const apiSightSenseStat2 = await apiService.post({
      url: utilsService.END_POINTS.getstats,
      data: {
        "filter_date": date || '',
        selectedEnvId: selectedEnvId,
        module_name: "sightsense"
      }
    });
    setSightSenseStat(apiSightSenseStat2?.data || {});
    setLoading(false);
  }
  useEffect(() => {
    // getSightSenseStats(true);
    let pageStore = storeService.getStore('PAGES_WITH_DETAILS');
    if (!pageStore.loaded) {
      storeService.subscribe('PAGES_WITH_DETAILS', ({ detail }) => {
        parsePageData(detail.items);
      });
    } else {
      parsePageData(pageStore.items);
    }
    storeService.subscribe('ENVIRONMENTS', ({ detail }) => {
      setselectedEnvId(detail.selectedEnvId)
    })
    storeService.sub('RELOAD_STATS', () => {
      getSightSenseStats();
    });
  }, [])

  const countConsecutiveOnes = (arr) => {
    let consecutiveCount = 0;
    let maxConsecutiveCount = 0;

    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === 1) {
        consecutiveCount++;
        if (consecutiveCount > maxConsecutiveCount) {
          maxConsecutiveCount = consecutiveCount;
        }
      } else {
        consecutiveCount = 0;
      }
    }
    return maxConsecutiveCount;
  }
  const parsePageData = (items) => {
    let jobcount = 0,
      deviceCount = 0,
      testedPageCount = 0,
      failedCount = 0;
    let consecutiveFailedCount = 0;
    let moreThan3TimesCount = 0;
    items.forEach(page => {
      let isPageFailed = false;
      let deviceConsecutiveFailedList = [];
      let devices = [...page.physicalDevices, ...page.virtualDevices];
      let notRunDeviceCount = 0;
      devices.forEach(device => {
        jobcount += device.jobs.length;
        if (device.jobs.length > 0) {
          let recentJob = device.jobs[0];
          let {
            layoutStatus,
            imageStatus,
            textStatus,
            lastStatus,
            differences
          } = pageutilService.getJobStatus(recentJob);
        } else {
          notRunDeviceCount += 1;
        }
        deviceCount += 1;
      });
      if (notRunDeviceCount != devices.length) {
        testedPageCount += 1;
      }
      if (Math.max(...deviceConsecutiveFailedList) > 1) {
        consecutiveFailedCount += 1;
      }
      if (Math.max(...deviceConsecutiveFailedList) > 3) {
        moreThan3TimesCount += 1;
      }
      if (isPageFailed) {
        failedPageCount += 1;
      }
    });
    let { desktopRunCount, mobileRunCount, mobileFailCount, desktopFailCount } = pageutilService.getAllDeviceStatus(items);
    let {
      failedPageCount
    } = pageutilService.getPagesStatus(items);
    setOverviewData({
      ...overviewData,
      totalJobsCount: jobcount,
      totalDeviceCount: deviceCount,
      failedJobCount: failedCount,
      failedDesktopJobCount: desktopFailCount,
      failedMobileJobCount: mobileFailCount,
      desktopRunCount,
      mobileRunCount,
      consecutiveFailureCount: consecutiveFailedCount,
      failedMorethan3Times: moreThan3TimesCount,
      totalPagesCount: testedPageCount,
      failedPageCount
    })
  }
  const showSideBarModal = (e, value) => {
    e.preventDefault();
    storeService.pub(utilsService.CONSTANTS.EVENT_NAME.OPEN_TESTCASES_SIDEBAR_MODAL, {
      showModal: true,
      selectedFilter: value,
      date: date
    })
  }
  const setDateFromElement = (value, fieldName) => {
    setOverviewData({
      ...overviewData,
      [fieldName]: value
    });
    setDate(value);
    storeService.store.PAGES_WITH_DETAILS.filterData.selectedDate = value;
  }

  useEffect(() => {
    getSightSenseStats();
  }, [date, selectedEnvId])

  return (
    <>
      <div className="section-box p-6 rounded-l">
        <div className="flex justify-between align-center">
          <div className="flex align-center pointer" id="sightsense-tooltip">
            <h2 className="mr-3">Overview</h2>
            <img src="/icons/info-svg.svg" className="svg-icon-l" />
            <Tooltip
              anchorSelect="#sightsense-tooltip"
              content="View test stats for created, executed, total runs, and failure rates (overall and by device). Filter results by environment and date."
              place="right"
              style={{
                backgroundColor: "#000",
                color: "#ffffff",
                borderRadius: "5px",
                zIndex: 22,
                fontWeight: "bold",
                width: "50%",
              }}
            />
          </div>
          <div className="align-center">
            <span className="h5-heading para-label mr-2 subheading-color">Select Date</span>
            <input
              className="input-date"
              onChange={(e) =>
                setDateFromElement(e.target.value, "lastDateRan")
              }
              value={overviewData.lastDateRan}
              type="date"
              name="lasttestrun"
              id="lasttestrun"
              max={today}
            />
          </div>
        </div>

        <div className="flex box-tiles mt-4">
          <div className="box-tile w-3/6 pr-2">
            <div className="box-tile__item box-tile__left p-6">
              <h4 className="h4-heading subheading-color">Total Pages</h4>
              <div className="flex card-section mt-4">
                <div className="card-item">
                  <div className="card-item__no align-center">
                    {/* <a href="/pages-window.html" onClick={e => showSideBarModal(e, 'TOTAL_RUNS')} className="align-center"> */}
                    <span className="mr-3">
                      {sightSenseStats?.total_pages}
                    </span>
                    {/* <SvgIcon path="/icons/arrow-right.svg"></SvgIcon> */}
                    {/* </a> */}
                  </div>
                  <div className="card-item__text align-center mt-2 pointer" id="createdpages">
                    <span className="mr-1">Created Pages</span>
                    <SvgIcon path="/icons/info-svg.svg"></SvgIcon>
                    <Tooltip   
                      anchorSelect="#createdpages"                    
                      content="Total number of tests created (not all tests may have been executed)."
                      place="bottom-start"
                      style={{
                        backgroundColor: "#000",
                        color: "#ffffff",
                        borderRadius: "5px",
                        zIndex: 22,
                        fontWeight: "bold", 
                        width:"20%",
                      }}
                    />
                  </div>
                </div>
                <div className="card-item">
                  <div className="card-item__no align-center">
                     <span className="mr-3">
                      {sightSenseStats?.total_pages_tested}
                    </span> 
                  </div>
                  <div className="card-item__text align-center mt-2 pointer" id="totaltestsran">
                    <span className="mr-1">Executed Tests</span>
                    <SvgIcon path="/icons/info-svg.svg"></SvgIcon>
                    <Tooltip   
                      anchorSelect="#totaltestsran"                    
                      content="The number of unique tests that have been run at least once."
                      place="bottom-start"
                      style={{
                        backgroundColor: "#000",
                        color: "#ffffff",
                        borderRadius: "5px",
                        zIndex: 22,
                        fontWeight: "bold",
                        width:"20%",
                      }}
                    />
                  </div>
                </div>
                <div className="card-item">
                  <div className="card-item__no align-center">
                    {/* <a href="/pages-window.html" onClick={e => showSideBarModal(e, 'TOTAL_RUNS')} className="align-center"> */}
                    <span className="mr-3">
                      {sightSenseStats?.total_test_runs}
                    </span>
                    {/* <SvgIcon path="/icons/arrow-right.svg"></SvgIcon>
                                        </a> */}
                  </div>
                  <div className="card-item__text mt-2 pointer" id="totalruns">
                    <span className="mr-1">Total Runs</span>
                    <SvgIcon path="/icons/info-svg.svg"></SvgIcon>
                    <Tooltip   
                      anchorSelect="#totalruns"                    
                      content="Total count of test executions (some tests may run multiple times)."
                      place="bottom"
                      style={{
                        backgroundColor: "#000",
                        color: "#ffffff",
                        borderRadius: "5px",
                        zIndex: 22,
                        fontWeight: "bold",
                        width:"20%",
                      }}
                    />
                  </div>
                </div> 
              </div>
            </div>
          </div>
          <div className="box-tile w-3/6 pl-2">
            <div className="box-tile__item box-tile__right p-6">
              <h4 className="h4-heading subheading-color">Failed Pages</h4>
              <div className="flex card-section mt-4">
                <div className="card-item">
                  <div className="card-item__no">
                    <a
                      href="#"
                      onClick={(e) => showSideBarModal(e, "FAILED_PAGES")}
                      className="align-center"
                    >
                      <span className="mr-3">
                        {sightSenseStats?.total_failed_pages}
                      </span>
                      <SvgIcon path="/icons/arrow-right.svg"></SvgIcon>
                    </a>
                  </div>
                  <div className="card-item__text mt-2">Failed Pages</div>
                </div>

                <div className="card-item">
                  <div className="card-item__no">
                    <a
                      href="#"
                      onClick={(e) =>
                        showSideBarModal(e, "FAILED_ON_DESKTOP")
                      }
                      className="align-center"
                    >
                      <span className="mr-3">
                        {sightSenseStats?.failed_devices_with_count?.DESKTOP}
                      </span>
                      <SvgIcon path="/icons/arrow-right.svg"></SvgIcon>
                    </a>
                  </div>
                  <div className="card-item__text mt-2">Desktop</div>
                </div>

                <div className="card-item">
                  <div className="card-item__no">
                    <a
                      href="#"
                      onClick={(e) => showSideBarModal(e, "FAILED_ON_MOBILE")}
                      className="align-center"
                    >
                      <span className="mr-3">
                        {sightSenseStats?.failed_devices_with_count?.MOBILE}
                      </span>
                      <SvgIcon path="/icons/arrow-right.svg"></SvgIcon>
                    </a>
                  </div>
                  <div className="card-item__text mt-2">Mobile</div>
                </div>

                <div className="card-item">
                  <div className="card-item__no">
                    <a
                      href="#"
                      onClick={(e) =>
                        showSideBarModal(e, "CONSECUTIVE_FAILURE")
                      }
                      className="align-center"
                    >
                      <span className="mr-3">
                        {sightSenseStats?.consecutive_failed_cases}
                      </span>
                      <SvgIcon path="/icons/arrow-right.svg"></SvgIcon>
                    </a>
                  </div>
                  <div className="card-item__text mt-2">Consecutive Failures</div>
                </div>
              </div>
              {/* <SectionLoader></SectionLoader> */}
            </div>
          </div>
        </div>
      </div>

      <div className="table-list-section p-6 rounded-l mt-6">
        <PageTableList selectedEnvId={selectedEnvId} date={date}></PageTableList>
        {
          isLoading && (
           <SectionLoader></SectionLoader>
          )
        }
      </div>

      <div className="section-box p-6 rounded-l mt-6 mb-6 page-trends hidden">
        <div className="flex justify-between align-center">
          <div className="flex align-center">
            <h2>Page Trends</h2>
            <svg
              className="ml-2"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433281 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 7.34783 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 17.5C8.51664 17.5 7.06659 17.0601 5.83323 16.236C4.59986 15.4119 3.63856 14.2406 3.07091 12.8701C2.50325 11.4997 2.35472 9.99168 2.64411 8.53682C2.9335 7.08196 3.64781 5.74559 4.6967 4.6967C5.7456 3.6478 7.08197 2.9335 8.53683 2.64411C9.99168 2.35472 11.4997 2.50325 12.8701 3.0709C14.2406 3.63856 15.4119 4.59985 16.236 5.83322C17.0601 7.06659 17.5 8.51664 17.5 10C17.5 11.9891 16.7098 13.8968 15.3033 15.3033C13.8968 16.7098 11.9891 17.5 10 17.5Z"
                fill="#869DBB"
                fillOpacity="0.7"
              />
              <path
                d="M10 8.75C9.66848 8.75 9.35054 8.8817 9.11612 9.11612C8.8817 9.35054 8.75 9.66848 8.75 10V13.75C8.75 14.0815 8.8817 14.3995 9.11612 14.6339C9.35054 14.8683 9.66848 15 10 15C10.3315 15 10.6495 14.8683 10.8839 14.6339C11.1183 14.3995 11.25 14.0815 11.25 13.75V10C11.25 9.66848 11.1183 9.35054 10.8839 9.11612C10.6495 8.8817 10.3315 8.75 10 8.75Z"
                fill="#869DBB"
                fillOpacity="0.7"
              />
              <path
                d="M10 7.1875C10.6904 7.1875 11.25 6.62786 11.25 5.9375C11.25 5.24714 10.6904 4.6875 10 4.6875C9.30964 4.6875 8.75 5.24714 8.75 5.9375C8.75 6.62786 9.30964 7.1875 10 7.1875Z"
                fill="#869DBB"
                fillOpacity="0.7"
              />
            </svg>
          </div>
          <div className="flex">
            <div className="mr-4">
              <span className="h5-heading para-label mr-2">From Date:</span>
              <input
                onChange={(e) =>
                  setDateFromElement(e.target.value, "rangeFromDate")
                }
                value={overviewData.rangeFromDate}
                className="input-date"
                type="date"
                name="lasttestrun"
                id="lasttestrun"
              />
            </div>
            <div>
              <span className="h5-heading para-label mr-2">To Date:</span>
              <input
                onChange={(e) =>
                  setDateFromElement(e.target.value, "rangeEndDate")
                }
                value={overviewData.rangeEndDate}
                className="input-date"
                type="date"
                name="lasttestrun"
                id="lasttestrun"
              />
            </div>
          </div>
        </div>

        <div className="mt-4 flex flex-layout">
          <div className="flex-layout__col35 pr-2">
            <div className="trend-box">
              <div className="flex justify-between align-center trend-box__head">
                <div className="py-2">Pages Tested on</div>
              </div>
            
            </div>
          </div>
          <div className="flex-layout__col65 pl-2">
          </div>
        </div>
      </div>
    </>
  );
}