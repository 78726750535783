export default (props) => {
    const { addNewJob, jobInProgress } = props;
    return (
        <a
            onClick={(e) => addNewJob(e)}
            href="#"
            className={
                (jobInProgress ? "disabled " : "") +
                "py-2 px-4 flex align-center no-underline edit-link-option"
            }
        >
            <svg
                className="svg-icon-l"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM9.98682 6.14618L17.5039 10.5534C17.6554 10.6418 17.7807 10.7661 17.8677 10.9144C17.9546 11.0627 18.0002 11.2299 18.0002 11.4C18.0002 11.5701 17.9546 11.7373 17.8677 11.8856C17.7807 12.0339 17.6554 12.1582 17.5039 12.2466L9.98682 16.6538C9.82918 16.7459 9.64886 16.7963 9.46427 16.7998C9.27968 16.8033 9.09743 16.7598 8.93612 16.6737C8.7748 16.5876 8.64021 16.4621 8.54607 16.3098C8.45192 16.1576 8.40161 15.9842 8.40025 15.8072V6.99281C8.40161 6.81585 8.45192 6.6424 8.54607 6.49017C8.64021 6.33794 8.7748 6.21238 8.93612 6.1263C9.09743 6.04022 9.27968 5.9967 9.46427 6.0002C9.64886 6.00369 9.82918 6.05406 9.98682 6.14618Z"
                />
            </svg>
            <span>Run</span>
        </a>
    )
}