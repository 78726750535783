import React, { useEffect, useState } from "react";
import { ChevronIcon } from "../ChevronIcon/ChevronIcon";

const PageHits = ({ onRegressionSelect, pageData, selectedSteps }) => {
  const { data = [] } = pageData || { data: [] };
  const [activeAction, setActiveAction] = useState(0);
  const [selectAllCheck, setSelectAllCheck] = useState(false);
  const [selectedStepList, setSelectedStepList] = useState([]);

  useEffect(() => {
    if (selectedSteps && JSON.stringify(selectedSteps) !== JSON.stringify(selectedStepList)) {
      setSelectedStepList(selectedSteps);
    }
  }, [selectedSteps]);

  const onRegressionChange = (e) => {
    setSelectAllCheck(e.target.checked);
    if (onRegressionSelect) {
      const obj = {
        regressionName: data[activeAction].page_name,
        regressionIndex: 0,
        pageId: data[activeAction].page_id || data[activeAction].page_name,
        steps: [{
          action: "OPEN_URL",
          url: data[activeAction].page_url,
          page_name: data[activeAction].page_name,
          stepName: "Open " + data[activeAction].page_name,
        }]
      };
      const steps = e.target.checked && data[activeAction]?.page_url ? [...selectedStepList, obj] : selectedStepList.filter(step => step?.pageId !== obj?.pageId);
      setSelectedStepList(steps);
      onRegressionSelect(steps);
    }
  }

  const switchPages = (index) => {
    setActiveAction(index);
    setSelectAllCheck(false);
    // onRegressionSelect && onRegressionSelect([]);
  }

  return (
    <div className="page-actions flex">
      <div className="page-actions-list w-50 useage-pulse-recom-modal">
        <div className="page-actions-list-info">
          <ul>
            {data.map((page, actionIndex) => {
              return (
                <li
                  key={actionIndex}
                  className={`pl-8 pr-2 pt-2 pb-3 ${actionIndex === activeAction ? "active" : ""}`}
                  onClick={() => switchPages(actionIndex)}
                >
                  <div className="flex justify-between align-center">
                    <p className="flex justify-between sub-heading-3 page-actions-list-name">
                      {page.page_name} 
                      {page.no_of_users != null ? ` (${page.no_of_users})`: ''}
                      {page.user_shift_percent != null ? ` (${page.shift == 'positive' ? '': '-'}${page.user_shift_percent}%)`: ''}
                      {(page.averagePageLoadTime != null && page.averagePageLoadTime != 0 ? ` (${page.averagePageLoadTime.toFixed(2)} secs)`: '')}
                    </p>
                    <ChevronIcon type="right" />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="page-actions-regressions w-50 useage-pulse-recom-modal">
        <div className="page-actions-container">
          <div className="pl-3 pr-6 py-4 justify-between align-center w-full page-actions-regressions-title">
            <p className="sub-heading-2">Select Automated Regression</p>
            <input type="checkbox" name="all" checked={!!selectedStepList.find(stepItem => stepItem.pageId === (data[activeAction]?.page_id || data[activeAction]?.page_name))} onChange={onRegressionChange} />
          </div>
          <div className="page-actions-regressions-list">
            {data[activeAction] ? (<ul>
              <li className="my-4 mx-3 p-3 justify-between align-center">
                <div className="w-70">
                  <p className="info-title pb-2 pr-4 sub-heading-3">
                    {data[activeAction].page_name}
                  </p>
                  {
                    data[activeAction].page_url && (
                      <p className="info-desc sub-heading-3 word-break-all">Page Url: {data[activeAction].page_url}</p>
                    )
                  }
                  {
                    data[activeAction].no_of_users != null && data[activeAction].no_of_users != 0 && (
                      <p className="info-desc sub-heading-3">{data[activeAction].no_of_users} clicks</p>
                    )
                  }
                   {
                    data[activeAction].user_shift_percent != null && data[activeAction].user_shift_percent != 0 && (
                      <p className="info-desc sub-heading-3">Changed: {data[activeAction].shift == 'positive' ? '' : '-'}{data[activeAction].user_shift_percent}%</p>
                    )
                  }
                </div>
                <input type="checkbox" checked={!!selectedStepList.find(stepItem => stepItem.pageId === (data[activeAction]?.page_id || data[activeAction]?.page_name))} onChange={onRegressionChange} />
              </li>
            </ul>) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHits;
