import { useState, useEffect } from "react"
import SvgCode from "../atoms/SvgCode"
import TreeViewItem from "./TreeView/TreeViewItem"
import storeService from "../../services/store.service"
import { ChevronIcon } from "../atoms/ChevronIcon/ChevronIcon"
const checkAndReturn = (valToCheck, prefix) => {
    if (valToCheck) {
        return prefix + valToCheck;
    }
    return "";
}; 
const getStepName = (step) => {
    if (step.actionType === "OPEN_URL" || step.page_title) {
        return (
            "Open " +
            (step.page_title || step.pageUrl.substr(step.pageUrl.lastIndexOf("/") + 1))
        );
    }
    if (step.actionType === "INPUT") {
        return (
            "Input " +
            step.elementValue +
            checkAndReturn(
                step.elementText || step.elementId || step.elementName,
                " in "
            )
        );
    }
    if (
        ["CHECKBOX", "RADIO", "SELECT", "MULTI_SELECT"].indexOf(step.actionType) >=
        0
    ) {
        return "Select " + step.elementValue + " as " + step.elementName;
    }
    if (["ANCHOR_CLICK", "BUTTON_CLICK"].indexOf(step.actionType) >= 0) {
        return (
            "Click " +
            (step.elementLinkText ||
                step.elementText ||
                step.elementId ||
                step.elementName ||
                step.actionType)
        );
    }
    return step.pageName || step.actionType;
};
export default (props) => {
    const [testCases, settestCases] = useState([])
    const [editTestcases, seteditTestcases] = useState({})
    const [tree, setTree] = useState({})
    const [isEdit, setisEdit] = useState(false)
    const [selectedLevel, setselectedLevel] = useState('ALL')
    useEffect(() => {
        if (props.tree) {
            setTree(props.tree);
        }
    }, [props.tree])
    useEffect(() => {
        if (testCases.length != props.selectedSteps) {
            settestCases(props.selectedSteps.map(x => {
                return {
                    name: x.displayPath,
                    displayPath: x.displayPath,
                    path: x.path
                }
            }))
        }
    }, [props.selectedSteps])
    const handleAddTestcase = (path) => {
        let allPaths = path.split('=>');
        let displayPath = allPaths.map(x => {
            const [name, url, actionType, elementId, elementLink, elementLinkText, elementName, elementXPath, elementValue] = x.split('||');
            let stepName = actionType == 'OPEN' ? `${name}` : getStepName({name, url, actionType, elementId, elementLink, elementLinkText, elementName, elementXPath, elementValue});
            return stepName;
        }).join(' -> ')
        let testcaseToAdd = {
            name: displayPath,
            displayPath,
            path
        }
        if (!testCases.find(x => x.path == path)) {
            let updatedList = [...testCases, testcaseToAdd];
            settestCases(updatedList)
            props.onRegressionSelect && props.onRegressionSelect(updatedList.map(x => {
                return {
                    path: x.path,
                    regressionName: x.name,
                    displayPath: x.displayPath,
                    steps: x.path.split('=>').map(p => {
                        const [name, url, actionType, elementId, elementLink, elementLinkText, elementName, elementXPath, elementValue] = p.split('||')
                        return {
                            action: x.page_title ? 'OPEN_URL' : actionType,
                            url,
                            page_name: name,
                            stepName: getStepName({name, url, actionType, elementId, elementLink, elementLinkText, elementName, elementXPath, elementValue}),
                
                            element: elementName,
                            elementId: elementId,
                            xPath: elementXPath,
                
                            value: elementValue,
                        }
                    })
                }
            }));
        }
    }
    const handleDeleteAll = () => {
        settestCases([])
    }
    const handleEdit = () => {
        testCases.forEach(x => {
            editTestcases[x.path] = x.name;
        })
        editTestcases
        setisEdit(true)
    }
    const handleSave = () => {
        testCases.forEach(x => {
            x.name = editTestcases[x.path] || x.name;
        })
        props.onRegressionSelect && props.onRegressionSelect(testCases.map(x => {
            return {
                path: x.path,
                regressionName: x.name,
                displayPath: x.displayPath,
                steps: x.path.split('=>').map(p => {
                    const [name, url] = p.split('||')
                    return {
                        action: 'OPEN_URL',
                        page_name: name,
                        stepName: 'Open ' + name,
                        url
                    }
                })
            }
        }));
        settestCases([...testCases])
        setisEdit(false)
    }
    const handleDelete = (e, item) => {
        e.preventDefault();
        const updatedList = testCases.filter(x => x.path != item.path);
        settestCases([...updatedList])
        props.onRegressionSelect && props.onRegressionSelect(updatedList.map(x => {
            return {
                path: x.path,
                regressionName: x.name,
                displayPath: x.displayPath,
                steps: x.path.split('=>').map(p => {
                    const [name, url] = p.split('||')
                    return {
                        action: 'OPEN_URL',
                        page_name: name,
                        stepName: 'Open ' + name,
                        url
                    }
                })
            }
        }));
    }
    const handleNameChange = (e, path) => {
        const val = e.target.value;
        editTestcases[path] = val;
        seteditTestcases({ ...editTestcases })
    }
    const handleToggleExpandAll = (isExpandAll) => {
        storeService.pub('TREE_EXPAND_ALL', isExpandAll)
    }
    const handleLevelChange = (e) => {
        const val = e.target.value;
        setselectedLevel(val)
        props.handleLevelChange && props.handleLevelChange(val)
    }
    return (
        <div className="tree-view-wrapper">
            <div className="tree-accordion">
                <div className="tree-accordion-header top-sticky-scroll bg-white align-center justify-between">
                    <h3>Count : {props.pathCount}</h3>
                    <div className="flex align-center">
                        <div className="form-field form-field--select align-center mr-4 w-100px">
                            <select className="select-w-full" value={selectedLevel} onChange={handleLevelChange}>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                                <option value={6}>6</option>
                                <option value={7}>7</option>
                                <option value={8}>8</option>
                                <option value={9}>9</option>
                                <option value={10}>10</option>
                                <option value={'ALL'}>All</option>
                            </select>
                            <ChevronIcon type="down" />
                        </div>
                        <button onClick={e => handleToggleExpandAll(true)}
                            className="tree-accordion-button--expand white-space-no-wrap"
                        >
                            Expand all
                        </button>
                        <button onClick={e => handleToggleExpandAll(false)}
                            className="tree-accordion-button--collapse white-space-no-wrap"
                        >
                            Collapse all
                        </button>
                    </div>
                </div>
                <div className="tree-path-wrapper">
                    {
                        tree.next && tree.next.length > 0 ? (
                            tree.next.map((item, idx) => {
                                return (<TreeViewItem handleAddTestcase={handleAddTestcase} tree={item} first={idx === 0} level={0} />);
                            })
                        ) : (
                            <div className="flex justify-center">No items to display</div>
                        )
                    }
                </div>
            </div>
            <div className="test-case-wrapper">
                <div className="test-case-header top-sticky-scroll">
                    <h3>Test Cases</h3>
                    {
                        testCases.length > 0 && (
                            <>
                                {
                                    isEdit ? (
                                        <>
                                            <button onClick={handleSave} className="test-case-button--save">Save</button>
                                            <button onClick={handleSave} className="test-case-button--save">Cancel</button>
                                        </>
                                    ) : (
                                        <button onClick={handleEdit} className="test-case-button--edit">Edit</button>
                                    )
                                }
                                <button onClick={handleDeleteAll} className="test-case-button--remove">Remove</button>
                            </>
                        )
                    }
                </div>
                <div className={`test-case-list ${testCases.length === 0 ? "test-case-list--empty" : ""}`}>
                    {testCases.length === 0 ? (
                        <p>Add from the list to view test cases</p>
                    ) : (
                        testCases.map((testcase, index) => (
                            <div key={index} className="test-case-item flex">
                                {
                                    isEdit ? (
                                        <div className="flex-1">
                                            <input value={editTestcases[testcase.path]} onChange={e => { handleNameChange(e, testcase.path) }} className="edit-testcase-input input-txt" type="text" />
                                        </div>
                                    ) : (
                                        <span className="flex-1 mr-5 word-break-all align-center">{testcase.name}</span>
                                    )
                                }
                                <a href="#" onClick={e => { handleDelete(e, testcase) }} className="ml-2 py-1 flex align-center no-underline edit-link-option">
                                    <SvgCode name="delete"></SvgCode>
                                </a>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    )
}