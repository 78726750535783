import utilsService from "./utils.service";
const getJobStatus = (recentjob) => {
    let differences = [],
        lastStatus = '-',
        textStatus = '-',
        imageStatus = '-',
        layoutStatus = '-',
        bestPracticesStatus = '-',
        differencesStatus = '-',
        finalStatus = '-'
        differencesCount = {
            text: 0,
            image: 0,
            layout: 0
        },
        bestPractices = [],
        failedPriority = null;
        insights = [];

    if (recentjob.screentest) {
        failedPriority = recentjob.screentest.priority;
        finalStatus = recentjob.screentest.finalStatus;
        
        if (recentjob.screentest.bestPracticesList) {
            bestPracticesStatus = recentjob.screentest.bestpracticesStatus;
            bestPractices = recentjob.screentest.bestPracticesList;
            insights = recentjob.screentest.bestPracticesList?.insights;
        }
        if (recentjob.screentest.imageComparisionList) {
            let comparisions = recentjob.screentest.imageComparisionList;
            differences = comparisions.Differences;
            if (recentjob.screentest.comparisionVersion != 2) {
                if (differences && differences.length > 0) {
                    let textChange = differences.filter(x => x.questions.find(y => (y.question_text == 'Content Change' || y.question_text == 'Content Changes') && y.Changed == 'Yes'));
                    let imageChange = differences.filter(x => x.questions.find(y => y.question_text == 'Content Formatting' && y.Changed == 'Yes'));
                    let layoutChange = differences.filter(x => x.questions.find(y => y.question_text == 'Content Location' && y.Changed == 'Yes'));
                    finalStatus = recentjob.screentest.ComparisonStatus || 'Failed';
                    textStatus = textChange.length > 0 ? 'Failed' : 'Passed';
                    imageStatus = imageChange.length > 0 ? 'Failed' : 'Passed';
                    layoutStatus = layoutChange.length > 0 ? 'Failed' : 'Passed';
                    differencesCount = {
                        text: textChange.length,
                        image: imageChange.length,
                        layout: layoutChange.length
                    }
                } else {
                    finalStatus = recentjob.screentest.ComparisonStatus || 'Passed';
                    textStatus = 'Passed';
                    imageStatus = 'Passed';
                    layoutStatus = 'Passed';
                }
            } else {
                if (differences && differences instanceof Array && differences.length > 0) {
                    finalStatus = recentjob.screentest.finalStatus || 'Failed';
                    differencesStatus = recentjob.screentest.ComparisonStatus || 'Failed';
                    let textChangeLen = differences.filter(x => x.type == 'text or image').length;
                    let layoutChangeLen = differences.filter(x => x.type == 'formatting').length;
                    textStatus = textChangeLen > 0 ? 'Failed' : 'Passed';
                    imageStatus = layoutChangeLen > 0 ? 'Failed' : 'Passed';
                    layoutStatus = '-';
                    differencesCount = {
                        text: textChangeLen,
                        image: layoutChangeLen,
                        layout: 0
                    }
                } else {
                    finalStatus = recentjob.screentest.finalStatus || 'Failed';
                    differencesStatus = recentjob.screentest.ComparisonStatus || 'Failed';
                    differencesCount = {
                        text: 0,
                        image: 0,
                        layout: 0
                    }
                }
            }
        } else {
            if (recentjob.screentest.status == 'IN_PROGRESS') {
                finalStatus = 'Analyzing';
            }
            if (recentjob.screentest.status == 'FAILED') {
                finalStatus = 'Failed';
            }
            if (recentjob.screentest.status == 'READY') {
                finalStatus = 'Waiting';
            }
        }
    } else {
        if (!(recentjob.status == 'DONE' || recentjob.status == 'FAILED')) {
            finalStatus = 'Running';
        }
        if (recentjob.status == 'FAILED') {
            finalStatus = 'Failed';
            failedPriority = recentjob.priority;
        }
        if (recentjob.status == 'IN_PROGRESS') {
            finalStatus = 'Running';
        }
    }
    return {
        layoutStatus,
        imageStatus,
        textStatus,
        lastStatus,
        differences,
        status: finalStatus,
        differencesCount,
        bestPractices,
        insights,
        bestPracticesStatus,
        differencesStatus,
        finalStatus,
        failedPriority
    }
}
const getObjectWithProps = (object, props) => {
    let obj = {};
    props.forEach(prop => {
        let objValue = object[prop];
        if (objValue) {
            obj[prop] = objValue;
        }
    });
    return obj;
};
const getPageJobStatus = (page) => {
    let notyetRun = 0,
        deviceCount = 0,
        passed = 0,
        failed = 0;
    [...page.physicalDevices, ...page.virtualDevices].forEach(device => {
        if (device.jobs && device.jobs.length > 0) {
            let recentjob = device.jobs[0];
            let recentJobStatus = getJobStatus(recentjob);
            if (recentJobStatus.status == 'Failed') {
                failed += 1;
            } else if (recentJobStatus.status == 'Passed') {
                passed += 1;
            } else {
                notyetRun += 1;
            }
        } else {
            notyetRun += 1;
        }
        deviceCount += 1;
    });
    return {
        pageStatus: failed > 0 ? 'Failed' : passed > 0 ? 'Passed' : 'Not Run',
        deviceCount,
        notyetRun,
        passed,
        failed
    };
};
export default {
    isDevicePassed(device) {
    },
    getAllDeviceStatus(pages) {
        let devices = [];
        let allDeviceStatus = [];
        pages.forEach(page => {
            [...page.physicalDevices, ...page.virtualDevices].forEach(device => {
                if (!devices.find(x => x._id == device._id)) {
                    devices.push(getObjectWithProps(device, utilsService.ALLOWED_PROPS.DEVICE_PROPS));
                }
                if (device.jobs && device.jobs.length > 0) {
                    let recentjob = device.jobs[0];
                    let recentJobStatus = getJobStatus(recentjob);
                    allDeviceStatus.push({
                        _id: device._id,
                        deviceGlass: device.deviceGlass,
                        name: device.name,
                        deviceMode: device.deviceMode,
                        jobStatus: recentJobStatus.status
                    })
                } else {
                    allDeviceStatus.push({
                        _id: device._id,
                        deviceGlass: device.deviceGlass,
                        name: device.name,
                        deviceMode: device.deviceMode,
                        jobStatus: 'Not yet'
                    })
                }
            });
        });
        let desktopHashMap = {};
        allDeviceStatus.filter(x => x.deviceGlass == 'DESKTOP').forEach(x => {
            if (!desktopHashMap[x._id]) {
                desktopHashMap[x._id] = [];
            }
            desktopHashMap[x._id].push(x.jobStatus);
        });
        return {
            desktopRunCount: devices.filter(x => x.deviceGlass == 'DESKTOP').length,
            mobileRunCount: devices.filter(x => x.deviceGlass == 'MOBILE').length,
            mobileFailCount: allDeviceStatus.filter(x => x.jobStatus == 'Failed' && x.deviceGlass == 'MOBILE').length,
            desktopFailCount: Object.keys(desktopHashMap).filter(x => desktopHashMap[x].find(y => y == 'Failed')).length,
        }
    },
    getPageJobStatus,
    getPagesStatus(pages) {
        let failedPageCount = 0;
        pages.forEach(page => {
            let { pageStatus } = getPageJobStatus(page);
            if (pageStatus == 'Failed') {
                failedPageCount += 1;
            }
        });
        return {
            failedPageCount
        }
    },
    getJobStatus
}