import React, { useState, useEffect } from "react";
import AppIcon from "./AppIcon/AppIcon.jsx";
import storeService from "../../services/store.service.js";
import utilsService from "../../services/utils.service.js";
import dataService from "../../services/data.service.js";
import apiService from "../../services/api.service.js";
import AppModal from "../organisms/AppModal.jsx";
import ExcelDownload from "./ExcelDownload.jsx";
import ConfirmModal from "./ConfirmModal.jsx";
import * as XLSX from "xlsx";
import TablePagination from "../molecules/TablePagination.jsx";

export default () => {
  const [apiData, setApiData] = useState([]);
  const [openFileUploadModal, setOpenFileUploadModal] = useState(false);
  const [apiInfo, setApiInfo] = useState({ name: '', description: ''});
  const [hasFileError, setFileError] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [dataToDelete, setDataToDelete] = useState('');
  const [fileName, setFileName] = useState('');
  const [paginationData, setPaginationData] = useState({
    pageNo: 1,
    pageSize: 5
  })
  const [paginationList, setPaginationList] = useState([])

  useEffect(() => {
    let newList = [];
    let filterLen = apiData.length;
    let start = (((paginationData.pageNo - 1) * paginationData.pageSize) + (1))
    let lastItem = ((paginationData.pageNo - 1) * paginationData.pageSize) + (paginationData.pageSize);
    let end = (lastItem < filterLen ? lastItem : filterLen)
    for (let i = start - 1; i < end; i++) {
      newList.push(apiData[i])
    }
    setPaginationList(newList)
  }, [paginationData.pageSize, paginationData.pageNo, apiData]);

  const handlePaginationChange = (data) => {
    if (data.perPage) {
      setPaginationData({
        ...paginationData,
        pageSize: data.perPage,
        pageNo: 1
      })
    }
    if (data.pageNo) {
      setPaginationData({
        ...paginationData,
        pageNo: data.pageNo
      })
    }
  }
  
  
  const hasValidKeys = excelData => {
    const requiredAPIKeys = ['test_param'];
    return excelData.every(obj => requiredAPIKeys.every(key => key in obj));
 };
  
  const sampleAPIData = [
    {
      test_param: "122",
      user_id: "user_id2",
      movieShow_ID: 5,
      available_seats: 250,
      ticket_price: 20,
    },
    {
      test_param: "123",
      user_id: "user_id1",
      movieShow_ID: 3,
      available_seats: 150,
      ticket_price: 10,
    },
    {
      test_param: "124",
      user_id: "user_id3",
      movieShow_ID: 4,
      available_seats: 200,
      ticket_price: 30,
    },
    {
      test_param: "125",
      user_id: "user_id4",
      movieShow_ID: 2,
      available_seats: 76,
      ticket_price: 40,
    },
  ];

  const formatTimestamp = (timestamp) => {
    const dateObj = new Date(timestamp);

    // Extract date components
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1; // Months are zero-indexed, so we add 1
    const year = dateObj.getFullYear();

    // Extract time components
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();

    // Format the date and time
    const formattedDate = `${day < 10 ? "0" + day : day}/${
      month < 10 ? "0" + month : month
    }/${year} at ${hours}:${minutes}`;

    return formattedDate;
  };

  const getAPIGlideData = async () => {
    const apiDataArray = await apiService.get({
      url: utilsService.END_POINTS.getApiData,
    });
    const { data = [] } = apiDataArray;
    data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    setApiData(data);
  };

  useEffect(() => {
    getAPIGlideData();
  }, []);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name); // Set the file name to state
    }
    const reader = new FileReader();
    reader.onload = async (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      let testData = [];
      workbook.SheetNames.forEach((sheetName) => {
        testData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          raw: false,
          dateNF: 'yyyy-mm-dd'
        });
      });
      const [{ _id = "" } = {}] = apiData;
      if (hasValidKeys(testData)) {
        setFileError(false);
        await dataService.addApiData({
          testData: testData,
          name: apiInfo.name,
          description: apiInfo.description,
          id: _id,
        });
        getAPIGlideData();
      } else {
        setFileError(true);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const closeSettingsModal = () => {
    storeService.pub(
      utilsService.CONSTANTS.EVENT_NAME.OPEN_SETTINGS_MODAL,
      false
    );
  };

  const deleteTestData = async () => {
    await dataService.deleteApiData({
      id: dataToDelete
    });
    setShowConfirmModal(false);
    getAPIGlideData();
  };

  const handleDelete = (dataID) => {
    setDataToDelete(dataID);
    setShowConfirmModal(true);
  }

  const closeFileUploadModal = () => {
    setOpenFileUploadModal(false);
    setFileName("");
    setFileError(false);
  };


  const formatDataForDownload = data => {
    let resultArray = [];
    for(item in data){
      const { testdata = {} } = data;
      resultArray = Object.keys(testdata).map(key => {
        return {
            test_param: key,
            ...testdata[key]
        };
      });
    };
    return resultArray;
}

  return (
    <div className="app-side-modal show settings-screen">
      <div className="app-side-modal__bg"></div>
      <div className="app-side-modal__content p-4">
        <div className="align-center p-4 modal-head">
          <button
            onClick={closeSettingsModal}
            className="bg-none no-border mr-4"
          >
            <AppIcon iconName="closeIcon" iconColor="#1B72E6" />
          </button>
          <h3 className="font-urbanist line-height-140">APIGlide Test Data Upload</h3>
        </div>
        <div className="p-2 model-main-content">
          <div>
            <div className="api-settings_modal">
              <div className="api-settings_modal_content w-full text-center">
                  {apiData?.length === 0 ?
                  <>
                    <h4>Configure Environments</h4>
                    <p>
                      To configure the environment, upload the details in a
                      spreadsheet.
                    </p>

                    <div className={"mt-6"}>
                      <div className="text-center">
                        <button type="button" onClick={() => setOpenFileUploadModal(true)} className="btn-medium-2">Choose File</button>
                      </div>
                      <ExcelDownload data={sampleAPIData} title="Download sample data" additionalClass="mt-4"/>
                    </div>
                  </> : null }
               

                {apiData?.length > 0 ? (
                  <div className="p-1">
                    <div className="flex align-center form-field justify-between">
                      <h2 className="mr-1">Test Data</h2>
                      <div className="flex align-center justify-between">
                        <button onClick={() => setOpenFileUploadModal(true)} type="button" className="btn-medium-2">
                            <AppIcon iconName="uploadIcon" iconColor="#fff"/>
                            <span className="ml-2">Upload Test Data</span>
                         </button>
                         <ExcelDownload data={sampleAPIData} title="Download sample data" additionalClass=""/>
                      </div>
                    </div>
                    <table className="app-table my-6">
                      <tbody>
                        <tr className="tr-head">
                          <td>
                            <div className="align-center">
                              <span className="mr-1">No</span>
                              <AppIcon iconName="filterIcon" />
                            </div>
                          </td>
                          <td>
                            <div className="align-center">
                              <span className="mr-1">Test Data</span>
                              <AppIcon iconName="filterIcon" />
                            </div>
                          </td>
                          <td>
                            <div className="align-center">
                              <span className="mr-1">Uploaded on</span>
                              <AppIcon iconName="filterIcon" />
                            </div>
                          </td>
                        </tr>
                        {paginationList && paginationList.map((data, rowIndex) => (
                          <tr key={rowIndex}>
                            <td className="px-0">{(((paginationData.pageNo - 1) * paginationData.pageSize) + (1)) + rowIndex}</td>
                            <td className="px-0">
                              <div className="flex align-center justify-between">
                                <p>{data?.name}</p>
                                <div className="flex justify-between align-center">
                                  <ExcelDownload data={formatDataForDownload(data)} fileName={`data_${data?.name}.xlsx`} />
                                  <button
                                    type="button"
                                    className="p-2 bg-none no-border"
                                    onClick={() => handleDelete(data._id)}
                                  >
                                    <AppIcon
                                      iconName="delete"
                                      iconColor="#1B72E6"
                                    />
                                  </button>
                                </div>
                              </div>
                            </td>
                            <td className="px-0 text-left">
                              {formatTimestamp(data?.createdAt)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {
                      apiData?.length > 0 ? (
                        <TablePagination onChange={handlePaginationChange} pageNo={paginationData.pageNo} pageSize={paginationData.pageSize} totalSize={apiData.length}></TablePagination>
                      ) : null
                    }
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {
          openFileUploadModal? (
        
        <AppModal>
          <div className="p-4">
            <div className="justify-between align-center mb-4">
                  <div className="align-center">
                      <h2 className="h2-heading">Add Test Data</h2>
                  </div>
                  <button type="button" onClick={() => closeFileUploadModal()} className="bg-none no-border">
                      <AppIcon iconName="closeIcon" iconColor="#1B72E6" />
                  </button>
            </div>
            <div className="form-field">
              <label className="label" htmlFor="name">Name:*</label>
              <input 
                className="input-txt" 
                type="text" 
                id="name" 
                name="name" 
                onChange={(event) => setApiInfo({...apiInfo, name : event.target.value})}
                required />
            </div>
            <div className="form-field mt-4">
              <label className="label" htmlFor="description">Description: *</label>
              <textarea rows="8"
                className="text-area" 
                onChange={(event) => setApiInfo({...apiInfo, description : event.target.value})}
                id="description" name="description" required></textarea>
            </div>
            <p>Please fill the required fields to upload the test data.</p>
            <div className="text-center">
                  <label
                    htmlFor="file-upload"
                    className={`custom-file-upload p-2 no-border ${apiInfo.description.length === 0 || apiInfo.name.length === 0 ? 'disabled' : ''}`}
                  >
                    <span className="ml-2">Upload CSV</span>
                  </label>
                  <input
                    id="file-upload"
                    className="file-upload"
                    type="file"
                    required
                    onChange={(event) => handleFileUpload(event)}
                  />
              </div>
              <div className="text-center font-bold mt-4">
                {hasFileError && <p className="font-danger bold mb-4">Please verify that the data is in the correct format. Download the sample data below for reference</p>}
                {fileName.length > 0 ? `Selected file is ${fileName}` : 'No file selected' }
              </div>
              <div className="mt-4">
                <button type="button" onClick={() => closeFileUploadModal()} className="btn-medium-2">Submit</button>
              </div>
          </div>
        </AppModal> ) : null }
        {showConfirmModal && <ConfirmModal onDelete={deleteTestData} onClose={() => setShowConfirmModal(false)}/>}
      </div>
    </div>
  );
};