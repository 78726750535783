import React from "react";
import { Link, useLocation } from "react-router-dom";
import AppIcon from "../atoms/AppIcon";
import { Tooltip } from 'react-tooltip';
const LeftNav = () => {
  const { pathname = '' } = useLocation();
  return (
    <nav className="nav-left-ribbon left-nav">
      <ul>
        <li className="text-center ">
          <Link to="/">
            <img src="/icons/logo.svg" />
          </Link>
        </li>
        <li className="mt-12 home-logo">
          <Link to="/" className={pathname === "/" ? "active" : ""}>
            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Nav-Home-unselected">
              <g id="Nav Home">
              <path className="fill-color" id="Subtract" fill-rule="evenodd" clip-rule="evenodd" d="M10.2858 20.2857V33.1428C10.2858 34.3263 11.2452 35.2857 12.4286 35.2857L17.143 35.2857V25.8571H24.8573V35.2857L29.5715 35.2857C30.755 35.2857 31.7143 34.3263 31.7143 33.1428V20.2857L21.0001 9.57141L10.2858 20.2857Z" fill="#5E7FAD"/>
              <path className="stroke-color" id="Vector 1 (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M19.4848 7.62762C20.3216 6.79079 21.6784 6.79079 22.5153 7.62762L35.3724 20.4848C36.2092 21.3216 36.2092 22.6784 35.3724 23.5152C34.5356 24.3521 33.1788 24.3521 32.342 23.5152L21 12.1733L9.6581 23.5152C8.82125 24.3521 7.46447 24.3521 6.62763 23.5152C5.79079 22.6784 5.79079 21.3216 6.62763 20.4848L19.4848 7.62762Z" fill="#C2D3EC"/>
              </g>
              </g>
            </svg>
          </Link>
        </li>
        <li id="usageregression" className="usage-regression-icon">
          <Link
            to={`/usage-regression`}
            className={pathname.includes("usage-regression") ? "active" : ""}
          >
            <AppIcon
              iconName="usageplus"
              iconColor={
                pathname.includes("usage-regression") ? "#185cb9" : "#889AB1"
              }
            /> 
          </Link>
          <Tooltip
            anchorSelect="#usageregression"
            content="Usage Pulse"
            place="right"
            style={{
              backgroundColor: "#000",
              color: "#ffffff",
              borderRadius: "5px",
              zIndex: 22,
              fontWeight: "bold",
            }}
          />
        </li>
        <li id="sightsense" className="sight-sense-icon">
          <Link
            to={`/ui-regression`}
            className={pathname.includes("ui-regression") ? "active" : ""}
          >
            <AppIcon
              iconName="sightsense"
              iconColor={
                pathname.includes("ui-regression") ? "#185cb9" : "#889AB1"
              }
            />
          </Link>
          <Tooltip
            anchorSelect="#sightsense"
            content="Sight Sense"
            place="right"
            style={{
              backgroundColor: "#000",
              color: "#ffffff",
              borderRadius: "5px",
              zIndex: 22,
              fontWeight: "bold",
            }}
          />
        </li>
        <li id="apiguide" className="api-guide-icon">
          <Link
            to={`/api-regression`}
            className={pathname.includes("api-regression") ? "active" : ""}
          >
            <AppIcon
              iconName="apiguide"
              iconColor={
                pathname.includes("api-regression") ? "#185cb9" : "#889AB1"
              }
            />
          </Link>
          <Tooltip
            anchorSelect="#apiguide"
            content="API Glide"
            place="right"
            style={{
              backgroundColor: "#000",
              color: "#ffffff",
              borderRadius: "5px",
              zIndex: 22,
              fontWeight: "bold",
            }}
          />
        </li>

        <li id="pageaction" className="page-action-icon">
          <Link
            to={`/page-action`}
            className={pathname.includes("page-action") ? "active" : ""}
          >
            <AppIcon
              iconName="pageaction"
              iconColor={
                pathname.includes("page-action") ? "#185cb9" : "#889AB1"
              }
            />
          </Link>
          <Tooltip
            anchorSelect="#pageaction"
            content="Page Blazer"
            place="right"
            style={{
              backgroundColor: "#000",
              color: "#ffffff",
              borderRadius: "5px",
              zIndex: "22",
              fontWeight: "bold",
            }}
          />
        </li>
      </ul>
    </nav>
  );
};
export default LeftNav;
