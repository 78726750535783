import ToolTipV2 from "../atoms/ToolTipV2"
import AppIcon from "../atoms/AppIcon/AppIcon.jsx";
export default ({ step }) => {
    if (!step) {
        return null;
    }
    if (
        !(
            step.enableMouseClick ||
            step.stepSkipThis ||
            step.skipScreenshot ||
            step.stepTimeout ||
            step.enforceStepTimeout
        )
    ) {
        return (
            <div>
                No configs
            </div>
        )
    }
    return (
        <>
            {
                step.enableMouseClick && (
                    <ToolTipV2 toolTipContent={'Mouse click enabled for this step action'}>
                        <AppIcon iconName="mouseclick" iconColor="#1B72E6" />
                    </ToolTipV2>
                )
            }
            {
                (step.stepSkipThis || step.skipScreenshot) && (
                    <>
                        {
                            step.stepSkipThis ? (
                                <ToolTipV2 toolTipContent={'This step is skipped during execution'}>
                                    <AppIcon iconName="skipstep" iconColor="#1B72E6" />
                                </ToolTipV2>
                            ) : (
                                <ToolTipV2 toolTipContent={'Screenshot is skipped for this step'}>
                                    <AppIcon iconName="skipscreenshot" iconColor="#1B72E6" />
                                </ToolTipV2>
                            )
                        }
                    </>
                )
            }
            {
                (step.stepTimeout || step.enforceStepTimeout) && (
                    <>
                        {
                            step.enforceStepTimeout ? (
                                <ToolTipV2 toolTipContent={`Execution must waits for ${step?.stepTimeout || '10'} sec(s) plus execution handover seconds.`}>
                                    <AppIcon iconName="enforcesteptimeout" iconColor="#1B72E6" />
                                </ToolTipV2>
                            ) : (
                                <ToolTipV2 toolTipContent={`Execution will wait for ${step?.stepTimeout || '10'} sec(s) or page load which ever comes first.`}>
                                    <AppIcon iconName="steptimeout" iconColor="#1B72E6" />
                                </ToolTipV2>
                            )
                        }
                    </>
                )
            }
        </>
    )
}