import React, { useRef, useState, useEffect } from "react";

const StackedBarChart = ({ minheight, data, mode }) => {
  const stackRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [selectedDevice, setSelectedDevice] = useState('DESKTOP');
  const [showToolTip, toggleToolTip] = useState(false);
  const [tooltipStyle, setTooltipStyle] = useState({ x: 0, y: 0, data: {}})

  const handleResize = () => {
    const { offsetWidth } = stackRef.current;
    setWidth(parseInt(offsetWidth - 48));
  };

  let dataToShow = [
    { total: 43 +57, pass: 43, fail: 57, date: "1/2" },
    { total: 42 +59, pass: 42, fail: 59, date: "1/3" },
    { total: 41 +59, pass: 41, fail: 59, date: "1/4" },
    { total: 50 +50, pass: 50, fail: 50, date: "1/5" },
    { total: 33 +67, pass: 33, fail: 67, date: "1/8" },
    { total: 75 +25, pass: 75, fail: 25, date: "1/11" },
    { total: 69 +31, pass: 69, fail: 31, date: "1/12" },
    { total: 46 +54, pass: 46, fail: 54, date: "1/16" },
    { total: 87 +13, pass: 87, fail: 13, date: "1/17" },
    { total: 77 +23, pass: 77, fail: 23, date: "1/22" },
    { total: 86 +14, pass: 86, fail: 14, date: "1/23" },
    { total: 77 +23, pass: 77, fail: 23, date: "1/24" },
    { total: 78 +22, pass: 78, fail: 22, date: "1/25" },
    { total: 82 +18, pass: 82, fail: 18, date: "1/26" },
    { total: 80 +20, pass: 80, fail: 20, date: "1/29" },
    { total: 85 +15, pass: 85, fail: 15, date: "1/30" },
    { total: 75 +25, pass: 75, fail: 25, date: "1/31" },
  ];
  if (selectedDevice == 'MOBILE') {
    dataToShow = [
      { total: 42+58, pass: 42, fail: 58, date: "1/2" },
      { total: 28+72, pass: 28, fail: 72, date: "1/3" },
      { total: 34+66, pass: 34, fail: 66, date: "1/4" },
      { total: 35+65, pass: 35, fail: 65, date: "1/8" },
      { total: 53+47, pass: 53, fail: 47, date: "1/11" },
      { total: 60+40, pass: 60, fail: 40, date: "1/12" },
      { total: 90+10, pass: 90, fail: 10, date: "1/17" },
      { total: 86+13, pass: 86, fail: 13, date: "1/22" },
      { total: 85+15, pass: 85, fail: 15, date: "1/24" },
      { total: 83+17, pass: 83, fail: 17, date: "1/25" },
      { total: 90+10, pass: 90, fail: 10, date: "1/26" },
      { total: 87+13, pass: 87, fail: 13, date: "1/29" },
      { total: 84+16, pass: 84, fail: 16, date: "1/30" },
      { total: 77+23, pass: 77, fail: 23, date: "1/31" },
    ];
  }
  if(data) {
    dataToShow = data;
  }
  useEffect(() => {
    const { offsetWidth } = stackRef.current;
    // deduct the padding of the container 48px
    setWidth(parseInt(offsetWidth - 48));
    // Resize event to udpate the svg dimensions
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMouseEnter = (position, data) => {
    setTooltipStyle({
      x: position + 10,
      y: 100,
      data: data
    })
    toggleToolTip(true);
  }

  const renderTooltip = () => {
      return (
        <div className="trends_svg_tooltip flex direction-column px-1">
          {Object.entries(tooltipStyle.data).map(([key, value]) => (
            <div
              key={key}
              className={`align-center justify-between p-1 ${key}`}
            >
              {key !== 'date' ? <div>{key.toUpperCase()}</div> : null}
              <div className="flex align-center">
                <p className="pr-1">{value}{key == 'date' ? '/2023' : ''}</p>
                {key !== "date" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 18"
                    fill="none"
                  >
                    <path
                      d="M2.91958 0.34627L10.743 8.16973C10.8525 8.27876 10.9393 8.40831 10.9985 8.55095C11.0577 8.69359 11.0882 8.84653 11.0882 9.00098C11.0882 9.15543 11.0577 9.30836 10.9985 9.45101C10.9393 9.59365 10.8525 9.7232 10.743 9.83222L2.91958 17.6557C2.69912 17.8761 2.40011 18 2.08834 18C1.77656 18 1.47755 17.8761 1.25709 17.6557C1.03663 17.4352 0.912782 17.1362 0.912782 16.8244C0.912782 16.5127 1.03663 16.2137 1.25709 15.9932L8.2503 9L1.25612 2.0068C1.03566 1.78634 0.911803 1.48734 0.911803 1.17556C0.911803 0.863781 1.03566 0.564774 1.25612 0.344315C1.47658 0.123856 1.77558 -3.7757e-08 2.08736 -5.13852e-08C2.39914 -6.50134e-08 2.69814 0.123856 2.9186 0.344314L2.91958 0.34627Z"
                      fill="#1B72E6"
                    ></path>
                  </svg>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      );
  }
  

  

  const yAxisLabels = [100, 80, 60, 40, 20, 0, 20, 40, 60, 80, 100];
  const getpassPercentages = () => {
    return dataToShow.map((entry) => entry.pass / entry.total);
  };

  const getPositions = (numPoints) => {
    const spacing = (width - 20) / numPoints;
    const pointers = [];
    for (let i = 0; i < numPoints; i++) {
      let position = parseInt(i * spacing) + 60;
      pointers.push(position);
    }
    return pointers;
  };

  const handlePathHover = (index) => {
    // Handle hover event, e.g., update state, show tooltip, etc.
  };

  const calculateYPositions = () => {
    const numPoints = 11;
    const rangeStart = 0;
    const rangeEnd = 308;
  
    const step = (rangeEnd - rangeStart) / (numPoints - 1);
    const yPositions = Array.from({ length: numPoints }, (_, index) =>
      Math.round(rangeStart + index * step)
    );
    return yPositions;
  };

  const passPercentages = getpassPercentages();
  const YAxisPositions = calculateYPositions();
  const XAxisPositions = getPositions(dataToShow.length);
  const midPoint = 140;

  return (
    <div className="trend-box" ref={stackRef}>
      <div className="flex justify-between align-center trend-box__head">
        <div className="trend-box__title flex justify-between align-center">
          <p>
            Pages Tested on <span className="pass"></span>
            <span className="fail"></span>
          </p>
        </div>
        <div className="flex align-center">
          <span className="h5-heading para-label mr-2">Device</span>
          <div className="form-field">
              <select value={selectedDevice} onChange={e => setSelectedDevice(e.target.value)}>
                <option value="DEKTOP">Desktop</option>
                <option value="MOBILE">Mobile</option>
              </select>
            <svg
              className="post-svg-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="11"
              viewBox="0 0 18 11"
              fill="none"
            >
              <path
                d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z"
                fill="#1B72E6"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="px-8 py-4 stacked_chart">
        <div className="trends_svg relative" style={{ style: `${width}px` }}>
           <div className="absolute" style={{
              left: tooltipStyle.x + 5,
              top: 50,
              background: "#fff",
              zIndex: 2,
              borderRadius: '5px',
              padding: '5px',
            }}>
            {renderTooltip()}
          </div>
          <svg width="100%" height={328}>
            {/* Y Axis Labels */}
            {YAxisPositions.map((position, index) => (
              <text
                key={`ylabel_${index}`}
                x="0"
                y={position + (index === 0 ? 10 : 5)}
                textAnchor="start"
              >
                {yAxisLabels[index]}
              </text>
            ))}
            {/* Y Axis horizontal bars */}
            {YAxisPositions.map((position, index) => (
              <path
                key={`path_${index}`}
                d={`M20,${position + 1} H${width - 7}`}
                stroke="#d7dfe8"
                strokeWidth="1"
                fill="#d7dfe8"
              />
            ))}
            {/* Y Axis Background Path */}
            {XAxisPositions.map((yPos, index) => (
              <path
                key={`xpath_${index}`}
                d={`M${yPos-5},6 Q${yPos-5},6 ${yPos-2.5},1 H${yPos+2.5} Q${yPos+2.5},1 ${yPos+5},6 V305.5 Q${yPos+5},305.5 ${yPos+2.5},308 H${yPos-2.5} Q${yPos-2.5},308 ${yPos-5},305.5 Z`}
                strokeWidth={0}
                fill="#E6E8F3"
                stroke="#E6E8F3"
                onMouseEnter={() => handleMouseEnter(yPos, dataToShow[index])}
              />
            ))}
            {/* Y Axis Pass % Bar  */}
            {XAxisPositions.map((yPos, index) => (
              <path
                key={`pass_${index}`}
                d={`M${yPos},${154} V${
                  midPoint - passPercentages[index] * midPoint
                }`}
                strokeWidth={10}
                stroke="#26A5A5"
                onMouseEnter={() => handleMouseEnter(yPos, dataToShow[index])}
              />
            ))}
            {/* Y Axis Fail % Bar  */}
            {XAxisPositions.map((yPos, index) => (
              <path
                key={`fail_${index}`}
                d={`M${yPos},${154} V${
                  midPoint + (1 - passPercentages[index]) * midPoint
                }`}
                strokeWidth={10}
                stroke="#C74068"
                onMouseEnter={() => handleMouseEnter(yPos, dataToShow[index])}
              />
            ))}
            {/* X Axis Labels as per the y axis positions */}
            {XAxisPositions.map((yPos, index) => (
              <text
                key={`xlabel_${index}`}
                x={yPos}
                y={328}
                textAnchor="middle"
              >{dataToShow[index].date}
              </text>
            ))}
          </svg>
        </div>
      </div>
    </div>
  );
};

export default StackedBarChart;