import React, { useState } from 'react';
import { ChevronIcon } from '../ChevronIcon/ChevronIcon';

const Modal = ({
  onClose,
  children,
  onNext,
  title,
  subtitle,
  buttonText,
  customClass,
  collectionSaved,
  onBack = null,
  disableNext = false,
  hideFooter = false,
  customComponent = null,
}) => {
  const [reSize, setReSize] = useState(false)
  const closeModal = () => {
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className={`flex direction-column modal ${customClass || ''} ${reSize ? ' expand ': ''}`}>
        <div className="modal-title flex justify-between flex-[0_0_auto]">
 
          <div className='d-flex align-center'>
            {onBack ? (
              <button type="button" className="back-btn mr-5" onClick={onBack}>
                <ChevronIcon type="left" />
              </button>
            ) : null}
            <div>
              <h3 className="h3-heading">{title}</h3>
              {subtitle?.length ? <p className="mt-1">{subtitle}</p> : null}
            </div>
          </div>
          <div className='flex align-center'>
            {customComponent && <div className="custom-component">{customComponent}</div>}
            <button className="close-button mr-4" onClick={e => { setReSize(!reSize) }}>
              Resize
            </button>
            <button className="close-button" onClick={closeModal}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.5365 20.3139C22.8316 20.609 22.9974 21.0092 22.9974 21.4265C22.9974 21.8438 22.8316 22.2441 22.5365 22.5391C22.2414 22.8342 21.8411 23 21.4238 23C21.0065 23 20.6062 22.8342 20.3111 22.5391L12 14.2259L3.68626 22.5365C3.39116 22.8316 2.99092 22.9974 2.57358 22.9974C2.15624 22.9974 1.75599 22.8316 1.46089 22.5365C1.16579 22.2414 1 21.8412 1 21.4239C1 21.0066 1.16579 20.6064 1.46089 20.3113L9.77463 12.0007L1.46351 3.68741C1.1684 3.39233 1.00262 2.99211 1.00262 2.57479C1.00262 2.15748 1.1684 1.75726 1.46351 1.46217C1.75861 1.16709 2.15886 1.00131 2.5762 1.00131C2.99353 1.00131 3.39378 1.16709 3.68888 1.46217L12 9.77541L20.3137 1.46086C20.6088 1.16578 21.0091 1 21.4264 1C21.8438 1 22.244 1.16578 22.5391 1.46086C22.8342 1.75595 23 2.15617 23 2.57348C23 2.9908 22.8342 3.39102 22.5391 3.6861L14.2254 12.0007L22.5365 20.3139Z" fill="#1B72E6"/>
              </svg>
            </button>
          </div>
        </div>
        <div className="modal-content flex-auto overflow-y-auto">{children}</div>
        {hideFooter ? null : (
          <div className="modal-footer flex justify-end px-6 py-4 flex-[0_0_auto]">
            {collectionSaved ?
              <button type="button" className="btn-medium-2" onClick={closeModal}>
                Close
              </button>
              :
              <button type="button" className="btn-medium-2 btn-medium-width" onClick={onNext} disabled={disableNext}>
                {buttonText}
              </button>
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
