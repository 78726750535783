import { ChevronIcon } from "../ChevronIcon/ChevronIcon";
const DefaultView = ({
  pageChange,
  entryPages,
  filteredStepPaths,
  setSelectAllCheck,
  setSelectedPath,
  setSelectedPathIndex,
  handleSelectedPathChange,
  selectedPathIndex,
  getStepsPath,
  getStepName,
  getStepMessage,
  selectedPath,
  selectedPage,
  selectedActionLevel,
  handleActionLevel
}) => {
  return (
    <>
      <div className="page-actions-list w-50 useage-pulse-recom-modal">
        <div className="py-2 px-6 align-center top-sticky-scroll bg-white">
          <div className="mr-2 w-70">
            <div className="form-field form-field--select align-center">
              {/* <div className="form-field__label align-center">i</div> */}
              <select
                id="starting-page-lbl"
                name="starting-page-lbl"
                className="select-w-full"
                onChange={(event) => pageChange(event)}
                value={selectedPage}
              >
                <option value={-1}>Choose starting page</option>
                {entryPages.map((page, index) => (
                  <option value={index} key={`page_${index}`}>
                    {page.pageName} ({page.actionCount})
                  </option>
                ))}
              </select>
              <label htmlFor="starting-page-lbl">
                <ChevronIcon type="down" />
              </label>
            </div>
          </div>
          <div className="w-20">
            <div className="form-field form-field--select align-center">
              {/* <div className="form-field__label align-center">i</div> */}
              <select
                className="select-w-full"
                onChange={(event) => handleActionLevel(event)}
                value={selectedActionLevel}
              >
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
                <option value={'ALL'}>All</option>
              </select>
              <ChevronIcon type="down" />
            </div>
          </div>
          <div className="w-10 justify-center align-center">
            {filteredStepPaths.length}
          </div>
        </div>
        <div className="page-actions-list-info">
          <ul>
            {filteredStepPaths && filteredStepPaths.length > 0 ?
              filteredStepPaths.map((path, index) => {
                return (
                  <li
                    key={`path_${index}`}
                    onClick={() => {
                      setSelectAllCheck(false);
                      setSelectedPath(path);
                      setSelectedPathIndex(index);
                      handleSelectedPathChange(path, index);
                    }}
                    className={
                      "pl-6 pr-2 pt-2 pb-3 " +
                      (index == selectedPathIndex ? "active" : "")
                    }
                  >
                    <div className="flex justify-between align-center">
                      <div className="flex-1 pr-3 sub-heading-3 page-actions-list-name">
                        <p className="word-break-all">{getStepsPath(path)}</p>
                        {/* <p className="flex justify-between">
                          <span>Sessions: {pageAction.no_of_users}</span>
                        </p> */}
                      </div>
                      <ChevronIcon type="right" />
                    </div>
                  </li>
                );
              }) : (
                <li className="pl-6 pr-2 pt-2 pb-3">No events recorded</li>
              )}
          </ul>
        </div>
      </div>
      <div className="page-actions-regressions w-50 useage-pulse-recom-modal">
        <div className="page-actions-container">
          <div className="pl-3 pr-6 py-4 justify-between align-center w-full page-actions-regressions-title">
            <p className="sub-heading-2">Selected Page Actions Journey</p>
            {/* <input type="checkbox" name="all" checked={selectAllCheck} onChange={onRegressionChange} /> */}
          </div>
          <div className="page-actions-regressions-list">
            <ul>
              {selectedPath && selectedPath.length > 0
                ? selectedPath.map((path, index) => {
                  return (
                    <li
                      className="my-2 mx-2 p-2 justify-between align-center"
                      key={"key-" + index}
                    >
                      <div>
                        <p className="info-title pb-2 pr-4 sub-heading-3">
                          {getStepName(path)}
                        </p>
                        <p className="info-desc sub-heading-3 word-break-all">
                          {getStepMessage(path)}
                        </p>
                        <p className="info-desc sub-heading-3">
                          {path.actionCount} clicks
                        </p>
                      </div>
                    </li>
                  );
                })
                : (<li className="my-2 mx-2 p-2 justify-between align-center">No Actions</li>)}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default DefaultView;
