import React, { useState, useEffect } from "react";
import AppIcon from "./AppIcon/AppIcon";
import dataService from "../../services/data.service.js";
import apiService from "../../services/api.service.js";
import utilsService from "../../services/utils.service.js";
import SvgIcon from '../../comps/atoms/SvgIcon.jsx';
import FormField from "./globalconfig/FormField.jsx";
import SectionLoader from "./SectionLoader.jsx";
const sectionsList = [
    {
        name: 'systemGeneral',
        display: 'System General',
        fields: [
            {
                name: 'screenshotTimeout',
                tooltipContent: '',
                display: 'Screenshot Timeout',
                fieldType: 'number'
            },
            {
                name: 'fullpageScreenshot',
                tooltipContent: '',
                display: 'fullpageScreenshot',
                fieldType: 'checkbox'
            },
            {
                name: 'useModel',
                tooltipContent: '',
                display: 'useModel',
                fieldType: 'select',


                options: [
                    {
                        value: 'Anthropic',
                        display: 'Andthropic'
                    },
                    {
                        value: 'OpenAI',
                        display: 'OpenAI'
                    }
                ]
            },
            {
                name: 'hostName',
                tooltipContent: '',
                display: 'hostName',
                fieldType: 'text'
            },
        ]
    },
    {
        name: 'webAuthenticationFields',
        display: 'Web Authentication Fields',
        fields: [
            {
                name: 'usernameXPath',
                tooltipContent: '',
                display: 'usernameXPath',
                fieldType: 'text'
            },
            {
                name: 'passwordXPath',
                tooltipContent: '',
                display: 'passwordXPath',
                fieldType: 'text'
            },
            {
                name: 'submitBtnXPath',
                tooltipContent: '',
                display: 'submitBtnXPath',
                fieldType: 'text'
            },
            {
                name: 'successCheckXPath',
                tooltipContent: '',
                display: 'successCheckXPath',
                fieldType: 'text'
            },
            {
                name: 'tenantXPath',
                tooltipContent: '',
                display: 'tenantXPath',
                fieldType: 'text'
            },
            {
                name: 'uiLoginURL',
                tooltipContent: '',
                display: 'UILoginURL',
                fieldType: 'text'
            }

        ]
    },
    {
        name: 'credentialFieldMapper',
        display: 'Credential Field Mapper',
        fields: [
            {
                name: 'userIDFieldName',
                tooltipContent: '',
                display: 'userIDFieldName',
                fieldType: 'text'
            },
            {
                name: 'passwordFieldName',
                tooltipContent: '',
                display: 'passwordFieldName',
                fieldType: 'text'
            },
            {
                name: 'tokenFieldName',
                tooltipContent: '',
                display: 'tokenFieldName',
                fieldType: 'text'
            }
        ]
    },
    {
        name: 'homePage',
        display: 'Home Page',
        fields: [
            {
                name: 'logoName',
                tooltipContent: '',
                display: 'logoName',
                fieldType: 'text'
            },
            {
                name: 'logoFileName',
                tooltipContent: '',
                display: 'logoFileName',
                fieldType: 'text'
            },
            {
                name: 'nextRelease',
                tooltipContent: '',
                display: 'nextRelease',
                fieldType: 'text'
            }
        ]
    },
    {
        name: 'sightSense',
        display: 'Sight Sense',
        fields: [
            {
                name: 'diffThreshold',
                tooltipContent: '',
                display: 'diffThreshold',
                fieldType: 'number'
            },
            {
                name: 'priority_rules_sightsense',
                tooltipContent: '', 
                display: 'priority_rules_sightsense',
                dataType: 'json', 
                fieldType: 'textarea'
            }
        ]
    },
    {
        name: 'apiGlide',
        display: 'API Glide',
        fields: [
            {
                name: 'apiAuthenticationType',
                tooltipContent: '',
                display: 'apiAuthenticationType',
                fieldType: 'text'
            },
            {
                name: 'apiTokenType ',
                tooltipContent: '',
                display: 'apiTokenType ',
                fieldType: 'text'
            },
            {
                name: 'apiAccessTokenValue ',
                tooltipContent: '',
                display: 'apiAccessTokenValue ',
                fieldType: 'text'
            },
            {
                name: 'apiContentType ',
                tooltipContent: '',
                display: 'apiContentType ',
                fieldType: 'text'
            },
            {
                name: 'apiXmlInputFieldName ',
                tooltipContent: '',
                display: 'apiXmlInputFieldName',
                fieldType: 'text'
            },
            {
                name: 'apiAuthentication',
                tooltipContent: '',
                display: 'apiAuthentication',
                dataType: 'json', 
                fieldType: 'textarea'
            },            
            {
                name: 'apiTenantKey  ',
                tooltipContent: '',
                display: 'apiTenantKey ',
                dataType: 'json', 
                fieldType: 'textarea'
            },
            {
                name: 'errorFieldName',
                tooltipContent: '',
                display: 'errorFieldName',
                fieldType: 'text'
            }
        ]
    },
    {
        name: 'notification',
        display: 'Notifications',
        fields: [
            {
                name: 'server',
                tooltipContent: '',
                display: 'server',
                sub: 'smtp',
                fieldType: 'text'
            },
            {
                name: 'port',
                tooltipContent: '',
                display: 'port',
                sub: 'smtp',
                fieldType: 'number'
            },
            {
                name: 'useSSL',
                tooltipContent: '',
                display: 'useSSL',
                sub: 'smtp',
                fieldType: 'text'
            },
            {
                name: 'username',
                tooltipContent: '',
                display: 'username',
                sub: 'smtp',
                fieldType: 'text'
            },
            {
                name: 'password',
                tooltipContent: '',
                display: 'password',
                sub: 'smtp',
                fieldType: 'text'
            },
            {
                name: 'timeout',
                tooltipContent: '',
                display: 'timeout',
                sub: 'smtp',
                fieldType: 'number'
            },
            {
                name: 'debugMode',
                tooltipContent: '',
                display: 'debugMode',
                sub: 'smtp',
                fieldType: 'text'
            }
        ]
    },
    {
        name: 'usagePulse',
        display: 'Usage Pulse',
        fields: [
            {
                name: 'urlExclusionList',
                tooltipContent: '',
                dataType: 'json',
                display: 'urlExclusionList',
                fieldType: 'textarea'
            }
        ]
    },
    {
        name: 'pageBlazer',
        display: 'pageBlazer',
        fields: [
            {
                name: 'priority_rules_pageblazer',
                tooltipContent: '', 
                display: 'priority_rules_pageblazer',
                dataType: 'json', 
                fieldType: 'textarea'
            }
        ]
    }
]
const EnvironmentsConfig = () => {
    const [errorMap, setErrorMap] = useState({});
    const [configData, setconfigData] = useState({})
    const [loading, setloading] = useState(false)
    const getEnvironmentsConfig = async () => {
        const environmentsConfig = await apiService.get({
            url: utilsService.END_POINTS.getEnvironmentsConfigs,
        });
        const { data = [] } = environmentsConfig;
        if (data.length > 0) {
            let allData = data[0];
            setconfigData(allData)
        }
    };
    const saveChange = (section, field, newValue) => {
        setloading(true);
        if (!configData[section.name]) {
            configData[section.name] = {}
        }
        if (field.sub) {
            if (!configData[section.name][field.sub]) {
                configData[section.name][field.sub] = {};
            }
            configData[section.name][field.sub][field.name] = newValue;
        } else {
            configData[section.name][field.name] = newValue
        }
        setconfigData({ ...configData })
        dataService.updateEnvironmentsConfig({
            environmentsConfig: configData
        }).then(apiResp => {

        }).finally(() => {
            setloading(false);
        })
    }
    useEffect(() => {
        getEnvironmentsConfig()
    }, [])
    return (
        <>
            <div className="environment_lists">
                <div className="environment_lists_info radius-8 p-4 w-full configs">
                    <h2 className="mr-1">Environments Configurations</h2>
                    {
                        sectionsList.map(section => {
                            return (
                                <div className="mt-6 model-section">
                                    <h3> {section.display} </h3>
                                    <div className="environment_lists_info_details mt-3"> 
                                            {
                                                section.fields && section.fields.map(field => {
                                                    return (
                                                        <FormField saveChange={val => {
                                                            let newValue = val;
                                                            saveChange(section, field, newValue);
                                                        }} field={field} value={field.sub != null ? configData?.[section.name]?.[field.sub]?.[field.name] : configData?.[section.name]?.[field.name]} />
                                                    )
                                                })
                                            } 
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            {
                loading && (
                    <SectionLoader></SectionLoader>
                )
            }
        </>
    );
};

export default EnvironmentsConfig;