import React, { useEffect, useState } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import utilsService from "../services/utils.service";
import LeftNav from "../comps/atoms/LeftNav";
import apiService from "../services/api.service.js";
import dataService from "../services/data.service.js";
import storeService from "../services/store.service.js";
import AppIcon from "../comps/atoms/AppIcon/AppIcon.jsx";
import ChatBot from "../comps/atoms/ChatBot.jsx";
import SettingsModal from "../comps/atoms/SettingsModal.jsx";
import Logout from "../comps/atoms/Logout.jsx";
import { Tooltip } from 'react-tooltip';
import EnvironmentSelector from "../comps/atoms/EnvironmentSelect/EnvironementSelect.jsx";
import SectionLoader from '../comps/atoms/SectionLoader'; 
import SvgIcon from '../comps/atoms/SvgIcon.jsx';
import LicenseWarning from "../comps/atoms/LicenseWarning.jsx";

const today = utilsService.getYMDFormat(new Date());
let initialCall = true;
export default () => {
  const [usertype, setUsertype] = useState((localStorage.getItem('userType') || '').toLowerCase() === 'admin');
  const [settingsModalOpen, setOpenSettingsModal] = useState(false);

  const openSettingsModal = () => {
    setOpenSettingsModal(true);
  };
  useEffect(() => {
    storeService.sub(utilsService.CONSTANTS.EVENT_NAME.OPEN_SETTINGS_MODAL, ({ detail }) => {
      setOpenSettingsModal(detail);
    });
  }, []);

  return (
    <div className="flex main-layout">
      <div className="main-layout__left-ribbon-nav">
        <LeftNav />
      </div>
      <div className="main-layout__content relative">
        <div className="main-header-nav fluid-container py-4 header-bg absolute w-full">
          <div>
          </div>
          <div className="main-header-nav__links absolute">
            <ul>
              <li>
                <LicenseWarning/>
              </li>
              <li className="home-layout-banner">
                <EnvironmentSelector />
              </li>
              {usertype ?
                <li>
                  <button onClick={openSettingsModal} className="bg-none no-border">
                    <AppIcon iconName="settingsIcon" iconColor="#fff" />
                  </button>
                </li> : null
              }
              <li>
                <a href="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M27.8159 24.6347C26.0385 21.5152 23.2632 19.0846 19.9366 17.7342C21.5909 16.4935 22.8129 14.7637 23.4294 12.7899C24.046 10.8162 24.0259 8.69845 23.372 6.73673C22.7181 4.77502 21.4635 3.06878 19.786 1.8597C18.1085 0.65062 16.0931 0 14.0253 0C11.9574 0 9.94203 0.65062 8.26452 1.8597C6.58701 3.06878 5.33245 4.77502 4.67854 6.73673C4.02464 8.69845 4.00454 10.8162 4.6211 12.7899C5.23766 14.7637 6.45962 16.4935 8.11388 17.7342C4.78733 19.0846 2.01199 21.5152 0.234627 24.6347C0.124495 24.8117 0.0510929 25.0092 0.0187838 25.2152C-0.0135253 25.4212 -0.00408184 25.6316 0.0465531 25.8339C0.097188 26.0362 0.187981 26.2263 0.313533 26.3928C0.439086 26.5593 0.596835 26.6989 0.777404 26.8032C0.957972 26.9075 1.15767 26.9745 1.36463 27.0001C1.5716 27.0257 1.78159 27.0094 1.98214 26.9522C2.18269 26.895 2.36969 26.7981 2.53204 26.6672C2.69438 26.5363 2.82875 26.3741 2.92716 26.1903C5.27616 22.1301 9.4245 19.7085 14.0253 19.7085C18.626 19.7085 22.7744 22.1314 25.1234 26.1903C25.3366 26.5332 25.6749 26.7798 26.0667 26.8778C26.4584 26.9759 26.8729 26.9177 27.2226 26.7157C27.5723 26.5137 27.8297 26.1836 27.9404 25.7953C28.0511 25.4069 28.0065 24.9907 27.8159 24.6347ZM7.28421 9.8562C7.28421 8.52294 7.67957 7.21963 8.42028 6.11107C9.161 5.00251 10.2138 4.13849 11.4456 3.62828C12.6773 3.11806 14.0327 2.98457 15.3404 3.24467C16.648 3.50478 17.8492 4.1468 18.7919 5.08955C19.7347 6.03231 20.3767 7.23345 20.6368 8.54108C20.8969 9.84872 20.7634 11.2041 20.2532 12.4359C19.743 13.6677 18.879 14.7205 17.7704 15.4612C16.6618 16.2019 15.3585 16.5972 14.0253 16.5972C12.2381 16.5952 10.5246 15.8843 9.26089 14.6206C7.99715 13.3568 7.28627 11.6434 7.28421 9.8562Z"
                      fill="#fff"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <Logout />
              </li>
            </ul>
          </div>
        </div>

        <Outlet />
        {settingsModalOpen ? <SettingsModal /> : null}
        <ChatBot />
      </div>
    </div>
  );
};
