import { useState } from 'react'
export default ({ label, defaultLabel, list, onItemSelect, defaultItemValue }) => {
    const [selectItem, setSelectedItem] = useState(defaultItemValue || '');
    const [availableList, setAvailableList] = useState(list)
    const [selectedList, setSelectedList] = useState([])
    const handleSelect = (value) => {
        setSelectedItem(value);
        onItemSelect && onItemSelect(value);
    }
    const addItemToList = (e) => {
        e.preventDefault()
        let selectedListItem = availableList.find(x => x._id === selectItem);
        if(selectedListItem && !selectedList.find(x => x._id == selectItem)) {
            setSelectedList([...selectedList, selectedListItem ])
        }
    }
    const removeSelectedItem = (e, item) => {
        e.preventDefault()
        let newList = selectedList.filter(x => x._id !== item._id)
        setSelectedList(newList)
    }
    return (
        <>
            <div className="form-field-select mt-3">
                <label className="label mb-1">{label}</label>
                <div className="align-center">
                    <div className="form-field">
                        <select className="select-text" value={selectItem} onChange={e => handleSelect(e.target.value)}>
                            <option value={0}>{ defaultLabel }</option>
                            {
                                availableList.map(x => {
                                    return (
                                        <option key={'dropdown-item' + x._id} value={x._id}>{x.name}</option>
                                    )
                                })
                            }
                        </select>
                        <svg className="post-svg-icon" xmlns="http://www.w3.org/2000/svg" width="18"
                            height="11" viewBox="0 0 18 11" fill="none">
                            <path
                                d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z"
                                fill="#1B72E6" />
                        </svg>
                    </div>
                </div>
            </div>
        </>
    )
}