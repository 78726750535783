import React, { useState } from "react";
import ImageComparisonSlider from "../atoms/ImageComparisonSlider";
export default ({ job, baseLineImagePath }) => {
  const [selectedTab, setSelectedTab] = useState("SIDE_BY_SIDE");
  const [changesType, setChangesType] = useState("DIFFERENCES");
  const [overlayValue, setOverlayValue] = useState(50);
  const [labelData, setLabelData] = useState({
    labels: [],
    imageHeight: 0,
    imageWidth: 0,
  });
  const hideAllLabels = (e) => {
    e.preventDefault();
    setLabelData({
      ...labelData,
      labels: labelData.labels.map((label, index) => {
        return {
          ...label,
          show: false,
        };
      }),
    });
  };
  const handleDifferenceLeave = (e, item) => {
    e.preventDefault();
  };
  const changeTab = (e, tab) => {
    e.preventDefault();
    setSelectedTab(tab);
  };
  const handleOverlayChange = (value) => {
    setOverlayValue(value);
  };
  const compareWithBase = (e, job) => {
    e.preventDefault();
  };
  const changeDifferenceTab = (e, value) => {
    e.preventDefault();
    setChangesType(value);
  };
  const handleDifferenceHover = (e, item) => {
    e.preventDefault();
    setLabelData({
      ...labelData,
      labels: labelData.labels.map((label, index) => {
        if (label.labelno == item["rectangle label"] - 1) {
          return {
            ...label,
            show: true,
          };
        }
        return {
          ...label,
          show: false,
        };
      }),
    });
  };
  return (
    <tr className="tr-screen-compare">
      <td colSpan="6">
        {job.screentest &&
          job.screentest.status == "FAILED" &&
          (job.screentest.failedReason ? (
            <div className="p-4 error-message">
              <span>{job.screentest.failedReason.message}</span>
            </div>
          ) : (
            <div className="p-4 error-message">
              <span>Screen compare job failed due to uneepected error.</span>
            </div>
          ))}
        {baseLineImagePath && job.status == "DONE" && (
          <>
            <div className="justify-between align-center p-4">
              <div className="flex align-center">
                {job.isBaseline ? (
                  <h4 className="h4-heading mr-6">Baseline Screen</h4>
                ) : (
                  <>
                    <h4 className="h4-heading mr-6">UI Comparison</h4>
                    <div className="tab-comp align-center">
                      <a
                        onClick={(e) => changeTab(e, "OVERLAY")}
                        className={
                          "tab-item font-small " +
                          (selectedTab == "OVERLAY" ? " active " : "")
                        }
                      >
                        Overlay
                      </a>
                      <a
                        onClick={(e) => changeTab(e, "SIDE_BY_SIDE")}
                        className={
                          "tab-item font-small " +
                          (selectedTab == "SIDE_BY_SIDE" ? " active " : "")
                        }
                      >
                        Side by Side
                      </a>
                      <a
                        onClick={(e) => changeTab(e, "ANNOTATION")}
                        className={
                          "tab-item font-small " +
                          (selectedTab == "ANNOTATION" ? " active " : "")
                        }
                      >
                        Annotation
                      </a>
                    </div>
                  </>
                )}
              </div>
              <div className="flex align-center">
                <a className="ml-4" onClick={(e) => expandJob(e, idx)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <path
                      d="M21.5365 19.3139C21.8316 19.609 21.9974 20.0092 21.9974 20.4265C21.9974 20.8438 21.8316 21.2441 21.5365 21.5391C21.2414 21.8342 20.8411 22 20.4238 22C20.0065 22 19.6062 21.8342 19.3111 21.5391L11 13.2259L2.68626 21.5365C2.39116 21.8316 1.99092 21.9974 1.57358 21.9974C1.15624 21.9974 0.755993 21.8316 0.46089 21.5365C0.165787 21.2414 6.21883e-09 20.8412 0 20.4239C-6.21883e-09 20.0066 0.165787 19.6064 0.46089 19.3113L8.77463 11.0007L0.463508 2.68741C0.168405 2.39233 0.00261814 1.99211 0.00261815 1.57479C0.00261815 1.15748 0.168405 0.757257 0.463508 0.462172C0.758611 0.167086 1.15886 0.00130875 1.5762 0.00130875C1.99353 0.00130874 2.39378 0.167086 2.68888 0.462172L11 8.77541L19.3137 0.460862C19.6088 0.165777 20.0091 -6.95245e-09 20.4264 0C20.8438 6.95245e-09 21.244 0.165777 21.5391 0.460862C21.8342 0.755948 22 1.15617 22 1.57348C22 1.9908 21.8342 2.39102 21.5391 2.6861L13.2254 11.0007L21.5365 19.3139Z"
                      fill="#1B72E6"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div className="screen-compare-section">
              <div
                className={
                  "screen-compare-section__main " +
                  (job.isBaseline ? "base-line-screen" : "base-line-screen")
                }
              >
                {selectedTab == "OVERLAY" && (
                  <React.Fragment>
                    {!job.isBaseline && (
                      <div className="flex mb-4 justify-center">
                        <label>Base line</label>
                        <input
                          className="mx-4"
                          onChange={(e) => handleOverlayChange(e.target.value)}
                          value={overlayValue}
                          type="range"
                          min="0"
                          max="100"
                        />
                        <label>Implemented</label>
                      </div>
                    )}

                    {job.screenshots.length > 0 && (
                      <div className="image-wrapper">
                        <div className="image-wrapper__scroll">
                          <img
                            className="image-wrapper__image-base"
                            src={
                              "/?action=screenshots&method=download&name=" +
                              job.screenshots[0]
                            }
                          />
                          <img
                            style={{ opacity: overlayValue / 100 }}
                            className="image-wrapper__image"
                            src={
                              "/?action=screenshots&method=download&name=" +
                              job.screenshots[0]
                            }
                          />
                          <img
                            style={{ opacity: 1 - overlayValue / 100 }}
                            className="image-wrapper__image-baseline"
                            src={
                              "/?action=screenshots&method=download&name=" +
                              baseLineImagePath
                            }
                          />
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}
                {selectedTab == "SIDE_BY_SIDE" && (
                  <ImageComparisonSlider
                    baseline={
                      "/?action=screenshots&method=download&name=" +
                      baseLineImagePath
                    }
                    implemented={
                      "/?action=screenshots&method=download&name=" +
                      ((job.screentest && job.screentest.markedImage) ||
                        job.screenshots[0])
                    }
                  ></ImageComparisonSlider>
                )}
                {selectedTab == "ANNOTATION" && (
                  <div className="image-wrapper">
                    <div className="image-wrapper__scroll">
                      <img
                        className="image-wrapper__image-base"
                        src={
                          "/?action=screenshots&method=download&name=" +
                          ((job.screentest && job.screentest.markedImage) ||
                            job.screenshots[0])
                        }
                      />
                      <img
                        className="image-wrapper__image"
                        src={
                          "/?action=screenshots&method=download&name=" +
                          ((job.screentest && job.screentest.markedImage) ||
                            job.screenshots[0])
                        }
                      />
                      {labelData.labels.map((x, idx) => {
                        if (x && x.coordinates) {
                          let cords = x.coordinates;
                          return (
                            <div
                              key={"label-" + idx}
                              data-label-no={x.labelno + ""}
                              className={
                                "image-annotation__label " +
                                (x.show ? "show" : "")
                              }
                              style={{
                                left:
                                  (cords[0] / labelData.imageWidth) * 100 + "%",
                                top:
                                  (cords[1] / labelData.imageHeight) * 100 +
                                  "%",
                                width:
                                  (cords[2] / labelData.imageWidth) * 100 + "%",
                                height:
                                  (cords[3] / labelData.imageHeight) * 100 +
                                  "%",
                              }}
                            ></div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  </div>
                )}
              </div>
              {!job.isBaseline && (
                <div className="screen-compare-section__side-bar">
                  <div className="flex p-4 side-bar-head justify-center">
                    {/* {
                                    job.annotations && job.annotations.length > 0 ? (<>
                                        <div className="side-bar-head__no font-btn-large font-secondary-color mr-4">{job.annotations.length}</div>
                                        <div className="side-bar-head__text sub-heading-1">Changes Detected</div>
                                    </>) : (<>
                                        <div className="side-bar-head__text sub-heading-1">No Changes Detected</div>
                                    </>)
                                } */}
                    <div className="tab-comp align-center">
                      <a
                        onClick={(e) => changeDifferenceTab(e, "DIFFERENCES")}
                        className={
                          "tab-item font-small " +
                          (changesType == "DIFFERENCES" ? " active " : "")
                        }
                      >
                        Differences
                      </a>
                      <a
                        onClick={(e) =>
                          changeDifferenceTab(e, "BEST_PRACTICES")
                        }
                        className={
                          "tab-item font-small " +
                          (changesType == "BEST_PRACTICES" ? " active " : "")
                        }
                      >
                        Best Practices
                      </a>
                      <a
                        onClick={(e) => changeDifferenceTab(e, "INSIGHTS")}
                        className={
                          "tab-item font-small " +
                          (changesType == "INSIGHTS" ? " active " : "")
                        }
                      >
                        Insights
                      </a>
                    </div>
                  </div>
                  <div
                    className="px-4 side-bar-list pb-4"
                    onMouseLeave={(e) => hideAllLabels(e)}
                  >
                    {changesType == "DIFFERENCES" &&
                    job.screentest &&
                    job.screentest.label_count == 0 ? (
                      <div className="sub-heading-2 mt-3">
                        No changes were found
                      </div>
                    ) : (
                      changesType == "DIFFERENCES" &&
                      job?.differences &&
                      (job?.screentest?.comparisionVersion != 2
                        ? job.differences.map((x, idx) => {
                            return (
                              <div
                                onMouseLeave={(e) =>
                                  handleDifferenceLeave(e, x)
                                }
                                onMouseEnter={(e) =>
                                  handleDifferenceHover(e, x)
                                }
                                key={"difference-" + idx}
                                className={"side-bar-list__item p-3 mt-3"}
                              >
                                <div className="justify-between">
                                  <div className="sub-heading-2">
                                    <span className="ml-2 capitalize">
                                      Label {x["rectangle label"]}
                                    </span>
                                  </div>
                                  <label className="form-field-checkbox">
                                    <input type="checkbox" />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                                <div className="para-4 mt-2">
                                  {x.questions &&
                                    x.questions
                                      .filter((j) => j.Changed == "Yes")
                                      .map((question, qi) => {
                                        return (
                                          <React.Fragment key={"rt-" + qi}>
                                            <div className="para-4 ml-2">
                                              {question.question_text}
                                            </div>
                                            <div className="para-4 ml-4">
                                              {question.Observation}
                                            </div>
                                          </React.Fragment>
                                        );
                                      })}
                                </div>
                                {x["Brief_Details"] && (
                                  <div className="ml-2 mt-1 para-4">
                                    <span className="font-bold">Details: </span>
                                    <span className="ml-1">
                                      {x["Brief_Details"]}
                                    </span>
                                  </div>
                                )}
                              </div>
                            );
                          })
                        : job.differences.map((x, idx) => {
                            return (
                              <div
                                key={"difference-" + idx}
                                className={"side-bar-list__item p-3 mt-3"}
                              >
                                <div className="justify-between">
                                  <div className="sub-heading-2">
                                    <span className="ml-2 capitalize">
                                      {x.title}
                                    </span>
                                  </div>
                                  <label className="form-field-checkbox">
                                    <input type="checkbox" />
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                                <div className="para-4 mt-2">
                                  <div className="para-4 ml-2">{x.text}</div>
                                </div>
                              </div>
                            );
                          }))
                    )}
                    {changesType == "BEST_PRACTICES" &&
                      job.bestPractices &&
                      Object.entries(job.bestPractices).map(([key, value]) => {
                        return (
                          <div
                            key={"best-pracices-" + key}
                            className={"side-bar-list__item p-3 mt-3"}
                          >
                            <div className="justify-between">
                              <div className="sub-heading-2">
                                <span className="h5-heading">{key}</span>
                              </div>
                            </div>
                            <div className="para-4 mt-2">{value}</div>
                          </div>
                        );
                      })}

                    {changesType == "INSIGHTS" &&
                      job.insights &&
                      job.insights.map((x, idx) => {
                        return (
                          <div
                            key={"insights-" + idx}
                            className={"side-bar-list__item p-3 mt-3"}
                          >
                            <div className="justify-between">
                              <div className="sub-heading-2">
                                {idx + 1}.
                                <span className="ml-2 capitalize">
                                  {x.text}
                                </span>
                              </div>
                            </div>
                            <div className="para-4 mt-2">{x.message}</div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="side-bar-footer p-4">
                    <a href="#" className="para-2 disabled">
                      Ignore
                    </a>
                    <button className="btn-medium-2 disabled">
                      Mark as Issue
                    </button>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {job.status == "FAILED" ? (
          <div className="p-4 error-message">
            {job.failedReason && <span>{job.failedReason.message}</span>}
          </div>
        ) : (
          !baseLineImagePath &&
          job.status == "DONE" && (
            <div className="p-4 error-message">
              Baseline image is not available for this device
            </div>
          )
        )}
      </td>
    </tr>
  );
};
