import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../molecules/ConfirmModal";
import AppIcon from "../atoms/AppIcon/AppIcon";
export default ({ iconColor = '#fff' }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const navigate = useNavigate();
  const logoutUser = event => {
    localStorage.removeItem("token");
    localStorage.removeItem("userType")
    navigate('/login');

  };

  useEffect(() => {
    let headerElem = document.querySelector(".main-header-nav");
    if (showConfirmModal) {
      headerElem.classList.add("position-unset");
    } else {
      headerElem.classList.remove("position-unset");
    }
  }, [showConfirmModal])

  return (
    <div>
      <button
        onClick={() => setShowConfirmModal(true)}
        className="bg-none no-border"
      >
        <AppIcon iconName="signOut" iconColor={iconColor} />
      </button>
      {showConfirmModal && 
        <ConfirmModal 
          confirmationTitle="Sign Out"
          confirmationMessage="Are you sure you want to sign out?"
          confirmButtonLabel="Sign Out"
          confirmBtnClass="button-warning"
          onOk={logoutUser} 
          onCancel={() => setShowConfirmModal(false)}
        />}
    </div>
  );
};
