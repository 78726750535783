import { useState } from 'react'
export default (props) => {
    const [copySuccess, setCopySuccess] = useState('');
    const hostName = location.protocol + '//' + location.hostname + (location.port.length > 0 ? ':' + location.port : '');
    const rawCode = `
        <script defer>
            window.astraqConfig = {
                apiEndPoint: "${hostName}/?action=analytics&amp;method=trackevents",
                debounceTime: 1000,
                siteId: "${props.siteId || 'site_1'}",
                tenantId: "${props.tenantId || 'tenant_1'}",
                startPageTime: performance['now'] ? performance['now']() : null
            }
            function loadScriptWithTimeout(src) {
                const script = document.createElement('script');
                script.src = src;
                script.async = true;
                document.head.appendChild(script);
            }
            setTimeout(() => {
                loadScriptWithTimeout("${hostName}/astraq-tracking.min.js");
            }, 2000)
        </script>
    `
    const highlightedCode = `
    &lt;script <span class="keyword">defer</span>&gt;
        window.astraqConfig = {
            <span class="keyword">apiEndPoint</span>: <span class="string">"<a style="padding:0;" class="btn-link" href="${hostName}/?action=analytics&amp;method=trackevents" target="_blank">${hostName}/?action=analytics&amp;method=trackevents</a>"</span>,
            <span class="keyword">debounceTime</span>: <span class="number">1000</span>,
            <span class="keyword">siteId</span>: <span class="string">"${props.siteId || 'site_1'}"</span>,
            <span class="keyword">tenantId</span>: <span class="string">"${props.tenantId || 'tenant_1'}"</span>,
            <span class="keyword">startPageTime</span>: <span class="">performance['now'] ? performance['now']() : null</span>
        <span class="bracket">}</span>
        <span class="function">function</span> loadScriptWithTimeout(src) <span class="bracket">{</span>
            <span class="keyword">const</span> script = <span class="predefined">document</span>.createElement(<span class="string">'script'</span>);
            script.src = src;
            script.async = <span class="keyword">true</span>;
            <span class="predefined">document</span>.head.appendChild(script);
        <span class="bracket">}</span>
        <span class="predefined">setTimeout</span>(() <span class="operator">=&gt;</span> <span class="bracket">{</span>
            loadScriptWithTimeout(<span class="string">"<a style="padding:0;" class="btn-link" href="${hostName}/astraq-tracking.min.js" target="_blank">${hostName}/astraq-tracking.min.js</a>"</span>);
        <span class="bracket">}</span>, <span class="number">2000</span>)
    &lt;/script&gt;
    `;
    const rawCode2 = `
        <script>
            window.astraqConfig = {
                apiEndPoint: "${hostName}/?action=analytics&amp;method=trackevents",
                debounceTime: 1000,
                siteId: "${props.siteId || 'site_1'}",
                tenantId: "${props.tenantId || 'tenant_1'}"
            }
        </script>
        <script src="/astraq-tracking.js" defer></script>
    `
    const highlightedCode2 = `
    &lt;script&gt;
        window.astraqConfig = {
            <span class="keyword">apiEndPoint</span>: <span class="string">"<a style="padding:0;" class="btn-link" href="${hostName}/?action=analytics&amp;method=trackevents" target="_blank">${hostName}/?action=analytics&amp;method=trackevents</a>"</span>,
            <span class="keyword">debounceTime</span>: <span class="number">1000</span>,
            <span class="keyword">siteId</span>: <span class="string">"${props.siteId || 'site_1'}"</span>,
            <span class="keyword">tenantId</span>: <span class="string">"${props.tenantId || 'tenant_1'}"</span>
        <span class="bracket">}</span>
    &lt;/script&gt;
    &lt;script src="<span class="placeholder">[[PATH TO YOUR JS FOLDER]]</span>/astraq-tracking.min.js" <span class="keyword">defer</span>&gt;&lt;/script&gt;
    `;
    const handleCopy = (codeToCopy) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(codeToCopy)
                .then(() => {
                    setCopySuccess("Copied!");
                    setTimeout(() => setCopySuccess(""), 2000);
                })
                .catch((err) => {
                    console.error("Clipboard API failed:", err);
                    fallbackCopyTextToClipboard(codeToCopy);
                });
        } else {
            console.warn("Clipboard API not available, using fallback.");
            fallbackCopyTextToClipboard(codeToCopy);
        }
    };

    const fallbackCopyTextToClipboard = (text) => {
        const textarea = document.createElement("textarea");
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        try {
            document.execCommand("copy");
            setCopySuccess("Copied!");
        } catch (err) {
            setCopySuccess("Failed to copy");
        }
        document.body.removeChild(textarea);
        setTimeout(() => setCopySuccess(""), 2000);
    };
    return (
        <>
            <h3 className='h3-heading mb-2'>Method 1:</h3>
            <div className='pl-4'>
                <p className='para-2 mb-1'>Paste below lines in your root layout file (html) to tracking in all pages.</p>
                <div className="mb-1 pos-rel">
                    <button className="btn-medium-2 btn-medium-width copy-to-clipboard" onClick={e => {
                        handleCopy(rawCode)
                    }}> {copySuccess || 'Click here to copy'}</button>
                    <pre class="code-block">
                        <code dangerouslySetInnerHTML={{ __html: highlightedCode }}>
                        </code>
                    </pre>
                </div>
            </div>
            <h3 className='h3-heading mb-2 mt-4'>Method 2:</h3>
            <div className='pl-4'>
                <ol>
                    <li className='mb-1'>First <a style={{ padding: 1 }} className='btn-link' target="_blank" download href={`${hostName}/astraq-tracking.min.js`}>Download Script</a></li>
                    <li className='mb-1'>Place it in your script files folder.</li>
                    <li className='mb-1'>Reference it in your layout file similar to your other JS files or in root path of public files.</li>
                </ol>
                <p className='para-2 mb-1'>Paste below lines in your root layout file (html) to tracking in all pages.</p>
                <div className="mb-1 pos-rel">
                    <button className="btn-medium-2 btn-medium-width copy-to-clipboard" onClick={e => {
                        handleCopy(rawCode2)
                    }}> {copySuccess || 'Click here to copy'}</button>
                    <pre class="code-block">
                        <code dangerouslySetInnerHTML={{ __html: highlightedCode2 }}>
                        </code>
                    </pre>
                </div>
            </div>
        </>
    )
}