import { useState } from 'react'
import DropdownList from '../atoms/DropdownList';
import SelectComp from '../atoms/SelectComp';
import RadioList from '../atoms/RadioList';
import CheckboxList from '../atoms/CheckboxList';
import Select from 'react-select';

const statusOptionsObj = {
    name: 'status',
    options: [{
        label: 'All',
        value: 'all'
    }, {
        label: 'Passed',
        value: 'Passed'
    }, {
        label: 'Failed',
        value: 'Failed'
    }]
};

export default ({ onClose, collections, filterData, onFilterApply }) => {
    const [loading, setLoading] = useState(false);
    const [selectedCollections, setSelectedCollections] = useState(filterData.selectedCollectionIds || [])
    const [selectedTags, setSelectedTags] = useState(filterData.selectedTags || []);
    const [selectedRuns, setSelectedRuns] = useState(filterData.runs || 0);
    const [selectedStatus, setSelectedStatus] = useState(filterData.status || '');
    const closeModal = (e) => {
        e && e.preventDefault();
        const filterObj = {
            selectedCollectionIds: [],
            selectedTags: [],
            runs: 0,
            status: ''
        };
        onFilterApply && onFilterApply(filterObj);
        onClose && onClose(e);
    }
    const handleformSubmit = (e) => {
        e.preventDefault();
        const filterObj = {
            selectedCollectionIds: selectedCollections,
            selectedTags: selectedTags,
            status: selectedStatus,
            runs: selectedRuns ? Number(selectedRuns) : 0
        };
        onFilterApply && onFilterApply(filterObj);
    }
    return (
        <>
            <div className="justify-between align-center filter-popup-content">
                <div className="align-center">
                    <h2 className="h2-heading">Filter Collection List</h2>
                </div>
                <a onClick={closeModal} href="/index.html">
                    <svg xmlns="http://www.w3.org/2000/svg" className="close-svg-icon" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path d="M21.5365 19.3139C21.8316 19.609 21.9974 20.0092 21.9974 20.4265C21.9974 20.8438 21.8316 21.2441 21.5365 21.5391C21.2414 21.8342 20.8411 22 20.4238 22C20.0065 22 19.6062 21.8342 19.3111 21.5391L11 13.2259L2.68626 21.5365C2.39116 21.8316 1.99092 21.9974 1.57358 21.9974C1.15624 21.9974 0.755993 21.8316 0.46089 21.5365C0.165787 21.2414 6.21883e-09 20.8412 0 20.4239C-6.21883e-09 20.0066 0.165787 19.6064 0.46089 19.3113L8.77463 11.0007L0.463508 2.68741C0.168405 2.39233 0.00261814 1.99211 0.00261815 1.57479C0.00261815 1.15748 0.168405 0.757257 0.463508 0.462172C0.758611 0.167086 1.15886 0.00130875 1.5762 0.00130875C1.99353 0.00130874 2.39378 0.167086 2.68888 0.462172L11 8.77541L19.3137 0.460862C19.6088 0.165777 20.0091 -6.95245e-09 20.4264 0C20.8438 6.95245e-09 21.244 0.165777 21.5391 0.460862C21.8342 0.755948 22 1.15617 22 1.57348C22 1.9908 21.8342 2.39102 21.5391 2.6861L13.2254 11.0007L21.5365 19.3139Z" fill="#1B72E6" />
                    </svg>
                </a>
            </div>
            <div className="filter-form-content">
                <form className="app-form">
                    <div className='modal-inner-content'>
                        <div className="app-form__fields">
                            <div className="form-field-select form-content">
                                <label className="label mb-2">Select Collections</label>
                                <div className="align-center">
                                    <div className="form-field">
                                        <Select
                                            value={selectedCollections}
                                            isMulti
                                            onChange={setSelectedCollections}
                                            name="colors"
                                            options={collections.map(x => ({ label: x.name, value: x._id }))}
                                            className="basic-multi-select select-text"
                                            classNamePrefix="select"
                                        />
                                    </div>
                                </div>
                            </div>
                             
                            <div className="form-field-select form-content">
                                <label className="label mb-2">Select Tags</label>
                                <div className="align-center">
                                    <div className="form-field">
                                        <Select
                                            value={selectedTags}
                                            isMulti
                                            onChange={setSelectedTags}
                                            name="colors"
                                            options={collections.map(x => ({ label: x.name, value: x._id }))}
                                            className="basic-multi-select select-text"
                                            classNamePrefix="select"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-field-select form-content">
                                <SelectComp
                                    onItemSelect={(val) => setSelectedRuns(val)}
                                    label="Collection Run More Than"
                                    defaultLabel="Select Number"
                                    defaultItemValue={selectedRuns}
                                    list={["1", "2", "3"].map(x => ({_id: x, name: x}))}
                                />
                            </div>
                            <div className="form-field-group-checkbox form-content-checkbox">
                                <div className="form-field-group-checkbox__label mb-4 field-label-heading">
                                    Status
                                </div>
                                <div className="form-field-group-checkbox__items radio-group">
                                    {statusOptionsObj?.options?.map((it, i) => {
                                        return (
                                            <div className="form-field-group-checkbox__item" key={i}>
                                                <label className="form-field-checkbox mr-2">
                                                    <input type="radio" value={it.value} defaultChecked={selectedStatus === it.value} name={statusOptionsObj.name} onChange={e => setSelectedStatus(e.target.value)} />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label>{it.label}</label>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                            {/* <RadioList></RadioList> */}
                            {/* <CheckboxList></CheckboxList>
                            <div className="form-field-group-checkbox mt-4">
                                <div className="form-field-group-checkbox__label mb-4 field-label-heading">
                                    Collection events in Production
                                </div>
                                <div className="">
                                    <div className="form-field-group-checkbox__item mt-4">
                                        <label className="form-field-checkbox mr-2">
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="ml-2">Two or more test cases logged</label>
                                    </div>
                                    <div className="form-field-group-checkbox__item mt-4">
                                        <label className="form-field-checkbox mr-2">
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="ml-2">Failed two or more time in the production</label>
                                    </div>
                                    <div className="form-field-group-checkbox__item mt-4">
                                        <label className="form-field-checkbox mr-2">
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="ml-2">Response time in production less than 3 sec</label>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className='mt-4'>
                                <div>
                                    <a className='align-center'>
                                        <span className='mr-2'>Show Advance Filters</span>
                                        <svg className="post-svg-icon" xmlns="http://www.w3.org/2000/svg" width="18"
                                            height="11" viewBox="0 0 18 11" fill="none">
                                            <path
                                                d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z"
                                                fill="#1B72E6" />
                                        </svg>
                                    </a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="app-form__controls mt-4 px-6 py-4 align-center">
                        <a onClick={closeModal} className="btn-link-medium mr-8">Clear All</a>
                        <button className="btn-medium-2 btn-medium-width" type='button' disabled={loading} onClick={handleformSubmit}>Apply Filter</button>
                    </div>
                </form>
            </div>
        </>
    );
}