const checkAndReturn = (valToCheck, prefix) => {
    if (valToCheck) {
        return prefix + valToCheck;
    }
    return "";
};
const getStepName = (step) => {
    if (step.actionType === "OPEN_URL" || step.page_title) {
        return (
            "Open " +
            (step.page_title || step.pageUrl.substr(step.pageUrl.lastIndexOf("/") + 1))
        );
    }
    if (step.actionType === "INPUT") {
        return (
            "Input " +
            step.elementValue +
            checkAndReturn(
                step.elementText || step.elementId || step.elementName,
                " in "
            )
        );
    }
    if (
        ["CHECKBOX", "RADIO", "SELECT", "MULTI_SELECT"].indexOf(step.actionType) >=
        0
    ) {
        return "Select " + step.elementValue + " as " + step.elementName;
    }
    if (["ANCHOR_CLICK", "BUTTON_CLICK"].indexOf(step.actionType) >= 0) {
        return (
            "Click " +
            (step.elementLinkText ||
                step.elementText ||
                step.elementId ||
                step.elementName ||
                step.actionType)
        );
    }
    return step.pageName || step.actionType;
};
const getTitleUrlPath = (pages) => {
    let paths = []
    pages.forEach(page => {
        let actionType = page.page_title ? 'OPEN' : page.actionType;
        let { elementId, elementLink, elementLinkText, elementName, elementXPath, elementValue } = page;
        paths.push(`${page.page_title}||${page.pageURL}||${page.no_of_users}||${actionType}||${elementId}||${elementLink}||${elementLinkText}||${elementName}||${elementXPath}||${elementValue}`);
    });
    return paths.join('=>')
}
const toArray = (node) => {
    let list = [];
    for (const key in node) {
        let nextArr = toArray(node[key].next);
        node[key].next = nextArr;
        node[key].hasNext = false;
        let nextLen = nextArr.length;
        if (nextLen > 0) {
            node[key].hasNext = true;
            node[key].next[0].first = true;
            node[key].next[nextLen - 1].last = true;
        }
        list.push(node[key])
    }
    return list;
}
export default {
    getJourneyList(data, selectedLevel = null) {
        let tree = { next: {} };
        const parsePaths = (paths) => {
            let treeNode = tree;
            let tillPath = [];
            paths.forEach((pathStr, pathIndex) => {
                const [name, url, count, actionType, elementId, elementLink, elementLinkText, elementName, elementXPath, elementValue] = pathStr.split('||');
                let pathKey = name + '||' + url + '||' + actionType + '||' + elementId + '||' + elementLink + '||' + elementLinkText + '||' + elementName + '||' + elementXPath + '||' + elementValue;
                tillPath.push(pathKey);
                let nodeObj = {
                    name,
                    url,
                    count,
                    actionType,
                    elementId,
                    elementLink,
                    elementLinkText,
                    elementName,
                    elementXPath,
                    elementValue,
                }
                let stepName = actionType == 'OPEN' ? `${name} (${count})` : getStepName(nodeObj);
                if (!treeNode.next[pathKey]) {
                    treeNode.next[pathKey] = {
                        ...nodeObj,
                        stepName: stepName,
                        next: {}
                    }
                }
                treeNode.next[pathKey].stepPath = tillPath.join('=>')
                treeNode = treeNode.next[pathKey];
            });
        }

        let totalCount = 0;
        data.filter(x => {
            if (selectedLevel && selectedLevel != 'ALL') {
                if (x.journey_level == selectedLevel - 0) {
                    return true;
                }
                return false
            }
            return true;
        }).forEach((item, idx) => {
            totalCount += 1;
            const { pages = [] } = item;
            item.fullPath = getTitleUrlPath(pages);
            parsePaths(item.fullPath.split('=>'));
        })
        tree.next = toArray(tree.next);
        return { tree, count: totalCount };
    },
    getTreePaths(rootNodes) {
        let paths = [];
        let completedPaths = 0;
        const parseNode = (node, pathArr) => {
            if (completedPaths >= 3) return;
            pathArr.push({
                pagename: node.pagename,
                count: node.count
            })
            if (node.children && node.children.length > 0) {
                node.children.forEach(item => {
                    parseNode(item, pathArr)
                });
            } else {
                completedPaths += 1;
                paths.push(pathArr)
            }
        }
        rootNodes.filter((x, i) => i <= 2).forEach(node => {
            parseNode(node, []);
        })
        return paths;
    },
    parseAllNodes(rootNodes) {
        let nodeKey = 1;
        let nodeSet = new Map();
        const parseNode = (node, pathArr) => {
            node.key =  nodeKey + '';
            nodeSet.set(nodeKey + '', {
                count: node.count,
                pagename: node.pagename,
                url: node.url,
                actionType: 'OPEN_URL'
            })
            nodeKey++;
            let newArr = [...pathArr];
            newArr.push(node.key)
            if (node.children && node.children.length > 0) {
                node.hasNext = true;
                node.children.forEach(item => {
                    parseNode(item, newArr)
                });
            } else {
                node.hasNext = false;
                node.stepPath = newArr.join('>')
            }
        }
        rootNodes.forEach((node, nodeIndex) => {
            parseNode(node, []);
        })
        return { updatedNodes: rootNodes, nodeSet };
    }
}