import { useState, useEffect } from "react";
import SvgIcon from "../atoms/SvgIcon";
import AppModal from "../../comps/organisms/AppModal";
import AppIcon from "../../comps/atoms/AppIcon/AppIcon.jsx";
import ConfirmModal from '../molecules/ConfirmModal.jsx';
import apiService from "../../services/api.service.js";
import storeService from "../../services/store.service.js";
import utilsService from "../../services/utils.service.js";
import TablePagination from '../molecules/TablePagination.jsx'

export default () => {
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [users, setUsers] = useState([])
  const [selectedUser, setselectedUser] = useState(null)
  const [formData, setFormdata] = useState({
    username: '',
    password: '',
    role: 'USER'
  })
  const [usePagination, setusePagination] = useState(false)
  const [formErrors, setformErrors] = useState({
    username: false,
    password: false,
    role: false
  })
  const [paginationData, setPaginationData] = useState({
    pageNo: 1,
    pageSize: 5
  })
  const [serverError, setServerError] = useState(null)
  const [totalCount, settotalCount] = useState(0)

  const handlePaginationChange = (data) => {
    if (data.perPage) {
      setPaginationData({
        ...paginationData,
        pageSize: data.perPage,
        pageNo: 1
      })
    }
    if (data.pageNo) {
      setPaginationData({
        ...paginationData,
        pageNo: data.pageNo
      })
    }
  }
  const handleUpdate = () => {
    setServerError(null)
    if (isEdit) {
      let usernameError = false, passwordError = false;
      let userToUpdate = {
        role: formData.role,
        firstName: formData.firstName,
        lastName: formData.lastName,
        _id: selectedUser._id
      }

      if (formData.username && formData.username.length > 0) {
        if (formData.username.trim().length == 0) {
          usernameError = true;
        } else {
          userToUpdate['username'] = (formData.username || '').trim();
        }
      } else {
        usernameError = true;
      }
      if (formData.password && formData.password.length > 0) {
        if (formData.password.trim().length == 0) {
          passwordError = true;
        } else {
          userToUpdate['password'] = (formData.password || '').trim();
        }
      }
      if (usernameError || passwordError) {
        setformErrors({
          ...formErrors,
          username: usernameError,
          password: passwordError
        })
        return;
      }
      apiService.post({
        url: utilsService.END_POINTS.updateuser,
        data: userToUpdate
      }).then((apiResp) => {
        const { data, error } = apiResp;
        if (error) {
          setServerError('Something went wrong, please try again with different data.')
          if (error.code == 'USERNAME_EXISTS_IN_DB') {
            setServerError('Username is already exists in the DB, please try again with different username.')
          }
          setFormdata({
            username: selectedUser.username,
            password: '',
            role: selectedUser.role
          })
          return;
        }
        loadUsers();
        setShowModal(false);
      }).catch(() => {
        setServerError('Something went wrong, please try again with different data.')
      })
    } else {
      let usernameError = false, passwordError = false;
      if (formData.username && formData.username.length > 0) {
        if (formData.username.trim().length == 0) {
          usernameError = true;
        }
      } else {
        usernameError = true;
      }
      if (formData.password && formData.password.length > 0) {
        if (formData.password.trim().length == 0) {
          passwordError = true;
        }
      } else {
        passwordError = true;
      }
      if (usernameError || passwordError) {
        setformErrors({
          ...formErrors,
          username: usernameError,
          password: passwordError
        })
        return;
      }
      let userToAdd = {
        username: formData.username.trim(),
        password: formData.password.trim(),
        role: formData.role,
        firstName: formData.firstName,
        lastName: formData.lastName
      }
      apiService.post({
        url: utilsService.END_POINTS.adduser,
        data: userToAdd
      }).then((apiResp) => {
        const { data, error } = apiResp;
        if (error) {
          setServerError('Something went wrong, please try again with different data.')
          if (error.code == 'USERNAME_EXISTS_IN_DB') {
            setServerError('Username is already exists in the DB, please try again with different username.')
          }
          setFormdata({
            username: '',
            password: '',
            role: 'USER'
          })
          return;
        }
        loadUsers();
        setShowModal(false);
      }).catch(() => {
        setServerError('Something went wrong, please try again with different data.')
      })
    }
  };
  const openDeleteNotification = (user) => {
    setselectedUser(user);
    setShowDeleteModal(true);
  }
  const handleDeleteModal = () => {
    console.log('user ', selectedUser);
    apiService.post({
      url: utilsService.END_POINTS.deleteuser,
      data: {
        _id: selectedUser._id
      }
    }).then(() => {

    }).catch().finally(() => {
      loadUsers();
      setShowDeleteModal(false);
    })

  }
  const loadUsers = () => {
    apiService.post({
      url: utilsService.END_POINTS.getusers,
      data: {
        pagination: usePagination
      }
    }).then((apiResp) => {
      const { data = {} } = apiResp;
      const { items = [], totalCount = 0 } = data;
      setUsers(items);
      settotalCount(totalCount);
    })
  }
  useEffect(() => {
    loadUsers();
  }, [])
  return (
    <>
      <div className="url-mapping-module p-4">
        <h2 className="mb-8">User Management</h2>
        <form className="app-form flex justify-start mb-8">
          <div className="w-100 justify-end flex">
            <div className="align-center">
              <button className="btn-medium-2 ml-3" type='button' onClick={() => {
                setFormdata({
                  username: '',
                  password: '',
                  role: 'USER'
                })
                setformErrors({
                  username: false,
                  password: false,
                  role: false
                })
                setIsEdit(false)
                setShowModal(true)
              }}>Add User </button>
            </div>
          </div>
        </form>

        <table className="app-table my-4">
          <tbody>
            <tr className="table-row-title">
              <td>
                <div className="align-center">
                  <span className="mr-1 table-subheading">Username</span>
                </div>
              </td>
              <td>
                <div className="align-center">
                  <span className="mr-1 table-subheading">Created date</span>
                </div>
              </td>
              {/* <td>
                <div className="align-center">
                  <span className="mr-1 table-subheading">Created By</span>
                </div>
              </td> */}
              <td>
                <div className="align-center">
                  <span className="mr-1 table-subheading">Role</span>
                </div>
              </td>
              <td>
                <div className="align-center">
                  <span className="mr-1 table-subheading">Actions</span>
                </div>
              </td>
            </tr>
            {
              users.map(user => {
                return (
                  <tr className="table-content ">
                    <td className="table-text">
                      <div className="justify-between align-center">
                        <span className="table-text">{user.username}</span>
                      </div>
                    </td>
                    <td>
                      <div className="justify-between align-center">
                        <span className="table-text">{utilsService.getDisplayDateTime(user.createdAt, true)}</span>
                      </div>
                    </td>
                    {/* <td>
                      <div className="justify-between align-center">
                        <span className="table-text">{user.createdBy}</span>
                      </div>
                    </td> */}
                    <td>
                      <div className="justify-between align-center">
                        <span className="table-text">{user.role == 'ADMIN' ? 'Admin' : 'User'}</span>
                      </div>
                    </td>
                    <td>
                      <div className="flex align-center">
                        {
                          user.username != 'admin' && (
                            <a onClick={() => {
                              setselectedUser(user)
                              setFormdata({
                                username: user.username,
                                password: '',
                                role: user.role
                              })
                              setformErrors({
                                username: false,
                                password: false,
                                role: false
                              })
                              setIsEdit(true)
                              setShowModal(true)
                            }}
                              className='py-1 flex align-center no-underline edit-link-option mr-6'>
                              Edit
                            </a>
                          )
                        }
                        {
                          user.username != 'admin' && (
                            <a onClick={e => {
                              e.preventDefault();
                              openDeleteNotification(user)
                            }}
                              className='py-1 flex align-center no-underline edit-link-option'>
                              Delete
                            </a>
                          )
                        }
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        {
          usePagination && (
            <TablePagination onChange={handlePaginationChange} pageNo={paginationData.pageNo} pageSize={paginationData.pageSize} totalSize={totalCount}></TablePagination>
          )
        }
      </div>
      {
        showDeleteModal && (
          <ConfirmModal
            confirmationTitle={'Are you sure you want to delete?'}
            confirmButtonLabel={'Delete'}
            confirmBtnClass="button-warning"
            onOk={() => handleDeleteModal()}
            onCancel={e => setShowDeleteModal(false)}
          />
        )
      }
      {
        showModal && (
          <AppModal>
            <div className="add-modal-popup">
              <div className="justify-between align-center px-8 py-6">
                <div className="align-center">
                  <h2 className="h2-heading"> {isEdit ? 'Edit' : 'Add'}  User </h2>
                </div>
                <button type="button"
                  onClick={e => {
                    setShowModal(false)
                  }}
                  className="bg-none no-border">
                  <AppIcon iconName="closeIcon" iconColor="#1B72E6" />
                </button>
              </div>
              <div className="p-4 edit-section modal-content modal-form-content overflow-y-scroll min-h-60vh max-h-60vh">
                <input type="text" style={{ opacity: 0, position: 'absolute' }} />
                <input type="password" style={{ opacity: 0, position: 'absolute' }} />
                {
                  serverError && (
                    <p className="error-message mb-2">{serverError}</p>
                  )
                }

                <div className="form-field mb-8">
                  <label className="label mb-1">Username</label>
                  <input
                    value={formData.username}
                    onChange={(e) =>
                      setFormdata({
                        ...formData,
                        username: e.target.value
                      })
                    }
                    autocomplete="off"
                    name="newusername"
                    className={`input-txt ${formErrors['username'] ? 'input-txt--error' : ''}`}
                    type="text"
                  />
                  {formErrors['username'] && (
                    <p className="error-message">This field is required.</p>
                  )}
                </div>
                <div className="form-field mb-8">
                  <label className="label mb-1">Password</label>
                  <input
                    value={formData.password}
                    name="newpassword"
                    onChange={(e) =>
                      setFormdata({
                        ...formData,
                        password: e.target.value
                      })
                    }
                    className={`input-txt ${formErrors['password'] ? 'input-txt--error' : ''}`}
                    type="password"
                  />
                  {
                    isEdit && (
                      <p className="field-note mt-1">Leave blank if you don't want to change.</p>
                    )
                  }

                  {formErrors['password'] && (
                    <p className="error-message">This field is required, all blanks are not allowed.</p>
                  )}
                </div>
                <div className="mb-8">
                  <label className="label mb-2" htmlFor="pageNameInput">Role</label>
                  <div className="form-field">
                    <select className="select-text" value={formData.role} onChange={e => {
                      setFormdata({
                        ...formData,
                        role: e.target.value
                      })
                    }}>
                      <option value="ADMIN">Admin</option>
                      <option value="USER">User</option>
                    </select>
                    <svg className="post-svg-icon" xmlns="http://www.w3.org/2000/svg" width="18"
                      height="11" viewBox="0 0 18 11" fill="none">
                      <path
                        d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z"
                        fill="#1B72E6" />
                    </svg>
                  </div>
                </div>

                <div className="flex justify-end py-6">
                  <button className="btn-medium-2 w-20 mr-6 btn-secondary" type='button' onClick={() =>
                    setShowModal(false)
                  }>Cancel</button>
                  <button className="btn-medium-2 btn-medium-width" type='button' onClick={handleUpdate}>{isEdit ? 'Update ' : 'Add '} User</button>
                </div>
              </div>
            </div>

          </AppModal>
        )
      }
    </>
  );
};