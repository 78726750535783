import { useState } from 'react'
import TrackingCodeView from './TrackingCodeView';
export default () => {
    const [siteId, setSiteId] = useState({
        value: 'site_1',
        isError: null
    })
    const [tenantId, settenantId] = useState({
        value: 'tenant_1',
        isError: null
    })
    
    return (
        <div className="modal-form-content">
            <h2 className="h2-heading mb-8">Tracking Scripts</h2>
            <div className="flex mb-2">
                <div className={`flex-1 mr-2 ` + (siteId.isError ? 'error ' : '')}>
                    <label className="white-space-no-wrap mr-2 bold w-full d-block mb-2 label">
                        Site Id
                    </label>
                    <input
                        type="text"
                        value={siteId.value}
                        onChange={e => {
                            let val = e.target.value;
                            setSiteId({ ...siteId, value: val, isError: val?.length > 0 ? false : true })
                        }}
                        className="input-field w-full p-2 input-txt"
                    />
                    {
                        siteId.isError && (
                            <p className="error-message">This is required field</p>
                        )
                    }
                </div>
                <div className={`flex-1 ` + (tenantId.isError ? 'error ' : '')}>
                    <label className="white-space-no-wrap mr-2 bold w-full d-block mb-2 label">
                        Tenant Id
                    </label>
                    <input
                        type="text"
                        value={tenantId.value}
                        onChange={e => {
                            let val = e.target.value;
                            settenantId({ ...tenantId, value: val, isError: val?.length > 0 ? false : true })
                        }}
                        className="input-field w-full p-2 input-txt"
                    />
                    {
                        tenantId.isError && (
                            <p className="error-message">This is required field</p>
                        )
                    }
                </div>
            </div>
            <TrackingCodeView siteId={siteId.value} tenantId={tenantId.value}/>
        </div>
    )
}
