import { useState } from "react";

export default (props) => {
    const [loading, setLoading] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState('')
    const [availableDevices, setAvailableDevices] = useState(props.filterData.availableDevices || [])

    const [selectedPage, setSelectedPage] = useState('')
    const [allPages, setAllPages] = useState(props.filterData.allPages || [])

    const [selectedTag, setSelectedTag] = useState('')
    const [allTags, setAllTags] = useState(props.filterData.allTags || [])
    const defaultSelected = {
        selectedDevices: [],
        selectedPages: [],
        selectedTags: [],
        selectedIssues: [],
        jiraTagged: [],
        runsMoreThan: null,
        failedMoreThan: null,
        issues: [],
        status: ''
    };
    const [filterData, setFilterData] = useState(props.selectedFilterData || defaultSelected)
    const closeModal = (e) => {
        e && e.preventDefault();
        props.onClose();
    }
    const handleformSubmit = (e) => {
        e.preventDefault();
        props.onSubmit(filterData)
    }
    const clearAllFilters = (e) => {
        e.preventDefault();
        setFilterData(defaultSelected);
        props.onSubmit(defaultSelected);
    }
    const handleDeviceChange = (value) => {
        setSelectedDevice(value);
    }
    const addDeviceToList = (e) => {
        e.preventDefault();
        let selectedItem = availableDevices.find(item => item._id == selectedDevice);
        if (selectedItem) {
            setFilterData({
                ...filterData,
                selectedDevices: [...filterData.selectedDevices, selectedItem]
            })
        }
    }
    const addPageToList = (e) => {
        e.preventDefault();
        let selectedItem = allPages.find(item => item._id == selectedPage);
        if (selectedItem) {
            setFilterData({
                ...filterData,
                selectedPages: [...filterData.selectedPages, selectedItem]
            })
        }
    }
    const addTagToList = (e) => {
        e.preventDefault()
        let selectedItem = allTags.find(item => item._id == selectedTag);
        if (selectedItem) {
            setFilterData({
                ...filterData,
                selectedTags: [...filterData.selectedTags, selectedItem]
            })
        }
    }
    const removeDevice = (e, item) => {
        e.preventDefault()
        setFilterData({
            ...filterData,
            selectedDevices: filterData.selectedDevices.filter(x => x._id != item._id)
        })
    }
    const removePage = (e, item) => {
        e.preventDefault()
        setFilterData({
            ...filterData,
            selectedPages: filterData.selectedPages.filter(x => x._id != item._id)
        })
    }
    const removeTag = (e, item) => {
        e.preventDefault()
        setFilterData({
            ...filterData,
            selectedTags: filterData.selectedTags.filter(x => x._id != item._id)
        })
    }
    const toggleIssue = (issueName) => {
        let issues = filterData.issues;
        let index = issues.indexOf(issueName);
        if (index > -1) {
            issues.splice(index, 1);
        } else {
            issues.push(issueName);
        }
    }
    const toggleJiraTicket = (jiraString) => {
        let jiraTagged = filterData.jiraTagged;
        let index = jiraTagged.indexOf(jiraString);
        if (index > -1) {
            jiraTagged.splice(index, 1);
        } else {
            jiraTagged.push(jiraString);
        }
    }
    const toggleStatus = (status) => {
        filterData.status = status;
    }
    return (
        <>
            <div className="justify-between align-center px-4 py-3 filter-popup-content">
                <div className="align-center">
                    <h2 className="h2-heading">Filter</h2>
                </div>
                <a onClick={closeModal} className="" href="/index.html">
                    <svg xmlns="http://www.w3.org/2000/svg" className="close-svg-icon" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path d="M21.5365 19.3139C21.8316 19.609 21.9974 20.0092 21.9974 20.4265C21.9974 20.8438 21.8316 21.2441 21.5365 21.5391C21.2414 21.8342 20.8411 22 20.4238 22C20.0065 22 19.6062 21.8342 19.3111 21.5391L11 13.2259L2.68626 21.5365C2.39116 21.8316 1.99092 21.9974 1.57358 21.9974C1.15624 21.9974 0.755993 21.8316 0.46089 21.5365C0.165787 21.2414 6.21883e-09 20.8412 0 20.4239C-6.21883e-09 20.0066 0.165787 19.6064 0.46089 19.3113L8.77463 11.0007L0.463508 2.68741C0.168405 2.39233 0.00261814 1.99211 0.00261815 1.57479C0.00261815 1.15748 0.168405 0.757257 0.463508 0.462172C0.758611 0.167086 1.15886 0.00130875 1.5762 0.00130875C1.99353 0.00130874 2.39378 0.167086 2.68888 0.462172L11 8.77541L19.3137 0.460862C19.6088 0.165777 20.0091 -6.95245e-09 20.4264 0C20.8438 6.95245e-09 21.244 0.165777 21.5391 0.460862C21.8342 0.755948 22 1.15617 22 1.57348C22 1.9908 21.8342 2.39102 21.5391 2.6861L13.2254 11.0007L21.5365 19.3139Z" fill="#1B72E6" />
                    </svg>
                </a>
            </div>

            <div className="filter-form-content">
                <form className="app-form" onSubmit={handleformSubmit}>
                    <div className="app-form__fields">
                        <div className="form-field-select form-content">
                            <label className="label mb-2">Pick Devices</label>
                            <div className="align-center">
                                <div className="form-field">
                                    <select className="select-text" value={selectedDevice} onChange={e => handleDeviceChange(e.target.value)}>
                                        <option>Select Device</option>
                                        {
                                            availableDevices.map((x, xIdx) => {
                                                return (
                                                    <option className="select-text" key={'vdevice' + xIdx} value={x._id}>{x.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <svg className="post-svg-icon" xmlns="http://www.w3.org/2000/svg" width="18"
                                        height="11" viewBox="0 0 18 11" fill="none">
                                        <path
                                            d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z"
                                            fill="#1B72E6" />
                                    </svg>
                                </div>
                                <a onClick={addDeviceToList} href="/add-page.html" className="btn-icon ml-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                        <path d="M2.08672 13.0811H8.91328V19.9189C8.91328 21.0784 9.86721 22 11 22C12.1328 22 13.0867 21.0486 13.0867 19.9189V13.0811H19.9133C21.0759 13.0811 22 12.1297 22 11C22 9.87027 21.0461 8.91892 19.9133 8.91892H13.1165V2.08108C13.1165 0.921622 12.1626 0 11.0298 0C9.89702 0 8.94309 0.951351 8.94309 2.08108V8.91892H2.08672C0.924119 8.91892 0 9.87027 0 11C0 12.1297 0.95393 13.0811 2.08672 13.0811Z" fill="#1B72E6" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                        {
                            filterData.selectedDevices && filterData.selectedDevices.length > 0 && (
                                <div className="tags mt-2">
                                    {
                                        filterData.selectedDevices.map((x, xi) => {
                                            return (
                                                <div key={'v-tags-' + xi} className="tag-item px-3 py-2 mr-2">
                                                    <div className="tag-item__text mr-2">{x.name}</div>
                                                    <a onClick={e => removeDevice(e, x)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                            <path d="M11.7472 10.5349C11.9081 10.6958 11.9986 10.9141 11.9986 11.1417C11.9986 11.3694 11.9081 11.5877 11.7472 11.7486C11.5862 11.9096 11.3679 12 11.1403 12C10.9126 12 10.6943 11.9096 10.5333 11.7486L6 7.21412L1.46523 11.7472C1.30427 11.9081 1.08595 11.9986 0.858315 11.9986C0.630676 11.9986 0.41236 11.9081 0.251395 11.7472C0.0904294 11.5862 3.39209e-09 11.3679 0 11.1403C-3.39209e-09 10.9127 0.0904295 10.6944 0.251395 10.5334L4.78616 6.00036L0.252822 1.46586C0.0918573 1.30491 0.00142808 1.0866 0.00142808 0.858978C0.00142808 0.631352 0.0918573 0.413049 0.252822 0.252094C0.413788 0.0911379 0.632104 0.000713864 0.859743 0.000713861C1.08738 0.000713859 1.3057 0.0911379 1.46666 0.252094L6 4.78659L10.5348 0.251379C10.6957 0.0904238 10.914 -3.79225e-09 11.1417 0C11.3693 3.79225e-09 11.5876 0.0904238 11.7486 0.251379C11.9096 0.412335 12 0.630638 12 0.858263C12 1.08589 11.9096 1.30419 11.7486 1.46515L7.21384 6.00036L11.7472 10.5349Z" fill="#556629" />
                                                        </svg>
                                                    </a>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            )
                        }

                        <div className="form-field-select form-content">
                            <label className="label mb-2">Tags</label>
                            <div className="align-center">
                                <div className="form-field">
                                    <select className="select-text" value={selectedTag} onChange={e => setSelectedTag(e.target.value)}>
                                        <option>Select Tag</option>
                                        {
                                            allTags.map((x, xdx) => {
                                                return (
                                                    <option className="select-text" key={'atags' + xdx} value={x._id}>{x.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <svg className="post-svg-icon" xmlns="http://www.w3.org/2000/svg" width="18"
                                        height="11" viewBox="0 0 18 11" fill="none">
                                        <path
                                            d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z"
                                            fill="#1B72E6" />
                                    </svg>
                                </div>
                                <a onClick={addTagToList} href="/add-page.html" className="btn-icon ml-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                        <path d="M2.08672 13.0811H8.91328V19.9189C8.91328 21.0784 9.86721 22 11 22C12.1328 22 13.0867 21.0486 13.0867 19.9189V13.0811H19.9133C21.0759 13.0811 22 12.1297 22 11C22 9.87027 21.0461 8.91892 19.9133 8.91892H13.1165V2.08108C13.1165 0.921622 12.1626 0 11.0298 0C9.89702 0 8.94309 0.951351 8.94309 2.08108V8.91892H2.08672C0.924119 8.91892 0 9.87027 0 11C0 12.1297 0.95393 13.0811 2.08672 13.0811Z" fill="#1B72E6" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                        {
                            filterData.selectedTags && filterData.selectedTags.length > 0 && (
                                <div className="tags mt-2">
                                    {
                                        filterData.selectedTags.map((x, xi) => {
                                            return (
                                                <div key={'v-tags-' + xi} className="tag-item px-3 py-2 mr-2">
                                                    <div className="tag-item__text mr-2">{x.name}</div>
                                                    <a onClick={e => removeTag(e, x)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                            <path d="M11.7472 10.5349C11.9081 10.6958 11.9986 10.9141 11.9986 11.1417C11.9986 11.3694 11.9081 11.5877 11.7472 11.7486C11.5862 11.9096 11.3679 12 11.1403 12C10.9126 12 10.6943 11.9096 10.5333 11.7486L6 7.21412L1.46523 11.7472C1.30427 11.9081 1.08595 11.9986 0.858315 11.9986C0.630676 11.9986 0.41236 11.9081 0.251395 11.7472C0.0904294 11.5862 3.39209e-09 11.3679 0 11.1403C-3.39209e-09 10.9127 0.0904295 10.6944 0.251395 10.5334L4.78616 6.00036L0.252822 1.46586C0.0918573 1.30491 0.00142808 1.0866 0.00142808 0.858978C0.00142808 0.631352 0.0918573 0.413049 0.252822 0.252094C0.413788 0.0911379 0.632104 0.000713864 0.859743 0.000713861C1.08738 0.000713859 1.3057 0.0911379 1.46666 0.252094L6 4.78659L10.5348 0.251379C10.6957 0.0904238 10.914 -3.79225e-09 11.1417 0C11.3693 3.79225e-09 11.5876 0.0904238 11.7486 0.251379C11.9096 0.412335 12 0.630638 12 0.858263C12 1.08589 11.9096 1.30419 11.7486 1.46515L7.21384 6.00036L11.7472 10.5349Z" fill="#556629" />
                                                        </svg>
                                                    </a>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            )
                        }

                        <div className="form-field-select form-content">
                            <label className="label mb-2">Page Name</label>
                            <div className="align-center">
                                <div className="form-field">
                                    <select className="select-text" value={selectedPage} onChange={e => setSelectedPage(e.target.value)}>
                                        <option>Select Page name</option>
                                        {
                                            allPages.map(x => {
                                                return (
                                                    <option className="select-text" key={'vdevice' + x._id} value={x._id}>{x.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <svg className="post-svg-icon" xmlns="http://www.w3.org/2000/svg" width="18"
                                        height="11" viewBox="0 0 18 11" fill="none">
                                        <path
                                            d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z"
                                            fill="#1B72E6" />
                                    </svg>
                                </div>
                                <a onClick={addPageToList} href="/add-page.html" className="btn-icon ml-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                        <path d="M2.08672 13.0811H8.91328V19.9189C8.91328 21.0784 9.86721 22 11 22C12.1328 22 13.0867 21.0486 13.0867 19.9189V13.0811H19.9133C21.0759 13.0811 22 12.1297 22 11C22 9.87027 21.0461 8.91892 19.9133 8.91892H13.1165V2.08108C13.1165 0.921622 12.1626 0 11.0298 0C9.89702 0 8.94309 0.951351 8.94309 2.08108V8.91892H2.08672C0.924119 8.91892 0 9.87027 0 11C0 12.1297 0.95393 13.0811 2.08672 13.0811Z" fill="#1B72E6" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                        {
                            filterData.selectedPages && filterData.selectedPages.length > 0 && (
                                <div className="tags mt-2">
                                    {
                                        filterData.selectedPages.map((x, xi) => {
                                            return (
                                                <div key={'v-tags-' + xi} className="tag-item px-3 py-2 mr-2">
                                                    <div className="tag-item__text mr-2">{x.name}</div>
                                                    <a onClick={e => removePage(e, x)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                            <path d="M11.7472 10.5349C11.9081 10.6958 11.9986 10.9141 11.9986 11.1417C11.9986 11.3694 11.9081 11.5877 11.7472 11.7486C11.5862 11.9096 11.3679 12 11.1403 12C10.9126 12 10.6943 11.9096 10.5333 11.7486L6 7.21412L1.46523 11.7472C1.30427 11.9081 1.08595 11.9986 0.858315 11.9986C0.630676 11.9986 0.41236 11.9081 0.251395 11.7472C0.0904294 11.5862 3.39209e-09 11.3679 0 11.1403C-3.39209e-09 10.9127 0.0904295 10.6944 0.251395 10.5334L4.78616 6.00036L0.252822 1.46586C0.0918573 1.30491 0.00142808 1.0866 0.00142808 0.858978C0.00142808 0.631352 0.0918573 0.413049 0.252822 0.252094C0.413788 0.0911379 0.632104 0.000713864 0.859743 0.000713861C1.08738 0.000713859 1.3057 0.0911379 1.46666 0.252094L6 4.78659L10.5348 0.251379C10.6957 0.0904238 10.914 -3.79225e-09 11.1417 0C11.3693 3.79225e-09 11.5876 0.0904238 11.7486 0.251379C11.9096 0.412335 12 0.630638 12 0.858263C12 1.08589 11.9096 1.30419 11.7486 1.46515L7.21384 6.00036L11.7472 10.5349Z" fill="#556629" />
                                                        </svg>
                                                    </a>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            )
                        }

                        <div className="form-field-group-checkbox form-content-checkbox">
                            <div className="form-field-group-checkbox__label mb-4 field-label-heading">
                                Issues
                            </div>
                            <div className="form-field-group-checkbox__items">
                                <div className="form-field-group-checkbox__item mr-4">
                                    <label htmlFor="content-changes" className="form-field-checkbox mr-2">
                                        <input onChange={e => toggleIssue('content')} id="content-changes" type="checkbox" { ...(filterData && filterData.issues.indexOf('content') >= 0 ? { checked: true }: {})} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <label htmlFor="content-changes">Content Changes</label>
                                </div>
                                <div className="form-field-group-checkbox__item mr-4">
                                    <label htmlFor="format-changes" className="form-field-checkbox mr-2">
                                    <input onChange={e => toggleIssue('format')} id="format-changes" type="checkbox" { ...(filterData && filterData.issues.indexOf('format') >= 0 ? { checked: true }: {})} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <label htmlFor="format-changes">Format Changes</label>
                                </div>
                                <div className="form-field-group-checkbox__item mr-4">
                                    <label htmlFor="layout-changes" className="form-field-checkbox mr-2">
                                    <input onChange={e => toggleIssue('layout')} id="layout-changes" type="checkbox" { ...(filterData && filterData.issues.indexOf('layout') >= 0 ? { checked: true }: {})} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <label htmlFor="layout-changes">Layout Changes</label>
                                </div>
                            </div>
                        </div>

                        <div className="form-field-group-checkbox form-content-checkbox">
                            <div className="form-field-group-checkbox__label mb-4 field-label-heading">
                                Jira Tickets
                            </div>
                            <div className="form-field-group-checkbox__items">
                                <div className="form-field-group-checkbox__item mr-4">
                                    <label htmlFor="jira-logged" className="form-field-checkbox mr-2">
                                        <input type="checkbox" id="jira-logged" onChange={e => toggleJiraTicket('logged')} { ...(filterData.jiraTagged.indexOf('logged') >= 0 ? { checked: true }:{})}/>
                                        <span className="checkmark"></span>
                                    </label>
                                    <label htmlFor="jira-logged">Logged</label>
                                </div>
                                <div className="form-field-group-checkbox__item mr-4">
                                    <label htmlFor="jira-notlogged" className="form-field-checkbox mr-2">
                                        <input type="checkbox" id="jira-notlogged" onChange={e => toggleJiraTicket('notlogged')} { ...(filterData.jiraTagged.indexOf('notlogged') >= 0 ? { checked: true }:{})}/>
                                        <span className="checkmark"></span>
                                    </label>
                                    <label htmlFor="jira-notlogged">Not Logged</label>
                                </div>
                            </div>
                        </div>

                        <div className="form-field-group-checkbox form-content-checkbox">
                            <div className="form-field-group-checkbox__label mb-4 field-label-heading">
                                Status
                            </div>
                            <div className="form-field-group-checkbox__items radio-group">
                                <div className="form-field-group-checkbox__item mr-4">
                                    <label htmlFor="status-all" className="form-field-checkbox mr-2">
                                        <input type="radio" name="status" id="status-all" onChange={e => toggleStatus('all')} {...( filterData.status == 'all' ? { checked: true }:{} )}/>
                                        <span className="checkmark"></span>
                                    </label>
                                    <label htmlFor="status-all">All</label>
                                </div>
                                <div className="form-field-group-checkbox__item radio-group mr-4">
                                    <label htmlFor="status-passed" className="form-field-checkbox mr-2">
                                        <input type="radio" name="status" id="status-passed" onChange={e => toggleStatus('passed')} {...( filterData.status == 'passed' ? { checked: true }:{} )}/>
                                        <span className="checkmark"></span>
                                    </label>
                                    <label htmlFor="status-passed">Passed</label>
                                </div>
                                <div className="form-field-group-checkbox__item radio-group mr-4">
                                    <label htmlFor="status-failed" className="form-field-checkbox mr-2">
                                        <input type="radio" name="status" id="status-failed" onChange={e => toggleStatus('failed')} {...( filterData.status == 'failed' ? { checked: true }:{} )}/>
                                        <span className="checkmark"></span>
                                    </label>
                                    <label htmlFor="status-failed">Failed</label>
                                </div>
                            </div>
                        </div>

                        <div className="flex form-content mb-2">
                            <div className="form-field-select pr-2">
                                <label className="label mb-2">Page Runs More than</label>
                                <div className="align-center">
                                    <div className="form-field">
                                        <select className="select-text">
                                            <option></option>
                                            <option>1</option>
                                            <option>2</option>
                                        </select>
                                        <svg className="post-svg-icon" xmlns="http://www.w3.org/2000/svg" width="18"
                                            height="11" viewBox="0 0 18 11" fill="none">
                                            <path
                                                d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z"
                                                fill="#1B72E6" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="form-field-select pl-2">
                                <label className="label mb-2">Page Failed More than</label>
                                <div className="align-center">
                                    <div className="form-field">
                                        <select className="select-text">
                                            <option></option>
                                            <option>1</option>
                                            <option>2</option>
                                        </select>
                                        <svg className="post-svg-icon" xmlns="http://www.w3.org/2000/svg" width="18"
                                            height="11" viewBox="0 0 18 11" fill="none">
                                            <path
                                                d="M17.6537 2.41958L9.83027 10.243C9.72124 10.3524 9.59169 10.4393 9.44905 10.4985C9.30641 10.5577 9.15347 10.5882 8.99902 10.5882C8.84457 10.5882 8.69164 10.5577 8.54899 10.4985C8.40635 10.4393 8.2768 10.3524 8.16778 10.243L0.344312 2.41958C0.123853 2.19912 -3.2851e-09 1.90011 0 1.58834C3.2851e-09 1.27656 0.123853 0.977554 0.344312 0.757094C0.564772 0.536635 0.863779 0.412782 1.17556 0.412782C1.48733 0.412782 1.78634 0.536635 2.0068 0.757094L9 7.75029L15.9932 0.756117C16.2137 0.535658 16.5127 0.411804 16.8244 0.411804C17.1362 0.411804 17.4352 0.535658 17.6557 0.756117C17.8761 0.976576 18 1.27558 18 1.58736C18 1.89914 17.8761 2.19814 17.6557 2.4186L17.6537 2.41958Z"
                                                fill="#1B72E6" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="app-form__controls align-center">
                        <a href="/add-page.html" onClick={e => clearAllFilters(e)} className="btn-link-medium mr-8">Clear All</a>
                        <button className="btn-medium-2 btn-medium-width" onClick={e => handleformSubmit} type='submit' disabled={loading}>Apply Filter</button>
                    </div>
                </form>
            </div>
        </>
    )
}