export default (props) => {
    const { tenant, environment, openAddEditEnvironment, openDeleteEnvironment } = props;
    return (
        <tr className="table-content">
            <td colSpan="3">
                <div className='flex justify-between'>
                    <span className="table-text capitalize">{environment.name}</span>
                    <div className='flex align-center'>
                        <a onClick={e => {
                            openAddEditEnvironment(tenant, environment)
                        }} className='py-1 flex align-center no-underline edit-link-option mr-4'>
                            Edit
                        </a>
                        <a onClick={e => {
                            openDeleteEnvironment(tenant, environment)
                        }} className='py-1 flex align-center no-underline edit-link-option mr-4'>
                            Delete
                        </a>
                    </div>
                </div>
            </td>
        </tr>
    )
}