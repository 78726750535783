export default () => {
    return (
        <>
            <div className="form-field-group-checkbox mt-4">
                <div className="form-field-group-checkbox__label mb-3 field-label-heading">
                    Show Collections
                </div>
                <div className="">
                    <div className="form-field-group-checkbox__item mt-4">
                        <label className="form-field-checkbox mr-2">
                            <input type="checkbox"/>
                            <span className="checkmark"></span>
                        </label>
                        <label className="ml-2">Failed consecutively</label>
                    </div>
                    <div className="form-field-group-checkbox__item mt-4">
                        <label className="form-field-checkbox mr-2">
                            <input type="checkbox"/>
                            <span className="checkmark"></span>
                        </label>
                        <label className="ml-2">Failed consecutively 2 times</label>
                    </div>
                    <div className="form-field-group-checkbox__item mt-4">
                        <label className="form-field-checkbox mr-2">
                            <input type="checkbox"/>
                            <span className="checkmark"></span>
                        </label>
                        <label className="ml-2">Failed more than 2 times in the past</label>
                        <input type="text" className="mx-2"/>
                        Runs
                    </div>
                    <div className="form-field-group-checkbox__item mt-4">
                        <label className="form-field-checkbox mr-2">
                            <input type="checkbox"/>
                            <span className="checkmark"></span>
                        </label>
                        <label className="ml-2">Response time more than 3 sec</label>
                    </div>
                </div>
            </div>
        </>
    )
}