import { useEffect, useState } from 'react'
import SvgIcon from "../atoms/SvgIcon"
import storeService from '../../services/store.service';
import pagesService from '../../services/pages.service';
import ListFilterForm from './ListFilterForm';
import AppModal from '../organisms/AppModal';
import SelectedFilterText from './SelectedFilterText';
function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}
export default ({ onFilterUpdate, mode, selectedFilterData }) => {
    const [showFilter, setShowFilter] = useState(false)
    const [filterData, setFilterData] = useState({
        searchText: mode == 'PAGE_LIST' ? storeService.store.PAGES_WITH_DETAILS.filterData.searchText : 
            (mode == 'WORKFLOW_LIST' ? storeService.store.WORKFLOWS_WITH_DETAILS.filterData.searchText : 
                mode == 'COLLECTION_LIST' ? storeService.store.COLLECTIONS_WITH_DETAILS.filterData.searchText : ''
            ),
        selectedDevices: [],
        selectedTags: [],
        byScheduled: null,
        selectedType: null,
        jobStatus: []
    })
    const debouncedValue = useDebounce(filterData.searchText, 700);
    const handleSearchText = (value) => {
        setFilterData({
            searchText: value,
            selectedDevices: [],
            selectedTags: []
        })
    }
    const showFilterModal = (e) => {
        e.preventDefault();
        setShowFilter(true);
    }
    const clearSearchText = (e) => {
        e.preventDefault();
        filterData.searchText = '';
        setFilterData({
            ...filterData
        })
    }

    const handleUpdateFilterData = (updatedFilter) => {
        const updatefilter = {
            ...filterData,
            ...updatedFilter
        }
        setFilterData(updatefilter)
        onFilterUpdate(updatefilter);
        setShowFilter(false)
    }
    const closeFilterModal = () => {
        setShowFilter(false)
    }
    useEffect(() => {
        // setFilterData(selectedFilterData)
    }, selectedFilterData)
    useEffect(() => {
        if (mode == 'WORKFLOW_LIST') {
            let storeSearchTerm = storeService.store.WORKFLOWS_WITH_DETAILS.filterData.searchText;
            if (debouncedValue != storeSearchTerm) {
                storeService.store.WORKFLOWS_WITH_DETAILS.filterData.searchText = debouncedValue;
                onFilterUpdate({
                    ...filterData,
                    ...storeService.store.WORKFLOWS_WITH_DETAILS.filterData
                });
            }
        }
        if (mode == 'PAGE_LIST') {
            let storeSearchTerm = storeService.store.PAGES_WITH_DETAILS.filterData.searchText;
            if (debouncedValue != storeSearchTerm) {
                storeService.store.PAGES_WITH_DETAILS.filterData.searchText = debouncedValue;
                onFilterUpdate({
                    ...filterData,
                    ...storeService.store.PAGES_WITH_DETAILS.filterData
                });
            }
        }
        if (mode == 'COLLECTION_LIST') {
            let storeSearchTerm = storeService.store.COLLECTIONS_WITH_DETAILS.filterData.searchText;
            if (debouncedValue != storeSearchTerm) {
                storeService.store.COLLECTIONS_WITH_DETAILS.filterData.searchText = debouncedValue;
                onFilterUpdate({
                    ...filterData,
                    ...storeService.store.COLLECTIONS_WITH_DETAILS.filterData
                });
            }
        }
       
    }, [debouncedValue]);

    return (
        <>
            <div className="form-field with-pre-icon mr-3 search-and-filter">
                <SvgIcon extraclass='pre-svg-icon' path="/icons/search-icon.svg"></SvgIcon>
                <input type="text" placeholder="Search" value={filterData.searchText} onChange={e => handleSearchText(e.target.value)} className="input-txt width-20vw search-and-filter__input" />
                <a onClick={clearSearchText} className="search-and-filter__clear-icon" href="">
                    <svg xmlns="http://www.w3.org/2000/svg" className="close-svg-icon" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <path d="M21.5365 19.3139C21.8316 19.609 21.9974 20.0092 21.9974 20.4265C21.9974 20.8438 21.8316 21.2441 21.5365 21.5391C21.2414 21.8342 20.8411 22 20.4238 22C20.0065 22 19.6062 21.8342 19.3111 21.5391L11 13.2259L2.68626 21.5365C2.39116 21.8316 1.99092 21.9974 1.57358 21.9974C1.15624 21.9974 0.755993 21.8316 0.46089 21.5365C0.165787 21.2414 6.21883e-09 20.8412 0 20.4239C-6.21883e-09 20.0066 0.165787 19.6064 0.46089 19.3113L8.77463 11.0007L0.463508 2.68741C0.168405 2.39233 0.00261814 1.99211 0.00261815 1.57479C0.00261815 1.15748 0.168405 0.757257 0.463508 0.462172C0.758611 0.167086 1.15886 0.00130875 1.5762 0.00130875C1.99353 0.00130874 2.39378 0.167086 2.68888 0.462172L11 8.77541L19.3137 0.460862C19.6088 0.165777 20.0091 -6.95245e-09 20.4264 0C20.8438 6.95245e-09 21.244 0.165777 21.5391 0.460862C21.8342 0.755948 22 1.15617 22 1.57348C22 1.9908 21.8342 2.39102 21.5391 2.6861L13.2254 11.0007L21.5365 19.3139Z" fill="#1B72E6" />
                    </svg>
                </a>
            </div>
            <a href="/filter-modal.html" onClick={showFilterModal} className="link-icon">
                <SvgIcon extraclass='svg-size-l' path="/icons/filter-icon.svg"></SvgIcon>
            </a>
            <SelectedFilterText handleUpdateFilterData={handleUpdateFilterData} selectedFilterData={selectedFilterData} />
            {
                showFilter && (
                    <AppModal>
                        <ListFilterForm mode={mode} selectedFilterData={selectedFilterData} handleUpdateFilterData={handleUpdateFilterData} onClose={closeFilterModal} />
                    </AppModal>
                )
            }
        </>
    )
}