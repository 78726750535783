import { useState, useEffect } from 'react';
export default ({ data }) => {
    const [inputParams, setInputParams] = useState('')
    const [outputParams, setOutputParams] = useState('')
    const [expectedOutput, setExpectedOutput] = useState('')
    const [receivedOutput, setReceivedOutput] = useState('')
    const [inputHeaders, setInputHeaders] = useState('')
    useEffect(() => {
        let result = data.result || {};
        setInputParams(JSON.stringify(result.input || data.input, null, 2));
        if(result.responseHeader) {
            setInputHeaders(JSON.stringify(result.responseHeader, null, 2));
        }
        
        setExpectedOutput(JSON.stringify(result.expectedOutput || data.expectedOutput, null, 2));
        if(result.responseContent) {
            setReceivedOutput(JSON.stringify(result.responseContent, null, 2));
        }
    }, [])
    return (
        <>
            <div className="model-main-content p-3">
                <div className="test-case-info">
                    <div className="flex justify-between mb-4">
                        <div className="test-case-info__item">
                            <div className="test-case-info__label">Test ID</div>
                            <div className="test-case-info__value">{ data.testId }</div>
                        </div>

                        <div className="test-case-info__item">
                            <div className="test-case-info__label">Environment</div>
                            <div className="test-case-info__value">Development</div>
                        </div>
                    </div>

                    <div className="flex justify-between mb-8">
                        <div className="test-case-info__item">
                            <div className="test-case-info__label">Case Name</div>
                            <div className="test-case-info__value">{ data.name }</div>
                        </div>

                        <div className="test-case-info__item">
                            <div className="test-case-info__label">Status</div>
                            <div className={'test-case-info__value ' + (data.result && data.result.result == 'Failed' ? 'font-danger': '')}>{ data.result ? data.result.result: '-' }</div>
                        </div>
                    </div>
                    <div className="test-case-info__field mb-8">
                        <label className="test-case-info__label">Input Headers</label>
                        <textarea className="w-full no-resize mt-2 text-area" defaultValue={inputHeaders} rows="5" readOnly></textarea>
                    </div>

                    <div className="test-case-info__field mb-8">
                        <label className="test-case-info__label">Input Parameters</label>
                        <textarea className="w-full no-resize mt-2 text-area" defaultValue={inputParams} rows="5" readOnly></textarea>
                    </div>

                    <div className="test-case-info__field mb-8">
                        <label className="test-case-info__label">Expected Output</label>
                        <textarea className="w-full no-resize mt-2 text-area" rows="5" defaultValue={expectedOutput} readOnly></textarea>
                    </div>

                    <div className="test-case-info__field mb-8">
                        <label className="test-case-info__label">Output Received 
                        {
                            data.result ? (' (Status Code: ' + data.result.statusCode + ')'): ('')
                        }
                        </label>
                        <textarea className="w-full no-resize mt-2 text-area" rows="5" defaultValue={receivedOutput} readOnly></textarea>
                    </div>
                </div>
            </div>    
        </>
    );
}